import React, { useState, useEffect, Component, ReactNode } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, RouteComponentProps, useHistory } from "react-router-dom";
import LoadingOverlay from "./Components/Navigation/LoadingOverlay/LoadingOverlay";
import Dashboard from "./Pages/Public/Dashboard";
import Unauthorised from "./Pages/Public/Unauthorised";
import AccessDenied from "./Pages/Shared/AccessDenied";
import ListRoles from "./Pages/Admin/Roles/List/ListRoles";
import ListUsers from "./Pages/Admin/Users/List/ListUsers";
import ListIdentityProviders from "./Pages/Admin/IdentityProviders/List/ListIdentityProviders";
import EditIdentityProvider from "./Pages/Admin/IdentityProviders/Edit/EditIdentityProvider";
import EditRole from "./Pages/Admin/Roles/Edit/EditRole";
import ListOrganisations from "./Pages/Admin/Organisations/List/ListOrganisations";
import ListRegions from "./Pages/Admin/Regions/List/ListRegions";
import ListSpaces2 from "./Pages/Admin/Spaces/List2/ListSpaces2";
import ListZones from "./Pages/Admin/Zones/List/ListZones";
import ListFloors from "./Pages/Admin/Floors/List/ListFloors";
import AdminListBuildings from "./Pages/Admin/Buildings/List/ListBuildings";
import EditSpace from "./Pages/Admin/Spaces/Edit/EditSpace";
import EditTaskCategory from "./Pages/Admin/TaskCategories/Edit/EditTaskCategory";
import EditTaskType from "./Pages/Admin/TaskTypes/Edit/EditTaskType";
import EditTaskState from "./Pages/Admin/TaskStates/Edit/EditTaskState";
import EditSpaceState from "./Pages/Admin/SpaceStates/Edit/EditSpaceState";
import Login from "./Pages/Public/Login/Login";
import { oktaAuthConfig } from "./configOkta";
import { LoginCallback, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import EditUser from "./Pages/Admin/Users/Edit/EditUser";
import EditDevice from "./Pages/Admin/Devices/Edit/EditDevice";
import EditParameter from "./Pages/Admin/Parameters/Edit/EditParameter";
import EditBuildingKpis from "./Pages/Admin/BuildingKpis/Edit/EditBuildingKpis";
import EditSignage from "./Pages/Admin/Signages/Edit/EditSignage";
import OneLensListBuildings from "./Pages/OneLens/Buildings/List/ListBuildings";
import ViewVisitTrends from "./Pages/OneLens/Visits/ViewTrends/ViewVisitTrends";
import ListFiles from "./Pages/Admin/Files/List/ListFiles";
import ViewSpaceOperationsOverview from "./Pages/OneLens/Spaces/ViewOperationsOverview/ViewSpaceOperationsOverview";
import EditVisit from "./Pages/Shared/Visits/Edit/EditVisit";
import EditTask from "./Pages/Shared/Tasks/Edit/EditTask";
import ListBookings from "./Pages/Shared/Bookings/List/ListBookings";
import AdminListNotifications from "./Pages/Admin/Notifications/List/ListNotifications";
import EditNotification from "./Pages/Admin/Notifications/Edit/EditNotification";
import NoRights from "./Pages/Public/NoRights";
import ListCateringOrders from "./Pages/OneLens/CateringOrders/List/ListCateringOrders";
import ListDirectedCleaningSpaces from "./Pages/OneLens/Spaces/ListDirectedCleaning/ListDirectedCleaningSpaces";
import EditCateringItem from "./Pages/OneLens/CateringItems/Edit/EditCateringItem";
import EditNotification2 from "./Pages/OneLens/Notifications/Edit2/EditNotification2";
import ListUserNotifications from "./Pages/OneLens/UserNotifications/List/ListUserNotifications";
import ListVisits from "./Pages/Shared/Visits/List/ListVisits";
import apis from "./Providers.Api/apis";
import ListMostRecentEnvironmentalDataByZone from "./Pages/OneLens/EnvironmentalData/ListMostRecentByZone/ListMostRecentEnvironmentalDataByZone";
import ViewEnvironmentalDailySummary from "./Pages/OneLens/EnvironmentalDailySummaries/View/ViewEnvironmentalDailySummary";
import ListSensors from "./Pages/OneLens/Sensors/List/ListSensors";
import ViewEnvironmentalDataOverview from "./Pages/OneLens/EnvironmentalData/ViewOverview/ViewEnvironmentalDataOverview";
import ViewSpaceUtilisation2 from "./Pages/OneLens/Spaces/ViewUtilisation2/ViewSpaceUtilisation";
import ViewMostRecentEnvironmentalDataByZone from "./Pages/OneLens/EnvironmentalData/ViewMostRecentByZone/ViewMostRecentEnvironmentalDataByZone";
import ListEnvironmentalDailySummariesByZone from "./Pages/OneLens/EnvironmentalDailySummaries/ListByZone/ListEnvironmentalDailySummariesByZone";
import ViewSensor from "./Pages/OneLens/Sensors/View/ViewSensor";
import ListFloorsAndViewFloorPlan from "./Pages/OneLens/Floors/ListAndViewPlan/ListFloorsAndViewFloorPlan";
import SearchSpaces from "./Pages/Shared/Spaces/Search/SearchSpaces";
import EditUserPreferenceWorkplacePreferences from "./Pages/Flex/UserPreferences/EditWorkplacePreferences/EditWorkplacePreferences";
import EditUserPreferenceNotifications from "./Pages/Flex/UserPreferences/EditNotifications/EditNotifications";
import EditUserPreferenceAccountSettings from "./Pages/Flex/UserPreferences/EditAccountSettings/EditAccountSettings";
import EditBooking from "./Pages/Shared/Bookings/Edit/EditBooking";
import Layout from "./Components/Layout/Layout/Layout";
import FlexListNotifications from "./Pages/Flex/Notifications/List/ListNotifications";
import ViewSpaceAnalyticsOverview from "./Pages/OneLens/Spaces/ViewAnalyticsOverview/ViewSpaceAnalyticsOverview";
import ViewSpaceUtilisation from "./Pages/OneLens/Spaces/ViewUtilisation/ViewSpaceUtilisation";
import ViewSpaceDailySummary from "./Pages/OneLens/Spaces/ViewDailySummary/ViewSpaceDailySummary";
import { appContext } from "./AppContext";
import { LogOutReason } from "./Providers.Api/Authentication";
import { MenuItemKey } from "./Components/Layout/Sidebar/MenuItemKey";
import { ConnectedComponent } from "react-redux";
import { StaticContext } from "react-router";
import EditBookingPolicy from "./Pages/Admin/BookingPolicies/Edit/EditBookingPolicy";
import ListBookingPolicies from "./Pages/Admin/BookingPolicies/List/ListBookingPolicies";
import NotFound from "./Pages/Public/NotFound";
import AssignBookingPolicyToSpace from "./Pages/Admin/BookingPolicies/AssignToSpace/AssignBookingPolicyToSpace";
import ListTasks from "./Pages/Shared/Tasks/List/ListTasks";
import Home from "./Pages/Shared/Home/Home";
import EditCateringOrder from "./Pages/OneLens/CateringOrders/Edit/EditCateringOrder";
import ListCateringItems from "./Pages/OneLens/CateringItems/List/ListCateringItems";
import CreateRecurringBookings from "./Pages/Flex/Bookings/CreateRecurring/CreateRecurringBookings";
import ViewCateringOrder from "./Pages/OneLens/CateringOrders/View/ViewCateringOrder";
import ListCateringOrderPolicies from "./Pages/OneLens/CateringOrderPolicies/List/ListCateringOrderPolicies";
import ListCostCodes from "./Pages/OneLens/CostCodes/List/ListCostCodes";
import ListCateringMenus from "./Pages/OneLens/CateringMenus/List/ListCateringMenus";
import EditCateringSupplier from "./Pages/OneLens/CateringSuppliers/Edit/EditCateringSupplier";
import ListCateringSuppliers from "./Pages/OneLens/CateringSuppliers/List/ListCateringSuppliers";
import ViewBookingsSchedule from "./Pages/OneLens/Bookings/ViewSchedule/ViewBookingsSchedule";
import ViewVisitDashboard from "./Pages/OneLens/Visits/ViewDashboard/ViewVisitDashboard";
import EditCateringOrderPolicy from "./Pages/OneLens/CateringOrderPolicies/Edit/EditCateringOrderPolicy";
import EditCateringMenu from "./Pages/OneLens/CateringMenus/Edit/EditCateringMenu";
import ViewTrends from "./Pages/OneLens/Bookings/ViewTrends/ViewTrends";
import ListDietaryTerms from "./Pages/OneLens/CateringDietaryTerms/List/ListDietaryTerms";
import ListDeletedUsers from "./Pages/OneLens/Users/ListDeleted/ListDeletedUsers";
import OutOfService from "./Pages/Shared/OutOfService";
import OnelensHome from "./Pages/OneLens/Shared/Home/OnelensHome";
import FlexHome from "./Pages/Flex/Shared/Home/FlexHome";
import OneLensEditNotification from "./Pages/OneLens/Notifications/Edit/EditNotification";
import SearchColleague from "./Pages/Flex/Users/SearchColleague/SearchColleague";
import ListSpaces from "./Pages/Admin/Spaces/List/ListSpaces";
import EditAdvancedSpace from "./Pages/Admin/Spaces/EditAdvanced/EditAdvancedSpace.tsx";
import OneLensListNotifications from "./Pages/OneLens/Notifications/List/ListNotifications";
import EditUserPreferenceCostCodes from "./Pages/Flex/UserPreferences/EditCostCodes/EditCostCodes";
import ViewSchedule from "./Pages/Flex/Bookings/ViewSchedule/ViewSchedule";
import AdminCreateManyDelegates from "./Pages/Admin/Delegates/CreateMany/CreateManyDelegates";
import ListDelegates from "./Pages/Flex/Delegates/List/ListDelegates";
import FlexCreateManyDelegates from "./Pages/Flex/Delegates/CreateMany/CreateManyDelegates";
import ListPantryTimeline from "./Pages/OneLens/CateringPantryTimeline/List/ListPantryTimeline";
import { ViewAnalytics } from "./Pages/OneLens/Visits/ViewAnalytics/ViewAnalytics";
import EditTags from "./Pages/Admin/Tags/Edit/EditTags";
import SystemFilters from "./Pages/Admin/SystemFilters/SystemFilters";
import ViewBookingSeries from "./Pages/Shared/BookingSeries/View/ViewBookingSeries";
import ViewBookingsManagedSchedule from "./Pages/OneLens/Bookings/ViewDashboard/ViewBookingsManagedSchedule";

interface IRoute
{
    exact: boolean;
    protected: boolean;
    requiredRights?: any[];
    path: string;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined; // from RouterProps interface
    menuItemId?: MenuItemKey;
}

export const routeList: IRoute[] =
    [
        // private routes
        { exact: true, protected: true, requiredRights: [], path: "/access-denied", component: AccessDenied },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers", component: ListIdentityProviders, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers/details", component: EditIdentityProvider, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers/details/:id", component: EditIdentityProvider, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users/userdetails", component: EditUser, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles/rolesdetails", component: EditRole, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users/userdetails/:id", component: EditUser, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles/rolesdetails/:id", component: EditRole, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles", component: ListRoles, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Delegates"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/admin/delegates/create", component: AdminCreateManyDelegates, menuItemId: MenuItemKey.Admin_Setup_Security_DelegatePermissions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users", component: ListUsers, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL": ["*"] }, { "API.CostCodes": ["V2"] }], path: "/cost-codes/", component: ListCostCodes, menuItemId: MenuItemKey.Admin_Manage_CostCodes },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Root", component: ListOrganisations, menuItemId: MenuItemKey.Admin_Setup_Organisation },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Region", component: ListRegions, menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Building", component: AdminListBuildings, menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Floor", component: ListFloors, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Zone", component: ListZones, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Space", component: ListSpaces2, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Taskcategories/:id", component: EditTaskCategory },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskCategories"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-categories/filter=Taskcategories/:id", component: EditTaskCategory },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskTypes"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskTypes/:id", component: EditTaskType },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskTypes/:id", component: EditTaskType },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskStates/:id", component: EditTaskState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskStates/:id", component: EditTaskState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Region)", component: EditSpace, menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Region)/:tab", component: EditSpace, menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Building)", component: EditSpace, menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Building)/:tab", component: EditSpace, menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskStates/nodeid=:nodeid/id=:id", component: EditTaskState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskStates/nodeid=:nodeid/id=:id", component: EditTaskState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskTypes"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskTypes/nodeid=:nodeid/id=:id", component: EditTaskType },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Building/TaskTypes/nodeid=:nodeid/id=:id", component: EditTaskType },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["SpaceStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=SpaceStates/nodeid=:nodeid/id=:id", component: EditSpaceState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=SpaceStates/nodeid=:nodeid/id=:id", component: EditSpaceState },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskCategories"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-categories/filter=TaskCategories/nodeid=:nodeid/id=:id", component: EditTaskCategory },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskCategories/nodeid=:nodeid/id=:id", component: EditTaskCategory },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Device/nodeid=:nodeid/id=:id", component: EditDevice },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Parameter/nodeid=:nodeid/id=:id", component: EditParameter },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/BuildingKPI/nodeid=:nodeid/id=:id", component: EditBuildingKpis },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Signage/nodeid=:nodeid/id=:id", component: EditSignage },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/:buildingid", component: ListBookingPolicies, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/assign/:bookingPolicyId", component: AssignBookingPolicyToSpace, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/:buildingId/create", component: EditBookingPolicy, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/edit/:bookingId", component: EditBookingPolicy, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.Filters": ["Read"] }], path: "/admin/system-filters/buildingId=:buildingid", component: SystemFilters, menuItemId: MenuItemKey.Admin_Manage_SystemFilters },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/admin/buildings/:buildingid/spaces", component: ListSpaces, menuItemId: MenuItemKey.Admin_Manage_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/admin/buildings/:floorid/spaces/:spaceid/edit", component: EditAdvancedSpace, menuItemId: MenuItemKey.Admin_Manage_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/admin/buildings/spaces/new", component: EditAdvancedSpace, menuItemId: MenuItemKey.Admin_Manage_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TagDefinitions"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/admin/tags/new", component: EditTags, menuItemId: MenuItemKey.Admin_Manage_Tags },
        { exact: true, protected: true, requiredRights: [], path: "/operational-services-buildings", component: OneLensListBuildings },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["DirectedCleaning"] }, { "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["OccupancyMonitor"] }, { "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-buildings/:buildingid", component: OneLensListBuildings, menuItemId: MenuItemKey.OneLens },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-tasks/:buildingid", component: ListTasks, menuItemId: MenuItemKey.OneLens_Operations_Tasks },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/:tab", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/:tab/:filter", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/visit/:visit_Id/:tab", component: EditVisit, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors-dashboard/:buildingid", component: ViewVisitDashboard, menuItemId: MenuItemKey.OneLens_Operations_Visitors_Dashboard },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors-trends/:buildingid", component: ViewVisitTrends, menuItemId: MenuItemKey.OneLens_Operations_Visitors_Trends },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-tasks/:buildingid/task/:task_Id/:tab", component: EditTask, menuItemId: MenuItemKey.OneLens_Operations_Tasks },
        { exact: true, protected: true, requiredRights: [], path: "/operational-services-home/", component: OnelensHome, menuItemId: MenuItemKey.OneLens_Home },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings-list/:buildingid", component: ListBookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/:tab", component: ListBookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/:tab/:filter", component: ListBookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/bookings/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/oneLens/:buildingId/booking-series/view/:seriesId", component: ViewBookingSeries, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/bookings/trends", component: ViewTrends, menuItemId: MenuItemKey.OneLens_Operations_Bookings_Trends },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/bookings/schedule-view", component: ViewBookingsSchedule, menuItemId: MenuItemKey.OneLens_Operations_Bookings_ScheduleView },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/bookings/dashboard", component: ViewBookingsManagedSchedule, menuItemId: MenuItemKey.OneLens_Operations_Bookings_Dashboard },
        { exact: true, protected: true, requiredRights: [], path: "/operational-services-home/", component: OnelensHome, menuItemId: MenuItemKey.OneLens_Home },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Notifications"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/notifications", component: AdminListNotifications, menuItemId: MenuItemKey.Admin_Setup_Organisation_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Notifications"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/notifications/:notificationid", component: EditNotification, menuItemId: MenuItemKey.Admin_Setup_Organisation_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Roamer"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/roamer", component: ListFiles, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Roamer },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Unity"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/unity", component: ListFiles, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Unity },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Inspire"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/inspire", component: ListFiles, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Inspire },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Common"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/common", component: ListFiles, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Common },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-orders/:buildingid", component: ListCateringOrders, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/oneLens/:buildingId/catering/pantry-timeline", component: ListPantryTimeline, menuItemId: MenuItemKey.OneLens_Operations_Catering_PantryTimeline },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-items/:buildingid", component: ListCateringItems, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid", component: ListCateringOrders, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies", component: ListCateringOrderPolicies, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies/new", component: EditCateringOrderPolicy, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies/:policyId/edit", component: EditCateringOrderPolicy, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId", component: EditCateringItem, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId/view", component: ViewCateringOrder, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus", component: ListCateringMenus, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus/new", component: EditCateringMenu, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus/:cateringMenuId/edit", component: EditCateringMenu, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-dietary-terms/:buildingId", component: ListDietaryTerms, menuItemId: MenuItemKey.Onelens_Operations_Catering_DietaryTerms },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers", component: ListCateringSuppliers, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers/new", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers/:supplierId/edit", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering-suppliers/create/:buildingId", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering-suppliers/edit/:buildingId/:supplierId", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["DirectedCleaning"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-directed-cleaning/:buildingid", component: ListDirectedCleaningSpaces, menuItemId: MenuItemKey.OneLens_Operations_DirectedCleaning },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["OrphanedBookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/users/deleted", component: ListDeletedUsers, menuItemId: MenuItemKey.OneLens_Operations_DeletedUsers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-notifications/:buildingid", component: ListUserNotifications, menuItemId: MenuItemKey.OneLens_Operations_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-notifications/:buildingid/notifications/:notificationId", component: EditNotification2, menuItemId: MenuItemKey.OneLens_Operations_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }], path: "/oneLens/:buildingid/announcements/create", component: OneLensEditNotification, menuItemId: MenuItemKey.OneLens_Operations_Announcements },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }], path: "/oneLens/:buildingid/announcements/edit/:notificationid", component: OneLensEditNotification, menuItemId: MenuItemKey.OneLens_Operations_Announcements },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }], path: "/oneLens/:buildingid/announcements/list", component: OneLensListNotifications, menuItemId: MenuItemKey.OneLens_Operations_Announcements },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid", component: ListMostRecentEnvironmentalDataByZone, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/:zoneId", component: ViewMostRecentEnvironmentalDataByZone, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["CostCodes"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-cost-codes/:buildingid", component: ListCostCodes, menuItemId: MenuItemKey.OneLens_Operations_CostCodes },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/zones/:zoneId", component: ViewEnvironmentalDailySummary, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/:zoneId/historical", component: ListEnvironmentalDailySummariesByZone, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-sensors/:buildingid", component: ListSensors, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-sensors/:buildingid/:sensorId", component: ViewSensor, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Overview"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-overview/:buildingid", component: ViewEnvironmentalDataOverview, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-floors/:buildingid", component: ListFloorsAndViewFloorPlan, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Floors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings", component: ListBookings, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings/:buildingid/mybooking/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookingsForOthers"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings-for-others", component: ListBookings, menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookingsForOthers"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex/bookings-for-others/:buildingId/booking-series/view/:seriesId", component: ViewBookingSeries, menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex/bookings/:buildingId/booking-series/view/:seriesId", component: ViewBookingSeries, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookingsForOthers"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings-for-others/:buildingid/mybookingother/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings/:buildingid/mybooking/:bookingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/create-catering-order/:buildingid/:bookingid/:spaceId", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyVisitors"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-visitor", component: ListVisits, menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyVisitors"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-visitor/:buildingid/myvisit/:visit_Id", component: EditVisit, menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Workplace"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-workplace", component: EditUserPreferenceWorkplacePreferences, menuItemId: MenuItemKey.Flex_Settings_Workplace },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["AccountSettings"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-notifications", component: EditUserPreferenceNotifications, menuItemId: MenuItemKey.Flex_Settings_Notifications },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Notifications"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-account", component: EditUserPreferenceAccountSettings, menuItemId: MenuItemKey.Flex_Settings_Account },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["CostCodes"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex/settings/default-cost-codes", component: EditUserPreferenceCostCodes, menuItemId: MenuItemKey.Flex_Settings_CostCodes },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Delegates"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex/delegates/create", component: FlexCreateManyDelegates, menuItemId: MenuItemKey.Flex_Settings_DelegatePermissions },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Delegates"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex/delegates", component: ListDelegates, menuItemId: MenuItemKey.Flex_Settings_DelegateAccess },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks", component: ListTasks, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks/:tab", component: ListTasks, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks/:buildingid/mytask/:task_Id", component: EditTask, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid", component: ViewSpaceOperationsOverview, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid/:tab", component: ViewSpaceOperationsOverview, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid/:tab/:filter", component: ViewSpaceOperationsOverview, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.Spaces": ["Read"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services/buildings/:buildingid/spaces/search", component: SearchSpaces, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "FLEX.SearchSpaces": ["Single"] }, { "FLEX.Search": ["*"] }], path: "/flex-find-a-space", component: SearchSpaces, menuItemId: MenuItemKey.Flex_FindASpace_Single },
        { exact: true, protected: true, requiredRights: [{ "FLEX.SearchSpaces": ["Single"] }, { "FLEX.Search": ["*"] }], path: "/flex-find-a-space/:buildingid/:searchaspace/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_FindASpace_Single },
        { exact: true, protected: true, requiredRights: [{ "FLEX.SearchSpaces": ["Single"] }, { "FLEX.Search": ["*"] }], path: "/flex/schedule-view", component: ViewSchedule, menuItemId: MenuItemKey.Flex_Schedule },
        { exact: true, protected: true, requiredRights: [{ "FLEX.SearchSpaces": ["Single"] }, { "FLEX.Search": ["*"] }], path: "/flex-my-bookings/:buildingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.SearchSpaces": ["Advanced"] }], path: "/flex-recurring-booking", component: CreateRecurringBookings, menuItemId: MenuItemKey.Flex_FindASpace_Recurring },
        { exact: true, protected: true, requiredRights: [], path: "/flex-home/", component: FlexHome, menuItemId: MenuItemKey.Flex_Home },
        { exact: true, protected: false, requiredRights: [], path: "/flex-my-notification", component: FlexListNotifications, menuItemId: MenuItemKey.Flex_MyNotification },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Overview"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-overview/:buildingid", component: ViewSpaceAnalyticsOverview, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Analytics"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-analytics", component: ViewSpaceUtilisation2, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Heatmaps"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-heatmaps/:buildingid", component: ViewSpaceUtilisation, menuItemId: MenuItemKey.OneLens_Analytics_Space_Heatmaps },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["SpaceDetails"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-detail", component: ViewSpaceDailySummary, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },
        { exact: true, protected: true, requiredRights: [{ "FLEX.Search": ["People"] }, { "FLEX.Search": ["*"] }], path: "/flex/users/search-colleagues", component: SearchColleague, menuItemId: MenuItemKey.Flex_FindAColleague },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.VisitorAnalytics": ["Overview"] }], path: "/onelens/visits/analytics", component: ViewAnalytics, menuItemId: MenuItemKey.OneLens_Analytics_Visitors },

        // public routes
        {
            exact: true,
            protected: false,
            requiredRights: [],
            path: "/norights",
            component: NoRights,
        },
        {
            exact: true,
            protected: false,
            requiredRights: [],
            path: "/out-of-service",
            component: OutOfService,
        },
        {
            exact: true,
            protected: false,
            path: "/unauthorised",
            component: Unauthorised,
        },
        { exact: true, protected: false, path: "/login", component: Login },
        {
            exact: true,
            protected: false,
            path: "/implicit/callback",
            component: LoginCallback,
        },
        { exact: true, protected: false, path: "/auth", component: Dashboard },
        { exact: true, protected: false, path: "/", component: Home },
    ];

export default class Routes extends Component<any, IState>
{
    private get auth() { return appContext().authentication; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }

    private restoreOriginalUri(_oktaAuth: any, originalUri: any): void
    {
        const history = this.props.history;
        if (history)
        {
            history.replace(toRelativeUrl(originalUri, window.location.origin + "/auth"));
        }
        else
        {
            window.location.href = window.location.origin + "/auth";
        }
    }

    private resetPageTitle(): void
    {
        this.appState.set({ pageTitle: "" });
    }

    private isAuthenticated(): boolean
    {
        let isAuthenticated = true;
        const token = this.local.getToken()
        if (!token)
        {
            isAuthenticated = false;
        }
        return isAuthenticated;
    }

    private isAuthorised(requiredRights: any): boolean
    {
        let isRightAvailable = false;
        const rights = this.local.getUserDetails().userRights;

        if(requiredRights == null || requiredRights.length === 0)
        {
            // if requiredRights is null, undefined or an empty array, then no rights are required and route is authorised. 
            return true;
        }

        requiredRights.forEach((item: any) =>
        {
            Object.entries(item).map(([objectKey, objectValue]) =>
            {
                try
                {
                    if (rights)
                    {
                        const newArray = objectKey.split(".");
                        let userRightsFromApi = { ...(rights as any) };

                        newArray.forEach((element) =>
                        {
                            userRightsFromApi = userRightsFromApi[element];
                        });

                        if (userRightsFromApi)
                        {
                            if (userRightsFromApi.includes("*"))
                            {
                                isRightAvailable = true;
                            }
                            else
                            {
                                (objectValue as any).forEach((element: any) =>
                                {
                                    const isRightAvailableForItem = userRightsFromApi.includes(element);
                                    if (isRightAvailableForItem === true)
                                    {
                                        isRightAvailable = true;
                                    }
                                });
                            }
                        }
                    }
                }
                catch (error)
                {
                    console.log(error);
                }
            });
        });
        return isRightAvailable;
    }

    // common function to get the public route object
    public getPublicRoute = (routeObj: IRoute): IRoute =>
    {
        const _routeobj =
        {
            key: routeObj.path,
            exact: routeObj.exact,
            path: routeObj.path,
            component: routeObj.component,
            protected: false,
        };
        return _routeobj;
    }

    public render(): JSX.Element
    {
        const oktaAuth = oktaAuthConfig;
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={(_oktaAuth, originalUri) => this.restoreOriginalUri(_oktaAuth, originalUri)}>
                <Router>
                    <Switch>
                        {routeList.map((routeObj) =>
                        {
                            if (routeObj.protected)
                            {
                                const privateRouteProps = {
                                    key: routeObj.path,
                                    exact: routeObj.exact,
                                    requiredRights: routeObj.requiredRights,
                                    path: routeObj.path,
                                    component: routeObj.component,
                                };

                                const { component: Component, ...rest } = privateRouteProps;
                                return (
                                    <Route
                                        {...rest}
                                        render={(props) =>
                                        {
                                            const isAuthenticated = this.isAuthenticated();
                                            const isAuthorised = this.isAuthorised(
                                                routeObj.requiredRights
                                            );

                                            if (!isAuthenticated)
                                            {
                                                this.auth.logOut(LogOutReason.SessionExpired);
                                                return <></>;
                                            }
                                            else if (!isAuthorised)
                                            {
                                                return <Redirect to={{ pathname: "/norights" }} />;
                                            }
                                            else if (!Component)
                                            {
                                                /*in the RouteProps interface, the component is defined as optional, hence it might be undefined. the 
                                                                    component in else block will throw an error. */
                                                return null;
                                            }
                                            else
                                            {
                                                return (
                                                    <Layout {...props} isPrivate={true}>
                                                        <Component {...props} />
                                                    </Layout>
                                                );
                                            }
                                        }}
                                    />
                                );
                            }
                            else
                            {
                                const publicRoute = this.getPublicRoute(routeObj);

                                const { component: Component, ...rest } = publicRoute;
                                if (!Component)
                                {
                                    return null;
                                }
                                return (
                                    <Route
                                        {...rest}
                                        render={(props) =>
                                        {
                                            return (
                                                <Layout {...props} isPrivate={false}>
                                                    <Component {...props} />
                                                </Layout>
                                            );
                                        }}
                                    />
                                );
                            }
                        })}
                        <Route exact path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Router>
            </Security>
        );
    }
}

export interface IState
{
}
