import { Component } from 'react';

export default class Waterpoint extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 4C6.25 1.92893 7.92893 0.25 10 0.25H24C26.0711 0.25 27.75 1.92893 27.75 4V19.0917C27.75 19.832 27.5309 20.5558 27.1202 21.1718L24.5146 25.0801C23.8191 26.1234 22.6483 26.75 21.3944 26.75H12.6056C11.3517 26.75 10.1809 26.1234 9.48536 25.0801L6.87981 21.1718C6.46914 20.5558 6.25 19.832 6.25 19.0917V4ZM10 1.75C8.75736 1.75 7.75 2.75736 7.75 4V19.0917C7.75 19.5359 7.88149 19.9701 8.12789 20.3397L10.7334 24.2481C11.1507 24.874 11.8533 25.25 12.6056 25.25H21.3944C22.1467 25.25 22.8493 24.874 23.2666 24.2481L25.8721 20.3397C26.1185 19.9701 26.25 19.5359 26.25 19.0917V4C26.25 2.75736 25.2426 1.75 24 1.75H10Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 25.25H21.75V29.75H12.25V25.25ZM13.75 26.75V28.25H20.25V26.75H13.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 30C3.25 29.0335 4.0335 28.25 5 28.25H29C29.9665 28.25 30.75 29.0335 30.75 30V33C30.75 33.4142 30.4142 33.75 30 33.75C29.5858 33.75 29.25 33.4142 29.25 33V30C29.25 29.8619 29.1381 29.75 29 29.75H5C4.86193 29.75 4.75 29.8619 4.75 30V33C4.75 33.4142 4.41421 33.75 4 33.75C3.58579 33.75 3.25 33.4142 3.25 33V30Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6C5.25 5.58579 5.58579 5.25 6 5.25H28C28.4142 5.25 28.75 5.58579 28.75 6C28.75 6.41421 28.4142 6.75 28 6.75H6C5.58579 6.75 5.25 6.41421 5.25 6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 19C5.25 18.5858 5.58579 18.25 6 18.25H28C28.4142 18.25 28.75 18.5858 28.75 19C28.75 19.4142 28.4142 19.75 28 19.75H6C5.58579 19.75 5.25 19.4142 5.25 19Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 32C8.25 31.5858 8.58579 31.25 9 31.25H12.5C12.9142 31.25 13.25 31.5858 13.25 32C13.25 32.4142 12.9142 32.75 12.5 32.75H9C8.58579 32.75 8.25 32.4142 8.25 32Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 32C21.25 31.5858 21.5858 31.25 22 31.25H25.5C25.9142 31.25 26.25 31.5858 26.25 32C26.25 32.4142 25.9142 32.75 25.5 32.75H22C21.5858 32.75 21.25 32.4142 21.25 32Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0874 12.0224C19.9151 12.4654 17.4146 12.5736 15.2215 11.6964C11.5349 10.2217 8.49109 11.7097 7.48809 12.5694L6.5119 11.4306C7.84223 10.2903 11.4651 8.57826 15.7785 10.3036C17.5854 11.0264 19.7516 10.9679 21.7876 10.5526C23.8139 10.1394 25.62 9.39128 26.6444 8.83965L27.3556 10.1604C26.2134 10.7754 24.2694 11.5773 22.0874 12.0224Z" />
            </svg>
        )
    }
};