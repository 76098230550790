import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IbssRequestConfig } from "../ApiClient";

export class GetPrivacyPolicyEndpoint implements IGetPrivacyPolicyEndpoint
{
    public async execute(): Promise<string>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: true };
            const response = await axios.get<string>(`${apis.allApi}Service/privacy-policy`, axiosConfig);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetPrivacyPolicyEndpoint
{
    execute(): Promise<string>;
}