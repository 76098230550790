import { Box, Card, Skeleton, TextField, Typography } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssAccordion from '../../../../Components/Miscellaneous/Accordion/IbssAccordion';
import { appContext } from '../../../../AppContext';
import './SpaceInfo.scss'
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DateTime } from 'luxon';
import { Component } from 'react';
import MarkdownViewer from '../../../../Components/Miscellaneous/MarkdownViewer/MarkdownViewer';

class SpaceInfo extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public spaceGuideClicked(actionUrl: string, spaceInfoMessage: string): void
    {
        if (actionUrl && actionUrl.length > 0)
        {
            window.open(actionUrl, '_blank')
            return;
        }
        if (spaceInfoMessage && spaceInfoMessage.length > 0)
        {
            let  newWindow = window.open('', '_blank');
            newWindow?.document.write(spaceInfoMessage);
            return;
        }
    }

    public render(): JSX.Element
    {
        const filteredSpaceGuides = this.props.spaceGuides.filter(x => DateTime.now() > x.availableFrom && DateTime.now() < x.expiryDate)

        return (
            <Card style={{ borderRadius: '8px', marginBottom: '20px' }}>
                <IbssAccordion
                    variant='outlined'
                    borderWidth='1px 0px'
                    isExpanded={this.props.expanded}
                    expandMoreClickedHandler={() => this.props.onClick ? this.props.onClick() : {}}
                    values={[{
                        hideExpand: this.props.hideExpand,
                        hideSummary: this.props.hideSummary,
                        key: 'space-info-accordion',
                        summary: (
                            <Box>
                                <div className="spaceDetailsTitle">
                                    {this.props.spaceName}
                                </div>
                                {!this.props.expanded &&
                                    <Typography className='mt-1' variant='body2'>
                                        {this.labels.funcAdvancedBookingSeeSpaceDetail_L}
                                    </Typography>
                                }
                            </Box>
                        ),
                        details: (
                            <Box sx={{ marginTop: this.props.hideSummary ? '25px' : '', maxHeight: 'calc(100vh - 425px)', overflowY: 'auto' }}>
                                {
                                    this.props.spaceInfo.length > 0 &&
                                    <Box>
                                        <div style={{ marginBottom: '5px' }} className="spaceDetailsSubTitle">
                                            {this.labels.funcSpaceInfoSpaceInformation_S}
                                        </div>
                                        <div className="mb-1">
                                            <MarkdownViewer value={this.props.spaceInfo} />
                                        </div>
                                    </Box>
                                }
                                {
                                    this.props.fetchingSpacePolicy ?
                                        <SpacePolicySkeleton />
                                        :
                                        <Box>
                                            <div style={{ marginBottom: '5px' }} className="spaceDetailsSubTitle">
                                                {this.labels.funcAdvancedBookingPolicyDescription_L}
                                            </div>
                                            <div className="mb-1">
                                                {this.props.policyDescription}
                                            </div>
                                        </Box>
                                }
                                {
                                    this.props.fetchingSpaceInfo &&
                                    <SpaceGuidesSkeleton />
                                }
                                {
                                    filteredSpaceGuides.length > 0 &&
                                    <Box>
                                        <div className='d-flex' style={{ marginBottom: '5px' }}>
                                            <div style={{ alignContent: 'center', marginRight: '5px' }} className="spaceDetailsSubTitle">
                                                {this.labels.funcSpaceInfoSpaceGuides_S}
                                            </div>
                                            <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ fontSize: '18px', color: (theme) => theme.palette.text.primary }}>
                                                <OpenInNewIcon />
                                            </IbssSvgIcon>
                                        </div>
                                        {
                                            filteredSpaceGuides.map(x => (
                                                <div className={`d-flex mb-1 pointer ${(x.actionUrl && x.actionUrl != '') || (x.spaceInfoMessage && x.spaceInfoMessage != '') ? 'spaceGuideOptionHover' : ''}`} onClick={() => this.spaceGuideClicked(x.actionUrl, x.spaceInfoMessage)}>
                                                    {
                                                        x.imageUrl.length > 0 ?
                                                            <img width={100} height={100} src={x.imageUrl} />
                                                            :
                                                            <div className='placeholderImage' />
                                                    }
                                                    <div style={{ alignContent: 'center', marginLeft: '20px' }}>
                                                        <MarkdownViewer value={x.title} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Box>
                                }
                            </Box>
                        )
                    }]}
                />
            </Card>
        )
    }
}

export class SpacePolicySkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ width: '100%' }}>
                <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={'70%'} height={10} />
                <Skeleton sx={{ margin: '15px' }} variant="rectangular" width={'50%'} height={10} />
            </div>
        )
    }
}

export class SpaceGuidesSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={100} height={100} />
                <Skeleton sx={{ margin: '15px', marginTop: '50px' }} variant="rectangular" width={'50%'} height={10} />
            </div>
        )
    }
}

export default SpaceInfo;

export interface IProps
{
    expanded: boolean,
    spaceName: string,
    fetchingSpacePolicy?: boolean,
    fetchingSpaceInfo?: boolean,
    policyDescription: string,
    spaceInfo: string,
    onClick?: () => void,
    hideExpand?: boolean,
    hideSummary?: boolean,
    spaceGuides: ISpaceGuides[],
}

export interface IState
{
}

export interface ISpaceGuides
{
    imageUrl: string,
    title: string,
    actionUrl: string,
    availableFrom: DateTime,
    expiryDate: DateTime
    spaceInfoMessage: string
}