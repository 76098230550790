import * as React from 'react';
import { Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

// to use Switch with a label, use IbssSwitchLabel.

const StyledSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.MuiSwitch-thumb':{
          color: theme.palette.primary.contrastText,
        },

        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.grey[400],
          opacity: 1,
        },
        '&.Mui-disabled .MuiSwitch-track': {
            color: theme.palette.primary.light,
            opacity: 1,
          },

        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.light,
            },

            '& .MuiSwitch-thumb': {
              backgroundColor: theme.palette.primary.main
            },

            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[400],
            },
          },
      },

      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.text.secondary,
      },

  }));

class IbssSwitch extends React.Component<SwitchProps>{
    render(): JSX.Element {
        return <StyledSwitch {...this.props}/>
    }
}

export default IbssSwitch;