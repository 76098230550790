import { Component } from 'react';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import { Grid } from '@mui/material';
import { IbssLuxonDateTimePicker } from '../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import { DateTime } from 'luxon';
import MediaSelector from '../../../../Components/DialogLaunchers/MediaSelector/MediaSelector';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import JoditEditor from 'jodit-react';
import { IJodit } from "jodit/types";
import { IFile } from '../../../../Providers.Api/Files/FilesRepository';
import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

const config: Partial<IJodit['options']> = {
    // HTML Editor
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: false,
    spellcheck: true,
    // @ts-ignore
    editorCssClass: true,
    triggerChangeEvent: true,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    // @ts-ignore
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: "p",
    defaultMode: 1,
    useSplitMode: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
}
class RoamerAndFlexAnnouncementForm extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    public render(): JSX.Element
    {
        return (
            <div style={{ display: 'block' }}>
                <Grid rowSpacing={4} container>
                    <Grid item xs={12}>
                        <div className='mb-1 mt-1 field-heading'>{this.labels.funcAnnouncementEnterTitleShortDescription_L}</div>
                        <Grid item xs={12}>
                            <IbssFormControl sx={{ width: '100%' }}>
                                <IbssTextField
                                    fullWidth
                                    label={this.labels.funcAnnouncementTitle_S}
                                    helperText={`${this.labels.funcAnnouncementCharacterLimit_S}: ${this.props.titleValue.length}/50`}
                                    onChange={(e) => this.props.onTitleChanged(e.target.value)}
                                    value={this.props.titleValue}
                                    inputProps={{ maxLength: 50 }}
                                    error={this.props.showFieldErrors && this.props.titleError}
                                />
                            </IbssFormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <IbssFormControl sx={{ width: '100%' }} className='mt-1'>
                                <IbssTextField
                                    fullWidth
                                    label={this.labels.funcAnnouncementShortDescription_S}
                                    onChange={(e) => this.props.onShortDescriptionChanged(e.target.value)}
                                    value={this.props.shortDescription}
                                    helperText={`${this.labels.funcAnnouncementCharacterLimit_S}: ${this.props.shortDescription.length}/250`}
                                    inputProps={{ maxLength: 250 }}
                                />
                            </IbssFormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='mb-1 field-heading'>{this.labels.funcAnnouncementEnter_L}</div>
                        <JoditEditor value={this.props.announcementMessage} onChange={e => this.props.onAnnouncementMessageChanged(e)} config={config as any} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='mb-1 field-heading'>{this.labels.funcAnnouncementSelectHeaderImage_S}</div>
                        <MediaSelector
                            options={this.props.images.map(x => { return { name: x.name, url: x.path } })}
                            imageSelected={(file: { name: string, url: string }) => this.props.onHeaderImageUrlChanged(file.url)}
                            uploadFile={(filename: string, file: FormData, processAsMap: boolean) => this.props.uploadImage(filename, file, processAsMap)}
                            defaultSelectedImage={this.props.headerImageUrl}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='field-heading'>{this.labels.funcAnnouncementsLabels_S}</div>
                        <div className='mb-1 field-message'>{this.labels.funcAnnouncementsLabels_Message}</div>
                        <div className='d-flex' style={{ height: '73px' }}>
                            <IbssFormControl className='mr-3' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                                <IbssSwitchLabel defaultChecked={this.props.customLabelSwitchValue} onChange={e => this.props.onCustomLabelSwitchChanged(e.target.checked)} label={this.labels.funcAnnouncementsCustomLabel_S} />
                            </IbssFormControl>
                            {
                                this.props.customLabelSwitchValue &&
                                <div className='d-flex'>
                                    <IbssFormControl sx={{ width: '250px' }} className='mt-1 mr-2'>
                                        <IbssTextField
                                            fullWidth
                                            label={this.labels.funcAnnouncementsCustomLabel_S}
                                            onChange={(e) => this.props.onCustomLabelChanged(e.target.value)}
                                            value={this.props.customLabel}
                                            inputProps={{ maxLength: 12 }}
                                            error={this.props.showFieldErrors && this.props.customLabelError}
                                        />
                                    </IbssFormControl>
                                    <div style={{ alignSelf: 'center', paddingTop: '5px' }}>{`${this.labels.funcAnnouncementCharacterLimit_S}: ${this.props.customLabel.length}/12`}</div>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <IbssFormControl>
                            <IbssSwitchLabel defaultChecked={this.props.highPriority} onChange={e => this.props.onHighPrioritySwitchChanged(e.target.checked)} label={this.labels.funcAnnouncementAddHighPriority_L} />
                        </IbssFormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                        <div className='mb-1 field-heading'>{this.labels.funcAnnouncementsMessageSchedule_S}</div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                            <IbssFormControl className='mr-3' style={{ width: '49%' }}>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.funcAnnouncementsActiveStart_S}
                                    value={this.props.activeDateTime}
                                    onChange={e => this.props.onActiveDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.props.showFieldErrors && this.props.dateTimeFieldError,
                                        helperText: this.props.showFieldErrors && this.props.dateTimeFieldError ? this.labels.funcAnnouncementStartError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                            <IbssFormControl style={{ width: '49%' }}>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.funcAnnouncementsEndExpire_S}
                                    value={this.props.expireDateTime}
                                    onChange={e => this.props.onExpireDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.props.showFieldErrors && this.props.dateTimeFieldError,
                                        helperText: this.props.showFieldErrors && this.props.dateTimeFieldError ? this.labels.funcAnnouncementEndError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RoamerAndFlexAnnouncementForm;

export interface IProps
{
    activeDateTime: DateTime;
    onActiveDateTimeChanged: (value: DateTime) => void;
    expireDateTime: DateTime;
    onExpireDateTimeChanged: (value: DateTime) => void;
    customLabelSwitchValue: boolean;
    onCustomLabelSwitchChanged: (value: boolean) => void;
    onTitleChanged: (value: string) => void;
    titleValue: string;
    onShortDescriptionChanged: (value: string) => void;
    shortDescription: string;
    onAnnouncementMessageChanged: (value: string) => void;
    announcementMessage: string;
    images: IFile[];
    uploadImage: (filename: string, file: FormData, processAsMap: boolean) => Promise<void>;
    headerImageUrl: string;
    onHeaderImageUrlChanged: (url: string) => void;
    onCustomLabelChanged: (value: string) => void;
    customLabel: string;
    onHighPrioritySwitchChanged: (value: boolean) => void;
    highPriority: boolean;
    showFieldErrors: boolean;
    titleError: boolean;
    dateTimeFieldError: boolean;
    customLabelError: boolean;
}

export interface IState
{
}