import { Box, Grid, SelectChangeEvent } from "@mui/material";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { appContext } from "../../../../AppContext";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import {  SpacesFilter } from "../../../../Providers.Api/Spaces/SpaceRepository";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import Helper from "../../../../Common/Helper";
import { GridColDef } from "@mui/x-data-grid";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import StatusUpdateModal from "./StatusUpdateModal";
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListSpaces extends IbssPage<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get localStorage() { return appContext().localStorageProvider; }

    private userCanUpdate: boolean;
    private userCanCreate: boolean;

    private rows = new Array<IGridRow>();

    constructor(props: IProps)
    {
        super(props);

        this.userCanUpdate = this.localStorage.hasRight('DATAMODEL.Spaces.Update');
        this.userCanCreate = this.localStorage.hasRight('DATAMODEL.Spaces.Create');

        this.state =
        {
            selectedSpaceIds: new Array<string>(),
            isLoading: false,
            buildingId: this.appState.buildingId,
            filterText: '',
            showDeleteConifimationModal: false,
            spaces: new Array<IGridRow>(),
            totalWorkspacesOfSelectedPolicies: 0,
            openFilterModal: false,
            statusValue: 'Any',
            selectedSpaceType:'Any',
            spaceTypeOptions: [],
            selectedSpacePolicy:'Any',
            spacePolicyOptions: [],
            selectedSpaceFloor:'Any',
            spaceFloorOptions: [],
            showStatusUpdateModal: false,
            disableUpdateStatusButton: true,
            statusUpdateType: ''
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.buildingChanged();
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.buildingChanged()
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ isLoading: true });
        const { history, match } = this.props;
        let buildingId = parseInt((this.props.match.params).buildingid);
        this.pageTitle = this.labels.HubLabelSpaces + ' ' + ' - ' +  ' ' + this.appState.buildingName;
        await this.appState.set({ buildingId: this.state.buildingId });
        
        await this.loadSpaces();
        this.populateSpaceTypes(buildingId);
        this.populateSpacePolicies();
        this.populateSpaceFloors();
        history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
        this.setState({ isLoading: false });
    }

    private async loadSpaces(): Promise<void>
    {
        const filter = new SpacesFilter
        ({ 
            spaceType: this.state.selectedSpaceType === "Any"  ? undefined : this.state.selectedSpaceType,
            policyId: this.state.selectedSpacePolicy === "Any"  ?  undefined : this.state.selectedSpacePolicy,
            isEnabled: this.state.statusValue === "Any" ? undefined : (this.state.statusValue.toLowerCase() === "true"),
            floorId:  this.state.selectedSpaceFloor === "Any" ? undefined : parseInt(this.state.selectedSpaceFloor)
        })
        this.setState({ isLoading: true });
        
        const spacesByBuilding = await this.apiClient.spaces.getV2AllSpaces(this.state.buildingId,filter);
        this.rows = new Array<IGridRow>();
        this.rows = spacesByBuilding?.map(space  =>
            {
                return {
                    spaceId:space.Space_Id ?? "",
                    spaceName:space.Space_Name,
                    description:space.Space_Class,
                    spaceType:space.Space_Type,
                    status:space.Space_IsEnabled === 1 ? this.labels.HubLabelActive : this.labels.HubLabelInActive,
                    policy:space.Booking_Policy_Id,
                    floor: Helper.getFloorNameUsingFloorAndBuildingId(this.state.buildingId, space.Node_Id),
                    zone:space.Meta_Loc_Zone,
                    nodeId: space.Node_Id
                }
            }
        );

        this.setState({ spaces: this.rows, isLoading: false });
    }

    private selectedSpacesChanged(spacesIds: string[]): void
    {
        this.setState({ selectedSpaceIds: spacesIds });

        const selectedSpaceData = this.state.spaces.filter(x => spacesIds.includes(x.spaceId))
        const activeSpacesSelected = selectedSpaceData.some(x => x.status == 'Active');
        const inactiveSpacesSelected = selectedSpaceData.some(x => x.status == 'Inactive');
        if(spacesIds.length == 0)
        {
            this.setState({ disableUpdateStatusButton: true });
            return;
        }
        if (activeSpacesSelected && inactiveSpacesSelected)
        {
            this.setState({ disableUpdateStatusButton: true });
            return;
        }
        if (activeSpacesSelected)
        {
            this.setState({ disableUpdateStatusButton: false, statusUpdateType: 'disable' });
            return;
        }
        if (inactiveSpacesSelected)
        {
            this.setState({ disableUpdateStatusButton: false, statusUpdateType: 'enable' });
            return;
        }
    }

    private async updateStatuses(): Promise<void>
    {
        this.setState({ isLoading: true, showStatusUpdateModal: false });

        const selectedSpaceData = this.state.spaces.filter(x => this.state.selectedSpaceIds.includes(x.spaceId)).map(x => ({ Node_Id: x.nodeId, Space_Id: x.spaceId }))
        if (this.state.statusUpdateType == 'enable')
        {
            try
            {
                await this.apiClient.spaces.enableSpaces(this.state.buildingId, selectedSpaceData)
            } catch (error)
            {
                this.setState({ isLoading: false });
            }
        }
        if (this.state.statusUpdateType == 'disable')
        {
            try
            {
                await this.apiClient.spaces.disableSpaces(this.state.buildingId, selectedSpaceData)
            } catch (error)
            {
                this.setState({ isLoading: false });
            }
        }
        await this.loadSpaces()
        this.setState({ isLoading: false, selectedSpaceIds: [] });
    }

    private applyFilterClicked(): void
    {
        this.setState({ openFilterModal: true })
    }

    private filterSpaces(searchTerm: string): IGridRow[] 
    {
        const spaces = this.state.spaces.filter(space =>
        {
            let key: keyof IGridRow;
            for (key in space)
            {

                if (space[key]?.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase()))
                {
                    return true;
                }
            }
            return false;
        });
        return spaces;
    }

    private filterModalCancelled(): void
    {
        this.setState({
            openFilterModal: !this.state.openFilterModal,
        });
        this.setState({ isLoading: false });
    }

    private async filterModalSubmitted(): Promise<void>
    {
        this.setState({ isLoading: true, openFilterModal: false });
        await this.loadSpaces();
    }

    public populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);
        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));
        this.setState({ spaceTypeOptions: [{ label: this.labels.HubLabelAny, value: "Any" }, ...options] });
    }

    public async populateSpacePolicies(): Promise<void>
    {
        const policy: IListOption<string>[] = [];
        const policies = await this.apiClient.bookingPolicies.getV2BookingPolicies(this.state.buildingId, 2000);
        policies.forEach((item) => {
            policy.push({
                label: item.Booking_Policy_Name,
                value: item.Booking_Policy_Id
            })
        })
        this.setState({
            spacePolicyOptions: [{ label: this.labels.HubLabelAny, value: "Any" }, ...policy]
        })
    }

    public populateSpaceFloors(): void
    {
        const options = this.state.spaces
            .filter(i => i.floor != null)
            .map(i => ({ label: i.floor, value: i.nodeId.toString() })).filter((option, index, self) =>
            index === self.findIndex((t) => (t.label === option.label && t.value === option.value)));
        this.setState({ spaceFloorOptions: [{ label: this.labels.HubLabelAny, value: "Any" }, ...options] });
    }
    
    public getPolicyName(policy: string): React.ReactNode
    {
        const policyName = this.state.spacePolicyOptions.filter((item) => item.value === policy)
        return policyName[0]?.label ? policyName[0]?.label : ""
    }

    private editSpace(space: IGridRow): React.ReactNode
    {
        const { history } = this.props;
        return (
            <IbssSvgIcon onClick={() => history.push(`/admin/buildings/${space.nodeId}/spaces/${space.spaceId}/edit`)}>
                <CreateIcon />
            </IbssSvgIcon>
        )
    }

    public render(): JSX.Element
    {
        const columns: GridColDef[] =
        [
            {
                field: 'spaceName',
                headerName: this.labels.HubLabelSpaceName,
                flex: 1
            },
            {
                field: 'description',
                headerName: this.labels.HubLabelViewPoliciesTableColumnDescription,
                flex: 1
            },
            {
                field: 'spaceType',
                headerName: this.labels.HubLabelSpaceType,
                flex: 1
            },
            {
                field: 'status',
                headerName: this.labels.HubLabelStatus,
                flex: 1
            },
            {
                field: 'policy',
                headerName: this.labels.HubBookingPolicyTitle,
                renderCell: (params) => this.getPolicyName(params.row.policy),
                minWidth:160,
                flex: 1
            },
            {
                field: 'floor',
                headerName: this.labels.HubLabelFloor,
                flex: 1
            },
            {
                field: 'zone',
                headerName: this.labels.HubLabelZone,
                flex: 1
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: 120,
                field: "",
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) => <>{this.editSpace(params.row)}</>
            }
        ];

        const actionButtons: IActionButton[] =
        [
            {
                label: this.labels.funcSpacesSetStatuses_Short,
                icon: (
                    <div style={{ fontSize: '14px' }}>
                        <IbssSvgIcon fontSize='inherit' className="mr-1">
                            {Icons.ZoneIcon}
                        </IbssSvgIcon>
                    </div>
                ),
                color: "info",
                onClick: () => this.setState({ showStatusUpdateModal: true }),
                disabled: this.state.disableUpdateStatusButton || !this.userCanUpdate,
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: !this.userCanCreate,
                onClick: () => this.props.history.push("/admin/buildings/spaces/new"),
            },

        ];

        let filterStatusOption =
        [
            { label: this.labels.HubLabelAny, value: "Any" },
            { label: 'Active', value: 'true' },
            { label: 'In-Active', value: 'false' },
        ];

        const filteredSpaces = this.filterSpaces(this.state.filterText);
        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box className='d-flex'>
                                        <Box className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </Box>
                                        <Box className='table-panel-header' style={{ paddingTop: '4px' }}>
                                            {this.labels.HubLabelSpaces}
                                        </Box>
                                    </Box>
                                    <Box className="table-panel-description" mb={0}>
                                        {this.labels.HubLabelViewSpacesTableDescription}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        <div>
                                            <div className='d-flex'>
                                                <IbssFilter
                                                    searchTerm={this.state.filterText}
                                                    searchTermChanged={(event) => this.setState({ filterText: event.target.value })}
                                                    filterButtonClicked={() => this.applyFilterClicked()}
                                                />
                                            </div>
                                            <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                                <IbssActionButton buttons={actionButtons} />
                                            </Box>
                                            <StatusUpdateModal
                                                open={this.state.showStatusUpdateModal}
                                                onClose={() => this.setState({ showStatusUpdateModal: false })}
                                                statusUpdateType={this.state.statusUpdateType}
                                                spaceCount={this.state.selectedSpaceIds.length}
                                                updateStatus={() => this.updateStatuses()}
                                            />
                                            <IbssDataGrid
                                                loading={this.state.isLoading}
                                                getRowId={(row: IGridRow) => row.spaceId}
                                                className="view-booking-policies-data-grid"
                                                disableRowSelectionOnClick
                                                onRowSelectionModelChange={e => this.selectedSpacesChanged(e as string[])}
                                                checkboxSelection
                                                columns={columns}
                                                rows={filteredSpaces}
                                                onPaginationModelChange={() => this.setState({ selectedSpaceIds: [] })}
                                                rowSelectionModel={this.state.selectedSpaceIds}
                                                pageSizeOptions={[100]}
                                            />
                                            <IbssDialog
                                                open={this.state.openFilterModal}
                                                onClose={() => this.filterModalCancelled()}
                                                fullWidth
                                                header={this.labels.HubLabelFilter}
                                                dialogContent=
                                                {
                                                    <>
                                                        <div className="my-2">
                                                            {this.labels.HubCateringFilterSubTitle}
                                                        </div>
                                                        <div className="my-3" >
                                                            <IbssInputDropDown
                                                                options={this.state.spaceTypeOptions}
                                                                id="statusSelection"
                                                                value={this.state.selectedSpaceType}
                                                                inputLabel={this.labels.HubLabelSpaceType}
                                                                fullWidth
                                                                onChange={(event: SelectChangeEvent<string>) => { this.setState({ selectedSpaceType: event.target.value }) }}
                                                            />
                                                        </div>
                                                        <div className="my-3" >
                                                            <IbssInputDropDown
                                                                options={filterStatusOption}
                                                                id="statusSelection"
                                                                value={this.state.statusValue}
                                                                inputLabel={this.labels.HubLabelStatus}
                                                                fullWidth
                                                                onChange={(event: SelectChangeEvent<string>) => { this.setState({ statusValue: event.target.value }) }}
                                                            />
                                                        </div>
                                                        <div className="my-3" >
                                                            <IbssInputDropDown
                                                                options={this.state.spacePolicyOptions}
                                                                id="statusSelection"
                                                                value={this.state.selectedSpacePolicy}
                                                                inputLabel={this.labels.HubLabelPolicy}
                                                                fullWidth
                                                                onChange={(event: SelectChangeEvent<string>) => { this.setState({ selectedSpacePolicy: event.target.value }) }}
                                                            />
                                                        </div>
                                                        <div className="my-3" >
                                                            <IbssInputDropDown
                                                                options={this.state.spaceFloorOptions}
                                                                id="statusSelection"
                                                                value={this.state.selectedSpaceFloor}
                                                                inputLabel={this.labels.HubLabelFloor}
                                                                fullWidth
                                                                onChange={(event: SelectChangeEvent<string>) => { this.setState({ selectedSpaceFloor: event.target.value }) }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                footer=
                                                {
                                                    <>
                                                        <IbssButtonRedo
                                                            onClick={() => this.filterModalCancelled()}
                                                            color="secondary"
                                                            variant="outlined"
                                                        >
                                                            {this.labels.HubButtonCancel}
                                                        </IbssButtonRedo>
                                                        <IbssButtonRedo
                                                            color="primary"
                                                            variant="contained"
                                                            size="medium"
                                                            onClick={() => this.filterModalSubmitted()}
                                                        >
                                                            {this.labels.HubLabelOk}
                                                        </IbssButtonRedo>
                                                    </>
                                                }
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ListSpaces);

export interface IQueryParams
{
    buildingid: string;
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    isLoading: boolean;
    buildingId: number;
    selectedSpaceIds: string[];
    filterText: string;
    showDeleteConifimationModal: boolean;
    spaces: IGridRow[];
    totalWorkspacesOfSelectedPolicies: number;
    openFilterModal: boolean;
    statusValue: string;
    selectedSpaceType: string;
    spaceTypeOptions: Array<IListOption<string>>;
    selectedSpacePolicy: string;
    spacePolicyOptions: Array<IListOption<string>>;
    selectedSpaceFloor: string;
    spaceFloorOptions: Array<IListOption<string>>;
    showStatusUpdateModal: boolean;
    disableUpdateStatusButton: boolean;
    statusUpdateType: string;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface IGridRow
{
    spaceId:string;
    spaceName:string;
    description:string;
    spaceType:string;
    status:string;
    policy:string;
    floor:string;
    zone:string;
    nodeId: number;
}

export interface IDeleteConfirmationModalProps
{
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
}
