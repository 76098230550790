import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ApiHelper } from "../ApiHelper";

export class GetTasksByBookingIdEndpoint implements IGetTasksByBookingIdEndpoint
{
    public async execute<TTask>(tTask: new() => TTask, buildingId: number, bookingId: string): Promise<TTask[]>
    {
        try
        {
            const query =
                `schema=false&` +
                `showDebugStats=false&` +
                `$top=2000&` +
                `$select=${ApiHelper.toODataSelect(tTask)}&` +
                `$filter=Booking_Id eq ${bookingId}`;

            const response: AxiosResponse<TTask[]> = await axios.get(`${apis.allApi}${buildingId}/Tasks?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetTasksByBookingIdEndpoint
{
    execute<TTask>(tTask: new() => TTask, buildingId: number, bookingId: string): Promise<TTask[]>;
}
