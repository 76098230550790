import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse } from "../Models";

export class GetTagsEndpoint implements IGetTagsEndpoint
{
    public async execute(buildingId: number): Promise<ITagDetails[]>
    {
        try
        {
            const response = await axios.get<IRawPagedResponse<ITagDetails[]>>(`${apis.allApiv2}${buildingId}/tags`);   
            return response.data.Value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetTagsEndpoint
{
    execute(buildingId: number): Promise<ITagDetails[]>;
}

export interface ISupplier
{
    Name: string;
    Description: string;
}


export interface ITagDetails 
{
    Tag_Id: string;
    Node_Id: number;
    TagType: string;
    Values: string;
    Icon: string;
    Description: string;
    Status: string;
    Name: string;
    ConcurrencyStamp: string;
}


