import React, { Component } from 'react';
import { connect } from "react-redux";
import { HEAT_MAP_FILTER_CRITERIA, UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';
import { IPartialAppState, appContext } from '../../../../AppContext';
import IbssbreadcrumbLeft from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import SearchCriteriaHeatmaps from './SearchCriteriaHeatmaps';
import { Drawer } from '@mui/material';
import apis from '../../../../Providers.Api/apis';
import { AxiosResponse } from 'axios';
import Alert from '../../../../Components/Miscellaneous/Alert/Alert';
import { dateConverter, getBuildingNameUsingBuildingId, getBuildingTimeZoneByNodeId, getFloorNameUsingFloorAndBuildingId, getFloorsFromSelectedBuilding, getPeriodTypeString } from '../../../../Common/Helper';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import FloorPlan, { IFloorPlanSpace as IFloorPlanSpace, IPagedFloorPlanSpaces } from '../../../../Components/Data/FloorPlan/FloorPlan';
import { DateTime } from 'luxon';
import { ISpaceUtilisationSummary } from '../../../../Providers.Api/SpaceUtilisationSummaries/GetManyByPeriodEndpoint';
import { DateHelper } from '../../../../Common/DateHelper';
import Guid from '../../../../Common/Guid';


enum SearchResults
{
    NoResults,
    MapTooManyFloors,
    MapFailedToLoad,
    Map,
};

type breadCrumbsValue = {
    src: string,
    value: string,
};

type SpaceAnalyticsSate = {
    isOpen: boolean,
    mapUrl: string,
    isLoading: boolean,
    searchResults: SearchResults,
    mapSpaces: Array<IFloorPlanSpace>,
    loadMap: Guid,
    heatMapBreadCrumbs: Array<breadCrumbsValue>,
    searchData: Array<string>,
    selectedBuildingId: string,
};

export interface queryObject
{
    buildingId: number | string,
    floorNodeId: number | string,
    periodStartDate: Date | string,
    periodEndDate: Date | string,
    periodType: number,
};

class ViewSpaceUtilisation extends React.Component<any, SpaceAnalyticsSate> {

    private labels = appContext().labels;
    private apiCache = appContext().apiCache;
    private spaces = new Array<ISpaceUtilisationSummary>();
    private alert = appContext().alert;
    private appState = appContext().state;
    private apiClient = appContext().apiClient; 
    private localStorageProvider = appContext().localStorageProvider
    constructor(props: any)
    {
        super(props);
        this.state = {
            isOpen: false,
            mapUrl: "",
            isLoading: false,
            searchResults: SearchResults.Map,
            mapSpaces: [],
            loadMap: Guid.empty,
            heatMapBreadCrumbs: [],
            searchData: ["building", "floor", "class", "spaceType", "date", "periodType",],
            selectedBuildingId: this.appState.buildingId.toString(),
        };
    };

    public async componentDidMount()
    {
        const { match }: any = this.props;
        this.appState.subscribe(this, i => this.appStateChanged(i));

        const buildingId = match.params["buildingid"];
        await this.appState.set({ buildingId: buildingId });

        this.setState({ isLoading: true });

        //redux to switch theme
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HublabelSpaceAnalyticsHeatmaps });

        this.fetchData();

    };

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
        const { history, match }: any = this.props;
        if (state.buildingId != undefined)
        {
            this.setState({ selectedBuildingId: state.buildingId?.toString() });
            history.push(match.path.replace(":buildingid", state.buildingId));
        }
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void
    {
        if (prevState.selectedBuildingId != this.state.selectedBuildingId)
        {
            this.fetchData();
        }
    }

    public componentWillReceiveProps(nextProps: any)
    {
        const SearchCriteriaHeatmaps = this.makeHeatMapCriteria();
        this.setState({ heatMapBreadCrumbs: SearchCriteriaHeatmaps });
    };

    public async fetchData()
    {
        const { dispatch }: any = this.props;

        //details of date
        const date = DateTime.local();
        const startDate = date.startOf('month');
        const defaultMonthStartDate = startDate.minus({ months: 1 }).startOf('month');
        const defaultMonthEndDate = startDate.minus({ months: 1 }).endOf('month');
        const monthFirstStartDate = date.day !== 1 ? startDate : defaultMonthStartDate;
        const monthFirstEndDate = date.day !== 1 ? startDate.endOf('month') : defaultMonthEndDate.endOf('month');


        //building details
        const buildingName = getBuildingNameUsingBuildingId(this.state.selectedBuildingId);

        //floor details
        const floorId = getFloorsFromSelectedBuilding(parseInt(this.state.selectedBuildingId))[0].Node_Id;
        const floorName = getFloorNameUsingFloorAndBuildingId(this.state.selectedBuildingId, floorId);

        const payloadData = {
            buildingId: this.state.selectedBuildingId,
            buildingName: buildingName,
            floorId: floorId,
            floorName: floorName,
            periodStartDate: monthFirstStartDate,
            periodEndDate: monthFirstEndDate,
            periodType: 1,
        }

        //getting from redux to update the state
        await dispatch({
            type: HEAT_MAP_FILTER_CRITERIA,
            payload: payloadData,
        });

        const query: queryObject = {
            buildingId: payloadData.buildingId,
            floorNodeId: payloadData.floorId,
            periodType: payloadData.periodType,
            periodStartDate: payloadData.periodStartDate.toJSDate(),
            periodEndDate: payloadData.periodEndDate.toJSDate(),
        };

        this.handleGetSearchData(query);
        this.setState({ heatMapBreadCrumbs: this.makeHeatMapCriteria() });
    }

    public toggleDrawer = (open: boolean) =>
    {
        this.setState({
            isOpen: open,
        });
    };

    public mapFailedToLoad(): void
    {
        this.setState({
            isLoading: false,
            searchResults: SearchResults.MapFailedToLoad,
        });
    };

    public async handleGetSearchData(query: queryObject)
    {
        this.setState({ isLoading: true });
        try
        {
            const buildingId = (typeof(query.buildingId) == "number" ? query.buildingId : parseInt(query.buildingId));
            const startDate = DateHelper.fromIsoOrJsDate(query.periodStartDate).setZoneByNode(buildingId).startOf('day');
            const endDate = startDate.plusPeriods(query.periodType);
            const response = await this.apiClient.spaceUtilisationSummaries.getManyByPeriod(parseInt(query.buildingId.toString()), query.periodType, startDate, endDate, query.floorNodeId.toString());
            this.spaces = response;
            const heatMapBreadCrumbs = this.makeHeatMapCriteria();
            this.updateSearchResults(heatMapBreadCrumbs);
        }
        catch (error)
        {
            this.setState({
                isLoading: false
            });
        }
    };

    public async updateSearchResults(heatMapBreadCrumbs: any)
    {

        this.setState({ isLoading: true });

        let searchResults!: SearchResults;
        let mapUrl = "";
        const allFloors = this.localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors);
        const floorIds = this.spaces.map(i => i.Node_Id).distinct();
        const floors = allFloors.filter(i => floorIds.contains(i.Node_Id));

        let mapSpaceIds = new Array<IFloorPlanSpace>();

        if (this.spaces.length === 0)
        {
            searchResults = SearchResults.NoResults;
        }

        if (floors.length !== 1)
        {
            searchResults = SearchResults.MapTooManyFloors;
        }
        else
        {
            searchResults = SearchResults.Map;
            mapUrl = floors[0].Floor_MapURI;
            mapSpaceIds = this.spaces.map(i => ({ id: i.Space_Id, colour: this.getColorFromRedToGreenByPercentage(i.Period_Current_Space_Value), getColourFromData: false, periodCurrentSpaceValue: i.Period_Current_Space_Value }));
        }

        this.setState({
            isOpen: false,
            searchResults: searchResults,
            mapUrl: mapUrl,
            mapSpaces: mapSpaceIds,
            loadMap: Guid.new(),
            isLoading: false,
            heatMapBreadCrumbs: heatMapBreadCrumbs,
        });
    }

    private floorPlanSpacesRequested(): Promise<IPagedFloorPlanSpaces>
    {
        const spaces = {
            skipToken: "",
            spaces: this.state.mapSpaces,
        };
        return Promise.resolve(spaces);
    }

    public makeHeatMapCriteria()
    {
        let arr: any = []

        this.state.searchData.map((option: string) =>
        {

            if (option === 'building')
            {
                if (Object.keys(this.props.heatMapFilterCriteria).length > 0 && this.props.heatMapFilterCriteria.buildingId !== undefined)
                {
                    arr.push({
                        src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Building.svg`),
                        value: this.props.heatMapFilterCriteria.buildingName,
                    });
                }
            }
            if (option === 'floor')
            {
                if (Object.keys(this.props.heatMapFilterCriteria).length > 0 && this.props.heatMapFilterCriteria.floorId !== undefined)
                {
                    arr.push({
                        src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Floor.svg`),
                        value: this.props.heatMapFilterCriteria.floorName,
                    });

                }
            }
            if (option === "class")
            {
                arr.push({
                    src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`),
                    value: "Any",
                });
            }
            if (option === "spaceType")
            {
                arr.push({
                    src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`),
                    value: "Any",
                });
            }
            if (option === 'date')
            {
                if (Object.keys(this.props.heatMapFilterCriteria).length > 0 && this.props.heatMapFilterCriteria.periodStartDate !== undefined)
                {
                    const periodStartDate = DateTime.fromISO(this.props.heatMapFilterCriteria.periodStartDate).toFormat('dd/MM/yy');
                    arr.push({
                        src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Calendar.svg`),
                        value: periodStartDate,
                    });
                }
            }
            if (option === "periodType")
            {
                if (Object.keys(this.props.heatMapFilterCriteria).length > 0 && this.props.heatMapFilterCriteria.periodType != undefined)
                {
                    const period = getPeriodTypeString(this.props.heatMapFilterCriteria.periodType)
                    arr.push({
                        src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Calendar.svg`),
                        value: period,
                    });
                }
            }
        })

        return arr;
    };

    public getColorFromRedToGreenByPercentage(value: number)
    {
        var hue = ((1 - (value / 100)) * 120).toString(10);
        return ["hsl(", hue, ",100%,50%)"].join("");
    };

    public render(): JSX.Element
    {

        return (
            <div>
                {this.state.isLoading && <Spinner />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content" style={{ paddingBottom: "5px" }}>
                        <div className="space-box-cont">
                            {/* Breadcrumb chip search filter */}
                            <div className="left-space-box-cont flex-row-bredcrumb">
                                <IbssbreadcrumbLeft value={this.state.heatMapBreadCrumbs} />
                            </div>
                            <div className="right-space-box-cont">
                                <div>
                                    <button type="button" className="edit-search btn-primary btn-md" onClick={() => this.toggleDrawer(true)}>{this.labels.HubButtonEditsearchcriteria}</button>
                                </div>
                            </div>
                            <Drawer
                                anchor='right'
                                open={this.state.isOpen}
                                onClose={() => this.toggleDrawer(false)}
                                className="flex-search-filter-criteria"
                            >
                                <SearchCriteriaHeatmaps
                                    toggleDrawer={this.toggleDrawer}
                                    updateSearchResults={(query: queryObject) => this.handleGetSearchData(query)}
                                />
                            </Drawer>
                        </div>
                        <div className={"space-analytics-page-layout " + this.resultsCssClass}>
                        {this.renderResults()}
                    </div>
                    </div>
                </div>
            </div>
        )
    };

    private renderResults(): JSX.Element
    {
        switch (this.state.searchResults)
        {
            case SearchResults.NoResults:
                return (!this.state.isLoading ? <Alert key="noResults" title={this.labels.HubLabelNoSpacesAvailable} text={this.labels.HubLabelsearchCriteriaNoSpaces} /> : <></>);

            case SearchResults.MapTooManyFloors:
                return (<Alert key="mapTooManyFloors" title={this.labels.HubLabelmapView} text={this.labels.HubLabelSelectAFloor} />);

            case SearchResults.MapFailedToLoad:
                return (<Alert key="mapFailedToLoad" title={this.labels.HubmapFailedToLoad} text={this.labels.HubLabelUsingTheListView} />);

            case SearchResults.Map:
                return (<FloorPlan key="map" url={this.state.mapUrl} loadSpaces={this.state.loadMap} onRequestSpaces={() => this.floorPlanSpacesRequested()} mapFailedToLoad={() => this.mapFailedToLoad()} floorId={this.props.heatMapFilterCriteria.floorId ?? 0} />);

            default:
                return (<></>);
        };
    };

    private get resultsCssClass(): string
    {
        switch (this.state.searchResults)
        {
            case SearchResults.NoResults:
            case SearchResults.MapTooManyFloors:
            case SearchResults.MapFailedToLoad:
                return "search-results-cont--alert";

            case SearchResults.Map:
                return "search-results-cont--map";

            default:
                return "";
        };
    };

};

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        heatMapFilterCriteria: state.heatMapFilterCriteria,

    };
};

export default connect(mapStateToProps)(ViewSpaceUtilisation);
