import { Component } from 'react';

export default class SensorIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 15.75C16.3096 15.75 15.75 16.3096 15.75 17C15.75 17.6904 16.3096 18.25 17 18.25C17.6904 18.25 18.25 17.6904 18.25 17C18.25 16.3096 17.6904 15.75 17 15.75ZM14.25 17C14.25 15.4812 15.4812 14.25 17 14.25C18.5188 14.25 19.75 15.4812 19.75 17C19.75 18.5188 18.5188 19.75 17 19.75C15.4812 19.75 14.25 18.5188 14.25 17Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4818 11.1752C13.7992 11.4413 13.8409 11.9143 13.5748 12.2318C12.4304 13.5971 11.75 15.2696 11.75 17.0916C11.75 18.8922 12.3647 20.4966 13.4328 21.8208C13.6929 22.1432 13.6423 22.6154 13.3199 22.8755C12.9975 23.1355 12.5253 23.0849 12.2653 22.7625C10.9937 21.1861 10.25 19.253 10.25 17.0916C10.25 14.8809 11.079 12.8744 12.4252 11.2682C12.6913 10.9508 13.1643 10.9091 13.4818 11.1752Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45252 8.27691C9.78284 8.52682 9.84802 8.9972 9.59811 9.32753C7.80716 11.6947 6.75 14.5274 6.75 17.59C6.75 20.5892 7.7328 23.3523 9.44431 25.6808C9.68963 26.0146 9.61795 26.484 9.28419 26.7293C8.95044 26.9746 8.48101 26.903 8.23569 26.5692C6.3472 24 5.25 20.929 5.25 17.59C5.25 14.1705 6.43284 11.0251 8.40189 8.4225C8.65181 8.09217 9.12219 8.02699 9.45252 8.27691Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45057 5.40044C5.7817 5.64928 5.84842 6.11944 5.59957 6.45058C3.17824 9.67267 1.75 13.5454 1.75 17.6722C1.75 21.669 3.0657 25.4063 5.37009 28.5573C5.6146 28.8916 5.54178 29.3609 5.20744 29.6054C4.8731 29.8499 4.40384 29.7771 4.15933 29.4427C1.6794 26.0518 0.25 22.0077 0.25 17.6722C0.25 13.1911 1.80215 9.007 4.40043 5.54945C4.64927 5.21831 5.11943 5.1516 5.45057 5.40044Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5182 11.1752C20.2008 11.4413 20.1591 11.9143 20.4252 12.2318C21.5696 13.5971 22.25 15.2696 22.25 17.0916C22.25 18.8922 21.6353 20.4966 20.5672 21.8208C20.3071 22.1432 20.3577 22.6154 20.6801 22.8755C21.0025 23.1355 21.4747 23.0849 21.7347 22.7625C23.0063 21.1861 23.75 19.253 23.75 17.0916C23.75 14.8809 22.921 12.8744 21.5748 11.2682C21.3087 10.9508 20.8357 10.9091 20.5182 11.1752Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5475 8.27691C24.2172 8.52682 24.152 8.9972 24.4019 9.32753C26.1928 11.6947 27.25 14.5274 27.25 17.59C27.25 20.5892 26.2672 23.3523 24.5557 25.6808C24.3104 26.0146 24.3821 26.484 24.7158 26.7293C25.0496 26.9746 25.519 26.903 25.7643 26.5692C27.6528 24 28.75 20.929 28.75 17.59C28.75 14.1705 27.5672 11.0251 25.5981 8.4225C25.3482 8.09217 24.8778 8.02699 24.5475 8.27691Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5494 5.40044C28.2183 5.64928 28.1516 6.11944 28.4004 6.45058C30.8218 9.67267 32.25 13.5454 32.25 17.6722C32.25 21.669 30.9343 25.4063 28.6299 28.5573C28.3854 28.8916 28.4582 29.3609 28.7926 29.6054C29.1269 29.8499 29.5962 29.7771 29.8407 29.4427C32.3206 26.0518 33.75 22.0077 33.75 17.6722C33.75 13.1911 32.1979 9.007 29.5996 5.54945C29.3507 5.21831 28.8806 5.1516 28.5494 5.40044Z" />
            </svg>
        )
    }
};
