import { Component } from 'react';

export default class Bar extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3669 11.75H15.4949H15.5048L22.6331 11.75L17 16.9769L11.3669 11.75ZM9.78426 10.2815L6.78659 7.49999L17.7905 7.49999L15.178 10.25H10C9.92501 10.25 9.85259 10.261 9.78426 10.2815ZM19.2155 5.99999L4.87565 5.99999C4.5672 5.99999 4.29019 6.18884 4.1775 6.47598C4.06481 6.76311 4.13941 7.08996 4.36552 7.29977L16.25 18.3272V32.25H10C9.58579 32.25 9.25 32.5858 9.25 33C9.25 33.4142 9.58579 33.75 10 33.75H24C24.4142 33.75 24.75 33.4142 24.75 33C24.75 32.5858 24.4142 32.25 24 32.25H17.75V18.3272L29.6345 7.29977C29.8606 7.08996 29.9352 6.76311 29.8225 6.47598C29.7098 6.18884 29.4328 5.99998 29.1244 5.99998L21.2845 5.99999L25.5437 1.51656C25.829 1.21626 25.8168 0.741542 25.5165 0.456252C25.2162 0.170963 24.7415 0.183134 24.4562 0.48344L19.2155 5.99999ZM19.8595 7.49999L17.247 10.25H24C24.075 10.25 24.1474 10.261 24.2157 10.2815L27.2134 7.49998L19.8595 7.49999Z" />
            </svg>
        )
    }
};