import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse3, PagedResponse } from "../Models";

export class GetBookingPolicyEndpoint implements IGetBookingPolicyEndpoint
{
    public async execute(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy | null>
    {
        try
        {
            const query =
                `$top=1&` +
                `$filter=Booking_Policy_Id eq '${bookingPolicyId}'&` +
                `$select=Booking_Policy_Id,Booking_Policy_Name,Booking_Policy,Node_Id,Booking_Policy_Description`;

            const pagedResponse = await axios.get<IRawPagedResponse3<IBookingPolicy[]>>(`${apis.dataEntryApiV2}${nodeId}/BookingPolicies?${query}`);
            const response = PagedResponse.fromRawResponse3(pagedResponse.data);
            const policy = response.value[0] ?? null;
            return policy;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetBookingPolicyEndpoint
{
    execute(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy | null>;
}

export interface IBookingPolicy
{
    Node_Id: number;
    Booking_Policy_Id: string;
    Booking_Policy_Name: string;
    Booking_Policy_Description: string;
    Booking_Policy: string;
}
