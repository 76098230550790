import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Editor, Element as SlateElement } from "slate";
import { Helper } from "../Helper";

export default class HorizontalRuleButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        const { editor } = this.props;
        const location = editor.selection?.anchor.path[0] ?? 0;
        this.helper.addHorizontalRule();
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcHorizontalRule_S}
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-3 -3 30 30" fill="none">
                    <path d="M4 12L20 12" style={{ stroke: 'var(--ui-text)' }} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
}
