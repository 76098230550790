import { Card, Typography } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import CostCodesDialog, { CostCodeWithAllocation } from '../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { PageHelper } from './PageHelper';

class CateringCostCodes extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get alert() { return appContext().alert; }
    private get userPreferences() { return appContext().userPreferencesService; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            showCostCodeModal: false,
            selectedCostCodes: [],
            userPreferences: PageHelper.getUserPrefrenceJson()
        };
    };

    public componentDidMount()
    {
        const userPrefs = this.localStorage.getUserPreferences();
        this.setState({
            userPreferences: userPrefs, selectedCostCodes: userPrefs.DefaultCateringCostCodes.map(x => ({
                costCodeId: x.CostCodeID.toString(),
                costCode: x.CostCodeName,
                costCodeDescription: x.CostCodeDescription,
                allocation: parseInt(x.CostCodeApportionment)
            }))
        });
    };

    private updateSelectedCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState({ selectedCostCodes: updatedCostCodes });

    }

    public async submit(): Promise<void>
    {
        this.setState({ isLoading: true });

        try
        {
            const payload = {
                ...this.state.userPreferences,
                DefaultCateringCostCodes: this.state.selectedCostCodes.map(x => ({
                    CostCodeID: x.costCodeId,
                    CostCodeName: x.costCode,
                    CostCodeDescription: x.costCodeDescription,
                    CostCodeApportionment: x.allocation.toString()
                }))
            };
            await this.userPreferences.update(payload);
            this.setState({ isLoading: false });
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch {
            this.setState({ isLoading: false });
        }
        this.setState({ isLoading: false });
    };

    public render(): JSX.Element
    {
        return (
            <>
                {
                    this.state.isLoading &&
                    <LoadingOverlay />
                }
                <CostCodesDialog
                    buildingId={1}
                    selectedCostCodes={this.state.selectedCostCodes}
                    show={this.state.showCostCodeModal}
                    onClose={() => this.setState({ showCostCodeModal: false })}
                    updateBookingCostCodes={(updatedCostCodes) => this.updateSelectedCostCodes(updatedCostCodes)}
                />
                <Card className="card-userPref">
                    <Typography className='card-userPref-title' variant="h6" gutterBottom>
                        {this.labels.funcDefaultCateringCostCodesHeader_Message}
                    </Typography>
                    <Typography className='card-userPref-text' variant="body2" gutterBottom>
                        {this.labels.funcDefaultCateringCostCodesInfoPt1_Message}
                    </Typography>
                    <Typography sx={{ marginBottom: '60px' }} className='card-userPref-text' variant="body2" gutterBottom>
                        {this.labels.funcDefaultCateringCostCodesInfoPt2_Message}
                    </Typography>
                    <IbssButton variant='contained' onClick={() => this.setState({ showCostCodeModal: true })}>
                        {this.labels.funcDefaultCostCodesSelect_Long}
                    </IbssButton>
                </Card>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => this.submit()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
}

export default CateringCostCodes;

export interface IProps
{
}

export interface IState
{
    isLoading: boolean;
    showCostCodeModal: boolean;
    selectedCostCodes: CostCodeWithAllocation[];
    userPreferences: IUserPreferences;
}