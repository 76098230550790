import { Component } from 'react';

export default class Toiletsaccessible extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3081 12.7704C14.3252 13.1843 14.0035 13.5336 13.5897 13.5507C8.53061 13.7595 4.5 17.9192 4.5 23.026C4.5 28.2613 8.73931 32.5006 13.9746 32.5006C19.21 32.5006 23.4493 28.2613 23.4493 23.026C23.4493 22.6118 23.7851 22.276 24.1993 22.276C24.6135 22.276 24.9493 22.6118 24.9493 23.026C24.9493 29.0898 20.0384 34.0006 13.9746 34.0006C7.91088 34.0006 3 29.0898 3 23.026C3 17.1109 7.669 12.2938 13.5278 12.052C13.9417 12.0349 14.291 12.3566 14.3081 12.7704Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0388 3.5C11.6632 3.5 10.5481 4.61512 10.5481 5.99069C10.5481 7.36627 11.6632 8.48139 13.0388 8.48139C14.4144 8.48139 15.5295 7.36626 15.5295 5.99069C15.5295 4.61512 14.4144 3.5 13.0388 3.5ZM9.04813 5.99069C9.04813 3.78669 10.8348 2 13.0388 2C15.2428 2 17.0295 3.78669 17.0295 5.99069C17.0295 8.19469 15.2428 9.98139 13.0388 9.98139C10.8348 9.98139 9.04813 8.19469 9.04813 5.99069Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9428 8.50488C13.3536 8.45185 13.7296 8.74188 13.7827 9.15269L15.0128 18.6818C15.0128 18.6821 15.0129 18.6825 15.0129 18.6829C15.2765 20.6696 16.9541 22.1547 18.9657 22.1547H25.499C27.6531 22.1547 29.5382 23.571 30.1519 25.6372L30.1524 25.6388L31.4694 30.11C31.5865 30.5073 31.3593 30.9243 30.9619 31.0413C30.5646 31.1584 30.1476 30.9311 30.0306 30.5338L28.714 26.0643C28.7139 26.0641 28.7139 26.0639 28.7138 26.0637C28.2875 24.6296 26.9841 23.6547 25.499 23.6547H18.9657C16.1946 23.6547 13.8866 21.605 13.5257 18.8786L13.5254 18.8762L12.295 9.34473C12.242 8.93393 12.532 8.55791 12.9428 8.50488Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1726 16.0594C13.1726 15.6451 13.5084 15.3094 13.9226 15.3094H21.4611C22.6703 15.3094 23.7813 16.0141 24.3037 17.1043L24.3073 17.1117L24.3073 17.1118L24.8965 18.3768C25.0714 18.7523 24.9087 19.1985 24.5333 19.3734C24.1578 19.5483 23.7116 19.3856 23.5367 19.0102L22.9494 17.7491C22.6739 17.1776 22.0877 16.8094 21.4611 16.8094H13.9226C13.5084 16.8094 13.1726 16.4736 13.1726 16.0594Z" />
            </svg>
        )
    }
};