import { Descendant, Editor, NodeEntry, Node as SlateNode, Range as SlateRange } from "slate";
import { SlateToMarkdownAdapter } from "./Adapters/SlateToMarkdownAdapter";
import { MarkdownToSlateAdapter } from "./Adapters/MarkdownToSlateAdapter";
import { SlateToReactAdapter } from "./Adapters/SlateToReactAdapter";
import { RenderElementProps, RenderLeafProps } from "slate-react";
import { IBlock, IMarks, INode, IText, NodeType } from "./Models";

export class Helper
{
    private markdownToSlateAdapter = new MarkdownToSlateAdapter();
    private slateToMarkdownAdapter = new SlateToMarkdownAdapter(this);
    private slateToReactAdapter = new SlateToReactAdapter();
    private static headingTypes: NodeType[] = ['heading_one', 'heading_two', 'heading_three', 'heading_four', 'heading_five', 'heading_six'];
    public editor: Editor;

    constructor(editor: Editor)
    {
        this.editor = editor;
    }

    public isBold(): boolean
    {
        const marks: IMarks | null = this.editor.getMarks();
        return (marks?.bold ?? false)
    }

    // todo: only mark text that is not in a code-block
    public addBold(): void
    {
        this.editor.addMark('bold', true);
    }

    public removeBold(): void
    {
        this.editor.removeMark('bold');
    }

    public toggleBold(): void
    {
        (this.isBold() ? this.removeBold() : this.addBold());
    }

    public isItalic(): boolean
    {
        const marks: IMarks | null = this.editor.getMarks();
        return (marks?.italic ?? false)
    }

    // todo: only mark text that is not in a code-block
    public addItalic(): void
    {
        this.editor.addMark('italic', true);
    }

    public removeItalic(): void
    {
        this.editor.removeMark('italic');
    }

    public toggleItalic(): void
    {
        (this.isItalic() ? this.removeItalic() : this.addItalic());
    }

    public isLink(): boolean
    {
        const marks: IMarks | null = this.editor.getMarks();
        const link = (marks?.link ?? '');
        return link.length > 0;
    }

    public getLink(): string
    {
        const marks: IMarks | null = this.editor.getMarks();
        const link = (marks?.link ?? '');
        return link;
    }

    public setLink(url: string): void
    {
        const hasUrl = /\S/.test(url);
        (hasUrl ? this.addLink(url) : this.removeLink());
    }

    // todo: only mark text that is not in a code-block
    public addLink(url: string): void
    {
        this.editor.addMark('link', url);
    }

    public removeLink(): void
    {
        this.editor.removeMark('link');
    }

    public isHeading(): boolean
    {
        const elements = Array.from(this.editor.nodes({ match: n => this.evaluateNode(n, (b, t) => b != null && Helper.headingTypes.contains(b.type)) }));
        return (elements.length > 0);
    }

    public setHeading(level: 0 | 1 | 2 | 3 | 4 | 5 | 6): void
    {
        const editor = this.editor;
        this.removeBlocks();
        const type = Helper.headingTypes[level - 1] ?? 'paragraph';
        editor.setNodes({ type: type } as IBlock);
    }

    public removeHeading(): void
    {
        this.editor.setNodes({ type: 'paragraph' } as IBlock, { match: n => this.evaluateNode(n, (b, t) => b != null && Helper.headingTypes.contains(b.type)) });
    }

    public isList(ordered: boolean): boolean
    {
        const elements = Array.from(this.editor.nodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == (ordered ? 'ol_list' : 'ul_list')) }));
        return (elements.length > 0);
    }

    public addList(ordered: boolean): void
    {
        const editor = this.editor;
        this.removeBlocks();
        editor.setNodes({ type: 'list_item' } as IBlock);
        editor.wrapNodes({ type: (ordered ? 'ol_list' : 'ul_list') } as IBlock);
    }

    public removeList(ordered: boolean): void
    {
        const editor = this.editor;
        const wrappers = Array.from(editor.nodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == (ordered ? 'ol_list' : 'ul_list')) }));
        editor.unwrapNodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == (ordered ? 'ol_list' : 'ul_list')), split: true });
        editor.setNodes({ type: 'paragraph' } as IBlock);
    }

    public toggleList(ordered: boolean): void
    {
        (this.isList(ordered) ? this.removeList(ordered) : this.addList(ordered));
    }

    public isCodeBlock(): boolean
    {
        const elements = Array.from(this.editor.nodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == 'code_block') }));
        return (elements.length > 0);
    }

    // todo: remove marks from the block
    public addCodeBlock(): void
    {
        const editor = this.editor;
        this.removeBlocks();
        editor.wrapNodes({ type: 'code_block' } as IBlock);
    }

    public removeCodeBlock(): void
    {
        const editor = this.editor;
        editor.unwrapNodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == 'code_block'), split: true });
    }

    public toggleCodeBlock(): void
    {
        (this.isCodeBlock() ? this.removeCodeBlock() : this.addCodeBlock());
    }

    public isHorizontalRule(): boolean
    {
        const elements = Array.from(this.editor.nodes({ match: n => this.evaluateNode(n, (b, t) => b != null && b.type == 'thematic_break') }));
        return (elements.length > 0);
    }

    public addHorizontalRule(): void
    {
        const editor = this.editor;
        const location = editor.selection?.anchor.path[0] ?? 0;

        editor.insertNodes(
            { type: 'thematic_break', children: [{ text: '' }] } as IBlock,
            { at: [location + 1] }
        );
        editor.insertNodes(
            { type: 'paragraph', children: [{ text: '' }] } as IBlock,
            { at: [location + 2] }
        );
        editor.select([location + 2]);
    }

    public removeHorizontalRule(): void
    {
        this.editor.setNodes({ type: 'paragraph' } as IBlock, { match: n => this.evaluateNode(n, (b, t) => b != null && b.type == 'thematic_break') });
    }

    public isSelectionZeroLength(): boolean
    {
        const selection = this.editor.selection;
        const result = selection != null && SlateRange.isCollapsed(selection);
        return result;
    }

    public removeBlocks(): void
    {
        this.removeHeading();
        this.removeList(true);
        this.removeList(false);
        this.removeCodeBlock();
        this.removeHorizontalRule();
    }

    public removeMarks(): void
    {
        this.removeBold();
        this.removeItalic();
        this.removeLink();
    }

    public evaluateNode<TResult>(node: SlateNode | INode, evaluate: (block: IBlock | null, text: IText | null) => TResult): TResult
    {
        return evaluate(this.asBlock(node), this.asText(node));
    }

    public asBlock(node: SlateNode | INode): IBlock | null
    {
        return this.isBlock(node) ? node as IBlock : null;
    }

    public isBlock(node: SlateNode | INode): boolean
    {
        return ('type' in node);
    }

    public asText(node: SlateNode | INode): IText | null
    {
        return this.isText(node) ? node as IText : null;
    }

    public isText(node: SlateNode | INode): boolean
    {
        return ('text' in node);
    }

    public fromMarkdown(markdown: string): Promise<Descendant[]>
    {
        const slate = this.markdownToSlateAdapter.execute(markdown);
        return slate;
    }

    public async toMarkdown(slate: Descendant[]): Promise<string>
    {
        const markdown = await this.slateToMarkdownAdapter.execute(slate as INode[], []);
        return markdown;
    }

    public renderElement(props: RenderElementProps, allowLinks: boolean): JSX.Element
    {
        const element = this.slateToReactAdapter.renderElement(props, allowLinks);
        return element;
    }

    public renderLeaf(props: RenderLeafProps, allowLinks: boolean): JSX.Element
    {
        const element = this.slateToReactAdapter.renderLeaf(props, allowLinks);
        return element;
    }

    public logSlate(): void
    {
        const slate = this.editor.children;
        console.log(`Slate:\n-----\n${JSON.stringify(slate, null, 2)}`);
    }

    public async logMarkdown(): Promise<void>
    {
        const slate = this.editor.children;
        const markdown = await this.toMarkdown(slate);
        console.log(`Markdown\n--------\n${markdown}`);
    }
}
