import "./InfiniteScroller.scss";
import { current } from "@reduxjs/toolkit";
import React from "react";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";

export default class InfiniteScroller extends IbssComponent<IProps, IState>
{
    private ref = React.createRef<HTMLDivElement>();

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            skipToken: "",
            complete: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.initialiseContainer();
    }

    private async initialiseContainer(): Promise<void>
    {
        if (this.props.container.current == null || this.ref.current == null)
        {
            window.setTimeout(() => this.initialiseContainer(), 500);
            return;
        }

        this.props.container.current.onscroll = () => this.getDataIfInView(); // todo: will this overwrite any existing handler?
        await this.getDataIfInView();
    }

    private async getDataIfInView(): Promise<void>
    {
        if (this.state.isLoading)
        {
            return;
        }

        if (this.isInView())
        {
            await this.setStateAsync({ isLoading: true });
            const nextSkipToken = await this.props.getData(this.state.skipToken);
            await this.setStateAsync({ isLoading: false, skipToken: nextSkipToken, complete: nextSkipToken == "" });
        }

        if (!this.state.complete && this.isInView())
        {
            this.getDataIfInView();
        }
        else if (!this.state.complete)
        {
            window.setTimeout(() => this.getDataIfInView(), 2000);
        }
    }

    private isInView(): boolean
    {
        const thisRect = this.ref.current?.getBoundingClientRect();
        const containerRect = this.props.container.current?.getBoundingClientRect();

        if (thisRect == null || containerRect == null)
        {
            return false;
        }

        const isInView = (thisRect.top <= containerRect.top ? containerRect.top - thisRect.top <= thisRect.height : thisRect.bottom - containerRect.bottom <= thisRect.height);
        return isInView;
    }

    public render(): JSX.Element
    {
        return (
            <>
                { !this.state.complete &&
                    <div ref={this.ref} className="infinite-scroller">
                        <div className="spinner-border text-primary" role="status" />
                    </div>
                }
            </>
        );
    }
}

export interface IProps
{
    container: React.RefObject<HTMLElement>;
    getData: (skipToken: string) => Promise<string>;
}

export interface IState
{
    isLoading: boolean;
    skipToken: string;
    complete: boolean;
}


export class InfiniteScrollerResult<TItem>
{
    public items: TItem[];
    public skipToken: string;

    constructor(items: TItem[], skipToken: string)
    {
        this.items = items;
        this.skipToken = skipToken;
    }
}
