import { appContext } from "../../../AppContext";
import Guid from "../../../Common/Guid";
import { MessageSeverity } from "./Messages";

export class MessageController
{
    private get appState() { return appContext().state; }

    public add(severity: MessageSeverity, value: string): string
    {
        const messageId = Guid.new().toString();
        const newMessage = { id: messageId, severity: severity, value: value };
        const messages = [newMessage , ...this.appState.messages ];
        this.appState.set({ messages: messages });

        setTimeout(() => this.remove(messageId), 3000);
        return messageId;
    }

    public remove(messageId: string): void
    {
        const messages = this.appState.messages.filter(i => i.id !== messageId);
        this.appState.set({ messages: messages });
    }
}