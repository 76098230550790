import { Button, Grid } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Helper } from "../Helper";
import { Editor, Path, Element as SlateElement } from "slate";
import IbssButton from "../../../Buttons/Button/IbssButton";
import IbssTextField from "../../TextField/IbssTextField";
import { clear } from "console";

export default class LinkButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            showDialog: false,
            url: '',
            urlError: '',
        };
    }

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        const url = this.helper.getLink();
        this.setState({ showDialog: true, url: url, urlError: '' });
    }

    public showLinkDialog(): void 
    {
        const url = this.helper.getLink();
        this.setState({ showDialog: true, url: url, urlError: '' });
    }

    private handleDialogCancelled(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.setState({ showDialog: false });
    }

    private handleDialogOked(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.setState({ showDialog: false });
        this.helper.setLink(this.state.url);
    }

    private handleUrlChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        const labels = appContext().labels;
        const url = e.target.value;

        if (/^\s*$/.test(url))
        {
            this.setState({ url: url, urlError: '' });
            return;
        }

        const isValid = /^(|http:\/\/|https:\/\/)([^: ]*)$/.test(url);
        this.setState({ url: url, urlError: isValid ? '' : labels.funcMustBeValidUrl_L });
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();
        const { showDialog } = this.state;
        const isActive = this.helper.isLink();
        const variant = (isActive ? "contained" : undefined);
        const buttonStyle = { backgroundColor: (isActive ? "var(--ui-mid-tone)" : undefined) };
        const dialogStyle = { display: (showDialog ? "block" : "none") };
        const dialogOverlayStyle = { display: (showDialog ? "block" : "none") };
        const hasUrlError = this.state.urlError ? true : false;

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcLink_S}
                variant={variant}
                sx={buttonStyle}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-4 -5 32 32" fill="none" onMouseDown={e => this.handleMouseDown(e)}>
                    <path d="M9.16488 17.6505C8.92513 17.8743 8.73958 18.0241 8.54996 18.1336C7.62175 18.6695 6.47816 18.6695 5.54996 18.1336C5.20791 17.9361 4.87912 17.6073 4.22153 16.9498C3.56394 16.2922 3.23514 15.9634 3.03767 15.6213C2.50177 14.6931 2.50177 13.5495 3.03767 12.6213C3.23514 12.2793 3.56394 11.9505 4.22153 11.2929L7.04996 8.46448C7.70755 7.80689 8.03634 7.47809 8.37838 7.28062C9.30659 6.74472 10.4502 6.74472 11.3784 7.28061C11.7204 7.47809 12.0492 7.80689 12.7068 8.46448C13.3644 9.12207 13.6932 9.45086 13.8907 9.7929C14.4266 10.7211 14.4266 11.8647 13.8907 12.7929C13.7812 12.9825 13.6314 13.1681 13.4075 13.4078M10.5919 10.5922C10.368 10.8319 10.2182 11.0175 10.1087 11.2071C9.57284 12.1353 9.57284 13.2789 10.1087 14.2071C10.3062 14.5492 10.635 14.878 11.2926 15.5355C11.9502 16.1931 12.279 16.5219 12.621 16.7194C13.5492 17.2553 14.6928 17.2553 15.621 16.7194C15.9631 16.5219 16.2919 16.1931 16.9495 15.5355L19.7779 12.7071C20.4355 12.0495 20.7643 11.7207 20.9617 11.3787C21.4976 10.4505 21.4976 9.30689 20.9617 8.37869C20.7643 8.03665 20.4355 7.70785 19.7779 7.05026C19.1203 6.39267 18.7915 6.06388 18.4495 5.8664C17.5212 5.3305 16.3777 5.3305 15.4495 5.8664C15.2598 5.97588 15.0743 6.12571 14.8345 6.34955" style={{ stroke: 'var(--ui-text)' }} stroke-width="2" stroke-linecap="round" />
                </svg>
                <div
                    className="markdown-editor__overlay"
                    style={dialogOverlayStyle}
                    onClick={e => this.handleDialogCancelled(e)}
                >
                </div>
                <div
                    className="markdown-editor__dialog markdown-editor__dialog--link"
                    style={dialogStyle}
                >
                    <Grid container direction="column">
                        <Grid sx={{ marginBottom: "8px" }}>
                            <IbssTextField
                                className="mt-8"
                                fullWidth
                                type="text"
                                size="small"
                                label="URL" /* todo: label */
                                value={this.state.url}
                                onChange={e => this.handleUrlChange(e)}
                                error={hasUrlError}
                                helperText={this.state.urlError}

                            />
                        </Grid>
                        <Grid>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid sx={{ marginRight: "8px" }}>
                                    <IbssButton
                                        onMouseDown={e => this.handleDialogCancelled(e)}
                                        variant="contained"
                                        className="btn-theme-secondary"
                                    >
                                        {labels.HubButtonCancel}
                                    </IbssButton>
                                </Grid>
                                <Grid>
                                    <IbssButton
                                        onMouseDown={e => this.handleDialogOked(e)}
                                        variant="contained"
                                        disabled={hasUrlError}
                                    >
                                        {labels.HubLabelOk}
                                    </IbssButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
    showDialog: boolean;
    url: string;
    urlError: string;
}
