import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuthConfig = new OktaAuth({
  issuer: 'https://dev-76852820.okta.com',
  clientId: '',
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid email']
});


export { oktaAuthConfig };