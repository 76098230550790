import { Component } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import { appContext } from '../../../../AppContext';
import { DateTime } from 'luxon';
import BookingSummaryItem from './BookingSummaryItem';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import TodayIcon from '@mui/icons-material/Today';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { ReactComponent as LayoutIcon } from './Layout.svg';
import './BookingSummary.scss';

class BookingSummary extends Component<IProps, IState>
{
    private get labels() { return appContext().labels }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <Box className='bookingSummary-container'>
                <Typography variant='body1'className="columnSubheading">{`${this.props.bookingDescription}`}</Typography>
                <BookingSummaryItem label={this.labels.HubLabelOwner} value={this.props.bookingOwner} icon={PersonOutlineOutlinedIcon}/>
                <BookingSummaryItem label={this.labels.HubLabelLocation} value={this.props.spaceName} icon={CoPresentOutlinedIcon}/>
                <BookingSummaryItem label={''} value={this.props.bookingStart.toLocaleDateString()} icon={TodayIcon}/>
                <BookingSummaryItem label={''} value={`${this.props.bookingStart.toLocaleTimeString()}  -  ${this.props.bookingEnd.toLocaleTimeString()}`} icon={ScheduleIcon}/>
                <BookingSummaryItem label={this.labels.HublabelSpaceLayout} value={this.props.spaceLayout} icon={LayoutIcon}/>
            </Box>
        )
    }
}

export default BookingSummary;

export interface IProps
{
    bookingDescription: string;
    bookingOwner: string;
    bookingEnd: DateTime;
    bookingStart: DateTime;
    spaceName: string;
    spaceLayout: string;
}

export interface IState
{
}