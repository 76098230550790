import React, { Component } from "react";
import { PieChart, Pie, Cell, Label } from "recharts";

export default class IbssPieChart extends Component<IProps>
{
    public render(): JSX.Element
    {
        const { emptyData, colors, value, data } = this.props;

        return (
            <div className="chartcenter">
                <PieChart width={215} height={170}>
                    <Pie
                        stroke="none"
                        data={emptyData}
                        innerRadius="40"
                        outerRadius="65"
                        paddingAngle={0}
                        dataKey="value"
                        startAngle={180}
                        endAngle={-360}
                    >
                        {emptyData.map((item, index) => (
                            <Cell
                                key={item.nodeId}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                        <Label
                            stroke="var(--ui-text)"
                            value={value}
                            position="center"
                            fontSize="35"
                            fontWeight="bolder"
                        />
                    </Pie>
                </PieChart>
                <div className="donutchartlegendSpace">
                    {data.length !== 0 && (
                        <table className="col-12">
                            <tbody>
                                {data.map((item, i) => (
                                    <tr key={i}>
                                        <td>
                                            <div
                                                className="statusbox"
                                                style={{
                                                    backgroundColor: colors[i],
                                                }}
                                            ></div>
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.count}</td>
                                        <td>{isNaN(item.value) ? 0 : item.value}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    }
}

interface IProps
{
    emptyData: Array<IPieChart>;
    data: Array<IPieChart>;
    colors: string[];
    value: number;
}

interface IPieChart
{
    nodeId?: number;
    name: string;
    value: number;
    count: number;
}
