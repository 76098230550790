import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Task } from "./TaskRepository";
import { DateTime } from "luxon";

export class UpdateTaskEndpoint implements IUpdateTaskEndpoint
{
    public async execute(nodeId: number, taskId: number, payload: IUpdateTask): Promise<string>
    {
        const primitivePayload = {
            ...payload,
            Catering_Service_Time: payload.Catering_Service_Time.toISO(),
            Catering_Clearing_Time: payload.Catering_Clearing_Time.toISO(),
          };
        try
        {
            const response = await axios.put(apis.allApi + nodeId + '/Tasks/' + taskId, primitivePayload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateTaskEndpoint
{
    execute(nodeId: number, taskId: number, payload: IUpdateTask): Promise<string>;
}


export interface IUpdateTask
{
    Task_Priority: number;
    Task_Description: string;
    Task_Resolve_Description: string;
    Task_Cost_Code: string;
    Catering_Attendees: number;
    Catering_Service_Time: DateTime;
    Catering_Clearing_Time: DateTime;
    Task_Status: string;
}