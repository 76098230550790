import { ICreateEndpoint, ICreateCateringRestrictionResponse } from "./CreateEndpoint";
import { IUpdateEndpoint, IEditCateringRestrictionResponse } from "./UpdateEndpoint";
import { IDeleteCateringRestricitonResponse, IDeleteCateringRestrictionEndpoint } from "./DeleteCateringRestrictionEndpoint";
import { IGetCateringRestrictionsEndpoint } from "./GetCateringRestrictionsEndpoint";

export class CateringRestrictionsRepository implements ICateringRestrictionsRepository
{
    private getCateringRestrictionsEndpoint: IGetCateringRestrictionsEndpoint;
    private deleteCateringRestrictionEndpoint: IDeleteCateringRestrictionEndpoint;
    private createCateringRestrictionEndpoint: ICreateEndpoint;
    private editCateringRestrictionEndpoint: IUpdateEndpoint;

    constructor(
        getCateringRestrictionsEndpoint: IGetCateringRestrictionsEndpoint,
        deleteCateringRestrictionEndpoint: IDeleteCateringRestrictionEndpoint,
        createCateringRestrictionEndpoint: ICreateEndpoint,
        editCateringRestrictionEndpoint: IUpdateEndpoint,
    )
    {
        this.getCateringRestrictionsEndpoint = getCateringRestrictionsEndpoint;
        this.deleteCateringRestrictionEndpoint = deleteCateringRestrictionEndpoint;
        this.createCateringRestrictionEndpoint = createCateringRestrictionEndpoint;
        this.editCateringRestrictionEndpoint = editCateringRestrictionEndpoint;
    }

    public getCateringRestrictions(nodeId: number): Promise<IGetCateringRestrictions>
    {
        return this.getCateringRestrictionsEndpoint.execute(nodeId);
    }
    public deleteCateringRestriction(nodeId: number, restrictionId: string): Promise<IDeleteCateringRestricitonResponse>
    {
        return this.deleteCateringRestrictionEndpoint.execute(nodeId, restrictionId);
    }

    public createCateringRestriction(nodeId: number, payload: ICateringRestriction): Promise<ICreateCateringRestrictionResponse>
    {
        return this.createCateringRestrictionEndpoint.execute(nodeId, payload);
    }

    public editCateringRestriction(nodeId: number, restrictionId: string, payload: ICateringRestriction): Promise<IEditCateringRestrictionResponse>
    {
        return this.editCateringRestrictionEndpoint.execute(nodeId, restrictionId, payload);
    }

}
export interface ICateringRestrictionsRepository 
{
    getCateringRestrictions(nodeId: number): Promise<IGetCateringRestrictions>;
    createCateringRestriction(nodeId: number, payload: ICateringRestriction): Promise<ICreateCateringRestrictionResponse>;
    editCateringRestriction(nodeId: number, restrictionId: string, payload: ICateringRestriction): Promise<IEditCateringRestrictionResponse>;
    deleteCateringRestriction(nodeId: number, restrictionId: string): Promise<IDeleteCateringRestricitonResponse>;
}

export interface IGetCateringRestrictions
{
    Value: ICateringRestriction[];
}

export interface ICateringRestriction
{
    Restriction_Id?: string;
    Section: string;
    ImageURI: string;
    Name: string;
    ConcurrencyStamp?: string;
}
