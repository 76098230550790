import * as React from 'react';
import { InputLabel } from '@mui/material';
import { InputLabelProps } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

type IbssInputLabelProps = InputLabelProps & { 
    // custom props created to allow us to style the component via passing in props. 
    labelColor?: string,
    focusColor?: string,
 };

// styledComponent allow us to define styles upfront with css in js, MUI theme as well as dynamically with props.
// we can create styledComponent using MUI's styled() function.
// we SHOULD NOT forward custom props we create for styling to DOM elements. React will complain.
// use the second argument in styled() to specify these custom props.
// specify the property shouldForwardProps in 2nd argument of styled(), with a function that excludes the custom props from being forwarded on.
// { shouldForwardProps : (prop) => prop !== 'customPropName'}

const StyledInputLabel = styled(InputLabel, {shouldForwardProp:(propName: PropertyKey)=>{
    return !['labelColor','focusColor'].includes(propName as string);
}})<IbssInputLabelProps>(({ labelColor, focusColor }) => ({
    color: labelColor,
    '&.Mui-focused': {
        color: focusColor
      }
  }));

class IbssInputLabel extends React.Component<IbssInputLabelProps>{
    render(){
        return <StyledInputLabel {...this.props}/>
    }
}

export default IbssInputLabel;