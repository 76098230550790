import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IFile } from "./FilesRepository";

export class GetFilesEndpoint implements IGetFilesEndpoint
{
    public async execute(path: string): Promise<IFile[]>
    {
        try
        {
            const response: AxiosResponse<IFile[]> = await axios.get(`${apis.dataEntryWebApi}/file/${path}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetFilesEndpoint
{
    execute(path: string): Promise<IFile[]>;
}
