import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IGetParameterByNameEndpoint } from "./GetParameterByNameEndpoint";

export class ParameterRepository implements IParameterRepository
{
    private getParameterByNameEndpoint: IGetParameterByNameEndpoint;

    constructor(getParameterByNameEndpoint: IGetParameterByNameEndpoint)
    {
        this.getParameterByNameEndpoint = getParameterByNameEndpoint;
    }

    public getParameterByName(nodeId: number, name: IParameterName): Promise<IParameter[]>
    {
        return this.getParameterByNameEndpoint.execute(nodeId, name);
    }
}

export interface IParameterRepository
{
    getParameterByName(nodeId: number, name: IParameterName): Promise<IParameter[]>;
}

export type IParameterName = ("Vis_Pass_Image"| "CostCode_Allow_Freeform" /* | "AnotherParameterName" | "YetAnotherParameterName" */);

export interface IParameter
{
    Node_Id: number;
    Parameter_Description: string;
    Parameter_Id: number;
    Parameter_Label: string;
    Parameter_Name: string;
    Parameter_Section: string;
    Parameter_Toggle_ValueCount: number;
    Parameter_Type: string;
    Parameter_Units: string;
    Parameter_Value: string;
}
