import React, { Component } from 'react';
import { RouterProps } from 'react-router';
import { appContext } from '../../../../AppContext';
import { DateTime } from 'luxon';
import { CateringOrdersFilter } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import { CateringOrderView } from '../../CateringOrders/List/ListCateringOrders';
import { DateHelper } from '../../../../Common/DateHelper';
import { Box, Typography } from '@mui/material';
import IbssPageHeader from '../../../../Components/Forms/DateRange/IbssPageHeader';
import { toggleButtonListObject } from '../../../../Components/Inputs/ToggleButtons/IbssToggleButtons';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { GetCateringStatus } from '../../CateringOrders/List/CateringStatus';
import { ReactComponent as NoSpaceScheduleViewIcon } from '../../../OneLens/Bookings/ViewSchedule/NoSpacesScheduleView.svg';
import ScheduleCateringOrder from './ScheduleCateringOrder';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import Helper from '../../../../Common/Helper';



export default class ListPantryTimeline extends IbssPage<IProps, IState> 
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get bookingService() { return appContext().bookingService; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private helper = new Helper()


    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            buildingId: this.appState.buildingId,
            scheduleCateringOrders: [],
            scheduleOrders: [],
            spaceData: [],
            bookingDetails: [],
            singleDateFilterValue: DateHelper.now().toJSDate(),
            listViewType: "allSchedule",
            buildingStartHrs: '',
            buildingEndHrs: '',
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.buildingChanged();
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            this.props.history.push(`/oneLens/${this.state.buildingId}/catering/pantry-timeline`);
            await this.buildingChanged();
        }
    }

    private getBuildingHours(): void
    {
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.appState.buildingId);
    
        this.setStateAsync({
            buildingStartHrs: building?.Occ_Office_hrs_stt.slice(0, -3) ?? '07:00',
            buildingEndHrs: building?.Occ_Office_hrs_stp.slice(0, -3) ?? '19:00',
        });
    }


    private async buildingChanged(): Promise<void>
    {

        this.pageTitle = this.labels.HubCateringPantryTimeline + ' ' + this.appState.buildingName;
        const payload: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: DateTime.fromJSDate(this.state.singleDateFilterValue).setZoneByNode(this.state.buildingId).startOf('day').toUTC().toISO(),
            endDate: DateTime.fromJSDate(this.state.singleDateFilterValue).setZoneByNode(this.state.buildingId).plus({ days: 1 }).startOf('day').toUTC().toISO(),
        };
        this.loadScheduleTasks(payload);
        this.getBuildingHours();
    }

    private async loadScheduleTasks(payload: IFilter): Promise<void> {
        try {
            const cateringStatus = new GetCateringStatus({});
            const tasksFilter = new CateringOrdersFilter({
                status: payload.statusValue,
                owner: payload.owner,
                taskDueFrom: DateTime.fromISO(payload.startDate),
                taskDueTo: DateTime.fromISO(payload.endDate),
            });
    
            const cateringOrders = await this.apiClient.cateringOrders.getMany(payload.buildingId, tasksFilter);
            const cateringOrdersList = cateringOrders.map(item => new CateringOrderView(item));
    
            const scheduleCateringOrders: IScheduleCateringOrders[] = cateringOrdersList.map(item => ({
                Id: item.id,
                Subject: cateringStatus.renderTaskOverAllStatusText(item.status),
                StartTime:  DateHelper.fromIsoByNode(item.cateringServiceTime, this.state.buildingId).toJSDate(),
                EndTime: DateHelper.fromIsoByNode(item.cateringClearingTime, this.state.buildingId).toJSDate(),
                SpaceName: item.spaceName,
                SpaceId: item.spaceId,
                Status: item.status
            }));
    
            const bookings: IScheduleBookings[] = cateringOrdersList.map(item => ({
                Id: item.id,
                Subject: item.spaceName,
                StartTime: DateHelper.fromIsoByNode(item.bookingStart, this.state.buildingId).toJSDate(),
                EndTime: DateHelper.fromIsoByNode(item.bookingEnd, this.state.buildingId).toJSDate(),
                SpaceName: item.spaceName,
                SpaceId: item.spaceId
            }));
    
            const uniqueSpaces = Array.from(new Set(cateringOrdersList.map(item => item.spaceId)));
            const spaceData = uniqueSpaces.map(spaceId => ({ text: spaceId, id: spaceId }));
    
            this.setState({
                scheduleCateringOrders,
                bookingDetails: bookings,
                scheduleOrders: cateringOrdersList,
                spaceData
            });
        } catch (error) 
        {
        }
    }

    private redirectPath(orderId: string): void
    {
        this.props.history.push(`/operational-services-catering/${this.state.buildingId}/catering/${orderId}/edit`)
    }

    private async onSingleDateFilterChanged(e: DateTime): Promise<void> 
    {
        const dateTime = e.startOf('day');
    
        await this.setStateAsync({
            singleDateFilterValue: dateTime.toJSDate(),
        });
    
        const timeZone = this.state.buildingId;

        const startDateTime = dateTime.setZoneByNode(timeZone);
        const payload: IFilter = 
        {
            buildingId: this.state.buildingId,
            startDate: startDateTime.toUTC().toISO(),
            endDate: startDateTime.plus({ days: 1 }).toUTC().toISO(),
        };
    
        this.loadScheduleTasks(payload);
    }
    

    public changeLayout(layout: string | null): void 
    {
        if(layout !== null)
        {
            this.setState({
                listViewType: layout,
            });
        }
    }


    public render(): JSX.Element 
    {

        const layoutOptions: toggleButtonListObject[] =
            [
                { value: 'allSchedule', icon: <FormatListBulletedIcon />, showFilterFields: true },
                { value: 'cateringSchedule', icon: <CalendarTodayIcon />, showFilterFields: true },
            ];

        return (
            <>
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <IbssPageHeader
                            title={this.state.listViewType === "allSchedule" ? this.labels.funcPantryViewHeading_S : this.labels.HubMenuCateringOrders}
                            onLayoutChange={(newLayout) => this.changeLayout(newLayout)}
                            layout={this.state.listViewType}
                            toggleButtonList={layoutOptions}
                            onSingleDateChange={(e) => this.onSingleDateFilterChanged(e)}
                            showSingleDate={true}
                            singleDate={DateTime.fromJSDate(this.state.singleDateFilterValue)}
                        />

                        <Box mt={5}>
                            {this.state.spaceData.length > 0 ?
                                <ScheduleCateringOrder
                                    buildingId={this.state.buildingId}
                                    spaceData={this.state.spaceData}
                                    bookingDetails={this.state.bookingDetails}
                                    scheduleOrders={this.state.scheduleOrders}
                                    cateringOrders={this.state.scheduleCateringOrders}
                                    scheduleDate={this.state.singleDateFilterValue}
                                    loadScheduleTasks={(data: IFilter) => this.loadScheduleTasks(data)}
                                    redirectPath={(orderId: string) => this.redirectPath(orderId)}
                                    listViewType={this.state.listViewType}
                                    buildingStartHrs={this.state.buildingStartHrs}
                                    buildingEndHrs={this.state.buildingEndHrs}
                                />
                                :
                                <Box className='schedule-calender' sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <NoSpaceScheduleViewIcon />
                                </Box>
                            }
                        </Box>
                    </div>
                </div>
            </>
        )
    }
}

interface IProps extends RouterProps { }

export interface IFilter
{
    buildingId: number;
    startDate: string;
    endDate: string;
    statusValue?: string;
    host?: string;
    owner?: string;
}

export interface IScheduleBookings 
{
    Id: number;
    Subject: string;
    StartTime: Date;
    EndTime: Date;
    SpaceName: string;
    SpaceId: string;
}

export interface IScheduleCateringOrders
{
    Id: number;
    Subject: string;
    StartTime: Date;
    EndTime: Date;
    SpaceName: string;
    SpaceId: string;
    Status: string;
}

interface IBookingDetails
{
    nodeId: number;
    bookingId: string;
}

interface IState
{
    buildingId: number;
    scheduleOrders: CateringOrderView[];
    spaceData: {
        text: string,
        id: string,
    }[];
    scheduleCateringOrders: IScheduleCateringOrders[];
    singleDateFilterValue: Date;
    listViewType: string;
    bookingDetails: IScheduleBookings[];
    buildingStartHrs: string;
    buildingEndHrs: string;
}
