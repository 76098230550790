import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Editor } from "slate";
import { Helper } from "../Helper";

export default class BoldButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.helper.toggleBold();
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();
        const isActive = this.helper.isBold();
        const variant = (isActive ? "contained" : undefined);
        const sx = { backgroundColor: (isActive ? "var(--ui-mid-tone)" : undefined) };

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcBold_S}
                variant={variant}
                sx={sx}
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <strong>B</strong>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
}
