import { Component } from 'react';

export default class Focusbooth extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8373 16.9203C19.9496 16.6324 20.227 16.4429 20.536 16.4429H30C30.2435 16.4429 30.4718 16.5611 30.6124 16.7599C30.753 16.9587 30.7883 17.2133 30.7071 17.4429L27.1711 27.4429C27.033 27.8334 26.6045 28.0381 26.214 27.9C25.8235 27.7619 25.6188 27.3334 25.7569 26.9428L28.9393 17.9429H21.0484L18.6987 23.9655C18.5482 24.3514 18.1133 24.5421 17.7274 24.3916C17.3415 24.241 17.1507 23.8062 17.3013 23.4203L19.8373 16.9203Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.75 27.1929C33.75 27.6071 33.4142 27.9429 33 27.9429L13 27.9429C12.5858 27.9429 12.25 27.6071 12.25 27.1929C12.25 26.7787 12.5858 26.4429 13 26.4429L33 26.4429C33.4142 26.4429 33.75 26.7787 33.75 27.1929Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 26.75C24.4142 26.75 24.75 27.0858 24.75 27.5V33C24.75 33.4142 24.4142 33.75 24 33.75C23.5858 33.75 23.25 33.4142 23.25 33V27.5C23.25 27.0858 23.5858 26.75 24 26.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27136 8.75C4.48432 8.75 3.03564 10.1987 3.03564 11.9857C3.03564 13.7728 4.48432 15.2214 6.27136 15.2214C8.05839 15.2214 9.50707 13.7728 9.50707 11.9857C9.50707 10.1987 8.05839 8.75 6.27136 8.75ZM1.53564 11.9857C1.53564 9.37025 3.6559 7.25 6.27136 7.25C8.88682 7.25 11.0071 9.37025 11.0071 11.9857C11.0071 14.6012 8.88682 16.7214 6.27136 16.7214C3.6559 16.7214 1.53564 14.6012 1.53564 11.9857Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.250039 19.8929C0.250039 17.8715 1.87154 16.25 3.8929 16.25H7.23575C9.25711 16.25 10.8786 17.8715 10.8786 19.8929V22.8071H16.6858C18.0643 22.8071 19.2358 23.9786 19.2358 25.3571C19.2358 26.7181 18.1459 27.9071 16.6858 27.9071H13.1292C13.3999 28.2013 13.6436 28.5207 13.858 28.8483C14.7125 30.1543 15.25 31.8037 15.25 33.1928C15.25 33.6071 14.9142 33.9428 14.5 33.9428H1C0.5858 33.9428 0.250019 33.6071 0.25 33.1929L0.250039 19.8929ZM10.1286 27.9071H6.46433C6.05011 27.9071 5.71433 27.5714 5.71433 27.1571V22.0143C5.71433 21.6001 6.05011 21.2643 6.46433 21.2643C6.87854 21.2643 7.21433 21.6001 7.21433 22.0143V26.4071H16.6858C17.2828 26.4071 17.7358 25.9248 17.7358 25.3571C17.7358 24.8071 17.2358 24.3071 16.6858 24.3071H10.1286C9.7144 24.3071 9.37861 23.9714 9.37861 23.5571V19.8929C9.37861 18.6999 8.42868 17.75 7.23575 17.75H3.8929C2.69997 17.75 1.75004 18.6999 1.75004 19.8929V29.4071L1.75 32.4428H13.6871C13.5438 31.5387 13.1616 30.5237 12.6028 29.6696C11.8671 28.5452 10.9715 27.9071 10.1286 27.9071Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H30.0093C32.0834 0.25 33.75 1.93197 33.75 4V33C33.75 33.4142 33.4142 33.75 33 33.75C32.5858 33.75 32.25 33.4142 32.25 33V4C32.25 2.75433 31.2489 1.75 30.0093 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z" />
            </svg>
        )
    }
};
