export class Diff<TItem>
{
    private before: TItem[];
    private after: TItem[];
    private getId: (item: TItem) => string;
    private areEqual: (itemA: TItem, itemB: TItem) => boolean;

    constructor(before: TItem[], after: TItem[], getId: (item: TItem) => string, areEqual: (itemA: TItem, itemB: TItem) => boolean)
    {
        this.before = before;
        this.after = after;
        this.getId = getId;
        this.areEqual = areEqual;
    }

    public execute(): IDiffResult<TItem>
    {
        const beforeMap = new Map(this.before.map(i => [this.getId(i), i]));
        const afterMap = new Map(this.after.map(i => [this.getId(i), i]));

        const createdItems = this.after.filter(i => !beforeMap.has(this.getId(i)));
        const deletedItems = this.before.filter(i => !afterMap.has(this.getId(i)));
        const updatedItemsBefore = this.before.filter(i => afterMap.has(this.getId(i)) && !this.areEqual(i, afterMap.get(this.getId(i))!));
        const updatedItemsAfter = this.after.filter(i => beforeMap.has(this.getId(i)) && !this.areEqual(i, beforeMap.get(this.getId(i))!));

        const isDirty = (createdItems.length > 0 || deletedItems.length > 0 || updatedItemsBefore.length > 0 || updatedItemsBefore.length > 0);

        return {
            createdItems,
            deletedItems,
            updatedItemsBefore,
            updatedItemsAfter,
            isDirty,
        };
    }
}

export interface IDiffResult<TItem>
{
    createdItems: TItem[];
    deletedItems: TItem[];
    updatedItemsBefore: TItem[];
    updatedItemsAfter: TItem[];
    isDirty: boolean;
}
