import { PagedResponse } from "../Models";
import * as GetMany from "./GetManyEndpoint";
import * as GetManyByMenuId from "./GetManyByMenuIdEndpoint";
import * as CreateDelete from "./CreateDeleteEndpoint";

export class CateringMenuItemRepository implements ICateringMenuItemRepository
{
    private getManyEndpoint: GetMany.IGetManyEndpoint;
    private getManyByMenuIdEndpoint: GetManyByMenuId.IGetManyByMenuIdEndpoint;
    private createDeleteEndpoint: CreateDelete.ICreateDeleteEndpoint;

    constructor(
        getManyEndpoint: GetMany.IGetManyEndpoint,
        getManyByMenuIdEndpoint: GetManyByMenuId.IGetManyByMenuIdEndpoint,
        createDeleteEndpoint: CreateDelete.ICreateDeleteEndpoint,
    )
    {
        this.getManyEndpoint = getManyEndpoint;
        this.getManyByMenuIdEndpoint = getManyByMenuIdEndpoint;
        this.createDeleteEndpoint = createDeleteEndpoint;
    }

    public getMany<TResponse>(nodeId: number, top: number, skipToken: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>
    {
        return this.getManyEndpoint.execute(nodeId, top, skipToken, responseType, filter);
    }

    public getManyByMenuId<TResponse>(nodeId: number, menuId: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>
    {
        return this.getManyByMenuIdEndpoint.execute(nodeId, menuId, responseType, filter);
    }

    public createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>
    {
        return this.createDeleteEndpoint.execute(nodeId, menuId, itemIdsToCreate, itemIdsToDelete);
    }
}

export interface ICateringMenuItemRepository
{
    getMany<TResponse>(nodeId: number, top: number, skipToken: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>;
    getManyByMenuId<TResponse>(nodeId: number, menuId: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>;
    createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>;
}

export class CateringMenuItem
{
    public Node_Id = 0;
    public MenuItem_Id = "";
    public Name = "";
    public Description = "";
    public ImageURI = "";
    public NutritionalInformation= "";
    public UnitPrice = 0;
    public OrderPolicy_Id = "";
    public Classification = CateringMenuItemClassification.Null;
    public Status = CateringMenuItemStatus.Null;
}

export class Filter
{
    public classification?: CateringMenuItemClassification;
    public status?: CateringMenuItemStatus;

    constructor(value: Partial<Filter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.classification != null ? `(Classification eq '${this.classification}')` : "");
        filterParts.push(this.status != null ? `(Status eq '${this.status}')` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }

    public toSqlString(): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.classification != null ? `(Classification eq '${this.classification}')` : "");
        filterParts.push(this.status != null ? `(Status eq '${this.status}')` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }
}

export enum CateringMenuItemClassification
{
    Null = "",
    Beverage = "MenuItemClassificationBeverage",
    Snack = "MenuItemClassificationSnack",
    Food = "MenuItemClassificationFood",
}

export enum CateringMenuItemStatus
{
    Null = "",
    Inactive = "StatusInactive",
    Active = "StatusActive",
}
