import { Component } from 'react';

export default class Specialistdesk extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 16.66C4 16.2955 4.34237 16 4.76471 16H29.2353C29.6576 16 30 16.2955 30 16.66V26.34C30 26.7045 29.6576 27 29.2353 27H4.76471C4.34237 27 4 26.7045 4 26.34V16.66ZM5.52941 17.32V25.68H28.4706V17.32H5.52941Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21503 15.4412C7.21503 13.8866 8.40838 12.5385 9.98532 12.5385H13.9559C15.5329 12.5385 16.7262 13.8866 16.7262 15.4412V16.5C16.7262 16.9335 16.3747 17.285 15.9412 17.285C15.5077 17.285 15.1562 16.9335 15.1562 16.5V15.4412C15.1562 14.6567 14.5719 14.1085 13.9559 14.1085H9.98532C9.36937 14.1085 8.78503 14.6567 8.78503 15.4412V16.5C8.78503 16.9335 8.43357 17.285 8.00003 17.285C7.56648 17.285 7.21503 16.9335 7.21503 16.5V15.4412Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80322 8.38235C8.80322 6.63307 10.2213 5.215 11.9706 5.215C13.7199 5.215 15.1379 6.63307 15.1379 8.38235C15.1379 10.1316 13.7199 11.5497 11.9706 11.5497C10.2213 11.5497 8.80322 10.1316 8.80322 8.38235ZM11.9706 6.785C11.0884 6.785 10.3732 7.50016 10.3732 8.38235C10.3732 9.26455 11.0884 9.97971 11.9706 9.97971C12.8528 9.97971 13.5679 9.26455 13.5679 8.38235C13.5679 7.50016 12.8528 6.785 11.9706 6.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.25 8C18.25 7.0335 19.0335 6.25 20 6.25H28C28.9665 6.25 29.75 7.0335 29.75 8V13C29.75 13.9665 28.9665 14.75 28 14.75H20C19.0335 14.75 18.25 13.9665 18.25 13V8ZM20 7.75C19.8619 7.75 19.75 7.86193 19.75 8V13C19.75 13.1381 19.8619 13.25 20 13.25H28C28.1381 13.25 28.25 13.1381 28.25 13V8C28.25 7.86193 28.1381 7.75 28 7.75H20Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 16.25C20 15.8358 20.3358 15.5 20.75 15.5H27.25C27.6642 15.5 28 15.8358 28 16.25C28 16.6642 27.6642 17 27.25 17H20.75C20.3358 17 20 16.6642 20 16.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 27H8V30.9989L7 31V27Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 27H27V30.9989L26 31V27Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29 21.25H5V19.75H29V21.25Z" />
            </svg>
        )
    }
};