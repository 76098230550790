import React, { Component } from 'react'
import { appContext } from "../../../AppContext";
import IbssTextField from "../../Inputs/TextField/IbssTextField";

export default class VisitorTabView extends Component<IProps> {

    private get labels() { return appContext().labels; }



  public render(): JSX.Element
  {
    
    const { onEmailChange, onFirstNameChange, onLastNameChange,onCompanyNameChange,email, errors, firstName, lastName, companyName } = this.props;
    
    return (
    <>
        <div className="mt-1">
            <label className="lable-modal">{this.labels.HubLabelNotMemberOfStaff}</label>
        </div>
        <div className="form-group mt-3 mx-10">
            <div>
                <IbssTextField
                    className="mt-8"
                    fullWidth
                    type="email"
                    name="Email"
                    value={email}
                    onChange={e => onEmailChange(e)}
                    label={this.labels.HubLabelEmailAddress}
                    size="small"
                />
            </div>
            <div className="text-danger">
                {errors}
            </div>
        </div>
        <div className="form-group mx-10">
            <div>
                <IbssTextField
                    className="mt-8"
                    fullWidth
                    type="text"
                    name="First_Name"
                    value={firstName}
                    onChange={e => onFirstNameChange(e)}
                    label={this.labels.HubLabelFirstName}
                    size="small"
                />
            </div>
        </div>
        <div className="form-group mx-10">
            <div>
                <IbssTextField
                    className="mt-8"
                    fullWidth
                    type="text"
                    name="Last_Name"
                    value={lastName}
                    onChange={e => onLastNameChange(e)}
                    label={this.labels.HubLabelLastName}
                    size="small"
                />
            </div>
        </div>
        <div className="form-group mx-10">
            <div>
                <IbssTextField
                    className="mt-8"
                    fullWidth
                    type="text"
                    name="Company"
                    value={companyName}
                    onChange={e => onCompanyNameChange(e)}
                    label={this.labels.HubLabelCompany}
                    size="small"
                />
            </div>
        </div>
    </>
    )
  }
}

export interface IProps
{
    onEmailChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFirstNameChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onLastNameChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCompanyNameChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    email: string;
    errors: string;
    firstName: string;
    lastName: string;
    companyName: string;
}