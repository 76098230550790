import { Component } from 'react';
export default class StandardDesk extends Component {
    render() {
        return (
            <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5595 10.7059L15.7921 16.25C15.9582 16.4412 16.0135 16.6801 16.0135 16.9669C16.0135 17.636 15.4601 18.1618 14.7959 18.3051V18.3529H24.869L28.6326 10.7059H18.5595Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="inherit" />
                <path d="M28.6323 18.353L14.7956 18.353V18.353L28.6323 18.353V18.353Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="inherit" />
                <path d="M20.8491 18.353V22.1765V21.4118V18.353Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="inherit" />
                <path d="M5.51762 9.91935C7.42124 9.91935 8.96443 8.55475 8.96443 6.87145C8.96443 5.18814 7.42124 3.82355 5.51762 3.82355C3.61399 3.82355 2.0708 5.18814 2.0708 6.87145C2.0708 8.55475 3.61399 9.91935 5.51762 9.91935Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="inherit" />
                <path d="M8.8533 18.3256H5.68446V14.3929V18.3256H8.8533ZM8.8533 18.3256H14.5239C15.4134 18.3256 16.0805 17.6866 16.0805 16.9492C16.0805 16.2118 15.3578 15.5727 14.5239 15.5727H8.8533V12.7706C8.8533 11.5416 7.74143 10.5584 6.35158 10.5584H3.46071C2.07086 10.5584 0.958984 11.5416 0.958984 12.7706V20.0462C0.958984 20.7345 1.29255 21.3244 1.84849 21.7177H12.6337C12.6337 19.8496 10.9659 18.3256 8.8533 18.3256Z" stroke="currentColor" fill="inherit" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        )
    }
};