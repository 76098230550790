import { DateTime, MinuteNumbers, WeekdayNumbers } from "luxon";
import { IRootNode } from "../Providers.Api/Models";
import { IWorkingHoursPrefs } from "../Providers.Api/UserPreferences/UserPreferenceRepository";

export class ConfigHelper
{
    public getBuildingConfig(rootNode: IRootNode, buildingId: number): IBuildingConfig
    {
        let buildings = rootNode.Regions.flatMap(i => i.Buildings);

        // get valid minutes
        let selectedBuilding = buildings.filter(i => i.Node_Id == buildingId)[0];
        let allowedMinutes = selectedBuilding?.Bkng_Allowed_Starts?.split(";").map(i => parseInt(i)).filter(i => !isNaN(i));
        let allowedMinutesOrDefault = (allowedMinutes == null || allowedMinutes.length == 0 ? Array.from(Array(60).keys()) : allowedMinutes) as MinuteNumbers[];

        // get working week
        let workingDayStart = parseInt(selectedBuilding?.Occ_Wkng_Days_Stt);
        let workingDayStartOrDefault = Math.min(7, Math.max(1, isNaN(workingDayStart) ? 1 : workingDayStart)) as WeekdayNumbers;

        let workingDayEnd = parseInt(selectedBuilding?.Occ_Wkng_Days_Stp);
        let workingDayEndOrDefault = Math.min(7, Math.max(1, isNaN(workingDayEnd) ? 5 : workingDayEnd)) as WeekdayNumbers;

        return { allowedMinutes: allowedMinutesOrDefault, workingDayStart: workingDayStartOrDefault, workingDayEnd: workingDayEndOrDefault };
    }

    public getWorkingHours(day: DateTime, config: IBuildingConfig, workingHoursPrefs: IWorkingHoursPrefs): IWorkingHours
    {
        // get working hours
        day = day.startOf("day");
        let dayAsString = day.toFormat("yyyy-LL-dd");
        let startOfWorkingDay = DateTime.fromISO(dayAsString + "T" + workingHoursPrefs.UserStartTime);
        let endOfWorkingDay = DateTime.fromISO(dayAsString + "T" + workingHoursPrefs.UserEndTime);
        
        // set defaults
        if (!startOfWorkingDay.isValid)
        {
            startOfWorkingDay = day.set({ hour: 9 });
        }
        if (!endOfWorkingDay.isValid)
        {
            endOfWorkingDay = day.set({ hour: 17 });
        }

        // ensure start < end
        if (startOfWorkingDay >= endOfWorkingDay)
        {
            let temp = startOfWorkingDay;
            startOfWorkingDay = endOfWorkingDay;
            endOfWorkingDay = temp;
        }

        // snap
        startOfWorkingDay = startOfWorkingDay.snapToMinute(config.allowedMinutes, { preserveDate: true });
        endOfWorkingDay = endOfWorkingDay.snapToMinute(config.allowedMinutes, { preserveDate: true, allowStartOfNextDay: true, direction: -1 });

        // done
        return { start: startOfWorkingDay, end: endOfWorkingDay };
    }

    public getDefaultTimes(config: IBuildingConfig, workingHoursPrefs: IWorkingHoursPrefs, snapToWorkingDay: boolean): IDefaultTimes
    {
        let snappedNow = DateTime.now().snapToMinute(config.allowedMinutes);
        if (snapToWorkingDay)
        {
            snappedNow = snappedNow.snapToDay(config.workingDayStart, config.workingDayEnd);
        }
        const workingHours = this.getWorkingHours(snappedNow, config, workingHoursPrefs);

        // set times
        let startTime: DateTime;
        let endTime: DateTime;

        if (snappedNow < workingHours.start)
        {
            startTime = workingHours.start;
            endTime = workingHours.end;
        }
        else
        {
            startTime = workingHours.start.plus({ days: 1 });
            endTime = workingHours.end.plus({ days: 1 });
        }

        // snap to working day
        if (snapToWorkingDay)
        {
            startTime = startTime.snapToDay(config.workingDayStart, config.workingDayEnd);
            endTime = endTime.snapToDay(config.workingDayStart, config.workingDayEnd);
        }

        // update state
        return { start: startTime, end: endTime };
    }
}

export interface IBuildingConfig
{
    allowedMinutes: MinuteNumbers[];
    workingDayStart: WeekdayNumbers;
    workingDayEnd: WeekdayNumbers;
}

export interface IWorkingHours
{
    start: DateTime;
    end: DateTime;
}

export interface IDefaultTimes
{
    start: DateTime;
    end: DateTime;
}
