import  { Component } from 'react';

export default class Stationery extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 17C12.25 16.5858 12.5858 16.25 13 16.25H21C21.4142 16.25 21.75 16.5858 21.75 17C21.75 17.4142 21.4142 17.75 21 17.75H13C12.5858 17.75 12.25 17.4142 12.25 17Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 4C3.25 1.92893 4.92893 0.25 7 0.25H24C26.0711 0.25 27.75 1.92893 27.75 4V7.5C27.75 7.91421 27.4142 8.25 27 8.25C26.5858 8.25 26.25 7.91421 26.25 7.5V4C26.25 2.75736 25.2426 1.75 24 1.75H7C5.75736 1.75 4.75 2.75736 4.75 4V30C4.75 31.2426 5.75736 32.25 7 32.25H24C25.2426 32.25 26.25 31.2426 26.25 30V22.5C26.25 22.0858 26.5858 21.75 27 21.75C27.4142 21.75 27.75 22.0858 27.75 22.5V30C27.75 32.0711 26.0711 33.75 24 33.75H7C4.92893 33.75 3.25 32.0711 3.25 30V4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 33V1H9.75V33H8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 6C12.25 5.58579 12.5858 5.25 13 5.25H23C23.4142 5.25 23.75 5.58579 23.75 6V11C23.75 11.4142 23.4142 11.75 23 11.75H13C12.5858 11.75 12.25 11.4142 12.25 11V6ZM13.75 6.75V10.25H22.25V6.75H13.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8544 9.23744C29.1236 7.96823 31.1814 7.96823 32.4506 9.23744C33.7198 10.5066 33.7198 12.5644 32.4506 13.8336L16.5407 29.7435C16.4302 29.8541 16.2879 29.9273 16.1337 29.953L11.8911 30.6601C11.6521 30.6999 11.4087 30.6219 11.2374 30.4506C11.0662 30.2794 10.9881 30.0359 11.028 29.797L11.7351 25.5544C11.7608 25.4002 11.834 25.2579 11.9445 25.1473L27.8544 9.23744ZM31.39 10.2981C30.7065 9.61468 29.5985 9.61468 28.9151 10.2981L14.0122 25.201C14.3863 25.3331 14.7669 25.5199 15.1228 25.7761C15.7222 26.2077 16.2295 26.8221 16.5142 27.6487L31.39 12.773C32.0734 12.0896 32.0734 10.9815 31.39 10.2981ZM15.2098 28.5863C15.0744 27.7971 14.684 27.3085 14.2463 26.9934C13.8861 26.734 13.4725 26.5789 13.0984 26.4984L12.6802 29.0079L15.2098 28.5863Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.3293 13.8943L27.7938 10.3588L28.8544 9.2981L32.39 12.8336L31.3293 13.8943Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.86596 4.31245C3.4964 4.18705 4.14986 4.25141 4.74372 4.49739C5.33758 4.74338 5.84516 5.15994 6.20228 5.6944C6.55939 6.22886 6.75 6.85721 6.75 7.5C6.75 7.91422 6.41422 8.25 6 8.25C5.58579 8.25 5.25 7.91422 5.25 7.5C5.25 7.15388 5.14737 6.81554 4.95507 6.52775C4.76278 6.23997 4.48947 6.01567 4.1697 5.88321C3.84993 5.75076 3.49806 5.7161 3.15859 5.78363C2.81913 5.85115 2.50731 6.01782 2.26256 6.26256C2.01782 6.50731 1.85115 6.81913 1.78363 7.15859C1.7161 7.49806 1.75076 7.84993 1.88321 8.1697C2.01567 8.48947 2.23997 8.76278 2.52775 8.95507C2.81554 9.14737 3.15388 9.25 3.5 9.25C3.91422 9.25 4.25 9.58579 4.25 10C4.25 10.4142 3.91422 10.75 3.5 10.75C2.85721 10.75 2.22886 10.5594 1.6944 10.2023C1.15994 9.84516 0.743378 9.33758 0.497393 8.74372C0.251408 8.14986 0.187047 7.4964 0.312449 6.86596C0.437851 6.23552 0.747384 5.65643 1.2019 5.2019C1.65643 4.74738 2.23552 4.43785 2.86596 4.31245Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.86596 13.3124C3.4964 13.187 4.14986 13.2514 4.74372 13.4974C5.33758 13.7434 5.84516 14.1599 6.20228 14.6944C6.55939 15.2289 6.75 15.8572 6.75 16.5C6.75 16.9142 6.41422 17.25 6 17.25C5.58579 17.25 5.25 16.9142 5.25 16.5C5.25 16.1539 5.14737 15.8155 4.95507 15.5278C4.76278 15.24 4.48947 15.0157 4.1697 14.8832C3.84993 14.7508 3.49806 14.7161 3.15859 14.7836C2.81913 14.8512 2.50731 15.0178 2.26256 15.2626C2.01782 15.5073 1.85115 15.8191 1.78363 16.1586C1.7161 16.4981 1.75076 16.8499 1.88321 17.1697C2.01567 17.4895 2.23997 17.7628 2.52775 17.9551C2.81554 18.1474 3.15388 18.25 3.5 18.25C3.91422 18.25 4.25 18.5858 4.25 19C4.25 19.4142 3.91422 19.75 3.5 19.75C2.85721 19.75 2.22886 19.5594 1.6944 19.2023C1.15994 18.8452 0.743378 18.3376 0.497393 17.7437C0.251408 17.1499 0.187047 16.4964 0.312449 15.866C0.437851 15.2355 0.747384 14.6564 1.2019 14.2019C1.65643 13.7474 2.23552 13.4379 2.86596 13.3124Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.86596 22.3124C3.4964 22.187 4.14986 22.2514 4.74372 22.4974C5.33758 22.7434 5.84516 23.1599 6.20228 23.6944C6.55939 24.2289 6.75 24.8572 6.75 25.5C6.75 25.9142 6.41422 26.25 6 26.25C5.58579 26.25 5.25 25.9142 5.25 25.5C5.25 25.1539 5.14737 24.8155 4.95507 24.5278C4.76278 24.24 4.48947 24.0157 4.1697 23.8832C3.84993 23.7508 3.49806 23.7161 3.15859 23.7836C2.81913 23.8512 2.50731 24.0178 2.26256 24.2626C2.01782 24.5073 1.85115 24.8191 1.78363 25.1586C1.7161 25.4981 1.75076 25.8499 1.88321 26.1697C2.01567 26.4895 2.23997 26.7628 2.52775 26.9551C2.81554 27.1474 3.15388 27.25 3.5 27.25C3.91422 27.25 4.25 27.5858 4.25 28C4.25 28.4142 3.91422 28.75 3.5 28.75C2.85721 28.75 2.22886 28.5594 1.6944 28.2023C1.15994 27.8452 0.743378 27.3376 0.497393 26.7437C0.251408 26.1499 0.187047 25.4964 0.312449 24.866C0.437851 24.2355 0.747384 23.6564 1.2019 23.2019C1.65643 22.7474 2.23552 22.4379 2.86596 22.3124Z" />
            </svg>
        )
    }
};