import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { CateringMenuItemClassification, CateringMenuItemStatus, Filter } from "./CateringMenuItemRepository";

export class GetManyByMenuIdEndpoint implements IGetManyByMenuIdEndpoint
{
    public async execute<TResponse>(nodeId: number, menuId: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>
    {
        try
        {
            const query =
                `$top=1000&` +
                `$select=${ApiHelper.toODataSelect(responseType)}&` +
                `$filter=${filter?.toSqlString() ?? ""}`;

            const response = await axios.get<IRawPagedResponse<TResponse[]>>(`${apis.allApiv2}${nodeId}/catering-menu/${menuId}/menu-items?${query}`);

            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (error)
        {
            const response = (error as AxiosError).response as AxiosResponse;
            throw ApiError.fromAxiosResponse(response);
        }
    }
}

export interface IGetManyByMenuIdEndpoint
{
    execute<TResponse>(nodeId: number, menuId: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>;
}
