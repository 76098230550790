import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Editor, Node as SlateNode, Text as SlateText, Element as SlateElement, Path } from "slate";
import { Helper } from "../Helper";

export default class CodeBlockButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.helper.toggleCodeBlock();
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();
        const isActive = this.helper.isCodeBlock();
        const variant = (isActive ? "contained" : undefined);
        const sx = { backgroundColor: (isActive ? "var(--ui-mid-tone)" : undefined) };

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcCodeBlock_S}
                variant={variant}
                sx={sx}
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-3 -4 30 30" fill="none">
                    <path d="M7 8L3 11.6923L7 16M17 8L21 11.6923L17 16M14 4L10 20" style={{ stroke: 'var(--ui-text)' }} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
}
