
import Grid from "@mui/material/Grid";
import DonutProgressChart from "../../../../Components/Data/DonutProgressChart/IbssDonutProgressChart";
import {
  donutColor,
  headerSpace_CapaUtiCompare,
  comparisionArrow,
  headerSpaceCapaKpiDiff,
  spaceDiff,
} from "../../../../../src/Common/Helper";
import upArrow from "../../../../assets/images/arrow/Up Green.svg";
import down from "../../../../assets/images/arrow/Down Red.svg";
import rightArrow from "../../../../assets/images/arrow/Flat.svg";
import { appContext } from "../../../../AppContext";
import { RootStateOrAny, useSelector } from "react-redux";

const SpaceAnalyticsRightHeader = (props: {
  CurrentSpaceValue: number;
  CurrentCapacityValue: number;
  CapacityKPITarget: number | undefined;
  SpaceKPITarget: number | undefined;
}) => {
  const {
    CurrentSpaceValue,
    CurrentCapacityValue,
    CapacityKPITarget,
    SpaceKPITarget,
  } = props;
  const labels = appContext().labels;
  const { buildingPeriodType, nodeLevelName } = useSelector(
    (state: RootStateOrAny) => ({
      buildingPeriodType:
        state.onelensSpaceAnalyticsFilterCriteria.buildingPeriodType,
        nodeLevelName: state.onelensDateBuilding.nodeLevelName,
    })
  );
  
  return (
    <>
      <div className="card-result mr-10 p-10 my-6">
        <Grid item xs={12} className="d-flex p-10">
          <div className="d-flex align-items-center">
            <div className="custom-donut-chart">
              <DonutProgressChart
                strokeWidth={7}
                value={CurrentSpaceValue === undefined? 0: CurrentSpaceValue}
                strokeColor={donutColor(CurrentSpaceValue)}
                fontColor={`var(--ui-text)`}
                portionColor={`blue`}
                donutSize={50}
              />
            </div>
            <div className="px-10">
              <h3 className="text-ui-20 my-0">
                {labels.HublabelSpaceUtilization}
              </h3>
              <h5 className="text-ui-14 my-0 pt-10">
                {headerSpace_CapaUtiCompare(
                  CurrentSpaceValue,
                  buildingPeriodType,
                  `${labels.HublabelSimilartolast} ${buildingPeriodType}`
                )}
              </h5>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className="d-flex p-10">
          <div className="kpi-analytics-bg">
            {SpaceKPITarget ? `${SpaceKPITarget}%` : `0%`}
          </div>
          <div className="px-10">
            <h3 className="text-ui-20 my-0" style={{ fontWeight: "400" }}>
              {labels.HublabelKpitaget}
            </h3>

            <h5 className="text-ui-14 pt-10 my-0">
              {headerSpaceCapaKpiDiff(spaceDiff(CurrentSpaceValue,SpaceKPITarget))}
              &nbsp;&nbsp;{Math.abs(spaceDiff(CurrentSpaceValue,SpaceKPITarget))}%
            </h5>
          </div>
          <div className="d-flex align-items-end">
            <img
              src={comparisionArrow(
                spaceDiff(CurrentSpaceValue,SpaceKPITarget),
                upArrow,
                down,
                rightArrow
              )}
              alt={"arrow"}
              height={10}
            />
          </div>
        </Grid>
      </div>

      {/* Header Capacity Utilization Card */}
      <div className="card-result my-6">
        <Grid item xs={12} className="d-flex p-10">
          <div className="d-flex align-items-center">
            <div className="custom-donut-chart">
              <DonutProgressChart
                strokeWidth={7}
                value={CurrentCapacityValue === undefined? 0:CurrentCapacityValue}
                strokeColor={donutColor(CurrentCapacityValue)}
                fontColor={`var(--ui-text)`}
                portionColor={`var(--ui-cleaning-pastel)`}
                donutSize={50}
              />
            </div>
          </div>
          <div className="px-10">
            <h3 className="text-ui-20 my-0">
              {labels.HublabelCapacityUtilization}
            </h3>
            <h5 className="text-ui-14 my-0 pt-10">
              {headerSpace_CapaUtiCompare(
                CurrentCapacityValue,
                buildingPeriodType,
                `${labels.HublabelSimilartolast} ${buildingPeriodType}`
              )}
            </h5>
          </div>
        </Grid>
        <Grid item xs={12} className="d-flex p-10">
          <div className="kpi-analytics-bg">
            {CapacityKPITarget ? `${CapacityKPITarget}%` : `0%`}
          </div>
          <div className="px-10">
            <h3 className="text-ui-20 my-0" style={{ fontWeight: "400" }}>
              {labels.HublabelKpitaget}
            </h3>
            <h5 className="text-ui-14 pt-10 my-0">
              {headerSpaceCapaKpiDiff(spaceDiff(CurrentCapacityValue,CapacityKPITarget))}
              &nbsp;&nbsp;{Math.abs(spaceDiff(CurrentCapacityValue,CapacityKPITarget))}%
            </h5>
          </div>
          <div className="d-flex align-items-end">
            <img
              src={comparisionArrow(
                spaceDiff(CurrentCapacityValue,CapacityKPITarget),
                upArrow,
                down,
                rightArrow
              )}
              alt={"arrow"}
              height={10}
            />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default SpaceAnalyticsRightHeader;
