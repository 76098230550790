import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetBookingPartyEndPoint implements IGetBookingPartyEndPoint
{
    public async execute(nodeId: number, bookingId: string): Promise<IBookingParty[]>
    {
        try
        {
            const response = await axios.get(apis.allApi + nodeId + `/BookingParties?$filter=Booking_Id eq '${bookingId}' and Booking_Participant_Type eq 3`);
            return response.data;
        }
        catch(error)
        {
            throw ApiError.fromAxiosResponse(error as AxiosResponse);
        }
    }
}

export interface IGetBookingPartyEndPoint
{
    execute(nodeId: number, bookingId: string): Promise<IBookingParty[]>
}

export interface IBookingParty
{
    Booking_End: string;
    Booking_Id: string;
    Booking_Participant_CheckedIn: number;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Participant_Type: number;
    Booking_Resource_Id: string;
    Booking_Start: string;
    Booking_Visitor: number;
    Node_Id: number;
    Record_Id: string;
}