import { AxiosError, AxiosResponse } from "axios";

export class ApiError
{
    public statusCode: number;
    public statusText: string;

    constructor(statusCode: number, statusText: string)
    {
        this.statusCode = statusCode;
        this.statusText = statusText;
    }

    public static fromAxiosResponse(value: AxiosResponse)
    {
        return new ApiError(value.status, value.statusText ?? value.data?.Message ?? "");
    }

    public static fromAxiosError(value: AxiosError)
    {
        const response = value.response;
        if (response == null)
        {
            return new ApiError(0, "");
        }
        return ApiError.fromAxiosResponse(response);
    }
}
