import { Grid } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export class Card extends IbssComponent<IProps, IState>
{
    public render(): JSX.Element
    {
        const props = this.props;

        return (
            <div className="table-panel">
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <div className="table-panel-header" style={{ marginLeft: 0 }}>{props.title}</div>
                    </Grid>
                    <Grid item>
                        {props.children}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export interface IProps
{
    title: string;
    children: React.ReactNode;
}

export interface IState
{
}
