import { DateTime } from "luxon";
import { AccumulationChartComponent, ChartComponent, ExportType } from "@syncfusion/ej2-react-charts";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import { appContext } from "../../../AppContext";
import IbssButton from "../../Buttons/Button/IbssButton";
import DownloadImage from "./Download.svg";
import "./Styles.scss";
import { CSSProperties } from "react";

export class ChartDownloadButton extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state = { showOptions: false };
    }

    private handleDownloadClick(): void
    {
        this.setState({ showOptions: !this.state.showOptions });
    }

    private handleOverlayClick(): void
    {
        this.setState({ showOptions: false });
    }

    private handleOverlayWheel(): void
    {
        this.setState({ showOptions: false });
    }

    private handleExportClick(fileType: ExportType): void
    {
        (fileType == 'CSV' && this.props.csv ? this.exportCustomCsv() : this.exportFile(fileType));
        this.setState({ showOptions: false });
    }

    private exportCustomCsv(): void
    {
        const props = this.props;
        if (!props.csv)
        {
            return;
        }
        const blob = new Blob([props.csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = props.filename;
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    }

    private exportFile(fileType: ExportType): void
    {
        const props = this.props;
        if (!props.chartRef.current)
        {
            return;
        }
        props.chartRef.current.exportModule.export(fileType, props.filename);
    }

    public render(): JSX.Element
    {
        const props = this.props;
        const state = this.state;

        return (
            <div
                className='chart-download-button'
                style={props.style}
            >
                <IbssButton
                    onClick={() => this.handleDownloadClick()}
                    className='chart-download-button__download-button'
                >
                    <img src={DownloadImage} />
                </IbssButton>
                {state.showOptions &&
                    <>
                        <div
                            onClick={() => this.handleOverlayClick()}
                            onWheel={() => this.handleOverlayWheel()}
                            className='chart-download-button__overlay'
                        >
                        </div>
                        <div className='chart-download-button__options'>
                            {props.exportTypes.map(exportType =>
                                <IbssButton
                                    onClick={() => this.handleExportClick(exportType)}
                                    style={{ display: 'block', cursor: 'pointer' }}
                                >
                                    {exportType}
                                </IbssButton>
                            )}
                        </div>
                    </>
                }
            </div>
        );
    }
}

export interface IProps
{
    style?: CSSProperties;
    chartRef: React.RefObject<ChartComponent | AccumulationChartComponent>;
    filename: string;
    exportTypes: ExportType[];
    csv?: string;
}

export interface IState
{
    showOptions: boolean;
}
