import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { VisitId } from "./VisitsRepository";

export class ApproveVisitsV2Endpoint implements IApproveVisitsV2Endpoint
{
    public async execute(nodeId: number, visits: VisitId[]): Promise<void>
    {
        try
        {
            const response: AxiosResponse<void> = await axios.post(`${apis.allApiv2}${nodeId}/visits/approve`, visits);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IApproveVisitsV2Endpoint
{
    execute(nodeId: number, visits: VisitId[]): Promise<void>;
}

export interface IApproveVisitV2
{
    Visit_Id: string;
    Visit_Approve_Comment: string;
}