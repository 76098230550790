import { Component } from 'react';

export default class Informalmeeting extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.39844 7.00002C8.39844 4.32144 10.5699 2.15002 13.2484 2.15002H28.619C31.2976 2.15002 33.469 4.32145 33.469 7.00003V16.1475C33.469 18.826 31.2976 20.9975 28.619 20.9975H28.1436V27.058C28.1436 27.3893 27.9511 27.6904 27.6505 27.8294C27.3498 27.9685 26.9958 27.9203 26.7433 27.7058L18.8463 20.9975H13.2484C10.5699 20.9975 8.39844 18.826 8.39844 16.1475V7.00002ZM13.2484 3.85002C11.5087 3.85002 10.0984 5.26033 10.0984 7.00002V16.1475C10.0984 17.8871 11.5087 19.2975 13.2484 19.2975H19.1586C19.3602 19.2975 19.5552 19.3691 19.7089 19.4996L26.4436 25.2206V20.1475C26.4436 19.678 26.8242 19.2975 27.2936 19.2975H28.619C30.3587 19.2975 31.769 17.8872 31.769 16.1475V7.00003C31.769 5.26033 30.3587 3.85002 28.619 3.85002H13.2484Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.149994 11.8117C0.149994 9.13309 2.32141 6.96167 5 6.96167H6.45626C6.92571 6.96167 7.30626 7.34223 7.30626 7.81167C7.30626 8.28111 6.92571 8.66167 6.45626 8.66167H5C3.2603 8.66167 1.84999 10.072 1.84999 11.8117V21.229C1.84999 22.9687 3.2603 24.379 4.99999 24.379H6.07202C6.54146 24.379 6.92202 24.7595 6.92202 25.229V29.9587L12.8675 24.5977C13.0236 24.4569 13.2264 24.379 13.4367 24.379H17.4969C17.9663 24.379 18.3469 24.7595 18.3469 25.229C18.3469 25.6984 17.9663 26.079 17.4969 26.079H13.7633L6.64123 32.5009C6.39183 32.7258 6.0334 32.7828 5.72657 32.6463C5.41974 32.5098 5.22202 32.2055 5.22202 31.8696V26.079H4.99999C2.32141 26.079 0.149994 23.9076 0.149994 21.229V11.8117Z" />
                <path d="M18.1842 11.9359C18.1842 12.6951 17.5687 13.3106 16.8095 13.3106C16.0502 13.3106 15.4348 12.6951 15.4348 11.9359C15.4348 11.1766 16.0502 10.5612 16.8095 10.5612C17.5687 10.5612 18.1842 11.1766 18.1842 11.9359Z" />
                <path d="M22.3085 11.9359C22.3085 12.6951 21.693 13.3106 20.9337 13.3106C20.1745 13.3106 19.559 12.6951 19.559 11.9359C19.559 11.1766 20.1745 10.5612 20.9337 10.5612C21.693 10.5612 22.3085 11.1766 22.3085 11.9359Z" />
                <path d="M26.4327 11.9359C26.4327 12.6951 25.8172 13.3106 25.058 13.3106C24.2988 13.3106 23.6833 12.6951 23.6833 11.9359C23.6833 11.1766 24.2988 10.5612 25.058 10.5612C25.8172 10.5612 26.4327 11.1766 26.4327 11.9359Z" />
            </svg>
        )
    }
};