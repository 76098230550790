import * as React from 'react';
import { Button } from '@mui/material';
import { ButtonProps, ButtonPropsColorOverrides } from '@mui/material/Button';
import { styled, Theme } from '@mui/material/styles';

// button usage.
// change width on the instance
//<IbssButton variant="contained" color="primary" sx={{ width: 100}}> // equivalent to sx={{width: "100px"}}

// 3 CTAs on figma
// Primary CTA = <IbssButton variant="contained" color="primary" >
// Secondary CTA = <IbssButton variant="contained" color="secondary" >
// Destructive CTA = <IbssButton variant="contained" color="error" >

const customColors = (theme: Theme, color: ButtonPropsColorOverrides | undefined)=>{
    // why custom Colors? because Figma design has not followed MUI's unstyled button's styles. custom theme alone is not enough.
    if(color === "primary") return {
        backgroundColor: undefined, // set undefined if MUI button's default behaviour is acceptable.
        color: undefined,
        hoverBackgroundColor: theme.palette.primary.light, 
    }

    if(color === "error") return {
        backgroundColor: undefined,
        color: undefined,
        hoverBackgroundColor: theme.palette.error.light,
    }
    
    if(color ==="secondary") return {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.primary,
        hoverBackgroundColor: theme.palette.background.paper,
    }
    
    // default case, if color isn't any of the above, or isn't passed as props, don't change the default behaviour of the MUI component.
    return {
        backgroundColor: undefined,
        color: undefined,
        hoverBackgroundColor: theme.palette.primary.light,
    }
}


const StyledButton = styled(Button)<ButtonProps>(({ theme, color }) => ({
    '&.MuiButton-root': {
        textTransform: "none",
        backgroundColor: customColors(theme, color).backgroundColor,
        color: customColors(theme, color).color,
    },
    '&.MuiButton-root:hover': {
        color:  theme.palette.text.primary,
        backgroundColor: customColors(theme, color).hoverBackgroundColor,
    },

    '&.Mui-disabled': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[400], //uiMidTone in customTheme grey[400]
    }
  }));

// because class extends, class IbssButtonRedo has access to all props of MUI Button.
// we don't have to define (MUI native) props ahead of time,  we can just write <IbssButton disabled/> 
// but this only works for simple MUI components, not one that is composed of other components. 

class IbssButton extends React.Component<ButtonProps>{ 
    render(): JSX.Element
    {
        return <StyledButton disableFocusRipple {...this.props}/>
    }
}

export default IbssButton;