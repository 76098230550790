import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringOrder } from "./CateringOrderRepository";

export class GetCateringOrdersEditEndpoint implements IGetCateringOrdersEditEndpoint
{
    public async execute(nodeId: number, taskId: string): Promise<ICateringOrder[]>
    {
        try
        {
            const response: AxiosResponse<ICateringOrder[]> = await axios.get(`${apis.allApiv2}${nodeId}/catering-order/${taskId}/edit-order-items`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetCateringOrdersEditEndpoint
{
    execute(nodeId: number, taskId: string): Promise<ICateringOrder[]>;
}
