import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Classification, Status } from "./CateringItemRepository";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(buildingId: number, spaceCateringId: string, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>
    {
        try
        {
            await axios.patch(`${apis.allApiv2}${buildingId}/catering-menuitem/${spaceCateringId}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(buildingId: number, spaceCateringId: string, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>;
}

export interface IUpdateSpaceCateringMenuItemPayload
{
    Node_Id?: number;
    Status: Status;
    ConcurrencyStamp: string;
    Description?: string;
    ImageURI?: string;
    UnitPrice?: number;
    Classification?: Classification | string;
    RetailPrice?: number;
    Name?: string;
    Supplier_Id?: string;
    MenuItem_Id?: string;
    OrderPolicy_Id?: string;
    NutritionalInformation?: string;
}
