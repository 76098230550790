import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Space } from "./SpaceRepository";

export class SearchV1SpaceByIdEndpoint implements ISearchV1SpaceByIdEndpoint
{
    public async execute(nodeId: number, spaceId: string): Promise<Space | null>
    {
        try
        {
            const query =
                '$top=1&' +
                `$filter=Space_Id eq '${spaceId}'`;

            const response = await axios.get<Space[]>(`${apis.allApi}${nodeId}/Spaces/search?${query}`);
            return (response.data[0] ?? null);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ISearchV1SpaceByIdEndpoint
{
    execute(nodeId: number, spaceId: string): Promise<Space | null>;
}
