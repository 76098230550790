import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse } from "../Models";
import { ICateringOrders, CateringOrdersFilter } from "./CateringOrderRepository";
import { DateTime } from "luxon";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(buildingId: number, filter: CateringOrdersFilter): Promise<ICateringOrders[]>
    {
        try
        {
            const query =
                `recursive=true&` +
                `schema=false&` +
                `showDebugStats=false&` +
                `$top=2000&` +
                `$filter=${filter.toODataString()}`;

            const response = await axios.get<IRawPagedResponse<ICateringOrders[]>>(`${apis.allApiv2}${buildingId}/catering-order?${query}`);
            response.data.Value.forEach(i =>
            {
                i.Catering_Service_Time = DateTime.fromISO(i.Catering_Service_Time as unknown as string);
                i.Catering_Clearing_Time = DateTime.fromISO(i.Catering_Clearing_Time as unknown as string);
                i.Booking_Start = DateTime.fromISO(i.Booking_Start as unknown as string);
                i.Booking_End = DateTime.fromISO(i.Booking_End as unknown as string);
                try
                {
                    i.Menu_Items = JSON.parse(i.Menu_Items as unknown as string);
                } catch (error)
                {
                    i.Menu_Items = [];
                }
            });

            return response.data.Value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(buildingId: number, filter: CateringOrdersFilter): Promise<ICateringOrders[]>;
}