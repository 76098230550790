import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";

export class CreateDeleteEndpoint implements ICreateDeleteEndpoint
{
    public async execute(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>
    {
        try
        {
            const payload =
            {
                Add_Ids: itemIdsToCreate,
                Remove_Ids: itemIdsToDelete,
            };
            await axios.patch(`${apis.allApiv2}${nodeId}/catering-menu/${menuId}/roles`, payload);
        }
        catch (error)
        {
            throw ApiError.fromAxiosError(error as AxiosError);
        }
    }
}

export interface ICreateDeleteEndpoint
{
    execute(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>;
}
