import { MDBDataTable } from "mdbreact";
import { appContext } from "../../../../AppContext";
import apis from "../../../../Providers.Api/apis";
import { MessageSeverity } from "../../../../Components/Layout/Messages/Messages";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export default class DataPermissions extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            dataSecurityRole: [{
                id: 0,
                recursiveNodes: true,
                eventTypeName: '',
                create: 0,
                read: 0,
                update: 0,
                delete: 0,
                lock: 0,
                unlock: 0,
                append: 0,
                appendTo: 0,
                share: 0,
            }],
        }
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadPermissions();
    }

    private async loadPermissions(): Promise<void>
    {
        const cirlceClassNames = {
            0: "circle-icon circle-none-icon",
            1: "circle-icon circle-quarter-icon",
            2: "circle-icon circle-half-icon",
            3: "circle-icon circle-three-quarter-icon",
            4: "circle-icon circle-full-icon",
        };

        try
        {
            const res = await apis.getRoleDataSecurityById(this.props.id);
            res.data.sort(this.getSortOrder("eventTypeName"));
            if (res.data.length !== 0)
            {
                let divClasses: any = cirlceClassNames;
                for (let j = 0; j < res.data.length; j++)
                {
                    res.data[j] = {
                        ...res.data[j],
                        'createStatus': <div className={divClasses[res.data[j].create]} onClick={() => { this.updatePermissions(res.data[j].create, j, res.data[j].id, res.data[j].eventTypeId, 'create') }}></div>,
                        'readStatus': <div className={divClasses[res.data[j].read]} onClick={() => { this.updatePermissions(res.data[j].read, j, res.data[j].id, res.data[j].eventTypeId, 'read') }}></div>,
                        'updateStatus': <div className={divClasses[res.data[j].update]} onClick={() => { this.updatePermissions(res.data[j].update, j, res.data[j].id, res.data[j].eventTypeId, 'update') }}></div>,
                        'deleteStatus': <div className={divClasses[res.data[j].delete]} onClick={() => { this.updatePermissions(res.data[j].delete, j, res.data[j].id, res.data[j].eventTypeId, 'delete') }}></div>,
                        'lockStatus': <div className={divClasses[res.data[j].lock]} onClick={() => { this.updatePermissions(res.data[j].lock, j, res.data[j].id, res.data[j].eventTypeId, 'lock') }}></div>,
                        'unlockStatus': <div className={divClasses[res.data[j].unlock]} onClick={() => { this.updatePermissions(res.data[j].unlock, j, res.data[j].id, res.data[j].eventTypeId, 'unlock') }}></div>,
                        'shareStatus': <div className={divClasses[res.data[j].share]} onClick={() => { this.updatePermissions(res.data[j].share, j, res.data[j].id, res.data[j].eventTypeId, 'share') }}></div>,
                    }
                }

                for (let i = 0; i < res.data.length; i++)
                {
                    const permissions = res.data[i].eventTypeName;
                    this.props.onPermissionsLoad(permissions);
                }

            }

            this.setState({ dataSecurityRole: res.data });
            this.props.onLoad();
        }
        catch
        {
        }
    }

    private getSortOrder(prop: any)
    {
        return function (obj1: any, obj2: any)
        {
            if (obj1[prop] > obj2[prop])
            {
                return 1;
            } else if (obj1[prop] < obj2[prop])
            {
                return -1;
            }
            return 0;
        }
    }

    private async updatePermissions(e: any, i: number, idRole: number, eventTypeId: number, property: string): Promise<void>
    {
        if (this.props.isUpdateRight === true)
        {
            const { history }: any = this.props;
            let dataSecure: any = [...this.state.dataSecurityRole]
            let dataSecured: any = { ...dataSecure[i] }
            if (property === 'create') { if (dataSecured.create === 4) { dataSecured.create = 0 } else { dataSecured.create += 1 } }
            if (property === 'read') { if (dataSecured.read === 4) { dataSecured.read = 0 } else { dataSecured.read += 1 } }
            if (property === 'update') { if (dataSecured.update === 4) { dataSecured.update = 0 } else { dataSecured.update += 1 } }
            if (property === 'delete') { if (dataSecured.delete === 4) { dataSecured.delete = 0 } else { dataSecured.delete += 1 } }
            if (property === 'lock') { if (dataSecured.lock === 4) { dataSecured.lock = 0 } else { dataSecured.lock += 1 } }
            if (property === 'unlock') { if (dataSecured.unlock === 4) { dataSecured.unlock = 0 } else { dataSecured.unlock += 1 } }
            if (property === 'share') { if (dataSecured.share === 4) { dataSecured.share = 0 } else { dataSecured.share += 1 } }

            dataSecure[i] = dataSecured;
            this.setState({ dataSecurityRole: dataSecure })

            let payload = [{
                "id": idRole,
                "eventTypeId": eventTypeId,
                "nodeIds": "1",
                "recursiveNodes": true,
                "create": dataSecure[i].create,
                "read": dataSecure[i].read,
                "update": dataSecure[i].update,
                "delete": dataSecure[i].delete,
                "lock": dataSecure[i].lock,
                "unlock": dataSecure[i].unlock,
                "append": 0,
                "appendTo": 0,
                "share": dataSecure[i].share
            }];

            try
            {
                await apis.updateRoleData(this.props.id, payload);
                this.loadPermissions();
            }
            catch
            {
            }
        }
    }

    public render(): JSX.Element
    {
        const dataDataSecurityRole = {
            columns: [{
                label: this.labels.HubLabeleventTypeCol,
                field: "eventTypeName",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelCreateCol,
                field: "createStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelReadCol,
                field: "readStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelUpdateCol,
                field: "updateStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelDeleteCol,
                field: "deleteStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelLockCol,
                field: "lockStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelUnlockCol,
                field: "unlockStatus",
                sort: "disabled",
                width: 50
            },
            {
                label: this.labels.HubLabelShareCol,
                field: "shareStatus",
                sort: "disabled",
                width: 50
            }],
            rows: this.state.dataSecurityRole,
        };

        return (
            <div className="main-content">
                {this.props.loading || (
                    <div className="row">
                        <MDBDataTable
                            className="customsizeRoleInnerCol7 tableDataSecurity"
                            paging={false}
                            searching={false}
                            data={dataDataSecurityRole}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export interface IProps
{
    id: string;
    loading: boolean;
    isUpdateRight: boolean;
    onPermissionsLoad: (permissions: any) => void;
    onLoad: () => void;
}

export interface IState
{
    dataSecurityRole: IEntityPermissions[];
}

export interface IEntityPermissions
{
    id: number;
    recursiveNodes: boolean;
    eventTypeName: string;
    create: number;
    read: number;
    update: number;
    delete: number;
    lock: number;
    unlock: number;
    append: number;
    appendTo: number;
    share: number;
}
