import { Component } from 'react';
export default class MapPinIcon extends Component {
    render() {
        return (
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 1.23531C7.15758 1.23531 3.35277 4.91003 3.35277 9.49021C3.35277 10.61 3.73173 12.0078 4.3748 13.5026C5.01407 14.9885 5.89693 16.5303 6.86362 17.926C7.83121 19.3231 8.8719 20.5582 9.8199 21.4373C10.2943 21.8773 10.7346 22.2179 11.1213 22.4455C11.5176 22.6787 11.8096 22.7647 11.9998 22.7647C12.1901 22.7647 12.4821 22.6787 12.8783 22.4455C13.265 22.2179 13.7053 21.8773 14.1798 21.4373C15.1278 20.5582 16.1684 19.3231 17.136 17.926C18.1027 16.5303 18.9856 14.9885 19.6249 13.5026C20.2679 12.0078 20.6469 10.61 20.6469 9.49021C20.6469 4.91003 16.8421 1.23531 11.9998 1.23531ZM2.29395 9.49021C2.29395 4.28215 6.61687 0.176483 11.9998 0.176483C17.3828 0.176483 21.7057 4.28215 21.7057 9.49021C21.7057 10.8174 21.2653 12.3687 20.5975 13.921C19.9258 15.4822 19.0062 17.0855 18.0065 18.5289C17.0077 19.971 15.9181 21.2693 14.8997 22.2137C14.391 22.6855 13.8891 23.0792 13.4154 23.358C12.9514 23.6311 12.465 23.8235 11.9998 23.8235C11.5346 23.8235 11.0482 23.6311 10.5842 23.358C10.1106 23.0792 9.60868 22.6855 9.09993 22.2137C8.08153 21.2693 6.99198 19.971 5.99318 18.5289C4.99348 17.0855 4.07382 15.4822 3.40217 13.921C2.73431 12.3687 2.29395 10.8174 2.29395 9.49021Z" fill="#263238"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 6.17647C9.95336 6.17647 8.29418 7.83565 8.29418 9.88235C8.29418 11.9291 9.95336 13.5882 12.0001 13.5882C14.0468 13.5882 15.7059 11.9291 15.7059 9.88235C15.7059 7.83565 14.0468 6.17647 12.0001 6.17647ZM7.23535 9.88235C7.23535 7.25088 9.36858 5.11765 12.0001 5.11765C14.6315 5.11765 16.7648 7.25088 16.7648 9.88235C16.7648 12.5138 14.6315 14.6471 12.0001 14.6471C9.36858 14.6471 7.23535 12.5138 7.23535 9.88235Z" fill="#263238"/>
            </svg>
        )
    }
};
