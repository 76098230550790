import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { BookingPartyFilter, IBookingParties } from "./BookingPartyRepository";
import { DateHelper } from "../../Common/DateHelper";
import { IRawPagedResponse } from "../Models";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(nodeId: number, bookingPartyFilter: BookingPartyFilter, recursive?:boolean): Promise<IBookingParties[]>
    {   
        try
        {   
            let query = `$filter=${bookingPartyFilter.toODataString()}`;
            if (recursive) 
            {
               query += "&recursive=true";
            }
            
            const response = await axios.get<IRawPagedResponse<IBookingParties[]>>(`${apis.allApiv2}${nodeId}/bookingparties?${query}`);   
            response.data.Value.forEach(i =>
                {
                    i.Booking_Start = DateHelper.fromIso(i.Booking_Start as unknown as string);
                    i.Booking_End = DateHelper.fromIso(i.Booking_End as unknown as string);
                });
            return response.data.Value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(nodeId: number, bookingPartyFilter: BookingPartyFilter, recursive?: boolean): Promise<IBookingParties[]>;
}




