import axios, { AxiosError, AxiosRequestConfig } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";

export class DeleteManyEndpoint implements IDeleteManyEndpoint
{
    public async execute(delegateIds: string[]): Promise<void>
    {
        try
        {
            await axios.delete(`${apis.userApiBaseUrl}/api/user/v1/Delegates`, { data: delegateIds });
        }
        catch (error)
        {
            throw ApiError.fromAxiosError(error as AxiosError);
        }
    }
}

export interface IDeleteManyEndpoint
{
    execute(delegateIds: string[]): Promise<void>;
}
