import { Component } from "react";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import Spinner from "../../../Components/Navigation/LoadingSpinner/Spinner";
import { AxiosResponse } from "axios";
import { Link, withRouter } from "react-router-dom";
import { RouterProps } from "react-router-dom";
import { appContext } from "../../../AppContext";
import IbssOnBoardingPopup from "./IbssOnboardingPopup";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Modal } from "react-bootstrap";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";

export default class OnBoardingSetUp extends Component<IOnBoardingSetUp, State>
{

    private labels = appContext().labels;
    private incrementActiveStepProp;

    constructor(props: IOnBoardingSetUp) {
        super(props);
        this.incrementActiveStepProp = props.incrementActiveStep;
        this.state = new State();
    }

   
      

    public componentDidMount() {

    }

    public render(): JSX.Element {

        return (<>
        <div className="px-50">
            <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelSetUp}</h2>
            <p className="ibss-modal-text-md"> {this.labels.HubLabelNavigateWorkspaces}</p>
            <div>
            <IbssButton
                color="primary" variant="contained" size="large"
                onClick={() => {
                    this.incrementActiveStepProp();
                }}
            > {this.labels.HubLabelDoThis}</IbssButton>
            </div>
            <div>
            {/* <div className="my-16 primary-link" onClick={this.props.closePopup}> {this.labels.HubLabelDoItLater} </div> */}
            </div>
            <div className="ibss-modal-text-sm mt-1">{this.labels.HubLabelPreferences} <strong>{this.labels.HubLabelPINMenu}</strong> {this.labels.HubLabelMenu}</div>
            </div>
        </>);

    }
}

class State {
   
};

export interface IOnBoardingSetUp {
    incrementActiveStep?: any;
    closePopup?: () => void;
   
}


