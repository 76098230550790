import { Component } from 'react';

export default class Coatstorage extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2998 15.8629C14.3226 13.6018 18.4546 13.5342 21.5498 15.6951L32.8225 23.5651C34.2264 24.5452 33.5329 26.75 31.8207 26.75H2.00637C0.32348 26.75 -0.389461 24.6067 0.958146 23.5987L11.2998 15.8629ZM20.6911 16.925C18.1266 15.1345 14.7029 15.1906 12.1983 17.064L1.85662 24.7998C1.66411 24.9438 1.76595 25.25 2.00637 25.25H31.8207C32.0653 25.25 32.1644 24.935 31.9639 24.795L20.6911 16.925Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 7.75C15.5462 7.75 14.75 8.54105 14.75 9.54545C14.75 9.95967 14.4142 10.2955 14 10.2955C13.5858 10.2955 13.25 9.95967 13.25 9.54545C13.25 7.73822 14.6924 6.25 16.5 6.25C18.3076 6.25 19.75 7.73822 19.75 9.54545C19.75 11.0942 18.6907 12.4087 17.25 12.753V14C17.25 14.4142 16.9142 14.75 16.5 14.75C16.0858 14.75 15.75 14.4142 15.75 14V11.3409H16.5C17.4538 11.3409 18.25 10.5499 18.25 9.54545C18.25 8.54105 17.4538 7.75 16.5 7.75Z" />
            </svg>
        )
    }
};