import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ISpaceCateringMenuItem } from "./GetManyEndpoint";


export class GetByIdEndpoint implements IGetByIdEndpoint
{
    public async execute(buildingId: number,cateringMenuItemId :string): Promise<ISpaceCateringMenuItem>
    {
        try
        {
            const response = await axios.get<ISpaceCateringMenuItem>(`${apis.allApiv2}${buildingId}/catering-menuitem/${cateringMenuItemId }`);

            return response.data;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByIdEndpoint
{
    execute(buildingId: number,cateringMenuItemId :string): Promise<ISpaceCateringMenuItem>;
}