import { Component } from 'react'
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import IbssNotificationCard from '../../../../Components/Cards/NotificationCard/IbssNotificationCard'
import { NotificationChipProps, NotificationListProps } from './FlexNotificationProps'
import NoNotificationPage from './NoNotificationPage';

type myNotificationList = NotificationListProps[]

export default class BuildingNotifications extends Component<{ buildingNotificationList: myNotificationList, setNotificationDetails: (value:NotificationListProps ) => void, ChipList:any  }> {
    render() {
        const { buildingNotificationList, ChipList } = this.props;
        return (
            <div>
              {/* {notificationChip} */}
              {ChipList.map((item: any) =>
                    <IbssChip
                        key={item.label}
                        label={item.label}
                        onClick={() => item.onClick("Building")}
                        className={item.className}
                    />
                )}
                <div className="v-scroll-notification">
                {buildingNotificationList.length > 0 && buildingNotificationList?.map((item: NotificationListProps) => (
                    <IbssNotificationCard
                        key={item.Notification_Id}
                        imageSrc={item.ImageURI}
                        notificationHeading={item.Notification_Title}
                        notificationSubClassification={item.Subclassification}
                        notificationSubDescription={item.Notification_Short_Message}
                        priority={item.Notification_Priority}
                        availableFrom={item.Available_From}
                        read={item.read}
                        elevation={0}
                        onClick={() => this.props.setNotificationDetails({
                          ...item,
                          read: true
                        })}
                    />
                ))}
                {buildingNotificationList.length === 0 && (
                    <NoNotificationPage />
                )}
                </div>
            </div>
        )
    }
}
