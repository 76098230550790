export class Pager<TItem>
{
    public pageSize = 20;
    public skipToken: string | null = null;

    public items: TItem[] = [];
    public getItems: (skipToken: string | null, pageSize: number) => Promise<IBatch<TItem>> = () => Promise.resolve({ skipToken: '', items: [] });

    private _pageIndex = 0;
    public get pageIndex() { return this._pageIndex; }
    public set pageIndex(value: number) { this._pageIndex = Math.max(0, Math.min(this.lastPageIndex, value)); }
    public get isFirstPage() { return this._pageIndex <= 0; }
    public get isLastPage() { return this._pageIndex >= this.lastPageIndex; }

    public get lastPageIndex(): number
    {
        if (this.items.length == 0)
        {
            return 0;
        }
        const lastPageIndex = Math.floor((this.items.length - 1) / this.pageSize);
        const noMoreBatches = (this.skipToken == '');
        return (noMoreBatches ? lastPageIndex : lastPageIndex + 1);
    }

    public async getCurrentPage(): Promise<TItem[]>
    {
        const pageSize = this.pageSize;
        let pageIndex = this._pageIndex;
        let skipToken = this.skipToken;
        let items = this.items;
        let currentPage: TItem[];

        while (true)
        {
            currentPage = items.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
            const isCompletePage = currentPage.length >= pageSize;
            const isPartialPage = currentPage.length > 0 && currentPage.length < pageSize;
            const isMoreData = skipToken != '';
            const isFirstPage = pageIndex == 0;

            if (isCompletePage)
            {
                break;
            }
            else if (isPartialPage && !isMoreData)
            {
                break;
            }
            else if (isMoreData)
            {
                const batch = await this.getItems(skipToken, pageSize);
                skipToken = batch.skipToken;
                items.push(...batch.items);
            }
            else if (isFirstPage)
            {
                break;
            }
            else
            {
                pageIndex--;
            }
        }

        this._pageIndex = pageIndex;
        this.skipToken = skipToken;
        return currentPage;
    }

    public clearItems(): void
    {
        this._pageIndex = 0;
        this.skipToken = null;
        this.items = [];
    }
}

export interface IBatch<TItem>
{
    skipToken: string;
    items: TItem[];
}
