import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ITasksFilter } from "./TaskRepository";

export class ExportTasksEndpoint implements IExportTasksEndpoint
{
    public async execute(buildingId: number, filter: ITasksFilter): Promise<void>
    {
        try
        {
            const outputFilename = `Tasks-Export-${DateTime.now().toISO()}.xlsx`;

            let filterParts = new Array<string>();
            filterParts.push(filter.taskCategory ? `Task_Category_Name eq '${filter.taskCategory}'` : "");
            filterParts.push(filter.status ? `Task_Status eq '${filter.status}'` : "");
            filterParts.push(filter.createdFrom ? `CreatedAt ge datetime'${filter.createdFrom.toUTC().toISO()}'` : "");
            filterParts.push(filter.createdTo ? `CreatedAt le datetime'${filter.createdTo.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskDueFrom ? `Task_Due_Date ge datetime'${filter.taskDueFrom.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskDueTo ? `Task_Due_Date le datetime'${filter.taskDueTo.toUTC().toISO()}'` : "");
            const filterString = filterParts.filter(i => i !== "").join(" and ");

            const query =
                `$select=Task_Id,Task_Type_Name,CreatedAt,Task_Description,Task_Category_Name,Space_Id,Task_Priority,Task_Due_Date,Task_Owner,Task_Status,Task_IsActive,Task_IsOwned,Cost_Code_Allocation&` +
                `$top=2000&` +
                `$filter=${filterString}`;

            const response: AxiosResponse<string | ArrayBuffer | ArrayBufferView | Blob> = await axios.get(`${apis.reportingApiV1}${buildingId}/Tasks/download?${query}`, { responseType: 'blob' });
            fileDownload(response.data, outputFilename);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IExportTasksEndpoint
{
    execute(buildingId: number, filter: ITasksFilter): Promise<void>;
}

