import { Component } from 'react';

export default class Lockers extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 2C11.25 1.0335 12.0335 0.25 13 0.25H21C21.9665 0.25 22.75 1.0335 22.75 2V32C22.75 32.9665 21.9665 33.75 21 33.75H13C12.0335 33.75 11.25 32.9665 11.25 32V2ZM13 1.75C12.8619 1.75 12.75 1.86193 12.75 2V32C12.75 32.1381 12.8619 32.25 13 32.25H21C21.1381 32.25 21.25 32.1381 21.25 32V2C21.25 1.86193 21.1381 1.75 21 1.75H13Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 2C1.25 1.0335 2.0335 0.25 3 0.25H11C11.9665 0.25 12.75 1.0335 12.75 2V32C12.75 32.9665 11.9665 33.75 11 33.75H3C2.0335 33.75 1.25 32.9665 1.25 32V2ZM3 1.75C2.86193 1.75 2.75 1.86193 2.75 2V32C2.75 32.1381 2.86193 32.25 3 32.25H11C11.1381 32.25 11.25 32.1381 11.25 32V2C11.25 1.86193 11.1381 1.75 11 1.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 2C21.25 1.0335 22.0335 0.25 23 0.25H31C31.9665 0.25 32.75 1.0335 32.75 2V32C32.75 32.9665 31.9665 33.75 31 33.75H23C22.0335 33.75 21.25 32.9665 21.25 32V2ZM23 1.75C22.8619 1.75 22.75 1.86193 22.75 2V32C22.75 32.1381 22.8619 32.25 23 32.25H31C31.1381 32.25 31.25 32.1381 31.25 32V2C31.25 1.86193 31.1381 1.75 31 1.75H23Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 4.25C14 3.83579 14.3358 3.5 14.75 3.5L19.25 3.5C19.6642 3.5 20 3.83579 20 4.25C20 4.66421 19.6642 5 19.25 5L14.75 5C14.3358 5 14 4.66421 14 4.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4.25C4 3.83579 4.33579 3.5 4.75 3.5L9.25 3.5C9.66421 3.5 10 3.83579 10 4.25C10 4.66421 9.66421 5 9.25 5L4.75 5C4.33579 5 4 4.66421 4 4.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 4.25C24 3.83579 24.3358 3.5 24.75 3.5L29.25 3.5C29.6642 3.5 30 3.83579 30 4.25C30 4.66421 29.6642 5 29.25 5L24.75 5C24.3358 5 24 4.66421 24 4.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 6.25C14 5.83579 14.3358 5.5 14.75 5.5L19.25 5.5C19.6642 5.5 20 5.83579 20 6.25C20 6.66421 19.6642 7 19.25 7L14.75 7C14.3358 7 14 6.66421 14 6.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.25C4 5.83579 4.33579 5.5 4.75 5.5L9.25 5.5C9.66421 5.5 10 5.83579 10 6.25C10 6.66421 9.66421 7 9.25 7L4.75 7C4.33579 7 4 6.66421 4 6.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 6.25C24 5.83579 24.3358 5.5 24.75 5.5L29.25 5.5C29.6642 5.5 30 5.83579 30 6.25C30 6.66421 29.6642 7 29.25 7L24.75 7C24.3358 7 24 6.66421 24 6.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8.25C14 7.83579 14.3358 7.5 14.75 7.5L19.25 7.5C19.6642 7.5 20 7.83579 20 8.25C20 8.66421 19.6642 9 19.25 9H14.75C14.3358 9 14 8.66421 14 8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8.25C4 7.83579 4.33579 7.5 4.75 7.5L9.25 7.5C9.66421 7.5 10 7.83579 10 8.25C10 8.66421 9.66421 9 9.25 9H4.75C4.33579 9 4 8.66421 4 8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 8.25C24 7.83579 24.3358 7.5 24.75 7.5L29.25 7.5C29.6642 7.5 30 7.83579 30 8.25C30 8.66421 29.6642 9 29.25 9H24.75C24.3358 9 24 8.66421 24 8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 14C15.1642 14 15.5 14.3358 15.5 14.75L15.5 20.25C15.5 20.6642 15.1642 21 14.75 21C14.3358 21 14 20.6642 14 20.25L14 14.75C14 14.3358 14.3358 14 14.75 14Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 14C5.16421 14 5.5 14.3358 5.5 14.75L5.5 20.25C5.5 20.6642 5.16421 21 4.75 21C4.33579 21 4 20.6642 4 20.25L4 14.75C4 14.3358 4.33579 14 4.75 14Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.75 14C25.1642 14 25.5 14.3358 25.5 14.75L25.5 20.25C25.5 20.6642 25.1642 21 24.75 21C24.3358 21 24 20.6642 24 20.25L24 14.75C24 14.3358 24.3358 14 24.75 14Z" />
            </svg>
        )
    }
};