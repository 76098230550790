import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";

export class CreateNotificationEndpoint implements ICreateNotificationEndpoint
{
    public async execute(nodeId: number, payload: ICreateNotification): Promise<void>
    {
        try
        {
            await axios.post(`${apis.allApi}${nodeId}/Notifications`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateNotificationEndpoint
{
    execute(nodeId: number, payload: ICreateNotification): Promise<void>
}

export interface ICreateNotification
{
    ImageURI: string,
    Notification_Title: string,
    Notification_Priority: number,
    Classification: string,
    SubClassfication: string,
    Notification_Message: string,
    Notification_Short_Message: string,
    Available_From: string,
    Expiry_Date: string,
    Notification_Type: number,
    Notification_Status: string,
    Notification_User_Email: string
  }
