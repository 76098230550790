import * as React from 'react';
import { DataGrid, DataGridProps, deDE, zhCN, esES, jaJP, enUS, GridLocaleText, GridPaginationModel, GridFilterModel, GridSortModel, GridColDef, GridValidRowModel, GridActionsColDef, GridPagination } from "@mui/x-data-grid";
import { ComponentHelper } from '../../../Common/ComponentHelper';
import { DataGridText } from '../../../Providers.Text/DataGridText';

export default class IbssDataGrid extends React.Component<IProps, IState>
{
    private defaultPageSizeOptions = [ 25, 50, 100 ];
    private dataGridText = new DataGridText();

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            pageSizeOptions: (this.props.pageSizeOptions as number[] ?? this.defaultPageSizeOptions),
        };
    }

    public render(): JSX.Element
    {
        return (
            <div style={{ height: this.props.height ? this.props.height : "calc(100vh - 370px)", width: "100%" }}>
                <DataGrid
                    localeText={this.dataGridText.getLocaleText()}
                    {...this.props}
                    pageSizeOptions={this.state.pageSizeOptions}
                />
            </div>
        );
    }
}
                   
export interface IProps<R extends GridValidRowModel = any> extends DataGridProps<R>
{
    height?: string;
    columns: IIbssGridColDef[];
}

export type IIbssGridColDef<R extends GridValidRowModel = any, V = any, F = V> = GridColDef<R, V, F>

export interface IState
{
    pageSizeOptions: number[],
}
