import React from "react";
import { Box, Grid, RadioGroup, Stack, Typography } from "@mui/material";
import { appContext } from "../../../../AppContext";
import MediaSelector, { IFile } from "../../../../Components/DialogLaunchers/MediaSelector/MediaSelector";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import AddIcon from "../../../../Components/Icons/AddIcon";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import Helper from "../../../../Common/Helper";
import IbssCheckBox from "../../../../Components/Inputs/CheckBox/IbssCheckBox";
import TagOption from './TagOption';
import "./EditTags.scss";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay";
import ListTags from "../List/ListTags";
import { ITagDetails } from "../../../../Providers.Api/Tags/GetTagsEndpoint";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import IbssToolTip from "../../../../Components/Miscellaneous/Tooltip/IbssToolTip";
import InfoIcon from "../../../../Components/Icons/InfoIcon";

export default class EditTags extends IbssPage<IProps, IState> {
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }


    constructor(props: IProps) 
    {
        super(props);

        this.state =
        {
            images: [],
            isLoading: false,
            tagType: 'TagTypeSimple',
            imageUrl: '',
            tagName: '',
            buildingId: this.appState.buildingId,
            description: '',
            status: 'StatusInactive',
            options: [{ optionName: '', optionImageUrl: '' }],
            layout: 'List',
            tagId: '',
            concurrencyStamp: '',
            tagCount: 0,
            tagNodeId: 0
        };

    }

    public async componentDidMount(): Promise<void> 
    {
        this.pageTitle = this.labels.funcTagManagement_S;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.getImages();
    }

    private async getImages(): Promise<void> 
    {
        try 
        {
            const tagFiles = await this.apiClient.files.getFiles('c/Tags');
            const tagFilesWithPath = tagFiles.map((item: IFile) => {
                let newItem = { ...item }
                newItem.path = `https://storage.ibss.${Helper.environment}/images/c/Tags/${item.name}`
                return newItem
            })
            this.setState({ images: tagFilesWithPath });
        }
        catch (error) 
        {

        }
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void> {
        try 
        {
            this.setState({ isLoading: true });
            await this.apiClient.files.uploadFile(file, 'c/Tags/' + filename, processAsMap);
            this.getImages();
        } finally 
        {
            this.setState({ isLoading: false });
        }
    }

    private tagTypeChanged(event: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({
            tagType: event.target.value
        });
    }

    private tagNameChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
    {
        this.setState({ tagName: event.target.value });

    }

    private tagDescriptionChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
    {
        this.setState({ description: event.target.value });
    }

    private statusChanged(e: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({
            status: e.target.checked ? "StatusActive" : "StatusInactive"
        });
    };

    private optionNameChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void 
    {
        const { options } = this.state;
        options[index].optionName = event.target.value;
        this.setState({ options });
    }

    private optionImageSelectedChanged(url: string, index: number): void 
    {
        const { options } = this.state;
        options[index].optionImageUrl = url;
        this.setState({ options });
    }

    private addAnotherOption(): void 
    {
        const { options } = this.state;
        this.setState({
            options: [...options, { optionName: '', optionImageUrl: '' }]
        });
    }

    private removeOption(index: number): void 
    {
        const { options } = this.state;
        options.splice(index, 1);
        this.setState({ options });
    }

    private changeLayout(layout: string): void 
    {
        if (layout === 'Create'|| layout === 'List') 
        {
            this.setState({
                tagType: 'TagTypeSimple',
                tagName: '',
                imageUrl: '',
                description: '',
                status: 'StatusInactive',
                options: [{ optionName: '', optionImageUrl: '' }],
                tagId: '',
                tagNodeId: 0
            });
        }
        this.setState({
            layout: layout
        });
    }

    private async saveTag(): Promise<void> 
    {
        try 
        {
            this.setState({ isLoading: true })
            const optionsImageUrl = this.state.options.map(option => `${option.optionImageUrl}`).join(';');
            const optionsName = this.state.options.map(option => `${option.optionName}`).join(';');
            
            const commonPayload = {
                TagType: this.state.tagType,
                Name: this.state.tagName,
                Node_Id: this.state.buildingId,
                Status: this.state.status,
                Description: this.state.description,
            };

            let payload;
            if (this.state.tagType === "TagTypeComplex") 
            {
                payload = {
                    ...commonPayload,
                    Values: optionsName,
                    Icon: optionsImageUrl
                };
            }
            else if (this.state.tagType === "TagTypeSimple") 
            {
                payload = {
                    ...commonPayload,
                    Values: '',
                    Icon: this.state.imageUrl
                };
            }

            if (payload && this.state.layout === 'Create') 
            {
                await this.apiClient.tags.createTag(this.state.buildingId, payload);
            }
            else if (payload && this.state.layout === 'Edit') 
            {
                const tagPayload = {
                    ...payload,
                    Tag_Id: this.state.tagId,
                    Node_Id: this.state.tagNodeId,
                    ConcurrencyStamp: this.state.concurrencyStamp
                };                
                await this.apiClient.tags.updateTag(this.state.tagNodeId, this.state.tagId, tagPayload);
            }

            this.setState({ isLoading: false })
            this.changeLayout('List');
        }
        catch (error) 
        {
            this.setState({ isLoading: false })
        }       
    }

    private handleEditTag(tagData: ITagDetails): void 
    {
        const values = tagData.Values?.split(";");
        const icons = tagData.Icon?.split(";");

        const minLength = Math.min(values?.length, icons?.length);
        const options = values.slice(0, minLength).map((value, index) => ({
            optionName: value,
            optionImageUrl: icons[index] || ""
        }));

        this.setState({
            tagId: tagData.Tag_Id,
            tagType: tagData.TagType,
            tagName: tagData.Name,
            imageUrl: tagData.Icon,
            description: tagData.Description,
            status: tagData.Status,
            options: options,
            concurrencyStamp: tagData.ConcurrencyStamp,
            tagNodeId: tagData.Node_Id
        });
        this.changeLayout('Edit');
    }

    private setTagCount(tagCount: number): void 
    {
        this.setState({ 
            tagCount:tagCount
        })
    }

    public render(): JSX.Element 
    {
        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    {this.state.isLoading && <LoadingOverlay />}
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container>
                            <Grid md={4} lg={4} pr={2}>
                                <Box className="">
                                    <Box className={(this.state.layout === 'Create' || this.state.layout === 'Edit') ? "tag-button active-tag-btn" : "tag-button"} display={'flex'} justifyContent={'space-between'} alignItems={'center'} onClick={() => this.changeLayout('Create')}>
                                        <Typography>{this.labels.funcCreateNewTag_S}</Typography>
                                        <Box className="add-icon">
                                            <Box>
                                                <IbssSvgIcon sx={{ paddingTop: '14px', paddingLeft: '4px' }}>
                                                    <AddIcon />
                                                </IbssSvgIcon>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={this.state.layout === 'List' ? "tag-button active-tag-btn" : "tag-button"} display={'flex'} justifyContent={'space-between'} alignItems={'center'} onClick={() => this.changeLayout('List')}>
                                        <Typography>{this.labels.funcCurrentTags_S}</Typography>
                                        <Box className="add-icon" sx={{ padding: '20px', lineHeight: '20px' }}>
                                            {this.state.tagCount}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid md={8} lg={6}>
                                <Box className="tablePanel" p={3.5}>
                                    {(this.state.layout === 'Create' || this.state.layout === 'Edit') &&
                                        <>
                                            <Typography variant="h5" fontWeight={'medium'} mb={1.5}>{this.state.tagId === ''? this.labels.funcCreateYourTag_S : this.labels.funcEditTag_S} </Typography>
                                            <Typography variant="body1" className="ui-text-light">{this.labels.funcCreateNewTagInfo_D}</Typography>
                                            <hr />
                                            <Typography variant="h6" fontWeight={'normal'} mb={1.5}>{this.labels.funcTagDetails_S}</Typography>
                                            <Box my={2} px={1}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={this.state.tagType}
                                                    onChange={(event) => this.tagTypeChanged(event)}
                                                >
                                                    <FormControlLabel
                                                        value="TagTypeSimple"
                                                        control={<Radio />}
                                                        label={this.labels.funcSimpleTags_S}
                                                        disabled={this.state.tagId !== ''}
                                                    />
                                                    <Box display="flex" alignItems="center" justifyContent="center" mr={2}>
                                                        <IbssToolTip title={this.labels.funcSimpleTagToolTip_L} arrow>
                                                            <Box component="span">
                                                                <InfoIcon />
                                                            </Box>
                                                        </IbssToolTip>
                                                    </Box>
                                                    <FormControlLabel
                                                        value="TagTypeComplex"
                                                        control={<Radio />}
                                                        label={this.labels.funcComplexTag_S}
                                                        disabled={this.state.tagId !== ''}
                                                    />
                                                    <Box display="flex" alignItems="center" justifyContent="center" mr={1.5}>
                                                        <IbssToolTip title={this.labels.funcComplexTagToolTip_L} arrow>
                                                            <Box component="span">
                                                                <InfoIcon />
                                                            </Box>
                                                        </IbssToolTip>
                                                    </Box>
                                                </RadioGroup>
                                            </Box>
                                            <Box className=" space-between" display="flex" alignItems="center">
                                                <IbssTextField
                                                    required
                                                    id="outlined-basic"
                                                    fullWidth
                                                    label={this.labels.funcTagName_S}
                                                    variant="outlined"
                                                    disabled={this.state.tagId !== ''}
                                                    value={this.state.tagName}
                                                    onChange={(event) => this.tagNameChanged(event)}
                                                />
                                                <Box ml={2}>
                                                    <IbssCheckBox
                                                        label={this.labels.HubLabelEnabled}
                                                        checked={this.state.status === "StatusActive"}
                                                        onClicked={(e: React.ChangeEvent<HTMLInputElement>) => this.statusChanged(e)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box my={2}>
                                                <IbssTextField
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    id="outlined-basic"
                                                    label={this.labels.funcTagDescription_S}
                                                    variant="outlined"
                                                    value={this.state.description}
                                                    onChange={(event) => this.tagDescriptionChanged(event)}
                                                />
                                                <hr />
                                            </Box>
                                            {this.state.tagType === 'TagTypeSimple' &&
                                                <>
                                                    <Box>
                                                        <Typography variant="h6" fontWeight={'normal'} mb={1}>{this.labels.funcUploadAnIcon_S}</Typography>
                                                        <Typography variant="body1" className="ui-text-light">{this.labels.funcUploadAnIconInfo_D}</Typography>
                                                    </Box>
                                                    <Box className="option-box" my={2}>
                                                        <Box mt={1} display={'flex'} justifyContent={"space-between"}>
                                                            <Typography variant="body1">
                                                                <MediaSelector
                                                                    options={this.state.images.map(i => ({ name: i.name, url: i.path }))}
                                                                    imageSelected={file => this.setState({ imageUrl: file.url })}
                                                                    uploadFile={(filename, file, processAsMap) => this.uploadImage(filename, file, processAsMap)}
                                                                    defaultSelectedImage={this.state.imageUrl}
                                                                />
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                            {this.state.tagType === "TagTypeComplex" &&
                                                <>
                                                    <Box>
                                                        <Typography variant="h6" fontWeight={'normal'} mb={1}>{this.labels.funcSelectYourOptions_S}</Typography>
                                                        <Typography variant="body1" className="ui-text-light">{this.labels.funcSelectYourOptionsInfo_D}</Typography>
                                                        <Typography variant="body1" className="ui-text-light">{this.labels.funcSelectYourOptionsIconInfo_D}</Typography>
                                                    </Box>
                                                    {this.state.options.map((option, index) => (
                                                        <TagOption
                                                            index={index}
                                                            optionName={option.optionName}
                                                            images={this.state.images}
                                                            imageUrl={option.optionImageUrl}
                                                            onImageSelected={(url: string) => this.optionImageSelectedChanged(url, index)}
                                                            onOptionNameChanged={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.optionNameChanged(event, index)}
                                                            onRemoveOption={(index: number) => this.removeOption(index)}
                                                            isRemoveDisable={this.state.tagId !== ''}
                                                            uploadImage= {(filename, file, processAsMap) => this.uploadImage(filename, file, processAsMap)}
                                                            isLastOption={this.state.options.length === 1}
                                                        />
                                                    ))}
                                                    <IbssButton
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth
                                                        disabled={this.state.tagId !== ''}
                                                        onClick={() => this.addAnotherOption()}
                                                    >
                                                        {this.labels.funcAddAnotherOption_S}
                                                    </IbssButton>
                                                </>
                                            }

                                            <hr />
                                            <Stack spacing={2}>                                            
                                                <IbssButton
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!this.state.tagName || (this.state.tagType === 'TagTypeComplex' && this.state.options.some(option => option.optionName === ''))}
                                                    fullWidth
                                                    onClick={() => this.saveTag()}
                                                >
                                                    {this.labels.funcSaveTag_S}
                                                </IbssButton>

                                                <IbssButton
                                                    variant="contained"
                                                    color="secondary"
                                                    fullWidth
                                                    onClick={() => this.changeLayout('List')}
                                                >
                                                    {this.labels.HubButtonCancel}
                                                </IbssButton>
                                            </Stack>
                                        </>
                                    }
                                    {this.state.layout === 'List' &&
                                        <>
                                            <ListTags
                                                onEditTag={(tagData) => this.handleEditTag(tagData)}
                                                tagCount={(tagCount) => this.setTagCount(tagCount)}
                                            />
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}

export interface IProps 
{
}
export interface IState 
{
    isLoading: boolean;
    tagName: string;
    images: IFile[];
    tagType: string;
    imageUrl: string;
    buildingId: number;
    description: string;
    status: string;
    options: IComplexTagOption[];
    layout: string;
    tagId: string;
    concurrencyStamp: string;
    tagCount: number,
    tagNodeId: number,
}

interface IComplexTagOption 
{
    optionName: string;
    optionImageUrl: string;
}