import { Component } from 'react';

export default class Lecturetheatre extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.215 14.6667C10.215 12.676 11.9991 11.215 14 11.215H20C22.0009 11.215 23.785 12.676 23.785 14.6667V16C23.785 16.4335 23.4335 16.785 23 16.785C22.5665 16.785 22.215 16.4335 22.215 16V14.6667C22.215 13.7118 21.3128 12.785 20 12.785H14C12.6872 12.785 11.785 13.7118 11.785 14.6667V16C11.785 16.4335 11.4335 16.785 11 16.785C10.5665 16.785 10.215 16.4335 10.215 16V14.6667Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.215 5C12.215 2.35732 14.3573 0.215 17 0.215C19.6427 0.215 21.785 2.35732 21.785 5C21.785 7.64268 19.6427 9.785 17 9.785C14.3573 9.785 12.215 7.64268 12.215 5ZM17 1.785C15.2244 1.785 13.785 3.2244 13.785 5C13.785 6.7756 15.2244 8.215 17 8.215C18.7756 8.215 20.215 6.7756 20.215 5C20.215 3.2244 18.7756 1.785 17 1.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 20C4.25 18.4812 5.48122 17.25 7 17.25H27C28.5188 17.25 29.75 18.4812 29.75 20V22C29.75 23.5188 28.5188 24.75 27 24.75H7C5.48122 24.75 4.25 23.5188 4.25 22V20ZM7 18.75C6.30964 18.75 5.75 19.3096 5.75 20V22C5.75 22.6904 6.30964 23.25 7 23.25H27C27.6904 23.25 28.25 22.6904 28.25 22V20C28.25 19.3096 27.6904 18.75 27 18.75H7Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 23.25H26.75V31C26.75 32.5188 25.5188 33.75 24 33.75H10C8.48122 33.75 7.25 32.5188 7.25 31V23.25ZM8.75 24.75V31C8.75 31.6904 9.30964 32.25 10 32.25H24C24.6904 32.25 25.25 31.6904 25.25 31V24.75H8.75Z" />
            </svg>
        )
    }
};