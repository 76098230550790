import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class StatusUpdateModal extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                fullWidth
                header={this.props.statusUpdateType == 'enable' ? this.labels.funcSpacesEnableSpaces_Short : this.labels.funcSpacesDisableSpaces_Short}
                dialogContent=
                {
                    <div>
                        <h3 className='mb-3 mt-0'>
                            {`${this.props.statusUpdateType == 'enable' ? this.labels.HubLabelEnable : this.labels.HubLabelDisable} ${this.labels.funcSpacesSelectedSpacesToUpdate_Message} (${this.props.spaceCount})`}
                        </h3>
                        <div>
                            {
                                this.props.statusUpdateType == 'enable' ?
                                    this.labels.funcSpacesEnabledSpacesInfo_Message :
                                    <div>
                                        <div>{this.labels.funcSpacesDisabledSpacesInfo_Message}</div>
                                        <div>{this.labels.funcSpacesDisabledSpacesInfoPt2_Message}</div>
                                    </div>
                            }
                        </div>
                    </div>
                }
                footer=
                {
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '82%' }}>
                        <IbssButtonRedo
                            color="secondary"
                            variant="contained"
                            size="medium"
                            onClick={() => this.props.onClose()}
                        >
                            {this.labels.HubLabelClose}
                        </IbssButtonRedo>
                        <IbssButtonRedo
                            color="primary"
                            variant="contained"
                            size="medium"
                            onClick={() => this.props.updateStatus()}
                        >
                            {this.props.statusUpdateType == 'enable' ? this.labels.HubLabelEnable : this.labels.HubLabelDisable}
                        </IbssButtonRedo>
                    </div>
                }
            />
        )
    }
}

export default StatusUpdateModal;

export interface IProps
{
    open: boolean;
    onClose: () => void;
    updateStatus: () => void;
    statusUpdateType: string;
    spaceCount: number;
}

export interface IState
{
}