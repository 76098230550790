import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { Space } from "./SpaceRepository";

export class GetV1SpaceByIdEndpoint implements IGetV1SpaceByIdEndpoint
{
    public async execute(nodeId: number, spaceId: string): Promise<Space | null>
    {
        try
        {
            const response = await axios.get<Space>(`${apis.allApi}${nodeId}/Spaces/${spaceId}`);
            return response.data;
        }
        catch (error: any)
        {
            const axiosError = error as AxiosError;
            const response = axiosError.response as AxiosResponse;

            if (response.status == 400)
            {
                return null;
            }
            throw ApiError.fromAxiosResponse(response);
        }
    }
}

export interface IGetV1SpaceByIdEndpoint
{
    execute(nodeId: number, spaceId: string): Promise<Space | null>;
}
