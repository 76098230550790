import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetRoleNamesByCurrentUserEndpoint implements IGetRoleNamesByCurrentUserEndpoint
{
    public async execute(): Promise<string[]>
    {
        try
        {
            const response: AxiosResponse<string[]> = await axios.get(`${apis.userApi}userroles2`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetRoleNamesByCurrentUserEndpoint
{
    execute(): Promise<string[]>;
}
