import axios, { AxiosResponse } from "axios";
import { IbssRequestConfig } from "../ApiClient";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteEndpoint implements IDeleteEndpoint
{
    public async execute(nodeId: number, cateringMenuId: string, suppressError: boolean): Promise<void>
    {
        try
        {
            const config: IbssRequestConfig = { suppressErrorPopup: suppressError };
            const response = await axios.delete(`${apis.allApiv2}${nodeId}/catering-menu/${cateringMenuId}`, config);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteEndpoint
{
    execute(nodeId: number, cateringMenuId: string, suppressError: boolean): Promise<void>;
}
