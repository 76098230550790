import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { getAirStatus } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo";
import InputTextBox from "../../../../Components/Inputs/TextBox/InputTextBox";
import { appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";

class ViewMostRecentEnvironmentalDataByZone extends Component {
  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  state: any = {
    isLoading: false,
    fullZoneData: {},
    formFieldsData: []
  };

  componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    const { zoneId } = match.params;
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones });
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetZonesData(zoneId);
  }

  handleGetZonesData = async (zoneId: string) => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFullZoneData(this.session.getBuildingId(), zoneId)
      this.setState({ fullZoneData: response?.data ?? {} });
      this.makeFormFields()
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }
  makeFormFields = () => {
    let arr = [
      {
        name: 'Env_Zone_Name',
        label: this.labels.HubLabelName,
        value: this.state.fullZoneData.Env_Zone_Name,
        readonly: true
      },
      {
        name: 'IAQ_Status',
        label: this.labels.HubLabelStatus,
        value: getAirStatus(this.state.fullZoneData.IAQ_Status),
        readonly: true
      },
      {
        name: 'Env_Temperature',
        label: this.labels.HubLabelTemperature,
        value: `${this.state.fullZoneData.Env_Temperature}\u00b0C`,
        readonly: true
      },
      {
        name: 'Env_Humidity',
        label: this.labels.HubLabelHumidity,
        value: `${this.state.fullZoneData.Env_Humidity}%`,
        readonly: true
      },
      {
        name: 'Env_Sound_Level',
        label: this.labels.HubLabelNoise,
        value: `${this.state.fullZoneData.Env_Sound_Level} dB`,
        readonly: true
      },
      {
        name: 'Env_CO',
        label: this.labels.HubLabelCo,
        value: `${this.state.fullZoneData.Env_CO} ppm`,
        readonly: true
      },
      {
        name: 'Env_CO2',
        label: this.labels.HubLabelCO2,
        value: `${this.state.fullZoneData.Env_CO2} ppm`,
        readonly: true
      },
      {
        name: 'Env_PM10',
        label: this.labels.HubLabelPM10,
        value: `${this.state.fullZoneData.Env_PM10} ppm`,
        readonly: true
      },
      {
        name: 'Env_PM25',
        label: this.labels.HubLabelPM25,
        value: `${this.state.fullZoneData.Env_PM25} ppm`,
        readonly: true
      },
      {
        name: 'Env_TVOC',
        label: this.labels.HubLabelTVOC,
        value: `${this.state.fullZoneData.Env_TVOC} ppm`,
        readonly: true
      },
      {
        name: 'Env_Zone_LastUpdated',
        label: this.labels.HubLabelLastUpdate,
        value: DateTime.fromISO(this.state.fullZoneData.Env_Zone_LastUpdated).toFormat("dd/MM/yyyy HH:mm"),
        readonly: true
      },
    ];
    this.setState({ isLoading: false, formFieldsData: arr })
  }

  openHistoricalData = () => {
    const { history, dispatch }: any = this.props;
    const startDate = DateTime.local().minus({ days: 7 }).toFormat("yyyy-MM-dd");
    const endDate = DateTime.local().toFormat("yyyy-MM-dd");
    dispatch({ type: "UPDATE_CURRENT_ENV_ZONE_NAME", currentEnvZoneName: this.state.fullZoneData.Env_Zone_Name });
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/${this.state.fullZoneData.Env_Zone_Id}/historical?StartDate=${startDate}&EndDate=${endDate} `)
  }


  render() {
    return (
      <>
        <div className="rightPanel-main-content-form">
          <div className="form-panel">
            {(this.state.isLoading || this.state.isLoading) && <Spinner />}
            <FormPanelInfo
              formHeading={this.state.fullZoneData.Env_Zone_Name}
              iconSrc="/images/Sidebar_Icons/Light_theme/Zone.svg"
            />
            <form className="form-panel-form">
              {this.state.formFieldsData.map((obj: any, ind: number) => {
                return (
                  <div className="row" key={ind}>
                    <InputTextBox {...obj} />
                  </div>
                )
              })}
            </form>
          </div>
        </div>
        <div className="rightPanel-main-button-group">
          <div className="d-flex justify-content-end w-100">
            <button className="button-primary" onClick={() => this.openHistoricalData()}>{this.labels.HubButtonHistoricalData}</button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(ViewMostRecentEnvironmentalDataByZone);
