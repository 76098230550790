import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRole } from "./RoleRepository";

export class GetRolesByCurrentUserEndpoint implements IGetRolesByCurrentUserEndpoint
{
    public async execute(): Promise<IRole[]>
    {
        try
        {
            const response = await axios.get<IRole[]>(`${apis.userApi}userroles2?$select=id,name,groupId`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}


export interface IGetRolesByCurrentUserEndpoint
{
    execute(): Promise<IRole[]>;
}
