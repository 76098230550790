import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetManyAsBlobEndpoint implements IGetManyAsBlobEndpoint
{
    public async execute(buildingId: number): Promise<Blob>
    {
        try
        {
            const response = await axios.get<Blob>(apis.allApiv2 + buildingId + `/catering-supplier/download`, { responseType: 'blob' })
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyAsBlobEndpoint
{
    execute(buildingId: number): Promise<Blob>;
}
