import { Component } from 'react';
export default class TickIcon extends Component {
    render() {
        return (
            <svg width="26" height="26" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path xmlns="http://www.w3.org/2000/svg" d="M2,9.014L3.414,7.6L6.004,10.189L12.593,3.6L14.007,5.014L6.003,13.017L2,9.014Z" />
            </svg>

        )
    }
};
