import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import InputSelectBox from "../../../../Components/Inputs/SelectList2/InputSelectBox";
import SelectBox from "../../../../Components/Inputs/SelectBox/SelectBox";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import Autocomplete from '@mui/material/Autocomplete';
import InputTextBox from "../../../../Components/Inputs/TextBox/InputTextBox"
import TextField from '@mui/material/TextField';
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo"
import DatePicker from "react-datepicker";
import JoditEditor from "jodit-react";
import { appContext } from "../../../../AppContext";
import { IJodit } from "jodit/types";
import { DateHelper } from "../../../../Common/DateHelper";
import Helper from "../../../../Common/Helper";
import { RouteComponentProps, RouterProps, generatePath } from "react-router";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BuildingChangeReason } from "../../../../Components/Core/BasePage/Types";

const InputTextBox_ = InputTextBox as unknown as React.JSXElementConstructor<{
    name: string;
    label: string;
    value: string;
    readonly: boolean;
    onchange: any;
}>;


let config: Partial<IJodit['options']> = {
    // HTML Editor
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: false,
    spellcheck: true,
    // @ts-ignore
    editorCssClass: true,
    triggerChangeEvent: true,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    // @ts-ignore
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: "p",
    defaultMode: 1,
    useSplitMode: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
}

class EditNotification2 extends IbssPage<IProps, IState> {
    private labels = appContext().labels;
    private session = appContext().sessionStorageProvider;
    private local = appContext().localStorageProvider;
    private get appState() { return appContext().state; }

    state = {
        apiVersion: 11,
        isLoading: false,
        editor: null,
        updates: false,
        imageUrlList: [],
        ImageURI: "",
        taskCategoryLoading: false,
        loading: false,
        Notification_Title: "",
        Classification: "Building",
        Subclassification: "",
        SubclassificationOption: [
            { label: "Info", value: "Info" },
            { label: "INSPIRE", value: "INSPIRE" },
            { label: "Custom", value: "Custom" },
        ], //Info, Inspire, Custom
        customSubclassification: "",
        Notification_Short_Message: "",
        Notification_Message: "",
        notificationPriorityOption: [
            { label: "High", value: 1 },
            { label: "Normal", value: 2 },
            { label: "Low", value: 3 },
        ], //Info, Inspire, Custom
        Notification_Priority: 1,
        Available_From: new Date(),
        Expiry_Date: new Date(),
        buildingId: this.appState.buildingId
    };

    componentDidMount() {
        this.handleMountComponent()
        this.onBuildingIdChanged<IMatchParams>(params => params.buildingid, (buildingId, reason) => this.buildingIdChanged(buildingId, reason));;
        let versions = 11;
        const version = this.local.getVersion();
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        if (version !== 0) {
        versions = version;
        }
        this.setState({ apiVersion: versions });
    }

    public async buildingIdChanged(buildingId: number, reason: BuildingChangeReason): Promise<void>
    {
        if (reason == "BuildingSelectorChanged" && this.props.match.params.notificationId == '0')
        {
            const path = generatePath(this.props.match.path, { buildingid: buildingId, notificationId: this.props.match.params.notificationId });
            this.props.history.push(path);
        }
    }

    handleMountComponent = async () => {
        const { match }: any = this.props;

        await this.retrieveImageURI()

        if (this.props.match.params.buildingid !== "0" && this.props.match.params.notificationId !== "0") {
            await this.retrieveSpecificNotificationData()
        }
    }


    retrieveImageURI = async () => {
        try {
            const response = await apis.getNotificationImageURIData()
            const responseData = response.data

            // const responseData = [{ name: "test1" }, { name: "test2" }]
            const newResponseData = responseData.map((item: any) => {
                let newItem = { ...item }
                newItem.value = `https://storage.ibss.${Helper.environment}/images/c/Notifications/${item.name}`
                newItem.label = `https://storage.ibss.${Helper.environment}/images/c/Notifications/${item.name}`
                return newItem
            })

            this.setState({ imageUrlList: newResponseData ? newResponseData : [], ImageURI: newResponseData ? newResponseData[0].value : ""})
        } catch (error) {

        }
    }


    retrieveSpecificNotificationData = async () => {
        try {
            const { match }: any = this.props;
            const response = await apis.getSpecificNotificationData(match.params.buildingid, match.params.notificationId)
            let responseData = response.data
            responseData.Available_From = new Date(responseData.Available_From)
            responseData.Expiry_Date = new Date(responseData.Expiry_Date)
            if (responseData.Subclassification !== "Info" && responseData.Subclassification !== "INSPIRE") {
                responseData.cutomSubclassification = responseData.Subclassification
                responseData.Subclassification = "Custom"
            }

            this.setState({ ...responseData })

            return;
        } catch (error: any) {
            this.setState({ isLoading: false })
            return;
        }
    }


    change = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name)
        {
            case 'Notification_Title':
                this.setState({ Notification_Title: e.target.value })
                break;
            case 'Classification':
                this.setState({ Classification: e.target.value })
                break;
            case 'customSubclassification':
                this.setState({ customSubclassification: e.target.value })
                break;
            case 'Notification_Short_Message':
                this.setState({ Notification_Short_Message: e.target.value })
                break;
            default:
                break;
        }
    }

    handleAvailableFromDate = (e: any) => {
        this.setState({
            Available_From: e,
            updates: true
        })
    }

    handleExpiryDate = (e: any) => {
        this.setState({
            Expiry_Date: e,
            updates: true
        })
    }

    cancel = () => {
        const { updates } = this.state
        const { history, match }: any = this.props;
        // if (updates) {
        //   this.handleModal();
        // } else {
        history.push(`/operational-services-notifications/${this.state.buildingId}`);
        // }
    }

    handleDeleteNotification = async () => {
        try {
            const { history, match }: any = this.props;
            const response = await apis.deleteSpecificFacilityNotification(match.params.buildingid, match.params.notificationId)
            history.push(`/operational-services-notifications/${this.state.buildingId}`);
            return response
        } catch (error: any) {
            return "error occured"
        }
    }

    submit = () => {
        this.setState({ isLoading: true })
        const { match }: any = this.props;

        const {
            ImageURI, Notification_Title, Classification, Subclassification, customSubclassification,
            Notification_Short_Message, Notification_Message, Available_From, Expiry_Date, Notification_Priority,
        }: any = this.state;

        let payload: any = {}

        if (match.params.notificationId === "0") {
            if (this.state.apiVersion > 11) {
                payload.ImageURI = ImageURI
                payload.Notification_Title = Notification_Title
                payload.Notification_Priority = Notification_Priority
                payload.Classification = Classification
                payload.SubClassfication = Subclassification === "Custom" ? customSubclassification : Subclassification
                payload.Notification_Message = Notification_Message
                payload.Notification_Short_Message = Notification_Short_Message
                payload.Available_From = Available_From
                payload.Expiry_Date = Expiry_Date
            } else {
                payload.Title = Notification_Title
                payload.Priority = Notification_Priority
                payload.Classification = Classification
                payload.SubClassfication = Subclassification === "Custom" ? customSubclassification : Subclassification
                payload.Message = Notification_Message
                payload.Notification_Short_Message = Notification_Short_Message
                payload.AvailableFrom = Available_From
                payload.ExpiryDate = Expiry_Date
                payload.ImageURI = ImageURI
            }
            this.create(payload);
        }
        else {
            if (this.state.apiVersion > 11) {
                payload.ImageURI = ImageURI
                payload.Notification_Title = Notification_Title
                payload.Notification_Priority = Notification_Priority
                payload.Classification = Classification
                payload.SubClassfication = Subclassification === "Custom" ? customSubclassification : Subclassification
                payload.Notification_Message = Notification_Message
                payload.Notification_Short_Message = Notification_Short_Message
                payload.Available_From = Available_From
                payload.Expiry_Date = Expiry_Date
            } else {
                payload.Title = Notification_Title
                payload.Priority = Notification_Priority
                payload.Classification = Classification
                payload.SubClassfication = Subclassification === "Custom" ? customSubclassification : Subclassification
                payload.Message = Notification_Message
                payload.Notification_Short_Message = Notification_Short_Message
                payload.AvailableFrom = Available_From
                payload.ExpiryDate = Expiry_Date
                payload.ImageURI = ImageURI

            }
            this.update(payload);
        }
    };

    create = async (payload: any) => {
        try {
            this.setState({ isLoading: false })
            const { history, match }: any = this.props;
            const response = await apis.createFacilityNotification(match.params.buildingid, payload)
            history.push(`/operational-services-notifications/` + this.state.buildingId);
        } catch (error: any) {
            this.setState({ isLoading: false });
        }
    }

    update = async (payload: any) => {
        try {
            this.setState({ isLoading: false })
            const { history, match }: any = this.props;
            const response = await apis.updateFacilityNotification(match.params.buildingid, match.params.notificationId, payload)
            history.push(`/operational-services-notifications/` + this.state.buildingId);
        } catch (error: any) {
            this.setState({ isLoading: false });
        }
    }

    changeHtml = (e: any) => {
        if (e !== "") {
            this.setState({
                Notification_Message: e,
            })
        }
    }

    render() {
        const { history, match }: any = this.props;

        const {
            Notification_Title, Classification, ImageURI, Notification_Short_Message, Subclassification, cutomSubclassification,
            Notification_Priority
        }: any = this.state;

        const notifnTitleFId = {
            name: "Notification_Title",
            label: this.labels.dataNotificationTitle_S,
            value: Notification_Title,
            // readonly: true,
            mandatory: true,
            onchange: this.change,
        };

        const classificationFId = {
            name: "Classification",
            label: this.labels.dataNotificationClassification_S,
            value: Classification,
            readonly: true,
            onchange: this.change,
        };


        const subClassificationFld = {
            name: "Subclassification",
            label: this.labels.dataNotificationSubclassification_S,
            value: Subclassification,
            // mandatory: true,
            // disabled: Task_Id !== "",
            onchange: (value: any) => {
                this.setState({
                    Subclassification: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.SubclassificationOption
        }


        const customSubClassificationFId = {
            name: "customSubclassification",
            label: this.labels.dataNotificationCustomSubclassification_S,
            value: cutomSubclassification,
            // readonly: true,
            onchange: this.change,
        };



        const shortMessageFId = {
            name: "Notification_Short_Message",
            label: this.labels.HubLabelShortMessage,
            value: Notification_Short_Message,
            // readonly: true,
            isMultiLine: true,
            onchange: this.change,
        };

        const notificationPriorityFld = {
            name: "Notification_Priority",
            label: this.labels.dataNotificationPriority_S,
            value: Notification_Priority,
            // mandatory: true,
            // disabled: Task_Id !== "",
            onchange: (value: any) => {
                this.setState({
                    Notification_Priority: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.notificationPriorityOption
        }


        const imageUriFld = {
            name: "ImageURI",
            label: this.labels.dataNotificationImage_S,
            value: ImageURI,
            // mandatory: true,
            // disabled: Task_Id !== "",
            onchange: (value: any) => {
                this.setState({
                    ImageURI: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.imageUrlList
        }

        return (
            <>
                {this.state.isLoading && <LoadingOverlay />}
                <div className="page-height-exct-header">
            <div className="rightPanel-main-content">
                        <div className="">
                            {/* {this.state.show ? (
                                <ConfirmModal {...confModalData} />
                            ) : ""} */}
                            <div className="form-panel">
                                <FormPanelInfo
                                    formHeading={this.labels.HubLabelNotifications}
                                    iconSrc="/images/Sidebar_Icons/Light_theme/Notification.svg"
                                />
                                {this.state.loading || (
                                    <div className="form-panel-form" >
                                        <form>

                                            <div className="row">
                                                <InputTextBox {...notifnTitleFId} />
                                            </div>

                                            <div className="row">
                                                <InputTextBox {...classificationFId} />
                                            </div>

                                            <div>
                                                <InputSelectBox {...subClassificationFld} />
                                            </div>

                                            {(Subclassification === "Custom") &&
                                                <div className="row">
                                                    <InputTextBox {...customSubClassificationFId} />
                                                </div>
                                            }

                                            <div className="row">
                                                <InputTextBox {...shortMessageFId} />
                                            </div>

                                            <div className="row">
                                                <div className="form-input-box form-input-box--jodit">
                                                    <label className="form-input-box-label">{this.labels.HubLabelMessage}</label>
                                                    <span className="form-input-box-required-asterisk"></span>
                                                    <JoditEditor ref={this.state.editor} value={this.state.Notification_Message} onChange={this.changeHtml} config={config as any} />
                                                </div>
                                            </div>

                                            <div className="notificn-priority-textfield">
                                                <InputSelectBox {...notificationPriorityFld} />
                                            </div>



                                            <div className="row">
                                                <div className="form-input-box">
                                                    <label className="form-input-box-label">{this.labels.dataNotificationAvailableFrom_S}</label>
                                                    <span className="form-input-box-required-asterisk">*</span>
                                                    <DatePicker className="form-input-box-datetime-picker form-input-text" selected={this.state.Available_From} onChange={this.handleAvailableFromDate} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" locale="pt-BR" timeFormat="HH:mm" timeIntervals={15} />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-input-box">
                                                    <label className="form-input-box-label">{this.labels.dataNotificationExpiryDate_S}</label>
                                                    <span className="form-input-box-required-asterisk">*</span>
                                                    <DatePicker className="form-input-box-datetime-picker form-input-text" selected={this.state.Expiry_Date} onChange={this.handleExpiryDate} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" locale="pt-BR" timeFormat="HH:mm" timeIntervals={15} />
                                                </div>
                                            </div>

                                            <div>
                                                <InputSelectBox {...imageUriFld} />
                                            </div>

                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="rightPanel-main-button-group">
                            <div>
                                <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                                <button className="button-tertiary mr-2" disabled={match.params.notificationId == "0" ? true : false} onClick={() => this.handleDeleteNotification()} >{this.labels.HubButtonDelete}</button>
                            </div>
                            <div>
                                <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                            </div>
                        </div>
                        </div>
                        </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        // currentPageTitle: state.currentPageTitle,
    };
};
export default connect(mapStateToProps)(EditNotification2);

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
    notificationId: string;
}

export interface IState
{
    apiVersion: number,
    isLoading: boolean,
    editor: any,
    updates: boolean,
    imageUrlList: string[],
    ImageURI: string,
    taskCategoryLoading: boolean,
    loading: boolean,
    Notification_Title: string,
    Classification: string,
    Subclassification: string,
    SubclassificationOption: IOption[];
    customSubclassification: string,
    Notification_Short_Message: string,
    Notification_Message: string,
    notificationPriorityOption: IOption[]
    Notification_Priority: number,
    Available_From: Date,
    Expiry_Date: Date,
    buildingId: number
}

export interface IOption
{
    label: string;
    value: string | number;
}