import React, { Component } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButtonGroupProps, ToggleButtonProps } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

export interface toggleButtonListObject {
    value: number | string,
    icon: JSX.Element,
    showFilterFields?: boolean;
};

type IbssToggleButtonProps = ToggleButtonGroupProps & ToggleButtonProps & {
    buttonList: Array<toggleButtonListObject>,
};

class IbssToggleButton extends React.Component<IbssToggleButtonProps> 
{
    render(): JSX.Element
    {
        return (
            <ToggleButtonGroup {...this.props} >
                {this.props.buttonList.map((item: toggleButtonListObject) => (
                    <ToggleButton value={item.value}>
                        {item.icon}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    }
};

export default IbssToggleButton;
