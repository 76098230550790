import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICostCode } from "./CostCodeRepository";

export class GetCostCodeByIdEndpoint implements IGetCostCodeByIdEndpoint
{
    public async execute(costCodeId: string): Promise<ICostCode>
    {
        try
        {
            const query = `$select=Cost_Code,Cost_Code_Description,Cost_Code_Id,Cost_Code_Type,_EventTime`;
            const response: AxiosResponse<ICostCode> = await axios.get(`${apis.allApi}${1}/CostCodes/${costCodeId}?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetCostCodeByIdEndpoint
{
    execute(costCodeId: string): Promise<ICostCode>;
}
