import axios, { AxiosError } from "axios";
import { IRawPagedResponse3, PagedResponse } from "../Models";
import apis from "../apis";
import { ApiError } from "../ApiError";

export class GetManyByDelegatorEndpoint implements IGetManyByDelegatorEndpoint
{
    public async execute(delegatorEmail: string): Promise<PagedResponse<IDelegate[]>>
    {
        try
        {
            const response = await axios.get<IRawPagedResponse3<IDelegate[]>>(`${apis.userApiBaseUrl}/api/user/v1/Delegates?$filter=PrimaryEmail eq '${delegatorEmail}'&$top=50`);
            
            const pagedResponse = PagedResponse.fromRawResponse3(response.data);
            return pagedResponse;
        }
        catch (error)
        {
            throw ApiError.fromAxiosError(error as AxiosError);
        }
    }
}

export interface IGetManyByDelegatorEndpoint
{
    execute(delegatorEmail: string): Promise<PagedResponse<IDelegate[]>>;
}

export interface IDelegate
{
    Delegate_Id: string;
    DelegateUser_Id: string;
    DelegateUserName: string;
    DelegateEmail: string;
    DelegateDisplayName: string | null;
    PrimaryDisplayName?: string,
    PrimaryUserName?: string,
    PrimaryEmail?: string,
}
