
import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { ApiError } from "../ApiError";
import { UserNotificationsFilter } from "./UserNotificationsRepository";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(nodeId: number, filter:UserNotificationsFilter): Promise<IUserNotifications[]>
    {
        try
        {
            const query = 
            `schema=false&` +
            `showDebugStats=false&` +
            `$top=1000&` +
            `$filter= ${filter.toODataString()}`;
            const response = await axios.get<IUserNotifications[]>(`${apis.allApi}${nodeId}/UserNotifications?${query}`);
            response.data.forEach(i =>
                {
                    i._EventTime = DateHelper.fromIso(i._EventTime as unknown as string);
                })
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(nodeId: number,filter:UserNotificationsFilter): Promise<IUserNotifications[]>;
}

export interface IUserNotifications
{
    Notification_Id: number;
    Classification: string;
    Subclassification: string;
    _EventTime: DateTime;
    Node_Id: number;
    ImageURI: string;
}
