import React, { Component } from 'react';

export default class InfoIcon extends Component {
    render() {
        return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5392 10.7969C7.72549 10.7969 7.88011 10.7361 8.00306 10.6147C8.12602 10.4932 8.1875 10.3404 8.1875 10.1562V7.5C8.1875 7.33067 8.12598 7.18417 8.00294 7.0605C7.87989 6.93683 7.73042 6.875 7.55455 6.875C7.36826 6.875 7.21364 6.93574 7.09069 7.05722C6.96773 7.17871 6.90625 7.33151 6.90625 7.51562V10.1719C6.90625 10.349 6.96777 10.4974 7.09081 10.6172C7.21386 10.737 7.36333 10.7969 7.5392 10.7969ZM7.50138 5.95312C7.714 5.95312 7.89193 5.88379 8.03516 5.74512C8.17839 5.60645 8.25 5.43099 8.25 5.21875C8.25 4.99479 8.17806 4.8112 8.03419 4.66797C7.8903 4.52474 7.71224 4.45312 7.5 4.45312C7.27604 4.45312 7.09505 4.52474 6.95703 4.66797C6.81901 4.8112 6.75 4.99219 6.75 5.21094C6.75 5.42969 6.82207 5.60807 6.96622 5.74609C7.11035 5.88411 7.28874 5.95312 7.50138 5.95312ZM7.50781 14.1406C6.5651 14.1406 5.68757 13.9709 4.8752 13.6315C4.06283 13.2921 3.36014 12.8259 2.76712 12.2329C2.17411 11.6399 1.70791 10.9372 1.3685 10.1248C1.02908 9.31243 0.859375 8.4349 0.859375 7.49219C0.859375 6.5599 1.02785 5.68984 1.3648 4.88203C1.70174 4.07421 2.16795 3.37152 2.76342 2.77395C3.3589 2.17639 4.06283 1.7053 4.8752 1.36069C5.68757 1.01606 6.5651 0.84375 7.50781 0.84375C8.4401 0.84375 9.31045 1.01539 10.1188 1.35866C10.9272 1.70192 11.6304 2.17224 12.2284 2.76963C12.8264 3.36701 13.2972 4.06949 13.6408 4.87708C13.9844 5.68465 14.1562 6.55896 14.1562 7.5C14.1562 8.4375 13.9839 9.31243 13.6393 10.1248C13.2947 10.9372 12.8236 11.6411 12.226 12.2366C11.6285 12.8321 10.9258 13.2983 10.118 13.6352C9.31016 13.9722 8.4401 14.1406 7.50781 14.1406Z" fill="#7A7B82" />
            </svg>
        )
    }
};
