import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import InputTextBox from "../../../../Components/Inputs/TextBox/InputTextBox"
import { appContext } from "../../../../AppContext";
import { DateTime } from "luxon";
import { Component } from "react";


class HubLabelViewStatus extends Component<IProps, any>
{
    private get labels() { return appContext().labels; }

    createdAtFld = {
        name: "_CreatedAt",
        label: this.labels.HublabelcreatedAt,
        value: this.props.createdAt.toLocaleDateTimeString(),
        readonly: true,
    };

    createdByFld = {
        name: "_CreatedBy",
        label: this.labels.HubLabelBookedBy,
        value: this.props.createdBy,
        readonly: true,
    };

    bookingidFld = {
        name: "bookingid",
        label: this.labels.HublabelBookingId,
        value: this.props.bookingid,
        readonly: true,
    };

    ExternalIdFld = {
        name: "metaExtBookingId",
        label: this.labels.HublabelExternalId,
        value: this.props.metaExtBookingId,
        readonly: true,
    };

    spaceLayoutField = {
        name: "Space_Layout",
        label: this.labels.HublabelSpaceLayout,
        value: this.props.Space_Layout,
        readonly: true,
    };

    bookingStatusFld = {
        name: "bookingStatus",
        label: this.labels.HubLabelStatus,
        value: this.props.bookingStatus,
        readonly: true,
    };

    bookingCheckInTimeFld = {
        name: "bookingCheckInTime",
        label: this.labels.HublabelCheckInTime,
        value: (this.props.bookingCheckInTime.isNull() ? "" : this.props.bookingCheckInTime.toLocaleDateTimeString()),
        readonly: true,
    };

    bookingCompletionTimeFld = {
        name: "bookingCompletionTime",
        label: this.labels.HublabelCompletionTime,
        value: (this.props.bookingCompletionTime.isNull() ? "" : this.props.bookingCompletionTime.toLocaleDateTimeString()),
        readonly: true,
    };

    bookingCancelletionTimeFld = {
        name: "bookingCancelletionTime",
        label: this.labels.HublabelCancelletionTime,
        value: (this.props.bookingCancelletionTime.isNull() ? "" : this.props.bookingCancelletionTime.toLocaleDateTimeString()),
        readonly: true,
    };

    bookingCancelledByFld = {
        name: "booking_Cancelled_By",
        label: this.labels.HubLabelBookingCancelledBy,
        value: this.props.bookingCancelledBy !== "" ? this.props.bookingCancelledBy : "",
        readonly: true,
    };

    public render(): JSX.Element
    {
        return (
            <>
                <div className="form-panel mt-0">
                    <form id="alignLables" className="form-panel-form">

                        <div className="row">
                            <InputTextBox {...this.createdAtFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.createdByFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.bookingidFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.ExternalIdFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.spaceLayoutField} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.bookingStatusFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HublabelIsActive}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="isactive" type="checkbox" className="checkBoxHide mb-13" checked={this.props.isactive} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HubLabelCheckedIn}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="checkedin" type="checkbox" className="checkBoxHide mb-13" checked={this.props.checkedin} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HubLabelCompleted}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="completed" type="checkbox" className="checkBoxHide mb-13" checked={this.props.completed} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HubLabelCancelled}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="cancelled" type="checkbox" className="checkBoxHide mb-13" checked={this.props.cancelled} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HublabelAutoCheckInLabel}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="autocheckin" type="checkbox" className="checkBoxHide mb-13" checked={this.props.autocheckin} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HublabelEarlyCheckIn}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="earlycheckin" type="checkbox" className="checkBoxHide mb-13" checked={this.props.earlycheckin} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <div className="form-input-box">
                                <label className="form-input-box-label">{this.labels.HublabelLateCheckIn}</label>
                                <span className="form-input-box-required-asterisk"></span>
                                <label className="newCheckBox">
                                    <input name="lastcheckin" type="checkbox" className="checkBoxHide mb-13" checked={this.props.lastcheckin} disabled={true} />
                                    <span className="geekmark ml-28"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.bookingCheckInTimeFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.bookingCompletionTimeFld} />
                        </div>
                        <hr className="my-0 op-5" />

                        <div className="row">
                            <InputTextBox {...this.bookingCancelletionTimeFld} />
                        </div>

                        <hr className="my-0 op-5" />
                        <div className="row">
                            <InputTextBox {...this.bookingCancelledByFld} />
                        </div>
                    </form>
                </div>
            </>
        );
    }
};

export default HubLabelViewStatus;

export interface IProps
{
    createdAt: DateTime;
    createdBy: string;
    bookingid: string;
    metaExtBookingId: string;
    Space_Layout: string;
    bookingStatus: string;
    isactive: boolean;
    checkedin: boolean;
    completed: boolean;
    cancelled: boolean;
    autocheckin: boolean;
    earlycheckin: boolean;
    lastcheckin: boolean;
    bookingCheckInTime: DateTime;
    bookingCompletionTime: DateTime;
    bookingCancelletionTime: DateTime;
    bookingCancelledBy: string;
}