import { AuthenticationResult, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { msalConfig } from "../azure-authentication-config";
import { AccountInfo } from "@azure/msal-common";
import { OktaAuth, TokenResponse } from "@okta/okta-auth-js";
import { appContext } from "../AppContext";

export default class Authentication
{
    private get localStorage() { return appContext().localStorageProvider; }
    private get sessionStorage() { return appContext().sessionStorageProvider; }
    private get cache() { return appContext().cache; }

    public refreshAccessToken(): Promise<string>
    {
        let securityProviderTypeId = this.localStorage.getUserDetails().providerType ?? -1;
        switch (securityProviderTypeId)
        {
            case 0: // native
                return Promise.reject(); // native auth does not support refresh tokens

            case 1: // active directory
                return this.refreshActiveDirectoryToken();

            case 2: // okta
                return this.refreshOktaToken();

            default:
                return Promise.reject();
        }
    }

    public refreshActiveDirectoryToken(): Promise<string>
    {
        return new Promise((resolve, reject) =>
        {
            let authentication: PublicClientApplication = new PublicClientApplication(msalConfig);
            let currentEmail = this.localStorage.getUserDetails().email;
            let currentAccount = authentication.getAccountByUsername(currentEmail);

            if (currentAccount == null)
            {
                reject();
                return;
            }

            authentication["config"].auth.clientId = currentAccount.idTokenClaims?.aud ?? "";
            authentication["config"].auth.authority += currentAccount.tenantId + "/";

            let tokenRequest: SilentRequest =
            {
                scopes: ["user.read"],
                account: currentAccount,
                forceRefresh: true,
            };

            authentication
                .acquireTokenSilent(tokenRequest)
                .then((tokenResponse: AuthenticationResult) =>
                {
                    if (!tokenResponse?.idToken)
                    {
                        reject();
                        return;
                    }

                    let idToken = tokenResponse.idToken;
                    this.localStorage.setToken(idToken);
                    resolve(idToken);
                })
                .catch(tokenError =>
                {
                    reject();
                });
        });
    }

    public refreshOktaToken(): Promise<string>
    {
        return new Promise((resolve, reject) =>
        {
            let tokenStorage = JSON.parse(localStorage.getItem("okta-token-storage") ?? "{}");
            const config =
            {
                issuer: tokenStorage.idToken.issuer,
                clientId: tokenStorage.idToken.clientId,
                redirectUri: window.location.origin + "/implicit/callback",
                scopes: ["openid email profile"],
            };

            let authClient = new OktaAuth(config);
            authClient
                .token
                .getWithoutPrompt()
                .then((tokenResponse: TokenResponse) =>
                {
                    if (!tokenResponse?.tokens?.idToken?.idToken)
                    {
                        reject();
                        return;
                    }

                    authClient.tokenManager.setTokens(tokenResponse.tokens);
                    let idToken = tokenResponse.tokens.idToken.idToken;
                    this.localStorage.setToken(idToken);
                    resolve(idToken);
                })
                .catch(reason =>
                {
                    reject();
                });
        });
    }

    public logOut(reason?: LogOutReason): void
    {
        this.sessionStorage.clear();
        this.localStorage.clear();
        this.cache.clearAll();
        window.location.href = `/login${reason ? '?reason=' + LogOutReason[reason] : ''}`;
    }

    public unauthorised(): void
    {
        console.log("User is not authorised. Redirecting to unauthorised.");
        window.location.href = "/unauthorised"
    }
}

export enum LogOutReason
{
    NotSet = 0,
    SessionExpired,
    AccountIncomplete,
    LoginFailed,
    VersionChanged,
}
