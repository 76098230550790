import React, { Component } from 'react'
import { appContext } from '../../../AppContext';
import { Typography } from '@mui/material';

export default class NoEquipment extends Component {
  private labels = appContext().labels;
  render() {
    return (
      <div className="notification-placeholder">
        <div className="text-center">
        <Typography className="notification-label-lg">{this.labels.HubLabelNoEquipment}</Typography>
        <Typography className="text-light-md">{this.labels.HubLabelNoEquipmentPara}</Typography>
        </div>
      </div>
    )
  }
}
