import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IbssRequestConfig } from "../ApiClient";
import { ApiHelper } from "../ApiHelper";

export class GetSpaceZonesEndpoint implements IGetSpaceZonesEndpoint
{
    public async execute(nodeId: number, suppressErrorPopup: boolean): Promise<ISpaceZone[]>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const query =`$select=${ApiHelper.toODataSelect(ISpaceZone)}`;

            const response = await axios.get<ISpaceZone[]>(`${apis.allApi}${nodeId}/SpaceZones?${query}`, axiosConfig);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetSpaceZonesEndpoint
{
    execute(nodeId: number, suppressErrorPopup: boolean): Promise<ISpaceZone[]>;
}

export class ISpaceZone
{
    Node_Id = 0;
    Node_Name = "";
    Space_Zone_Id = 0;
    Meta_Loc_Zone = "";
}
