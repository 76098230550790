import React from 'react'
import { DateTimePickerProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Date-fns allows MUI DateTimePicker to be passed native JS Date objects as values, without conversions to and from Moment or Luxon
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

type IbssDateTimePickerProps = DateTimePickerProps<Date, Date>

class IbssDateTimePicker extends React.Component<IbssDateTimePickerProps> {

    constructor(props:any)
    {
        super(props)
    }

    render(): JSX.Element {
        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                <DateTimePicker
                    {...this.props}
                />
            </LocalizationProvider>
        );
    }
}

export default IbssDateTimePicker;
