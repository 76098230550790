import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICreateVisitResponse, IVisit } from "./VisitsRepository";

export class CreateVisitEndpoint implements ICreateVisitEndpoint
{
    public async execute(nodeId: number, visit: IVisit): Promise<ICreateVisitResponse>
    {
        try
        {
            const response: AxiosResponse<ICreateVisitResponse> = await axios.post(`${apis.allApi}${nodeId}/Visits`, visit);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateVisitEndpoint
{
    execute(nodeId: number, visit: IVisit): Promise<ICreateVisitResponse>;
}
