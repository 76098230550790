import { Chip, styled, ChipProps, Theme } from '@mui/material';
import { Component } from 'react';

type IbssChipPorps = ChipProps & {
    bgColor?: string,
    textColor?: string,
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (propName: PropertyKey) => {
        return !['textColor', 'bgColor'].includes(propName as string)
    }
})<IbssChipPorps>(({textColor, bgColor, theme}) => ({
    '&.MuiChip-root': {
        backgroundColor: bgColor,
        color: theme.palette.text.secondary,
    }
}));

class IbssChip extends Component<IbssChipPorps> {
  render() {
    return (
      <StyledChip {...this.props} />
    )
  }
}

export default IbssChip
