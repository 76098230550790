import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Helper } from "../Helper";
import { Editor } from "slate";

export default class ClearFormattingButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.helper.removeBlocks();
        this.helper.removeMarks();
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcClearFormatting_S}
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: 'var(--ui-text)' }} width="24px" height="24px" viewBox="-6.5 -6.5 24 24" preserveAspectRatio="xMinYMin">
                    <path d="M6.326 2L3.95 9.309a1 1 0 1 1-1.902-.618L4.223 2H1a1 1 0 1 1 0-2h8a1 1 0 1 1 0 2H6.326zm3.33 6.243l.708.707a1 1 0 1 1-1.414 1.414l-.707-.707-.707.707A1 1 0 0 1 6.12 8.95l.707-.707-.707-.707A1 1 0 0 1 7.536 6.12l.707.707.707-.707a1 1 0 1 1 1.414 1.415l-.707.707z" />
                </svg>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
}
