import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteEndpoint implements IDeleteEndpoint
{
    public async execute(buildingId: number, policyId: string): Promise<void>
    {
        try
        {
            await axios.delete(`${apis.allApiv2}${buildingId}/catering-order-policy/${policyId}`);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteEndpoint
{
    execute(buildingId: number, policyId: string): Promise<void>;
}
