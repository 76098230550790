import React, { Component } from 'react';

export default class Presentation extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27142 8.75C4.48438 8.75 3.03571 10.1987 3.03571 11.9857C3.03571 13.7728 4.48438 15.2214 6.27142 15.2214C8.05846 15.2214 9.50713 13.7728 9.50713 11.9857C9.50713 10.1987 8.05846 8.75 6.27142 8.75ZM1.53571 11.9857C1.53571 9.37025 3.65596 7.25 6.27142 7.25C8.88688 7.25 11.0071 9.37025 11.0071 11.9857C11.0071 14.6012 8.88688 16.7214 6.27142 16.7214C3.65596 16.7214 1.53571 14.6012 1.53571 11.9857Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 19.7C0.25 17.6787 1.8715 16.0572 3.89286 16.0572H7.23571C9.25707 16.0572 10.8786 17.6787 10.8786 19.7V22.6143H16.6857C18.0642 22.6143 19.2357 23.7858 19.2357 25.1643C19.2357 26.5253 18.1458 27.7143 16.6857 27.7143H13.7395C14.6767 28.6575 15.25 29.9651 15.25 31.4C15.25 31.8143 14.9142 32.15 14.5 32.15H2.02857C1.85827 32.15 1.69304 32.0921 1.56005 31.9857C0.737619 31.3278 0.25 30.34 0.25 29.2143V19.7ZM10.1286 27.7143H6.46429C6.05007 27.7143 5.71429 27.3785 5.71429 26.9643V21.8215C5.71429 21.4073 6.05007 21.0715 6.46429 21.0715C6.8785 21.0715 7.21429 21.4073 7.21429 21.8215V26.2143H16.6857C17.2827 26.2143 17.7357 25.732 17.7357 25.1643C17.7357 24.6143 17.2358 24.1143 16.6857 24.1143H10.1286C9.71436 24.1143 9.37857 23.7785 9.37857 23.3643V19.7C9.37857 18.5071 8.42864 17.5572 7.23571 17.5572H3.89286C2.69993 17.5572 1.75 18.5071 1.75 19.7V29.2143C1.75 29.7953 1.96067 30.2878 2.31529 30.65H13.676C13.339 28.9695 11.8894 27.7143 10.1286 27.7143Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6659 16.6972C20.7858 16.4254 21.0549 16.25 21.352 16.25H33C33.2528 16.25 33.4887 16.3774 33.6273 16.5889C33.7659 16.8004 33.7886 17.0674 33.6877 17.2993L29.3357 27.2993C29.2166 27.573 28.9465 27.75 28.648 27.75H17C16.5858 27.75 16.25 27.4142 16.25 27V26.9375C16.25 26.5918 16.4863 26.2909 16.8221 26.2089C17.296 26.0932 17.658 25.6756 17.658 25.1875C17.658 24.8875 17.6048 24.7688 17.5572 24.7068C17.3901 24.4893 17.3551 24.198 17.4659 23.9472L20.6659 16.6972ZM21.8408 17.75L18.9883 24.2127C19.1221 24.5371 19.158 24.8758 19.158 25.1875C19.158 25.574 19.0719 25.9319 18.9219 26.25H28.1565L31.8557 17.75H21.8408Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 27C16.25 26.5858 16.5858 26.25 17 26.25H33V27.75H17C16.5858 27.75 16.25 27.4142 16.25 27Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 26.25C24.4142 26.25 24.75 26.5858 24.75 27V32C24.75 32.4142 24.4142 32.75 24 32.75C23.5858 32.75 23.25 32.4142 23.25 32V27C23.25 26.5858 23.5858 26.25 24 26.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3509 0.337187C19.717 0.530992 19.8566 0.984867 19.6628 1.35094L15.1628 9.85094C14.969 10.217 14.5152 10.3567 14.1491 10.1629C13.783 9.96906 13.6434 9.51519 13.8372 9.14911L18.3372 0.649112C18.531 0.283035 18.9848 0.143382 19.3509 0.337187Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6025 0.36398C24.9538 0.144447 25.4165 0.251227 25.636 0.602479L33.136 12.6025C33.3555 12.9537 33.2488 13.4164 32.8975 13.636C32.5463 13.8555 32.0835 13.7487 31.864 13.3975L24.364 1.39748C24.1445 1.04623 24.2513 0.583512 24.6025 0.36398Z" />
            </svg>
        )
    }
};