import { extend } from "@okta/okta-auth-js";
import { appContext } from "../../../../AppContext";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import SelectBox from "../../../../Components/Inputs/SelectBox/SelectBox";
import apis from "../../../../Providers.Api/apis";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export default class Details extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            identityProviderIdList: [],
        };
    }

    private get groupIdCssDisplay(): string
    {
        return (this.props.identityProviderId !== 1 ? 'block' : 'none');
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadIdentityProviders();
    }

    private async loadIdentityProviders(): Promise<void>
    {
        try
        {
            const res = await apis.getIdentityProviders();
            let tempidentityProviderIdList: any = [];

            for (let i = 0; i < res.data.length; i++)
            {
                let tempidentityProviderId = { title: "", value: i }
                tempidentityProviderId.title = res.data[i].name;
                tempidentityProviderId.value = res.data[i].id;
                tempidentityProviderIdList.push(tempidentityProviderId);
            }

            this.setState({ identityProviderIdList: tempidentityProviderIdList });
        }
        catch
        {
        }
    }

    public render(): JSX.Element
    {
        const { id, name, identityProviderId, groupId, loading, isOwner, onChange } = this.props;

        const nameFld = {
            name: "name",
            label: this.labels.HubLabelName,
            value: name ?? "",
            mandatory: true,
            placeholder: "Provide Name",
            onchange: (e: React.ChangeEvent<HTMLInputElement>) =>
            {
                onChange(e.target.value, identityProviderId ?? 0, groupId ?? "", isOwner);
            },
        };

        const IdentityProviderTypeFld = {
            name: "identityProviderType",
            label: this.labels.HubLabelIdentityProviderType,
            value: identityProviderId ?? 0,
            mandatory: true,
            disabled: id !== "0",
            onchange: (value: number) =>
            {
                onChange(name ?? "", value, groupId ?? "", isOwner);
            },
            type: "option",
            options: this.state.identityProviderIdList,
            placeholder: "Provide value",
        };

        const identityProviderGroupIdField = {
            name: "groupId",
            label: this.labels.HubLabelIdentityProviderGroupID,
            value: groupId ?? "",
            mandatory: true,
            placeholder: "00000000-0000-0000-0000-00000000000",
            onchange: (e: React.ChangeEvent<HTMLInputElement>) =>
            {
                onChange(name ?? "", identityProviderId ?? 0, e.target.value, isOwner);
            },
        };

        return (
            <div className="main-content" >
                {loading || (
                    <div className="card card-scroller">
                        <div className="p-45">
                            <form>
                                <div className="row">
                                    <div className="col-6 pr-2">
                                        <div className="input-controls col-12">
                                            <Inputbox {...nameFld} />
                                        </div>
                                        <div className="input-controls col-12">
                                            <SelectBox {...IdentityProviderTypeFld} />
                                        </div>
                                        <div className="input-controls col-12" style={{ display: this.groupIdCssDisplay }}>
                                            <Inputbox {...identityProviderGroupIdField} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="input-controls">
                                            <label>{this.labels.HubLabelIsOwner}</label>
                                        </div>
                                        <div className="switch-field" >
                                            <input
                                                type="radio"
                                                id="radio-one"
                                                name="switch-one"
                                                defaultValue="Disabled"
                                                checked={isOwner === 0}
                                                onChange={() => onChange(name ?? "", identityProviderId ?? 0, groupId ?? "", 0)}
                                            />
                                            <label htmlFor="radio-one">
                                                {this.labels.HubLabelDisabled}
                                            </label>
                                            <input
                                                type="radio"
                                                id="radio-two"
                                                name="switch-one"
                                                defaultValue="Enabled"
                                                checked={isOwner === 1}
                                                onChange={() => onChange(name ?? "", identityProviderId ?? 0, groupId ?? "", 1)}
                                            />
                                            <label htmlFor="radio-two">
                                                {this.labels.HubLabelEnabled}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export interface IProps
{
    id: string;
    name?: string;
    identityProviderId?: number;
    groupId?: string;
    loading: boolean;
    isOwner: number;
    onChange: (name: string, identityProviderId: number, groupId: string, isOwner: number) => void;
}

export interface IState
{
    identityProviderIdList: { title: string, value: number }[];
}
