import { Component } from 'react';
export default class HearingAidIcon extends Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30273 4.91717C6.30273 3.21292 7.59745 1.79492 9.24761 1.79492C10.8978 1.79492 12.204 3.22517 12.204 4.92943C12.204 5.2996 12.14 5.67 12.0235 6.00209C12.0217 6.00708 12.0199 6.01202 12.0179 6.01692L9.70121 11.7761C9.62846 11.9569 9.42289 12.0446 9.24205 11.9718C9.06121 11.8991 8.97358 11.6935 9.04633 11.5127L11.3599 5.76111C11.4483 5.50678 11.4981 5.21821 11.4981 4.92943C11.4981 3.57028 10.4646 2.5008 9.24761 2.5008C8.07839 2.5008 7.0892 3.47661 7.0133 4.75847C8.49064 4.94734 9.61202 6.2912 9.61202 7.87029C9.61202 9.57454 8.30583 11.0048 6.65568 11.0048C6.46075 11.0048 6.30273 10.8468 6.30273 10.6519C6.30273 10.4569 6.46075 10.2989 6.65568 10.2989C7.8727 10.2989 8.90614 9.22943 8.90614 7.87029C8.90614 6.51114 7.8727 5.44166 6.65568 5.44166C6.46075 5.44166 6.30273 5.28365 6.30273 5.08872L6.30273 4.91717Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.2484 0.774242C7.14873 0.774242 5.41096 2.6018 5.41096 4.91731C5.41096 5.11224 5.25294 5.27026 5.05802 5.27026C4.8631 5.27026 4.70508 5.11224 4.70508 4.91731C4.70508 2.25958 6.7129 0.0683594 9.2484 0.0683594C11.7827 0.0683594 13.8034 2.25826 13.8034 4.91731C13.8034 4.93022 13.8027 4.94312 13.8013 4.95596L13.4505 4.91731C13.8013 4.95596 13.8013 4.95592 13.8013 4.95596L13.8008 4.96063L13.7994 4.97307L13.7939 5.01979C13.7891 5.06016 13.782 5.11836 13.7727 5.19052C13.7541 5.33471 13.7266 5.5352 13.6908 5.76067C13.6202 6.20549 13.5143 6.76977 13.3759 7.18823C13.2401 7.59875 12.4449 9.42142 11.6986 11.1101C11.3224 11.9615 10.9542 12.7885 10.6801 13.4025C10.5431 13.7095 10.4295 13.9634 10.3502 14.1405L10.226 14.4177C10.226 14.4178 10.2259 14.418 9.90388 14.2735L10.226 14.4177C10.219 14.4333 10.2108 14.4487 10.2016 14.4631C9.66591 15.3042 8.76457 15.8824 7.71505 15.8824C6.03401 15.8824 4.71678 14.4321 4.71678 12.6911C4.71678 12.4962 4.8748 12.3382 5.06972 12.3382C5.26465 12.3382 5.42267 12.4962 5.42267 12.6911C5.42267 14.0899 6.46984 15.1765 7.71505 15.1765C8.48333 15.1765 9.1675 14.7588 9.5927 14.1049L9.70598 13.8521C9.78517 13.6752 9.8986 13.4216 10.0355 13.1148C10.3094 12.5012 10.6772 11.6751 11.053 10.8248C11.8107 9.11011 12.584 7.33471 12.7057 6.96659C12.8248 6.60641 12.9237 6.09064 12.9936 5.65007C13.0281 5.43285 13.0547 5.23931 13.0727 5.10015C13.0816 5.03062 13.0884 4.97482 13.093 4.9366L13.0975 4.89768C13.0877 2.59279 11.3434 0.774242 9.2484 0.774242Z" fill="inherit" />
                <path d="M13.6666 15.0587V12.574H12.8242V11.9905H15.2054V12.574H14.363V15.0587H13.6666Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2754 0.438477L13.2635 1.45035C13.2153 1.49859 13.3997 1.76117 13.6753 2.03683C13.951 2.31249 14.2136 2.49686 14.2618 2.44862L15.3189 1.39151V0.438477H14.2754Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.51483 15.6648L5.65207 11.5276C5.79446 11.3852 5.68642 11.0463 5.41076 10.7706C5.13509 10.4949 4.79619 10.3869 4.6538 10.5293L0.471305 14.7118V15.6648H1.51483Z" fill="inherit" />
            </svg>
        )
    }
};
