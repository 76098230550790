import { Component } from 'react';

export default class Equipmentroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H17C17.4142 0.25 17.75 0.585786 17.75 1V32C17.75 32.4142 17.4142 32.75 17 32.75H1C0.585786 32.75 0.25 32.4142 0.25 32V1ZM1.75 1.75V31.25H16.25V1.75H1.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 1C16.25 0.585786 16.5858 0.25 17 0.25H33C33.4142 0.25 33.75 0.585786 33.75 1V32C33.75 32.4142 33.4142 32.75 33 32.75H17C16.5858 32.75 16.25 32.4142 16.25 32V1ZM17.75 1.75V31.25H32.25V1.75H17.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 25C3.25 24.5858 3.58579 24.25 4 24.25H14C14.4142 24.25 14.75 24.5858 14.75 25V29C14.75 29.4142 14.4142 29.75 14 29.75H4C3.58579 29.75 3.25 29.4142 3.25 29V25ZM4.75 25.75V28.25H13.25V25.75H4.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.25 25C19.25 24.5858 19.5858 24.25 20 24.25H30C30.4142 24.25 30.75 24.5858 30.75 25V29C30.75 29.4142 30.4142 29.75 30 29.75H20C19.5858 29.75 19.25 29.4142 19.25 29V25ZM20.75 25.75V28.25H29.25V25.75H20.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 4C3.25 3.58579 3.58579 3.25 4 3.25H14C14.4142 3.25 14.75 3.58579 14.75 4V22C14.75 22.4142 14.4142 22.75 14 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22V4ZM4.75 4.75V21.25H13.25V4.75H4.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.25 4C19.25 3.58579 19.5858 3.25 20 3.25H30C30.4142 3.25 30.75 3.58579 30.75 4V22C30.75 22.4142 30.4142 22.75 30 22.75H20C19.5858 22.75 19.25 22.4142 19.25 22V4ZM20.75 4.75V21.25H29.25V4.75H20.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 31.25C3.41421 31.25 3.75 31.5858 3.75 32V33C3.75 33.4142 3.41421 33.75 3 33.75C2.58579 33.75 2.25 33.4142 2.25 33V32C2.25 31.5858 2.58579 31.25 3 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 31.25C19.4142 31.25 19.75 31.5858 19.75 32V33C19.75 33.4142 19.4142 33.75 19 33.75C18.5858 33.75 18.25 33.4142 18.25 33V32C18.25 31.5858 18.5858 31.25 19 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 31.25C15.4142 31.25 15.75 31.5858 15.75 32V33C15.75 33.4142 15.4142 33.75 15 33.75C14.5858 33.75 14.25 33.4142 14.25 33V32C14.25 31.5858 14.5858 31.25 15 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31 31.25C31.4142 31.25 31.75 31.5858 31.75 32V33C31.75 33.4142 31.4142 33.75 31 33.75C30.5858 33.75 30.25 33.4142 30.25 33V32C30.25 31.5858 30.5858 31.25 31 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 19.75H4V18.25H14V19.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 19.75H20V18.25H30V19.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 16.75H4V15.25H14V16.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 16.75H20V15.25H30V16.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 13.75H4V12.25H14V13.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 13.75H20V12.25H30V13.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 10.75H4V9.25H14V10.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 10.75H20V9.25H30V10.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.75H4V6.25H14V7.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 7.75H20V6.25H30V7.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 4L7.75 7L6.25 7L6.25 4L7.75 4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.75 4L23.75 7L22.25 7L22.25 4L23.75 4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.75 7L11.75 10L10.25 10L10.25 7L11.75 7Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.75 7L27.75 10L26.25 10L26.25 7L27.75 7Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 16L9.75 19L8.25 19L8.25 16L9.75 16Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.75 16L25.75 19L24.25 19L24.25 16L25.75 16Z" />
            </svg>
        )
    }
};