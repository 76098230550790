import { Component } from 'react';
export default class CateringIcon extends Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.176 8.00003C15.176 4.03657 11.963 0.823558 7.99954 0.823558V0.117676C12.3528 0.117676 15.8819 3.64672 15.8819 8.00003C15.8819 11.1172 14.0725 13.811 11.4483 15.0898L11.139 14.4552C13.5299 13.2902 15.176 10.837 15.176 8.00003ZM4.41156 1.78343C2.26559 3.0248 0.82307 5.34417 0.82307 8.00003C0.82307 11.0112 2.67777 13.5901 5.30844 14.655C5.35675 14.6745 5.40531 14.6936 5.45414 14.7121L5.20371 15.3721C5.15005 15.3517 5.09667 15.3308 5.04358 15.3093C2.15552 14.1402 0.117188 11.3086 0.117188 8.00003C0.117188 5.082 1.70299 2.53477 4.05811 1.17241L4.41156 1.78343ZM7.99954 15.1765C8.53928 15.1765 9.06469 15.117 9.56973 15.0043L9.72347 15.6932C9.16829 15.8171 8.59133 15.8824 7.99954 15.8824C7.58094 15.8824 7.16972 15.8497 6.76836 15.7867C6.69276 15.7749 6.61751 15.7619 6.54264 15.7479L6.67234 15.0541C6.74048 15.0668 6.80896 15.0786 6.87778 15.0894C7.24308 15.1467 7.61771 15.1765 7.99954 15.1765Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.23575 0.117676C4.43068 0.117676 4.58869 0.275693 4.58869 0.470617V4.00245C4.58869 4.35017 4.73628 4.68155 4.99473 4.91416C5.40193 5.28064 5.63445 5.80272 5.63445 6.35054V14.6928C5.63445 14.96 5.85099 15.1765 6.11811 15.1765C6.38523 15.1765 6.60177 14.96 6.60177 14.6928V6.35054C6.60177 5.80272 6.83428 5.28064 7.24148 4.91416C7.49994 4.68155 7.64752 4.35017 7.64752 4.00245V0.470617C7.64752 0.275693 7.80554 0.117676 8.00046 0.117676C8.19538 0.117676 8.3534 0.275693 8.3534 0.470617V4.00245C8.3534 4.55028 8.12089 5.07236 7.71369 5.43884C7.45523 5.67145 7.30765 6.00283 7.30765 6.35054V14.6928C7.30765 15.3498 6.77507 15.8824 6.11811 15.8824C5.46114 15.8824 4.92856 15.3498 4.92856 14.6928V6.35054C4.92856 6.00283 4.78098 5.67145 4.52252 5.43884C4.11533 5.07236 3.88281 4.55028 3.88281 4.00245V0.470617C3.88281 0.275693 4.04083 0.117676 4.23575 0.117676Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 0.133409C11.4409 0.179081 11.5417 0.315825 11.5417 0.470643V14.6929C11.5417 15.3498 11.0092 15.8824 10.3522 15.8824C9.69524 15.8824 9.16266 15.3498 9.16266 14.6929V10.3758C9.16266 9.64087 8.99391 8.91572 8.66942 8.25627L8.44582 7.80186C8.38097 7.67006 8.35189 7.52349 8.36101 7.37742C8.51323 4.942 9.32024 2.58224 10.6962 0.566315L10.8973 0.271675C10.9846 0.143803 11.145 0.0877378 11.2929 0.133409ZM10.8359 1.66119C9.80149 3.4094 9.19264 5.38757 9.06552 7.42145C9.06403 7.44532 9.06886 7.46923 9.07918 7.49021L9.30278 7.94462C9.67498 8.70103 9.86854 9.5328 9.86854 10.3758V14.6929C9.86854 14.96 10.0851 15.1765 10.3522 15.1765C10.6193 15.1765 10.8359 14.96 10.8359 14.6929V1.66119Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64786 0.117676C5.84279 0.117676 6.0008 0.275693 6.0008 0.470617V3.76473C6.0008 3.95966 5.84279 4.11768 5.64786 4.11768C5.45294 4.11768 5.29492 3.95966 5.29492 3.76473V0.470617C5.29492 0.275693 5.45294 0.117676 5.64786 0.117676Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58732 0.117676C6.78224 0.117676 6.94026 0.275693 6.94026 0.470617V3.76473C6.94026 3.95966 6.78224 4.11768 6.58732 4.11768C6.39239 4.11768 6.23438 3.95966 6.23438 3.76473V0.470617C6.23438 0.275693 6.39239 0.117676 6.58732 0.117676Z" fill="inherit" />
            </svg>
        )
    }
};
