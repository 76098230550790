import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IbssRequestConfig } from "../ApiClient";

export class GetExternalIdentityProvidersEndpoint implements IGetExternalIdentityProvidersEndpoint
{
    public async execute(suppressErrorPopup: boolean): Promise<IExternalIdentityProvider[]>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const response = await axios.get<IExternalIdentityProvider[]>(`${apis.allApi}Auth/allexternalidentityproviders`, axiosConfig);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetExternalIdentityProvidersEndpoint
{
    execute(suppressErrorPopup: boolean): Promise<IExternalIdentityProvider[]>;
}

export interface IExternalIdentityProvider
{
    ClientId: string | null;
    ID: number;
    Name: string;
    SecurityProviderTypeId: number;
    TenantDomain: string | null;
}
