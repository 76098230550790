import { Component } from "react";
import { appContext, IPartialAppState } from "../../../AppContext";
import { MenuItem } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';
import Helper from "../../../Common/Helper";

export default class BuldingSelector extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiCache() { return appContext().apiCache; }
    private get appState() { return appContext().state; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            options: new Array<IOption>(),
            selectedBuildingId: this.appState.buildingId,
            lightModeTheme: this.appState.lightModeTheme,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ selectedBuildingId: i.buildingId, lightModeTheme: i.lightModeTheme }));
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IOption);
        const selectedBuildingId = options.find(i => i.value === this.appState.buildingId)?.value ?? 0;
        this.setState({ options: options, selectedBuildingId: selectedBuildingId });
    }

    private handleClick(selectedBuildingId: number): void
    {
        const building = this.state.options.find(i => i.value === selectedBuildingId);
        const buildingId = building?.value ?? 0;
        const buildingName = building?.label ?? "";
        this.appState.set({ buildingId: buildingId, buildingName: buildingName });
    }

    public render(): JSX.Element
    {
        return (
            <Box>
                {this.state.options.length > 1 && <Box
                    sx={{
                        padding: '16px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        backgroundColor: (theme) => theme.palette.grey[400]
                    }}>
                    <b>{this.labels.HubLabelSelectBuilding}:</b>
                    <Paper elevation={0}>
                        <TextField
                            select
                            id="buildingDropdown"
                            value={this.state.selectedBuildingId}
                            sx={{
                                width: 210,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}>
                            {this.state.options.map(option =>
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    onClick={() => this.handleClick(option.value)}>
                                    {option.label}
                                </MenuItem>
                            )}
                        </TextField>
                    </Paper>
                </Box>}
            </Box>
        );
    }
}

interface IProps
{
}

interface IState
{
    options: IOption[];
    selectedBuildingId: number;
    lightModeTheme: boolean;
}

export interface IOption
{
    label: string,
    value: number,
}
