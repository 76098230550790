import { Component } from 'react';

export default class Sitstanddesk extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 18C16.25 17.5858 16.5858 17.25 17 17.25H28C28.4142 17.25 28.75 17.5858 28.75 18C28.75 18.4142 28.4142 18.75 28 18.75H17C16.5858 18.75 16.25 18.4142 16.25 18Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 17.25C26.4142 17.25 26.75 17.5858 26.75 18V32C26.75 32.4142 26.4142 32.75 26 32.75C25.5858 32.75 25.25 32.4142 25.25 32V18C25.25 17.5858 25.5858 17.25 26 17.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.25 13C18.25 12.5858 18.5858 12.25 19 12.25L28 12.25C28.4142 12.25 28.75 12.5858 28.75 13C28.75 13.4142 28.4142 13.75 28 13.75L19 13.75C18.5858 13.75 18.25 13.4142 18.25 13Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 4C14.25 3.58579 14.5858 3.25 15 3.25H27.4203C27.7075 3.25 27.9716 3.32982 28.1913 3.48668C28.4036 3.63831 28.5314 3.83092 28.6071 3.99326C28.6821 4.15389 28.7159 4.30474 28.732 4.40863C28.7404 4.46245 28.7448 4.50878 28.7472 4.54438C28.7484 4.56231 28.7491 4.57786 28.7495 4.59067L28.7499 4.6079L28.75 4.61496L28.75 4.6181L28.75 4.61957L28.75 4.62028C28.75 4.62062 28.75 4.62097 28.0016 4.62097H28.75V14.8387C28.75 15.6667 28.0475 16.3226 27.2464 16.3226H22.9529V18C22.9529 18.4142 22.6171 18.75 22.2029 18.75C21.7887 18.75 21.4529 18.4142 21.4529 18V15.5726C21.4529 15.1584 21.7887 14.8226 22.2029 14.8226H27.2435C27.2452 14.8218 27.2475 14.8204 27.25 14.8184V4.75H15C14.5858 4.75 14.25 4.41421 14.25 4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.92251 12.254C6.3345 12.2112 6.70319 12.5105 6.74598 12.9225L8.625 31.0112V31.0301C8.62583 31.0318 8.62685 31.0338 8.62811 31.0361C8.64003 31.0576 8.66578 31.0924 8.71045 31.1301C8.80687 31.2115 8.92135 31.25 9 31.25H12.1875C12.2661 31.25 12.3806 31.2115 12.4771 31.1301C12.5217 31.0924 12.5475 31.0576 12.5594 31.0361C12.5606 31.0338 12.5617 31.0318 12.5625 31.0301V31.015L14.2533 12.9302C14.2918 12.5178 14.6574 12.2147 15.0698 12.2533C15.4822 12.2918 15.7853 12.6574 15.7467 13.0698L14.0619 31.0916C14.0466 31.6023 13.7527 32.0161 13.4448 32.2761C13.1194 32.551 12.6713 32.75 12.1875 32.75H9C8.51615 32.75 8.06813 32.551 7.74268 32.2761C7.43569 32.0169 7.14265 31.6047 7.12577 31.0962L5.25401 13.0775C5.21122 12.6655 5.51051 12.2968 5.92251 12.254Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22815 8.25H13.7424C16.0585 8.25 17.9639 9.9337 17.7314 12.1881L17.1942 18.3858C17.1768 19.6895 16.1961 20.6526 15.4116 21.1616L15.3811 21.1814L14.4482 21.6657L13.7571 20.3343L14.6241 19.8843C15.2579 19.4645 15.6944 18.8911 15.6944 18.3506V18.3182L16.2388 12.0385C16.3618 10.8679 15.3867 9.75 13.7424 9.75H7.28828C5.57511 9.8965 4.64739 11.0544 4.76024 12.0308L4.76147 12.0414L5.3056 18.3182V18.3506C5.3056 18.8766 5.72967 19.4553 6.52234 19.8668L7.42301 20.3343L6.73193 21.6657L5.83126 21.1981C4.83141 20.6791 3.82336 19.7119 3.80583 18.3858L3.26887 12.1916C3.03026 10.062 4.97095 8.42753 7.1988 8.25231L7.22815 8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2.75C9.5335 2.75 8.75 3.5335 8.75 4.5C8.75 5.4665 9.5335 6.25 10.5 6.25C11.4665 6.25 12.25 5.4665 12.25 4.5C12.25 3.5335 11.4665 2.75 10.5 2.75ZM7.25 4.5C7.25 2.70507 8.70507 1.25 10.5 1.25C12.2949 1.25 13.75 2.70507 13.75 4.5C13.75 6.29493 12.2949 7.75 10.5 7.75C8.70507 7.75 7.25 6.29493 7.25 4.5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.25 32C23.25 31.5858 23.5858 31.25 24 31.25H28C28.4142 31.25 28.75 31.5858 28.75 32C28.75 32.4142 28.4142 32.75 28 32.75H24C23.5858 32.75 23.25 32.4142 23.25 32Z" />
            </svg>
        )
    }
};