import { Component } from 'react';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as DeskIcon } from "../../../../Pages/Shared/Bookings/List/StandardDesk.svg"

class IconHousing extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    private isPermittedImageFileExtension(fileLocation: string): boolean
    {
        const lowerCaseFileLocation = fileLocation.toLowerCase();
        const fileExtension = lowerCaseFileLocation.split('.').pop();
        const permittedExtensions = ['jpeg', 'jpg', 'png', 'svg'];

        if(!fileExtension)
        {
            return false;
        }

        return permittedExtensions.includes(fileExtension);
    }

    private getTagLabel(): string
    {
        if(this.props.tagType === 'TagTypeComplex' && this.props.value)
        {
            return `${this.props.name}: ${this.props.value}`
        }
        else
        {
            return this.props.name;
        }
    }

    public render(): JSX.Element
    {
        const fileExtensionIsPermitted = this.isPermittedImageFileExtension(this.props.location);
        const tagLabel = this.getTagLabel();

        return (
            <div key={this.props.tagToEventTypeId}>
                {
                    fileExtensionIsPermitted &&
                        <IbssToolTip title={tagLabel} key={this.props.tagToEventTypeId} arrow>
                            <IconButton
                                aria-label="tag-icon"
                                key={this.props.tagToEventTypeId}
                                sx={{border: '1px solid #DCE1E5', backgroundColor: 'white'}}
                                size={'small'}
                            >
                                <img src={this.props.location} alt={tagLabel} style={{height: '16px', width: '16px'}} />
                            </IconButton>
                        </IbssToolTip>
                }
                {
                    !fileExtensionIsPermitted && <IbssToolTip title={tagLabel} key={this.props.tagToEventTypeId} arrow>
                        <Typography fontSize='8px' fontFamily='Source Sans Pro' fontWeight='bold' sx={{border: '1px solid #DCE1E5', color: '#263238', backgroundColor: 'white'}} width='max-content' padding='2px 2px' borderRadius='5px'>
                            {tagLabel}
                        </Typography>
                    </IbssToolTip>
                }
            </div>
        )
    }
}

export default IconHousing;

export interface IProps
{
    tagToEventTypeId: string,
    tagType: string,
    value: string,
    name: string;
    location: string;
}

export interface IState
{
}