import { Component } from 'react';
import { Redirect, RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { appContext } from '../../AppContext';
import { LogOutReason } from '../../Providers.Api/Authentication';

class NotFound extends Component<RouteComponentProps>
{
    private get local() { return appContext().localStorageProvider; }
    private get auth() { return appContext().authentication; }

    public render(): JSX.Element
    {
        if (this.local.getToken())
        {
            this.props.history.goBack();
        }
        else
        {
            this.auth.logOut(LogOutReason.SessionExpired);
        }
        return <></>
    }
}

export default withRouter(NotFound);
