import React from "react";


function TablePanelInfo(props: any) {
    const {iconSrc, tableHeading, tableDescription } = props

    return (
        <div className="table-panel-info">
            <div className="table-panel-heading">
                <div className="table-panel-icon">
                    <img src={iconSrc} alt=""/>
                </div>
                <h1 className="table-panel-header">{tableHeading}</h1>
            </div>
            <div className="table-panel-description">
                {tableDescription}
            </div>
        </div>
    )
}


export default TablePanelInfo;