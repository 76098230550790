import React, { Component } from "react";

export default class Inputbox extends Component {
  state = {
    value: "",
    error: "Provide Value",
  };
  onchange = (e: any) => {
    const { onchange }: any = this.props;
    this.setState({ value: e.target.value });
    onchange(e);
  }; 
  render() {
    const { name, label, placeholder, value, mandatory, readonly }: any = this.props;
    return (
      <div className="input-controls">
        <label>
          {label}
          {mandatory === true &&
            <span className="required"> *</span>
          }
        </label>
        <input
          name={name}
          className="input-box border"
          value={this.state.value || value}
          onChange={(e) => {
            this.onchange(e);
          }}
          placeholder={placeholder}
          readOnly = {readonly ? true : false}
        />
      </div>
    );
  }
}