import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateTagEndpoint implements ICreateTagEndpoint
{
    public async execute(nodeId: number, payload: ITag): Promise<void>
    {
        try
        {
            await axios.post(`${apis.allApiv2}${nodeId}/tags`, payload);
           
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateTagEndpoint
{
    execute(nodeId: number, payload: ITag): Promise<void>;
}

export interface ITag {
    TagType: string,
    Values: string,
    Icon: string,
    Name: string,
    Node_Id: number,
    Description: string,
    Status: string,
} 