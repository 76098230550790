import { ICreateDeleteEndpoint } from "./CreateDeleteEndpoint";
import { IGetManyByMenuIdEndpoint } from "./GetManyByMenuIdEndpoint";

export class CateringSpacesRepository implements ICateringSpacesRepository
{
    private getManyByMenuIdEndpoint: IGetManyByMenuIdEndpoint;
    private createDeleteEndpoint: ICreateDeleteEndpoint;

    constructor(
        getManyByMenuIdEndpoint: IGetManyByMenuIdEndpoint,
        createDeleteEndpoint: ICreateDeleteEndpoint,
    )
    {
        this.getManyByMenuIdEndpoint = getManyByMenuIdEndpoint;
        this.createDeleteEndpoint = createDeleteEndpoint;
    }


    public getManyByMenuId(nodeId: number, menuId: string): Promise<ICateringSpaces[]>
    {
        return this.getManyByMenuIdEndpoint.execute(nodeId, menuId);
    }

    public createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>
    {
        return this.createDeleteEndpoint.execute(nodeId, menuId, itemIdsToCreate, itemIdsToDelete);
    }

}

export interface ICateringSpacesRepository
{
    getManyByMenuId(nodeId: number, menuId: string): Promise<ICateringSpaces[]>;
    createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>;
}

export interface ICateringSpaces
{
    Space_Id: string,
    Space_Name: string,
    Node_Id: number,
    Meta_Serv_Reqs_Catering: number
}
