import "../SpaceCard/SpaceCard.scss";
import { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DonutProgressChart from "../../../Components/Data/DonutProgressChart/IbssDonutProgressChart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { appContext } from "../../../AppContext";
import { Box, Stack, Typography } from "@mui/material";
import { comparisionArrow, dateConverter, donutColor, getBuildingTimeZoneByNodeId, headerSpaceCapaKpiDiff } from "../../../Common/Helper";
import { DateTime } from "luxon";
import upArrow from "../../../assets/images/arrow/Up Green.svg";
import down from "../../../assets/images/arrow/Down Red.svg";
import rightArrow from "../../../assets/images/arrow/Flat.svg";
import Alert from '../../../Components/Miscellaneous/Alert/Alert';
import { ISpacesDailySummary } from "../../../Providers.Api/SpacesDailySummaries/SpacesDailySummaryRepository";

export interface IUtilizationGraph {
  Date: string,
  capacityUtilization: number,
  peakUtilization: number,
  spaceUtilization: number
}

export interface IOutputCard {
  Available: number,
  CouldBeFree: number,
  Date: string,
  InUse: number,
  OutOfService: number
}

class SpaceAnalyticsDetailCard extends Component<Props, State> {
  private labels = appContext().labels;
  private apiClient = appContext().apiClient;
  constructor(props: Props) {
    super(props);
    this.state = new State();
  }

  /* Booking graph converted output */
  public bookingOutput(graphcontent: ISpacesDailySummary[]): void{
    const arr:Array<{OutOfService: number, InUse: number, CouldBeFree: number, Available: number, Date: string }> = [];
    for (let i = 0; i < graphcontent.length; i++) {
      const outOfService = graphcontent[i].S5;
      const inUse = graphcontent[i].S8 + graphcontent[i].S14 + graphcontent[i].S17 + graphcontent[i].S26 + graphcontent[i].S29 + graphcontent[i].S36 + graphcontent[i].S38;
      const couldBeFree = graphcontent[i].S9 + graphcontent[i].S15 + graphcontent[i].S16 + graphcontent[i].S18 + graphcontent[i].S19 + graphcontent[i].S27 + graphcontent[i].S28 + graphcontent[i].S30 + graphcontent[i].S31 + graphcontent[i].S37 + graphcontent[i].S39;
      const available = graphcontent[i].S6 + graphcontent[i].S12 + graphcontent[i].S24;
      const date = DateTime.fromJSDate(new Date(graphcontent[i].Summary_Date.slice(0, 10))).toFormat('dd-MM-yyyy');
      arr.push({ OutOfService: parseFloat(((outOfService / graphcontent[i].Period_Duration) * 100).toFixed(2)), InUse: parseFloat(((inUse / graphcontent[i].Period_Duration) * 100).toFixed(2)), CouldBeFree: parseFloat(((couldBeFree / graphcontent[i].Period_Duration) * 100).toFixed(2)), Available: parseFloat(((available / graphcontent[i].Period_Duration) * 100).toFixed(2)), Date: date });
    }
    this.setState({ outputGraph: arr });
  }

  /* Utilization graph converted output */
  public utilizationOutput(graphContent: ISpacesDailySummary[]): void{
    const arr:Array<{spaceUtilization: number, peakUtilization: number,capacityUtilization: number, Date: string }> = [];
    for (let i = 0; i < graphContent.length; i++) {
      const date =DateTime.fromJSDate(new Date(graphContent[i].Summary_Date.slice(0, 10))).toFormat('dd-MM-yyyy');
      arr.push({
        spaceUtilization: parseFloat((graphContent[i].Period_Space_Utilisation).toFixed(2)), peakUtilization: parseFloat((graphContent[i].Period_Peak_Utilisation).toFixed(2)), capacityUtilization: parseFloat((graphContent[i].Period_Capacity_Utilisation).toFixed(2)), Date: date
      })
    }
    this.setState({ utilizationGraph: arr });
  }

   async componentDidMount(): Promise<void> {
    const buildingTz = getBuildingTimeZoneByNodeId(this.props.buildingNodeId);
    const StartDate = DateTime.fromISO(dateConverter(this.props.onelensSpaceAnalyticsOverviewStartDate)).setZone(buildingTz).startOf('day').toUTC().toISO();
    const EndDate = DateTime.fromISO(dateConverter(this.props.onelensSpaceAnalyticsOverviewEndDate)).setZone(buildingTz).endOf('day').toUTC().toISO();
    const response = await this.apiClient.spacesDailySummaries.getSpacesDailySummary(this.props.buildingNodeId, StartDate, EndDate, this.props.spaceName);
    this.bookingOutput(response);
    this.utilizationOutput(response);
  }

  /*Receiving props */
  public componentWillReceiveProps(para: any): void {
    this.bookingOutput(para.graphResponse)
    this.utilizationOutput(para.utilizationResponse)
  }

  public getKpiDiffValue(): number 
  {
    return this.props.occupancyVal - this.props.capacityPer;
  }

  Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  public render(): JSX.Element {
    let item = this.props;
    return (
      <>
        <Grid container spacing={3}>
          <br />
          <br />
        </Grid>


        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                 <Box className="search-result p-20" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  <Grid item xs={12} className="d-flex my-3px">
                  <DonutProgressChart
                      strokeWidth={7}
                      value={this.props.capacityPer}
                      strokeColor={donutColor(this.props.capacityPer)}
                      fontColor={`var(--ui-text)`}
                      portionColor={"#DFE2E7"}
                      donutSize={50}
                    />
                    <div className="px-10">
                      <h3 className="text-ui-20 my-0 weight-400">
                      {this.labels.HublabelSpaceUtilization}
                      </h3>
                      <h5 className="text-ui-14 pt-10 my-0">
                      {this.labels.HubLabelCapacity}: {this.props.capacityVal}
                      </h5>
                    </div>
                  </Grid>
                  </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="search-result p-20" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  <Grid item xs={12} className="d-flex my-3px">
                    <div className="kpi-analytics-bg">
                      {this.props.occupancyVal}%
                    </div>
                    <div className="px-10">
                      <h3 className="text-ui-20 my-0 weight-400">
                        {this.labels.HublabelKpitaget}
                      </h3>
                      <h5 className="text-ui-14 pt-10 my-0">
                        {headerSpaceCapaKpiDiff(this.getKpiDiffValue())}
                        &nbsp;&nbsp;{Math.abs(Math.round(this.getKpiDiffValue()))}%
                      </h5>
                    </div>
                    <div className="d-flex align-items-end">
                      <img
                        src={comparisionArrow(
                          this.getKpiDiffValue(),
                          upArrow,
                          down,
                          rightArrow
                        )}
                        alt={"arrow"}
                        height={10}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="search-result p-20 mt-8">
                <div className="px-10">
                      <h2 className="text-ui-20 my-0" >
                      <b>{this.labels.HubLabelWhereIsThisSapce}</b>
                      </h2>
                    </div>
                  <Box className="d-flex mt-16">
                    <Stack className="mr-50 ai-center"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                      <div><img
                        src={`/images/SpaceType_Icons/${this.props.lightModeTheme ? "Light_Theme" : "Dark_Theme"
                          }/Active_floor.svg`} />
                      </div>
                      <div> 
                      <div>
                      <h5 className="text-ui-20 pt-10 my-0 my-lg-n4">
                      {this.props.spaceName}
                      </h5>
                      </div>
                       </div>
                    </Stack>
                    {this.props.zone ?
                      <Stack className="ai-center"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                      >
                        <div><img
                          src={`/images/SpaceType_Icons/${this.props.lightModeTheme ? "Light_Theme" : "Dark_Theme"
                            }/Zone.svg`}
                        /></div>
                        <div> 
                        <div>
                          <h5 className="text-ui-20 pt-10 my-0 my-lg-n4">
                          {this.labels.HubLabelZone} {this.props.zone}
                          </h5>
                        </div>
                         </div>
                      </Stack>
                      : ""}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ mb: '2' }}>
            <Box className="search-result p-0 h-100">
              <Card className="cardimg ">
                <Card.Img className="" src={this.props.imageURI ? this.props.imageURI : this.state.imagePaths.get(this.props.spaceType)} />
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} className="mb-32">
            <Box className="search-result px-0">
            <div className="pl-2">
                      <h2 className="text-ui-20 my-0" >
                          {this.labels.HubLabelHowisthespaceused}
                      </h2>
            </div>
              <hr />
              <div className="px-20">
                {(this.state.outputGraph).length === 0 ?
                <Alert key="noResults" title={"There are currently no spaces used data available"} text={`Based on your search criteria we've found no spaces used available, please adjust your criteria and try again.`} />:
                  <>
                  <ResponsiveContainer width={'100%'} height={340}>
                    <BarChart data={this.state.outputGraph}>
                      <CartesianGrid vertical={false} />
                      <XAxis stroke="var(--ui-text)" dataKey="Date" />
                      <YAxis
                        stroke="var(--ui-text)"
                        label={{
                          value: this.labels.HubLabelTIMESPENT,
                          angle: -90,
                          position: "insideLeft",
                          fill: "var(--ui-text)",
                        }}
                      />
                      <Tooltip
                        contentStyle={{
                          color: "var(--ui-text)",
                          backgroundColor: "var(--ui-background-alternate)",
                        }}
                      />
                      <Legend />
                      <Bar
                        dataKey="OutOfService"
                        name={this.labels.HublabelOutOfService}
                        stackId="a"
                        fill="#7A7B82"
                      />
                      <Bar
                        dataKey="InUse"
                        name={this.labels.HublabelInUse}
                        stackId="a"
                        fill="#F44336"
                      />
                      <Bar
                        dataKey="CouldBeFree"
                        name={this.labels.HublabelCouldBeFree}
                        stackId="a"
                        fill="#FFC400"
                      />
                      <Bar
                        dataKey="Available"
                        name={this.labels.HubLabelAvailable}
                        stackId="a"
                        fill="#00C853 "
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  <Typography sx={{ display: 'flex', justifyContent: 'center', color: 'var(--ui-text-alternate )' }}>
                  <p className="text-ui-20 my-0" >
                    {this.labels.HubLabelDate}
                  </p>
                  </Typography>
                  </>
                }
               
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: '2', mt: '0' }}>
            <Box className="search-result px-0">
            <div className="pl-2">
                      <h2 className="text-ui-20 my-0" >
                      {this.labels.HubLabelUtilization}
                      </h2>
            </div>
              <hr />
              <div className="px-20">
                {(this.state.outputGraph).length === 0 ?
                  <>
                  <Alert key="noResults" title={"There are currently no Utilisation data available"} text={`Based on your search criteria we've found no Utilisation data available, please adjust your criteria and try again.`} />
                  </>:
                  <>
                  <ResponsiveContainer width={'100%'} height={340}>
                  <BarChart data={this.state.utilizationGraph}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Date" />
                    <YAxis
                      stroke="var(--ui-text)"
                      label={{
                        value: this.labels.HubLabelUtilisation,
                        angle: -90,
                        position: "insideLeft",
                        fill: "var(--ui-text)",
                      }} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="spaceUtilization" name={"Space Utilization"} fill="#2984FF" />
                    <Bar dataKey="capacityUtilization" name={"Capacity Utilization"} fill="#6CC1A7" />
                    <Bar dataKey="peakUtilization" name={"Peak Utilization"} fill="#FE774E" />
                  </BarChart>
                </ResponsiveContainer>
                <Typography sx={{ display: 'flex', justifyContent: 'center', color: 'var(--ui-text-alternate )' }}>
                <p className="text-ui-20 my-0" >
                  {this.labels.HubLabelDate}
                </p>
                  </Typography>
                  </>
                }
                
              </div>
            </Box>
          </Grid>
        </Grid>

      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    onelensSpaceAnalyticsOverviewStartDate: state.onelensSpaceAnalyticsOverview.fmsfc_start_date_for_filter_modal,
    onelensSpaceAnalyticsOverviewEndDate: state.onelensSpaceAnalyticsOverview.End_Date_For_filter_modal,
    buildingNodeId: state.onelensSpaceAnalyticsOverview.buildingNodeId,
    periodType: state.onelensSpaceAnalyticsOverview.periodTypeValue
  };
};

export default connect(mapStateToProps)(SpaceAnalyticsDetailCard);
export class Props {
  public lightModeTheme = "";
  public capacityPer = 0;
  public capacityVal = 0;
  public spaceName = "";
  public occupanyDiff = 0;
  public occupancyVal = 0;
  public onelensSpaceAnalyticsOverviewStartDate = "";
  public onelensSpaceAnalyticsOverviewEndDate = "";
  public buildingNodeId = 0;
  public zone = "";
  public imageURI = "";
  public spaceType = "";
  public graphResponse:Array<ISpacesDailySummary> = [];
  public utilizationResponse:Array<ISpacesDailySummary> = [];
  public periodType = "";
}

export class State {
  public outputGraph: Array<IOutputCard> = [];
  public utilizationGraph: Array<IUtilizationGraph> = [];
  public imagePaths = new Map<string, string>();

  constructor() {
    //Workspaces
    this.imagePaths.set("Desk", "/images/spaceImages/space_desk.png");
    this.imagePaths.set("SitStandDesk", "/images/spaceImages/space_sitstanddesk.png");
    this.imagePaths.set("SpecialistDesk", "/images/spaceImages/space_specialistdesk.png");
    this.imagePaths.set("MeetingRoom", "/images/spaceImages/space_meetingroom.png");
    this.imagePaths.set("MeetingBooth", "/images/spaceImages/space_meetingbooth.png");
    this.imagePaths.set("PhoneBooth", "/images/spaceImages/space_phonebooth.png");
    this.imagePaths.set("FocusBooth", "/images/spaceImages/space_focusBooth.png");
    this.imagePaths.set("Touchdown", "/images/spaceImages/space_touchdown.png");
    this.imagePaths.set("CollaborationSpace", "/images/spaceImages/space_collaborationspace.png");
    this.imagePaths.set("Office", "/images/spaceImages/space_office.png");
    this.imagePaths.set("PrivateRoom", "/images/spaceImages/space_privateroom.png");
    this.imagePaths.set("VCRoom", "/images/spaceImages/space_vcroom.png");
    this.imagePaths.set("Boardroom", "/images/spaceImages/space_boardroom.png");
    this.imagePaths.set("SharedQuietRoom", "/images/spaceImages/space_quietroom.png");
    this.imagePaths.set("LectureTheatre", "/images/spaceImages/space_lecturetheatre.png");

    //Supportspaces
    this.imagePaths.set("InformalLounge", "/images/spaceImages/space_informallounge.png");
    this.imagePaths.set("TrainingRoom", "/images/spaceImages/space_trainingroom.png");
    this.imagePaths.set("BenchSeating", "/images/spaceImages/space_benchseating.png");
    this.imagePaths.set("Storage", "/images/spaceImages/space_storage.png");
    this.imagePaths.set("PrintRoom", "/images/spaceImages/space_printroom.png");
    this.imagePaths.set("Lockers", "/images/spaceImages/space_lockers.png");
    this.imagePaths.set("Stationery", "/images/spaceImages/space_stationery.png");
    this.imagePaths.set("PostRoom", "/images/spaceImages/space_postroom.png");

    //Amenities
    this.imagePaths.set("Reception", "/images/spaceImages/amenity_reception.png");
    this.imagePaths.set("WaitingLounge", "/images/spaceImages/amenity_waitinglounge.png");
    this.imagePaths.set("Restaurant", "/images/spaceImages/amenity_restaurant.png");
    this.imagePaths.set("Café", "/images/spaceImages/amenity_cafe.png");
    this.imagePaths.set("Kitchenette", "/images/spaceImages/amenity_kitchenette.png");
    this.imagePaths.set("Bar", "/images/spaceImages/amenity_bar.png");
    this.imagePaths.set("LiftLobby", "/images/spaceImages/amenity_liftlobby.png");
    this.imagePaths.set("TreatmentRoom", "/images/spaceImages/amenity_treatmentroom.png");
    this.imagePaths.set("BOHOffice", "/images/spaceImages/space_office.png");
    this.imagePaths.set("Gym", "/images/spaceImages/amenity_gym.png");
    this.imagePaths.set("Changing", "/images/spaceImages/amenity_changing.png");
    this.imagePaths.set("UnisexWCs", "/images/spaceImages/amenity_toilets.png");
    this.imagePaths.set("MaleWC", "/images/spaceImages/amenity_toiletsmale.png");
    this.imagePaths.set("FemaleWC", "/images/spaceImages/amenity_toiletsfemale.png");
    this.imagePaths.set("AccessibleWC", "/images/spaceImages/amenity_toilets.png");
    this.imagePaths.set("BikeStore", "/images/spaceImages/amenity_bikestore.png");
    this.imagePaths.set("FirstAid", "/images/spaceImages/amenity_firstaid.png");
    this.imagePaths.set("MothersRoom", "/images/spaceImages/amenity_mothersroom.png");
    this.imagePaths.set("Security", "/images/spaceImages/amenity_security.png");
    this.imagePaths.set("CoatStorage", "/images/spaceImages/amenity_coatstorage.png");
    this.imagePaths.set("IndoorGarden", "/images/spaceImages/amenity_indoorgarden.png");
    this.imagePaths.set("PrayerRoom", "/images/spaceImages/amenity_prayerroom.png");
    this.imagePaths.set("Prayer", "/images/spaceImages/amenity_prayer.png");
    this.imagePaths.set("CleaningStation", "/images/spaceImages/amenity_cleaningstation.png");
    this.imagePaths.set("PlantRoom", "/images/spaceImages/ancillary_plantroom.png");
    this.imagePaths.set("Riser", "/images/spaceImages/ancillary_riser.png");
    this.imagePaths.set("EquipmentRoom", "/images/spaceImages/ancillary_equipmentroom.png");
    this.imagePaths.set("WellnessRoom", "/images/spaceImages/amenity_wellness.png");
    this.imagePaths.set("Pantry", "/images/spaceImages/amenity_pantry.png");
    this.imagePaths.set("WaterPoint", "/images/spaceImages/amenity_waterpoint.png");
    this.imagePaths.set("ParkingSpace", "/images/spaceImages/amenity_parkingspace.png");
    this.imagePaths.set("ParcelLocker", "/images/spaceImages/amenity_parcellocker.png");
  }
}
