
export class PoEditorParser
{
    private labels = new Map<string, string | IPoEditorLabel>();

    public async read(fileName: string): Promise<void>
    {
        // get default labels
        let defaultLabels: (IPoEditorLabels | null) = null;
        const defaultLabelsPath = `./en/${fileName}`;

        try
        {
            const module = await import(`${defaultLabelsPath}`) as { default: IPoEditorLabels };
            defaultLabels = module.default;
            console.log(`Loaded the default labels from '${defaultLabelsPath}'.`);
        }
        catch
        {
            console.error(`Failed to load the default labels from '${defaultLabelsPath}'.`);
            defaultLabels = null;
        }

        // get labels for locale
        let preferredLabels: (IPoEditorLabels | null) = [];
        const languages = navigator.languages;

        for (var i = 0; i < languages.length; i++)
        {
            try
            {
                const preferredLabelsPath = `./${languages[i]}/${fileName}`;
                const module = await import(`${preferredLabelsPath}`) as { default: IPoEditorLabels };
                preferredLabels = module.default;
                console.log(`Loaded the labels for the current locale from '${preferredLabelsPath}'.`);
                break;
            }
            catch
            {
                preferredLabels = null;
            }
        }

        if (preferredLabels == null)
        {
            console.error("Failed to load the labels for the current locale.");
        }

        // aggregate labels
        for (let labels of [defaultLabels, preferredLabels])
        {
            if (labels == null)
            {
                continue;
            }
            else if (Array.isArray(labels))
            {
                for (let label of labels)
                {
                    this.labels.set(label.term.toLocaleLowerCase(), label);
                }
            }
            else if (typeof (labels) == "object")
            {
                for (let term in labels)
                {
                    this.labels.set(term.toLocaleLowerCase(), labels[term]);
                }
            }
        }
    }

    public simple(term: string): string
    {
        const label = this.labels.get(term.toLocaleLowerCase());
        if (label == null)
        {
            return `[${term}]`;
        }
        else if (typeof (label) == "string")
        {
            return label;
        }
        else if (typeof (label.definition) == "string")
        {
            return label.definition;
        }
        else
        {
            return `[${term}]`;
        }
    }

    public plural(term: string, size: number): string
    {
        const label = this.labels.get(term.toLocaleLowerCase());
        if (label == null || typeof (label) == "string" || typeof (label.definition) == "string")
        {
            return `[${term}]`;
        }

        const definition = label.definition;
        if (size == 1)
        {
            return definition.one ?? definition.other;
        }
        else
        {
            return definition.other;
        }
    }
}

type IPoEditorLabels = (IPoEditorSimpleLabels | IPoEditorComplexLabels)
type IPoEditorSimpleLabels = { [key: string]: string }
type IPoEditorComplexLabels = Array<IPoEditorLabel>;

interface IPoEditorLabel
{
    term: string;
    definition: string | IPoEditorLabelDefinition;
    context?: string;
    term_plural?: string;
    reference?: string;
    comment?: string;
}

interface IPoEditorLabelDefinition
{
    zero?: string;
    one?: string;
    two?: string;
    few?: string;
    many?: string;
    other: string;
}
