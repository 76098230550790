import { DataGridProps, GridPagination, GridValidRowModel } from "@mui/x-data-grid";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";

export class Pager extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        const { onResize, ...otherProps } = this.props;
        return (<GridPagination {...otherProps} labelDisplayedRows={() => (<>{this.props.fromRow} to {this.props.toRow}</>)} />);
    }
}

export interface IProps<R extends GridValidRowModel = any> extends Omit<DataGridProps<R>, "slots" | "slotProps" | 'paginationMode' | 'rows' | 'rowCount'>
{
    fromRow: number;
    toRow: number;
}

export interface IState
{
}
