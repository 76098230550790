import { Component } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { appContext } from '../../../../AppContext';
import { VisitShort } from '../../../../Providers.Api/Visits/VisitsRepository';
import { Card } from "@mui/material";


class VisitorsTodayChartWidget extends Component<IProps, Record<string, never>>
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        const upcomingVisits = this.props.visits.filter(x => x.Visit_Status == 'Approved');
        const checkedInVisits = this.props.visits.filter(x => x.Visit_Status == 'Checked In');
        const checkedOutVisits = this.props.visits.filter(x => x.Visit_Status == 'Checked Out');
        const visitorsToday = this.props.visits.filter(x => x.Visit_Status == 'Approved' || x.Visit_Status == 'Checked In' || x.Visit_Status == 'Checked Out');

        const data = [
            { name: this.labels.HubLabelsUpcoming, value: upcomingVisits.length, count: upcomingVisits.length },
            { name: this.labels.HubLabelsInBuilding, value: checkedInVisits.length, count: checkedInVisits.length },
            { name: this.labels.HubLabelsCheckedOut, value: checkedOutVisits.length, count: checkedOutVisits.length }]
        const colors = ['#2962FF', '#FFC400', '#00C853']
        const emptyPieData = [{ name: "No Data", value: 100, count: 0 }];
        const noDataColor = ['#393D44'];

        return (
            <Card>
                <div className='d-flex'>
                    {
                        visitorsToday.length == 0 ?
                            <PieChart width={215} height={170}>
                                <Pie
                                    stroke="none"
                                    data={emptyPieData}
                                    innerRadius="40"
                                    outerRadius="65"
                                    paddingAngle={0}
                                    dataKey="value"
                                    startAngle={180}
                                    endAngle={-360}
                                >
                                    {emptyPieData.map((item, index) => (
                                        <Cell
                                            key={item.name}
                                            fill={noDataColor[index % noDataColor.length]}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                            :
                            <PieChart width={215} height={170}>
                                <Pie
                                    stroke="none"
                                    data={data}
                                    innerRadius="40"
                                    outerRadius="65"
                                    paddingAngle={0}
                                    dataKey="value"
                                    startAngle={180}
                                    endAngle={-360}
                                >
                                    {data.map((item, index) => (
                                        <Cell
                                            key={item.name}
                                            fill={colors[index % colors.length]}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                    }
                    <div style={{ paddingTop: '30px' }}>
                        <div style={{ fontSize: '20px' }} >{this.labels.HubLabelsVisitorsToday}</div>
                        <div style={{ fontSize: '65px' }} >{visitorsToday.length}</div>
                    </div>
                </div>
                <div>
                    {data.length !== 0 && (
                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            {data.map((item, i) => (
                                <div key={i} className='m-4'>
                                    <div className='mb-1'>{item.name}</div>
                                    <div className='d-flex'>
                                        <div
                                            className="statusbox mr-2"
                                            style={{
                                                backgroundColor: colors[i],
                                            }}
                                        ></div>
                                        <div style={{ marginTop: '-3px' }}>{item.count}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Card>
        )
    }
}

export default VisitorsTodayChartWidget;

export interface IProps
{
    visits: VisitShort[];
}