import { Component } from "react";
import { Avatar, Box, Typography, List, Tooltip } from "@mui/material";
import IbssChip from "../../Navigation/Chip/IbssChip";
import { TagToEventTypeView} from "./BookingTagPicker";
import IbssToolTip from "../../Miscellaneous/Tooltip/IbssToolTip";

class TagsChips extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    private async handleDelete(tagId: string): Promise<void>
    {
        await this.props.onDelete(tagId);
    }

    private getTagLabel(tag: TagToEventTypeView): string
    {
        const matchingTagToEventType = this.props.selectedTags.find(tagToEventType => tagToEventType.tagId === tag.tagId);
        if(matchingTagToEventType)
        {
            // add TagToEventType value if 1. there is a value, 2. there is only one value, not a semi-colon ; delimited list of values.
            const complexTagOptionLabel = matchingTagToEventType.value.length > 0 && !matchingTagToEventType.value.includes(';') ? `: ${matchingTagToEventType.value}` : '';
            const tagLabel = tag.name + complexTagOptionLabel;
            return tagLabel;
        }
        else
        {
            return tag.name;
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                <List key={'tag-chips-list'} sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                    {this.props.selectedTags.map(tag => 
                        {
                            return (
                                <Box key={tag.tagId}>
                                    {
                                        tag.icon && 
                                        <IbssChip
                                            key={tag.tagId}
                                            avatar={
                                                <Avatar 
                                                    alt={'tag'} 
                                                    sx={{backgroundColor: 'white'}}
                                                >
                                                    <img src={tag.icon} alt={tag.name} style={{height: '16px', width: '16px'}} />
                                                </Avatar>
                                            }
                                            label={<IbssToolTip title={this.getTagLabel(tag)} placement="bottom" arrow><Typography sx={{fontFamily: 'Source Sans Pro', fontWeight:'bold'}} noWrap>{this.getTagLabel(tag)}</Typography></IbssToolTip>} 
                                            onDelete={this.props.canDelete? ()=> this.handleDelete(tag.tagId) : undefined}
                                            sx={{height: '40px',maxWidth:'250px'}}
                                            variant={'filled'}
                                        />
                                    }
                                    {
                                        !tag.icon && 
                                        <IbssChip
                                            key={tag.tagId}
                                            label={<IbssToolTip title={this.getTagLabel(tag)} placement="bottom" arrow><Typography sx={{fontFamily: 'Source Sans Pro', fontWeight:'bold'}}  noWrap>{this.getTagLabel(tag)}</Typography></IbssToolTip>} 
                                            onDelete={this.props.canDelete? ()=> this.handleDelete(tag.tagId) : undefined}
                                            sx={{height: '40px',maxWidth:'250px'}}
                                            variant={'filled'}
                                        />

                                    }
                                </Box>
                            )
                        }
                    )}
                </List>
            </>
        )
    }
}

export default TagsChips;

export interface IProps
{
    canDelete: boolean,
    selectedTags: TagToEventTypeView[],
    onDelete: (tagId: string) => Promise<void>;
}

export interface IState
{
}