import { Component } from 'react';

export default class Lightson extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.12531 16.1857C6.12531 10.3608 10.868 5.61816 16.6929 5.61816C22.541 5.61816 27.2849 10.3596 27.2849 16.1857C27.2849 20.158 25.0984 23.5944 21.8742 25.4009V26.4929C21.8742 27.4457 21.0977 28.2222 20.1449 28.2222H13.2653C12.3124 28.2222 11.536 27.4457 11.536 26.4929V25.4009C8.31175 23.5944 6.12531 20.158 6.12531 16.1857ZM16.6929 7.11816C11.6964 7.11816 7.62531 11.1893 7.62531 16.1857C7.62531 19.7337 9.65781 22.7866 12.6234 24.2807C12.8764 24.4082 13.036 24.6672 13.036 24.9505V26.4929C13.036 26.6173 13.1409 26.7222 13.2653 26.7222H20.1449C20.2693 26.7222 20.3742 26.6173 20.3742 26.4929V24.9505C20.3742 24.6672 20.5338 24.4082 20.7868 24.2807C23.7524 22.7866 25.7849 19.7337 25.7849 16.1857C25.7849 11.1905 21.715 7.11816 16.6929 7.11816Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5153 27.4967C12.5153 27.0825 12.8511 26.7467 13.2653 26.7467H20.1449C20.5591 26.7467 20.8949 27.0825 20.8949 27.4967V28.9657C20.8949 29.9185 20.1184 30.695 19.1656 30.695H14.2446C13.2918 30.695 12.5153 29.9185 12.5153 28.9657V27.4967ZM14.0153 28.2467V28.9657C14.0153 29.0901 14.1202 29.195 14.2446 29.195H19.1656C19.29 29.195 19.3949 29.0901 19.3949 28.9657V28.2467H14.0153Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5191 29.9449C13.5191 29.5307 13.8549 29.1949 14.2691 29.1949H19.1656C19.5798 29.1949 19.9156 29.5307 19.9156 29.9449C19.9156 31.7057 18.4781 33.1432 16.7173 33.1432C14.9566 33.1432 13.5191 31.7057 13.5191 29.9449ZM15.1954 30.6949C15.4742 31.255 16.0536 31.6432 16.7173 31.6432C17.3811 31.6432 17.9605 31.255 18.2392 30.6949H15.1954Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7051 10.6289C13.6229 10.6289 11.1359 13.135 11.1359 16.1981C11.1359 16.6123 10.8001 16.9481 10.3859 16.9481C9.97171 16.9481 9.63593 16.6123 9.63593 16.1981C9.63593 12.3101 12.791 9.12891 16.7051 9.12891C17.1193 9.12891 17.4551 9.46469 17.4551 9.87891C17.4551 10.2931 17.1193 10.6289 16.7051 10.6289Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2338 13.1956C27.0929 12.8061 27.2945 12.3761 27.684 12.2353L30.325 11.28C30.7146 11.1391 31.1445 11.3406 31.2854 11.7302C31.4263 12.1197 31.2248 12.5497 30.8352 12.6905L28.1942 13.6458C27.8047 13.7867 27.3747 13.5852 27.2338 13.1956Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.30673 13.1956C5.44762 12.8061 5.24606 12.3761 4.85655 12.2353L2.21549 11.28C1.82597 11.1391 1.39599 11.3406 1.25511 11.7302C1.11422 12.1197 1.31577 12.5497 1.70529 12.6905L4.34634 13.6458C4.73586 13.7867 5.16584 13.5852 5.30673 13.1956Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5948 8.25384C24.305 7.95791 24.3099 7.48307 24.6058 7.19324L26.7122 5.13027C27.0081 4.84044 27.483 4.84538 27.7728 5.14131C28.0626 5.43724 28.0577 5.91208 27.7618 6.20191L25.6554 8.26488C25.3595 8.55471 24.8846 8.54977 24.5948 8.25384Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9458 8.25384C8.23563 7.95791 8.23069 7.48307 7.93476 7.19324L5.82838 5.13027C5.53245 4.84044 5.0576 4.84538 4.76777 5.14131C4.47794 5.43724 4.48289 5.91208 4.77882 6.20191L6.8852 8.26488C7.18113 8.55471 7.65597 8.54977 7.9458 8.25384Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5755 5.56067C20.207 5.37163 20.0614 4.91961 20.2505 4.55105L21.6547 1.81322C21.8438 1.44466 22.2958 1.29913 22.6644 1.48817C23.0329 1.67721 23.1785 2.12923 22.9894 2.49779L21.5852 5.23561C21.3961 5.60417 20.9441 5.74971 20.5755 5.56067Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1325 5.56067C12.501 5.37163 12.6466 4.91961 12.4575 4.55104L11.0533 1.81322C10.8642 1.44466 10.4122 1.29913 10.0436 1.48817C9.67508 1.67721 9.52955 2.12923 9.71859 2.49779L11.1228 5.23561C11.3119 5.60418 11.7639 5.74971 12.1325 5.56067Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7039 4.57814C17.1181 4.57881 17.4544 4.24356 17.4551 3.82935L17.46 0.752403C17.4607 0.33819 17.1254 0.00186432 16.7112 0.00119765C16.297 0.000530986 15.9607 0.335776 15.96 0.749989L15.9551 3.82693C15.9544 4.24115 16.2896 4.57747 16.7039 4.57814Z" />
            </svg>
        )
    }
};