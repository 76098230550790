import { Avatar } from "@mui/material";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";

export default class IbssAvatar extends IbssComponent<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state = { initials: "" };
    }

    public componentDidMount(): void
    {
        const initials = this.props
            .fullName
            .split(' ')
            .filter(word => word.length > 0)
            .slice(0, 2)
            .map(word => word[0])
            .join('')
            .toUpperCase();

        this.setState({ initials: initials });
    }

    public render(): JSX.Element
    {
        return (
            <Avatar
                sx={{
                    width: "32px",
                    height: "32px",
                    border: "solid #C5D2DD 1px",
                    backgroundColor: "#DAE9F5",
                    color: "#263238",
                    fontSize: "14px",
                    fontWeight: "normal",
                }}
            >
                {this.state.initials || null}
            </Avatar>
        );
    }
}

export interface IProps
{
    fullName: string;
}

export interface IState
{
    initials: string;
}
