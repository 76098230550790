import { Card, CardContent, Grid } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import AccessCardImage from "./AccessCard.svg";
import DownImage from "./Down.svg";
import SameImage from "./Same.svg";
import UpImage from "./Up.svg";

export class KpiDataCard extends IbssComponent<IProps, IState>
{
    public render(): JSX.Element
    {
        const { value, valueForPreviousPeriod, rollingAverage } = this.props;

        const previousPeriodPercentChange = (valueForPreviousPeriod == 0 && value == 0 ? 0 : (100 * (valueForPreviousPeriod / value) - 100));
        const previousPeriodPercentChangeAsString = (previousPeriodPercentChange < -1000 ? "<1000" : (previousPeriodPercentChange > 1000 ? ">1000" : previousPeriodPercentChange.toFixed(0)));
        const previousPeriodImage = (previousPeriodPercentChange < 0 ? DownImage : (previousPeriodPercentChange > 0 ? UpImage : SameImage));

        const rollingAveragePercentChange = (rollingAverage == 0 && value == 0 ? 0 : (100 * (rollingAverage / value) - 100));
        const rollingAveragePercentChangeAsString = (rollingAveragePercentChange < -1000 ? "<1000" : (rollingAveragePercentChange > 1000 ? ">1000" : rollingAveragePercentChange.toFixed(0)));
        const rollingAverageImage = (rollingAveragePercentChange < 0 ? DownImage : (rollingAveragePercentChange > 0 ? UpImage : SameImage));

        return (
            <Card sx={{ height: "100%", backgroundColor: this.props.backgroundColour }}>
                <CardContent>
                    <div>{this.props.title}</div>
                    <Grid container spacing={1} alignItems='center' justifyContent='center' flexDirection='row'>
                        <Grid item flexGrow={1} sx={{ fontSize: '3rem', color: 'var(--ui-text-alternate)' }}>
                            {this.props.value.toFixed(1)}
                        </Grid>
                        <Grid item flexGrow={1}>
                            <img src={AccessCardImage} style={{ width: 64, height: 66 }} />
                        </Grid>
                        <Grid item flexGrow={1}>
                            <div>Previous period</div>
                            <div>{previousPeriodPercentChangeAsString}% <img src={previousPeriodImage} style={{ width: 22, height: 22, position: 'relative', top: '5px' }} /></div>
                            <div>Against rolling average</div>
                            <div>{rollingAveragePercentChangeAsString}% <img src={rollingAverageImage} style={{ width: 22, height: 22, position: 'relative', top: '5px' }} /></div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export interface IProps
{
    backgroundColour: string;
    title: string;
    value: number;
    valueForPreviousPeriod: number;
    rollingAverage: number;
}

export interface IState
{
}

interface IPercentageChange
{
    value: number;
    isInfinity: boolean;

}
