import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DateTime } from "luxon";

export class CreateTaskEndpoint implements ICreateTaskEndpoint
{
    public async execute(nodeId: number, payload: ICreateTask): Promise<string>
    {
        const primitivePayload = {
            ...payload,
            Booking_Start: payload.Booking_Start.toISO(),
            Catering_Service_Time: payload.Catering_Service_Time.toISO(),
            Catering_Clearing_Time: payload.Catering_Clearing_Time.toISO(),
          };
        try
        {
            const response = await axios.post(apis.allApi + nodeId + '/Tasks', primitivePayload);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateTaskEndpoint
{
    execute(nodeId: number, payload: ICreateTask): Promise<string>;
}

export interface ICreateTask
{
    Space_Id: string;
    Task_Category_Id: number;
    Task_Type_Id: number;
    Task_Description: string;
    Booking_Start: DateTime;
    Catering_Service_Time: DateTime;
    Catering_Clearing_Time: DateTime;
    Catering_Attendees: number;
    Catering_Notes: string;
    DisableExtUpdate: false;
}

export interface ICostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}