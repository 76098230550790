import { Component } from 'react';


export default class RightArrowIcon extends Component {
    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" style={{ overflow: "visible" }} >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.18436 2.44925C7.39111 2.2425 7.72631 2.2425 7.93306 2.44925L17.4625 11.9787C17.6692 12.1854 17.6692 12.5206 17.4625 12.7274L7.93306 22.2568C7.72631 22.4635 7.39111 22.4635 7.18436 22.2568C6.97761 22.05 6.97761 21.7148 7.18436 21.5081L16.3394 12.353L7.18436 3.19795C6.97761 2.9912 6.97761 2.656 7.18436 2.44925Z" />
            </svg>
        )
    }
};
