import { DateHelper } from "../../../../Common/DateHelper";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Note extends ODataSelect
{
    public Note_Id = "";
    public Note = "";
    public CreatedBy = "";
    public CreatedAt = DateHelper.null();

    public init(response: any, nodeId: number): Note
    {
        super.init(response, nodeId);
        this.CreatedAt = DateHelper.fromIsoByNode(response.CreatedAt, nodeId);
        return this;
    }
}
