import { Component } from 'react';

export default class Privateroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.225 15.6667C10.225 13.7436 11.8259 12.225 13.75 12.225H19.25C21.1742 12.225 22.775 13.7436 22.775 15.6667V17C22.775 17.428 22.428 17.775 22 17.775C21.572 17.775 21.225 17.428 21.225 17V15.6667C21.225 14.6442 20.3634 13.775 19.25 13.775H13.75C12.6366 13.775 11.775 14.6442 11.775 15.6667V17C11.775 17.428 11.428 17.775 11 17.775C10.572 17.775 10.225 17.428 10.225 17V15.6667Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.225 7.5C12.225 5.13898 14.139 3.225 16.5 3.225C18.861 3.225 20.775 5.13898 20.775 7.5C20.775 9.86102 18.861 11.775 16.5 11.775C14.139 11.775 12.225 9.86102 12.225 7.5ZM16.5 4.775C14.995 4.775 13.775 5.99502 13.775 7.5C13.775 9.00497 14.995 10.225 16.5 10.225C18.005 10.225 19.225 9.00497 19.225 7.5C19.225 5.99502 18.005 4.775 16.5 4.775Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5384 20.75C8.4743 20.75 7.55569 21.4955 7.33661 22.5368L5.73393 30.1544C5.64865 30.5598 5.25093 30.8192 4.84559 30.7339C4.44025 30.6487 4.18079 30.2509 4.26607 29.8456L5.86874 22.2279C6.23388 20.4924 7.76489 19.25 9.5384 19.25H24.1468C25.855 19.25 27.3474 20.4044 27.7766 22.0578L29.7259 29.5677C29.83 29.9686 29.5894 30.378 29.1884 30.482C28.7875 30.5861 28.3781 30.3455 28.2741 29.9445L26.3247 22.4347C26.0672 21.4426 25.1718 20.75 24.1468 20.75H9.5384Z" />
            </svg>
        )
    }
};