import React, { Component } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default class NotificationIcon extends Component<SvgIconProps> {
    render() {
        return (
            <SvgIcon {...this.props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.39124 10.1147C4.4816 6.7199 6.47194 3.27253 9.86675 2.3629C13.2615 1.45326 16.7089 3.4436 17.6186 6.8384L18.2524 9.20381C18.5492 10.3115 19.2876 11.2989 20.2566 11.8656C21.3308 12.3722 22.0477 13.4087 22.3435 14.5124L22.6103 15.5084C22.7415 15.9981 22.6961 16.4902 22.4569 16.9044C22.2178 17.3186 21.8143 17.6041 21.3246 17.7353L7.63014 21.4047C7.14045 21.5359 6.6483 21.4905 6.23409 21.2513C5.81988 21.0122 5.53443 20.6087 5.40321 20.119L5.13635 19.123C4.83076 17.9826 4.99687 16.8672 5.60917 15.8067C6.17406 14.8283 6.3238 13.5951 6.02505 12.4801L5.39124 10.1147ZM10.1408 3.38564C7.31083 4.14392 5.6557 7.0107 6.41398 9.84066L7.04779 12.2061C7.41621 13.581 7.23715 15.1046 6.52613 16.3361C6.04479 17.1698 5.93094 17.9975 6.15909 18.849L6.42596 19.845C6.4949 20.1022 6.62522 20.2545 6.7635 20.3344C6.90178 20.4142 7.09882 20.4509 7.3561 20.382L21.0506 16.7126C21.3079 16.6436 21.4601 16.5133 21.54 16.375C21.6198 16.2367 21.6565 16.0397 21.5876 15.7824L21.3207 14.7865C21.0849 13.9062 20.5294 13.158 19.789 12.8158C19.7745 12.8091 19.7603 12.8017 19.7464 12.7937C18.5149 12.0827 17.598 10.8528 17.2296 9.47786L16.5958 7.11245C15.8375 4.28249 12.9707 2.62736 10.1408 3.38564Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.6569 19.5483C11.9393 19.4726 12.2296 19.6402 12.3053 19.9226L12.4721 20.5451C12.6633 21.2586 13.4779 21.729 14.1915 21.5378C14.905 21.3466 15.3754 20.5319 15.1842 19.8184L15.0174 19.1959C14.9417 18.9135 15.1093 18.6232 15.3917 18.5475C15.6742 18.4719 15.9645 18.6395 16.0401 18.9219L16.2069 19.5444C16.5495 20.8227 15.7439 22.218 14.4655 22.5606C13.1871 22.9031 11.7919 22.0975 11.4493 20.8192L11.2825 20.1967C11.2069 19.9143 11.3745 19.624 11.6569 19.5483Z" />
            </SvgIcon>
        )
    }
};
