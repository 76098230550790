import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetIdentityProvidersEndpoint implements IGetIdentityProvidersEndpoint
{
    public async execute(): Promise<IIdentityProvider[]>
    {
        try
        {
            const response = await axios.get<IIdentityProvider[]>(`${apis.dataEntryWebApi}Security/IdentityProviders`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetIdentityProvidersEndpoint
{
    execute(): Promise<IIdentityProvider[]>;
}

export interface IIdentityProvider
{
    id: number;
    name: string | null;
    securityProviderTypeId: number;
    securityProviderTypeName: string;
    clientId: string | null;
    tenantDomain: string | null;
    tenantId: string | null;
    isDefault: boolean;
    status: number;
}