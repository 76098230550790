import { Box, Card, Grid, Typography } from "@mui/material";
import { appContext } from "../../../../AppContext";
import IbssAvatar from "../../../../Components/Miscellaneous/Avatar/IbssAvatar";
import { Fragment } from "react";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

export default class ListDelegators extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            delegates: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcDelegateAccess_S;
        const delegateData = await this.apiClient.delegates.getPrimaryDelegators();
        const delegates = delegateData.value.map(i => new Delegate({
            id: i.PrimaryEmail,
            name: i.PrimaryDisplayName ?? "",
            email: i.PrimaryEmail,
        }));

        this.setState({ delegates: delegates });
    }

    public render(): JSX.Element
    {
        const labels = this.labels;

        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content" style={{ height: "calc(100% - 100px)" }}>
                    <Card style={{ overflow: 'auto', borderRadius: '8px', height: "100%" }}>
                        <Box className="m-3">
                            <Typography variant="h5">{labels.funcDelegateSummary_S}</Typography>
                            <Typography variant="body1" className="ui-text-alternate">{labels.funcDelegateAccess_D}</Typography>
                            <hr />
                            <Typography variant="body1">{labels.funcDelegateReceived_S}</Typography>
                            {
                                this.state.delegates.map(i =>
                                    <Fragment key={i.id}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box>
                                                {this.renderUser(i.name, i.email)}
                                            </Box>
                                        </Box>
                                        <hr />
                                    </Fragment>
                                )
                            }
                        </Box>
                    </Card>
                </div>
            </div>
        );
    }

    private renderUser(fullName: string, email: string): JSX.Element
    {
        return (
            <Box display="flex" alignItems="center">
                <IbssAvatar fullName={fullName} />
                <Box ml={2}>
                    <Typography variant="subtitle1">{fullName}</Typography>
                    <Typography variant="subtitle2">{email}</Typography>
                </Box>
            </Box>
        );
    }
}

export interface IProps
{
}

export interface IState
{
    delegates: Delegate[];
}

export class Delegate
{
    public id = "";
    public name = "";
    public email = "";

    constructor(value: Partial<Delegate>)
    {
        Object.assign(this, value);
    }
}
