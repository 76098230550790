import * as React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { PaperProps } from '@mui/material/Paper';

// form elements - contains interactive elements that changes between login screens like buttons, text inputs, links and messages. 
export class FormContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px',
            gap: '10px',
        }}>{this.props.children}</Box>
    }
}

export class MessageContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 0px',
            gap: '10px',
        }}>{this.props.children}</Box>
    }
}

export class FormFieldContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '10px',
            width: '100%',
        }}>{this.props.children}</Box>
    }
}

export class IconContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '10px',
            gap: '10px',
            width: '40px',
            height: '70px',
        }}>{this.props.children}</Box>
    }
}

export class TextFieldContainter extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '10px',
            gap: '10px',
            width: '100%',
            height: '70px',
        }}>{this.props.children}</Box>
    }
}

export class LinkContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '15px',
            gap: '10px',
            width: '100%',
            height: '56px',
        }}>{this.props.children}</Box>
    }
}

// login template elements - these are elements that stay the same between the login IDP, native login and forgotten password pages.
export class LoginFrame extends React.Component<PaperProps>
{
    public render(): JSX.Element
    {
        return <Paper sx={theme => ({
            boxSizing: 'border-box',
            height: '480px',
            padding: '10px',
            display: "flex",
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '10px',

            [theme.breakpoints.up(1052)]: { // media query for screen sizes 1052 pixels and above
                width: '1052',
                maxWidth: '1052',
            },

            [theme.breakpoints.down(1052)]: { // media query for screen sizes below 1052 pixels
                width: '473px',
                maxWidth: '473px',
            },
        })}>{this.props.children}</Paper>
    }
}

// for the two logos on either side of the footer. Both has to disappear at a specified window width.
export class ImageContainer extends React.Component<BoxProps>
{
    public render(): JSX.Element
    {
        return <Box sx={theme => ({
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(1052)]: {
                display: 'none'
            },
        })}>{this.props.children}</Box>
    }
}

export class LoginContainer extends React.Component<PaperProps>
{
    public render(): JSX.Element
    {
        return <Paper
            square
            elevation={0}
            sx={theme => ({
                boxSizing: 'border-box',
                height: '460px',
                width: '453px',
                maxWidth: '453px',
                padding: '10px 19px',
                display: "flex",
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',

                backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default,
            })}>{this.props.children}</Paper>
    }
}
