import { Component } from 'react';

export default class Gym extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.667 2.58818L15.5051 3.78536C15.4949 3.79583 15.4844 3.80601 15.4737 3.81587C15.3871 3.89523 15.3524 4.07273 15.497 4.2175C15.4971 4.21759 15.4969 4.21742 15.497 4.2175L29.817 18.5022C29.8249 18.5101 29.8326 18.5181 29.8402 18.5264C29.9196 18.613 30.0973 18.6477 30.2421 18.5028L31.4089 17.336C31.4166 17.3283 31.4245 17.3208 31.4325 17.3134C31.5191 17.234 31.5537 17.0564 31.4089 16.9116L17.0885 2.59112C17.0808 2.58344 17.0733 2.57559 17.066 2.56759C16.9871 2.48155 16.8113 2.44678 16.667 2.58818ZM18.1591 1.54046C17.4593 0.79245 16.303 0.830859 15.6034 1.53046L14.4419 2.72702C13.6987 3.42729 13.7384 4.58026 14.4366 5.27842L28.7474 19.5539C29.4472 20.3015 30.6033 20.263 31.3027 19.5635L32.4596 18.4066C33.2076 17.7068 33.1692 16.5505 32.4696 15.8509L18.1591 1.54046Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5465 1.82503C22.4671 1.7384 22.2895 1.70375 22.1446 1.84857L21.4375 2.55575C21.1446 2.84865 20.6697 2.84865 20.3768 2.55575C20.0839 2.26286 20.0839 1.78799 20.3768 1.49509L21.084 0.78791C21.7836 0.0883054 22.9399 0.0498961 23.6397 0.797904L33.212 10.3702C33.9116 11.0698 33.9501 12.2261 33.2021 12.926L32.5049 13.6232C32.212 13.916 31.7371 13.916 31.4442 13.6232C31.1513 13.3303 31.1513 12.8554 31.4442 12.5625L32.1514 11.8553C32.1591 11.8476 32.1669 11.8401 32.1749 11.8328C32.2616 11.7534 32.2962 11.5757 32.1514 11.4309L22.5691 1.84857C22.5614 1.84089 22.5539 1.83304 22.5465 1.82503Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3355 2.9919C29.2561 2.90527 29.0784 2.87062 28.9336 3.01544L28.4033 2.48511L28.9336 3.01544L26.8828 5.06627C26.5899 5.35916 26.115 5.35916 25.8221 5.06627C25.5292 4.77337 25.5292 4.2985 25.8221 4.00561L27.8729 1.95478C28.5726 1.25518 29.7288 1.21677 30.4287 1.96477L32.0452 3.5813L31.5149 4.11163L32.0452 3.5813C32.7448 4.2809 32.7832 5.43721 32.0352 6.13704L29.9944 8.17787C29.7015 8.47076 29.2266 8.47076 28.9337 8.17787C28.6408 7.88498 28.6408 7.4101 28.9337 7.11721L30.9845 5.06638C30.9922 5.0587 31.0001 5.05119 31.0081 5.04385C31.0947 4.96443 31.1294 4.78678 30.9845 4.64196L29.358 3.01544C29.3503 3.00776 29.3428 2.99991 29.3355 2.9919Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.72693 14.4419C3.42719 13.6987 4.58016 13.7384 5.27832 14.4365L5.27898 14.4372L19.5538 28.7473C20.3014 29.4472 20.2629 30.6032 19.5634 31.3027L18.4065 32.4596C17.7067 33.2076 16.5504 33.1692 15.8508 32.4696L1.54037 18.1591C0.792358 17.4593 0.830768 16.303 1.53037 15.6034L1.53829 15.5955L1.53835 15.5955L2.72693 14.4419ZM4.21741 15.4969C4.07264 15.3524 3.89514 15.3871 3.81578 15.4737C3.80591 15.4844 3.79574 15.4949 3.78527 15.505L2.58809 16.667C2.44669 16.8113 2.48146 16.9871 2.5675 17.0659C2.5755 17.0733 2.58335 17.0808 2.59103 17.0885L16.9115 31.4089C17.0563 31.5537 17.2339 31.5191 17.3134 31.4324C17.3207 31.4244 17.3282 31.4166 17.3359 31.4089L18.5027 30.242C18.6476 30.0972 18.6129 29.9196 18.5263 29.8402C18.518 29.8326 18.51 29.8249 18.5021 29.817L4.21766 15.4972C4.21758 15.4971 4.21749 15.497 4.21741 15.4969Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55575 20.3768C2.84865 20.6697 2.84865 21.1446 2.55575 21.4375L1.84857 22.1447C1.70375 22.2895 1.7384 22.4672 1.82503 22.5466C1.83304 22.5539 1.84089 22.5614 1.84857 22.5691L11.4309 32.1514C11.5757 32.2962 11.7534 32.2616 11.8328 32.175C11.8401 32.1669 11.8476 32.1591 11.8553 32.1514L12.5625 31.4442C12.8554 31.1513 13.3303 31.1513 13.6232 31.4442C13.916 31.7371 13.916 32.212 13.6232 32.5049L12.926 33.2021C12.2261 33.9501 11.0698 33.9117 10.3702 33.2121L0.797904 23.6398C0.0498961 22.9399 0.0883053 21.7836 0.78791 21.084L1.49509 20.3768C1.78799 20.0839 2.26286 20.0839 2.55575 20.3768Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.0663 25.8221C5.35919 26.115 5.35919 26.5899 5.0663 26.8828L3.01547 28.9336L2.48514 28.4033L3.01547 28.9336C2.87065 29.0785 2.9053 29.2561 2.99193 29.3355C2.99994 29.3429 3.00779 29.3504 3.01547 29.3581L4.64199 30.9846C4.78681 31.1294 4.96446 31.0947 5.04388 31.0081C5.05122 31.0001 5.05873 30.9923 5.06641 30.9846L7.11724 28.9338C7.41013 28.6409 7.88501 28.6409 8.1779 28.9337C8.47079 29.2266 8.47079 29.7015 8.1779 29.9944L6.13707 32.0352C5.43724 32.7833 4.28093 32.7448 3.58133 32.0452L4.11166 31.5149L3.58133 32.0452L1.9648 30.4287C1.2168 29.7289 1.25521 28.5726 1.95481 27.873L4.00564 25.8221C4.29853 25.5293 4.77341 25.5293 5.0663 25.8221Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1745 12.0674C19.4674 12.3603 19.4674 12.8352 19.1745 13.1281L11.1833 21.1193C10.8905 21.4122 10.4156 21.4122 10.1227 21.1193C9.82979 20.8264 9.82979 20.3515 10.1227 20.0586L18.1138 12.0674C18.4067 11.7746 18.8816 11.7746 19.1745 12.0674Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1093 15.0023C22.4022 15.2952 22.4022 15.77 22.1093 16.0629L13.9767 24.1955C13.6838 24.4884 13.209 24.4884 12.9161 24.1955C12.6232 23.9026 12.6232 23.4277 12.9161 23.1349L21.0487 15.0023C21.3416 14.7094 21.8164 14.7094 22.1093 15.0023Z" />
            </svg>
        )
    }
};