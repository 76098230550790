import { Component } from "react";
import { appContext } from "../../../AppContext";
import { SvgIcon } from "@mui/material";
import CostCodeIcon from "../../Icons/CostCodeIcon";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import CostCodesDialog, { CostCodeWithAllocation } from "../../Dialogs/CostCodesDialog/CostCodesDialog";
import { Button } from '@mui/material';
import { isEqual } from "lodash";

export default class CostCodesPicker extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            showCostCodeModal: false,
            costCodes: []
        }

        this.handleCostCodeModal = this.handleCostCodeModal.bind(this);
        this.updateBookingCostCodes = this.updateBookingCostCodes.bind(this);
    }

    public componentDidMount(): void
    {
        if (this.props.selectedCostCodes !== null && this.props.selectedCostCodes !== undefined) {
            this.setState({ costCodes: this.props.selectedCostCodes });
        }
    }
    
    public componentDidUpdate(prevProps: IProps): void
    {
        // if props.selectedCostCodes is defined and has changed.
        if(this.props.selectedCostCodes && (!isEqual(prevProps?.selectedCostCodes, this.props?.selectedCostCodes)))
        {
            this.setState({ costCodes: this.props.selectedCostCodes });
        }
    }

    private handleCostCodeModal(): void
    {
        this.setState((prevState) => ({
            showCostCodeModal: !prevState.showCostCodeModal
        }));
    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState({
            costCodes: updatedCostCodes
        })

        this.props.onSubmit(updatedCostCodes)
    }

    private getCostCodeLabel(): string
    {
        /* return the string that is returned on the cost code button*/
        const numberOfCostCodes = this.state.costCodes.length;
        if (numberOfCostCodes === 1)
        {
            return this.state.costCodes[0].costCode; //if just one cost code, show the cost code
        }
        else if (numberOfCostCodes > 1)
        {
            return `${this.state.costCodes.length} ${this.labels.HubLabelCostCodes}` // Cost Codes
        }
        else
        {
            return '';
        }
    }

    public render(): JSX.Element
    {

        return (
            <>
                {
                    (this.local.hasRight("API.Bookings.AssignCostCode") && this.local.hasRight("API.Bookings.V2")) && (
                        <>
                            <Button sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} fullWidth className="row attendes cp" onClick={() => this.handleCostCodeModal()}>
                                <div className="icon-text-inline pl-0">
                                    <div style={{ fontSize: '26px', height: '19px', marginTop: '-11px' }}>
                                        <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }}>
                                            {Icons.CostCodeIcon}
                                        </IbssSvgIcon>
                                    </div>
                                    <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelCostCode}</span>
                                </div>
                                <div className="d-flex">
                                    <span className="space-text-item mr-3">{this.getCostCodeLabel()}</span>
                                    <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {Icons.RightArrowIcon}
                                    </IbssSvgIcon>
                                </div>
                            </Button>
                            <hr />
                        </>
                    )
                }

                <CostCodesDialog
                    selectedCostCodes={this.state.costCodes}
                    show={this.state.showCostCodeModal}
                    onClose={this.handleCostCodeModal}
                    updateBookingCostCodes={this.updateBookingCostCodes}
                    buildingId={this.props.buildingId}
                />
            </>
        );
    }
}

export interface IState
{
    showCostCodeModal: boolean;
    costCodes: CostCodeWithAllocation[]
}

export interface IProps
{
    onSubmit: (updatedCostCodes: CostCodeWithAllocation[]) => void;
    selectedCostCodes?: CostCodeWithAllocation[];
    buildingId?: number
}
