import { MDBDataTable } from "mdbreact";
import { appContext } from "../../../../AppContext";
import apis from "../../../../Providers.Api/apis";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

class Users extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            usersRole: [{
                email: "",
                identityProviderId: "",
            }],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadUsers();
    }

    private async loadUsers(): Promise<void>
    {
        try
        {
            const res = await apis.getUserRoleByRoleId(this.props.id);
            const newRes = res.data && res.data[0] && res.data.map((item: any) =>
            {
                let userTypeStatus = "";
                if (item.userType === 0)
                {
                    userTypeStatus = "User";
                }
                if (item.userType === 1)
                {
                    userTypeStatus = "Application";
                }

                const newItem = {
                    ...item,
                    displayName: item.displayName ? item.displayName : item.email,
                    clickEvent: () => this.navigateToUser(item.id),
                    userType: userTypeStatus,
                    'Option': <button className="btn btn-success" onClick={() => this.navigateToUser(item.id)} >Edit </button>
                };
                return newItem;
            });

            for (let i = 0; i < res.data.length; i++)
            {
                const user = res.data[i].eventTypeName;
                this.props.onUserSelected(user);
            }

            this.setState({ usersRole: newRes ? newRes : [] });
        }
        catch
        {
        }
    }

    private navigateToUser(userId: string)
    {
        const { history } = this.props;
        sessionStorage.setItem('roleID', this.props.id);
        sessionStorage.setItem('roleTitle', this.props.title);
        history.push("/security-users/userdetails/" + userId, `user=${this.props.id}`);
    }

    public render(): JSX.Element
    {
        const dataUsersRole = {
            columns: [{
                label: this.labels.HubLabelName,
                field: "displayName",
                sort: "",
                width: 50
            },
            {
                label: this.labels.HubLabelIdentityprovider,
                field: "identityProviderName",
                sort: "",
                width: 50
            },
            {
                label: this.labels.HubLabelType,
                field: "securityProviderTypeName",
                sort: "",
                width: 50
            },
            {
                label: this.labels.HubLabeluserType,
                field: "userType",
                sort: "",
                width: 50
            },
            {
                label: "",
                field: "",
                sort: "disabled",
            },
            ],
            rows: this.state.usersRole
        };

        return (
            <div className="main-content">
                {
                    this.props.loading ||
                    <div className="row">
                        <MDBDataTable className="standardTable tableTabButtons tableUsers tableDataSecurity" displayEntries={false} entries={50} hover data={dataUsersRole} />
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(Users);

export interface IProps extends RouteComponentProps
{
    id: string;
    loading: boolean;
    title: string;
    onUserSelected: (user: any) => void;
}

export interface IState
{
    usersRole: IUser[];
}

export interface IUser
{
    email: string;
    identityProviderId: string;
}
