import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IVisitAction } from "./VisitsRepository";

export class VisitActionEndpoint implements IVisitActionEndpoint
{
    public async execute(nodeId: number, visitId: string, payload: IVisitAction): Promise<void>
    {
        try
        {
            const response: AxiosResponse<void> = await axios.put(`${apis.allApi}/${nodeId}/Visits/${visitId}/action`, payload);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IVisitActionEndpoint
{
    execute(nodeId: number, visitId: string, payload: IVisitAction): Promise<void>;
}
