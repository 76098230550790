import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateEndpoint implements ICreateEndpoint
{
    public async execute(buildingId: number, payload: ICreateOrderPolicyPayload): Promise<void>
    {
        try
        {
            await axios.post(`${apis.allApiv2}${buildingId}/catering-order-policy`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateEndpoint
{
    execute(buildingId: number, payload: ICreateOrderPolicyPayload): Promise<void>;
}

export interface ICreateOrderPolicyPayload
{
    Description: string;
    AdvanceNotice: string;
    PreparationTime_Mins: number;  
    Kitchen_OperatingHours_Start:string;
    Kitchen_OperatingHours_Stop: string;
    Name: string;
    OrderPolicy_Ref: string;
    Node_Id: number;
}
