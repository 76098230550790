import { Card, CircularProgress } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { appContext } from '../../../../AppContext';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import Helper, { getBuildingNameUsingFloorNodeId, getBuildingNodeIdUsingFloorNodeId, getFloorUsingFloorId } from '../../../../Common/Helper';
import CreateIcon from '@mui/icons-material/Create';
import './ViewSeries.scss'
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import TickIcon from '../../../../Components/Icons/TickIcon';

class ViewSeries extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiCache() { return appContext().apiCache; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            bookingDetails: {
                bookingName: '',
                bookingDescription: '',
                bookingOwner: ''
            },
            rows: [],
            spaces: []
        };
    };

    public async componentDidMount(): Promise<void>
    {
        if (this.props.bookingDetails)
        {
            this.setState({ bookingDetails: this.props.bookingDetails });

            if (this.props.bookingDates && this.props.bookingDates.length > 0)
            {
                this.getSpaceDetails(this.props.bookingDates[0].nodeId)
            }
        }
    }

    private spaceNamesById: (Map<string, string> | null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [i.Space_Id, i.Space_Name]));
        return this.spaceNamesById.get(spaceId) ?? "";
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): Promise<void>
    {
        if (this.props.bookingDates && this.props.bookingDates.length > 0 && prevProps.bookingDates != this.props.bookingDates)
        {
            if (this.state.spaces.length == 0)
            {
                if (this.props.bookingDates.length > 0)
                {
                    await this.getSpaceDetails(this.props.bookingDates[0].nodeId)
                }
            }
            let rows = this.props.bookingDates.map(x =>
            {
                const floorId = x.nodeId;
                return ({
                    date: x.start.toLocaleString(DateTime.DATE_SHORT),
                    time: `${x.start.toLocaleString(DateTime.TIME_24_SIMPLE)}-${x.end.toLocaleString(DateTime.TIME_24_SIMPLE)}`,
                    building: getBuildingNameUsingFloorNodeId(floorId),
                    floor: getFloorUsingFloorId(floorId),
                    space: this.getSpaceNameById(x.spaceId),
                    spaceId: x.spaceId,
                    bookedFor: x.bookedFor,
                    attendees: x.attendeedCount,
                    nodeId: getBuildingNodeIdUsingFloorNodeId(x.nodeId),
                    bookingId: x.bookingId,
                    id: x.bookingId,
                    failed: false,
                    catering: x.catering ?? false,
                    resources: x.resources ?? false,
                });

            }
            );
            if (this.props.failedSeriesBookings && this.props.failedSeriesBookings.length > 0)
            {
                const failedBookingsRows = this.props.failedSeriesBookings.map(x =>
                {
                    const floorId = x.nodeId;
                    return ({
                        date: x.start.toLocaleString(DateTime.DATE_SHORT),
                        time: `${x.start.toLocaleString(DateTime.TIME_24_SIMPLE)}-${x.end.toLocaleString(DateTime.TIME_24_SIMPLE)}`,
                        building: getBuildingNameUsingFloorNodeId(floorId),
                        floor: getFloorUsingFloorId(floorId),
                        space: this.getSpaceNameById(x.spaceId),
                        spaceId: x.spaceId,
                        bookedFor: x.bookedFor,
                        attendees: x.attendeedCount,
                        nodeId: getBuildingNodeIdUsingFloorNodeId(x.nodeId),
                        bookingId: x.bookingId,
                        id: x.start.toISO(),
                        failed: true,
                        catering: false,
                        resources: false
                    })
                });
                rows = rows.concat(failedBookingsRows);
            }
            this.setState({
                rows: rows
            });

            if (this.props.bookingDetails)
            {
                this.setState({ bookingDetails: this.props.bookingDetails });
            }
        }
    }

    private async getSpaceDetails(floorId: number): Promise<void>
    {
        const spaceList = await this.apiCache.getSpacesByBuilding(parseInt(getBuildingNodeIdUsingFloorNodeId(floorId)));
        await this.setStateAsync({ spaces: spaceList });
    }

    private getRowClassName(row: IBooking): string
    {
        if (row.failed)
        {
            return 'failed-booking-row'
        }
        else
        {
            return ''
        }
    }

    public render(): JSX.Element
    {
        const columns: GridColDef[] = [
            {
                headerName: this.labels.HubLabelDate,
                field: Helper.nameOf<Booking>("date"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelTime,
                field: Helper.nameOf<Booking>("time"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBuilding,
                field: Helper.nameOf<Booking>("building"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HublabelFloor,
                field: Helper.nameOf<Booking>("floor"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelSpace,
                field: Helper.nameOf<Booking>("space"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookedFor,
                field: Helper.nameOf<Booking>("bookedFor"),
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelAttendees,
                field: Helper.nameOf<Booking>("attendees"),
                maxWidth: 140,
                flex: 1,
                align: 'center',
                headerAlign: 'center',
            },
            {
                headerName: this.labels.HubMenuCatering,
                field: Helper.nameOf<Booking>("catering"),
                minWidth: 140,
                flex: 1,
                align: 'center',
                headerAlign: 'center',
                renderCell: (param) => <div>{param.row.catering ? <IbssSvgIcon> <TickIcon /> </IbssSvgIcon> : <div></div>}</div>
            },
            {
                headerName: this.labels.HubLabelPresentationAid,
                field: Helper.nameOf<Booking>("resources"),
                minWidth: 140,
                flex: 1,
                align: 'center',
                headerAlign: 'center',
                renderCell: (param) => <div>{param.row.resources ? <IbssSvgIcon> <TickIcon /> </IbssSvgIcon> : <div></div>}</div>
            },
            {
                headerName: this.labels.HubLabelAction,
                field: "",
                maxWidth: 100,
                flex: 1,
                align: 'center',
                headerAlign: 'center',
                renderCell: (param) => <div>{param.row.failed ? <div></div> : <IbssSvgIcon onClick={() => this.props.editRedirectRoute(param.row.nodeId, param.row.bookingId, param.row.spaceId)}> <CreateIcon /> </IbssSvgIcon>}</div>
            },
        ];

        return (
            <div className='m-2'>
                <div className='d-flex justify-content-between mb-1'>
                    <div className="pageTitle">{this.labels.funcYourBookingSummary_S}</div>
                    {
                        this.props.redirectBackRoute &&
                        <IbssButton color='secondary' onClick={() => this.props.redirectBackRoute ? this.props.redirectBackRoute() : {}}>{this.labels.HubButtonBack}</IbssButton>
                    }
                </div>
                <Card>
                    <div className='m-3'>
                        <div className="pageTitle mb-3">{this.labels.funcSeriesBooking_S}</div>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                value={this.state.bookingDetails.bookingName}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ bookingDetails: { ...this.state.bookingDetails, bookingName: e.target.value } })}
                                label={this.labels.HubLabelBookingName}
                                disabled={!this.props.allowTitleDescriptionEdit}
                            />
                        </IbssFormControl>
                        <IbssFormControl fullWidth className='mb-3'>
                            <IbssTextField
                                value={this.state.bookingDetails.bookingDescription}
                                fullWidth
                                variant='outlined'
                                onChange={e => this.setState({ bookingDetails: { ...this.state.bookingDetails, bookingDescription: e.target.value } })}
                                label={this.labels.HubLabelBookingDescription}
                                disabled={!this.props.allowTitleDescriptionEdit}
                            />
                        </IbssFormControl>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <IbssSvgIcon sx={{ fontSize: '15px', marginRight: '10px' }} fontSize='inherit'>
                                    {Icons.UserIcon}
                                </IbssSvgIcon>
                                {this.labels.funcBookingOwner_S}
                            </div>
                            <div>
                                {this.state.bookingDetails.bookingOwner}
                            </div>
                        </div>
                        <hr />
                        {
                            this.props.creatingSeries && !this.props.seriesCreationCompleted &&
                            <div className='d-flex my-3'>
                                <CircularProgress />
                                <div className='ml-2'>
                                    <p style={{ fontSize: '15px' }} className='m-0'>{this.labels.funcSeriesCreationInProgressPt1_Message}</p>
                                    <p style={{ fontSize: '15px' }} className='m-0'>{this.labels.funcSeriesCreationInProgressPt2_Message}</p>
                                </div>
                            </div>
                        }
                        {
                            this.props.seriesCreationCompleted &&
                            <div className='d-flex my-3'>
                                <IbssSvgIcon sx={{ fontSize: '45px', marginRight: '10px' }} color='success' fontSize='inherit'>
                                    {Icons.TickIcon}
                                </IbssSvgIcon>
                                <div className='ml-2'>
                                    <p style={{ fontSize: '15px' }} className='m-0'>{this.labels.funcSeriesCreated_Message}</p>
                                    <p style={{ fontSize: '15px' }} className='m-0'>{this.labels.funcFailedSeriesBooking_Message}</p>
                                </div>
                            </div>
                        }
                        {
                            (this.props.seriesCreationCompleted || this.state.rows.length > 0) &&
                            <IbssDataGrid
                                getRowClassName={(params) => this.getRowClassName(params.row)}
                                className='clickable-data-grid-row'
                                height="calc(100vh - 500px)"
                                rows={this.state.rows}
                                columns={columns}
                                hideFooter
                            />
                        }
                    </div>
                </Card>
            </div>
        )
    }
}

export default ViewSeries;

export interface IProps
{
    creatingSeries?: boolean;
    seriesCreationCompleted?: boolean;
    bookingDetails?: ISeriesBookingDetails;
    bookingDates?: ISeriesBooking[];
    allowTitleDescriptionEdit: boolean;
    failedSeriesBookings?: ISeriesBooking[];
    editRedirectRoute: (nodeId: string, bookingId: string, spaceId: string) => void;
    redirectBackRoute?: () => void;
}

export interface IState
{
    bookingDetails: ISeriesBookingDetails;
    rows: IBooking[];
    spaces: Space[];
}

export interface ISeriesBookingDetails
{
    bookingName: string;
    bookingDescription: string;
    bookingOwner: string;
}

export interface ISeriesBooking
{
    start: DateTime;
    end: DateTime;
    bookingId: string;
    spaceId: string;
    nodeId: number;
    bookedFor: string;
    attendeedCount: number;
    catering?: boolean;
    resources?: boolean;
}

export interface IAttemptedBooking
{
    from: string;
    to: string;
    spaceId: string;
}

export interface IBooking
{
    bookingId: string;
    date: string;
    time: string;
    building: string;
    floor: string;
    space: string;
    bookedFor: string;
    attendees: number;
    nodeId: string;
    spaceId: string;
    failed: boolean;
    catering: boolean;
    resources: boolean;
}

export class Booking
{
    public date: string;
    public time: string;
    public building: string;
    public floor: string;
    public space: string;
    public bookedFor: string;
    public attendees: number;
    public catering: boolean;
    public resources: boolean;

    constructor(value: IBooking)
    {
        this.date = value.date;
        this.time = value.time;
        this.building = value.building;
        this.floor = value.floor;
        this.space = value.space;
        this.bookedFor = value.bookedFor;
        this.attendees = value.attendees;
        this.catering = value.catering;
        this.resources = value.resources;
    }
}