import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(nodeId: number, taskId: string, payload: ICateringStatus): Promise<string>
    {
        try
        {
            const response = await axios.patch(apis.allApiv2 + nodeId + '/catering-order/' + taskId, payload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(nodeId: number, taskId: string, payload: ICateringStatus): Promise<string>;
}


export interface ICateringStatus 
{
    Catering_Status: string;
}