import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IParameter, IParameterName } from "./ParameterRepository";

export class GetParameterByNameEndpoint implements IGetParameterByNameEndpoint
{
    public async execute(nodeId: number, name: IParameterName): Promise<IParameter[]>
    {
        try
        {
            const query =
                `schema=false&` +
                `recursive=false&` +
                `$filter=Parameter_Name eq '${name}'`;

            const response = await axios.get<IParameter[]>(`${apis.allApi}${nodeId}/Parameters?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetParameterByNameEndpoint
{
    execute(nodeId: number, name: IParameterName): Promise<IParameter[]>;
}
