import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateTagEndpoint implements IUpdateTagEndpoint
{
    public async execute(buildingId: number, tagId: string, payload: IUpdateTag): Promise<void>
    {
        try
        {
            await axios.put(`${apis.allApiv2}${buildingId}/tags/${tagId}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateTagEndpoint
{
    execute(buildingId: number, tagId: string, payload: IUpdateTag): Promise<void>;
}

export interface IUpdateTag
{
    Tag_Id: string,
    Node_Id: number,
    TagType: string,
    Values: string,
    Icon: string,
    Description: string,
    Status: string,
    Name: string,
    ConcurrencyStamp: string
}