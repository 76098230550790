import apis from "./apis";
import { ICache } from "../Common/Cache";
import { IFloor, IRootNode, INode } from "./Models";
import { IDataEntrySpace } from "./Models.DataEntryApi";
import { AxiosResponse } from "axios";
import { Space } from "./Spaces/SpaceRepository";
import { appContext } from "../AppContext";
import { DateTime } from "luxon";

export class ApiCache implements IApiCache
{
    private cache: ICache;
    private get localStorage() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(cache: ICache)
    {
        this.cache = cache;
    }

    public getFile(path: string): Promise<string>
    {
        return this.cache.lazyGet(`Api_GetFile_${path}`, DateTime.now().plus({ days: 1 }), async () =>
        {
            let file = await apis.getFile(path);
            return await file.text();
        });
    }

    public getAllFloors(nodeId: number): Promise<IFloor[]>
    {
        return this.cache.lazyGet(`Api_GetAllFloors_${nodeId}`, DateTime.now().plus({ days: 1 }), async () =>
        {
            let response: AxiosResponse<IFloor[]> = await apis.getAllFloors(nodeId);
            return response.data;
        });
    }

    public getBuildings(): Promise<IDataEntrySpace[]>
    {
        return this.cache.lazyGet(`Api_GetBuildings`, DateTime.now().plus({ days: 1 }), async () =>
        {
            let response: AxiosResponse<IDataEntrySpace[]> = await apis.getSpacesByFilterEnabledBuilding("Building", 1);
            return response.data;
        });
    }

    public getSpacesByBuilding(buildingId: number): Promise<Space[]>
    {
        return this.cache.lazyGet(`Api_SpacesByBuilding_${buildingId}`, DateTime.now().plus({ hours: 12 }), async () =>
        {
            const spaces = await this.apiClient.spaces.getV1SpacesByNode(buildingId);
            return spaces;
        });
    }

    public getSpace(floorId: number, spaceId: string): Promise<Space | null>
    {
        return this.cache.lazyGet(`Api_GetSpace_${spaceId}`, DateTime.now().plus({ days: 1 }), async () =>
        {
            const space = await this.apiClient.spaces.searchV1SpaceById(floorId, spaceId);
            return space;
        });
    }

    public clearSpacesByBuildingId(buildingId: number): Promise<void>
    {
        return this.cache.clear(`Api_SpacesByBuilding_${buildingId}`);
    }
}

export interface IApiCache
{
    getFile(path: string): Promise<string>;
    getAllFloors(nodeId: number): Promise<IFloor[]>;
    getBuildings(): Promise<IDataEntrySpace[]>;
    getSpacesByBuilding(buildingId: number): Promise<Space[]>;
    clearSpacesByBuildingId(buildingId: number): Promise<void>;
    getSpace(floorId: number, spaceId: string): Promise<Space | null>;
}
