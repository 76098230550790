import { Component } from 'react';

export default class TaskIcon extends Component {
    render() {
        return (
            <svg>
                <path fillRule="evenodd" clipRule="evenodd" d="M2.61745 4.76463C2.13014 4.76463 1.73509 5.15968 1.73509 5.64699V21.8823C1.73509 22.3696 2.13014 22.7646 2.61745 22.7646H22.3822C22.8695 22.7646 23.2645 22.3696 23.2645 21.8823V5.64699C23.2645 5.15968 22.8695 4.76463 22.3822 4.76463H18.1469V3.70581H22.3822C23.4542 3.70581 24.3233 4.5749 24.3233 5.64699V21.8823C24.3233 22.9544 23.4542 23.8235 22.3822 23.8235H2.61745C1.54536 23.8235 0.67627 22.9544 0.67627 21.8823V5.64699C0.67627 4.57491 1.54536 3.70581 2.61745 3.70581H6.85274V4.76463H2.61745Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.61748 9.48754C8.83558 9.68227 8.85453 10.0169 8.65979 10.235L6.13878 13.0586C6.03834 13.1711 5.89469 13.2354 5.74387 13.2354C5.59306 13.2354 5.44941 13.1711 5.34897 13.0586L4.34056 11.9292C4.14583 11.7111 4.16477 11.3764 4.38288 11.1817C4.60098 10.9869 4.93565 11.0059 5.13038 11.224L5.74387 11.9111L7.86998 9.52985C8.06471 9.31175 8.39938 9.2928 8.61748 9.48754Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.61748 15.8403C8.83558 16.0351 8.85453 16.3697 8.65979 16.5878L6.13878 19.4114C6.03834 19.5239 5.89469 19.5882 5.74387 19.5882C5.59306 19.5882 5.44941 19.5239 5.34897 19.4114L4.34056 18.2819C4.14583 18.0638 4.16477 17.7292 4.38288 17.5344C4.60098 17.3397 4.93565 17.3586 5.13038 17.5767L5.74387 18.2639L7.86998 15.8826C8.06471 15.6645 8.39938 15.6456 8.61748 15.8403Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9707 10.5882C11.9707 10.2959 12.2077 10.0588 12.5001 10.0588H20.2648C20.5572 10.0588 20.7942 10.2959 20.7942 10.5882C20.7942 10.8806 20.5572 11.1177 20.2648 11.1177H12.5001C12.2077 11.1177 11.9707 10.8806 11.9707 10.5882Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9707 14.8236C11.9707 14.5312 12.2077 14.2942 12.5001 14.2942H20.2648C20.5572 14.2942 20.7942 14.5312 20.7942 14.8236C20.7942 15.116 20.5572 15.353 20.2648 15.353H12.5001C12.2077 15.353 11.9707 15.116 11.9707 14.8236Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9707 19.0587C11.9707 18.7663 12.2077 18.5293 12.5001 18.5293H20.2648C20.5572 18.5293 20.7942 18.7663 20.7942 19.0587C20.7942 19.3511 20.5572 19.5881 20.2648 19.5881H12.5001C12.2077 19.5881 11.9707 19.3511 11.9707 19.0587Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.81785 1.23534C9.72039 1.23534 9.64138 1.31435 9.64138 1.41181V1.83534C9.64138 2.12772 9.40435 2.36475 9.11197 2.36475H7.55902C7.46156 2.36475 7.38255 2.44376 7.38255 2.54122V5.6471C7.38255 5.74456 7.46156 5.82357 7.55902 5.82357H17.4414C17.5388 5.82357 17.6178 5.74456 17.6178 5.6471V2.54122C17.6178 2.44376 17.5388 2.36475 17.4414 2.36475H15.8884C15.5961 2.36475 15.359 2.12772 15.359 1.83534V1.41181C15.359 1.31435 15.28 1.23534 15.1826 1.23534H9.81785ZM8.58703 1.30593C8.64074 0.673258 9.17128 0.176514 9.81785 0.176514H15.1826C15.8291 0.176514 16.3597 0.673257 16.4134 1.30593H17.4414C18.1236 1.30593 18.6767 1.85899 18.6767 2.54122V5.6471C18.6767 6.32933 18.1236 6.8824 17.4414 6.8824H7.55902C6.87679 6.8824 6.32373 6.32934 6.32373 5.6471V2.54122C6.32373 1.85899 6.87679 1.30593 7.55902 1.30593H8.58703Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.61745 4.76463C2.13014 4.76463 1.73509 5.15968 1.73509 5.64699V21.8823C1.73509 22.3696 2.13014 22.7646 2.61745 22.7646H22.3822C22.8695 22.7646 23.2645 22.3696 23.2645 21.8823V5.64699C23.2645 5.15968 22.8695 4.76463 22.3822 4.76463H18.1469V3.70581H22.3822C23.4542 3.70581 24.3233 4.5749 24.3233 5.64699V21.8823C24.3233 22.9544 23.4542 23.8235 22.3822 23.8235H2.61745C1.54536 23.8235 0.67627 22.9544 0.67627 21.8823V5.64699C0.67627 4.57491 1.54536 3.70581 2.61745 3.70581H6.85274V4.76463H2.61745Z" />
            </svg>
        )
    }
};