import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { CateringMenuItemClassification, CateringMenuItemStatus, Filter } from "./CateringMenuItemRepository";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute<TResponse>(nodeId: number, top: number, skipToken: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>
    {
        try
        {
            const query =
                `$top=${top}&` +
                `$skipToken=${skipToken}&` +
                `$select=${ApiHelper.toODataSelect(responseType)}&` +
                `$filter=${filter?.toSqlString() ?? ""}`;

            const response = await axios.get<IRawPagedResponse<TResponse[]>>(`${apis.allApiv2}${nodeId}/catering-menuitem?${query}`);

            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute<TResponse>(nodeId: number, top: number, skipToken: string, responseType: new() => TResponse, filter?: Filter): Promise<PagedResponse<TResponse[]>>;
}
