import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DateTime } from "luxon";
import { ApiHelper } from "../ApiHelper";
import { VisitFilter } from "./VisitsRepository";
import { ODataQuery } from "../ODataQuery";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute<TVisit>(query: ODataQuery<TVisit>): Promise<TVisit[]>
    {
        try
        {
            const queryString = query.toODataString("", "");
            const response = await axios.get<TVisit[]>(`${apis.allApi}/${query.nodeId}/Visits?${queryString}`);
            response.data.forEach(i => query.fixTypes(i));
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute<TVisit>(query: ODataQuery<TVisit>): Promise<TVisit[]>;
}
