import { Component } from 'react';
export default class Boardroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 13C12.25 11.4812 13.4812 10.25 15 10.25H19C20.5188 10.25 21.75 11.4812 21.75 13V14C21.75 14.4142 21.4142 14.75 21 14.75C20.5858 14.75 20.25 14.4142 20.25 14V13C20.25 12.3096 19.6904 11.75 19 11.75H15C14.3096 11.75 13.75 12.3096 13.75 13V14C13.75 14.4142 13.4142 14.75 13 14.75C12.5858 14.75 12.25 14.4142 12.25 14V13Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.25 16C25.25 15.5858 25.5858 15.25 26 15.25H30.0133C31.9949 15.25 33.75 16.702 33.75 18.6667V20C33.75 20.4142 33.4142 20.75 33 20.75C32.5858 20.75 32.25 20.4142 32.25 20V18.6667C32.25 17.6858 31.3308 16.75 30.0133 16.75H26C25.5858 16.75 25.25 16.4142 25.25 16Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.25 27C25.25 26.5858 25.5858 26.25 26 26.25H30.0133C31.9949 26.25 33.75 27.702 33.75 29.6667V31C33.75 31.4142 33.4142 31.75 33 31.75C32.5858 31.75 32.25 31.4142 32.25 31V29.6667C32.25 28.6858 31.3308 27.75 30.0133 27.75H26C25.5858 27.75 25.25 27.4142 25.25 27Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 16C8.75 15.5858 8.41421 15.25 8 15.25H3.98667C2.00514 15.25 0.25 16.702 0.25 18.6667V20C0.25 20.4142 0.585786 20.75 1 20.75C1.41421 20.75 1.75 20.4142 1.75 20V18.6667C1.75 17.6858 2.66921 16.75 3.98667 16.75H8C8.41421 16.75 8.75 16.4142 8.75 16Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 27C8.75 26.5858 8.41421 26.25 8 26.25H3.98667C2.00514 26.25 0.25 27.702 0.25 29.6667V31C0.25 31.4142 0.585786 31.75 1 31.75C1.41421 31.75 1.75 31.4142 1.75 31V29.6667C1.75 28.6858 2.66921 27.75 3.98667 27.75H8C8.41421 27.75 8.75 27.4142 8.75 27Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 6C13.25 3.92893 14.9289 2.25 17 2.25C19.0711 2.25 20.75 3.92893 20.75 6C20.75 8.07107 19.0711 9.75 17 9.75C14.9289 9.75 13.25 8.07107 13.25 6ZM17 3.75C15.7574 3.75 14.75 4.75736 14.75 6C14.75 7.24264 15.7574 8.25 17 8.25C18.2426 8.25 19.25 7.24264 19.25 6C19.25 4.75736 18.2426 3.75 17 3.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.25 11C24.25 8.92893 25.9289 7.25 28 7.25C30.0711 7.25 31.75 8.92893 31.75 11C31.75 13.0711 30.0711 14.75 28 14.75C25.9289 14.75 24.25 13.0711 24.25 11ZM28 8.75C26.7574 8.75 25.75 9.75736 25.75 11C25.75 12.2426 26.7574 13.25 28 13.25C29.2426 13.25 30.25 12.2426 30.25 11C30.25 9.75736 29.2426 8.75 28 8.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.25 22C24.25 19.9289 25.9289 18.25 28 18.25C30.0711 18.25 31.75 19.9289 31.75 22C31.75 24.0711 30.0711 25.75 28 25.75C25.9289 25.75 24.25 24.0711 24.25 22ZM28 19.75C26.7574 19.75 25.75 20.7574 25.75 22C25.75 23.2426 26.7574 24.25 28 24.25C29.2426 24.25 30.25 23.2426 30.25 22C30.25 20.7574 29.2426 19.75 28 19.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 11C9.75 8.92893 8.07107 7.25 6 7.25C3.92893 7.25 2.25 8.92893 2.25 11C2.25 13.0711 3.92893 14.75 6 14.75C8.07107 14.75 9.75 13.0711 9.75 11ZM6 8.75C7.24264 8.75 8.25 9.75736 8.25 11C8.25 12.2426 7.24264 13.25 6 13.25C4.75736 13.25 3.75 12.2426 3.75 11C3.75 9.75736 4.75736 8.75 6 8.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 22C9.75 19.9289 8.07107 18.25 6 18.25C3.92893 18.25 2.25 19.9289 2.25 22C2.25 24.0711 3.92893 25.75 6 25.75C8.07107 25.75 9.75 24.0711 9.75 22ZM6 19.75C7.24264 19.75 8.25 20.7574 8.25 22C8.25 23.2426 7.24264 24.25 6 24.25C4.75736 24.25 3.75 23.2426 3.75 22C3.75 20.7574 4.75736 19.75 6 19.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5981 17.75C13.5219 17.75 12.5964 18.5121 12.3899 19.5683L9.73606 33.1439C9.65659 33.5504 9.26261 33.8155 8.8561 33.7361C8.44958 33.6566 8.18445 33.2626 8.26392 32.8561L10.9178 19.2805C11.2619 17.5202 12.8045 16.25 14.5981 16.25H19.1718C20.9426 16.25 22.472 17.4887 22.8399 19.2209L25.7336 32.8442C25.8197 33.2493 25.561 33.6476 25.1558 33.7336C24.7506 33.8197 24.3524 33.561 24.2664 33.1558L21.3727 19.5325C21.1519 18.4932 20.2343 17.75 19.1718 17.75H14.5981Z" />
            </svg>
        )
    }
};