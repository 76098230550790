import { Component } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default class Submenu extends Component {

  updateDimensions = () => {
    const tele = document.getElementById('teleporter');
    const ddlMoreMenu = document.getElementById('ddlMoreMenu');
    const rec = document.getElementById('receiver');
    this.resize(rec, tele, ddlMoreMenu)
  };
  componentDidMount() {
    this.hideMoreButton();
    setTimeout(() => {
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions);
      window.addEventListener('load', this.updateDimensions);
    }, 2000);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('load', this.updateDimensions);
  }

  hideMoreButton() {
    const ddlMoreMenu: any = document.getElementById('ddlMoreMenu');
    ddlMoreMenu.style.display = "none";
  }

  resize(rec: any, tele: any, ddlMoreMenu: any) {
    if (rec !== null) {
      const rChildren = rec.children;
      let numW = 0;

      [...rChildren].forEach(item => {
        item.outHTML = '';
        tele.appendChild(item);
      })

      const teleW = tele.offsetWidth;
      const tChildren = tele.children;

      [...tChildren].forEach(item => {
        numW = numW + item.offsetWidth;
        if (numW >= teleW) {
          item.outHTML = '';
          rec.appendChild(item);
        }
      }
      );

      if (rec.hasChildNodes()) {
        ddlMoreMenu.style.display = "block";
      } else {
        ddlMoreMenu.style.display = "none";
      }
    }

  }

  render() {

    const { submenu, childValue, activePageValue, }: any = this.props;
    // {lightModeTheme ? light.submenu : dark.submenu}
    // this.updateDimensions();

    return (
      <div className="submenu-wrapper">
        <div className="submenu" onLoad={() => this.updateDimensions()}>
          <ul id="teleporter" className="pl-0" >
            {submenu.map((row: any, i: any) => (
              <li key={i} className={activePageValue === row.tabNo ? "menuitem active" : "menuitem"} style={{ display: row.display ? row.display : 'block' }}
                onClick={() => { this.setState({ active: row.tabNo }); childValue(row.tabNo); }}>
                <Link className={activePageValue === row.tabNo ? "active-link" : "inactive-link"} to={`#`}>{row.title}</Link>
              </li>
            ))}
          </ul>
          <div id="ddlMoreMenu" className="dropdown1" >
            <button className="dropbtn1">More... <KeyboardArrowDownIcon className="menuI" /></button>
            <div className="dropdown-content1">
              <ul id="receiver"></ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}