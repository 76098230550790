import { Component } from 'react';

export default class MobileDeviceIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7594 1.43619C10.4334 1.43619 9.35848 2.57262 9.35848 3.97448V7.89904C9.35848 8.29563 8.5 8 8.5 8C8.12487 8 8 8.29563 8 7.89904V3.97448C8 1.77943 9.68315 0 11.7594 0H30.2406C32.3168 0 34 1.77943 34 3.97448V30.0255C34 32.2206 32.3168 34 30.2406 34L13.9054 33.9898C13.5302 33.9896 13.2263 33.6678 13.2265 33.2713C13.2268 32.8747 13.5311 32.5534 13.9062 32.5536L30.2406 32.5638C31.5664 32.5636 32.6415 31.4272 32.6415 30.0255V3.97448C32.6415 2.57262 31.5666 1.43619 30.2406 1.43619H11.7594Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21 29.4574C21 29.0432 21.3358 28.7074 21.75 28.7074H21.7666C22.1808 28.7074 22.5166 29.0432 22.5166 29.4574C22.5166 29.8716 22.1808 30.2074 21.7666 30.2074H21.75C21.3358 30.2074 21 29.8716 21 29.4574Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.71255 8.44895C1.8745 8.44895 1.19513 9.2726 1.19513 10.2886V30.8018C1.19513 31.8412 1.77033 32.5511 2.58946 32.5511H13.3457C14.19 32.5511 14.8049 31.8102 14.8049 30.8018V10.2886C14.8049 9.2726 14.1255 8.44895 13.2875 8.44895H2.71255ZM0 10.2886C0 8.47237 1.21445 7 2.71255 7H13.2875C14.7856 7 16 8.47237 16 10.2886V30.8018C16 32.6257 14.8374 34 13.3457 34H2.58946C1.07244 34 0 32.5947 0 30.8018V10.2886Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 30.4528C7 30.0386 7.33579 29.7028 7.75 29.7028H7.76659C8.18081 29.7028 8.51659 30.0386 8.51659 30.4528C8.51659 30.867 8.18081 31.2028 7.76659 31.2028H7.75C7.33579 31.2028 7 30.867 7 30.4528Z" />
            </svg>
        )
    }
};
