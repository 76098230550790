import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";

export class DeleteNotificationEndpoint implements IDeleteNotificationEndpoint
{
    public async execute(nodeId: number, notificationId: string): Promise<void>
    {
        try
        {
            await axios.delete(`${apis.allApi}${nodeId}/Notifications/${notificationId}`);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteNotificationEndpoint
{
    execute(nodeId: number, notificationId: string): Promise<void>
}
