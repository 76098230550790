import React, { Component } from 'react'
import { connect } from 'react-redux';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay'
import { Box, Grid } from '@mui/material';
import IbssHorizontalTabs, { TabsParameters } from '../../../../Components/Layout/Tabs/IbssTabs';
import CalendarIcon from '../../../../Components/Icons/CalendarIcon';
import RightArrowIcon from '../../../../Components/Icons/RightArrowIcon';
import { appContext } from '../../../../AppContext';
import CalendarIntegration from './CalendarIntegration';
import DataPrivacy from './DataPrivacy';
import ManagePin from './ManagePin';
import SecurityIcon from '../../../../Components/Icons/SecurityIcon';
import DataPrivacyIcon from '../../../../Components/Icons/DataPrivacyIcon';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';

class EditAccountSettings extends Component<any, any> {
    private labels = appContext().labels;

    state = {
        isLoading: false,
    };

    render() {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubMenuAccountSettings });

        const listTabs: TabsParameters[] = [
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.calenderIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelCalendarIntegration}
                        </div>
                    </div>
                ),
                components: <CalendarIntegration />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.dataPrivacyIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelDataPrivacy}
                        </div>
                    </div>
                ),
                components: <DataPrivacy />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.securityIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelManagePIN}
                        </div>
                    </div>
                ),
                components: <ManagePin />,
            },
        ];
        return (
            <>
            <Box className="page-height-exct-header">
                {this.state.isLoading && <LoadingOverlay />}
                <div className='rightPanel-main-content'>
                    <Grid container gap={15} columns={16}>
                        <IbssHorizontalTabs
                            className='ibss-vertical-tab'
                            orientation='vertical'
                            tabs={listTabs}
                            boxwidth="344px"
                            sx={{
                                fontSize: "18px", marginTop: "25px", fontFamily: "Source Sans Pro", backgroundColor: `background.paper`, display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            leftAlignTabContent
                        />
                    </Grid>
                </div>
            </Box>
            </>
        )
    }
};

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(EditAccountSettings);
