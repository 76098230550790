import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { BookingFilter2 } from "./BookingRepository";

export class DownloadV1BookingEndpoint implements IDownloadV1BookingEndpoint
{
    public async execute(buildingId: number, filter: BookingFilter2): Promise<Blob>
    {
        try
        {
            const query =
                `$select=_CreatedAt,_CreatedBy,Booking_Id,Booking_Owner_Name,Booking_Owner_Email,Booking_Start,Booking_End,Booking_Name,Space_Id,Space_Name,Space_Layout,Booking_Status,Booking_Checkin_Time,Booking_Completed_Time,Booking_Cancelled_Time&` +
                `$top=2000&` +
                `$filter=${filter.toODataString()}`;

            const response = await axios.get<Blob>(`${apis.reportingApiV1}${buildingId}/Bookings/download?${query}`, { responseType: 'blob' });
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDownloadV1BookingEndpoint
{
    execute(buildingId: number, filter: BookingFilter2): Promise<Blob>;
}
