export const SWITCH_THEME:string = "SWITCH_THEME";
export const IDENTITY_PROVIDER_DATA:string = "IDENTITY_PROVIDER_DATA";
export const UPDATE_MAIN_PAGE_TITLE:string = "UPDATE_MAIN_PAGE_TITLE";
export const FLEX_MY_SEARCH_FILTER:string = "FLEX_MY_SEARCH_FILTER";
export const FACILITY_ZONE_DETAIL:string = "FACILITY_ZONE_DETAIL";
export const MY_NOTIFICATION_LIST:string = "MY_NOTIFICATION_LIST";
export const MY_NOTIFICATION_READED_LIST:string = "MY_NOTIFICATION_READED_LIST";
export const MY_NEW_NOTIFICATION:string = "MY_NEW_NOTIFICATION";
export const ONELENS_SPACEANALYTICS_OVERVIEW:string = "ONELENS_SPACEANALYTICS_OVERVIEW"
export const ONELENS_SPACEANALYTICS_FILTER:string = "ONELENS_SPACEANALYTICS_FILTER";
export const ONE_LENSE_DATE_BULDING:string = "ONE_LENSE_DATE_BULDING";
export const HEAT_MAP_FILTER_CRITERIA:string = "HEAT_MAP_FILTER_CRITERIA";
export const ONELENS_SPACEANALYTICS_DETAIL:string = "ONELENS_SPACEANALYTICS_DETAIL";

/* Space Analytics Constants start*/

export const defaultNodeLevel:string = 'Floor';
export const periodTypeMonth:string = "Month";
export const defaultAny:string = "Any";
export const defaultMetabookable:number = 0;
export const classTypeDefault:string = "Work";
export const workspaceTypeDefault:string = "Total";
export const space_Kpi_Target:string = "Occ_Work_Space_KPI_Target";
export const capacity_Kpi_Target:string = "Occ_Work_Capacity_KPI_Target";
export const constTotal: string = "Total";
export const constAny: string = "Any";
export const constSpaceTypeLabel:string = "Space_Type_Label";
export const constBuilding:string = "Building";
export const constSpace: string = "Space";
export const constFloor: string = "Floor";
export const constWork: string = "Work";

/* Space Analytics Constants end*/
