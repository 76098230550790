import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { handleResDataLength, handleTableSpaceError } from "../../../../Common/Helper";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { appContext } from "../../../../AppContext";

class ListRegions extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private local = appContext().localStorageProvider;

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            spaces: [],
            filter: "",
            tableMessage: this.labels.HubLabelLoadingText,
            isCreateRight: false,
        };
    }

    componentDidMount()
    {
        const { match }: any = this.props;
        const filter = match.url.split('=')[1];
        this.setState({ filter: filter });
        apis.getSpacesByFilter(filter).then((res) =>
        {
            // handleResDataLength(this, res, this.labels.HublabelErrorEmptyData);
            this.setState({
                spaces: res.data
            })
        }).catch((error: any) =>
        {
            const tableError = handleTableSpaceError(this, error, this.labels.HubLabelTokenErorr);
            this.setState({
                ...tableError
            })
        });
        this.getRightList();
        sessionStorage.removeItem('spaceFilter');
        sessionStorage.removeItem('showOrganisation');
        sessionStorage.removeItem('organisationState');
        sessionStorage.removeItem('orgnisationnPath');
        sessionStorage.removeItem('organisationId');
        sessionStorage.removeItem('showRegion');
        sessionStorage.removeItem('regionState');
        sessionStorage.removeItem('regionPath');
        sessionStorage.removeItem('regionId');
        sessionStorage.removeItem('showBuilding');
        sessionStorage.removeItem('buildingState');
        sessionStorage.removeItem('buildingPath');
        sessionStorage.removeItem('buildingId');
        sessionStorage.removeItem('showFloor');
        sessionStorage.removeItem('floorState');
        sessionStorage.removeItem('floorPath');
        sessionStorage.removeItem('floorId');
        sessionStorage.removeItem('showZone');
        sessionStorage.removeItem('zoneState');
        sessionStorage.removeItem('zonePath');
        sessionStorage.removeItem('floorId');
        sessionStorage.removeItem('showRoom');
        sessionStorage.removeItem('roomState');
        sessionStorage.removeItem('roomPath');
        sessionStorage.removeItem('roomId');
        sessionStorage.removeItem('showDesk');
        sessionStorage.removeItem('deskState');
        sessionStorage.removeItem('deskPath');
        sessionStorage.removeItem('deskId');
    }

    getRightList = () =>
    {
        const res = this.local.getIbssRightList();

        if (res.ADMINPORTAL && res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Create") > -1)
        {
            this.setState({
                isCreateRight: true
            })
        }
    }

    handleClick(params: any)
    {
        const { history }: any = this.props;
        history.push("/portfolio-setup-spaces/edit/" + params.id + "/Region/0");
    }

    render()
    {
        const { spaces, filter }: any = this.state;

        let rowData: any = [];
        for (let j = 0; j < spaces.length; j++)
        {
            let params = spaces[j];
            spaces[j] = {
                ...spaces[j],
                clickEvent: () => this.handleClick(params),
                'grossArea': spaces[j].area.grossArea,
                'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                    defaultChecked={spaces[j].isEnabled === 1 ? true : false} />,
                'Option': <button className="btn btn-success" onClick={() => this.handleClick(params)} >Edit </button>
            }
        }

        const data = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "code",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCapacity,
                    field: "personCapacity",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelSize,
                    field: "grossArea",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelActive,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                },
            ],
            rows: spaces
        };

        return (
            <div className="rightPanel-main-content">
                <div className="table-panel">
                    <TablePanelInfo
                        tableHeading={this.labels.HubLabelSpaces}
                        iconSrc="/images/Sidebar_Icons/Light_theme/Region.svg"
                    />
                    <div className="table-panel-content">
                        <Link to={{ pathname: `/portfolio-setup-spaces/edit/0/` + filter + `/0`, }}>
                            {this.state.isCreateRight === false &&
                                <button className="button-primary-disable" disabled={true}>{this.labels.HubButtonAdd}</button>
                            }
                            {this.state.isCreateRight === true &&
                                <button className="button-primary">{this.labels.HubButtonAdd}</button>
                            }
                        </Link>
                    </div>
                    <MDBDataTable className="standardTable tablePageNoButtons tableSpaces" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={data} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(ListRegions);

export interface IProps
{
}

export interface IState
{
    spaces: [];
    filter: string;
    tableMessage?: string;
    isCreateRight: boolean;
}
