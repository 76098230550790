import { Component } from "react";

export class ComponentHelper<P, S>
{
    private component: Component<P, S>;

    constructor(component: Component<P, S>)
    {
        this.component = component;
    }

    public setStateAsync<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null)): Promise<void>
    {
        return new Promise(resolve =>
        {
            this.component.setState(state, () => resolve());
        });
    }
}
