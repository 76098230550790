import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Classification, Status } from "./CateringItemRepository";
import { ISpaceCateringMenuItem } from "./GetManyEndpoint";

export class CreateEndpoint implements ICreateEndpoint
{
    public async execute(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<ISpaceCateringMenuItem>
    {
        try
        {
            return (await axios.post(`${apis.allApiv2}${buildingId}/catering-menuitem`, payload)).data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateEndpoint
{
    execute(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<ISpaceCateringMenuItem>;
}

export interface ICreateSpaceCateringMenuItemPayload
{
    Description: string,
    Classification: Classification | string,
    Status: Status,
    UnitPrice: number;
    RetailPrice: number;
    ImageURI: string;
    NutritionalInformation: string;
    Supplier_Id: string;
    OrderPolicy_Id: string;
    Name: string;
    Node_Id: number;
}
