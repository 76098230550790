import { Component } from 'react';

export default class OrganisationIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path id="Path_65" data-name="Path 65" d="M5.062,8.57a3.788,3.788,0,0,0-.937,2.592,3.776,3.776,0,0,0,.944,2.6A3.365,3.365,0,0,0,7.615,14.8a4.1,4.1,0,0,0,1.774-.39.728.728,0,0,1,.633,1.311,5.557,5.557,0,0,1-2.407.535A4.817,4.817,0,0,1,4,14.751a5.228,5.228,0,0,1-1.333-3.589A5.24,5.24,0,0,1,3.99,7.584,4.809,4.809,0,0,1,7.615,6.066a6.386,6.386,0,0,1,1.237.13.728.728,0,1,1-.284,1.428,4.936,4.936,0,0,0-.953-.1A3.357,3.357,0,0,0,5.062,8.57Z" fill-rule="evenodd" />
                <path id="Path_66" data-name="Path 66" d="M2.948,21.7A6.776,6.776,0,0,0,1.7,25.756v.767a1.19,1.19,0,0,0,.215.706c.122.162.231.189.279.189H5.033a.728.728,0,0,1,0,1.456H2.191A1.819,1.819,0,0,1,.749,28.1a2.642,2.642,0,0,1-.507-1.581v-.767a8.227,8.227,0,0,1,1.542-4.927,4.989,4.989,0,0,1,3.959-2.146h2.02a.728.728,0,0,1,0,1.456H5.744A3.541,3.541,0,0,0,2.948,21.7Z" fill-rule="evenodd" />
                <path id="Path_67" data-name="Path 67" d="M24.148,6.2a6.386,6.386,0,0,1,1.237-.13A4.809,4.809,0,0,1,29.01,7.584a5.24,5.24,0,0,1,1.321,3.578A5.228,5.228,0,0,1,29,14.751a4.817,4.817,0,0,1-3.614,1.507,5.557,5.557,0,0,1-2.407-.535.728.728,0,0,1,.633-1.311,4.1,4.1,0,0,0,1.774.39,3.364,3.364,0,0,0,2.546-1.041,3.776,3.776,0,0,0,.944-2.6,3.788,3.788,0,0,0-.937-2.592,3.356,3.356,0,0,0-2.553-1.047,4.936,4.936,0,0,0-.953.1A.728.728,0,1,1,24.148,6.2Z" fill-rule="evenodd" />
                <path id="Path_68" data-name="Path 68" d="M24.508,19.412a.728.728,0,0,1,.728-.728h2.02a4.989,4.989,0,0,1,3.959,2.146,8.226,8.226,0,0,1,1.542,4.927v.767a2.642,2.642,0,0,1-.507,1.581,1.819,1.819,0,0,1-1.442.77H27.967a.728.728,0,0,1,0-1.456h2.842c.048,0,.156-.027.279-.189a1.19,1.19,0,0,0,.215-.706v-.767a6.776,6.776,0,0,0-1.25-4.052,3.541,3.541,0,0,0-2.8-1.565h-2.02A.728.728,0,0,1,24.508,19.412Z" fill-rule="evenodd" />
                <path id="Path_69" data-name="Path 69" d="M16.478,6.552a4.06,4.06,0,0,0-3.657,2.266l0,.007h0a4.165,4.165,0,0,0-.443,1.852,4.079,4.079,0,0,0,.9,2.585,4.125,4.125,0,0,0,7.349-2.585,4,4,0,0,0-.46-1.865L20.159,8.8h0A4.088,4.088,0,0,0,16.478,6.552ZM11.515,8.175a5.566,5.566,0,0,1,9.946-.028,5.451,5.451,0,0,1,.62,2.529,5.579,5.579,0,0,1-1.221,3.5l0,0a5.6,5.6,0,0,1-8.715,0l0,0h0a5.535,5.535,0,0,1-1.221-3.5A5.621,5.621,0,0,1,11.515,8.175Z" fill-rule="evenodd" />
                <path id="Path_70" data-name="Path 70" d="M13.6,20.139a1.259,1.259,0,0,0-.253.013.712.712,0,0,1-.092.014c-2.505.225-4.765,3.105-4.765,6.988v.938a1.621,1.621,0,0,0,.3.976.628.628,0,0,0,.482.292H23.73a.624.624,0,0,0,.486-.3,1.642,1.642,0,0,0,.292-.971v-.938c0-3.888-2.242-6.763-4.72-6.988a.722.722,0,0,1-.092-.014,1.259,1.259,0,0,0-.253-.013Zm-.019-1.456h5.876a2.62,2.62,0,0,1,.5.037c3.521.346,6,4.172,6,8.434v.938a3.093,3.093,0,0,1-.581,1.842,2.069,2.069,0,0,1-1.652.882H9.27a2.073,2.073,0,0,1-1.64-.865,3.071,3.071,0,0,1-.594-1.859v-.938c0-4.267,2.51-8.088,6.049-8.434A2.617,2.617,0,0,1,13.585,18.684Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
