import { Component } from 'react';

export default class Firstaid extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 9C0.25 7.48122 1.48122 6.25 3 6.25H31C32.5188 6.25 33.75 7.48122 33.75 9V31C33.75 32.5188 32.5188 33.75 31 33.75H3C1.48122 33.75 0.25 32.5188 0.25 31V9ZM3 7.75C2.30964 7.75 1.75 8.30964 1.75 9V31C1.75 31.6904 2.30964 32.25 3 32.25H31C31.6904 32.25 32.25 31.6904 32.25 31V9C32.25 8.30964 31.6904 7.75 31 7.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 3C9.25 1.48122 10.4812 0.25 12 0.25H22C23.5188 0.25 24.75 1.48122 24.75 3V7H23.25V3C23.25 2.30964 22.6904 1.75 22 1.75H12C11.3096 1.75 10.75 2.30964 10.75 3V7H9.25V3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.75C11.8914 10.75 7.75 14.8914 7.75 20C7.75 25.1086 11.8914 29.25 17 29.25C22.1086 29.25 26.25 25.1086 26.25 20C26.25 14.8914 22.1086 10.75 17 10.75ZM6.25 20C6.25 14.0629 11.0629 9.25 17 9.25C22.9371 9.25 27.75 14.0629 27.75 20C27.75 25.9371 22.9371 30.75 17 30.75C11.0629 30.75 6.25 25.9371 6.25 20Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.85 14C13.85 13.5858 14.1858 13.25 14.6 13.25H19.4C19.8142 13.25 20.15 13.5858 20.15 14V16.85H23C23.4142 16.85 23.75 17.1858 23.75 17.6V22.4C23.75 22.8142 23.4142 23.15 23 23.15H20.15V26C20.15 26.4142 19.8142 26.75 19.4 26.75H14.6C14.1858 26.75 13.85 26.4142 13.85 26V23.15H11C10.5858 23.15 10.25 22.8142 10.25 22.4V17.6C10.25 17.1858 10.5858 16.85 11 16.85H13.85V14ZM15.35 14.75V17.6C15.35 18.0142 15.0142 18.35 14.6 18.35H11.75V21.65H14.6C15.0142 21.65 15.35 21.9858 15.35 22.4V25.25H18.65V22.4C18.65 21.9858 18.9858 21.65 19.4 21.65H22.25V18.35H19.4C18.9858 18.35 18.65 18.0142 18.65 17.6V14.75H15.35Z" />
            </svg>
        )
    }
};