import { IExternalIdentityProvider, IGetExternalIdentityProvidersEndpoint } from "./GetExternalIdentityProvidersEndpoint";
import { IIdentityProvider, IGetIdentityProvidersEndpoint } from "./GetIdentityProvidersEndpoint";

export class ExternalIdentityProviderRepository implements IExternalIdentityProviderRepository
{
    private getExternalIdentityProvidersEndpoint: IGetExternalIdentityProvidersEndpoint;
    private getIdentityProvidersEndpoint: IGetIdentityProvidersEndpoint;
    
    constructor(
        getExternalIdentityProvidersEndpoint: IGetExternalIdentityProvidersEndpoint,
        getIdentityProvidersEndpoint: IGetIdentityProvidersEndpoint,
        )
    {
        this.getExternalIdentityProvidersEndpoint =  getExternalIdentityProvidersEndpoint;
        this.getIdentityProvidersEndpoint = getIdentityProvidersEndpoint;
    }

    public getExternalIdentityProviders(suppressErrorPopup: boolean): Promise<IExternalIdentityProvider[]>
    {
        return this.getExternalIdentityProvidersEndpoint.execute(suppressErrorPopup);
    }

    public getIdentityProviders(): Promise<IIdentityProvider[]>
    {
        return this.getIdentityProvidersEndpoint.execute();
    }

}

export interface IExternalIdentityProviderRepository
{
    getExternalIdentityProviders(suppressErrorPopup: boolean): Promise<IExternalIdentityProvider[]>;
    getIdentityProviders(): Promise<IIdentityProvider[]>;
}