import React, { Component } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type TabPanelProps = {
    children: React.ReactNode,
    value: number,
    index: number,
    orientation?: "horizontal" | "vertical",
}

class IbssTabPanel extends Component<TabPanelProps> {

    constructor(props: TabPanelProps) {
        super(props);
    }

    render() {
        const { children, value, index, ...other } = this.props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: this.props.orientation === "vertical" ? 3 : null}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
};

export default IbssTabPanel;