import "../SpaceCard/SpaceCard.scss";
import { Component } from "react";
import { connect } from "react-redux";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ChartPanelInfo from "../../Headings/ChartHeading/ChartPanelInfo";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { appContext } from "../../../AppContext";
import IbssButtonRedo from "../../Buttons/Button/IbssButton";
import { constTotal, defaultAny, defaultNodeLevel, defaultMetabookable } from "../../../app/constants";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Space } from "../../../Providers.Api/Spaces/SpaceRepository";
import { DateTime } from 'luxon';

class SpaceAnalyticsCard extends Component<Props, State>
{
    private imagePaths = new Map<string, string>();
    private labels = appContext().labels;
    private local = appContext().localStorageProvider;
    constructor(props: Props) {
        super(props);
        this.state = new State();
    };
   
     Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

      public componentDidMount(): void {
        let response = this.local.getUserDetails().userRights;
        if (response ?.ONELENS360?.SpaceAnalytics?.includes?.('Analytics')) {
          this.setState({
            viewAllButtons: true
          })
        }
      }

    public render(): JSX.Element {
        let item = this.props;
        const tempretatureInfo = 
        [
          {space: `${this.props.Period_Current_Space_Value.toFixed(2)}`,label: `${this.labels.HubLabelSpaces}`,name: ""},
          {capacity: `${this.props.Period_Current_Capacity_Value.toFixed(2)}`,label: `${this.labels.HubLabelCapacity}`,name: ""},
          {peak: `${this.props.Period_Current_Peak_Value.toFixed(2)}`,label: `${this.labels.HublabelPeak}`,name: ""}
        ];
        const state ={
          buildingNodeId: this?.props?.onelensSpaceAnalyticsOverviewbuildingNodeId,
          buildingname: this?.props?.onelensSpaceAnalyticsOverviewbuildingbuildingName,
          classtype: this?.props?.Space_Class,
          periodtype: this.props.onelensSpaceAnalyticsOverviewperiodType,
          startdate: DateTime.fromJSDate(new Date(this.props.onelensSpaceAnalyticsOverviewStartDate)).toISO(),
          enddate: DateTime.fromJSDate(new Date(this.props.onelensSpaceAnalyticsOverviewEndDate)).toISO(),
          floortype: defaultAny,
          workspaceType: constTotal, 
          groupby: defaultNodeLevel,
          metabookable:+defaultMetabookable
        }
        return (
          <>
            <Grid item md={4} xs={12}>
              <div className="ibss-card">
                <div className="ibss-card-header">
                  <ChartPanelInfo
                    chartHeading={`${
                      this?.props?.Space_Class === "Work"
                        ? this.labels.HublabelWork
                        : this?.props?.Space_Class === "Support"
                        ? this.labels.HublabelSupport
                        : this?.props?.Space_Class === "Amenity"
                        ? this.labels.HublabelAmenity
                        : ""
                    } ${this.labels.HublabelSpaceClassification}`}
                    iconSrc="/images/fi_info.svg"
                  />
                </div>
                <div className="ibss-card-body">
                  <div className="">
                    <ResponsiveContainer width={"100%"} height={200}>
                      <BarChart
                        data={tempretatureInfo}
                        height={200}
                        width={400}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" unit="%" />
                        <Tooltip
                          contentStyle={{
                            color: "var(--ui-text)",
                            backgroundColor: "var(--ui-background-alternate)",
                          }}
                        />
                        {/* Bar graph division */}
                        <Bar
                          dataKey="space"
                          name={this.labels.HubLabelSpace}
                          stackId="a"
                          fill="#4584ff"
                        />
                        <Bar
                          dataKey="capacity"
                          name={this.labels.HubLabelCapacity}
                          stackId="a"
                          fill="#6cc1a7"
                        />
                        <Bar
                          dataKey="peak"
                          name={this.labels.HublabelPeak}
                          stackId="a"
                          fill="#ea754b"
                        />
                        <Bar
                          dataKey="NoData"
                          name={this.labels.HubLabelNoData}
                          stackId="a"
                          fill="#393D44"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={5}>
                    </Grid>
                    <Grid item xs={6}>
                              <h2 className="text-ui-20 my-0" >
                               {this.labels.HubLabelUtilization}
                              </h2>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                  </Grid>
                  </div>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div
                            className="statusbox my-8"
                            style={{ backgroundColor: "#4584ff" }}
                          ></div>
                        </td>
                        <td>
                          <p className="barchart-data-labels my-8">
                            {this.labels.HublabelSpaceUtilization}
                          </p>
                        </td>
                        <td>
                          <p className="barchart-data-labels text-right my-8">
                            {Math.round(this.props.Period_Current_Space_Value)}%
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            className="statusbox my-8"
                            style={{ backgroundColor: "#6cc1a7" }}
                          ></div>
                        </td>
                        <td>
                          <p className="barchart-data-labels my-8">
                            {this.labels.HublabelCapacityUtilization}
                          </p>
                        </td>
                        <td>
                          <p className="barchart-data-labels text-right my-8">
                            {Math.round(
                              this.props.Period_Current_Capacity_Value
                            )}
                            %
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            className="statusbox my-8"
                            style={{ backgroundColor: "#ea754b" }}
                          ></div>
                        </td>
                        <td>
                          <p className="barchart-data-labels my-8">
                            {this.labels.HublabelPeakUtilization}
                          </p>
                        </td>
                        <td>
                          <p className="barchart-data-labels text-right my-8">
                            {Math.round(this.props.Period_Current_Peak_Value)}%
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.state.viewAllButtons === true && 
                    <Link 
                    to={{
                      pathname: "/space-analytics-analytics",
                      state: {...state},
                    }}
                    className="text-decoration-none">
                      <div  className="d-flex justify-content-center">
                      <IbssButtonRedo
                        variant='contained'
                      >
                          {" "}
                        {this.labels.HublabelViewAll} {this?.props?.Space_Class}{" "}
                        {this.labels.HubLabelSpaces}
                      </IbssButtonRedo>
                      </div>
                    </Link>
                  }
                </div>
              </div>
            </Grid>
          </>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        onelensSpaceAnalyticsOverviewperiodType: state.onelensSpaceAnalyticsOverview.periodType,
        onelensSpaceAnalyticsOverviewStartDate: state.onelensSpaceAnalyticsOverview.fmsfc_start_date_for_filter_modal,
        onelensSpaceAnalyticsOverviewEndDate: state.onelensSpaceAnalyticsOverview.End_Date_For_filter_modal,
        onelensSpaceAnalyticsOverviewbuildingNodeId: state.onelensSpaceAnalyticsOverview.buildingNodeId,
        onelensSpaceAnalyticsOverviewbuildingbuildingName: state.onelensSpaceAnalyticsOverview.buildingOption,
    };
};

export default connect(mapStateToProps)(SpaceAnalyticsCard);
export class Props {
    public imageUrl = "";
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceType = "";
    public spaceCapacity = 0;
    public spaceSetup = 0;
    public zone = "";
    public lightModeTheme = true;
    public Period_Change_Capacity_Value= 0;
    public Space_Class= "";
    public Period_Current_Peak_Value= 0;
    public Period_Current_Capacity_Value= 0;
    public Period_Current_Max_Occ_Value= 0;
    public Period_Current_Space_Value= 0;
    public onelensSpaceAnalyticsOverviewperiodType = "";
    public onelensSpaceAnalyticsOverviewStartDate = "";
    public onelensSpaceAnalyticsOverviewEndDate = "";
    public onelensSpaceAnalyticsOverviewbuildingNodeId = 0;
    public onelensSpaceAnalyticsOverviewbuildingbuildingName ="";

    constructor(space: Space) {
        this.imageUrl = space.ImageURI;
        this.nodeId = space.Node_Id;
        this.spaceId = space.Space_Id;
        this.spaceName = space.Space_Name;
        this.spaceType = space.Space_Type;
        this.spaceCapacity = space.Space_Capacity;
        this.spaceSetup = space.Space_Setup;
        this.zone = space.Meta_Loc_Zone;
    }
}

export class State {
  public viewAllButtons:boolean = false
}
