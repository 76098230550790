import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(buildingId: number,cateringMenuItemId: string, payload: IUpdateCateringRestrictionsPayload): Promise<void>
    {
        try
        {
            await axios.patch(`${apis.allApiv2}${buildingId}/catering-menuitem/${cateringMenuItemId}/catering-restrictions`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(buildingId: number,cateringMenuItemId:string, payload: IUpdateCateringRestrictionsPayload): Promise<void>;
}

export interface IUpdateCateringRestrictionsPayload
{
    Add_Ids: string[];
    Remove_Ids: string[]
}