import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringRestriction } from "./CateringRestrictionsRepository";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(nodeId: number, restrictionId: string, payload: ICateringRestriction): Promise<IEditCateringRestrictionResponse>
    {
        try
        {
            const response = await axios.patch<IEditCateringRestrictionResponse>(apis.allApiv2 + nodeId + `/catering-restriction/` + restrictionId, payload);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(nodeId: number, restrictionId: string, payload: ICateringRestriction): Promise<IEditCateringRestrictionResponse>;
}

export interface IEditCateringRestrictionResponse
{
    Message: string;
}