import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CateringItemsFilter, Classification, Status } from "./CateringItemRepository";
import { IRawPagedResponse } from "../Models";
import { DateTime } from "luxon";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>
    {
        try
        {
            const query = `$filter=${filter.toODataString()}`;
            const response = await axios.get<IRawPagedResponse<ISpaceCateringMenuItem[]>>(`${apis.allApiv2}${buildingId}/catering-menuitem?${query}`);   
            return response.data.Value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>;
}

export interface ISupplier
{
    Name: string;
    Description: string;
}


export interface ISpaceCateringMenuItem
{
    Description: string;
    Status: Status;
    UnitPrice: number;
    Classification: Classification;
    RetailPrice: number;
    Name: string;
    Supplier_Id: string;
    MenuItem_Id: string;
    ConcurrencyStamp: string;
    NutritionalInformation: string;
    OrderPolicy_Id: string;
    ImageURI: string;
    CreatedAt: string;
}


