import { Component } from 'react';
export default class RepeatIcon extends Component
{
    render()
    {
        return (
            <svg width="16" height="18" viewBox="0 0 16 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.353027 5.02937C0.353027 3.81109 1.34063 2.82349 2.55891 2.82349H6.67656C6.92021 2.82349 7.11773 3.02101 7.11773 3.26466C7.11773 3.50832 6.92021 3.70584 6.67656 3.70584H2.55891C1.82794 3.70584 1.23538 4.2984 1.23538 5.02937V12.6764C1.23538 13.4074 1.82794 14 2.55891 14H5.61146L3.71754 12.106C3.54525 11.9337 3.54525 11.6544 3.71754 11.4821C3.88983 11.3098 4.16917 11.3098 4.34146 11.4821L6.98851 14.1292C7.16081 14.3015 7.16081 14.5808 6.98851 14.7531L4.34146 17.4002C4.16917 17.5724 3.88983 17.5724 3.71754 17.4002C3.54525 17.2279 3.54525 16.9485 3.71754 16.7762L5.61146 14.8823H2.55891C1.34063 14.8823 0.353027 13.8947 0.353027 12.6764V5.02937Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0588 12.6764C15.0588 13.8947 14.0712 14.8823 12.853 14.8823L8.73531 14.8823C8.49165 14.8823 8.29413 14.6848 8.29413 14.4411C8.29413 14.1975 8.49165 14 8.73531 14L12.853 14C13.5839 14 14.1765 13.4074 14.1765 12.6764L14.1765 5.02938C14.1765 4.29842 13.5839 3.70585 12.853 3.70585L9.8004 3.70585L11.6943 5.59978C11.8666 5.77207 11.8666 6.0514 11.6943 6.22369C11.522 6.39598 11.2427 6.39598 11.0704 6.22369L8.42335 3.57664C8.34061 3.4939 8.29413 3.38168 8.29413 3.26468C8.29413 3.14767 8.34061 3.03545 8.42335 2.95272L11.0704 0.305659C11.2427 0.13337 11.522 0.13337 11.6943 0.305659C11.8666 0.47795 11.8666 0.757287 11.6943 0.929578L9.8004 2.8235L12.853 2.8235C14.0712 2.8235 15.0588 3.81111 15.0588 5.02938L15.0588 12.6764Z" fill="inherit" />
            </svg>
        )
    }
};
