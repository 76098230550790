import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IGetCateringRestrictions } from "./CateringRestrictionsRepository";

export class GetCateringRestrictionsEndpoint implements IGetCateringRestrictionsEndpoint
{
    public async execute(nodeId: number): Promise<IGetCateringRestrictions>
    {
        try
        {
            const response = await axios.get<IGetCateringRestrictions>(apis.allApiv2 + nodeId + `/catering-restriction`);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface IGetCateringRestrictionsEndpoint
{
    execute(nodeId: number): Promise<IGetCateringRestrictions>;
}
