import axios, { AxiosResponse } from "axios";
import { IbssRequestConfig } from "../ApiClient";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CateringMenuStatus } from "./CateringMenuRepository";

export class EnableEndpoint implements IEnableEndpoint
{
    public async execute(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>
    {
        try
        {
            const config: IbssRequestConfig = { suppressErrorPopup: suppressError };
            const response = await axios.patch(`${apis.allApiv2}${nodeId}/catering-menu/${cateringMenuId}`, { ConcurrencyStamp: concurrencyStamp, Status: CateringMenuStatus.active }, config);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEnableEndpoint
{
    execute(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>;
}
