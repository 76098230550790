import { Component } from 'react';

export default class Prayer extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.215 9.00003C9.215 6.35734 11.3573 4.21503 14 4.21503C16.6427 4.21503 18.785 6.35734 18.785 9.00003C18.785 11.6427 16.6427 13.785 14 13.785C11.3573 13.785 9.215 11.6427 9.215 9.00003ZM14 5.78503C12.2244 5.78503 10.785 7.22443 10.785 9.00003C10.785 10.7756 12.2244 12.215 14 12.215C15.7756 12.215 17.215 10.7756 17.215 9.00003C17.215 7.22443 15.7756 5.78503 14 5.78503Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 13.6667C16.25 11.7797 17.7797 10.25 19.6667 10.25C21.5536 10.25 23.0833 11.7797 23.0833 13.6667V21.5453C23.0833 23.5149 22.4679 25.4353 21.3231 27.038L20.4574 28.25H25C26.5188 28.25 27.75 29.4812 27.75 31V33C27.75 33.4142 27.4142 33.75 27 33.75H9C7.48122 33.75 6.25 32.5188 6.25 31V30.7198C6.25 29.8134 6.69663 28.9652 7.44397 28.4524L11.3358 25.7816C11.3893 25.7449 11.446 25.716 11.5043 25.6948C11.5395 25.5471 11.5903 25.401 11.6573 25.2586L16.25 15.499V13.6667ZM11.739 27.3241L8.29271 29.6892C7.95301 29.9223 7.75 30.3078 7.75 30.7198V31C7.75 31.6904 8.30964 32.25 9 32.25H26.25V31C26.25 30.3096 25.6904 29.75 25 29.75H19.8902C18.9076 29.75 18.336 28.6393 18.9071 27.8397L20.1025 26.1661C21.0656 24.8178 21.5833 23.2023 21.5833 21.5453V13.6667C21.5833 12.6081 20.7252 11.75 19.6667 11.75C18.6081 11.75 17.75 12.6081 17.75 13.6667V15.5646C17.75 15.7422 17.7108 15.9176 17.6352 16.0783L13.0145 25.8973C12.8522 26.2421 12.9782 26.6537 13.3056 26.8486C13.6813 27.0723 14.168 26.9233 14.3541 26.5277L18.9881 16.6807C19.1644 16.3059 19.6112 16.145 19.986 16.3214C20.3608 16.4978 20.5217 16.9446 20.3453 17.3193L15.7114 27.1664C15.148 28.3636 13.6752 28.8144 12.5383 28.1375C12.1951 27.9331 11.925 27.6501 11.739 27.3241Z" />
            </svg>
        )
    }
};