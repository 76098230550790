import React from 'react';
import { Box, Grid, Typography} from '@mui/material';
import { DateTime } from 'luxon';
import { appContext } from '../../../AppContext';
import IbssButton from '../../Buttons/Button/IbssButton';
import apis from '../../../Providers.Api/apis';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import LoadingOverlay from '../../Navigation/LoadingOverlay/LoadingOverlay';
import { Equipment, IBookedEquipment } from './EquipmentsDialog';

class EquipmentSummary extends React.Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            isLoading: false,
        }

    }

    public async handleSubmitAddToBooking(): Promise<void>
    {
        this.setState({ isLoading: true});

        const bookedEquipmentResourceIds = this.props.bookedEquipment.map((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id)

        for (const id of this.props.selectedEquipmentIds)
        {
            if (!bookedEquipmentResourceIds.includes(id))
            {
                await apis.updateBookingParties(
                    this.props.floorId,

                    {
                        "Booking_Id": this.props.bookingId,
                        "BookingStart": this.props.bookingStart,
                        "BookingEnd": this.props.bookingEnd,
                        "Booking_Resource_Id": id,
                        "Booking_Participant_Type": 3
                    }
                )
            }
        }

        for (const equipment of this.props.equipments)
        {
            const bookedItemIndex = this.props.bookedEquipment.findIndex((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id === equipment.equipmentId);
            if (bookedItemIndex >= 0 && !this.props.selectedEquipmentIds.includes(this.props.bookedEquipment[bookedItemIndex]?.Booking_Resource_Id))
            {
                await this.apiClient.bookingParties.delete(this.props.floorId, this.props.bookedEquipment[bookedItemIndex].Record_Id);
            }
        }

        this.setState({ isLoading: false });
        if (this.props.equipmentSubmitted)
        {
            this.props.equipmentSubmitted();
        }
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        const startTime = DateTime.fromISO(this.props.bookingStart);
        const endTime = DateTime.fromISO(this.props.bookingEnd);
        return (
            <>
                {this.state.isLoading && <LoadingOverlay />}
                <Box 
                    className="right-pannel-sm" 
                    sx={{
                        minHeight: 'calc(100% - 200px)',
                        padding:'16px 25px',
                        borderRadius: '7px',
                        backgroundColor: theme => theme.palette.background.paper
                    }}
                >
                    <Typography className="summary-card-heading"> {this.labels.HublabelSummary} </Typography>
                    <div>
                        <Box className='summery-card mb-10'>
                            <Typography className="font-14 card-userPref-text">
                                {this.props.bookingName}
                            </Typography>
                            <div className="summary-card-heading my-6">
                                {this.props.spaceName}
                            </div>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <div className='d-flex'>
                                        <IbssSvgIcon className='mr-10'>
                                            {Icons.calenderIcon}
                                        </IbssSvgIcon>
                                        {startTime.toLocaleDateString() === 'Invalid DateTime' ? '' : startTime.toLocaleDateString()}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <div className='d-flex ml-10'>
                                            <IbssSvgIcon className='mr-10'>{Icons.TimeIcon}</IbssSvgIcon>
                                            <span className='mr-10'>
                                                {`${startTime.toLocaleTimeString()} - ${endTime.toLocaleTimeString()}`}
                                            </span>
                                        </div>
                                        <Typography className="card-userPref-text" variant='h6'>
                                            <div>
                                            </div>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <div>
                            </div>
                        </Box>
                    </div>
                    <div>
                        <div style={{ textAlign: "center" }}>
                            {this.props.selectedEquipmentIds.filter((x: string) => this.props.equipments.map(x => x.equipmentId).includes(x)).length} {this.labels.HubLabelItemsSelected}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-20">
                        <IbssButton
                            variant='contained'
                            disabled={this.props?.disabled ?? false }  // if this.props.disabled exists, use that value, else do not disable
                            onClick={() => this.handleSubmitAddToBooking()}
                        >
                            {this.labels.HubButtonUpdateBooking}
                        </IbssButton>
                    </div>
                </Box>
            </>
        )
    }
}

export default EquipmentSummary;

export interface IState
{
    isLoading: boolean;
}

export interface IProps
{
    bookedEquipment: IBookedEquipment[],
    bookingStart: string,
    bookingEnd: string,
    bookingName: string,
    bookingId: string,
    buildingId: string,
    disabled?: boolean,
    equipments: Equipment[],
    equipmentType: string,
    floorId: number,
    onClose: ()=> void,
    selectedEquipmentIds: string[],
    spaceId: string,
    spaceName: string,
    equipmentSubmitted?: () => void,
}