import { Grid, List, ListItem, ListItemButton } from '@mui/material';
import { Component } from 'react'
import LoadingOverlay from '../../Navigation/LoadingOverlay/LoadingOverlay';
import IbssSearchCard from '../../Cards/SearchCard/IbssSearchCard';
import NoEquipment from './NoEquipment';
import { IBookedEquipment } from './EquipmentsDialog';
import { Equipment } from './EquipmentsDialog';

export default class EquipmentList extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
        };
    }

    public render(): JSX.Element
    {
        return (
            <div>
                {this.props.isLoading && <LoadingOverlay />}
                <List>
                {!this.props.isLoading && this.props.equipments?.filter( (equip: { equipmentType: string; }) => equip.equipmentType === this.props.equipmentType).sort((a,b) => a.equipmentName > b.equipmentName ? 1 : -1).map(item => (
                    <ListItem>
                        <ListItemButton 
                            key={item.equipmentId} 
                            onClick={() => this.props.selectEquipment(item.equipmentId)} 
                            selected={this.props.selectedEquipmentIds.includes(item.equipmentId)}
                        >
                        <Grid key={item.equipmentId} container>
                            <Grid item xs={8}>
                                <IbssSearchCard
                                    className='equipment-item'
                                    imageSrc={item.imageUrl}
                                    searchHeading={item.equipmentName}
                                    searchSubHeading={item.equipmentType}
                                    backgroundColor={'transparent'}
                                />
                            </Grid>
                            <Grid item xs={4} className='my-auto'>
                                <div className='ml-2'>
                                    <div className='d-flex align-items-center mb-10'>
                                        {/* added alt attribute to remove warnings */}
                                        <img className='icon-sm mr-10' src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt="floor-icon"/>
                                        <p className="labels-sm my-0">{item.floorName}</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <img className='icon-sm mr-10' src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt="zones-icon"/>
                                        <p className="labels-sm my-0">{item.metaLocZone}</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </ListItemButton>
                    </ListItem>
                ))}
                </List>
                {!this.props.isLoading && this.props.equipments.length === 0 && (
                    <NoEquipment />
                )}
            </div>
        )
    }
}

export interface IProps
{
    buildingId: string;
    bookingId: string;
    bookingStart: string;
    bookingEnd: string;
    equipmentType: string;
    selectEquipment: (equipmentId: string) => void;
    selectedEquipmentIds: string[];
    equipments: Equipment[];
    isLoading: boolean;
}

export interface IQueryParams
{
    buildingid: string;
}

export interface IState
{
    isLoading: boolean;
}