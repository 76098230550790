import * as GetSpaceZones from "./GetSpaceZones";

export class SpaceZonesRepository implements ISpaceZonesRepository
{
    private getSpaceZonesEndpoint: GetSpaceZones.IGetSpaceZonesEndpoint;

    constructor(
        getSpaceZonesEndpoint: GetSpaceZones.IGetSpaceZonesEndpoint,
    )
    {
        this.getSpaceZonesEndpoint = getSpaceZonesEndpoint;
    }

    public async getMultiple(nodeId: number, suppressErrorPopup?: boolean): Promise<GetSpaceZones.ISpaceZone[]>
    {
        return this.getSpaceZonesEndpoint.execute(nodeId, suppressErrorPopup ?? false);
    }
}

export interface ISpaceZonesRepository
{
    getMultiple(nodeId: number, suppressErrorPopup?: boolean): Promise<GetSpaceZones.ISpaceZone[]>
}

