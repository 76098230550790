import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";
import { INotification } from "./GetActiveNotificationEndpoint";
import { NotificationsFilter } from "./NotificationRepository";

export class GetNotificationsEndpoint implements IGetNotificationsEndpoint
{
    public async execute(nodeId: number, startDate: string, endDate: string, filter: NotificationsFilter): Promise<INotification[]>
    {
        try
        {
            const response = await axios.get<INotification[]>(`${apis.allApi}${nodeId}/Notifications?startDate=${startDate}&endDate=${endDate}&$filter=${filter.toODataString()}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetNotificationsEndpoint
{
    execute(nodeId: number, startDate: string, endDate: string, filter: NotificationsFilter): Promise<INotification[]>
}
