import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteCateringSupplierEndpoint implements IDeleteCateringSupplierEndpoint
{
    public async execute(nodeId: number, supplierCateringId: string): Promise<void>
    {
        try
        {
            await axios.delete(`${apis.allApiv2}${nodeId}/catering-supplier/${supplierCateringId}`);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteCateringSupplierEndpoint
{
    execute(nodeId: number, supplierCateringId: string): Promise<void>;
}
