import { Component } from 'react';

export default class Vcroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 5C0.25 3.48122 1.48122 2.25 3 2.25H31C32.5188 2.25 33.75 3.48122 33.75 5V26C33.75 27.5188 32.5188 28.75 31 28.75H3C1.48122 28.75 0.25 27.5188 0.25 26V5ZM3 3.75C2.30964 3.75 1.75 4.30964 1.75 5V26C1.75 26.6904 2.30964 27.25 3 27.25H31C31.6904 27.25 32.25 26.6904 32.25 26V5C32.25 4.30964 31.6904 3.75 31 3.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 32C5.25 31.5858 5.58579 31.25 6 31.25H29.5C29.9142 31.25 30.25 31.5858 30.25 32C30.25 32.4142 29.9142 32.75 29.5 32.75H6C5.58579 32.75 5.25 32.4142 5.25 32Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.215 22C9.215 19.459 11.0383 17.215 13.5 17.215H20.5C22.9617 17.215 24.785 19.459 24.785 22V24C24.785 24.4335 24.4335 24.785 24 24.785C23.5665 24.785 23.215 24.4335 23.215 24V22C23.215 20.1226 21.9043 18.785 20.5 18.785H13.5C12.0957 18.785 10.785 20.1226 10.785 22V24C10.785 24.4335 10.4335 24.785 10 24.785C9.56645 24.785 9.215 24.4335 9.215 24V22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.215 11C12.215 8.35728 14.3573 6.21497 17 6.21497C19.6427 6.21497 21.785 8.35728 21.785 11C21.785 13.6426 19.6427 15.785 17 15.785C14.3573 15.785 12.215 13.6426 12.215 11ZM17 7.78497C15.2244 7.78497 13.785 9.22437 13.785 11C13.785 12.7756 15.2244 14.215 17 14.215C18.7756 14.215 20.215 12.7756 20.215 11C20.215 9.22437 18.7756 7.78497 17 7.78497Z" />
            </svg>
        )
    }
};