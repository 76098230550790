import React, { Component } from 'react'
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { Icons } from '../../../../Common/AllsvgIcons';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFilterOptionsNumber } from './EditAdvancedSpace.tsx';


export default class Environmental extends Component<IEnvironmental> 
{
  private get labels() { return appContext().labels; }
 

  render(): JSX.Element
  {
    const { tempRange, zoneId, onZoneIdChange,onTempRangeChange, soundRange,onSoundRangeChange, isCreate,tempRanges,soundRanges} = this.props;

    return (
        <Box mt={2} className="tablePanel" sx={{'& > :not(style)': { my: 1}}}>
        <Box mt={2} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'} sx={{'& > :not(style)': { mt: 1},}}>
                <Box className="icon-bg-dark" mr={1} p={1.5}>
                    <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                        {Icons.DeviceIcon}
                    </IbssSvgIcon>
                </Box>
                <Typography variant="h6" sx={{fontWeight:'regular'}}>{this.labels.HubLabelEnvironmental}</Typography>
            </Box>
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            {isCreate &&   
                <IbssTextField 
                    value={zoneId} 
                    fullWidth
                    label={this.labels.HubLabelEnvironmentalZone}
                    variant="outlined" 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onZoneIdChange(event)}
                />
            }
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            <IbssInputDropDown 
                options={soundRanges}
                id="Sound Range"
                value={soundRange != null ? soundRange.toString() : ''}
                inputLabel={this.labels.HubLabelSoundRange}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onSoundRangeChange(event)}
            />
            <IbssInputDropDown 
                options={tempRanges}
                id="Temp Range"
                value={tempRange != null ? tempRange.toString() : ''}
                inputLabel={this.labels.HubLabelTemperatureRange}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onTempRangeChange(event)}
            />
        </Box>
    </Box>
    )
  }
}

interface IEnvironmental 
{
    isCreate: boolean;
    tempRange: number | null;
    onTempRangeChange:(event: SelectChangeEvent<string>) => void; 
    soundRange: number | null;
    onSoundRangeChange: (event: SelectChangeEvent<string>) => void; 
    zoneId: number;
    onZoneIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tempRanges: IFilterOptionsNumber[],
    soundRanges: IFilterOptionsNumber[],
}
