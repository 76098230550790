import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(cateringMenu: ICateringMenu): Promise<void>
    {
        try
        {
            const payload =
            {
                ...cateringMenu,
                Description: cateringMenu.Short_Description,
                Available_From: cateringMenu.Available_From.toUTC().toISO(),
                Available_Until: cateringMenu.Available_Until.toUTC().toISO(),
            }
            const response = await axios.put(`${apis.allApiv2}${cateringMenu.Node_Id}/catering-menu/${payload.Menu_Id}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(cateringMenu: ICateringMenu): Promise<void>;
}

export interface ICateringMenu
{
    Node_Id: number;
    Menu_Id: string;
    Name: string;
    Short_Description: string;
    Available_From: DateTime;
    Available_Until: DateTime;
    ImageURI: string;
    Status: string;
    ConcurrencyStamp: string;
}
