import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import JoditEditor from "jodit-react";
import { IJodit } from "jodit/types";
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo"
import { appContext } from "../../../../AppContext";

// enableRipple(true);

let config: Partial<IJodit['options']> = {
    // HTML Editor
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: false,
    spellcheck: true,
    // @ts-ignore
    editorCssClass: true,
    triggerChangeEvent: true,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    // @ts-ignore
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: "p",
    defaultMode: 1,
    useSplitMode: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
}

class EditNotification extends Component
{

    private get labels() { return appContext().labels }
    private local = appContext().localStorageProvider;
    [x: string]: any;
    state = {
        filter: "",
        loading: false,
        activePage: 0,
        updates: false,
        nodeId: null,
        title: null,
        id: 0,
        name: null,
        eventType: null,
        shouldSendMail: false,
        shouldSendMobilePush: false,
        to: null,
        cc: null,
        bcc: null,
        replyTo: null,
        subject: null,
        isHTML: false,
        message: '',
        mobilePushTo: null,
        mobilePushTitle: null,
        mobilePushMessage: null,
        notificationArea: 0,
        mobilePushAction: null,
        mobilePushBadge: null,
        mobilePushActionUri: null,
        show: false,
        isDelete: false,
        showAlertModal: false,
        alertMessageText: null,
        isUpdateRight: false,
        isDeleteRight: false,
        alertTitle: null,
        editor: null,
        content: null,
        ruleExpression: null,
        disabled: false,
        attachmentType: 0,
        officeExportId: null,
        shouldSendTwilio: false,
        twilioTo: null,
        twilioMessage: null,
        concurrencyTimestamp: null,
    };
    jodit: any;

    componentDidMount()
    {
        const { match }: any = this.props;

        if (match.params.notificationid !== "0")
        {
            this.setState({
                id: parseInt(match.params.notificationid)
            })
            this.getDetails(match.params.notificationid)
            this.getRightList();
        } else
        {
            this.setState({
                title: "New Notification Setup",
                isUpdateRight: true
            })
        }
    }

    getRightList = () =>
    {
        const res = this.local.getIbssRightList();

        if (res.ADMINPORTAL && res.ADMINPORTAL.Notifications && res.ADMINPORTAL.Notifications.indexOf("Update") > -1)
        {
            this.setState({
                isUpdateRight: true
            })
        }
        if (res.ADMINPORTAL && res.ADMINPORTAL.Notifications && res.ADMINPORTAL.Notifications.indexOf("Delete") > -1)
        {
            this.setState({
                isDeleteRight: true
            })
        }
    }

    getDetails = (id: any) =>
    {

        apis.getNotificationById(id).then((res) =>
        {
            let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
            let validHTML = isHTML(res.data.message);
            let htmlMessage
            if (res.data.isHTML)
            {
                // config.defaultMode = 1
                if (validHTML && res.data.message.includes('<style>') !== true)
                {
                    htmlMessage = res.data.message;
                } else
                {

                    let parser = new DOMParser();
                    htmlMessage = parser.parseFromString(res.data.message, 'text/html');
                    // console.log(htmlMessage.documentElement);
                    // htmlMessage = htmlMessage.documentElement.toString();
                    htmlMessage = htmlMessage.body.innerText;
                }
            } else
            {
                // config.defaultMode = 2
                htmlMessage = res.data.message;
            }

            this.setState({
                ruleExpression: res.data.ruleExpression,
                to: res.data.to,
                cc: res.data.cc,
                bcc: res.data.bcc,
                replyTo: res.data.replyTo,
                subject: res.data.subject,
                message: htmlMessage,
                name: res.data.name,
                disabled: res.data.disabled,
                isHTML: res.data.isHTML,
                attachmentType: res.data.attachmentType,
                officeExportId: res.data.officeExportId,
                notificationArea: res.data.notificationArea,
                shouldSendMail: res.data.shouldSendMail,
                shouldSendMobilePush: res.data.shouldSendMobilePush,
                mobilePushTo: res.data.mobilePushTo,
                shouldSendTwilio: res.data.shouldSendTwilio,
                twilioTo: res.data.twilioTo,
                mobilePushMessage: res.data.mobilePushMessage,
                mobilePushTitle: res.data.mobilePushTitle,
                mobilePushAction: res.data.mobilePushAction,
                mobilePushBadge: res.data.mobilePushBadge,
                twilioMessage: res.data.twilioMessage,
                mobilePushActionUri: res.data.mobilePushActionUri,
                eventType: res.data.eventTypeName,
                concurrencyTimestamp: res.data.concurrencyTimestamp
            })
        }).catch((error: any) => { });

    };
    submit = () =>
    {
        const { match }: any = this.props;
        const { id, ruleExpression, disabled, attachmentType, officeExportId, shouldSendTwilio, twilioTo, twilioMessage,
            name, eventType, to, cc, bcc, replyTo, subject, isHTML, mobilePushTo, mobilePushTitle, message, concurrencyTimestamp,
            mobilePushMessage, notificationArea, mobilePushAction, mobilePushBadge, mobilePushActionUri, shouldSendMail, shouldSendMobilePush }: any = this.state;

        let payload = {};

        if (match.params.notificationid === "0")
        {
            payload = {
                "ruleExpression": ruleExpression,
                "name": name,
                "disabled": disabled,
                "eventTypeName": eventType,
                "shouldSendMail": shouldSendMail,
                "to": to,
                "cc": cc,
                "bcc": bcc,
                "replyTo": replyTo,
                "subject": subject,
                "message": message,
                "isHTML": isHTML,
                "attachmentType": attachmentType,
                "officeExportId": officeExportId,
                "shouldSendMobilePush": shouldSendMobilePush,
                "mobilePushTo": mobilePushTo,
                "mobilePushMessage": mobilePushMessage,
                "mobilePushTitle": mobilePushTitle,
                "notificationArea": notificationArea,
                "mobilePushAction": mobilePushAction,
                "mobilePushBadge": mobilePushBadge,
                "mobilePushActionUri": mobilePushActionUri,
                "shouldSendTwilio": shouldSendTwilio,
                "twilioTo": twilioTo,
                "twilioMessage": twilioMessage,
            }
            this.create(payload);
        } else
        {
            payload = {
                "ruleExpression": ruleExpression,
                "to": to,
                "cc": cc,
                "bcc": bcc,
                "replyTo": replyTo,
                "subject": subject,
                "message": message,
                "name": name,
                "disabled": disabled,
                "isHTML": isHTML,
                "attachmentType": attachmentType,
                "officeExportId": officeExportId,
                "notificationArea": notificationArea,
                "shouldSendMail": shouldSendMail,
                "shouldSendMobilePush": shouldSendMobilePush,
                "mobilePushTo": mobilePushTo,
                "shouldSendTwilio": shouldSendTwilio,
                "twilioTo": twilioTo,
                "mobilePushMessage": mobilePushMessage,
                "mobilePushTitle": mobilePushTitle,
                "mobilePushAction": mobilePushAction,
                "mobilePushBadge": mobilePushBadge,
                "twilioMessage": twilioMessage,
                "mobilePushActionUri": mobilePushActionUri,
                "eventTypeName": eventType,
                "concurrencyTimestamp": concurrencyTimestamp
            }
            this.update(id, payload);
        }
    };

    create = (payload: any) =>
    {
        apis.createNotification(payload).then((res) =>
        {
            const { history }: any = this.props;
            this.setState({
                updates: true
            }, () =>
            {
                history.push("/notifications");
                window.location.reload();
            })
        }).catch((error: any) => { });
    }

    update = (id: any, payload: any) =>
    {
        const { history }: any = this.props;
        const { updates }: any = this.state;
        if (updates)
        {
            apis.updateNotificationById(id, payload).then((res) =>
            {
                history.push("/notifications");
            }).catch((error: any) => { });
        } else
        {
            history.push("/notifications");
        }
    };

    change = (e: any) =>
    {
        this.setState({ updates: true })
        this.setState({ [e.target.name]: e.target.value });
    };

    changeHtml = (e: any) =>
    {
        // this.JoditEditor.g  
        // debugger
        // debugger
        if (e !== "")
        {

            this.setState({
                updates: true,
                message: e,
            })
        }
    }

    isDelete = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
        this.delete();
    }

    cancelDeleteModal = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
    }
    delete = () =>
    {
        const { history }: any = this.props;
        if (this.state.isDelete)
        {
            apis.deleteNotificationById(this.state.id).then((res) =>
            {
                history.push("/notifications");
            }).catch((error: any) => { });
        }
    };
    handleModal = () =>
    {
        this.setState({
            show: !this.state.show,
        })
    }

    cancel = () =>
    {
        const { updates } = this.state
        const { history }: any = this.props;
        if (updates)
        {
            this.handleModal();
        } else
        {
            history.push("/notifications");
        }
    }

    setChecked = (e: any) =>
    {
        this.setState({ updates: true })

        if (e.target.name === 'disabled')
        {
            this.setState({
                disabled: !this.state.disabled,
            })
        }
        if (e.target.name === 'isHTML')
        {
            this.setState({
                isHTML: !this.state.isHTML,
            }, () =>
            {
                // if (this.state.isHTML) {
                //   config.defaultMode = 1;
                // } else {
                //   config.defaultMode = 2;
                // }
            })
        }
        if (e.target.name === 'shouldSendMail')
        {
            this.setState({
                shouldSendMail: !this.state.shouldSendMail,
            })
        }
        if (e.target.name === 'shouldSendMobilePush')
        {
            this.setState({
                shouldSendMobilePush: !this.state.shouldSendMobilePush,
            })
        }
    };

    cancelSendMailModal = () =>
    {
        this.setState({
            to: "",
            cc: "",
            bcc: "",
            replyTo: "",
            subject: "",
            isHTML: false,
            message: "",
        })
    }

    submitSendMailModal = () =>
    {

    }

    cancelMobilePushModal = () =>
    {
        this.setState({
            mobilePushTo: "",
            mobilePushTitle: "",
            mobilePushMessage: "",
            notificationArea: "",
            mobilePushAction: "",
            mobilePushBadge: "",
            mobilePushActionUri: "",
        })
    }

    submitMobilePushModal = () =>
    {

    }

    getEditorVal = (e: any) =>
    {

    }

    render()
    {
        const { name, eventType, to, cc, bcc, replyTo, subject, message, isHTML, mobilePushTo, mobilePushTitle,
            mobilePushMessage, notificationArea, mobilePushAction, mobilePushBadge, mobilePushActionUri, shouldSendMail,
            shouldSendMobilePush, ruleExpression, disabled }: any = this.state;
        const { history }: any = this.props;
        const nameFld = {
            name: "name",
            label: this.labels.HubLabelName,
            value: name,
            mandatory: true,
            placeholder: "Provide Name",
            onchange: this.change,
        }

        const ruleExpressionFld = {
            name: "ruleExpression",
            label: this.labels.HubLabelRule,
            value: ruleExpression,
            mandatory: true,
            placeholder: "Provide Rule",
            onchange: this.change,
        }

        const eventTypeFld = {
            name: "eventType",
            label: this.labels.HubLabelEventType,
            value: eventType,
            mandatory: true,
            placeholder: this.labels.HubLabelEventTypePlaceholder,
            onchange: this.change,
        }

        const toFld = {
            name: "to",
            label: this.labels.HubLabelTo,
            value: to,
            mandatory: true,
            placeholder: this.labels.HubLabelToPlaceholder,
            onchange: this.change,
        }

        const ccFld = {
            name: "cc",
            label: this.labels.HubLabelcc,
            value: cc,
            placeholder: this.labels.HubLabelccPlaceholder,
            onchange: this.change,
        }

        const bccFld = {
            name: "bcc",
            label: this.labels.HubLabelbccLabel,
            value: bcc,
            placeholder: this.labels.HubLabelbccPlaceholder,
            onchange: this.change,
        }

        const replyToFld = {
            name: "replyTo",
            label: this.labels.HubLabelReplyTo,
            value: replyTo,
            placeholder: this.labels.HubLabelReplyToPlaceholder,
            onchange: this.change,
        }

        const subjectFld = {
            name: "subject",
            label: this.labels.HubLabelSubject,
            value: subject,
            mandatory: true,
            placeholder: this.labels.HubLabelSubjectPlaceholder,
            onchange: this.change,
        }

        const isHTMLFld = {
            name: "isHTML",
            label: this.labels.HubLabelhtmlMessageLabel,
            value: isHTML,
            placeholder: this.labels.HubLabelhtmlMessagePlaceholder,
            onchange: this.change,
        }

        const mobilePushToFld = {
            name: "mobilePushTo",
            label: this.labels.HubLabelTo,
            value: mobilePushTo,
            mandatory: true,
            placeholder: this.labels.HubLabelToPlaceholder,
            onchange: this.change,
        }

        const mobilePushTitleFld = {
            name: "mobilePushTitle",
            label: this.labels.HubLabelTitle,
            value: mobilePushTitle,
            mandatory: true,
            placeholder: this.labels.HubLabelTitlePlaceholder,
            onchange: this.change,
        }

        const mobilePushMessageFld = {
            name: "mobilePushMessage",
            label: this.labels.HubLabelMessage,
            value: mobilePushMessage,
            mandatory: true,
            placeholder: this.labels.HubLabelMessagePlaceholder,
            onchange: this.change,
        }

        const notificationAreaFld = {
            name: "notificationArea",
            label: this.labels.HubLabelarea,
            value: notificationArea,
            mandatory: true,
            placeholder: this.labels.HubLabelareaPlaceholder,
            onchange: this.change,
        }

        const mobilePushActionFld = {
            name: "mobilePushAction",
            label: this.labels.HubLabelAction,
            value: mobilePushAction,
            mandatory: true,
            placeholder: this.labels.HubLabelActionPlaceholder,
            onchange: this.change,
        }

        const mobilePushBadgeFld = {
            name: "mobilePushBadge",
            label: this.labels.HubLabelBadge,
            value: mobilePushBadge,
            mandatory: true,
            placeholder: this.labels.HubLabelBadgePlaceholder,
            onchange: this.change,
        }

        const mobilePushActionUriFld = {
            name: "mobilePushActionUri",
            label: this.labels.HubLabelActionURI,
            value: mobilePushActionUri,
            mandatory: true,
            placeholder: this.labels.HubLabelActionURIPlaceholder,
            onchange: this.change,
        }

        const props = {
            ...this.props,
            submenu: [
                {
                    title: this.labels.HubTabProperties,
                    tabNo: 0,
                    active: this.state.activePage === 0 ? true : false,
                },
                // {
                //   title: this.labels.HubLabelDetailslabel,
                //   tabNo: 1,
                //   active: this.state.activePage === 1 ? true : false,
                // },
            ],
            activePageValue: this.state.activePage,
            childValue: (i: any) =>
            {
                this.setState({ activePage: i });
            },
        };

        const confModalData = this.state.isDelete ? {
            show: this.state.show,
            handleModal: this.cancelDeleteModal,
            okButton: this.delete,
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }
            : {
                show: this.state.show,
                handleModal: this.handleModal,
                okButton: () => { history.push("/notifications"); },
                modalHeading: this.labels.HubLabelConfirmation,
                modalMessage: this.labels.HubLabelConfirmCancelMessage,
            }
        const alertModalData = {
            show: this.state.showAlertModal,
            modalMessage: this.state.alertMessageText,
            modalTitle: this.state.alertTitle,
        }
        return (
            <div>
                <Submenu {...props} />
                <div className="page-height-exct-submenu">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                    {this.state.show ? (
                        <ConfirmModal {...confModalData} />
                    ) : ""}
                    <div className="form-panel">
                        {this.state.title &&
                            <FormPanelInfo
                                formHeading={this.state.title}
                                iconSrc="/images/Notifications.svg"
                            />}
                        <div className="main-content">
                            {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}

                            <div className="height-control">
                                {this.state.loading || (
                                    <div className="card card-scroller" style={{ background: 'none' }}>
                                        {this.state.activePage === 0 && (

                                            <form>
                                                {(this.state.shouldSendMail === false && this.state.shouldSendMobilePush === false) ?
                                                    <div className="card p-45">
                                                        <div className="row" >
                                                            <div className="col-6 pr-2 input-controls"><Inputbox {...nameFld} /></div>
                                                            <div className="col-6 input-controls"><Inputbox {...eventTypeFld} /></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12"><Inputbox {...ruleExpressionFld} /></div>
                                                        </div>

                                                        <div className="row" >
                                                            <div className="col-3 input-controls">
                                                                <label className="check-label">{this.labels.HubLabelDisabled}</label>
                                                                <input name="disabled" type="checkbox" className="input-check-box-label border"
                                                                    onChange={this.setChecked} />
                                                            </div>

                                                            <div className="col-3 input-controls">
                                                                <label className="check-label">{this.labels.HubLabelSendEmail}</label>
                                                                <input name="shouldSendMail" type="checkbox" className="input-check-box-label border"
                                                                    onChange={this.setChecked} />
                                                            </div>


                                                            <div className="col-3 input-controls">
                                                                <label className="check-label">{this.labels.HubLabelSendMobilePushMessage}</label>
                                                                <input name="shouldSendMobilePush" type="checkbox" className="input-check-box-label border"
                                                                    onChange={this.setChecked} />
                                                            </div>


                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="p-45 cardHeight">
                                                        <div className="row" >
                                                            <div className="col-6 pr-2 input-controls"><Inputbox {...nameFld} /></div>
                                                            <div className="col-6 input-controls"><Inputbox {...eventTypeFld} /></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12"><Inputbox {...ruleExpressionFld} /></div>
                                                        </div>

                                                        <div className="row" >
                                                            {disabled ?
                                                                <div className="col-3 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelDisabled}</label>
                                                                    <input name="disabled" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={true} />
                                                                </div>
                                                                :

                                                                <div className="col-3 pr-2 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelDisabled}</label>
                                                                    <input name="disabled" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={false} />
                                                                </div>
                                                            }

                                                            {shouldSendMail ?
                                                                <div className="col-3 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelSendEmail}</label>
                                                                    <input name="shouldSendMail" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={true} />
                                                                </div>
                                                                :

                                                                <div className="col-3 pr-2 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelSendEmail}</label>
                                                                    <input name="shouldSendMail" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={false} />
                                                                </div>
                                                            }

                                                            {shouldSendMobilePush ?
                                                                <div className="col-3 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelSendMobilePushMessage}</label>
                                                                    <input name="shouldSendMobilePush" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={true} />
                                                                </div>
                                                                :
                                                                <div className="col-3 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelSendMobilePushMessage}</label>
                                                                    <input name="shouldSendMobilePush" type="checkbox" className="input-check-box-label border"
                                                                        onChange={this.setChecked} defaultChecked={false} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.shouldSendMail && (
                                                    <div className="p-45 cardTopSpace">
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...toFld} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...ccFld} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...bccFld} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...replyToFld} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...subjectFld} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <label className="check-label">{this.labels.HubLabelhtmlMessageLabel}</label>
                                                                <input name="isHTML" type="checkbox" className="input-check-box-label border" defaultChecked={this.state.isHTML} onChange={this.setChecked} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <label className="check-label">{this.labels.HubLabelMessage}</label>
                                                                {this.state.isHTML === true ?
                                                                    <JoditEditor ref={this.state.editor} value={this.state.message} onChange={this.changeHtml} config={config as any} />
                                                                    :
                                                                    <textarea
                                                                        name="message"
                                                                        className="textAreaUserDetails"
                                                                        value={this.state.message}
                                                                        onChange={this.change}
                                                                    />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {this.state.shouldSendMobilePush && (
                                                    <div className="p-45 cardTopSpace">
                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushToFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushTitleFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushMessageFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...notificationAreaFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushActionFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushBadgeFld} />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 input-controls">
                                                                <Inputbox {...mobilePushActionUriFld} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </form>
                                        )}
                                        {/* {this.state.activePage === 1 && (
                        <div className="card p-45">
                          <form>
                            <div className="row" style={{ display: "block" }}>
                            <div className="col-12 input-controls">
                              <div className="label"><label>{this.labels.HubTabDeviceConfiguration}</label></div>
                              <textarea
                                name="device_Config"
                                className="textAreaUserDetails"
                                onChange={this.change}
                              // value={device_Config}
                              />
                            </div>
                            </div>
                          </form>
                        </div>
                      )} */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPanel-main-button-group">
                    <div>
                        <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                        {(this.state.id !== 0 && this.state.isDeleteRight === false) && (
                            <button className="button-tertiary-disable mr-2" disabled={true} >{this.labels.HubButtonDelete}</button>
                        )}
                        {(this.state.id !== 0 && this.state.isDeleteRight === true) && (
                            <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                        )}
                    </div>
                    <div>
                        {this.state.isUpdateRight === false &&
                            <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                        }
                        {this.state.isUpdateRight === true &&
                            <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                        }
                    </div>
                </div>
                </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProvidersDetails,
        mainPageTitle: state.mainPageTitle,
    };
};
export default connect(mapStateToProps)(EditNotification);