import { Component } from 'react';

export default class UpAngleIcon extends Component {
    render() {
        return (
            <svg>
                <path d="M1 6L5.5 1L10 6" stroke="#2A3F49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        )
    }
};