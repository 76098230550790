import { Component } from 'react';

export default class ZoneIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path id="Path_78" data-name="Path 78" d="M.242,16.5a3.64,3.64,0,0,1,3.64-3.64H6.794a.728.728,0,1,1,0,1.456H3.882A2.184,2.184,0,0,0,1.7,16.5v2.912a.728.728,0,1,1-1.456,0Z" fill-rule="evenodd" />
                <path id="Path_79" data-name="Path 79" d="M12.86,3.882A3.64,3.64,0,0,1,16.5.243h2.912a.728.728,0,1,1,0,1.456H16.5a2.184,2.184,0,0,0-2.184,2.184V6.794a.728.728,0,1,1-1.456,0Z" fill-rule="evenodd" />
                <path id="Path_80" data-name="Path 80" d="M20.14,29.118a3.64,3.64,0,0,1-3.64,3.64H13.588a.728.728,0,1,1,0-1.456H16.5a2.184,2.184,0,0,0,2.184-2.184V26.206a.728.728,0,1,1,1.456,0Z" fill-rule="evenodd" />
                <path id="Path_81" data-name="Path 81" d="M32.758,16.5a3.64,3.64,0,0,1-3.64,3.64H26.206a.728.728,0,1,1,0-1.456h2.912A2.184,2.184,0,0,0,31.3,16.5V13.588a.728.728,0,1,1,1.456,0Z" fill-rule="evenodd" />
                <path id="Path_82" data-name="Path 82" d="M16.5,12.86a3.64,3.64,0,0,1,3.64,3.64v2.912a.728.728,0,1,1-1.456,0V16.5A2.184,2.184,0,0,0,16.5,14.316H13.588a.728.728,0,1,1,0-1.456Z" fill-rule="evenodd" />
                <path id="Path_83" data-name="Path 83" d="M29.118.243a3.64,3.64,0,0,1,3.64,3.64V6.794a.728.728,0,0,1-1.456,0V3.882A2.184,2.184,0,0,0,29.118,1.7H26.206a.728.728,0,0,1,0-1.456Z" fill-rule="evenodd" />
                <path id="Path_84" data-name="Path 84" d="M3.882,32.757a3.64,3.64,0,0,1-3.64-3.64V26.206a.728.728,0,1,1,1.456,0v2.912A2.184,2.184,0,0,0,3.882,31.3H6.794a.728.728,0,0,1,0,1.456Z" fill-rule="evenodd" />
                <path id="Path_85" data-name="Path 85" d="M.97,21.11a.728.728,0,0,1,.728.728v1.941a.728.728,0,0,1-1.456,0V21.838A.728.728,0,0,1,.97,21.11Z" fill-rule="evenodd" />
                <path id="Path_86" data-name="Path 86" d="M13.588,8.493a.728.728,0,0,1,.728.728v1.941a.728.728,0,0,1-1.456,0V9.221A.728.728,0,0,1,13.588,8.493Z" fill-rule="evenodd" />
                <path id="Path_87" data-name="Path 87" d="M19.412,21.11a.728.728,0,0,1,.728.728v1.941a.728.728,0,0,1-1.456,0V21.838A.728.728,0,0,1,19.412,21.11Z" fill-rule="evenodd" />
                <path id="Path_88" data-name="Path 88" d="M32.03,8.493a.728.728,0,0,1,.728.728v1.941a.728.728,0,1,1-1.456,0V9.221A.728.728,0,0,1,32.03,8.493Z" fill-rule="evenodd" />
                <path id="Path_89" data-name="Path 89" d="M11.89,13.588a.728.728,0,0,1-.728.728H9.221a.728.728,0,1,1,0-1.456h1.941A.728.728,0,0,1,11.89,13.588Z" fill-rule="evenodd" />
                <path id="Path_90" data-name="Path 90" d="M24.508.971a.728.728,0,0,1-.728.728H21.839a.728.728,0,0,1,0-1.456H23.78A.728.728,0,0,1,24.508.971Z" fill-rule="evenodd" />
                <path id="Path_91" data-name="Path 91" d="M11.89,32.029a.728.728,0,0,1-.728.728H9.221a.728.728,0,1,1,0-1.456h1.941A.728.728,0,0,1,11.89,32.029Z" fill-rule="evenodd" />
                <path id="Path_92" data-name="Path 92" d="M24.508,19.412a.728.728,0,0,1-.728.728H21.839a.728.728,0,0,1,0-1.456H23.78A.728.728,0,0,1,24.508,19.412Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
