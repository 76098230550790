import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetByZoneIdsEndpoint implements IGetByZoneIdsEndpoint
{
    public async execute(nodeId: number, zoneIds: string[]): Promise<IEnvironmentalZoneData[]>
    {
        try
        {
            if (zoneIds.length == 0)
            {
                return new Array<IEnvironmentalZoneData>();
            }

            const zoneIdsQuery = zoneIds.map(i => `envZoneIds=${i}`).join("&");
            const response = await axios.get<IEnvironmentalZoneData[]>(`${apis.allApi}${nodeId}/EnvironmentalZoneData/ZonesData?${zoneIdsQuery}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByZoneIdsEndpoint
{
    execute(nodeId: number, zoneIds: string[]): Promise<IEnvironmentalZoneData[]>;
}

export interface IEnvironmentalZoneData
{
    Node_Id: number;

    Env_Zone_Id: number;
    Env_Zone_Name: string;
    Env_Zone_Status: number;
    Env_Zone_LastUpdated: string;

    Env_Temperature: number;
    Env_Temperature_Status: number;
    Env_Temperature_Range: number;

    Env_Humidity: number;
    Env_Humidity_Status: number;
    Env_Humidity_Range: number;

    Env_Sound_Level: number;
    Env_Sound_Level_Status: number;
    Env_Sound_Level_Range: number;

    Env_Light_Level: number;
    Env_Light_Level_Status: number;
    Env_Light_Level_Range: number;

    Env_Battery_Level: number;
    Env_Battery_Level_Status: number;

    Env_CO: number;
    Env_CO_Status: number;

    Env_CO2: number;
    Env_CO2_Status: number;

    Env_PM25: number;
    Env_PM25_Status: number;

    Env_PM10: number;
    Env_PM10_Status: number;

    Env_TVOC: number;
    Env_TVOC_Status: number;

    IAQ_Status: number;
}
