import { IbssComponent } from "../../Core/BaseComponent/IbssComponent"

export default class EditIcon extends IbssComponent<IProps, {}>
{
    public render(): JSX.Element
    {
        const { className } = this.props;

        return (
            <svg className={className} width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <path d="M 0.026 14.276 L 0.026 18.026 L 3.776 18.026 L 14.836 6.966 L 11.086 3.216 L 0.026 14.276 Z M 17.736 4.066 C 18.126 3.676 18.126 3.046 17.736 2.656 L 15.396 0.316 C 15.006 -0.074 14.376 -0.074 13.986 0.316 L 12.156 2.146 L 15.906 5.896 L 17.736 4.066 Z"></path>
            </svg>
        );
    }
}

export interface IProps
{
    className?: string;
}
