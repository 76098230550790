import { DateTime } from "luxon";
import { IDeletedUser, IGetDeletedEndpoint } from "./GetDeletedEndpoint";
import { IGetUsersEndpoint } from "./GetUsersEndpoint";

export class UsersRepository implements IUsersRepository
{
    private getUsersEndpoint: IGetUsersEndpoint;
    private getDeletedUsersEndpoint: IGetDeletedEndpoint;

    constructor(
        getUsersEndpoint: IGetUsersEndpoint,
        getDeletedUsersEndpoint: IGetDeletedEndpoint,
    )
    {
        this.getUsersEndpoint = getUsersEndpoint;
        this.getDeletedUsersEndpoint = getDeletedUsersEndpoint;
    }

    public getUsers(name: string): Promise<IUser[]>
    {
        return this.getUsersEndpoint.execute(name);
    }

    public getDeletedUsers(filter?: Filter): Promise<IDeletedUser[]>
    {
        return this.getDeletedUsersEndpoint.execute(filter);
    }
}
export interface IUsersRepository 
{
    getUsers(name: string): Promise<IUser[]>;
    getDeletedUsers(filter?: Filter): Promise<IDeletedUser[]>;
}

export class Filter implements IFilter
{
    public deletedAfter?: DateTime;
    public deletedBefore?: DateTime;

    constructor(value: Partial<Filter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.deletedAfter ? `DeletedAt ge ${this.deletedAfter.toUTC().toISO()}` : "");
        filterParts.push(this.deletedBefore ? `DeletedAt lt ${this.deletedBefore.toUTC().toISO()}` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }
}

export interface IFilter
{
    deletedAfter?: DateTime;
    deletedBefore?: DateTime;
}

export interface IUser
{
    displayName: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    pin: string,
    UserName: string,
    OID: string,
}
