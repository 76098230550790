import { ODataSelect } from "../../../Providers.Api/ODataQuery";

export class ITag extends ODataSelect
{
    public Tag_Id = "";
    public TagType = "";
    public Values = "";
    public Icon = "";
    public Description = "";
    public Status = "";
    public Node_Id = 0;
    public Name = "";
    public ConcurrencyStamp = "";
    public OwnedByUserId = "";
    public OwnedByRoleId = "";
    public CreatedByUserId = "";
    public ModifiedByUserId = "";
    public TenancyId = "";

    public init(response: any, nodeId: number): ITag
    {
        super.init(response, nodeId);
        return this;
    }
}

export class ITagToEventType extends ODataSelect
{
    public Node_Id = "";
    public Tag2EventType_Id = "";
    public Tag_Id = "";
    public TagType = "";
    public Name = "";
    public Value = "";
    public Icon = "";
    public Record_Id = "";
    public EventType_Id = 0;

    public init(response: any, nodeId: number): ITagToEventType
    {
        super.init(response, nodeId);
        return this;
    }
}

export interface IDeleteTagToEventType
{
    Message: string;
    Details: string[];
}