import { Component } from 'react';

export default class Reception extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 18C0.25 17.5858 0.585786 17.25 1 17.25H33C33.4142 17.25 33.75 17.5858 33.75 18V33C33.75 33.4142 33.4142 33.75 33 33.75C32.5858 33.75 32.25 33.4142 32.25 33V18.75H1.75V33C1.75 33.4142 1.41421 33.75 1 33.75C0.585786 33.75 0.25 33.4142 0.25 33V18Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.15858 12.7268C3.75475 11.8597 4.65768 11.215 5.75 11.215H11.25C12.3423 11.215 13.2452 11.8597 13.8414 12.7268C14.44 13.5975 14.785 14.7571 14.785 16V18C14.785 18.4335 14.4335 18.785 14 18.785C13.5665 18.785 13.215 18.4335 13.215 18V16C13.215 15.0337 12.9444 14.1934 12.5477 13.6163C12.1485 13.0357 11.6765 12.785 11.25 12.785H5.75C5.32353 12.785 4.85146 13.0357 4.45233 13.6163C4.05558 14.1934 3.785 15.0337 3.785 16V18C3.785 18.4335 3.43354 18.785 3 18.785C2.56645 18.785 2.215 18.4335 2.215 18V16C2.215 14.7571 2.56002 13.5975 3.15858 12.7268Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.215 5.5C4.215 3.13346 6.13346 1.215 8.5 1.215C10.8665 1.215 12.785 3.13346 12.785 5.5C12.785 7.86654 10.8665 9.785 8.5 9.785C6.13346 9.785 4.215 7.86654 4.215 5.5ZM8.5 2.785C7.00054 2.785 5.785 4.00054 5.785 5.5C5.785 6.99945 7.00054 8.215 8.5 8.215C9.99945 8.215 11.215 6.99945 11.215 5.5C11.215 4.00054 9.99945 2.785 8.5 2.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 1.75C22.5482 1.75 19.75 4.54822 19.75 8C19.75 11.4518 22.5482 14.25 26 14.25C29.4518 14.25 32.25 11.4518 32.25 8C32.25 4.54822 29.4518 1.75 26 1.75ZM18.25 8C18.25 3.71979 21.7198 0.25 26 0.25C30.2802 0.25 33.75 3.71979 33.75 8C33.75 12.2802 30.2802 15.75 26 15.75C21.7198 15.75 18.25 12.2802 18.25 8Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 7.25C26.4142 7.25 26.75 7.58579 26.75 8L26.75 12C26.75 12.4142 26.4142 12.75 26 12.75C25.5858 12.75 25.25 12.4142 25.25 12L25.25 8C25.25 7.58579 25.5858 7.25 26 7.25Z" />
                <path d="M27 5C27 5.55228 26.5523 6 26 6C25.4477 6 25 5.55228 25 5C25 4.44772 25.4477 4 26 4C26.5523 4 27 4.44772 27 5Z" />
            </svg>
        )
    }
};