import { Card, Grid, Typography } from '@mui/material';
import RoamerAndFlexAnnouncementForm from './RoamerAndFlexAnnouncementForm';
import { DateTime } from 'luxon';
import "./EditNotification.scss";
import { appContext } from '../../../../AppContext';
import { IFile } from '../../../../Providers.Api/Files/FilesRepository';
import Helper from '../../../../Common/Helper';
import InspireAnnouncementForm from './InspireAnnouncementForm';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { RouteComponentProps } from 'react-router';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { ICreateNotification } from '../../../../Providers.Api/Notifications/CreateNotificationEndpoint';
import { Box } from '@mui/system';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { DateHelper } from '../../../../Common/DateHelper';

class EditNotification extends IbssPage<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            notificationType: null,
            announcementId: 0,
            activeDateTime: DateTime.now().set({ minute: 0 }).plus({ hours: 1 }),
            expireDateTime: DateTime.now().set({ minute: 0 }).plus({ hours: 1, days: 1 }),
            customLabelSwitchValue: false,
            titleValue: '',
            shortDescription: '',
            announcementMessage: '',
            images: [],
            headerImageUrl: '',
            customLabel: '',
            highPrioritySwitchValue: false,
            loading: false,
            showFieldValidationError: false,
            titleError: false,
            dateTimeFieldError: false,
            customLabelError: false
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcBuildingAnnouncement_S;
        const buildingId = parseInt(this.props.match.params.buildingid);

        if (this.props.match.params.notificationid)
        {
            const announcementData = await this.apiClient.notifications.getById(parseInt(this.props.match.params.buildingid), this.props.match.params.notificationid)
            this.setState({
                notificationType: announcementData.Subclassification == 'INSPIRE' ? 'inspire' : 'roamerAndFlex',
                announcementId: announcementData.Notification_Id,
                activeDateTime: DateHelper.fromIsoByNode(announcementData.Available_From, buildingId),
                expireDateTime: DateHelper.fromIsoByNode(announcementData.Expiry_Date, buildingId),
                customLabelSwitchValue: announcementData.Subclassification != 'info' && announcementData.Subclassification != 'INSPIRE',
                titleValue: announcementData.Notification_Title,
                shortDescription: announcementData.Notification_Short_Message,
                announcementMessage: announcementData.Notification_Message,
                headerImageUrl: announcementData.ImageURI,
                customLabel: announcementData.Subclassification != 'info' && announcementData.Subclassification != 'INSPIRE' ? announcementData.Subclassification : '',
                highPrioritySwitchValue: announcementData.Notification_Priority == 1,
            })
        }
        this.getImages();
    }

    private activeDateTimeChanged(value: DateTime): void
    {
        if(value < this.state.expireDateTime)
        {
            this.setState({ dateTimeFieldError: false });
        }
        this.setState({ activeDateTime: value })
    }

    private expireDateTimeChanged(value: DateTime): void
    {
        if(value > this.state.activeDateTime)
        {
            this.setState({ dateTimeFieldError: false });
        }
        this.setState({ expireDateTime: value })
    }

    private customLabelSwitchChanged(value: boolean): void
    {
        this.setState({ customLabelSwitchValue: value })
    }

    private titleChanged(value: string): void
    {
        if(value != '')
        {
            this.setState({ titleError: false });
        }
        this.setState({ titleValue: value })
    }

    private shortDescriptionChanged(value: string): void
    {
        this.setState({ shortDescription: value })
    }

    private announcementMessageChanged(value: string): void
    {
        this.setState({ announcementMessage: value })
    }

    private headerImageUrlChanged(value: string): void
    {
        this.setState({ headerImageUrl: value })
    }

    private customLabelChanged(value: string): void
    {
        if(value != '')
        {
            this.setState({ customLabelError: false });
        }
        this.setState({ customLabel: value })
    }

    private highPrioritySwitchChanged(value: boolean): void
    {
        this.setState({ highPrioritySwitchValue: value })
    }

    private async getImages(): Promise<void>
    {
        const responseData = await this.apiClient.files.getFiles('c/Notifications');
        const newResponseData = responseData.map((item: IFile) =>
        {
            let newItem = { ...item }
            newItem.path = `https://storage.ibss.${Helper.environment}/images/c/Notifications/${item.name}`
            return newItem
        });

        this.setState({ images: newResponseData });
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        await this.apiClient.files.uploadFile(file, 'c/Notifications/' + filename, processAsMap);
        this.getImages();
    }

    private getPayload(draft: boolean): ICreateNotification
    {
        const currentUser = this.localStorage.getUserDetails();
        const buildingId = parseInt(this.props.match.params.buildingid);
        let payload = {
            ImageURI: '',
            Notification_Title: '',
            Notification_Priority: 0,
            Classification: '',
            SubClassfication: '',
            Notification_Message: '',
            Notification_Short_Message: '',
            Available_From: '',
            Expiry_Date: '',
            Notification_Type: 1,
            Notification_Status: '',
            Notification_User_Email: currentUser.email
        }

        if (this.state.notificationType == 'roamerAndFlex')
        {
            payload = {
                ImageURI: this.state.headerImageUrl,
                Notification_Title: this.state.titleValue,
                Notification_Priority: this.state.highPrioritySwitchValue ? 1 : 2,
                Classification: 'Building',
                SubClassfication: this.state.customLabelSwitchValue ? this.state.customLabel : 'info',
                Notification_Message: this.state.announcementMessage,
                Notification_Short_Message: this.state.shortDescription,
                Available_From: this.state.activeDateTime.toUtcByNode(buildingId).toISO(),
                Expiry_Date: this.state.expireDateTime.toUtcByNode(buildingId).toISO(),
                Notification_Type: 1,
                Notification_Status: draft ? 'Inactive' : 'Active',
                Notification_User_Email: currentUser.email
            }
        }

        if (this.state.notificationType == 'inspire')
        {
            payload = {
                ImageURI: '',
                Notification_Title: this.state.titleValue,
                Notification_Priority: 2,
                Classification: 'Building',
                SubClassfication: 'INSPIRE',
                Notification_Message: this.state.shortDescription,
                Notification_Short_Message: this.state.shortDescription,
                Available_From: this.state.activeDateTime.toUtcByNode(buildingId).toISO(),
                Expiry_Date: this.state.expireDateTime.toUtcByNode(buildingId).toISO(),
                Notification_Type: 1,
                Notification_Status: draft ? 'Inactive' : 'Active',
                Notification_User_Email: currentUser.email
            }
        }

        return payload
    }

    private async createAnnouncement(draft: boolean): Promise<void>
    {
        this.setState({ loading: true });

        try
        {
            await this.apiClient.notifications.create(parseInt(this.props.match.params.buildingid), this.getPayload(draft));
            this.setState({ loading: false });
            this.props.history.push("/oneLens/" + this.props.match.params.buildingid + "/announcements/list")
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async editAnnouncement(draft: boolean): Promise<void>
    {
        this.setState({ loading: true });

        try
        {
            await this.apiClient.notifications.edit(parseInt(this.props.match.params.buildingid), this.state.announcementId, this.getPayload(draft));
            this.setState({ loading: false });
            this.props.history.push("/oneLens/" + this.props.match.params.buildingid + "/announcements/list")
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async publishAnnouncementClicked(draft: boolean): Promise<void>
    {
        this.setState({ showFieldValidationError: true });
        const formValid = this.validateForm();

        if (!formValid)
        {
            return;
        }
        if (this.state.announcementId == 0)
        {
            this.createAnnouncement(draft);
        }
        if (this.state.announcementId != 0)
        {
            this.editAnnouncement(draft);
        }
    }

    private validateForm(): boolean
    {
        let formValid = true;

        const titleValid = this.state.titleValue.length > 0;
        const timesValid = this.state.activeDateTime < this.state.expireDateTime;
        const customLabelValid = this.state.customLabelSwitchValue && this.state.notificationType == 'roamerAndFlex' ? this.state.customLabel.length > 0 : true;

        if (!timesValid)
        {
            formValid = false;
            this.setState({ dateTimeFieldError: true });
        }

        if (!titleValid)
        {
            formValid = false;
            this.setState({ titleError: true });
        }

        if (!customLabelValid)
        {
            formValid = false;
            this.setState({ customLabelError: true });
        }

        return formValid;
    }

    public render(): JSX.Element
    {
        return (
            <>
            <Box className="page-height-exct-header">
                {
                    this.state.loading &&
                    <LoadingOverlay />
                }
                <Box className="page-height-exct-header">
                <Typography variant='h5' className='table-panel-header' mt={2} ml={2}>{this.state.announcementId == 0 ? this.labels.funcAnnouncementsCreate_L : this.labels.funcAnnouncementsEdit_L}</Typography>
                <Card className='m-3'>
                    {
                        this.state.announcementId != 0 &&
                        <div className='m-3'> {this.labels.funcAnnouncementID_S}: {this.state.announcementId}</div>
                    }
                    <Box className='m-3'>
                        {
                            this.state.announcementId == 0 &&
                            <Box>
                                <Typography className='mb-3 mt-2 field-heading'>{this.labels.funcAnnouncementBroadcast_Message}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    <Box className={`announcement-type-buttons ${this.state.notificationType == 'roamerAndFlex' ? 'announcement-button-selected' : ''}`} onClick={() => this.setState({ notificationType: 'roamerAndFlex' })}>
                                        <Typography className='announcement-button-content field-heading'>{this.labels.funcAnnouncementRoamerAndFlex}</Typography>
                                        <Box className='d-flex announcement-button-logo-container'>
                                            <img className='mr-3' width={35} height={35} src={`/images/RoamerLogo.svg`} />
                                            <img width={35} height={35} src={`/images/FlexLogo.svg`} />
                                        </Box>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Box className={`announcement-type-buttons ${this.state.notificationType == 'inspire' ? 'announcement-button-selected' : ''}`} onClick={() => this.setState({ notificationType: 'inspire' })}>
                                        <Typography className='announcement-button-content field-heading'>{this.labels.HubMenuInspire}</Typography>
                                        <Box className='announcement-button-logo-container'><img width={35} height={35} src={`/images/InspireLogo.svg`} /></Box>
                                    </Box>
                                    </Grid>
                                    </Grid>
                                <hr />
                            </Box>
                        }
                        {
                            this.state.notificationType == 'roamerAndFlex' &&
                            <RoamerAndFlexAnnouncementForm
                                activeDateTime={this.state.activeDateTime}
                                onActiveDateTimeChanged={(value: DateTime) => this.activeDateTimeChanged(value)}
                                expireDateTime={this.state.expireDateTime}
                                onExpireDateTimeChanged={(value: DateTime) => this.expireDateTimeChanged(value)}
                                customLabelSwitchValue={this.state.customLabelSwitchValue}
                                onCustomLabelSwitchChanged={(value: boolean) => this.customLabelSwitchChanged(value)}
                                titleValue={this.state.titleValue}
                                onTitleChanged={(value: string) => this.titleChanged(value)}
                                shortDescription={this.state.shortDescription}
                                onShortDescriptionChanged={(value: string) => this.shortDescriptionChanged(value)}
                                announcementMessage={this.state.announcementMessage}
                                onAnnouncementMessageChanged={(value: string) => this.announcementMessageChanged(value)}
                                images={this.state.images}
                                uploadImage={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                headerImageUrl={this.state.headerImageUrl}
                                onHeaderImageUrlChanged={(url: string) => this.headerImageUrlChanged(url)}
                                customLabel={this.state.customLabel}
                                onCustomLabelChanged={(value: string) => this.customLabelChanged(value)}
                                onHighPrioritySwitchChanged={(value: boolean) => this.highPrioritySwitchChanged(value)}
                                highPriority={this.state.highPrioritySwitchValue}
                                showFieldErrors={this.state.showFieldValidationError}
                                titleError={this.state.titleError}
                                dateTimeFieldError={this.state.dateTimeFieldError}
                                customLabelError={this.state.customLabelError}
                            />
                        }
                        {
                            this.state.notificationType == 'inspire' &&
                            <InspireAnnouncementForm
                                activeDateTime={this.state.activeDateTime}
                                onActiveDateTimeChanged={(value: DateTime) => this.activeDateTimeChanged(value)}
                                expireDateTime={this.state.expireDateTime}
                                onExpireDateTimeChanged={(value: DateTime) => this.expireDateTimeChanged(value)}
                                titleValue={this.state.titleValue}
                                onTitleChanged={(value: string) => this.titleChanged(value)}
                                scrollingMessage={this.state.shortDescription}
                                onScrollingMessageChanged={(value: string) => this.shortDescriptionChanged(value)}
                                showFieldErrors={this.state.showFieldValidationError}
                                titleError={this.state.titleError}
                                dateTimeFieldError={this.state.dateTimeFieldError}
                            />
                        }
                    </Box>
                </Card >
                <div className='d-flex m-4 justify-content-between'>
                    <div>
                        <IbssButton onClick={() => this.props.history.push("/oneLens/" + this.props.match.params.buildingid + "/announcements/list")} variant='contained' color='secondary'>
                            {this.labels.HubButtonCancel}
                        </IbssButton>
                    </div>
                    {
                        this.state.notificationType &&
                        <div className='d-flex'>
                            <IbssButton onClick={() => this.publishAnnouncementClicked(true)} className='mr-3' variant='contained' color='secondary'>
                                {this.labels.funcAnnouncementSaveAsDraft_S}
                            </IbssButton>
                            <IbssButton onClick={() => this.publishAnnouncementClicked(false)} variant='contained' color='primary'>
                                {this.labels.funcAnnouncementPublish_S}
                            </IbssButton>
                        </div>
                    }
                </div>
                </Box>
            </Box>
            </>
        )
    }
}

export default EditNotification;

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    announcementId: number;
    notificationType: string | null;
    activeDateTime: DateTime;
    expireDateTime: DateTime;
    customLabelSwitchValue: boolean;
    titleValue: string;
    shortDescription: string;
    announcementMessage: string;
    images: IFile[];
    headerImageUrl: string;
    customLabel: string;
    highPrioritySwitchValue: boolean;
    loading: boolean;
    showFieldValidationError: boolean;
    titleError: boolean,
    dateTimeFieldError: boolean,
    customLabelError: boolean
}

export interface IQueryParams
{
    buildingid: string;
    notificationid: string;
}