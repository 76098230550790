import React, { Component } from 'react'
import { Box, Card, CardActions, CardContent, CardMedia, Button, Typography } from "@mui/material";


export default class IbssHomeCard extends Component<IProps>
{

  public render(): JSX.Element 
  {
    const { src, imageAlt, title, description, route, buttonLabel } = this.props;
    return (
        <Card sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <CardMedia
                component="img"
                height="55%"
                image={src}
                alt={imageAlt}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <CardContent 
                    sx={theme => 
                        ({
                            width: '240px',
                            [theme.breakpoints.up(1200)]: {
                                pt: 3,
                                pb: 1,
                            },
                            [theme.breakpoints.down(1200)]: { 
                                pt: 1,
                                pb: 0.2,
                            },
                        })
                    }
                >
                    <Typography 
                        gutterBottom 
                        variant="h2" 
                        component="div" 
                        noWrap
                        sx={theme => 
                            ({
                                fontFamily: 'Source Sans Pro',
                                fontWeight: 600, 
                                fontSize: '1.625rem', 
                                textAlign: "center",
                                typography: { xs: "p",  sm: "p", md: "h6"},
                                [theme.breakpoints.up(1200)]: {
                                    fontSize: '1.625rem',
                                },
                                [theme.breakpoints.down(1200)]: { 
                                    fontSize: '1.25rem',
                                },
                            })
                        }
                    >
                        {title}
                    </Typography>
                    <Box
                        sx={{height: '3.9rem'}}
                    >
                    <Typography 
                        variant="body2"
                        color="text.secondary"
                        className='text-overflow-3'
                        sx={theme => 
                            ({
                                fontFamily: 'Source Sans Pro',
                                textAlign:"center",
                                boxorient: "vertical",
                                fontWeight: 600,
                                [theme.breakpoints.up(1200)]: {
                                    fontSize: '0.875rem',
                                },
                                [theme.breakpoints.down(1200)]: { 
                                    fontSize: '0.75rem',
                                },
                            })
                        }>
                        {description}
                    </Typography>
                    </Box>
                </CardContent>
                <CardActions sx={{mb: 3, mt: 0.3}}>
                    <Button variant="contained" size="medium" fullWidth sx={{textTransform: 'none'}} onClick={() => this.props.redirect(route)}>{buttonLabel}</Button>
                </CardActions>
            </Box>
        </Card> 
    )
  }
}

interface IProps 
{
    id?: number;
    title: string;
    description: string;
    buttonLabel: string;
    src: string;
    imageAlt: string;
    route: string;
    redirect: (route: string) => void;
}