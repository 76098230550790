import { DateTime } from "luxon";
import { ODataSelect } from "../../../Providers.Api/ODataQuery";

export class Booking extends ODataSelect
{
    Booking_IsActive= false;
    Booking_Start = '';
    Booking_End = '';
    Booking_Share_Loc = 0;
    Booking_Owner_Name = ''
}

export interface IBooking extends Omit<IRawBooking, "Booking_IsActive" | "Booking_Start" | "Booking_End" | "Booking_Share_Loc">
{
    Booking_IsActive: boolean;
    Booking_Start: DateTime;
    Booking_End: DateTime;
    Booking_Share_Loc: (boolean | null);
}

export interface IRawBooking
{
    Approval_Groups: string,
    Approval_Required: number,
    Auto_Rejection_At: string,
    Booking_Actual_Duration: number,
    Booking_All_Day: number,
    Booking_Approval_Time: string,
    Booking_AutoCheckIn: number,
    Booking_Cancelled_Time: string
    Booking_Checkin_Time: string
    Booking_Completed_Time: string
    Booking_Description: string,
    Booking_Early_Checkin: string,
    Booking_Host_Email: string,
    Booking_Host_Name: string,
    Booking_Id: string,
    Booking_IsActive: number,
    Booking_IsApproved: number,
    Booking_IsCancelled: number,
    Booking_IsCheckedIn: number,
    Booking_IsCompleted: number,
    Booking_IsEarly_Checkin: number,
    Booking_IsLate_Checkin: number,
    Booking_IsPrivate: number,
    Booking_Name: string,
    Booking_Owner_Email: string,
    Booking_Owner_Id: string,
    Booking_Owner_Name: string,
    Booking_Parties: string,
    Booking_Parties_Formatted: string,
    Booking_Party_Count: number,
    Booking_Resources: string,
    Booking_Scheduled_Duration: number,
    Booking_Share_Loc: number,
    Booking_Start: string,
    Booking_End: string,
    Booking_Status: string,
    Cost_Code_Allocation: ICreateV2Booking_CostCodeAllocation[],
    Meta_Auto_Cancel_Thld_Min: number,
    Meta_Ext_Booking_Id: string,
    Meta_Organisation: string,
    Node_Id: number,
    Online_Meeting: string,
    Space_Id: string,
    Space_Layout: string,
}

export interface ICreateV2Booking_CostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}