import React from "react";
import "./SearchColleague.scss";
import { Box, Card, CardContent, Chip,Typography } from "@mui/material";
import { appContext } from "../../../../AppContext";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import { RouterProps } from "react-router";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { IUser } from "../../../../Providers.Api/Users/UsersRepository";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import { DateTime } from "luxon";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import SearchAColleague from './SearchAColleague.svg';
import { BookingPartyFilter } from "../../../../Providers.Api/BookingParties/BookingPartyRepository";
import Helper, { getBuildingTimeZoneByNodeId } from "../../../../Common/Helper";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";


export default class SearchColleague extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get appState() { return appContext().state; }
    private apiCache = appContext().apiCache;

    constructor(props: IProps) 
    {
        super(props);
        this.state =
        {
            search: '',
            userList: [],
            showColleagueLocation: false,
            selectedDate: DateTime.now(),
            selectedUser: '',
            bookingDetails: [],
            selectedUserEmail: ''
        };
    }

    public componentDidMount(): void 
    {
        this.pageTitle = this.labels.HubMenuFindColleague;
    }

    private searchInputChanged(e: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({ search: e.target.value });

    }

    private handleView(userData: IUser): void 
    {
        this.setState({
            showColleagueLocation: true,
            selectedUserEmail: userData.email,
            selectedUser: userData.displayName,
        });
        this.getBookingDetails(userData.email);
    }

    private fromDateToString(date: DateTime, type: "start" | "end"): string 
    {
        if (type === "start") 
        {
            return date.setZone(getBuildingTimeZoneByNodeId(this.appState.buildingId)).startOf('day').toUTC().toISO();
        }
        else 
        {
            return date.setZone(getBuildingTimeZoneByNodeId(this.appState.buildingId)).endOf('day').toUTC().toISO();
        }
    }

    private async getBookingDetails(email: string): Promise<void> 
    {
        const recursive = true;
        const bookingPartyFilter = new BookingPartyFilter({
            participantType: 4,
            bookingShare: 1,
            participantEmail: email ? email : this.state.selectedUserEmail,
            startDateTime: this.fromDateToString(this.state.selectedDate, "start"),
            endDateTime: this.fromDateToString(this.state.selectedDate, "end"),
        });
    
        try
        {
            const bookingData = await this.apiClient.bookingParties.getMany(1, bookingPartyFilter, recursive);
            const bookingsWithTimeAndSpace = await Promise.all(bookingData.map(async (data) => {
                const spaceList = await this.apiCache.getSpacesByBuilding(data.Node_Id);
                const spaceData = spaceList.find(x => x.Space_Id === data.Space_Id);
                const zone = spaceData?.Meta_Loc_Zone;
                return {
                    ...data,
                    zone,
                };
            }));
    
            this.setState({ bookingDetails: bookingsWithTimeAndSpace });
        } 
        catch (error) 
        {    
        }
    }
    

    private async handleSearch(): Promise<void>
     {
        try 
        {

            if (this.state.search) 
            {
                const userlist = await this.apiClient.users.getUsers(this.state.search);
                let newArray = userlist?.map(item => ({
                    ...item,
                }));

                this.setState({ userList: newArray });
            } 
            else 
            {
                this.setState({ userList: [] });
            }

        }
        catch (error) 
        {
        }
    }

    private getFloorName(nodeId:number): string 
    {
        let foundFloorName = "";
        Helper.getAllBuildingsData().some((building) => {
          if (building.Floors !== null) 
          {
            const matchingFloor = building.Floors.find((floor) => floor.Node_Id === nodeId);
            if (matchingFloor)
            {
              foundFloorName = matchingFloor.Node_Name;
              return true;
            }
          }
          return false;
        });
        return foundFloorName;
    }

    private async changeDate(date: DateTime | null): Promise<void> 
    {
        if (date !== null) 
        {
            await this.setStateAsync({ selectedDate: date });
        }
        this.getBookingDetails("")
    }

    private getBookingTime(nodeId: number, startTime: DateTime, endTime: DateTime): string 
    {
        const bookingStartTime = startTime.offsetTimeByNode(nodeId, true).toLocaleTimeString();
        const bookingEndTime = endTime.offsetTimeByNode(nodeId, true).toLocaleTimeString();
        return `${bookingStartTime} - ${bookingEndTime}`;
    }

    private handleColleagueDetailModal(): void
    {
        this.setState({
        showColleagueLocation: false,
        selectedDate: DateTime.now(),
        })
    }


    public render(): JSX.Element {
        const { selectedDate, selectedUser } = this.state;
        const formattedDate = selectedDate.toLocaleDateString();
        const columns: GridColDef[] =
            [
                { field: 'displayName', headerName: this.labels.HubLabelName, width: 200 },
                { field: 'email', headerName: this.labels.HubLabelEmail, width: 200 },
                {
                    field: 'view',
                    headerName: this.labels.HubLabelView,
                    renderCell: (params) => (
                        <IbssButton
                            sx={{ textDecoration: 'underline' }}
                            onClick={() => this.handleView(params.row)}
                        >
                            {this.labels.HubLabelView}
                        </IbssButton>
                    ),
                },
            ];

        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content table-panel content-area-vh">
                    <Typography mb={2}>{this.labels.HubLabelFindAColleagueHeading}</Typography>
                    <Box display={"flex"} alignItems={"center"}>
                        <IbssTextField
                            id="search"
                            label={this.labels.HubLabelEnterNameOrEmail}
                            variant="outlined"
                            size="small"
                            placeholder={`${this.labels.HubLabelNameOrEmailEtc} ...`}
                            sx={{ minWidth: '350px' }}
                            value={this.state.search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.searchInputChanged(event)}
                        />
                        <IbssButton
                            variant='contained'
                            className="ml-2"
                            disabled={this.state.search.length < 3}
                            onClick={() => { this.handleSearch() }}
                        >
                            {this.labels.HubLabelSearch}
                        </IbssButton>
                    </Box>
                    {this.state.userList.length == 0 ?
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="mt-2" src={SearchAColleague} />
                            </Box>
                            <Box textAlign={'center'} mt={2}>
                                <label className="text-label">{this.labels.funcSearchForAColleague_S}</label>
                                <Typography variant="body2" mt={2}>{this.labels.funcSearchForAColleague_Message}</Typography>
                            </Box>
                        </Box>
                        :
                        <Box sx={{ mt: 3 }}>
                            <Typography mb={2}>{this.labels.HubLabelFindColleagueInfoMessage}</Typography>
                            <IbssDataGrid
                                rows={this.state.userList}
                                columns={columns}
                                getRowId={(row) => row.displayName}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    }
                    <Box>
                        <IbssDialog
                            aria-modal="true"
                            aria-label="colleague popup"
                            id="colleague-dialog"
                            open={this.state.showColleagueLocation}
                            onClose={() => this.handleColleagueDetailModal()}
                            fullWidth
                            header=
                            {
                              this.labels.HubLabelColleagueLocation
                            }
                            dialogContent=
                            {
                                <>
                                    <Box mb={1}>
                                        <Typography className="ui-text-light" variant="caption">{this.labels.HubLabelColleagueBookingInfoMessage} </Typography>
                                    </Box>
                                    <div className=" d-flex justify-content-start">
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                disablePast
                                                value={selectedDate}
                                                onChange={(date) => this.changeDate(date)}
                                                renderInput={({ inputRef }) => (
                                                    <div style={{ display: 'none' }}>
                                                        <input ref={inputRef} readOnly />
                                                    </div>
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <Box>
                                            <Typography variant="h5">{selectedUser}</Typography>
                                            <Typography mt={1} variant="body2"> {formattedDate}</Typography>
                                            <div className="custom-scrollbar colleague-card-height">
                                                {this.state.bookingDetails.length > 0 ? (
                                                    this.state.bookingDetails.map((data, index) => (
                                                        <Card sx={{ mt: 2, mr: 1 }} key={index} aria-label="booking information card">
                                                            <CardContent className="card-gray">
                                                                <Box>
                                                                    {data.Booking_Participant_CheckedIn === 1 ? (
                                                                        <Box mb={1} display={"flex"} justifyContent={"end"}>
                                                                            <Chip className="bg-success-highlight" label="Checked in" />
                                                                        </Box>
                                                                    ) : null}
                                                                    <Typography mb={1} variant="body1">{`${data.Booking_Name}`}</Typography>
                                                                    <Typography mb={1} variant="body1">{` ${data.Space_Name}`}</Typography>
                                                                    <Typography mb={1} variant="body1">{` ${data.Node_Name}`}</Typography>
                                                                    <Typography mb={1} variant="body1">{`${data.zone}`}, {`${this.getFloorName(data.Node_Id)}`}</Typography>
                                                                    <Typography variant="body1">{this.getBookingTime(data.Node_Id, data.Booking_Start, data.Booking_End)}</Typography>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <Typography mb={1} variant="body1">{this.labels.HubLabelNoData}</Typography>
                                                )}
                                            </div>
                                        </Box>
                                    </div>
                                </>
                            }
                            footer=
                            {
                                <div className="px-58 w-100 d-flex justify-content-center">
                                    <IbssButton
                                        variant='contained'
                                        onClick={() => this.handleColleagueDetailModal()}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButton>
                                </div>
                            }
                        />
                    </Box>
                </div>
            </div>
        )
    }
}

export interface IState 
{
    search: string;
    userList: IUser[];
    showColleagueLocation: boolean;
    selectedDate: DateTime,
    selectedUser: string,
    selectedUserEmail: string,
    bookingDetails: IBookingDetails[]
}

interface IBookingDetails {
    Space_Id: string,
    Visit_Id: string,
    Booking_Id: string,
    Booking_Start: DateTime,
    Booking_End: DateTime,
    Booking_Name: string,
    Booking_Status: string,
    Node_Id: number,
    Booking_Participant_Email: string,
    Booking_Participant_Name: string,
    Booking_Participant_Organisation: string,
    Record_Id: string,
    Booking_Resource_Id: string,
    Booking_Visitor: number,
    Booking_Participant_Type: number,
    Booking_Participant_CheckedIn: number,
    Booking_Participant_Status: string,
    Booking_Early_Checkin: string,
    Space_Name: string,
    Space_Name_Label: string,
    Node_Name: string,
    zone: string | undefined;
}
export interface IProps extends RouterProps {
}