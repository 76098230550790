import { Typography } from '@mui/material';
import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class ChangeMenuModal extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            selectedMenuId: this.props.selectedMenu
        };
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void
    {
        if (prevProps.selectedMenu != this.props.selectedMenu)
        {
            this.setState({ selectedMenuId: this.props.selectedMenu });
        }
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                dialogContent=
                {
                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            <div className='mb-3'>{this.labels.funcCateringOrderMenuChangeWarning_Message}</div>
                            <strong>{this.labels.funcCateringOrdersPleaseNote_Short} </strong>
                            {this.labels.funcCateringOrderMenuChangeInfo_Message}
                        </Typography>
                        <div className='mb-3 mt-2'>
                            <IbssInputDropDown
                                id='menu-select-dropdown'
                                onChange={(x: { target: { value: string; }; }) => this.setState({ selectedMenuId: x.target.value })}
                                value={this.state.selectedMenuId}
                                options={this.props.options}
                                inputLabel={this.labels.funcCateringOrderSelectedMenu_Short} />
                        </div>
                        <div className='mb-2' style={{ textAlign: 'right' }}>
                            <IbssButton
                                style={{ height: '45px', minWidth: '100px' }}
                                color='primary'
                                variant="contained"
                                onClick={() => this.props.onChange(this.state.selectedMenuId)}
                            >
                                {this.labels.HublabelChange}
                            </IbssButton>
                        </div>
                    </>
                }
                header={this.labels.funcCateringOrderChangeMenu_Short}
                fullWidth
            />
        )
    }
}

export default ChangeMenuModal;

export interface IProps
{
    open: boolean;
    onClose: () => void;
    onChange: (menuId: string) => void;
    options: IDropdownOptions[];
    selectedMenu: string;
}

export interface IState
{
    selectedMenuId: string;
}

export interface IDropdownOptions
{
    label: string;
    value: string;
}