
export class EventType
{
    public static advancedSecurityEntities = new EventType(49, "AdvancedSecurityEntities");
    public static aspNetRoles = new EventType(46, "AspNetRoles");
    public static aspNetUserRoles = new EventType(47, "AspNetUserRoles");
    public static aspNetUsers = new EventType(45, "AspNetUsers");
    public static bookingParties = new EventType(10, "BookingParties");
    public static bookingPolicies = new EventType(52, "BookingPolicies");
    public static bookings = new EventType(8, "Bookings");
    public static bookingsDailySummary = new EventType(13, "BookingsDailySummary");
    public static bookingSpaces = new EventType(50, "BookingSpaces");
    public static buildingKPIs = new EventType(2, "BuildingKPIs");
    public static cateringOrders = new EventType(58, "CateringOrders");
    public static connectorsConfiguration = new EventType(63, "ConnectorsConfiguration");
    public static costCodes = new EventType(42, "CostCodes");
    public static delegates = new EventType(59, "Delegates");
    public static devices = new EventType(33, "Devices");
    public static devicesStatus = new EventType(43, "DevicesStatus");
    public static environmentalFloorData = new EventType(36, "EnvironmentalFloorData");
    public static environmentalSensors = new EventType(20, "EnvironmentalSensors");
    public static environmentalZoneDailySummary = new EventType(22, "EnvironmentalZoneDailySummary");
    public static environmentalZoneData = new EventType(24, "EnvironmentalZoneData");
    public static environmentalZones = new EventType(15, "EnvironmentalZones");
    public static environmentalZoneSummary = new EventType(21, "EnvironmentalZoneSummary");
    public static equipment = new EventType(14, "Equipment");
    public static ibssFilters = new EventType(61, "IBSS_Filters");
    public static ibssMenu = new EventType(54, "IBSS_Menu");
    public static ibssMenuItem = new EventType(55, "IBSS_MenuItem");
    public static ibssOrderPolicy = new EventType(53, "IBSS_OrderPolicy");
    public static ibssRestriction = new EventType(56, "IBSS_Restriction");
    public static ibssSupplier = new EventType(57, "IBSS_Supplier");
    public static ibssTagDefinitions = new EventType(60, "IBSS_Tag_Definitions");
    public static identityProviders = new EventType(44, "IdentityProviders");
    public static nodes = new EventType(9, "Nodes");
    public static notes = new EventType(62, "Notes");
    public static notificationRule = new EventType(48, "NotificationRule");
    public static notifications = new EventType(6, "Notifications");
    public static parameters = new EventType(18, "Parameters");
    public static resolversCategories = new EventType(29, "ResolversCategories");
    public static resolversDailySummary = new EventType(12, "ResolversDailySummary");
    public static signage = new EventType(39, "Signage");
    public static spaceCateringMenu = new EventType(32, "SpaceCateringMenu");
    public static spaceInformation = new EventType(25, "SpaceInformation");
    public static spaces = new EventType(4, "Spaces");
    public static spacesDailySummary = new EventType(31, "SpacesDailySummary");
    public static spacesMonthlySummary = new EventType(35, "SpacesMonthlySummary");
    public static spacesSpaceInformation = new EventType(26, "SpacesSpaceInformation");
    public static spacesSummary = new EventType(30, "SpacesSummary");
    public static spaceStates = new EventType(19, "SpaceStates");
    public static spacesWeeklySummary = new EventType(34, "SpacesWeeklySummary");
    public static spaceUtilisationSummary = new EventType(40, "SpaceUtilisationSummary");
    public static spaceZones = new EventType(41, "SpaceZones");
    public static supportedVersions = new EventType(27, "SupportedVersions");
    public static taskCategories = new EventType(16, "TaskCategories");
    public static tasks = new EventType(7, "Tasks");
    public static tasksDailySummary = new EventType(11, "TasksDailySummary");
    public static taskStates = new EventType(23, "TaskStates");
    public static taskTypes = new EventType(17, "TaskTypes");
    public static userNotifications = new EventType(28, "UserNotifications");
    public static visitors = new EventType(38, "Visitors");
    public static visits = new EventType(37, "Visits");
    public static visitsDailySummary = new EventType(51, "VisitsDailySummary");

    public id: number;
    public name: string;

    private constructor(id: number, name: string)
    {
        this.id = id;
        this.name = name;
    }
}
