import { Component } from 'react';
import { appContext } from '../../../AppContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import { CateringTask } from '../../../Providers.Api/Tasks/TaskRepository';
import { CateringOrderView } from '../../../Pages/OneLens/Bookings/ViewSchedule/BookingCriteria';

class CateringOrderPicker extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            lightModeTheme: this.appState.lightModeTheme,
            cateringItemsTotal: 0,
            cateringTask: null,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public handleCateringRoute(): void
    {
        const { history } = this.props;
        const activeurl = window.location.href;

        const primarySpaceId: string = this.props.spaceId.split(';')[0];

        // if there is already a catering task, route to one of the catering task edit pages, else route to the catering task creation page. 
        if (this.props.cateringOrder?.orderId ?? '')
        {
            if (activeurl.includes("flex-my-bookings"))
            {
                this.props.history.push("/flex-my-bookings/" + this.props.buildingId + "/catering/" + this.props.cateringOrder.orderId + "/edit");
            }
            else if (activeurl.includes("schedule-view"))
            {
                this.props.history.push({
                    pathname: `/operational-services-catering/${this.props.buildingId}/catering/${this.props.cateringOrder.orderId}/edit`,
                    state: { bookingId: this.props.bookingId, originRoute: `/buildings/${this.props.buildingId}/bookings/schedule-view` } // information to push via react router
                });
            }
            else
            {
                this.props.history.push("/operational-services-catering/" + this.props.buildingId + "/catering/" + this.props.cateringOrder.orderId + "/edit");
            }
        }
        else
        {
            if (activeurl.includes("schedule-view"))
            {
                history.push({
                    pathname: `/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${primarySpaceId}`,
                    state: { bookingId: this.props.bookingId, originRoute: `/buildings/${this.props.buildingId}/bookings/schedule-view` } // information to push via react router
                });
            }
            else
            {
                history.push(`/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${primarySpaceId}`);
            }
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                {this.props.bookingId && (this.props.metaServReqsCatering === 1) &&
                    (
                        <Button
                            fullWidth
                            sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }}
                            className={"row attendes cp mt-16"}
                            onClick={() => this.handleCateringRoute()}
                        >
                            <div className="icon-text-inline pl-0">
                                <span className="space-icon-item">
                                    <img alt="Catering" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                </span>
                                <span className="space-text-item col-text">{this.labels.HubLabelCateringTable}</span>
                            </div>
                            <div className="d-flex">
                                {this.state.isLoading &&
                                    <div style={{ cursor: "none" }}>
                                        <CircularProgress size={20} />
                                    </div>
                                }
                                {!this.state.isLoading &&
                                    <div className="d-flex">
                                        <span className="space-text-item mr-3 text-lowercase">{`${this.props.cateringItemsTotal} ${this.labels.HubLabelItems}`}</span>
                                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {Icons.RightArrowIcon}
                                        </IbssSvgIcon>
                                    </div>
                                }
                            </div>
                        </Button>
                    )
                }
            </>
        )
    }
}

export default withRouter(CateringOrderPicker);

export interface IProps extends RouteComponentProps
{
    bookingId: string;
    buildingId: number;
    cateringOrder: CateringOrderView;
    cateringItemsTotal: number;
    metaServReqsCatering: number;
    spaceId: string;
}

export interface IState
{
    isLoading: boolean;
    lightModeTheme: boolean;
    cateringItemsTotal: number;
    cateringTask: CateringTask | null;
}