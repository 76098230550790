import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { appContext } from "../../../../AppContext";
import MediaSelector, { IFile } from "../../../../Components/DialogLaunchers/MediaSelector/MediaSelector";
import "./EditTags.scss"
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import Bin from "../../../../Components/Icons/Bin";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export default class TagOption extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps) 
    {
        super(props);
    }
    public render(): JSX.Element 
    {

        const { index, imageUrl, isLastOption, optionName, onImageSelected, onRemoveOption, onOptionNameChanged, images, uploadImage, isRemoveDisable } = this.props;
        return (

            <Box className="option-box" my={2}>
                <Typography variant="body2" mb={1}>{`Option ${index + 1}`}</Typography>
                <TextField
                    required
                    fullWidth id="outlined-basic"
                    label={this.labels.funcOptionName_S}
                    variant="outlined"
                    value={optionName}
                    disabled={isRemoveDisable}
                    onChange={(event) => onOptionNameChanged(event, index)} />
                <Box mt={1} display={'flex'} justifyContent={"space-between"}>
                    <Typography variant="body1">
                        <MediaSelector
                            options={images.map(i => ({ name: i.name, url: i.path }))}
                            imageSelected={file => onImageSelected(file.url)}
                            uploadFile={(filename, file, processAsMap) => uploadImage(filename, file, processAsMap)}
                            defaultSelectedImage={imageUrl}
                        />
                    </Typography>
                    {!isLastOption &&
                        <IbssButton variant="text" color="error" onClick={() => onRemoveOption(index)} disabled={isRemoveDisable}>
                            <IbssSvgIcon>
                                <Bin />
                            </IbssSvgIcon>
                        </IbssButton>
                    }
                </Box>
            </Box>
        )
    }
}
export interface IProps 
{
    images: IFile[];
    index: number;
    imageUrl: string;
    optionName: string;
    onImageSelected: (url: string) => void;
    onRemoveOption: (index: number) => void;
    onOptionNameChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
    uploadImage: (filename: string, file: FormData, processAsMap: boolean) => Promise<void>;
    isLastOption: boolean;
    isRemoveDisable: boolean;
}
export interface IState 
{
}
