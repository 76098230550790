import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringSupplier } from "./CateringSupplierRepository";

export class UpdateCateringSupplierEndpoint implements IUpdateCateringSupplierEndpoint
{
    public async execute(nodeId: number, supplierId: string, payload: ICateringSupplier): Promise<void>
    {
        try
        {
            const response = await axios.put<void>(apis.allApiv2 + nodeId + `/catering-supplier/` + supplierId, payload);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface IUpdateCateringSupplierEndpoint
{
    execute(nodeId: number, supplierId: string, payload: ICateringSupplier): Promise<void>;
}
