import { Descendant, Text as SlateText } from "slate";
import { RenderElementProps, RenderLeafProps } from "slate-react";

export class SlateToReactAdapter
{
    public renderLeaf(props: RenderLeafProps, allowLinks: boolean): JSX.Element
    {
        const leaf = props.leaf as TextNode;
        let children = props.children;

        if (leaf.bold == true)
        {
            children = <strong>{children}</strong>;
        }
        if (leaf.italic == true)
        {
            children = <em>{children}</em>;
        }
        if ('link' in leaf && typeof (leaf.link) == 'string' && leaf.link)
        {
            children = <a href={leaf.link} onClick={e => this.linkClicked(e, allowLinks, leaf.link as string)}>{children}</a>;
        }

        return <span {...props.attributes}>{children}</span>;
    }

    public renderElement(props: RenderElementProps, allowLinks: boolean): JSX.Element
    {
        const { attributes, children, element } = props;
        if (!('type' in element))
        {
            return <p {...attributes}>{children}</p>;
        }

        const href = ('link' in element && typeof (element.link) == 'string' ? element.link : '#');

        switch (element.type)
        {
            case 'heading_one':
                return <h1 {...attributes}>{children}</h1>;
            case 'heading_two':
                return <h2 {...attributes}>{children}</h2>;
            case 'heading_three':
                return <h3 {...attributes}>{children}</h3>;
            case 'heading_four':
                return <h4 {...attributes}>{children}</h4>;
            case 'heading_five':
                return <h5 {...attributes}>{children}</h5>;
            case 'heading_six':
                return <h6 {...attributes}>{children}</h6>;
            case 'ol_list':
                return <ol {...attributes}>{children}</ol>;
            case 'ul_list':
                return <ul {...attributes}>{children}</ul>;
            case 'list_item':
                return <li {...attributes}>{children}</li>;
            case 'code_block':
                return <span className="markdown-editor__code-block" {...attributes}>{children}</span>;
            case 'thematic_break':
                return <div className='markdown-editor__thematic-break' {...attributes}>{children}</div>;
            default:
                return <p {...attributes}>{children}</p>;
        }
    }

    private linkClicked(event: React.MouseEvent, allowLinks: boolean, href: string): void
    {
        event.preventDefault();
        if (!allowLinks)
        {
            return;
        }
        window.open(href);
    }
}

interface TextNode extends SlateText
{
    text: string;
    bold?: boolean;
    italic?: boolean;
    code?: boolean;
}
