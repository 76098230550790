import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


type IbssDonutProgressChartProps = CircularProgressProps & {
    value: number,
    donutSize?: number,
    strokeWidth?: number,
    strokeColor?: string,
    portionColor?: string,
    fontSize?: number,
    fontColor?: string,
}

const StyledCircularProgress = styled(CircularProgress, {
    shouldForwardProp: (propName: PropertyKey) => {
        return !['strokeColor'].includes(propName as string);
    }
})<IbssDonutProgressChartProps>(({ strokeColor }) => ({
    '&.MuiCircularProgress-colorPrimary': {
        color: strokeColor
    }
}));

const IbssDonutProgressChart = (props: IbssDonutProgressChartProps) => {

    const { value, donutSize, strokeWidth, fontSize, fontColor } = props

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    position: 'relative',
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === "light" ? 300 : 800]
                }}
                size={donutSize}
                thickness={strokeWidth}
                {...props}
                value={100}
            />
            <StyledCircularProgress {...props}
                variant="determinate"
                sx={{
                    position: 'absolute',
                }}
                value={value}
                size={donutSize}
                thickness={strokeWidth}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    color={fontColor ? fontColor : `var(--ui-text)`}
                    fontSize={fontSize}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default IbssDonutProgressChart;
