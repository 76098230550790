import { Component } from 'react';

export default class Waitinglounge extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 1.75C22.5482 1.75 19.75 4.54822 19.75 8C19.75 11.4518 22.5482 14.25 26 14.25C29.4518 14.25 32.25 11.4518 32.25 8C32.25 4.54822 29.4518 1.75 26 1.75ZM18.25 8C18.25 3.71979 21.7198 0.25 26 0.25C30.2802 0.25 33.75 3.71979 33.75 8C33.75 12.2802 30.2802 15.75 26 15.75C21.7198 15.75 18.25 12.2802 18.25 8Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 3.25C26.4142 3.25 26.75 3.58579 26.75 4V7.68934L28.5303 9.46967C28.8232 9.76256 28.8232 10.2374 28.5303 10.5303C28.2374 10.8232 27.7626 10.8232 27.4697 10.5303L25.25 8.31066V4C25.25 3.58579 25.5858 3.25 26 3.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 33C0.25 32.5858 0.585786 32.25 1 32.25H33C33.4142 32.25 33.75 32.5858 33.75 33C33.75 33.4142 33.4142 33.75 33 33.75H1C0.585786 33.75 0.25 33.4142 0.25 33Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 26C0.25 24.4812 1.48122 23.25 3 23.25H11C12.5188 23.25 13.75 24.4812 13.75 26C13.75 27.5188 12.5188 28.75 11 28.75H3C1.48122 28.75 0.25 27.5188 0.25 26ZM3 24.75C2.30964 24.75 1.75 25.3096 1.75 26C1.75 26.6904 2.30964 27.25 3 27.25H11C11.6904 27.25 12.25 26.6904 12.25 26C12.25 25.3096 11.6904 24.75 11 24.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 26C13.25 24.4812 14.4812 23.25 16 23.25H24C25.5188 23.25 26.75 24.4812 26.75 26C26.75 27.5188 25.5188 28.75 24 28.75H16C14.4812 28.75 13.25 27.5188 13.25 26ZM16 24.75C15.3096 24.75 14.75 25.3096 14.75 26C14.75 26.6904 15.3096 27.25 16 27.25H24C24.6904 27.25 25.25 26.6904 25.25 26C25.25 25.3096 24.6904 24.75 24 24.75H16Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 16C1.25 13.9289 2.92893 12.25 5 12.25H9C11.0711 12.25 12.75 13.9289 12.75 16V24.75H1.25V16ZM5 13.75C3.75736 13.75 2.75 14.7574 2.75 16V23.25H11.25V16C11.25 14.7574 10.2426 13.75 9 13.75H5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.75C16.7574 13.75 15.75 14.7574 15.75 16V23.25H24.25V15.1875H25.75V24.75H14.25V16C14.25 13.9289 15.9289 12.25 18 12.25H21V13.75H18Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 33L6.25 28L7.75 28L7.75 33L6.25 33Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.25 33L19.25 28L20.75 28L20.75 33L19.25 33Z" />
            </svg>
        )
    }
};