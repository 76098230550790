import { Component } from "react";
import { appContext } from "../../../../AppContext";
import { Box, Card, Grid, SvgIcon, TextField, TextareaAutosize, Typography } from "@mui/material";
import "../../../../styles/css/dashboard.scss";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import RightArrowIcon from "../../../../Components/Icons/RightArrowIcon";
import customTheme from "../../../../customTheme";
import { RouteComponentProps } from "react-router-dom";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import apis from "../../../../Providers.Api/apis";
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { INode } from "../../../../Providers.Api/Models";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { CateringTask } from "../../../../Providers.Api/Tasks/TaskRepository";

class ViewCateringOrder extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() {return appContext().apiCache; }
    private userCanCancel = false;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            task: {} as ITask,
            orderItems: [] as ITaskOrderItems[],
            loading: false,
            currency: '',
            preBookCateringTime: '',
            bookingStart: DateHelper.null(),
            bookingEnd: DateHelper.null(),
            showMessageModal: false,
            cancelMessage: '',
            bookingName: '',
            spaces: []
        };
        this.userCanCancel = this.localStorage.getIbssRightList().ADMINPORTAL.Tasks.includes('Cancel');
    }

    public async componentDidMount(): Promise<void>
    {
        const { match } = this.props;
        const availableSpaces = this.localStorage.getNodeData();
        let selectedBuilding: INode | null = null;
        const buildings = availableSpaces.Regions.map(x => x.Buildings);
        const spaces = await this.apiCache.getSpacesByBuilding(this.appState.buildingId);
        this.setState({ spaces: spaces })

        for (let i = 0; i < buildings.length; i++)
        {
            const building = buildings[i].filter(building => building.Node_Id === this.appState.buildingId);
            if (building.length > 0)
            {
                selectedBuilding = building[0];
                break;
            }
        }
        if(selectedBuilding == null)
        {
            return;
        }
        this.setState({ loading: true, currency: selectedBuilding.Cat_Crncy, preBookCateringTime: selectedBuilding.Pre_Book_Cat_Time });

        const taskData = await this.apiClient.tasks.getTask(CateringTask, 1, match.params.spacecateringId);
        const task = {
            nodeId: taskData.Node_Id,
            bookingId: taskData.Booking_Id,
            taskId: taskData.Task_Id,
            taskTypeName: taskData.Task_Type_Name,
            createdAt: taskData.CreatedAt,
            taskCategoryName: taskData.Task_Category_Name,
            spaceId: taskData.Space_Id,
            taskPriority: taskData.Task_Priority,
            taskDueDate: taskData.Task_Due_Date,
            taskOwner: taskData.Task_Owner,
            taskEventTime: taskData.Task_Event_Time,
            taskCreatedBy:taskData.Task_CreatedBy,
            taskStatus: taskData.Task_Status,
            taskIsActive: taskData.Task_IsActive,
            taskIsOwned: taskData.Task_IsOwned,
            cateringServiceTime: taskData.Catering_Service_Time,
            cateringClearingTime: taskData.Catering_Clearing_Time,
            cateringAttendees: taskData.Catering_Attendees,
            cateringNotes: taskData.Catering_Notes,
            costCodeAllocation: taskData.Cost_Code_Allocation.map(x => {return{costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation}}),
            taskTTC: taskData.Task_TTC
        }
        const floorId = spaces.filter(space => space.Space_Id == taskData.Space_Id)[0].Node_Id
        this.setState({ task: task });

        const taskOrderItemsData = await this.apiClient.cateringOrders.getTaskOrderItems(1, match.params.spacecateringId);
        const orderItems = taskOrderItemsData.map(x => {return({
            imageUri: x.ImageURI,
            menuItemId: x.Menu_Item_Id,
            menuItemTitle: x.Menu_Item_Title,
            menuItemDescription: x.Menu_Item_Description,
            menuItemSection: x.Menu_Item_Section,
            menuItemPrice: x.Menu_Item_Price,
            nodeId: x.Node_Id,
            cateringOrderId: x.Catering_Order_Id,
            spaceId: x.Space_Id,
            cateringOrderItemId: x.Catering_Order_Item_Id,
            cateringOrderItemQuantity: x.Catering_Order_Item_Quantity,
        })});
        this.setState({ orderItems: orderItems });

        const bookingData = await this.bookingService.get(floorId, this.state.task.bookingId)
        this.setState({ bookingStart: DateTime.fromISO(bookingData.Booking_Start), bookingEnd: DateTime.fromISO(bookingData.Booking_End), bookingName: bookingData.Booking_Name });

        this.setState({ loading: false });
    }

    public async cancelTask(): Promise<void>
    {
        const { match } = this.props;
        this.setState({ loading: true });
        let apiPayload = { "TaskResolveDescription": this.state.cancelMessage };
        await apis.updateTasksCancelByTaskId(this.appState.buildingId, this.state.task.taskId, apiPayload);
        const taskData = await this.apiClient.tasks.getTask(CateringTask, 1, match.params.spacecateringId);
        const task = {
            nodeId: taskData.Node_Id,
            bookingId: taskData.Booking_Id,
            taskId: taskData.Task_Id,
            taskTypeName: taskData.Task_Type_Name,
            createdAt: taskData.CreatedAt,
            taskCategoryName: taskData.Task_Category_Name,
            spaceId: taskData.Space_Id,
            taskPriority: taskData.Task_Priority,
            taskDueDate: taskData.Task_Due_Date,
            taskOwner: taskData.Task_Owner,
            taskEventTime: taskData.Task_Event_Time,
            taskCreatedBy:taskData.Task_CreatedBy,
            taskStatus: taskData.Task_Status,
            taskIsActive: taskData.Task_IsActive,
            taskIsOwned: taskData.Task_IsOwned,
            cateringServiceTime: taskData.Catering_Service_Time,
            cateringClearingTime: taskData.Catering_Clearing_Time,
            cateringAttendees: taskData.Catering_Attendees,
            cateringNotes: taskData.Catering_Notes,
            costCodeAllocation: taskData.Cost_Code_Allocation.map(x => {return{costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation}}),
            taskTTC: taskData.Task_TTC
        }
        this.setState({ loading: false, showMessageModal: false, task: task });
    }

    private spaceNamesById: (Map<string, string> |  null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [ i.Space_Id, i.Space_Name ]));
        return this.spaceNamesById.get(spaceId) ?? "";
    }

    public render(): JSX.Element
    {
        const currentDate = DateTime.now();
        const currentTime = DateTime.local();
        const serviceTime = DateTime.fromISO(this.state.task.cateringServiceTime).toLocaleString(DateTime.TIME_24_SIMPLE);
        const cleanUpTime = DateTime.fromISO(this.state.task.cateringClearingTime).toLocaleString(DateTime.TIME_24_SIMPLE);

        const cutOffTimeDays = this.state.preBookCateringTime.split('.')[0];

        const preBookCateringHours = parseInt(this.state.preBookCateringTime?.split('.')[1]?.split(':')[0]);
        const bookingStartHours = this.state.bookingStart.hour;

        const preBookCateringMinutes = parseInt(this.state.preBookCateringTime?.split('.')[1]?.split(':')[1]);
        const bookingStartMinutes = this.state.bookingStart.minute;

        const cutOffTimeHours = bookingStartHours - preBookCateringHours < 0 ? (bookingStartHours - preBookCateringHours) + 24 : bookingStartHours - preBookCateringHours;
        const cutOffTimeMinutes = bookingStartMinutes - preBookCateringMinutes < 0 ? (bookingStartMinutes - preBookCateringMinutes) + 60 : bookingStartMinutes - preBookCateringMinutes;

        const newTime = !Number.isNaN(cutOffTimeHours) && currentTime.minus({ hours: Number(cutOffTimeHours),minutes: Number(cutOffTimeMinutes)}).toISOTime();

        const newDate = currentDate.minus({ days: Number(cutOffTimeDays) });

        const calculatedDate = newDate.toISODate();
        const formattedDate = DateTime.fromISO(calculatedDate).toLocaleString(DateTime.DATE_SHORT)

        const calculatedHour = newTime && newTime.substring(0, 2);
        const calculatedMinutes = newTime &&  newTime.substring(3, 5);


        return (
            <>
                {
                    this.state.loading ?
                        <Spinner />
                        :
                        <div className="rightPanel-main-content-form">
                                <div className="m-3">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <TablePanelInfo
                                            tableHeading={`${this.labels.HubLabelCateringTask} - ${this.state.task.taskId}`}
                                            iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                                        />
                                         <Box component="div">
                                            <IbssButton disabled={this.state.task.taskStatus == 'Cancelled' || this.state.task.taskStatus == 'Resolved'} variant="contained" className="m-2" onClick={() => this.props.history.push("/operational-services-catering/" + this.appState.buildingId + "/catering/" + this.props.match.params.spacecateringId + "/edit")}>{this.labels.HubLabelEditCatering}</IbssButton>
                                            <IbssButton disabled={!this.userCanCancel || this.state.task.taskStatus == 'Cancelled' || this.state.task.taskStatus == 'Resolved'} color="error" variant="contained" className="m-2" onClick={() => this.setState({ showMessageModal: true })}>{this.labels.HubLabelCancelCatering}</IbssButton>
                                            <IbssButton color="secondary"  variant="contained" className="m-2" onClick={() => this.props.history.push("/catering-orders/" + this.appState.buildingId)}>{this.labels.HubButtonBacklabel}</IbssButton>
                                        </Box>
                                    </div>
                                    <Box sx={{my:2}}><Typography variant="body2" gutterBottom>{this.labels.HubLabelCateringDetailsDescription}</Typography></Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={7} xl={8}>
                                            <Card>
                                                {this.state.orderItems?.map(item =>
                                                {
                                                    return (
                                                        <Box  component="div" key={item.menuItemId} sx={{mb:1,p:3}}>
                                                            <Grid container>
                                                                <Grid item xs={2} md={3} lg={2} sx={{ display:'flex',justifyContent:'center', padding: item.imageUri.length > 0 ? '' : '40px 0px' }}>
                                                                    {item.imageUri.length > 0 &&
                                                                        <img
                                                                            width={100}
                                                                            height={100}
                                                                            src={item.imageUri}
                                                                            alt={item.menuItemTitle}
                                                                        />
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={6} md={5} lg={6} sx={{ alignSelf: 'center' }}>
                                                                    <Box sx={{ml:2}}>
                                                                    <Typography variant="h6" gutterBottom>{item.menuItemTitle}</Typography>
                                                                    <Typography variant="body2" gutterBottom>{item.menuItemDescription}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={1} style={{ alignSelf: 'center' }}><Typography sx={{mb:0}} variant="body2" gutterBottom>{this.state.currency}{item.menuItemPrice.toFixed(2)}</Typography></Grid>
                                                                <Grid item xs={2} style={{ alignSelf: 'center', textAlign: 'center', fontSize: '23px' }}>{item.cateringOrderItemQuantity}</Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                })}
                                            </Card>
                                        </Grid>
                                        <Grid item md={5} xl={4}>
                                            <Card className="ml-2">
                                                <Box sx={{p:4}}>
                                                    <Typography variant="h5" gutterBottom>{this.labels.HublabelSummary}</Typography>
                                                    <Card style={{ backgroundColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiBackGround : customTheme.darkTheme.uiBackGround }}>
                                                    <Box component="div" sx={{ m:2 }}>
                                                        <Typography variant="overline" fontSize={14} display="block" gutterBottom style={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>{this.state.bookingName}</Typography>
                                                        <Typography sx={{fontSize:24,fontWeight:500}} gutterBottom>{this.getSpaceNameById(this.state.task.spaceId)}</Typography>
                                                        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                                                                <Box display="flex" gridColumn="span 1" gap={1}>
                                                                        <IbssSvgIcon fontSize="small" className="mr-1">{Icons.calenderIcon}</IbssSvgIcon>
                                                                        {DateTime.fromISO(this.state.task.cateringServiceTime).toLocaleString(DateTime.DATE_SHORT)}
                                                                </Box>
                                                                <Box display="flex" gridColumn="span 1" gap={1}>
                                                                        <IbssSvgIcon fontSize="small" className="mr-1">{Icons.TimeIcon}</IbssSvgIcon>
                                                                        {`${DateTime.fromISO(this.state.bookingStart.toString()).toFormat('HH:mm')} - ${DateTime.fromISO(this.state.bookingEnd.toString()).toFormat('HH:mm')}`}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                    <Box component="div" sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between'}}>
                                                        <Box style={{ alignSelf: 'center' }}>
                                                            <div>
                                                                {this.labels.HubLabelService}
                                                            </div>
                                                            <Typography variant="h4" gutterBottom> {serviceTime}</Typography>
                                                        </Box>
                                                        <Box style={{ alignSelf: 'center' }}
                                                            className="icon-text-inline mt-3"
                                                            sx={{padding: "10px 22px", }}>
                                                            <img src={`/images/Sidebar_Icons/${this.appState.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                                        </Box>
                                                        <Box style={{ alignSelf: 'center' }}>
                                                            <div>
                                                                {this.labels.HubLabelCleanUp}
                                                            </div>
                                                            <Typography variant="h4" gutterBottom>{cleanUpTime}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <div className="d-flex">
                                                        <IbssSvgIcon fontSize="small" className="mr-1">{Icons.TimerIcon}</IbssSvgIcon>
                                                        <Typography gutterBottom>
                                                            {`${this.labels.HubLabelCutOffTime}: ${formattedDate}, ${calculatedHour}:${calculatedMinutes}`}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                    <TextField
                                                     multiline
                                                     color="secondary"
                                                            placeholder={this.labels.HubLabelAddNotes}
                                                            value={this.state.task.cateringNotes}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                            fullWidth
                                                            minRows={3}
                                                            disabled
                                                        />
                                                    </div>
                                                    <Box className="mt-1" sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                                        <Typography variant="overline" fontSize={14} display="block" gutterBottom> {`${this.state.orderItems?.map(x => parseInt(x.cateringOrderItemQuantity)).reduce((x, y) => { return x + y }, 0)} ${this.labels.HubLabelItems}`}</Typography>
                                                        <Typography variant="h3" display="block" gutterBottom>{`${this.state.currency}${this.state.orderItems?.map(x => x.menuItemPrice * parseInt(x.cateringOrderItemQuantity)).reduce((x, y) => { return x + y }, 0).toFixed(2)}`}</Typography>
                                                    </Box>
                                                    <Box style={{ padding: '10px' }}>
                                                        <div>
                                                            <Grid container style={{ height: '60px' }}>
                                                                <Grid item xs={1} style={{ alignSelf: 'center' }}>
                                                                    <div style={{ marginTop: '10px' }}>
                                                                        <IbssSvgIcon fontSize="small" className="mr-1">{Icons.CostCodeIcon}</IbssSvgIcon>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={10} style={{ alignSelf: 'center' }}>
                                                                    <div>{this.state.task?.costCodeAllocation?.map(x => x.costCode).toString().replaceAll(',', ', ') ?? []}</div>
                                                                </Grid>
                                                                <Grid item xs={1} style={{ alignSelf: 'center' }}> <SvgIcon component={RightArrowIcon} inheritViewBox sx={{ color: (theme) => theme.palette.text.primary }} /></Grid>
                                                            </Grid>
                                                            <hr className="m-0"/>
                                                        </div>
                                                    </Box>
                                                    
                                                </Box>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                                <IbssDialog
                                    open={this.state.showMessageModal}
                                    onClose={() => this.setState({ showMessageModal: false })}
                                    fullWidth
                                    header={this.labels.HubLabelMessageBox}
                                    dialogContent=
                                    {
                                        <>
                                            <label>{this.labels.HubLabelMessage}</label>
                                            <IbssTextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                type="text"
                                                name="message"
                                                className="mt-1"
                                                fullWidth
                                                value={this.state.cancelMessage}
                                                onChange={(e) => this.setState({ cancelMessage: e.target.value })}
                                            />
                                        </>
                                    }
                                    footer=
                                    {
                                        <>
                                            <IbssButtonRedo
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() => this.setState({ showMessageModal: false })}
                                            >
                                                {this.labels.HubButtonCancel}
                                            </IbssButtonRedo>
                                            <IbssButtonRedo
                                                color='primary'
                                                variant='contained'
                                                onClick={() => this.cancelTask()}
                                            >
                                                {this.labels.HubLabelOk}
                                            </IbssButtonRedo>
                                        </>
                                    }
                                />
                            
                        </div>
                }
            </>
        );
    }
}

export default ViewCateringOrder;

export interface IProps extends RouteComponentProps<IQueryParams>, IPropsFromState
{
}

export interface IQueryParams
{
    spacecateringId: string;
}

export interface IState
{
    task: ITask;
    orderItems: ITaskOrderItems[];
    loading: boolean;
    currency: string;
    preBookCateringTime: string;
    bookingStart: DateTime;
    bookingEnd: DateTime;
    showMessageModal: boolean;
    cancelMessage: string;
    bookingName: string;
    spaces: Space[];
}

export interface ITask
{
    nodeId: number;
    bookingId: string;
    taskId: number;
    taskTypeName: string;
    createdAt: string;
    taskCategoryName: string;
    spaceId: string;
    taskPriority: number;
    taskDueDate: string;
    taskOwner: string;
    taskEventTime: string;
    taskCreatedBy:string
    taskStatus: string;
    taskIsActive: number;
    taskIsOwned: number;
    cateringServiceTime: string;
    cateringClearingTime: string;
    cateringAttendees: number;
    cateringNotes: string;
    costCodeAllocation: ICostAllocation[];
    taskTTC: number;
}

export interface ICostAllocation
{
    costCode: string,
    costCodeId: string,
    allocation: number
}

export interface ITaskOrderItems 
{
    imageUri: string;
    menuItemId: number;
    menuItemTitle: string;
    menuItemDescription: string;
    menuItemSection: string;
    menuItemPrice: number;
    nodeId: number;
    cateringOrderId: number;
    spaceId: string;
    cateringOrderItemId: string;
    cateringOrderItemQuantity: string;
}
