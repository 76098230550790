import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringOrderPolicy } from "./CateringOrderPoliciesRepository";


export class GetOrderPolicyById implements IGetByIdEndpoint
{
    public async execute(buildingId: number,policyId:string): Promise<ICateringOrderPolicy>
    {
        try
        {
            const query = `$select=Node_Id,OrderPolicy_Id,OrderPolicy_Ref,Description,AdvanceNotice,PreparationTime_Mins,Kitchen_OperatingHours_Start,Kitchen_OperatingHours_Stop,Name,ConcurrencyStamp,OwnedByUserId,OwnedByRoleId,CreatedByUserId,ModifiedByUserId,TenancyId`;

            const response = await axios.get<ICateringOrderPolicy>(`${apis.allApiv2}${buildingId}/catering-order-policy/${policyId}?${query}`);

            return response.data;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByIdEndpoint
{
    execute(buildingId: number,policyId:string): Promise<ICateringOrderPolicy>;
}