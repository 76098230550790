import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ApiHelper } from "../ApiHelper";

export class GetTaskEndpoint implements IGetTaskEndpoint
{
    public async execute<TTask>(tTask: new() => TTask,nodeId: number, taskId: string): Promise<TTask>
    {
        const query = `$select=${ApiHelper.toODataSelect(tTask)}`;
        try
        {
            const response: AxiosResponse<TTask> = await axios.get(`${apis.allApi}${nodeId}/Tasks/${taskId}?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetTaskEndpoint
{
    execute<TTask>(tTask: new() => TTask,nodeId: number, taskId: string): Promise<TTask>;
}
