import { Box, Typography } from "@mui/material";
import React from "react";


function FormPanelInfo(props: any) {
    const {iconSrc, formHeading, formDescription, style } = props

    return (
        <div className="form-panel-info" style={style}>
            <Box className="form-panel-heading">
                <Box className="form-panel-icon" mr={1}>
                    <img src={iconSrc} alt=""/>
                </Box>
                <Typography variant="h5" className="form-panel-header">{formHeading}</Typography>
            </Box>
            <Typography variant="body2" className="form-panel-description">
                {formDescription}
            </Typography>
        </div>
    )
}


export default FormPanelInfo;