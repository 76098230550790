import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { Filter, IMenuItems } from "./CateringMenuRepository";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(nodeId: number, expandMenuItems: boolean, expandMenuItemRestrictions: boolean, top: number, filter: Filter): Promise<PagedResponse<ICateringMenu[]>>
    {
        try
        {
            let expand = '';
            if(expandMenuItems)
            {
                expand = '$expand=Menu_MenuItems($expand=MenuItem)';
            }
            if(expandMenuItemRestrictions)
            {
                expand = '$expand=Menu_MenuItems($expand=MenuItem($expand=MenuItem_Restrictions($expand=Restriction)))';
            }
            const query =
                `$top=${top}&${expand}&$filter=${filter.toODataString()}`;
            const response = await axios.get<IRawPagedResponse<ICateringMenu[]>>(`${apis.allApiv2}${nodeId}/catering-menu?${query}`);
            response.data.Value.forEach(i =>
            {
                if(i.Available_From)
                {
                    i.Available_From = DateHelper.fromIso(i.Available_From as unknown as string);
                }
                if(i.Available_Until)
                {
                    i.Available_Until = DateHelper.fromIso(i.Available_Until as unknown as string);
                }
                
            })
            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(nodeId: number, expandMenuItems: boolean, expandMenuItemRestrictions: boolean, top: number, filter: Filter): Promise<PagedResponse<ICateringMenu[]>>;
}

export interface ICateringMenu
{
    Node_Id: number;
    Menu_Id: string;
    Name: string;
    Description: string;
    Short_Description: string;
    ImageURI: string;
    Status: string;
    ConcurrencyStamp: string;
    Available_From: DateTime;
    Available_Until: DateTime;
    Menu_MenuItems: IMenuItems[];
}

