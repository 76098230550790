import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DenyVisitsV2Endpoint implements IDenyVisitsV2Endpoint
{
    public async execute(nodeId: number, visits: IDenyVisitV2[]): Promise<void>
    {
        try
        {
            const response: AxiosResponse<void> = await axios.post(`${apis.allApiv2}${nodeId}/visits/deny`, visits);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDenyVisitsV2Endpoint
{
    execute(nodeId: number, visits: IDenyVisitV2[]): Promise<void>;
}

export interface IDenyVisitV2
{
    Visit_Id: string;
    Visit_Denial_Comment: string;
}