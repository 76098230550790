import { IFilter } from "../ODataFilter";
import { ICreateNotificationEndpoint, ICreateNotification } from "./CreateNotificationEndpoint";
import { IDeleteNotificationEndpoint } from "./DeleteNotificationEndpoint";
import { IEditNotificationEndpoint } from "./EditNotificationEndpoint";
import { IGetActiveNotificationEndpoint, INotification } from "./GetActiveNotificationEndpoint";
import { IGetNotificationByIdEndpoint } from "./GetNotificationByIdEndpoint";
import { IGetNotificationsEndpoint } from "./GetNotificationsEndpoint";

export class NotificationRepository implements INotificationRepository
{
    private getActiveNotificationEndpoint: IGetActiveNotificationEndpoint;
    private createNotificationEndpoint: ICreateNotificationEndpoint;
    private getNotificationByIdEndpoint: IGetNotificationByIdEndpoint;
    private editNotificationEndpoint: IEditNotificationEndpoint;
    private getNotificationsEndpoint: IGetNotificationsEndpoint;
    private deleteNotificationEndpoint: IDeleteNotificationEndpoint;

    constructor
    (
        getActiveNotificationEndpoint: IGetActiveNotificationEndpoint,
        createNotificationEndpoint: ICreateNotificationEndpoint,
        getNotificationByIdEndpoint: IGetNotificationByIdEndpoint,
        editNotificationEndpoint: IEditNotificationEndpoint,
        getNotificationsEndpoint: IGetNotificationsEndpoint,
        deleteNotificationEndpoint: IDeleteNotificationEndpoint
    )

    {
        this.getActiveNotificationEndpoint = getActiveNotificationEndpoint;
        this.createNotificationEndpoint = createNotificationEndpoint;
        this.getNotificationByIdEndpoint = getNotificationByIdEndpoint;
        this.editNotificationEndpoint = editNotificationEndpoint;
        this.getNotificationsEndpoint = getNotificationsEndpoint;
        this.deleteNotificationEndpoint = deleteNotificationEndpoint;
    }

    public async getMany(nodeId: number, startDate: string, endDate: string, filter: NotificationsFilter): Promise<INotification[]>
    {
        return this.getNotificationsEndpoint.execute(nodeId, startDate, endDate, filter);
    }

    public async getActiveNotification(nodeId: number): Promise<INotification[]>
    {
        return this.getActiveNotificationEndpoint.execute(nodeId);
    }

    public async getById(nodeId: number, notificationId: string): Promise<INotification>
    {
        return this.getNotificationByIdEndpoint.execute(nodeId, notificationId);
    }

    public async create(nodeId: number, payload: ICreateNotification): Promise<void>
    {
        return this.createNotificationEndpoint.execute(nodeId, payload);
    }

    public async edit(nodeId: number, notificationId: number, payload: ICreateNotification): Promise<void>
    {
        return this.editNotificationEndpoint.execute(nodeId, notificationId, payload);
    }

    public async delete(nodeId: number, notificationId: string): Promise<void>
    {
        return this.deleteNotificationEndpoint.execute(nodeId, notificationId);
    }
}

export interface INotificationRepository
{
    getActiveNotification(nodeId: number): Promise<INotification[]>;
    create(nodeId: number, payload: ICreateNotification): Promise<void>;
    getById(nodeId: number, notificationId: string): Promise<INotification>;
    edit(nodeId: number, notificationId: number, payload: ICreateNotification): Promise<void>;
    getMany(nodeId: number, startDate: string, endDate: string, filter: NotificationsFilter): Promise<INotification[]>;
    delete(nodeId: number, notificationId: string): Promise<void>;
}

export class NotificationsFilter implements IFilter
{
    public classification = "";

    constructor(value: Partial<NotificationsFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.classification ? `Classification eq '${this.classification}'` : "");
        return filterParts.filter(i => i !== "").join(" and ");
    }

}