import { IApiMessages } from "./IApiMessages";
import { PoEditorParser } from "./PoEditorParser";

export default class ApiMessages implements IApiMessages
{
    private parser = new PoEditorParser();
    private simple(term: string) { return this.parser.simple(term); }
    private plural(term: string, size: number) { return this.parser.plural(term, size); }

    private constructor() { }

    public static async create(): Promise<IApiMessages>
    {
        const apiMessages = new ApiMessages();
        await apiMessages.parser.read("ApiMessages.json");
        return apiMessages;
    }

    public ﻿Not_Bookable_Title = "";
    public get Already_Booked_Title() { return this.simple("Already_Booked_Title"); }
    public get Invalid_Node_ID_Title() { return this.simple("Invalid_Node_ID_Title"); }
    public get CheckedIn_Booking_Start_Amend_Title() { return this.simple("CheckedIn_Booking_Start_Amend_Title"); }
    public get Booking_Outside_Hours_Title() { return this.simple("Booking_Outside_Hours_Title"); }
    public get Booking_Invalid_Times_Title() { return this.simple("Booking_Invalid_Times_Title"); }
    public get Booking_Invalid_Time_Intervals_Title() { return this.simple("Booking_Invalid_Time_Intervals_Title"); }
    public get Booking_Invalid_Dates_Title() { return this.simple("Booking_Invalid_Dates_Title"); }
    public get Booking_Invalid_Dates_Closed_Title() { return this.simple("Booking_Invalid_Dates_Closed_Title"); }
    public get Booking_Invalid_Day_Title() { return this.simple("Booking_Invalid_Day_Title"); }
    public get Booking_Invalid_Dates_Below_Min_Horizon_Title() { return this.simple("Booking_Invalid_Dates_Below_Min_Horizon_Title"); }
    public get Booking_Invalid_Dates_Horizon_Max_Exceeded_Title() { return this.simple("Booking_Invalid_Dates_Horizon_Max_Exceeded_Title"); }
    public get Booking_Invalid_Duration_Too_Long_Title() { return this.simple("Booking_Invalid_Duration_Too_Long_Title"); }
    public get Booking_Invalid_Duration_Too_Short_Title() { return this.simple("Booking_Invalid_Duration_Too_Short_Title"); }
    public get Booking_Invalid_Title_Length_Title() { return this.simple("Booking_Invalid_Title_Length_Title"); }
    public get Import_Max_Error_Title() { return this.simple("Import_Max_Error_Title"); }
    public get Booking_Invalid_OnBehalfOfEmail_Title() { return this.simple("Booking_Invalid_OnBehalfOfEmail_Title"); }
    public get Booking_Invalid_Party_Visitor_Title() { return this.simple("Booking_Invalid_Party_Visitor_Title"); }
    public get Import_Error_Title() { return this.simple("Import_Error_Title"); }
    public get Booking_Invalid_Party_Internal_Title() { return this.simple("Booking_Invalid_Party_Internal_Title"); }
    public get Booking_Parties_Warning_Title() { return this.simple("Booking_Parties_Warning_Title"); }
    public get Booking_Admin_Bookable_Only_Title() { return this.simple("Booking_Admin_Bookable_Only_Title"); }
    public get Booking_Space_Capacity_Exceeded_Title() { return this.simple("Booking_Space_Capacity_Exceeded_Title"); }
    public get Booking_Linked_Space_Not_Allowed_Title() { return this.simple("Booking_Linked_Space_Not_Allowed_Title"); }
    public get Booking_Invalid_Linked_Space_Title() { return this.simple("Booking_Invalid_Linked_Space_Title"); }
    public get Space_Not_Found_Title() { return this.simple("Space_Not_Found_Title"); }
    public get Space_Out_Of_Service_Title() { return this.simple("Space_Out_Of_Service_Title"); }
    public get Booking_Invalid_Horizon_Title() { return this.simple("Booking_Invalid_Horizon_Title"); }
    public get Booking_Missing_Values_Title() { return this.simple("Booking_Missing_Values_Title"); }
    public get Booking_Allowed_Exceeded_Title() { return this.simple("Booking_Allowed_Exceeded_Title"); }
    public get Booking_Same_Class_Space_Overlap_Title() { return this.simple("Booking_Same_Class_Space_Overlap_Title"); }
    public get Booking_V2_Access_Denied_Title() { return this.simple("Booking_V2_Access_Denied_Title"); }
    public get Booking_No_Policy_Title() { return this.simple("Booking_No_Policy_Title"); }
    public get Cost_Code_Invalid_Title() { return this.simple("Cost_Code_Invalid_Title"); }
    public get Cost_Code_Allocation_Invalid_Title() { return this.simple("Cost_Code_Allocation_Invalid_Title"); }
    public get Lockers_No_Auth_Service_Title() { return this.simple("Lockers_No_Auth_Service_Title"); }
    public get Lockers_Invalid_Auth_Token_Title() { return this.simple("Lockers_Invalid_Auth_Token_Title"); }
    public get Lockers_System_Unauthorised_Title() { return this.simple("Lockers_System_Unauthorised_Title"); }
    public get Lockers_User_Unauthorised_Title() { return this.simple("Lockers_User_Unauthorised_Title"); }
    public get Lockers_LockerBanks_Failure_Title() { return this.simple("Lockers_LockerBanks_Failure_Title"); }
    public get Lockers_LockerBanks_Incompatible_Title() { return this.simple("Lockers_LockerBanks_Incompatible_Title"); }
    public get Lockers_LockerBanks_Error_Title() { return this.simple("Lockers_LockerBanks_Error_Title"); }
    public get Lockers_LockerBank_Lockers_Failure_Title() { return this.simple("Lockers_LockerBank_Lockers_Failure_Title"); }
    public get Lockers_LockerBanks_None_Title() { return this.simple("Lockers_LockerBanks_None_Title"); }
    public get Lockers_Available_Lockers_Failure_Title() { return this.simple("Lockers_Available_Lockers_Failure_Title"); }
    public get Lockers_Available_Lockers_None_Title() { return this.simple("Lockers_Available_Lockers_None_Title"); }
    public get Lockers_Available_Lockers_Incompatible_Title() { return this.simple("Lockers_Available_Lockers_Incompatible_Title"); }
    public get Lockers_Available_Lockers_Error_Title() { return this.simple("Lockers_Available_Lockers_Error_Title"); }
    public get Lockers_No_User_Title() { return this.simple("Lockers_No_User_Title"); }
    public get Lockers_Allocated_Lockers_Failure_Title() { return this.simple("Lockers_Allocated_Lockers_Failure_Title"); }
    public get Lockers_Allocated_Lockers_Incompatible_Title() { return this.simple("Lockers_Allocated_Lockers_Incompatible_Title"); }
    public get Lockers_Allocated_Lockers_None_Title() { return this.simple("Lockers_Allocated_Lockers_None_Title"); }
    public get Lockers_Allocated_Lockers_Error_Title() { return this.simple("Lockers_Allocated_Lockers_Error_Title"); }
    public get Lockers_Allocate_Locker_Failure_Title() { return this.simple("Lockers_Allocate_Locker_Failure_Title"); }
    public get Lockers_Allocate_Locker_Forbidden_Title() { return this.simple("Lockers_Allocate_Locker_Forbidden_Title"); }
    public get Lockers_Allocate_Locker_Incompatible_Title() { return this.simple("Lockers_Allocate_Locker_Incompatible_Title"); }
    public get Lockers_Release_Locker_Failure_Title() { return this.simple("Lockers_Release_Locker_Failure_Title"); }
    public get Lockers_Release_Locker_Forbidden_Title() { return this.simple("Lockers_Release_Locker_Forbidden_Title"); }
    public get Lockers_Release_Locker_Incompatible_Title() { return this.simple("Lockers_Release_Locker_Incompatible_Title"); }
    public get IBSS_Request_Failed_Title() { return this.simple("IBSS_Request_Failed_Title"); }
    public get IBSS_Request_Unauthorised_Title() { return this.simple("IBSS_Request_Unauthorised_Title"); }
    public get IBSS_Request_Not_Found_Title() { return this.simple("IBSS_Request_Not_Found_Title"); }
    public get IBSS_Request_No_Content_Title() { return this.simple("IBSS_Request_No_Content_Title"); }
    public get IBSS_Request_Spaces_Incompatible_Title() { return this.simple("IBSS_Request_Spaces_Incompatible_Title"); }
    public get IBSS_Request_Succeeded_Title() { return this.simple("IBSS_Request_Succeeded_Title"); }
    public get IBSS_Request_Exception_Title() { return this.simple("IBSS_Request_Exception_Title"); }
    public get IBSS_Request_Location_Incompatible_Title() { return this.simple("IBSS_Request_Location_Incompatible_Title"); }
    public get IBSS_Internal_Request_Unauthorised_Title() { return this.simple("IBSS_Internal_Request_Unauthorised_Title"); }
    public get System_Request_Unauthorised_Title() { return this.simple("System_Request_Unauthorised_Title"); }
    public get Irisys_Payload_Deserialise_Failed_Title() { return this.simple("Irisys_Payload_Deserialise_Failed_Title"); }
    public get Irisys_Payload_NoCountLog_Title() { return this.simple("Irisys_Payload_NoCountLog_Title"); }
    public get Irisys_Payload_NoCounters_Title() { return this.simple("Irisys_Payload_NoCounters_Title"); }
    public get Irisys_Counter_NoTag_Title() { return this.simple("Irisys_Counter_NoTag_Title"); }
    public get Irisys_Counter_NotNewer_Title() { return this.simple("Irisys_Counter_NotNewer_Title"); }
    public get Irisys_Counter_UnmatchedSensor_Title() { return this.simple("Irisys_Counter_UnmatchedSensor_Title"); }
    public get Irisys_Error_Title() { return this.simple("Irisys_Error_Title"); }
    public get Invalid_Booking_Owner_Email_Title() { return this.simple("Invalid_Booking_Owner_Email_Title"); }
    public get Import_Visit_InvalidDates_Title() { return this.simple("Import_Visit_InvalidDates_Title"); }
    public get Import_Visit_FirstName_LastName_Missing_Title() { return this.simple("Import_Visit_FirstName_LastName_Missing_Title"); }
    public get Import_Visit_Email_Missing_Title() { return this.simple("Import_Visit_Email_Missing_Title"); }
    public get Import_Visit_Host_Email_Missing_Title() { return this.simple("Import_Visit_Host_Email_Missing_Title"); }
    public get Import_Visit_Host_Name_Missing_Title() { return this.simple("Import_Visit_Host_Name_Missing_Title"); }
    public get Import_Visit_Node_Id_Missing_Title() { return this.simple("Import_Visit_Node_Id_Missing_Title"); }
    public get Not_Bookable_Message() { return this.simple("Not_Bookable_Message"); }
    public get Already_Booked_Message() { return this.simple("Already_Booked_Message"); }
    public get Invalid_Node_ID_Message() { return this.simple("Invalid_Node_ID_Message"); }
    public get CheckedIn_Booking_Start_Amend_Message() { return this.simple("CheckedIn_Booking_Start_Amend_Message"); }
    public get Booking_Outside_Hours_Message() { return this.simple("Booking_Outside_Hours_Message"); }
    public get Booking_Invalid_Times_Message() { return this.simple("Booking_Invalid_Times_Message"); }
    public get Booking_Invalid_Time_Intervals_Message() { return this.simple("Booking_Invalid_Time_Intervals_Message"); }
    public get Booking_Invalid_Dates_Message() { return this.simple("Booking_Invalid_Dates_Message"); }
    public get Booking_Invalid_Dates_Closed_Message() { return this.simple("Booking_Invalid_Dates_Closed_Message"); }
    public get Booking_Invalid_Day_Message() { return this.simple("Booking_Invalid_Day_Message"); }
    public get Booking_Invalid_Dates_Below_Min_Horizon_Message() { return this.simple("Booking_Invalid_Dates_Below_Min_Horizon_Message"); }
    public get Booking_Invalid_Dates_Horizon_Max_Exceeded_Message() { return this.simple("Booking_Invalid_Dates_Horizon_Max_Exceeded_Message"); }
    public get Booking_Invalid_Duration_Too_Long_Message() { return this.simple("Booking_Invalid_Duration_Too_Long_Message"); }
    public get Booking_Invalid_Duration_Too_Short_Message() { return this.simple("Booking_Invalid_Duration_Too_Short_Message"); }
    public get Booking_Invalid_Title_Length_Message() { return this.simple("Booking_Invalid_Title_Length_Message"); }
    public get Import_Max_Error_Message() { return this.simple("Import_Max_Error_Message"); }
    public get Booking_Invalid_OnBehalfOfEmail_Message() { return this.simple("Booking_Invalid_OnBehalfOfEmail_Message"); }
    public get Booking_Invalid_Party_Visitor_Message() { return this.simple("Booking_Invalid_Party_Visitor_Message"); }
    public get Import_Error_Message() { return this.simple("Import_Error_Message"); }
    public get Booking_Invalid_Party_Internal_Message() { return this.simple("Booking_Invalid_Party_Internal_Message"); }
    public get Booking_Parties_Warning_Message() { return this.simple("Booking_Parties_Warning_Message"); }
    public get Booking_Admin_Bookable_Only_Message() { return this.simple("Booking_Admin_Bookable_Only_Message"); }
    public get Booking_Space_Capacity_Exceeded_Message() { return this.simple("Booking_Space_Capacity_Exceeded_Message"); }
    public get Booking_Linked_Space_Not_Allowed_Message() { return this.simple("Booking_Linked_Space_Not_Allowed_Message"); }
    public get Booking_Invalid_Linked_Space_Message() { return this.simple("Booking_Invalid_Linked_Space_Message"); }
    public get Space_Not_Found_Message() { return this.simple("Space_Not_Found_Message"); }
    public get Space_Out_Of_Service_Message() { return this.simple("Space_Out_Of_Service_Message"); }
    public get Booking_Invalid_Horizon_Message() { return this.simple("Booking_Invalid_Horizon_Message"); }
    public get Booking_Missing_Values_Message() { return this.simple("Booking_Missing_Values_Message"); }
    public get Booking_Allowed_Exceeded_Message() { return this.simple("Booking_Allowed_Exceeded_Message"); }
    public get Booking_Same_Class_Space_Overlap_Message() { return this.simple("Booking_Same_Class_Space_Overlap_Message"); }
    public get Booking_V2_Access_Denied_Message() { return this.simple("Booking_V2_Access_Denied_Message"); }
    public get Booking_No_Policy_Message() { return this.simple("Booking_No_Policy_Message"); }
    public get Cost_Code_Invalid_Message() { return this.simple("Cost_Code_Invalid_Message"); }
    public get Cost_Code_Allocation_Invalid_Message() { return this.simple("Cost_Code_Allocation_Invalid_Message"); }
    public get Lockers_No_Auth_Service_Message() { return this.simple("Lockers_No_Auth_Service_Message"); }
    public get Lockers_Invalid_Auth_Token_Message() { return this.simple("Lockers_Invalid_Auth_Token_Message"); }
    public get Lockers_System_Unauthorised_Message() { return this.simple("Lockers_System_Unauthorised_Message"); }
    public get Lockers_User_Unauthorised_Message() { return this.simple("Lockers_User_Unauthorised_Message"); }
    public get Lockers_LockerBanks_Failure_Message() { return this.simple("Lockers_LockerBanks_Failure_Message"); }
    public get Lockers_LockerBanks_Incompatible_Message() { return this.simple("Lockers_LockerBanks_Incompatible_Message"); }
    public get Lockers_LockerBanks_Error_Message() { return this.simple("Lockers_LockerBanks_Error_Message"); }
    public get Lockers_LockerBank_Lockers_Failure_Message() { return this.simple("Lockers_LockerBank_Lockers_Failure_Message"); }
    public get Lockers_LockerBanks_None_Message() { return this.simple("Lockers_LockerBanks_None_Message"); }
    public get Lockers_Available_Lockers_Failure_Message() { return this.simple("Lockers_Available_Lockers_Failure_Message"); }
    public get Lockers_Available_Lockers_None_Message() { return this.simple("Lockers_Available_Lockers_None_Message"); }
    public get Lockers_Available_Lockers_Incompatible_Message() { return this.simple("Lockers_Available_Lockers_Incompatible_Message"); }
    public get Lockers_Available_Lockers_Error_Message() { return this.simple("Lockers_Available_Lockers_Error_Message"); }
    public get Lockers_No_User_Message() { return this.simple("Lockers_No_User_Message"); }
    public get Lockers_Allocated_Lockers_Failure_Message() { return this.simple("Lockers_Allocated_Lockers_Failure_Message"); }
    public get Lockers_Allocated_Lockers_None_Message() { return this.simple("Lockers_Allocated_Lockers_None_Message"); }
    public get Lockers_Allocated_Lockers_Incompatible_Message() { return this.simple("Lockers_Allocated_Lockers_Incompatible_Message"); }
    public get Lockers_Allocated_Lockers_Error_Message() { return this.simple("Lockers_Allocated_Lockers_Error_Message"); }
    public get Lockers_Allocate_Locker_Failure_Message() { return this.simple("Lockers_Allocate_Locker_Failure_Message"); }
    public get Lockers_Allocate_Locker_Forbidden_Message() { return this.simple("Lockers_Allocate_Locker_Forbidden_Message"); }
    public get Lockers_Allocate_Locker_Incompatible_Message() { return this.simple("Lockers_Allocate_Locker_Incompatible_Message"); }
    public get Lockers_Release_Locker_Failure_Message() { return this.simple("Lockers_Release_Locker_Failure_Message"); }
    public get Lockers_Release_Locker_Forbidden_Message() { return this.simple("Lockers_Release_Locker_Forbidden_Message"); }
    public get Lockers_Release_Locker_Incompatible_Message() { return this.simple("Lockers_Release_Locker_Incompatible_Message"); }
    public get IBSS_Request_Failed_Message() { return this.simple("IBSS_Request_Failed_Message"); }
    public get IBSS_Request_Unauthorised_Message() { return this.simple("IBSS_Request_Unauthorised_Message"); }
    public get IBSS_Request_Not_Found_Message() { return this.simple("IBSS_Request_Not_Found_Message"); }
    public get IBSS_Request_No_Content_Message() { return this.simple("IBSS_Request_No_Content_Message"); }
    public get IBSS_Request_Spaces_Incompatible_Message() { return this.simple("IBSS_Request_Spaces_Incompatible_Message"); }
    public get IBSS_Request_Succeeded_Message() { return this.simple("IBSS_Request_Succeeded_Message"); }
    public get IBSS_Request_Exception_Message() { return this.simple("IBSS_Request_Exception_Message"); }
    public get IBSS_Request_Location_Incompatible_Message() { return this.simple("IBSS_Request_Location_Incompatible_Message"); }
    public get IBSS_Internal_Request_Unauthorised_Message() { return this.simple("IBSS_Internal_Request_Unauthorised_Message"); }
    public get System_Request_Unauthorised_Message() { return this.simple("System_Request_Unauthorised_Message"); }
    public get Irisys_Payload_Deserialise_Failed_Message() { return this.simple("Irisys_Payload_Deserialise_Failed_Message"); }
    public get Irisys_Payload_NoCountLog_Message() { return this.simple("Irisys_Payload_NoCountLog_Message"); }
    public get Irisys_Payload_NoCounters_Message() { return this.simple("Irisys_Payload_NoCounters_Message"); }
    public get Irisys_Counter_NoTag_Message() { return this.simple("Irisys_Counter_NoTag_Message"); }
    public get Irisys_Counter_NotNewer_Message() { return this.simple("Irisys_Counter_NotNewer_Message"); }
    public get Irisys_Counter_UnmatchedSensor_Message() { return this.simple("Irisys_Counter_UnmatchedSensor_Message"); }
    public get Irisys_Error_Message() { return this.simple("Irisys_Error_Message"); }
    public get Invalid_Booking_Owner_Email_Message() { return this.simple("Invalid_Booking_Owner_Email_Message"); }
    public get Import_Visit_InvalidDates_Message() { return this.simple("Import_Visit_InvalidDates_Message"); }
    public get Import_Visit_FirstName_LastName_Missing_Message() { return this.simple("Import_Visit_FirstName_LastName_Missing_Message"); }
    public get Import_Visit_Email_Missing_Message() { return this.simple("Import_Visit_Email_Missing_Message"); }
    public get Import_Visit_Host_Email_Missing_Message() { return this.simple("Import_Visit_Host_Email_Missing_Message"); }
    public get Import_Visit_Host_Name_Missing_Message() { return this.simple("Import_Visit_Host_Name_Missing_Message"); }
    public get Import_Visit_Node_Id_Missing_Message() { return this.simple("Import_Visit_Node_Id_Missing_Message"); }
    public get Booking_Not_Found_Title() { return this.simple("Booking_Not_Found_Title"); }
    public get Booking_Not_Found_Message() { return this.simple("Booking_Not_Found_Message"); }
    public get Visit_Not_Found_Title() { return this.simple("Visit_Not_Found_Title"); }
    public get Task_Not_Found_Title() { return this.simple("Task_Not_Found_Title"); }
    public get Catering_Order_Not_Found_Title() { return this.simple("Catering_Order_Not_Found_Title"); }
    public get Catering_Menu_Not_Found_Title() { return this.simple("Catering_Menu_Not_Found_Title"); }
    public get Catering_Menu_Item_Not_Found_Title() { return this.simple("Catering_Menu_Item_Not_Found_Title"); }
    public get Building_Not_Found_Title() { return this.simple("Building_Not_Found_Title"); }
    public get Floor_Not_Found_Title() { return this.simple("Floor_Not_Found_Title"); }
    public get Visit_Not_Found_Message() { return this.simple("Visit_Not_Found_Message"); }
    public get Task_Not_Found_Message() { return this.simple("Task_Not_Found_Message"); }
    public get Catering_Order_Not_Found_Message() { return this.simple("Catering_Order_Not_Found_Message"); }
    public get Catering_Menu_Not_Found_Message() { return this.simple("Catering_Menu_Not_Found_Message"); }
    public get Catering_Menu_Item_Not_Found_Message() { return this.simple("Catering_Menu_Item_Not_Found_Message"); }
    public get Building_Not_Found_Message() { return this.simple("Building_Not_Found_Message"); }
    public get Floor_Not_Found_Message() { return this.simple("Floor_Not_Found_Message"); }
    public get Booking_Party_Not_Found_Title() { return this.simple("Booking_Party_Not_Found_Title"); }
    public get Building_Kpi_Not_Found_Title() { return this.simple("Building_Kpi_Not_Found_Title"); }
    public get Connector_Not_Found_Title() { return this.simple("Connector_Not_Found_Title"); }
    public get Device_Not_Found_Title() { return this.simple("Device_Not_Found_Title"); }
    public get Equipment_Not_Found_Title() { return this.simple("Equipment_Not_Found_Title"); }
    public get Parameter_Not_Found_Title() { return this.simple("Parameter_Not_Found_Title"); }
    public get Cost_Code_Not_Found_Title() { return this.simple("Cost_Code_Not_Found_Title"); }
    public get Booking_Party_Not_Found_Message() { return this.simple("Booking_Party_Not_Found_Message"); }
    public get Building_Kpi_Not_Found_Message() { return this.simple("Building_Kpi_Not_Found_Message"); }
    public get Connector_Not_Found_Message() { return this.simple("Connector_Not_Found_Message"); }
    public get Device_Not_Found_Message() { return this.simple("Device_Not_Found_Message"); }
    public get Equipment_Not_Found_Message() { return this.simple("Equipment_Not_Found_Message"); }
    public get Parameter_Not_Found_Message() { return this.simple("Parameter_Not_Found_Message"); }
    public get Cost_Code_Not_Found_Message() { return this.simple("Cost_Code_Not_Found_Message"); }
}