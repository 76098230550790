import { Descendant, createEditor } from "slate";
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import './Styles.css';
import '../../Inputs/MarkdownEditor/Styles.css';
import { Helper } from "../../Inputs/MarkdownEditor/Helper";

export default class MarkdownViewer extends IbssComponent<IProps, IState>
{
    private editor = withReact(createEditor());
    private helper = new Helper(this.editor);

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            ready: false,
            value: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const valueAsSlate = await this.helper.fromMarkdown(this.props.value);
        await this.setValue(valueAsSlate);
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (JSON.stringify(this.props.value) != JSON.stringify(prevProps.value))
        {
            const valueAsSlate = await this.helper.fromMarkdown(this.props.value);
            await this.setValue(valueAsSlate);
        }
    }

    private async setValue(value: Descendant[]): Promise<void>
    {
        await this.setStateAsync({ ready: false, value: value });
        this.forceUpdate();
        await this.setStateAsync({ ready: true });
    }

    private handleRenderLeaf(props: RenderLeafProps): JSX.Element
    {
        return this.helper.renderLeaf(props, true);
    }

    private renderElement(props: RenderElementProps): JSX.Element
    {
        return this.helper.renderElement(props, true);
    }

    public render(): JSX.Element
    {
        const { style, } = this.props;
        const { ready, value } = this.state;

        return (
            <div style={style} className="markdown-viewer">
                {ready &&
                    <Slate
                        editor={this.editor}
                        initialValue={value}
                    >
                        <Editable
                            readOnly={true}
                            renderLeaf={props => this.handleRenderLeaf(props)}
                            renderElement={props => this.renderElement(props)}
                            className="markdown-editor__editor"
                            spellCheck={false}
                        />
                    </Slate>
                }
            </div>
        );
    }
}

export interface IProps
{
    style?: React.CSSProperties;
    value: string;
}

export interface IState
{
    ready: boolean;
    value: Descendant[];
}
