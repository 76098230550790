import { Grid } from '@mui/material';
import { Component } from 'react';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IbssLuxonDateTimePicker } from '../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class InspireAnnouncementForm extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    public render(): JSX.Element
    {
        return (
            <div style={{ display: 'block' }}>
                <Grid rowSpacing={4} container>
                    <Grid item xs={12}>
                        <div className='mb-1 mt-1 field-heading'>{this.labels.funcAnnouncementsEnterTitleScrollingMessage_L}</div>
                        <div className='mb-1 mt-1 field-heading field-message'>{this.labels.funcAnnouncementsTitleInfoHeading_Message}</div>
                        <Grid item xs={12}>
                            <IbssFormControl sx={{ width: '100%' }}>
                                <IbssTextField
                                    fullWidth
                                    label={this.labels.funcAnnouncementTitle_S}
                                    helperText={`${this.labels.funcAnnouncementCharacterLimit_S}: ${this.props.titleValue.length}/50`}
                                    onChange={(e) => this.props.onTitleChanged(e.target.value)}
                                    value={this.props.titleValue}
                                    inputProps={{ maxLength: 50 }}
                                    error={this.props.showFieldErrors && this.props.titleError}
                                />
                            </IbssFormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <IbssFormControl sx={{ width: '100%' }} className='mt-1'>
                                <IbssTextField
                                    fullWidth
                                    label={this.labels.funcAnnouncementsScrollingMessage_L}
                                    onChange={(e) => this.props.onScrollingMessageChanged(e.target.value)}
                                    value={this.props.scrollingMessage}
                                    helperText={`${this.labels.funcAnnouncementCharacterLimit_S}: ${this.props.scrollingMessage.length}/250`}
                                    inputProps={{ maxLength: 250 }}
                                />
                            </IbssFormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <hr />
                        <div className='mb-1 field-heading'>{this.labels.funcAnnouncementsMessageSchedule_S}</div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                            <IbssFormControl className='mr-3' style={{ width: '49%' }}>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.funcAnnouncementsActiveStart_S}
                                    value={this.props.activeDateTime}
                                    onChange={e => this.props.onActiveDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.props.showFieldErrors && this.props.dateTimeFieldError,
                                        helperText: this.props.showFieldErrors && this.props.dateTimeFieldError ? this.labels.funcAnnouncementStartError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                            <IbssFormControl style={{ width: '49%' }}>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.funcAnnouncementsEndExpire_S}
                                    value={this.props.expireDateTime}
                                    onChange={e => this.props.onExpireDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.props.showFieldErrors && this.props.dateTimeFieldError,
                                        helperText: this.props.showFieldErrors && this.props.dateTimeFieldError ? this.labels.funcAnnouncementStartError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default InspireAnnouncementForm;

export interface IProps
{
    activeDateTime: DateTime;
    onActiveDateTimeChanged: (value: DateTime) => void;
    expireDateTime: DateTime;
    onExpireDateTimeChanged: (value: DateTime) => void;
    onTitleChanged: (value: string) => void;
    titleValue: string;
    onScrollingMessageChanged: (value: string) => void;
    scrollingMessage: string;
    showFieldErrors: boolean;
    titleError: boolean;
    dateTimeFieldError: boolean;
}

export interface IState
{
}