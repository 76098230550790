import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class EditV2VisitEndpoint implements IEditV2VisitEndpoint
{
    public async execute(nodeId: number, visitId: string, visit: IEditV2Visit): Promise<IEditV2VisitResponse>
    {
        try
        {
            const response: AxiosResponse<IEditV2VisitResponse> = await axios.put(`${apis.allApiv2}${nodeId}/Visits/${visitId}`, visit);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEditV2VisitEndpoint
{
    execute(nodeId: number, visitId: string, visit: IEditV2Visit): Promise<IEditV2VisitResponse>;
}

export interface IEditV2Visit
{
    Visitor_First_Name: string;
    Visitor_Last_Name: string;
    Visitor_Email: string;
    Visitor_Company: string;
    Visit_Host_Name: string;
    Visit_Host_Email: string;
    Visit_Start_Date: string;
    Visit_End_Date: string;
    Visit_Approval_Comments: string;
    Visitor_Dietary_Pref: number;
    Visit_Pacs_Id: string;
    Visit_Save_Info: number;
    Space_Id: string;
    Space_Name: string;
    Disable_Ext_Update: boolean;
  }

  export interface IEditV2VisitResponse
  {
    Visit_CheckIn_Time: string;
    Visit_CheckOut_Time: string;
    Visit_IsEarly_Checkin: number;
    Visit_IsLate_Checkin: number;
    Visit_Scheduled_Duration: number;
    Visit_Actual_Duration: number;
    Space_Id: string;
    Booking_Id: string;
    Booking_Start: string;
    Booking_End: string;
    Node_Id: number;
    Space_Name: string;
    Visit_Id: string;
    Visitor_First_Name: string;
    Visitor_Last_Name: string;
    Visitor_Email: string;
    Visitor_Company: string;
    Visit_Host_Name: string;
    Visit_Host_Email: string;
    Visit_Start_Date: string;
    Visit_End_Date: string;
    Visit_IsCheckedIn: number;
    Visit_IsApproved: number;
    Visit_IsCancelled: number;
    Visit_IsNoShow: number;
    Visit_IsCheckedOut: number;
    Visit_Approval_Comments: string;
    Visitor_Dietary_Pref: number;
    Visit_Status: string;
    Visit_IsDenied: number;
    Visit_Save_Info: number;
    Booking_Name: string;
    Meta_Ext_Visit_Id: string;
    Visit_Approve_Time: string;
    Visit_Deny_Time: string;
    Visit_Pacs_Id: string;
  }