import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";
import { INotification } from "./GetActiveNotificationEndpoint";

export class GetNotificationByIdEndpoint implements IGetNotificationByIdEndpoint
{
    public async execute(nodeId: number, notificationId: string): Promise<INotification>
    {
        try
        {
            const response = await axios.get<INotification>(`${apis.allApi}${nodeId}/Notifications/${notificationId}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetNotificationByIdEndpoint
{
    execute(nodeId: number, notificationId: string): Promise<INotification>
}
