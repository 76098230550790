import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray, getAirStatus } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { IPartialAppState, appContext } from "../../../../AppContext";
import { FACILITY_ZONE_DETAIL, UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";

class ListEnvironmentalDailySummariesByZone extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    zonesData: [],
    facilityDataSort: { columnName: "", direction: "" },
    zoneList: [],
    currentEnvZoneName: '',
    selectedBuildingId: this.appState.buildingId.toString(),
  };

  public async componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones });
    this.appState.subscribe(this, i => this.appStateChanged(i));

    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });

    this.handleGetZonesData()
  }

  private async appStateChanged(state: IPartialAppState): Promise<void> {
    if (state.buildingId != undefined) {
      this.setState({ selectedBuildingId: state.buildingId?.toString() });
    }
  }

  handleGetZonesData = async () => {
    this.setState({ isLoading: true });
    const { match, location, currentEnvZoneName }: any = this.props;
    const { zoneId } = match.params
    const { search } = location;
    const startDate = search.split('&')[0].split('=')[1];
    const endDate = search.split('&')[1].split('=')[1];
    try {
      const response: any = await apis.getHistoricalZoneData(this.session.getBuildingId(), zoneId, startDate, endDate)
      const responseData = response.data;

      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }

        newItem.table_Summary_Date_Local = <div onClick={() => this.handleClickZone(item)}>{DateTime.fromISO(item.Summary_Date_Local).toFormat("dd/MM/yyyy HH:mm")}</div>
        newItem.table_IAQ_Status = <div onClick={() => this.handleClickZone(item)}>{getAirStatus(item.IAQ_Status)}</div>
        newItem.table_Env_Temperature = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Temperature)}{'\u00b0'}C</div>
        newItem.table_Env_Humidity = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Humidity)}%</div>
        newItem.table_Env_Sound_Level = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Sound_Level)} dB</div>
        newItem.table_Env_Zone_LastUpdated = <div onClick={() => this.handleClickZone(item)}>{DateTime.fromISO(item.Env_Zone_LastUpdated).toLocaleString(DateTime.DATETIME_SHORT)}</div>
        return newItem
      })

      this.setState({ isLoading: false, currentEnvZoneName: currentEnvZoneName, zoneList: newResponseData ? newResponseData : [] })
      this.setState({ isLoading: false, zonesData: responseData ? responseData : [] })
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  handleClickZone = async (item: any) => {

    const { dispatch }: any = this.props;
    await dispatch({ type: FACILITY_ZONE_DETAIL, payload: item })

    const { history }: any = this.props;
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/zones/${item.Env_Zone_Id}`)
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.zoneList, e.column, e.direction)
    this.setState({ zoneList: newArray })
  }

  render() {
    const tableData = {
      columns: [
        {
          label: this.labels.HubLabelDate,
          field: 'table_Summary_Date_Local',
          sort: this.state.facilityDataSort.columnName == "table_Summary_Date_Local" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_IAQ_Status",
          sort: this.state.facilityDataSort.columnName == "table_IAQ_Status" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelTemperature,
          field: "table_Env_Temperature",
          sort: this.state.facilityDataSort.columnName == "table_Env_Temperature" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelHumidity,
          field: "table_Env_Humidity",
          sort: this.state.facilityDataSort.columnName == "table_Env_Humidity" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelNoise,
          field: "table_Env_Sound_Level",
          sort: this.state.facilityDataSort.columnName == "table_Env_Sound_Level" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelLastUpdate,
          field: "table_Env_Zone_LastUpdated",
          sort: this.state.facilityDataSort.columnName == "Env_Zone_LastUpdated" ? this.state.facilityDataSort.direction : "",
          width: 50
        },

      ],
      rows: this.state.zoneList ? this.state.zoneList : []
    };

    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
            <TablePanelInfo
              tableHeading={this.labels.HubLabelHistoricalData}
              iconSrc="/images/Sidebar_Icons/Light_theme/Zone.svg"
            />
            <div className="position-relative">
              {(this.state.isLoading || this.state.isLoading) && <Spinner />}
              <MDBDataTable className="standardTable tablePageNoButtons sustainability-table" searchLabel={this.labels.HubLabelMySearchLabel} disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small onSort={this.handleFacilityDataSort} data={tableData} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    currentEnvZoneName: state.currentEnvZoneName,
  };
};

export default connect(mapStateToProps)(ListEnvironmentalDailySummariesByZone);
