import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ITasksFilter } from "./TaskRepository";
import { ApiHelper } from "../ApiHelper";

export class GetTasksEndpoint implements IGetTasksEndpoint
{
    public async execute<TTask>(tTask: new() => TTask, buildingId: number, filter: ITasksFilter): Promise<TTask[]>
    {
        try
        {
            let filterParts = new Array<string>();
            filter.taskStatusNot?.map((item) => filterParts.push(`Task_Status ne '${item}'`));
            filterParts.push(filter.status ? `Task_Status eq '${filter.status}'` : "");
            filterParts.push(filter.owner ? `Task_Owner eq '${filter.owner.toLocaleLowerCase()}'` : "");
            filterParts.push(filter.createdBy ? `CreatedBy eq '${filter.createdBy.toLocaleLowerCase()}'` : "");
            filterParts.push(filter.taskCategory ? `Task_Category_Name eq '${filter.taskCategory}'` : "");
            filterParts.push(filter.createdFrom ? `CreatedAt ge datetime'${filter.createdFrom.toUTC().toISO()}'` : "");
            filterParts.push(filter.createdTo ? `CreatedAt le datetime'${filter.createdTo.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskEventStart ? `Task_Event_Time ge datetime'${filter.taskEventStart.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskEventEnd ? `Task_Event_Time lt datetime'${filter.taskEventEnd.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskDueFrom ? `Task_Due_Date ge datetime'${filter.taskDueFrom.toUTC().toISO()}'` : "");
            filterParts.push(filter.taskDueTo ? `Task_Due_Date le datetime'${filter.taskDueTo.toUTC().toISO()}'` : "");
            const filterString = filterParts.filter(i => i != "").join(" and ");

            const query =
                `schema=false&` +
                `showDebugStats=false&` +
                `$top=2000&` +
                `$select=${ApiHelper.toODataSelect(tTask)}&` +
                `$filter=${filterString}`;

            const response: AxiosResponse<TTask[]> = await axios.get(`${apis.allApi}${buildingId}/Tasks?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetTasksEndpoint
{
    execute<TTask>(tTask: new() => TTask,buildingId: number, filter: ITasksFilter): Promise<TTask[]>;
}
