import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import apis from "../apis";

export class GetManyByPeriodEndpoint implements IGetManyByPeriodEndpoint
{
    public async execute(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<ISpaceUtilisationSummary[]>
    {
        try
        {
            const query =
                `recursive=true&` +
                `$top=2000&` +
                `$select=Space_Id,Space_Type,Space_Class,Node_Id,Node_Level_Name,Space_Name,Space_Type_Label,Period_Current_Space_Value,Period_Current_Max_Occ_Value&` +
                `$filter=` +
                    `Node_Id eq ${floorNodeId} and ` +
                    `Node_Level_Name eq 'Space' and ` +
                    `Period_Type eq ${periodType} and ` +
                    `Period_Start_Date ge datetime'${periodStart.toUTC().toISO()}' and ` +
                    `Period_Start_Date lt datetime'${periodEnd.toUTC().toISO()}' and ` +
                    `Space_Type_Label ne 'Total'`;
            
            const response = await axios.get(`${apis.allApi}${nodeId}/SpaceUtilisation/SpaceUtilisationData?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyByPeriodEndpoint
{
    execute(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<ISpaceUtilisationSummary[]>;
}

export interface ISpaceUtilisationSummary
{
    Node_Id: number;
    Node_Level_Name: string;
    Period_Current_Max_Occ_Value: number;
    Period_Current_Space_Value: number;
    Space_Class: string;
    Space_Id: string;
    Space_Name: string;
    Space_Type: string;
    Space_Type_Label: string;
}
