import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringSupplier } from "./CateringSupplierRepository";

export class CreateCateringSupplierEndpoint implements ICreateCateringSupplierEndpoint
{
    public async execute(nodeId: number, payload: ICateringSupplier): Promise<void>
    {
        try
        {
            const response = await axios.post<void>(apis.allApiv2 + nodeId + `/catering-supplier`, payload);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface ICreateCateringSupplierEndpoint
{
    execute(nodeId: number, payload: ICateringSupplier): Promise<void>;
}
