import { Component } from 'react';

export default class CalendarIcon extends Component {
    render() {
        return (
            <svg>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.67627 6.35301C0.67627 4.11139 2.49347 2.29419 4.73509 2.29419H20.2645C22.5061 2.29419 24.3233 4.11139 24.3233 6.35301V19.7648C24.3233 22.0064 22.5061 23.8236 20.2645 23.8236H4.73509C2.49347 23.8236 0.67627 22.0064 0.67627 19.7648V6.35301ZM4.73509 3.35301C3.07824 3.35301 1.73509 4.69616 1.73509 6.35301V19.7648C1.73509 21.4216 3.07824 22.7648 4.73509 22.7648H20.2645C21.9214 22.7648 23.2645 21.4216 23.2645 19.7648V6.35301C23.2645 4.69616 21.9214 3.35301 20.2645 3.35301H4.73509Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.1471 0.176514C18.4395 0.176514 18.6765 0.413539 18.6765 0.705925V4.94122C18.6765 5.23361 18.4395 5.47063 18.1471 5.47063C17.8547 5.47063 17.6177 5.23361 17.6177 4.94122V0.705925C17.6177 0.413539 17.8547 0.176514 18.1471 0.176514Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.85314 0.176514C7.14553 0.176514 7.38255 0.413539 7.38255 0.705925V4.94122C7.38255 5.23361 7.14553 5.47063 6.85314 5.47063C6.56076 5.47063 6.32373 5.23361 6.32373 4.94122V0.705925C6.32373 0.413539 6.56076 0.176514 6.85314 0.176514Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.67627 9.17638C0.67627 8.884 0.913295 8.64697 1.20568 8.64697H23.7939C24.0863 8.64697 24.3233 8.884 24.3233 9.17638C24.3233 9.46877 24.0863 9.7058 23.7939 9.7058H1.20568C0.913295 9.7058 0.67627 9.46877 0.67627 9.17638Z" />
            </svg>
        )
    }
};