import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { appContext, IPartialAppState } from "../../../../AppContext";
import { DateTime } from "luxon";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { SpacesFilter } from "../../../../Providers.Api/Spaces/SpaceRepository";
import Helper from "../../../../Common/Helper";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class ListDirectedCleaningSpaces extends IbssPage<IProps, IState>
{
  private get appState() { return appContext().state; };
  private get labels() { return appContext().labels; };
  private get api() { return appContext().apiClient; };

  constructor(props: IProps)
  {
    super(props);
    this.state =
    {
      isLoading: false,
      spaces: [],
      selectedBuildingId: this.appState.buildingId,
    };
  }

  public async componentDidMount()
  {
    const { match }: any = this.props;
    this.setState({
      isLoading: true
    })

    this.appState.subscribe(this, i => this.appStateChanged(i));

    const buildingIdParam = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingIdParam }, this);

    this.getDetails();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void
  {
    if (prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.getDetails();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined)
    {
      this.setState({ selectedBuildingId: state.buildingId });
      history.push(match.path.replace(":buildingid", state.buildingId))
    }
  }

  private async getDetails(): Promise<void>
  {
    this.setState({ isLoading: true });
    const spaceFilter = new SpacesFilter({
      lastOccupiedFrom: DateTime.now().minus({ days: 1 }).startOf('day').offsetTimeByNode(this.state.selectedBuildingId),
      lastOccupiedTo: DateTime.now().plus({ days: 1 }).startOf('day').offsetTimeByNode(this.state.selectedBuildingId),
    });
    let spaces = await this.api.spaces.getV1Spaces(Space, this.state.selectedBuildingId, 10000, spaceFilter);
    spaces = spaces.map(x => ({ ...x, id: x.Space_Id }))
    this.setState({ spaces: spaces, isLoading: false });
  }

  render()
  {
    const { spaces }: any = this.state;

    const columns = [
      {
        headerName: this.labels.HubLabelSpaceName,
        field: Helper.nameOf<Space>("Space_Name"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelSpaceClass,
        field: Helper.nameOf<Space>("Space_Class"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelSpaceType,
        field: Helper.nameOf<Space>("Space_Type"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabelSpaceId,
        field: Helper.nameOf<Space>("Space_Id"),
        flex: 1
      },
      {
        headerName: this.labels.HubLabellastOccupiedLabel,
        field: Helper.nameOf<Space>("Space_Last_Occupied"),
        flex: 1,
        valueGetter: (params: { row: { Space_Last_Occupied: string; } }) => DateTime.fromISO(params.row.Space_Last_Occupied).setZoneByNode(this.state.selectedBuildingId).toLocaleDateTimeString(),
      },
    ]
    const rows = spaces;

    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
            <TablePanelInfo
              tableHeading={this.labels.HubTabOpservDirectedCleaning}
              iconSrc="/images/Meeting Room.svg"
            />
            <IbssDataGrid
              className="data-grid-clickable-row"
              height="calc(100vh - 250px)"
              columns={columns}
              rows={rows}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) =>
{
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(ListDirectedCleaningSpaces);

export class Space
{
  public Space_Class = "";
  public Space_Last_Occupied = "";
  public Space_Type = "";
  public Space_Name = "";
  public Space_Id = "";
}

export interface IProps
{
}

export interface IState
{
  isLoading: boolean,
  spaces: Space[],
  selectedBuildingId: number,
}
