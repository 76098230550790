import { Component } from 'react';

export default class Touchdown extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 5C6.25 2.92893 7.92893 1.25 10 1.25C12.0711 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.0711 8.75 10 8.75C7.92893 8.75 6.25 7.07107 6.25 5ZM10 2.75C8.75736 2.75 7.75 3.75736 7.75 5C7.75 6.24264 8.75736 7.25 10 7.25C11.2426 7.25 12.25 6.24264 12.25 5C12.25 3.75736 11.2426 2.75 10 2.75ZM7.5 9.25C5.8699 9.25 4.25 10.3337 4.25 12V13C4.25 13.0877 4.26504 13.1718 4.29268 13.25H0.25V19.25V20.75V34H1.75V20.75H5.35656L7.35656 33.75H12.6434L14.6434 20.75H32.25V34H33.75V20.75V19.25V13.25H28.6155L31.686 6.3032C31.7885 6.07124 31.7669 5.80318 31.6285 5.59067C31.49 5.37817 31.2536 5.25 31 5.25H21.536C21.2397 5.25 20.9711 5.42446 20.8507 5.6952L17.4905 13.25H15.7073C15.735 13.1718 15.75 13.0877 15.75 13V12C15.75 10.3337 14.1301 9.25 12.5 9.25H7.5ZM19.1322 13.25H26.9755L29.8485 6.75H22.0233L19.1322 13.25ZM18 14.75H1.75V19.25H5.12579H14.8742H32.25V14.75H27.464H18ZM14.2927 13.25C14.265 13.1718 14.25 13.0877 14.25 13V12C14.25 11.4572 13.6313 10.75 12.5 10.75H7.5C6.36868 10.75 5.75 11.4572 5.75 12V13C5.75 13.0877 5.73496 13.1718 5.70732 13.25H14.2927ZM8.64344 32.25L6.87421 20.75H13.1258L11.3566 32.25H8.64344Z" />
            </svg>
        )
    }
};
