import { Component } from 'react';

export default class Height extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 11C0.25 10.5858 0.585786 10.25 1 10.25H33C33.4142 10.25 33.75 10.5858 33.75 11C33.75 11.4142 33.4142 11.75 33 11.75H1C0.585786 11.75 0.25 11.4142 0.25 11Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1.25C17.4142 1.25 17.75 1.58579 17.75 2L17.75 33C17.75 33.4142 17.4142 33.75 17 33.75C16.5858 33.75 16.25 33.4142 16.25 33L16.25 2C16.25 1.58579 16.5858 1.25 17 1.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4697 0.46967C16.7626 0.176777 17.2374 0.176777 17.5303 0.46967L20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033C20.2374 4.82322 19.7626 4.82322 19.4697 4.53033L17 2.06066L14.5303 4.53033C14.2374 4.82322 13.7626 4.82322 13.4697 4.53033C13.1768 4.23744 13.1768 3.76256 13.4697 3.46967L16.4697 0.46967Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5303 33.5303C17.2374 33.8232 16.7626 33.8232 16.4697 33.5303L13.4697 30.5303C13.1768 30.2374 13.1768 29.7626 13.4697 29.4697C13.7626 29.1768 14.2374 29.1768 14.5303 29.4697L17 31.9393L19.4697 29.4697C19.7626 29.1768 20.2374 29.1768 20.5303 29.4697C20.8232 29.7626 20.8232 30.2374 20.5303 30.5303L17.5303 33.5303Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 22C0.25 21.5858 0.585786 21.25 1 21.25H33C33.4142 21.25 33.75 21.5858 33.75 22C33.75 22.4142 33.4142 22.75 33 22.75H1C0.585786 22.75 0.25 22.4142 0.25 22Z" />
            </svg>
        )
    }
};