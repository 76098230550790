import { Component } from 'react';

export default class Phonebooth extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path d="M28.8152 32.7C28.7152 32.7 28.6152 32.7 28.5152 32.7C23.7152 32.2 19.1152 30.5 15.1152 27.9C11.4152 25.5 8.21521 22.3 5.81521 18.6C3.21521 14.6 1.51521 10 1.01521 5.2C0.915206 4.1 1.31521 3 2.01521 2.2C2.71521 1.5 3.81521 1 4.91521 1H9.41521C11.3152 1 13.0152 2.4 13.2152 4.3C13.4152 5.7 13.7152 7 14.2152 8.4C14.7152 9.8 14.4152 11.4 13.3152 12.5L11.8152 14C13.7152 17.2 16.4152 19.9 19.6152 21.8L21.1152 20.4C22.2152 19.3 23.8152 19 25.2152 19.5C26.5152 20 27.9152 20.3 29.3152 20.5C31.2152 20.8 32.7152 22.5 32.6152 24.4V28.9C32.6152 30 32.1152 31 31.3152 31.8C30.7152 32.3 29.8152 32.7 28.8152 32.7ZM9.41521 2.7H4.91521C4.31521 2.7 3.71521 3 3.31521 3.4C2.91521 3.8 2.71521 4.4 2.71521 5.1C3.21521 9.6 4.71521 14 7.21521 17.8C9.41521 21.3 12.5152 24.3 16.0152 26.6C19.8152 29.1 24.2152 30.6 28.7152 31.1C29.3152 31.1 29.9152 30.9 30.3152 30.5C30.7152 30.1 31.0152 29.5 31.0152 28.9V24.4C31.0152 23.3 30.2152 22.4 29.1152 22.2C27.6152 22 26.1152 21.6 24.7152 21.1C23.9152 20.8 23.0152 21 22.4152 21.6L20.5152 23.5C20.2152 23.8 19.8152 23.8 19.5152 23.6C15.6152 21.4 12.4152 18.1 10.1152 14.2C9.91521 13.9 10.0152 13.4 10.2152 13.2L12.1152 11.3C12.8152 10.7 13.0152 9.8 12.7152 9C12.2152 7.6 11.8152 6.1 11.6152 4.6C11.4152 3.5 10.5152 2.7 9.41521 2.7Z" />
            </svg>
        )
    }
};
