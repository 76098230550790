import { Box, BoxProps, Grid, Paper, PaperProps, styled, Typography } from '@mui/material';
import moment from 'moment';
import { Component } from 'react';
import IbssChip from '../../Navigation/Chip/IbssChip';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';

type IbssBoxProps = BoxProps & {
    imageSrc: string,
    searchHeading: string,
    searchSubHeading: string,
    Icon?: string,
    backgroundColor?: string;
}

const StyledBox = styled(Box)<IbssBoxProps>(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '24px',
    backgroundColor: 'var(--ui-background-alternate)'
}));

class IbssSearchCard extends Component<IbssBoxProps> {
    render() {
        const {
            imageSrc,
            searchHeading,
            searchSubHeading,
            backgroundColor
        } = this.props;

        return (
            <div className="">
                <StyledBox {...this.props} sx={{ cursor: "pointer", backgroundColor: backgroundColor ? backgroundColor : '' }} className="box-shadow-none">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex text-left" >
                            <div className="line-height-1 notification-grid-image">
                                <img src={imageSrc ? imageSrc : "../../../images/FlexNotificationImage.svg"} />
                            </div>
                            <div className="ml-24 d-flex align-items-center">
                                <div>
                                <Typography className="text-ui-md ui-text-alternate" variant='h6'>
                                    {searchHeading}
                                </Typography>
                                
                                <Typography variant='body2' className="card-userPref-text mt-8">
                                    {searchSubHeading}
                                </Typography>
                                <Typography variant='body2' className="card-userPref-text mt-8">
                                    {this.props.children}
                                </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                </StyledBox>
            </div>
        )
    }
}

export default IbssSearchCard;
