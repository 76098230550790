import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IMenuItems } from "./CateringMenuRepository";

export class GetByIdEndpoint implements IGetByIdEndpoint
{
    public async execute(nodeId: number, menuId: string, expandMenuItems: boolean, expandMenuItemRestrictions: boolean): Promise<ICateringMenu>
    {
        try
        {
            let expand = '';
            if(expandMenuItems)
            {
                expand = '$expand=Menu_MenuItems($expand=MenuItem)';
            }
            if(expandMenuItemRestrictions)
            {
                expand = '$expand=Menu_MenuItems($expand=MenuItem($expand=MenuItem_Restrictions($expand=Restriction)))';
            }
            const response = await axios.get<ICateringMenu>(`${apis.allApiv2}${nodeId}/catering-menu/${menuId}?${expand}`);
            const menu = response.data;
            menu.Available_From = DateHelper.fromIso(response.data.Available_From as unknown as string);
            menu.Available_Until = DateHelper.fromIso(response.data.Available_Until as unknown as string);
            return menu;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByIdEndpoint
{
    execute(nodeId: number, menuId: string, expandMenuItems: boolean, expandMenuItemRestrictions: boolean): Promise<ICateringMenu>;
}

export interface ICateringMenu
{
    Node_Id: number;
    Menu_Id: string;
    Name: string;
    Description: string;
    Short_Description: string;
    Available_From: DateTime;
    Available_Until: DateTime;
    ImageURI: string;
    Status: string;
    ConcurrencyStamp: string;
    Menu_MenuItems: IMenuItems[];
}
