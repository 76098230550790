import { Component } from 'react';

export default class Kitchenette extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 13C21.25 12.0335 22.0335 11.25 23 11.25H32C32.9665 11.25 33.75 12.0335 33.75 13V31C33.75 31.9665 32.9665 32.75 32 32.75H23C22.0335 32.75 21.25 31.9665 21.25 31V13ZM23 12.75C22.8619 12.75 22.75 12.8619 22.75 13V31C22.75 31.1381 22.8619 31.25 23 31.25H32C32.1381 31.25 32.25 31.1381 32.25 31V13C32.25 12.8619 32.1381 12.75 32 12.75H23Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 21C0.25 20.0335 1.0335 19.25 2 19.25H18C18.9665 19.25 19.75 20.0335 19.75 21V31C19.75 31.9665 18.9665 32.75 18 32.75H2C1.0335 32.75 0.25 31.9665 0.25 31V21ZM2 20.75C1.86193 20.75 1.75 20.8619 1.75 21V31C1.75 31.1381 1.86193 31.25 2 31.25H18C18.1381 31.25 18.25 31.1381 18.25 31V21C18.25 20.8619 18.1381 20.75 18 20.75H2Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H18C18.9665 0.25 19.75 1.0335 19.75 2V9C19.75 9.9665 18.9665 10.75 18 10.75H2C1.0335 10.75 0.25 9.9665 0.25 9V2ZM2 1.75C1.86193 1.75 1.75 1.86193 1.75 2V9C1.75 9.13807 1.86193 9.25 2 9.25H18C18.1381 9.25 18.25 9.13807 18.25 9V2C18.25 1.86193 18.1381 1.75 18 1.75H2Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 6C21.25 5.0335 22.0335 4.25 23 4.25H32C32.9665 4.25 33.75 5.0335 33.75 6V11C33.75 11.9665 32.9665 12.75 32 12.75H23C22.0335 12.75 21.25 11.9665 21.25 11V6ZM23 5.75C22.8619 5.75 22.75 5.86193 22.75 6V11C22.75 11.1381 22.8619 11.25 23 11.25H32C32.1381 11.25 32.25 11.1381 32.25 11V6C32.25 5.86193 32.1381 5.75 32 5.75H23Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 31.25C2.41421 31.25 2.75 31.5858 2.75 32V33C2.75 33.4142 2.41421 33.75 2 33.75C1.58579 33.75 1.25 33.4142 1.25 33V32C1.25 31.5858 1.58579 31.25 2 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 31.25C18.4142 31.25 18.75 31.5858 18.75 32V33C18.75 33.4142 18.4142 33.75 18 33.75C17.5858 33.75 17.25 33.4142 17.25 33V32C17.25 31.5858 17.5858 31.25 18 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 31.25C24.4142 31.25 24.75 31.5858 24.75 32V33C24.75 33.4142 24.4142 33.75 24 33.75C23.5858 33.75 23.25 33.4142 23.25 33V32C23.25 31.5858 23.5858 31.25 24 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32 31.25C32.4142 31.25 32.75 31.5858 32.75 32V33C32.75 33.4142 32.4142 33.75 32 33.75C31.5858 33.75 31.25 33.4142 31.25 33V32C31.25 31.5858 31.5858 31.25 32 31.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 23.75H1V22.25H19V23.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 1L10.75 10L9.25 10L9.25 1L10.75 1Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 23L10.75 32L9.25 32L9.25 23L10.75 23Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 7.25C24.4142 7.25 24.75 7.58579 24.75 8L24.75 9C24.75 9.41421 24.4142 9.75 24 9.75C23.5858 9.75 23.25 9.41421 23.25 9L23.25 8C23.25 7.58579 23.5858 7.25 24 7.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 14.25C24.4142 14.25 24.75 14.5858 24.75 15L24.75 19C24.75 19.4142 24.4142 19.75 24 19.75C23.5858 19.75 23.25 19.4142 23.25 19L23.25 15C23.25 14.5858 23.5858 14.25 24 14.25Z" />
                <path d="M8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7Z" />
                <path d="M8 27C8 27.5523 7.55228 28 7 28C6.44772 28 6 27.5523 6 27C6 26.4477 6.44772 26 7 26C7.55228 26 8 26.4477 8 27Z" />
                <path d="M14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7Z" />
                <path d="M14 27C14 27.5523 13.5523 28 13 28C12.4477 28 12 27.5523 12 27C12 26.4477 12.4477 26 13 26C13.5523 26 14 26.4477 14 27Z" />
            </svg>
        )
    }
};