import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteEndpoint implements IDeleteEndpoint
{
    public async execute(nodeId: number, taskId: string): Promise<string>
    {
        try
        {
            const response = await axios.delete(apis.allApiv2 + nodeId + '/catering-order/' + taskId);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteEndpoint
{
    execute(nodeId: number, taskId: string): Promise<string>;
}