import React, { useRef, useEffect, useState, } from 'react'


const CheckboxIndeterminateInput = (props) => {
  const { label, checkBoxValue, isDisabled, onChange } = props;
  const checkboxRef = useRef();

  const [checkValue, setCheckValue] = useState(checkBoxValue ? checkBoxValue : false);


  useEffect(() => {
    setCheckValue(checkBoxValue)
  }, [checkBoxValue])


  useEffect(() => {
    /** 
     * if checkbox is checked ==> checkValue = true 
     * if checkbox is not checked ==> checkValue = false 
     * if checkbox is indeterminate ==> checkValue = null 
     * */
    if (checkValue === true) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (checkValue === false) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (checkValue === null) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [checkValue]);

  const handleChange = () => {
    let updatedChecked;
    if (checkValue === true) {
      updatedChecked = false;
    } else if (checkValue === false) {
      updatedChecked = null;
    } else if (checkValue === null) {
      updatedChecked = true;
    }
    setCheckValue(updatedChecked);
    onChange(updatedChecked)
  }


  return (
    <div className="d-flex mb-3">
      <label className="upload-file-modal-label">{label}</label>
      <input className="modal-status-checkbox" ref={checkboxRef} type="checkbox" onChange={handleChange} disabled={isDisabled}></input>
    </div>
  );
};

export default CheckboxIndeterminateInput