import { Component } from 'react';

export default class FloorIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path id="Path_54" data-name="Path 54" d="M30.7,15.917,28.037,14.2l.789-1.224,3.6,2.32a.728.728,0,0,1,.008,1.218L16.9,26.813a.728.728,0,0,1-.8,0L.568,16.515a.728.728,0,0,1,0-1.212l3.478-2.32.808,1.211L2.285,15.907,16.5,25.333Z" fill-rule="evenodd" />
                <path id="Path_55" data-name="Path 55" d="M30.7,20.77l-2.662-1.717.789-1.224,3.6,2.32a.728.728,0,0,1,.008,1.219L16.9,31.666a.728.728,0,0,1-.8,0L.568,21.368a.728.728,0,0,1,0-1.212l3.478-2.32.808,1.211L2.285,20.76,16.5,30.185Z" fill-rule="evenodd" />
                <path id="Path_56" data-name="Path 56" d="M16.1.362a.728.728,0,0,1,.8,0l15.529,10.2a.728.728,0,0,1,0,1.217L16.9,21.962a.728.728,0,0,1-.8,0L.571,11.781a.728.728,0,0,1,0-1.217ZM2.3,11.172l14.2,9.31,14.2-9.31L16.5,1.842Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
