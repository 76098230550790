import { createStore, compose } from '@reduxjs/toolkit';
import {
  SWITCH_THEME,
  IDENTITY_PROVIDER_DATA,
  UPDATE_MAIN_PAGE_TITLE,
  FLEX_MY_SEARCH_FILTER,
  FACILITY_ZONE_DETAIL,
  MY_NOTIFICATION_LIST,
  MY_NOTIFICATION_READED_LIST,
  MY_NEW_NOTIFICATION,
  ONELENS_SPACEANALYTICS_OVERVIEW,
  ONELENS_SPACEANALYTICS_FILTER,
  ONE_LENSE_DATE_BULDING,
  HEAT_MAP_FILTER_CRITERIA,
  ONELENS_SPACEANALYTICS_DETAIL,
} from './constants';

type CommonStateType = {
  currentPageTitle: string,
  mainPageTitle: string,
  lightModeTheme: boolean,
  identityProviders: any, // todo. apply typescript types to this - array of complex objects. 
  identityProvidersDetails: any,
  flexMySearchFilterCriteria: any,
  facilityZoneDtl: any,
  notificationList: any,
  notificationReadedList: any,
  newNotification: boolean,
  onelensSpaceAnalyticsOverview: any,
  onelensSpaceAnalyticsFilterCriteria: any,
  onelensDateBuilding: any,
  heatMapFilterCriteria: any,
}

const initialstate: CommonStateType = {
  currentPageTitle: "Identity Providers",
  mainPageTitle: "Security",
  lightModeTheme: ((sessionStorage.getItem("themeMode") && sessionStorage.getItem("themeMode")) === "false" ? false : true),
  identityProviders: [],
  identityProvidersDetails: [],
  flexMySearchFilterCriteria: {},
  facilityZoneDtl: {},
  onelensSpaceAnalyticsOverview: {},
  onelensSpaceAnalyticsFilterCriteria: {},
  onelensDateBuilding: {},
  notificationList: [],
  notificationReadedList: [],
  newNotification: false,
  heatMapFilterCriteria: {},
};

const reducer = (state = initialstate, action: any) => {
  switch (action.type) {
    case SWITCH_THEME: {
      return { ...state, lightModeTheme: !state.lightModeTheme };
    }
    case IDENTITY_PROVIDER_DATA: {
      return { ...state, identityProviders: action.payload };
    }
    case UPDATE_MAIN_PAGE_TITLE: {
      return { ...state, mainPageTitle: action.mainPageTitle };
    }
    case FLEX_MY_SEARCH_FILTER: {
      return { ...state, flexMySearchFilterCriteria: action.payload };
    }
    case FACILITY_ZONE_DETAIL: {
      return { ...state, facilityZoneDtl: action.payload };
    }
    case MY_NOTIFICATION_LIST: {
      return { ...state, notificationList: action.payload };
    }
    case MY_NOTIFICATION_READED_LIST: {
      return { ...state, notificationReadedList: action.payload };
    }
    case MY_NEW_NOTIFICATION: {
      return { ...state, newNotification: action.payload };
    }
    case ONELENS_SPACEANALYTICS_OVERVIEW: {
      return { ...state, onelensSpaceAnalyticsOverview: action.payload };
    }
    case ONELENS_SPACEANALYTICS_FILTER: {
      return { ...state, onelensSpaceAnalyticsFilterCriteria: action.payload };
    }
    case ONE_LENSE_DATE_BULDING: {
      return { ...state, onelensDateBuilding: action.payload };
    }
    case ONELENS_SPACEANALYTICS_DETAIL: {
      return { ...state, onelensSpaceAnalyticsDetails: action.payload };
    }
    case HEAT_MAP_FILTER_CRITERIA: {
      return { ...state, heatMapFilterCriteria: action.payload};
    }

    default: {
      return state;
    }
  }
};

// code to enable Redux DevTools Extension in supported browsers.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reducer, composeEnhancers());

// todo. migrate to configureStore after debugging why http://localhost:3000/flex-find-a-space doesn't render with it.
//export const store = configureStore({reducer: reducer}); 

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch