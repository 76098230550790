import { Component } from 'react';

export default class Treatmentroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.86484 4.76361L8.52632 5.43286L7.81648 5.19072C7.88658 4.98523 8.04228 4.82021 8.24334 4.73829C8.44441 4.65638 8.6711 4.66561 8.86484 4.76361ZM8.9877 6.55451C8.97575 6.60229 8.96357 6.65181 8.95122 6.70301C8.79061 7.36887 8.60095 8.31586 8.48696 9.43231C8.25784 11.6764 8.34058 14.545 9.51943 17.1898C11.1322 20.8077 12.5524 21.9456 13.7615 22.1897C14.1675 22.2716 14.4302 22.6672 14.3483 23.0733C14.2663 23.4793 13.8708 23.742 13.4647 23.66C11.4968 23.2628 9.8018 21.5074 8.14938 17.8005C6.82285 14.8244 6.75154 11.6618 6.99472 9.27995C7.11688 8.08345 7.31996 7.06881 7.49305 6.35127C7.57967 5.99214 7.65903 5.70643 7.71743 5.50843C7.74664 5.40941 7.77063 5.33224 7.7877 5.27871C7.79624 5.25195 7.80304 5.23108 7.80791 5.21635L7.81373 5.19886L7.8155 5.19361L7.81648 5.19072C7.81657 5.19046 7.81648 5.19072 8.52632 5.43286C8.86484 4.76361 8.86471 4.76354 8.86484 4.76361L8.86619 4.76429L8.86842 4.76543L8.87554 4.76906L8.90014 4.78177C8.92109 4.79266 8.95103 4.80837 8.9894 4.82888C9.06612 4.86989 9.17658 4.93011 9.31621 5.0093C9.59537 5.16763 9.99163 5.4021 10.4682 5.71083C11.4203 6.32753 12.6992 7.24461 14.0077 8.44796C14.3126 8.72835 14.3324 9.20281 14.052 9.50769C13.7716 9.81257 13.2972 9.83243 12.9923 9.55204C11.7603 8.41904 10.5524 7.55255 9.65273 6.96978C9.40525 6.80947 9.18157 6.67093 8.9877 6.55451Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4442 5.19073L25.7343 5.43286L25.4226 4.75071C25.6154 4.66263 25.8364 4.6602 26.031 4.74404C26.2257 4.82788 26.3758 4.99015 26.4442 5.19073ZM25.2636 6.51739C25.0338 6.64336 24.7616 6.79842 24.4582 6.98204C23.4963 7.56416 22.2312 8.42699 21.0077 9.55207C20.7028 9.83244 20.2283 9.81256 19.948 9.50766C19.6676 9.20276 19.6875 8.7283 19.9924 8.44793C21.3093 7.23692 22.6613 6.31618 23.6815 5.69876C24.1926 5.38943 24.6231 5.15465 24.9278 4.99625C25.0802 4.91702 25.2014 4.85679 25.2857 4.81581C25.3278 4.79532 25.3608 4.77963 25.3838 4.76877L25.4109 4.75611L25.4187 4.7525L25.4212 4.75138L25.4226 4.75071C25.4227 4.75065 25.4226 4.75071 25.7343 5.43286C26.4442 5.19073 26.4441 5.19046 26.4442 5.19073L26.4446 5.19188L26.4452 5.19362L26.4469 5.19887L26.4527 5.21636C26.4576 5.23109 26.4644 5.25196 26.473 5.27872C26.49 5.33225 26.514 5.40942 26.5432 5.50845C26.6016 5.70645 26.681 5.99217 26.7676 6.35131C26.9407 7.06887 27.1438 8.08354 27.2659 9.28005C27.5091 11.6619 27.4378 14.8244 26.1113 17.8005C24.4033 21.6323 22.4155 23.4197 20.4135 23.7509C20.0048 23.8185 19.6187 23.542 19.5511 23.1333C19.4835 22.7247 19.76 22.3386 20.1686 22.271C21.4174 22.0644 23.1036 20.8636 24.7412 17.1898C25.9201 14.5451 26.0028 11.6765 25.7737 9.4324C25.6597 8.31594 25.47 7.36892 25.3094 6.70304C25.2938 6.63844 25.2785 6.57652 25.2636 6.51739Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8566 9.30826C32.8567 9.30826 32.8569 9.30827 32.8368 10.058C33.583 10.1339 33.5829 10.1342 33.5829 10.1344L33.5826 10.1369L33.5821 10.1425L33.5801 10.1608C33.5783 10.1762 33.5758 10.198 33.5724 10.2259C33.5656 10.2817 33.5552 10.3618 33.5405 10.464C33.511 10.6683 33.464 10.9611 33.393 11.3237C33.2511 12.048 33.0125 13.055 32.6246 14.1934C31.8524 16.4597 30.4688 19.3044 28.022 21.456C26.1433 23.1081 24.5403 24.0742 23.1371 24.4788C21.7016 24.8926 20.4867 24.7134 19.468 24.1144C19.111 23.9044 18.9918 23.4447 19.2017 23.0877C19.4117 22.7306 19.8714 22.6114 20.2284 22.8214C20.8654 23.196 21.6511 23.3461 22.7216 23.0375C23.8244 22.7195 25.2328 21.9113 27.0314 20.3296C29.2058 18.4176 30.4772 15.8449 31.2048 13.7096C31.5667 12.6473 31.7893 11.7075 31.9209 11.0353C31.935 10.9633 31.9481 10.8945 31.9602 10.8289C31.7069 10.8425 31.4046 10.8643 31.0628 10.8987C29.9704 11.0086 28.4851 11.247 26.9071 11.7582C26.5131 11.8859 26.0901 11.6699 25.9625 11.2759C25.8348 10.8819 26.0507 10.4589 26.4448 10.3312C28.1502 9.77869 29.7453 9.52368 30.9125 9.4062C31.4972 9.34736 31.9774 9.32275 32.314 9.3129C32.4823 9.30797 32.615 9.30673 32.707 9.30676C32.7531 9.30678 32.789 9.30711 32.8141 9.30746L32.8438 9.30797L32.8524 9.30816L32.8552 9.30822L32.8566 9.30826ZM32.8368 10.058L33.5829 10.1344C33.604 9.92688 33.5378 9.71939 33.4 9.56272C33.2622 9.40605 33.0651 9.31384 32.8566 9.30826L32.8368 10.058Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.0699 10.0582C0.32375 10.1341 0.323775 10.1343 0.323804 10.1346L0.324063 10.1371L0.324644 10.1426L0.326648 10.161C0.328371 10.1764 0.330884 10.1982 0.334287 10.2261C0.341094 10.2818 0.351468 10.362 0.366214 10.4642C0.395698 10.6685 0.442712 10.9613 0.513734 11.3238C0.655634 12.0482 0.894164 13.0551 1.28208 14.1935C2.05428 16.4597 3.43791 19.3044 5.88473 21.4562C7.77815 23.1212 9.39184 24.0896 10.8033 24.4884C12.2485 24.8967 13.4675 24.7018 14.4859 24.0864C14.8404 23.8721 14.9541 23.4111 14.7398 23.0565C14.5256 22.702 14.0645 22.5883 13.71 22.8026C13.0706 23.189 12.2839 23.348 11.2112 23.0449C10.1047 22.7323 8.68831 21.9241 6.87528 20.3298C4.70088 18.4176 3.42947 15.8449 2.70192 13.7097C2.33995 12.6474 2.11743 11.7076 1.98575 11.0354C1.97245 10.9675 1.96007 10.9023 1.94859 10.8401C2.25205 10.8582 2.62296 10.8866 3.04366 10.931C4.24364 11.0577 5.83035 11.313 7.40622 11.8237C7.80025 11.9514 8.22321 11.7355 8.3509 11.3414C8.4786 10.9474 8.26269 10.5244 7.86865 10.3967C6.16114 9.84337 4.46408 9.57263 3.20113 9.43931C2.56775 9.37244 2.03897 9.33968 1.66668 9.32361C1.48044 9.31558 1.33308 9.31171 1.23106 9.30986C1.18004 9.30893 1.14034 9.3085 1.11276 9.30831L1.08057 9.30817L1.07148 9.30817L1.06871 9.30817L1.06743 9.30817C1.06728 9.30818 1.06715 9.30818 1.0699 10.0582ZM1.0699 10.0582L1.06743 9.30817C0.855785 9.30895 0.654044 9.39911 0.512454 9.55642C0.370864 9.71372 0.302381 9.92406 0.323804 10.1346L1.0699 10.0582ZM1.81599 9.98169C1.81598 9.98159 1.81598 9.98158 1.81599 9.98169Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60085 17.6719C4.82458 18.0205 4.72337 18.4844 4.37478 18.7082C2.5951 19.8504 1.75 21.1214 1.75 22.3335C1.75 23.8869 3.15649 25.5345 5.99879 26.8372C8.77557 28.1098 12.6644 28.9168 17 28.9168C20.4182 28.9168 23.5644 28.415 26.1079 27.575C26.5012 27.4452 26.9254 27.6587 27.0553 28.052C27.1851 28.4453 26.9716 28.8695 26.5783 28.9994C23.8641 29.8957 20.5568 30.4168 17 30.4168C12.4991 30.4168 8.3879 29.5822 5.37381 28.2008C2.42525 26.8494 0.25 24.8303 0.25 22.3335C0.25 20.3717 1.60475 18.7037 3.56456 17.4458C3.91315 17.2221 4.37711 17.3233 4.60085 17.6719Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3097 17.668C29.5354 17.3207 30 17.2222 30.3473 17.448C32.2408 18.6792 33.75 20.3673 33.75 22.3334C33.75 24.5885 31.9684 26.4534 29.4862 27.7758C29.1206 27.9705 28.6663 27.8321 28.4716 27.4665C28.2768 27.1009 28.4153 26.6467 28.7809 26.4519C31.1183 25.2067 32.25 23.7306 32.25 22.3334C32.25 21.1576 31.307 19.8612 29.5296 18.7056C29.1823 18.4798 29.0839 18.0152 29.3097 17.668Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5458 4.51442C17.5457 4.51451 17.5456 4.5146 17.5455 4.51469L17.5444 4.5159L17.5362 4.5247C17.5285 4.53309 17.5162 4.54648 17.4996 4.56474C17.4665 4.60128 17.4164 4.6573 17.3518 4.73182C17.2225 4.8809 17.0353 5.10374 16.8094 5.39243C16.3571 5.97041 15.7523 6.80895 15.1478 7.84495C13.9328 9.92704 12.75 12.7526 12.75 15.8368C12.75 19.0478 13.3051 20.8929 14.0675 21.9239C14.7906 22.9019 15.7801 23.25 17 23.25C17.4142 23.25 17.75 23.5858 17.75 24C17.75 24.4142 17.4142 24.75 17 24.75C15.4584 24.75 13.9479 24.2851 12.8614 22.8157C11.8141 21.3994 11.25 19.163 11.25 15.8368C11.25 12.3838 12.5672 9.29094 13.8522 7.08893C14.4977 5.98289 15.1429 5.08797 15.6281 4.46799C15.8709 4.15769 16.0743 3.91543 16.2185 3.74916C16.2906 3.666 16.348 3.60178 16.3881 3.55749C16.4082 3.53534 16.4239 3.51817 16.4351 3.5061L16.4484 3.49184L16.4523 3.4876L16.4536 3.48622L16.4541 3.4857C16.4543 3.4855 16.4545 3.48532 17 4L16.4545 3.48532C16.7387 3.18403 17.2134 3.17022 17.5147 3.45448C17.8159 3.73862 17.8298 4.21305 17.5458 4.51435L17.5458 4.51442Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4542 4.51442C16.4543 4.51451 16.4544 4.5146 16.4545 4.51469L16.4556 4.5159L16.4638 4.5247C16.4715 4.53309 16.4838 4.54648 16.5004 4.56474C16.5335 4.60128 16.5836 4.6573 16.6482 4.73182C16.7775 4.8809 16.9647 5.10374 17.1906 5.39243C17.6429 5.97041 18.2477 6.80895 18.8522 7.84495C20.0672 9.92704 21.25 12.7526 21.25 15.8368C21.25 19.0478 20.6949 20.8929 19.9325 21.9239C19.2094 22.9019 18.2199 23.25 17 23.25C16.5858 23.25 16.25 23.5858 16.25 24C16.25 24.4142 16.5858 24.75 17 24.75C18.5416 24.75 20.0521 24.2851 21.1386 22.8157C22.1859 21.3994 22.75 19.163 22.75 15.8368C22.75 12.3838 21.4328 9.29094 20.1478 7.08893C19.5023 5.98289 18.8571 5.08797 18.3719 4.46799C18.1291 4.15769 17.9257 3.91543 17.7815 3.74916C17.7094 3.666 17.652 3.60178 17.6119 3.55749C17.5918 3.53534 17.5761 3.51817 17.5649 3.5061L17.5516 3.49184L17.5477 3.4876L17.5464 3.48622L17.5459 3.4857C17.5457 3.4855 17.5455 3.48532 17 4L17.5455 3.48532C17.2613 3.18403 16.7866 3.17022 16.4853 3.45448C16.1841 3.73862 16.1702 4.21305 16.4542 4.51435L16.4542 4.51442Z" />
            </svg>
        )
    }
};