import { IDelegate as IGetManyDelegate, IGetManyByDelegatorEndpoint } from "./GetManyByDelegatorEndpoint";
import { ICreateManyByDelegatorEndpoint } from "./CreateManyByDelegatorEndpoint";
import { IDeleteManyEndpoint } from "./DeleteManyEndpoint";
import { IPrimariesDelegatorEndpoint } from "./GetPrimariesDelegatorEndpoint";
import { PagedResponse } from "../Models";

export class DelegateRepository implements IDelegateRepository
{
    private getManyByDelegatorEndpoint: IGetManyByDelegatorEndpoint;
    private createManyByDelegatorEndpoint: ICreateManyByDelegatorEndpoint;
    private deleteManyEndpoint: IDeleteManyEndpoint;
    private getPrimaryDelegatorsEndpoint: IPrimariesDelegatorEndpoint;

    constructor(
        getManyByDelegatorEndpoint: IGetManyByDelegatorEndpoint,
        createManyByDelegatorEndpoint: ICreateManyByDelegatorEndpoint,
        deleteManyEndpoint: IDeleteManyEndpoint,
        getPrimaryDelegatorsEndpoint: IPrimariesDelegatorEndpoint
    )
    {
        this.getManyByDelegatorEndpoint = getManyByDelegatorEndpoint;
        this.createManyByDelegatorEndpoint = createManyByDelegatorEndpoint;
        this.deleteManyEndpoint = deleteManyEndpoint;
        this.getPrimaryDelegatorsEndpoint = getPrimaryDelegatorsEndpoint;
    }

    public getManyByDelegator(delegatorEmail: string): Promise<PagedResponse<IGetManyDelegate[]>>
    {
        return this.getManyByDelegatorEndpoint.execute(delegatorEmail);
    }

    public createManyByDelegator(delegatorEmail: string, delegateEmails: string[]): Promise<void>
    {
        return this.createManyByDelegatorEndpoint.execute(delegatorEmail, delegateEmails);
    }

    public deleteMany(delegateIds: string[]): Promise<void>
    {
        return this.deleteManyEndpoint.execute(delegateIds);
    }

    public getPrimaryDelegators(): Promise<PagedResponse<IGetManyDelegate[]>>
    {
        return this.getPrimaryDelegatorsEndpoint.execute();
    }
}

export interface IDelegateRepository
{
    getManyByDelegator(delegatorEmail: string): Promise<PagedResponse<IGetManyDelegate[]>>;
    createManyByDelegator(delegatorEmail: string, delegateEmails: string[]): Promise<void>;
    deleteMany(delegateIds: string[]): Promise<void>;
    getPrimaryDelegators(): Promise<PagedResponse<IGetManyDelegate[]>>;
}
