import { DateTime } from "luxon";
import { appContext } from "../AppContext";
import { DateHelper } from "../Common/DateHelper";
import { ISpaceUtilisationSummary } from "../Providers.Api/SpaceUtilisationSummaries/GetManyEndpoint";

export class SpaceUtilisationSummaryService implements ISpaceUtilisationSummaryService
{
    private get summaryRepo() { return appContext().apiClient.spaceUtilisationSummaries; }

    public async getSpaceUtilisationSummaries(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<ISpaceUtilisationSummary[]>
    {
        periodStart = periodStart.date();
        periodEnd = periodEnd.date();

        const buildingZone = DateHelper.getZoneByNode(nodeId);
        const multiZone = (buildingZone == "UTC");
        const multiZonePeriodStart = periodStart.minus({ days: multiZone ? 1 : 0 }).setZoneByNode(nodeId, true);
        const multiZonePeriodEnd = periodEnd.plus({ days: multiZone ? 1 : 0 }).setZoneByNode(nodeId, true);

        let summaries = await this.summaryRepo.getMany(nodeId, nodeLevelName, spaceClass, spaceTypeLabel, periodType,
            multiZonePeriodStart, multiZonePeriodEnd, floorNodeId, bookable, sortKey, sortAscending);

        if (multiZone)
        {
            summaries = summaries.filter(i =>
            {
                const summaryStartDate = DateTime.fromISO(i.Period_Start_Date).offsetTimeByNode(i.Node_Id, true).date();
                const summaryEndDate = DateTime.fromISO(i.Period_End_Date).offsetTimeByNode(i.Node_Id, true).date();
                const isSummaryBetweenTimes = (summaryStartDate >= periodStart && summaryEndDate < periodEnd);
                return isSummaryBetweenTimes;
            });
        }

        return summaries;
    }
}

export interface ISpaceUtilisationSummaryService
{
    getSpaceUtilisationSummaries(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<ISpaceUtilisationSummary[]>;
}
