import * as React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Radio, RadioProps } from '@mui/material';

export default class IbssRadioButton extends React.Component<IProps>{

    public changed(e: React.ChangeEvent<HTMLInputElement>)
    {
        this.props.valueChanged(e.target.value)
    }

    public render(): JSX.Element
    {
        return (
            <FormControl>
                <FormLabel>{this.props.children}</FormLabel>
                <RadioGroup row={this.props.horizontal} onChange={(e) => this.changed(e)} value={this.props.value} >
                    {this.props.option.map(item =>
                    {
                        return <FormControlLabel
                            key={item.value}
                            control={<Radio />}
                            value={item.value}
                            label={item.label}
                            disabled={item.disabled}
                            labelPlacement={item.labelplacement}
                        />
                    })}
                </RadioGroup>
            </FormControl>
        );
    }
}

export interface IProps extends RadioProps
{
    option: Array<IOption>;
    horizontal?: boolean;
    valueChanged: (newValue: string) => void;
    value?: string | number;
    children?: React.ReactNode;
}

export interface IOption
{
    label: string;
    disabled?: boolean;
    labelplacement: "end" | "start" | "top" | "bottom";
    value: string;
}
