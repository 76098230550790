import axios, { AxiosError } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";

export class CreateManyByDelegatorEndpoint implements ICreateManyByDelegatorEndpoint
{
    public async execute(delegatorEmail: string, delegateEmails: string[]): Promise<void>
    {
        try
        {
            const payload = delegateEmails.map(i =>
            ({
                Scope: 0,
                PrimaryEmail: delegatorEmail,
                DelegateEmail: i,
            }));

            await axios.post(`${apis.userApiBaseUrl}/api/user/v1/Delegates`, payload);
        }
        catch (error)
        {
            throw ApiError.fromAxiosError(error as AxiosError);
        }
    }
}

export interface ICreateManyByDelegatorEndpoint
{
    execute(delegatorEmail: string, delegateEmails: string[]): Promise<void>;
}
