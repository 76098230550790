import { appContext } from "../AppContext";
import { PagedResponse } from "../Providers.Api/Models";
import { ODataQuery } from "../Providers.Api/ODataQuery";
import { ICreateV2Visit } from "../Providers.Api/Visits/CreateV2VisitEndpoint";
import { IEditV2Visit } from "../Providers.Api/Visits/EditV2VisitEndpoint";
import { ICreateVisitResponse, IEditVisitResponse, IVisit } from "../Providers.Api/Visits/VisitsRepository";

export class VisitsService implements IVisitsService
{
    private get api() { return appContext().apiClient; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Visits.V2"); }

    public async getMany<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>
    {
        if (this.hasV2Rights)
        {
            return await this.api.visits.getManyV2(query);
        }
        else
        {
            const v1VisitsResponse = await this.api.visits.getMany(query)
            const response = { skipToken: '', value: v1VisitsResponse }
            return response;
        }
    }

    public async getById<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>
    {
        if (this.hasV2Rights)
        {
            return await this.api.visits.getByIdV2(type, nodeId, visitId);
        }
        else
        {

            return await this.api.visits.getById(type, nodeId, visitId);
        }
    }

    public async create(nodeId: number, visit: ICreateV2Visit): Promise<ICreateVisitResponse>
    {
        if (this.hasV2Rights)
        {
            return await this.api.visits.createV2Visit(nodeId, visit);
        }
        else
        {
            const v1Visit: IVisit =
            {
                ...visit,
                DisableExtUpdate: visit.Disable_Ext_Update
            };

            return await this.api.visits.createVisit(nodeId, v1Visit);
        }
    }

    public async edit(nodeId: number, visitId: string, visit: ICreateV2Visit): Promise<ICreateVisitResponse>
    {
        if (this.hasV2Rights)
        {
            return await this.api.visits.editV2Visit(nodeId, visitId, visit);
        }
        else
        {
            const v1Visit: IVisit =
            {
                ...visit,
                DisableExtUpdate: false
            };

            return await this.api.visits.editVisit(nodeId, visitId, v1Visit);
        }
    }

    public async approve(nodeId: number, visitIds: string[], comment: string): Promise<void>
    {
        if (this.hasV2Rights)
        {
            const approvePayload = visitIds.map(x => ({ Visit_Id: x, Visit_Approve_Comment: comment }));
            return await this.api.visits.approveVisitsV2(nodeId, approvePayload);
        }
        else
        {
            const payload = {
                Visit_IsApproved: 1,
                Visit_Approval_Comments: comment
            }
            visitIds.map(async id =>
            {
                return await this.api.visits.visitAction(nodeId, id, payload);
            });
        }
    }

    public async cancel(nodeId: number, visitIds: string[]): Promise<void>
    {
        if (this.hasV2Rights)
        {
            const cancelPayload = visitIds.map(x => ({ Visit_Id: x }));
            return await this.api.visits.cancelVisitsV2(nodeId, cancelPayload);
        }
        else
        {
            const payload = {
                Visit_IsCancelled: 1
            }
            visitIds.map(async id =>
            {
                return await this.api.visits.visitAction(nodeId, id, payload);
            });
        }
    }

    public async checkin(nodeId: number, visitIds: string[]): Promise<void>
    {
        if (this.hasV2Rights)
        {
            const checkinPayload = visitIds.map(x => ({ Visit_Id: x }));
            return await this.api.visits.checkinVisitsV2(nodeId, checkinPayload);
        }
        else
        {
            const payload = {
                Visit_IsCheckedIn: 1
            }
            visitIds.map(async id =>
            {
                return await this.api.visits.visitAction(nodeId, id, payload);
            });
        }
    }

    public async checkout(nodeId: number, visitIds: string[]): Promise<void>
    {
        if (this.hasV2Rights)
        {
            const checkoutPayload = visitIds.map(x => ({ Visit_Id: x }))
            return await this.api.visits.checkoutVisitsV2(nodeId, checkoutPayload);
        }
        else
        {
            const payload = {
                Visit_IsCheckedOut: 1
            }
            visitIds.map(async id =>
            {
                return await this.api.visits.visitAction(nodeId, id, payload);
            });
        }
    }

    public async deny(nodeId: number, visitIds: string[], comment: string): Promise<void>
    {
        if (this.hasV2Rights)
        {
            const denyPayload = visitIds.map(x => ({ Visit_Id: x, Visit_Denial_Comment: comment }));
            return await this.api.visits.denyVisitsV2(nodeId, denyPayload);
        }
        else
        {
            const payload = {
                Visit_IsDenied: 1,
                Visit_Approval_Comments: comment
            }
            visitIds.map(async id =>
            {
                return await this.api.visits.visitAction(nodeId, id, payload);
            });
        }
    }
}

export interface IVisitsService
{
    getMany<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>;
    create(nodeId: number, visit: ICreateV2Visit): Promise<ICreateVisitResponse>;
    getById<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>;
    edit(nodeId: number, visitId: string, visit: IEditV2Visit): Promise<IEditVisitResponse>;
    approve(nodeId: number, visitIds: string[], comment: string): Promise<void>;
    cancel(nodeId: number, visitIds: string[]): Promise<void>;
    checkin(nodeId: number, visitIds: string[]): Promise<void>;
    checkout(nodeId: number, visitIds: string[]): Promise<void>;
    deny(nodeId: number, visitIds: string[], comment: string): Promise<void>;
}
