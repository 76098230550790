import { Component } from 'react';

export default class Informallounge extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C2.75736 10.75 1.75 11.7574 1.75 13C1.75 13.9836 2.38155 14.8216 3.26348 15.1269C3.94215 15.3619 4.75 15.9975 4.75 17V27C4.75 27.6904 5.30964 28.25 6 28.25H17.5V29.75H6C4.48122 29.75 3.25 28.5188 3.25 27V17C3.25 16.9441 3.22919 16.8708 3.14828 16.7813C3.0634 16.6873 2.93026 16.5989 2.77273 16.5444C1.30538 16.0363 0.25 14.6424 0.25 13C0.25 10.9289 1.92893 9.25 4 9.25C6.07107 9.25 7.75 10.9289 7.75 13V24.25H17.5V25.75H6.25V13C6.25 11.7574 5.24264 10.75 4 10.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0909 10.75C31.2618 10.75 32.25 11.7354 32.25 13C32.25 13.99 31.6394 14.8166 30.811 15.122C30.1504 15.3656 29.3409 15.9975 29.3409 17V27C29.3409 27.6904 28.7813 28.25 28.0909 28.25H17V29.75H28.0909C29.6097 29.75 30.8409 28.5188 30.8409 27V17C30.8409 16.945 30.8614 16.8709 30.9445 16.7784C31.0315 16.6814 31.168 16.5891 31.3299 16.5294C32.751 16.0054 33.75 14.6149 33.75 13C33.75 10.9509 32.1334 9.25 30.0909 9.25C28.0485 9.25 26.4318 10.9509 26.4318 13V24.25H17V25.75H27.9318V13C27.9318 11.7354 28.9201 10.75 30.0909 10.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 21C6.25 18.9289 7.92893 17.25 10 17.25H24C26.0711 17.25 27.75 18.9289 27.75 21V25.75H6.25V21ZM10 18.75C8.75736 18.75 7.75 19.7574 7.75 21V24.25H26.25V21C26.25 19.7574 25.2426 18.75 24 18.75H10Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 10C4.25 6.27208 7.27208 3.25 11 3.25H23C26.7279 3.25 29.75 6.27208 29.75 10V10.5H28.25V10C28.25 7.10051 25.8995 4.75 23 4.75H11C8.10051 4.75 5.75 7.10051 5.75 10V10.5H4.25V10Z" />
                <path d="M5 29H10V31H5V29Z" />
                <path d="M24 29H29V31H24V29Z" />
            </svg>
        )
    }
};