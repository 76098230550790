import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DataEntryRole } from "./RoleRepository";

export class GetAllEndpoint implements IGetAllEndpoint
{
    public async execute(): Promise<DataEntryRole[]>
    {
        try
        {
            const response = await axios.get<DataEntryRole[]>(`${apis.dataEntrySecurityApi}/Roles`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetAllEndpoint
{
    execute(): Promise<DataEntryRole[]>;
}
