import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UploadFileEndpoint implements IUploadFileEndpoint
{
    public async execute(fileData: FormData, pathValue: string, processAsMap: boolean): Promise<void>
    {
        try
        {
            const response: AxiosResponse<void> = await axios.post(`${apis.dataEntryWebApi}/File/${pathValue}?ProcessAsMapFile=${processAsMap}`, fileData);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUploadFileEndpoint
{
    execute(fileData: FormData, pathValue: string, processAsMap: boolean): Promise<void>;
}
