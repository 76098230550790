import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IUser } from "./UsersRepository";

export class GetUsersEndpoint implements IGetUsersEndpoint
{
    public async execute(name: string): Promise<IUser[]>
    {
        try
        {
            const response: AxiosResponse<IUser[]> = await axios.get(`${apis.allApi}/Users/${name}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetUsersEndpoint
{
    execute(name: string): Promise<IUser[]>;
}
