import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IBookingPolicies } from "./BookingPolicyRepository";

export class GetBookingPoliciesEndpoint implements IGetBookingPoliciesEndpoint
{
    public async execute(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>
    {
        try
        {
            let query =
                `$select=Booking_Policy_Id,Booking_Policy_Name,Booking_Policy&` +
                `$filter=Node_Id eq ${nodeId}&` +
                `$top=${top}&` +
                `$skipToken=${skipToken}`;

            const response: AxiosResponse<IBookingPolicies> = await axios.get(`${apis.dataEntryApiV2}${nodeId}/BookingPolicies?${query}`);
            response.data.value.map(i => { i.Booking_Policy_Object = JSON.parse(i.Booking_Policy); });
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetBookingPoliciesEndpoint
{
    execute(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>;
}
