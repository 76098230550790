import { Component } from 'react';

export default class WorkingDayIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1766 1.76463C6.42638 1.76463 1.76488 6.42614 1.76488 12.1764C1.76488 17.9267 6.42638 22.5882 12.1766 22.5882C17.9269 22.5882 22.5884 17.9267 22.5884 12.1764C22.5884 6.42614 17.9269 1.76463 12.1766 1.76463ZM0.706055 12.1764C0.706055 5.84137 5.84161 0.705811 12.1766 0.705811C18.5117 0.705811 23.6472 5.84137 23.6472 12.1764C23.6472 18.5114 18.5117 23.647 12.1766 23.647C5.84161 23.647 0.706055 18.5114 0.706055 12.1764Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5294 5.64697C12.8218 5.64697 13.0588 5.884 13.0588 6.17638V11.8234C13.0588 12.1158 12.8218 12.3529 12.5294 12.3529C12.237 12.3529 12 12.1158 12 11.8234V6.17638C12 5.884 12.237 5.64697 12.5294 5.64697Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1551 11.4493C12.3618 11.2425 12.697 11.2425 12.9038 11.4493L16.4332 14.9787C16.6399 15.1854 16.6399 15.5206 16.4332 15.7274C16.2264 15.9341 15.8912 15.9341 15.6845 15.7274L12.1551 12.198C11.9483 11.9912 11.9483 11.656 12.1551 11.4493Z" />
            </svg>
        )
    }
};
