import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import apis from "../apis";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<ISpaceUtilisationSummary[]>
    {
        try
        {
            let filterParts = new Array<string>();
            filterParts.push(`Node_Level_Name eq '${(floorNodeId !== "Any" && nodeLevelName === "Building") ? "Floor" : nodeLevelName}'`);
            filterParts.push(`Space_Class eq '${spaceClass}'`);
            filterParts.push(`Period_Type eq ${periodType}`);
            filterParts.push(`Period_Start_Date ge datetime'${periodStart.toUTC().toISO()}'`);
            filterParts.push(`Period_Start_Date lt datetime'${periodEnd.toUTC().toISO()}'`);

            if (bookable != 0)
            {
                switch (bookable)
                {
                    case 1345:
                        filterParts.push(`(Meta_Bookable eq 1 or Meta_Bookable eq 3 or Meta_Bookable eq 4 or Meta_Bookable eq 5)`);
                        break;
                    case 2:
                        filterParts.push(`(Meta_Bookable eq 2)`);
                        break;
                    case 12345:
                        filterParts.push(`(Meta_Bookable eq 1 or Meta_Bookable eq 2 or Meta_Bookable eq 3 or Meta_Bookable eq 4 or Meta_Bookable eq 5)`);
                        break;
                }
            }

            if (floorNodeId != "Any" && floorNodeId != "")
            {
                filterParts.push(`Node_Id eq ${floorNodeId}`);
            }

            if (nodeLevelName != "Space" || spaceTypeLabel != "Total")
            {
                filterParts.push(`Space_Type_Label ${spaceTypeLabel === "Total" && nodeId !== 1 && nodeLevelName === "Building" ? "ne" : 'eq'} '${spaceTypeLabel === "Total" && nodeId !== 1 && nodeLevelName === "Building" ? "Total" : spaceTypeLabel}'`);
            }

            const select = `Space_Id,Space_Type,Space_Class,Node_Id,Node_Level_Name,Space_Name,Space_Type_Label,Period_Start_Date,Period_End_Date,Period_Type,Period_Current_Space_Value,Period_Previous_Space_Value,Period_Change_Space_Value,Period_Type_Value,Summary_Id,Period_Current_Capacity_Value,Period_Previous_Capacity_Value,Period_Change_Capacity_Value,Period_Current_Peak_Value,Period_Previous_Peak_Value,Period_Change_Peak_Value,Period_Current_Max_Occ_Value,Period_Previous_Max_Occ_Value,Period_Current_Avg_Occ_Value,Period_Previous_Avg_Occ_Value,Period_Change_Max_Occ_Value,Period_Change_Avg_Occ_Value,Meta_Bookable`;
            const filter = filterParts.join(" and ");
            const orderBy = (sortKey == "" ? "" : `${sortKey} ${sortAscending ? "desc" : "asc"}`);
            const response = await axios.get(`${apis.allApi}${(floorNodeId === "Any" || floorNodeId === "") ? nodeId : floorNodeId}/SpaceUtilisation/SpaceUtilisationData?recursive=true&$select=${select}&$filter=${filter}&$orderby=${orderBy}&$top=500`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<ISpaceUtilisationSummary[]>;
}

export interface ISpaceUtilisationSummary
{
    Node_Id: number;
    Node_Level_Name: string;
    Space_Id: string;
    Space_Type: string;
    Space_Class: string;
    Space_Name: string;
    Space_Type_Label: string;
    Summary_Id: string;
    Period_Start_Date: string;
    Period_End_Date: string;
    Period_Type: number;
    Period_Current_Space_Value: number;
    Period_Previous_Space_Value: number;
    Period_Change_Space_Value: number;
    Period_Type_Value: number;
    Period_Current_Capacity_Value: number;
    Period_Previous_Capacity_Value: number;
    Period_Change_Capacity_Value: number;
    Period_Current_Peak_Value: number;
    Period_Previous_Peak_Value: number;
    Period_Change_Peak_Value: number;
    Period_Current_Max_Occ_Value: number;
    Period_Previous_Max_Occ_Value: number;
    Period_Current_Avg_Occ_Value: number;
    Period_Previous_Avg_Occ_Value: number;
    Period_Change_Max_Occ_Value: number;
    Period_Change_Avg_Occ_Value: number;
    Meta_Bookable: number;
}
