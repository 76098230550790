import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { Box, Grid } from '@mui/material';
import IbssHorizontalTabs, { TabsParameters } from '../../../../Components/Layout/Tabs/IbssTabs';
import { appContext } from '../../../../AppContext';
import Building from './Building';
import SearchResult from './SearchResult';
import WorkingDay from './WorkingDay';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';

class EditWorkplacePreferences extends Component<any, any> {
    private labels = appContext().labels;

    state = {
        isLoading: false,
    };

    render() {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubMenuWorkplacePreferences });

        const listTabs: TabsParameters[] = [
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.building}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelBuilding}
                        </div>
                    </div>
                ),
                components: <Building />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.workingDayIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelworkingDay}
                        </div>
                    </div>
                ),
                components: <WorkingDay />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.searchResultIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelSearchResults}
                        </div>
                    </div>
                ),
                components: <SearchResult />,
            },
        ];
        return (
            <>
            <Box className="page-height-exct-header">
                {this.state.isLoading && <LoadingOverlay />}
                <Box className='rightPanel-main-content'>
                    <Grid container gap={15} columns={16}>
                        <IbssHorizontalTabs
                            className='ibss-vertical-tab'
                            orientation='vertical'
                            tabs={listTabs}
                            boxwidth="344px"
                            sx={{
                                fontSize: "18px",
                                marginTop: "25px",
                                fontFamily: "Source Sans Pro",
                                backgroundColor: `background.paper`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            leftAlignTabContent
                        />
                    </Grid>
                </Box>
            </Box>
            </>
        )
    }
};

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(EditWorkplacePreferences);

