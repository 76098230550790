import { Component } from 'react';

export default class Printroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 21C1.25 15.1434 5.68424 10.25 11.2308 10.25H22.6769C28.3158 10.25 32.75 15.1434 32.75 21.1V26.1C32.75 27.583 31.614 28.75 30.2462 28.75H26.5C26.0858 28.75 25.75 28.4142 25.75 28C25.75 27.5858 26.0858 27.25 26.5 27.25H30.2462C30.7245 27.25 31.25 26.817 31.25 26.1V21.1C31.25 15.8566 27.3765 11.75 22.6769 11.75H11.2308C6.62345 11.75 2.75 15.8566 2.75 21V25.4C2.75 26.4631 3.50287 27.25 4.4 27.25H7.5C7.91421 27.25 8.25 27.5858 8.25 28C8.25 28.4142 7.91421 28.75 7.5 28.75H4.4C2.5279 28.75 1.25 27.1369 1.25 25.4V21Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0816 1.45802C12.2212 1.32451 12.4069 1.25 12.6 1.25H25.5C25.9142 1.25 26.25 1.58579 26.25 2V11C26.25 11.4142 25.9142 11.75 25.5 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11V6.4C7.25 6.19531 7.33366 5.99951 7.48158 5.85802L12.0816 1.45802ZM12.9009 2.75L8.75 6.72047V10.25H24.75V2.75H12.9009Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2 1.45C13.6142 1.45 13.95 1.78578 13.95 2.2V7.2C13.95 7.4018 13.8687 7.59509 13.7244 7.73619C13.5801 7.87729 13.3851 7.9543 13.1833 7.94981L8.18332 7.8387C7.76921 7.8295 7.44097 7.48633 7.45017 7.07222C7.45937 6.65811 7.80254 6.32987 8.21665 6.33907L12.45 6.43315V2.2C12.45 1.78578 12.7858 1.45 13.2 1.45Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 25.798C9.7511 25.3838 10.0878 25.0489 10.502 25.05C11.1862 25.0518 11.8375 25.0536 12.457 25.0552C12.6875 25.0548 12.9405 25.0545 13.217 25.0541C15.1698 25.0516 18.2999 25.05 23 25.05C23.1463 25.05 23.2829 25.0919 23.3983 25.1644C23.4524 25.1859 23.5098 25.2119 23.5625 25.2422C23.5758 25.277 23.5872 25.3115 23.5967 25.3456C23.6929 25.4717 23.75 25.6292 23.75 25.8C23.75 26.1044 23.5687 26.3664 23.3082 26.4839C23.2504 26.5494 23.2045 26.5862 23.1917 26.5881C23.1766 26.5895 23.1545 26.5911 23.1469 26.5915C23.1352 26.5921 23.1249 26.5923 23.1208 26.5924L23.085 26.5929C23.0591 26.5932 23.0223 26.5934 22.9762 26.5936C22.8835 26.5939 22.748 26.5942 22.5763 26.5943C22.2326 26.5946 21.7413 26.5944 21.1517 26.5939C19.9725 26.593 18.3987 26.5906 16.8235 26.5875C15.2483 26.5844 13.6714 26.5805 12.4857 26.5764C11.8929 26.5743 11.3975 26.5722 11.0487 26.5701C10.8746 26.5691 10.736 26.5681 10.6399 26.567C10.5922 26.5665 10.553 26.566 10.5245 26.5654C10.5108 26.5651 10.4961 26.5648 10.4829 26.5643C10.4771 26.5641 10.4657 26.5636 10.4525 26.5627C10.445 26.5622 10.4264 26.5605 10.4146 26.5593C10.3905 26.5561 10.2879 26.5335 10.2115 26.5064C10.2051 26.4991 10.1989 26.4918 10.1928 26.4844C9.93112 26.3668 9.74919 26.1035 9.75 25.798ZM23.0838 26.5915C23.0844 26.5915 23.0843 26.5915 23.0837 26.5915L23.0838 26.5915Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 23C6.75 22.5858 7.08579 22.25 7.5 22.25H26.5C26.9142 22.25 27.25 22.5858 27.25 23V32C27.25 32.4142 26.9142 32.75 26.5 32.75H7.5C7.08579 32.75 6.75 32.4142 6.75 32V23ZM8.25 23.75V31.25H25.75V23.75H8.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 28.798C9.7511 28.3838 10.0878 28.0489 10.502 28.05C11.1862 28.0518 11.8375 28.0536 12.457 28.0552C12.6875 28.0548 12.9405 28.0545 13.217 28.0541C15.1698 28.0516 18.2999 28.05 23 28.05C23.1463 28.05 23.2829 28.0919 23.3983 28.1644C23.4524 28.1859 23.5098 28.2119 23.5625 28.2422C23.5758 28.277 23.5872 28.3115 23.5967 28.3456C23.6929 28.4717 23.75 28.6292 23.75 28.8C23.75 29.1044 23.5687 29.3664 23.3082 29.4839C23.2504 29.5494 23.2045 29.5862 23.1917 29.5881C23.1766 29.5895 23.1545 29.5911 23.1469 29.5915C23.1352 29.5921 23.1249 29.5923 23.1208 29.5924L23.085 29.5929C23.0591 29.5932 23.0223 29.5934 22.9762 29.5936C22.8835 29.5939 22.748 29.5942 22.5763 29.5943C22.2326 29.5946 21.7413 29.5944 21.1517 29.5939C19.9725 29.593 18.3987 29.5906 16.8235 29.5875C15.2483 29.5844 13.6714 29.5805 12.4857 29.5764C11.8929 29.5743 11.3975 29.5722 11.0487 29.5701C10.8746 29.5691 10.736 29.5681 10.6399 29.567C10.5922 29.5665 10.553 29.566 10.5245 29.5654C10.5108 29.5651 10.4961 29.5648 10.4829 29.5643C10.4771 29.5641 10.4657 29.5636 10.4525 29.5627C10.445 29.5622 10.4264 29.5605 10.4146 29.5593C10.3905 29.5561 10.2879 29.5335 10.2115 29.5064C10.2051 29.4991 10.1989 29.4918 10.1928 29.4844C9.93112 29.3668 9.74919 29.1035 9.75 28.798ZM23.0838 29.5915C23.0844 29.5915 23.0843 29.5915 23.0837 29.5915L23.0838 29.5915Z" />
            </svg>
        )
    }
};