import { BlockType, LeafType } from "remark-slate";
import { Descendant } from "slate";
import { IBlock, INode } from "../Models";
import { Helper } from "../Helper";

export class SlateToMarkdownAdapter
{
    private helper: Helper;

    constructor(helper: Helper)
    {
        this.helper = helper;
    }

    public async execute(slate: INode[], ancestors: IBlock[]): Promise<string>
    {
        const markdownParts: string[] = [];
        const parent = (ancestors.length == 0 ? null : ancestors[ancestors.length - 1]);
        const isCodeBlock = ancestors.some(i => i.type == 'code_block');

        for (const node of slate)
        {
            let markdown = "";
            const block = this.helper.asBlock(node);
            const textNode = this.helper.asText(node);

            if (block)
            {
                const parentType = parent?.type ?? '';
                const innerMarkdown = await this.execute(block.children, [...ancestors, block]);

                switch (block.type)
                {
                    case "heading_one":
                        markdown += `# ${innerMarkdown}\n\n`;
                        break;

                    case "heading_two":
                        markdown += `## ${innerMarkdown}\n\n`;
                        break;

                    case "heading_three":
                        markdown += `### ${innerMarkdown}\n\n`;
                        break;

                    case "heading_four":
                        markdown += `#### ${innerMarkdown}\n\n`;
                        break;

                    case "heading_five":
                        markdown += `##### ${innerMarkdown}\n\n`;
                        break;

                    case "heading_six":
                        markdown += `###### ${innerMarkdown}\n\n`;
                        break;

                    case 'ol_list':
                    case 'ul_list':
                        markdown += `${innerMarkdown}\n`;
                        break;

                    case 'list_item':
                        markdown += `${(parentType == 'ol_list' ? `1.` : `-`)} ${innerMarkdown} \n`;
                        break;

                    case 'code_block':
                        markdown += `\`\`\`\n${innerMarkdown}\n\`\`\`\n\n`;
                        break;

                    case 'thematic_break':
                        markdown += `---\n\n`;
                        break;

                    default: // paragraph
                        markdown += `${innerMarkdown}${parentType == 'code_block' ? '' : '\n'}\n`;
                        break;
                }
            }
            else if (textNode)
            {
                const text = (isCodeBlock ? textNode.text : this.escapeMarks(textNode.text));
                const openLink = textNode.link ? '[' : '';
                const closeLink = textNode.link ? `](${textNode.link})` : '';
                const boldWrapper = textNode.bold ? '**' : '';
                const italicWrapper = textNode.italic ? '*' : '';
                markdown = `${openLink}${boldWrapper}${italicWrapper}${text}${italicWrapper}${boldWrapper}${closeLink}`;
            }
            else
            {
                throw new Error("Not supported.");
            }
            markdownParts.push(markdown);
        }
        return markdownParts.join("");
    }

    private escapeMarks(value: string): string
    {
        return value
            .replace(/\\/g, '\\\\')
            .replace(/`/g, '\\`')
            .replace(/\*/g, '\\*')
            .replace(/_/g, '\\_')
            .replace(/\{/g, '\\{')
            .replace(/\}/g, '\\}')
            .replace(/\[/g, '\\[')
            .replace(/\]/g, '\\]')
            .replace(/</g, '\\<')
            .replace(/>/g, '\\>')
            .replace(/\(/g, '\\(')
            .replace(/\)/g, '\\)')
            .replace(/#/g, '\\#')
            .replace(/\+/g, '\\+')
            .replace(/-/g, '\\-')
            .replace(/\./g, '\\.')
            .replace(/!/g, '\\!')
            .replace(/\|/g, '\\|');
    }
}
