import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class SpacesDailySummaryRepository implements ISpacesDailySummaryRepository
{
    public async getSpacesDailySummary(nodeId: number,startDate: string, endDate: string, spaceName: string): Promise<ISpacesDailySummary[]>
    {
        let queryString = `Space_Id,Space_Work_Type,Space_Type,Space_IsEnabled,Space_Class,Node_Id,Summary_Date,Record_Id,Space_Name,Env_Zone_Id,IsWorking_Day,Avg_Occupancy,Max_Occupancy,Space_Used,Period_Start,Period_End,Period_Duration,Period_Space_Utilisation,Period_Capacity_Utilisation,Period_Peak_Utilisation,S5,S6,S7,S8,S9,S10,S11,S12,S13,S14,S15,S16,S17,S18,S19,S24,S25,S26,S27,S28,S29,S30,S31,Booked_Duration,Space_Type_Label,Summary_Day_Type,S36,S37,S38,S39`;
        try
        {
            const response: AxiosResponse<ISpacesDailySummary[]> = await axios.get(`${apis.allApi}${nodeId}/SpacesDailySummary/${spaceName}?startDate=${startDate.slice(0,10)}&endDate=${endDate.slice(0,10)}&%24select=${queryString}&$filter=Summary_Day_Type eq 'WorkingHours'&isUtc=true`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ISpacesDailySummaryRepository
{
    getSpacesDailySummary(nodeId: number, startDate: string, endDate: string, spaceName: string): Promise<ISpacesDailySummary[]>;
}

export interface ISpacesDailySummary
{
    Avg_Occupancy: number;
    Booked_Duration: number;
    Env_Zone_Id: string;
    IsWorking_Day: number;
    Max_Occupancy: number;
    Node_Id: number;
    Period_Capacity_Utilisation: number;
    Period_Duration: number;
    Period_End: string;
    Period_Peak_Utilisation: number;
    Period_Space_Utilisation: number;
    Period_Start: string;
    Record_Id: string;
    S5: number;
    S6: number;
    S7: number;
    S8: number;
    S9: number;
    S10: number;
    S11: number;
    S12: number;
    S13: number;
    S14: number;
    S15: number;
    S16: number;
    S17: number;
    S18: number;
    S19: number;
    S24: number;
    S25: number;
    S26: number;
    S27: number;
    S28: number;
    S29: number;
    S30: number;
    S31: number;
    S36: number;
    S37: number;
    S38: number;
    S39: number;
    Space_Class: string;
    Space_Id: string;
    Space_IsEnabled: number;
    Space_Name: string;
    Space_Type: string;
    Space_Type_Label: string;
    Space_Used: number;
    Space_Work_Type: string;
    Summary_Date: string;
    Summary_Day_Type: string;
}
