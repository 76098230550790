import { Component } from "react";
import "../../../styles/css/dashboard.scss";
import "../../../App.css";
import { connect } from "react-redux";
import { appContext } from "../../../AppContext";
import { IUserDetails } from "../../../Providers.LocalStorage/Models";
import { IUserRights } from "../../../Providers.Api/Models.UserApi";
import { RouteComponentProps } from "react-router-dom";

class Home extends Component<RouteComponentProps>
{
    private labels = appContext().labels;
    private appState = appContext().state;
    private local = appContext().localStorageProvider;

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.authCheck();
    }

    public authCheck(): void
    {
        const token = this.local.getToken();
        if (!token)
        {
            this.pushHistory("/login");
        }
    }

    public componentDidMount() 
    {
        const userDetails = this.local.getUserDetails()
        if (userDetails?.userRights) 
        {
            this.redirect(userDetails.userRights);
        }
    }

    private redirect(rights: IUserRights): void
    {
        if (Object.keys(rights).length === 0)
        {
            this.pushHistory("/access-denied");
        }
        else if(rights)
        {
            // redirect user after login to flex homepage instead of immediately routing to /flex-my-bookings page.
            this.pushHistory("/flex-home/");
        }
        else
        {
            this.pushHistory("/norights");
        }
    }

    private pushHistory(url: string): void
    {
        this.props.history.replace(url);
    }

    public render(): JSX.Element
    {
        return (
            <div>
                <div className="redirectContainer">
                    <div className="title">{this.labels.HubLabelRedirectionStatus}</div>
                    <div className="subtitle">{this.labels.HubLabelRedirectionSubtitle_part_1} <span id="countdown"></span></div>
                </div>
            </div>
        );
    }
}

export default connect()(Home);
