import { Component } from 'react';

export default class Toiletsmale extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 4.5C14.75 3.5335 15.5335 2.75 16.5 2.75C17.4665 2.75 18.25 3.5335 18.25 4.5C18.25 5.4665 17.4665 6.25 16.5 6.25C15.5335 6.25 14.75 5.4665 14.75 4.5ZM16.5 1.25C14.7051 1.25 13.25 2.70507 13.25 4.5C13.25 6.29493 14.7051 7.75 16.5 7.75C18.2949 7.75 19.75 6.29493 19.75 4.5C19.75 2.70507 18.2949 1.25 16.5 1.25ZM13.2282 8.25H19.7424C22.0585 8.25 23.9639 9.9337 23.7314 12.1881L23.1942 18.3858C23.1768 19.6895 22.1961 20.6526 21.4116 21.1616L21.3811 21.1814L20.9683 21.3957L20.0619 31.0916C20.0466 31.6023 19.7527 32.0161 19.4448 32.2761C19.1194 32.551 18.6713 32.75 18.1875 32.75H15C14.5161 32.75 14.0681 32.551 13.7427 32.2761C13.4357 32.0169 13.1426 31.6047 13.1258 31.0962L12.1127 21.3442L11.8313 21.1981C10.8314 20.6791 9.82336 19.7119 9.80583 18.3858L9.26887 12.1916C9.03025 10.062 10.971 8.42753 13.1988 8.25231L13.2282 8.25ZM21.6944 18.3506C21.6944 18.7234 21.4867 19.1119 21.1498 19.4549L21.7467 13.0698C21.7853 12.6574 21.4822 12.2918 21.0698 12.2533C20.6574 12.2147 20.2918 12.5178 20.2532 12.9302L18.5625 31.015V31.0301L18.5594 31.0361C18.5475 31.0576 18.5217 31.0924 18.477 31.1301C18.3806 31.2115 18.2661 31.25 18.1875 31.25H15C14.9213 31.25 14.8069 31.2115 14.7104 31.1301C14.6658 31.0924 14.64 31.0576 14.6281 31.0361L14.625 31.0301V31.0112L12.746 12.9225C12.7032 12.5105 12.3345 12.2112 11.9225 12.254C11.5105 12.2968 11.2112 12.6655 11.254 13.0775L11.9174 19.4637C11.516 19.1177 11.3056 18.7211 11.3056 18.3506V18.3182L10.7615 12.0414L10.7602 12.0308C10.6474 11.0544 11.5751 9.8965 13.2883 9.75H19.7424C21.3867 9.75 22.3618 10.8679 22.2388 12.0385L21.6944 18.3182V18.3506Z" />
            </svg>
        )
    }
};