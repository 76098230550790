import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../App.css";
import Header from "../../../../Components/Layout/Header/Header";
import Sidebar from "../../../../Components/Layout/Sidebar/Sidebar";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import { appContext } from "../../../../AppContext";

class EditSignage extends Component {
  private labels = appContext().labels;
  private local = appContext().localStorageProvider;
  state = {
    isLoadingToSave: false,
    filter: "",
    activePage: 0,
    loading: false,
    updates: false,
    nodeId: null,
    title: "",
    id: null,
    imageURI: null,
    meta_Loc_X_m: null,
    meta_Loc_Y_m: null,
    signage_Mode: null,
    signage_Orientation: null,
    signage_Show_Bldg_Name: null,
    zone_Config: "",
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter')
    })
    if (match.params.id !== "0" && match.params.nodeid) {
      this.getRightList();
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        id: parseInt(match.params.id),
        title: match.params.id,
        filter: match.params.filter
      }, () => {
        this.getDetails(this.state.id, this.state.filter);
      })
    } else {
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        title: "New Signage",
        filter: match.params.filter,
        id: 0,
        isUpdateRight: true,
      })
    }

  }

  getRightList = () => {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.Signage && res.ADMINPORTAL.Signage.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.Signage && res.ADMINPORTAL.Signage.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any, filter: any) => {
    this.setState({ loading: true });
    const nodeId = this.state.nodeId;

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }
    apis.getSignagesById(nodeId, id).then((res) => {
      this.setState({ ...res.data });
      this.setState({ loading: false });
    }).catch((error: any) => { });

  };

  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match, history }: any = this.props;
    const { updates, nodeId, id, imageURI, meta_Loc_X_m, meta_Loc_Y_m, signage_Mode, signage_Orientation,
      signage_Show_Bldg_Name, zone_Config }: any = this.state;

    let payload = {
      "imageURI": imageURI,
      "meta_Loc_X_m": parseInt(meta_Loc_X_m),
      "meta_Loc_Y_m": parseInt(meta_Loc_Y_m),
      "signage_Mode": parseInt(signage_Mode),
      "signage_Orientation": parseInt(signage_Orientation),
      "signage_Show_Bldg_Name": parseInt(signage_Show_Bldg_Name),
      "zone_Config": zone_Config,
    }

    if (match.params.id === "0") {
      apis.createSignage(nodeId, payload).then((res) => {
        const { history }: any = this.props;
        this.setState({
          updates: true,
          isLoadingToSave: false
        }, () => {
          history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Signage/nodeid=" + res.data.node_Id + "/id=" + res.data.signage_Id);
          window.location.reload();
        })
        // history.push("/portfolio-setup-spaces/filter=" + this.state.filter);
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      apis.updateSignageById(nodeId, id, payload).then((res) => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/12");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };
  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }

  delete = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    const nodeId = this.state.nodeId
    if (this.state.isDelete) {
      apis.deleteSignageById(nodeId, id).then((res) => {
        const { history }: any = this.props;
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/12");
      }).catch((error: any) => {
      });
    }
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, nodeId } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/12");
    }
  }

  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }


  linkPath = (data: any) => {
    const { filter } = this.state
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  render() {
    const { signage_Id, imageURI, meta_Loc_X_m, meta_Loc_Y_m, signage_Mode, signage_Orientation,
      signage_Show_Bldg_Name }: any = this.state;
    const { history }: any = this.props;

    const signage_IdFld = {
      name: "signage_Id",
      label: this.labels.HubLabelName,
      value: signage_Id,
      placeholder: this.labels.HubLabelNamePlaceholderText,
      onchange: this.change,
    }

    const signage_ModeFld = {
      name: "signage_Mode",
      label: this.labels.HubLabelsignageMode,
      value: signage_Mode,
      placeholder: this.labels.HubLabelsignagePlaceholder,
      onchange: this.change,
    }

    const signage_OrientationFld = {
      name: "signage_Orientation",
      label: this.labels.HubLabelOrientation,
      value: signage_Orientation,
      placeholder: this.labels.HubLabellimit1Placeholder,
      onchange: this.change,
    }

    const signage_Show_Bldg_NameFld = {
      name: "signage_Show_Bldg_Name",
      label: this.labels.HubLabelShowBuildingName,
      value: signage_Show_Bldg_Name,
      placeholder: this.labels.HubLabelBuildingNamePlaceholder,
      onchange: this.change,
    }

    const imageURIFld = {
      name: "imageURI",
      label: this.labels.HubLabelImageURI,
      value: imageURI,
      placeholder: this.labels.HubLabelImageURIPlaceholder,
      onchange: this.change,
    }

    const meta_Loc_X_mFld = {
      name: "meta_Loc_X_m",
      label: this.labels.HubLabelxLocation,
      value: meta_Loc_X_m,
      placeholder: this.labels.HubLabelxLocationPlaceholder,
      onchange: this.change,
    }

    const meta_Loc_Y_mFld = {
      name: "meta_Loc_Y_m",
      label: this.labels.HubLabelyLocation,
      value: meta_Loc_Y_m,
      placeholder: this.labels.HubLabelyLocationPlaceholder,
      onchange: this.change,
    }


    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.signage_Show_Bldg_Name,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.signage_Show_Bldg_Name,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.nodeId + "/" + this.state.filter + "/12") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              {this.state.activePage === 0 && (
                <div className="main-content">
                  {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                  {/* <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSignage}</Link></div> */}
                  <div className="breadcrumbs">
                    {this.state.id === 0 ?
                      <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSignage}</Link>
                      :
                      <div>
                        <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                        {this.state.showOrganisation && this.state.organisationState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                        {this.state.showRegion && this.state.regionState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                        {this.state.showBuilding && this.state.buildingState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                        {this.state.showFloor && this.state.floorState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                        {this.state.showZone && this.state.zoneState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                        {this.state.showRoom && this.state.roomState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                        {this.state.showDesk && this.state.deskState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                      </div>
                    }

                  </div>
                  <div className="page-title mb-20"><h1>{this.state.title}</h1></div>
                  <div className="height-control">
                    {this.state.loading || (
                      <div className="card card-scroller">
                        <div className="p-45">
                          <form>

                            <div className="row" >
                              <div className="col-6 pr-2"><Inputbox {...signage_IdFld} /> </div>
                            </div>

                            <div className="row" >
                              <div className="col-6 pr-2 div-flex">
                                <div className="col-6 pr-2 input-controls"><Inputbox {...signage_ModeFld} /> </div>
                                <div className="col-6 input-controls"><Inputbox {...signage_OrientationFld} /> </div>
                              </div>

                              <div className="col-6 div-flex">
                                <div className="col-6 pr-2 input-controls"><Inputbox {...signage_Show_Bldg_NameFld} /> </div>
                              </div>
                            </div>

                            <div className="row" >
                              <div className="col-12"><Inputbox {...imageURIFld} /> </div>
                            </div>

                            <div className="row" >
                              <div className="col-6 pr-2">
                                <Inputbox {...meta_Loc_X_mFld} />
                              </div>

                              <div className="col-6">
                                <Inputbox {...meta_Loc_Y_mFld} />
                              </div>
                            </div>

                          </form>
                        </div>
                      </div>)}
                  </div>
                </div>
              )}

              <div className="main-button-content">
                <div className="buttons-group">
                  <div className="col-8" style={{ display: 'flex' }}>
                    <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                    {(this.state.id !== 0 && this.state.isDeleteRight === false) && (
                      <button className="button-tertiary-disable mr-2" disabled={true} onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                    )}
                    {(this.state.id !== 0 && this.state.isDeleteRight === true) && (
                      <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                    )}
                  </div>
                  <div className=" space-between">
                    {this.state.isUpdateRight === false &&
                      <button className="button-primary-disable ml-2" disabled={true} onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                    }
                    {this.state.isUpdateRight === true &&
                      <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
   
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(EditSignage);