import { DateTime } from "luxon";
import { IGetManyByPeriodEndpoint, ISpaceUtilisationSummary as IGetManyByPeriodResponse } from "./GetManyByPeriodEndpoint";
import { IGetManyEndpoint, ISpaceUtilisationSummary as IGetManyResponse } from "./GetManyEndpoint";

export class SpaceUtilisationSummaryRepository implements ISpaceUtilisationSummaryRepository
{
    private getManyEndpoint: IGetManyEndpoint;
    private getManyByPeriodEndpoint: IGetManyByPeriodEndpoint;

    constructor(
        getManyEndpoint: IGetManyEndpoint,
        getManyByPeriodEndpoint: IGetManyByPeriodEndpoint,
    )
    {
        this.getManyEndpoint = getManyEndpoint;
        this.getManyByPeriodEndpoint = getManyByPeriodEndpoint;
    }

    public getMany(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<IGetManyResponse[]>
    {
        return this.getManyEndpoint.execute(nodeId, nodeLevelName, spaceClass, spaceTypeLabel, periodType, periodStart, periodEnd, floorNodeId, bookable, sortKey, sortAscending);
    }

    public getManyByPeriod(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<IGetManyByPeriodResponse[]>
    {
        return this.getManyByPeriodEndpoint.execute(nodeId, periodType, periodStart, periodEnd, floorNodeId);
    }
}

export interface ISpaceUtilisationSummaryRepository
{
    getMany(nodeId: number, nodeLevelName: string, spaceClass: string, spaceTypeLabel: string, periodType: number,
        periodStart: DateTime, periodEnd: DateTime, floorNodeId: string, bookable: number, sortKey: string, sortAscending: boolean): Promise<IGetManyResponse[]>;

    getManyByPeriod(nodeId: number, periodType: number, periodStart: DateTime, periodEnd: DateTime, floorNodeId: string): Promise<IGetManyByPeriodResponse[]>;
}
