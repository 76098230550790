import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

class IbssToolTip extends React.Component<IProps> {

    constructor(props: IProps) {
        super(props);
    };

    render(): React.ReactNode {

        return (
            <Tooltip {...this.props}>
                {this.props.children}
            </Tooltip>
        );
    };
};

export default IbssToolTip;

export interface IProps extends TooltipProps
{
}