import { Component } from 'react';

export default class Meetingroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 14C12.25 12.4812 13.4812 11.25 15 11.25H19C20.5188 11.25 21.75 12.4812 21.75 14V15C21.75 15.4142 21.4142 15.75 21 15.75C20.5858 15.75 20.25 15.4142 20.25 15V14C20.25 13.3096 19.6904 12.75 19 12.75H15C14.3096 12.75 13.75 13.3096 13.75 14V15C13.75 15.4142 13.4142 15.75 13 15.75C12.5858 15.75 12.25 15.4142 12.25 15V14Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.25 22C25.25 21.5858 25.5858 21.25 26 21.25H30.0133C31.9949 21.25 33.75 22.702 33.75 24.6667V26C33.75 26.4142 33.4142 26.75 33 26.75C32.5858 26.75 32.25 26.4142 32.25 26V24.6667C32.25 23.6858 31.3308 22.75 30.0133 22.75H26C25.5858 22.75 25.25 22.4142 25.25 22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 22C8.75 21.5858 8.41421 21.25 8 21.25H3.98667C2.00514 21.25 0.25 22.702 0.25 24.6667V26C0.25 26.4142 0.585786 26.75 1 26.75C1.41421 26.75 1.75 26.4142 1.75 26V24.6667C1.75 23.6858 2.66921 22.75 3.98667 22.75H8C8.41421 22.75 8.75 22.4142 8.75 22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 7C13.25 4.92893 14.9289 3.25 17 3.25C19.0711 3.25 20.75 4.92893 20.75 7C20.75 9.07107 19.0711 10.75 17 10.75C14.9289 10.75 13.25 9.07107 13.25 7ZM17 4.75C15.7574 4.75 14.75 5.75736 14.75 7C14.75 8.24264 15.7574 9.25 17 9.25C18.2426 9.25 19.25 8.24264 19.25 7C19.25 5.75736 18.2426 4.75 17 4.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.25 17C24.25 14.9289 25.9289 13.25 28 13.25C30.0711 13.25 31.75 14.9289 31.75 17C31.75 19.0711 30.0711 20.75 28 20.75C25.9289 20.75 24.25 19.0711 24.25 17ZM28 14.75C26.7574 14.75 25.75 15.7574 25.75 17C25.75 18.2426 26.7574 19.25 28 19.25C29.2426 19.25 30.25 18.2426 30.25 17C30.25 15.7574 29.2426 14.75 28 14.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 17C9.75 14.9289 8.07107 13.25 6 13.25C3.92893 13.25 2.25 14.9289 2.25 17C2.25 19.0711 3.92893 20.75 6 20.75C8.07107 20.75 9.75 19.0711 9.75 17ZM6 14.75C7.24264 14.75 8.25 15.7574 8.25 17C8.25 18.2426 7.24264 19.25 6 19.25C4.75736 19.25 3.75 18.2426 3.75 17C3.75 15.7574 4.75736 14.75 6 14.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6036 17.75C12.5549 17.75 11.6453 18.4744 11.4106 19.4965L8.73098 31.1678C8.63829 31.5715 8.23588 31.8237 7.83217 31.731C7.42846 31.6383 7.17633 31.2359 7.26902 30.8322L9.94866 19.1609C10.3398 17.4574 11.8558 16.25 13.6036 16.25H20.142C21.8511 16.25 23.344 17.4057 23.7723 19.0602L26.7261 30.4706C26.8299 30.8716 26.5889 31.2808 26.188 31.3846C25.787 31.4884 25.3777 31.2475 25.2739 30.8465L22.3202 19.4361C22.0632 18.4434 21.1675 17.75 20.142 17.75H13.6036Z" />
            </svg>
        )
    }
};