import { AxiosResponse } from "axios";
import { IRawPagedResponse, IRawPagedResponse3, PagedResponse } from "./Models";

export class ApiHelper
{
    public static toODataSelect<TResponse>(responseType: new () => TResponse): string
    {
        const responseInstance = new responseType();
        const oDataSelect = Object.entries(responseInstance as {}).map(i => i[0]).join(",");
        return oDataSelect;
    }

    public static toTypedResponse<TResponse>(response: any, mapResponse?: (response: any) => TResponse, mapItem?: (item: any) => any): TResponse
    {
        const isPaged = typeof(response) == 'object' && ('@odata.nextLink' in response);
        const mapItemOrDefault = (mapItem ? mapItem : (item: any) => item);
        let mappedResponse: TResponse;

        if (mapResponse)
        {
            mappedResponse = mapResponse(response);
        }
        else if (Array.isArray(response))
        {
            mappedResponse = response.map(item => mapItemOrDefault(item)) as TResponse;
        }
        else if (isPaged && response.Value)
        {
            const pagedResponse = PagedResponse.fromRawResponse(response as IRawPagedResponse<[]>);
            pagedResponse.value = pagedResponse.value.map(item => mapItemOrDefault(item)) as [];
            mappedResponse = pagedResponse as TResponse;
        }
        else if (isPaged && response.value)
        {
            const pagedResponse = PagedResponse.fromRawResponse3(response as IRawPagedResponse3<[]>);
            pagedResponse.value = pagedResponse.value.map(item => mapItemOrDefault(item)) as [];
            mappedResponse = pagedResponse as TResponse;
        }
        else
        {
            mappedResponse = mapItemOrDefault(response);
        }
        return mappedResponse;
    }
}
