import  { Component } from 'react';

export default class Changing extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6444 25.3396C10.8664 25.2201 11.1336 25.2201 11.3556 25.3396L20.8633 30.4592C22.464 31.3211 21.8517 33.75 20.0336 33.75H1.9664C0.148327 33.75 -0.464034 31.3211 1.13673 30.4592L10.6444 25.3396ZM11 26.8518L1.84788 31.7799C1.77389 31.8197 1.74513 31.8637 1.73093 31.8992C1.71394 31.9418 1.70837 31.9992 1.72399 32.0611C1.73961 32.1231 1.77173 32.171 1.80686 32.2004C1.83622 32.225 1.88237 32.25 1.9664 32.25H20.0336C20.1176 32.25 20.1638 32.225 20.1931 32.2004C20.2283 32.171 20.2604 32.1231 20.276 32.0611C20.2916 31.9992 20.2861 31.9418 20.2691 31.8992C20.2549 31.8637 20.2261 31.8197 20.1521 31.7799L11 26.8518Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 20.75C10.3096 20.75 9.75 21.3096 9.75 22H8.25C8.25 20.4812 9.48122 19.25 11 19.25C12.5188 19.25 13.75 20.4812 13.75 22C13.75 23.2588 12.9043 24.32 11.75 24.6465V26H10.25V24C10.25 23.5858 10.5858 23.25 11 23.25C11.6904 23.25 12.25 22.6904 12.25 22C12.25 21.3096 11.6904 20.75 11 20.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2563 1.93436C15.7208 0.469898 18.0951 0.469899 19.5596 1.93437L23.8022 6.17701C25.2667 7.64147 25.2667 10.0158 23.8023 11.4803L23.2201 12.0624C22.9272 12.3553 22.4524 12.3553 22.1595 12.0624L13.6742 3.57715C13.3813 3.28426 13.3813 2.80938 13.6742 2.51649L14.2563 1.93436ZM18.4989 2.99503C17.6203 2.11635 16.1956 2.11635 15.317 2.99502L15.2652 3.04682L22.6898 10.4714L22.7416 10.4196C23.6203 9.54097 23.6203 8.11635 22.7416 7.23767L18.4989 2.99503Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.543 0.900081C20.8359 0.607188 21.3107 0.607188 21.6036 0.900081L24.8365 4.13296C25.1294 4.42586 25.1294 4.90073 24.8365 5.19362L23.4223 6.60784L22.3616 5.54718L23.2455 4.66329L21.0733 2.49107L20.1894 3.37495L19.1288 2.31429L20.543 0.900081Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.9932 3.19266C28.2635 1.4629 25.459 1.4629 23.7292 3.19266C23.4363 3.48556 22.9614 3.48556 22.6685 3.19266C22.3756 2.89977 22.3756 2.4249 22.6685 2.132C24.9841 -0.183546 28.7383 -0.183546 31.0539 2.132C32.1964 3.27451 32.7754 4.76888 32.7902 6.26542L32.7903 6.27288L32.7903 6.27288L32.7903 32.8434C32.7903 33.2576 32.4545 33.5934 32.0403 33.5934C31.6261 33.5934 31.2903 33.2576 31.2903 32.8434V6.27676C31.2783 5.15813 30.8461 4.04555 29.9932 3.19266Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4545 17.8434C28.4545 17.4292 28.7903 17.0934 29.2045 17.0934L32.2045 17.0934L32.2045 18.5934L29.9545 18.5934L29.9545 21.0934L32.2045 21.0934L32.2045 22.5934L29.2045 22.5934C28.7903 22.5934 28.4545 22.2576 28.4545 21.8434L28.4545 17.8434Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7914 7.14099C19.0843 7.43388 19.0843 7.90875 18.7914 8.20165L16.6701 10.323C16.3772 10.6159 15.9024 10.6159 15.6095 10.323C15.3166 10.0301 15.3166 9.5552 15.6095 9.26231L17.7308 7.14099C18.0237 6.84809 18.4985 6.84809 18.7914 7.14099Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5488 11.3836C14.8417 11.6765 14.8417 12.1514 14.5488 12.4443L12.4275 14.5656C12.1346 14.8585 11.6597 14.8585 11.3668 14.5656C11.0739 14.2727 11.0739 13.7978 11.3668 13.5049L13.4881 11.3836C13.781 11.0907 14.2559 11.0907 14.5488 11.3836Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4438 5.04625C16.6509 5.40497 16.528 5.86366 16.1693 6.07077L13.5712 7.57077C13.2125 7.77787 12.7538 7.65497 12.5467 7.29625C12.3396 6.93753 12.4625 6.47884 12.8212 6.27173L15.4193 4.77173C15.778 4.56462 16.2367 4.68753 16.4438 5.04625Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2476 8.04625C11.4547 8.40497 11.3318 8.86366 10.9731 9.07077L8.37502 10.5708C8.0163 10.7779 7.55761 10.655 7.35051 10.2962C7.1434 9.93753 7.2663 9.47884 7.62502 9.27173L10.2231 7.77173C10.5818 7.56462 11.0405 7.68753 11.2476 8.04625Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0026 9.60504C21.3613 9.81215 21.4842 10.2708 21.2771 10.6296L19.7771 13.2276C19.57 13.5864 19.1113 13.7093 18.7526 13.5022C18.3938 13.295 18.2709 12.8364 18.478 12.4776L19.978 9.87956C20.1851 9.52084 20.6438 9.39793 21.0026 9.60504Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0026 14.8012C18.3613 15.0083 18.4842 15.467 18.2771 15.8257L16.7771 18.4238C16.57 18.7825 16.1113 18.9054 15.7526 18.6983C15.3938 18.4912 15.2709 18.0325 15.478 17.6738L16.978 15.0757C17.1851 14.717 17.6438 14.5941 18.0026 14.8012Z" />
            </svg>
        )
    }
};