import { IBookingParty } from "./GetBookingPartyEndPoint";
import {  IDeleteBookingPartyEndPoint } from "./DeleteBookingPartyEndPoint";
import {  IGetBookingPartyEndPoint } from "./GetBookingPartyEndPoint";
import { IGetManyEndpoint } from "./GetManyEndpoint";
import { DateTime } from "luxon";

export class BookingPartyRepository implements IBookingPartyRepository
{
    private deleteBookingPartyEndPoint: IDeleteBookingPartyEndPoint;
    private getBookingPartyEndPoint: IGetBookingPartyEndPoint;
    private getManyEndpoint: IGetManyEndpoint;

    constructor(
        deleteBookingPartyEndPoint: IDeleteBookingPartyEndPoint,
        getBookingPartyEndPoint: IGetBookingPartyEndPoint,
        getManyEndpoint: IGetManyEndpoint,
    )
    {
        this.deleteBookingPartyEndPoint = deleteBookingPartyEndPoint;
        this.getBookingPartyEndPoint = getBookingPartyEndPoint;
        this.getManyEndpoint = getManyEndpoint;
    }

    public delete(nodeId: number, recordId: string): Promise<void>
    {
        return this.deleteBookingPartyEndPoint.execute(nodeId, recordId);
    }

    public getByBookingId(nodeId: number, bookingId: string): Promise<IBookingParty[]>
    {
        return this.getBookingPartyEndPoint.execute(nodeId, bookingId);
    }

    public getMany(nodeId: number, bookingPartyFilter:BookingPartyFilter, recursive?: boolean): Promise<IBookingParties[]>
    {
        return this.getManyEndpoint.execute(nodeId, bookingPartyFilter, recursive);
    }

}

export interface IBookingPartyRepository
{
    delete(nodeId: number, recordId: string): Promise<void>;
    getByBookingId(nodeId: number, bookingId: string): Promise<IBookingParty[]>;
    getMany(nodeId: number, filter:BookingPartyFilter, recursive?:boolean): Promise<IBookingParties[]>;
}

export interface IBookingParties
{
    Space_Id: string,
    Visit_Id: string,
    Booking_Id: string,
    Booking_Start: DateTime,
    Booking_End: DateTime,
    Booking_Name: string,
    Booking_Status: string,
    Node_Id: number,
    Booking_Participant_Email: string,
    Booking_Participant_Name: string,
    Booking_Participant_Organisation: string,
    Record_Id: string,
    Booking_Resource_Id: string,
    Booking_Visitor: number,
    Booking_Participant_Type: number,
    Booking_Participant_CheckedIn: number,
    Booking_Participant_Status: string,
    Booking_Early_Checkin: string,
    Space_Name: string,
    Space_Name_Label: string,
    Node_Name: string,
}

export class BookingPartyFilter {
    public participantType: number | null = null;
    public bookingShare: number | null = null;
    public participantEmail: string | null = null;
    public startDateTime: string | null = null;
    public endDateTime: string | null = null;
    public bookingId: string | null = null;

    constructor(value: Partial<BookingPartyFilter>) {
        Object.assign(this, value);
    }

    public toODataString(): string {
        let filterParts = new Array<string | null>();
        filterParts.push(this.bookingId == null ? null : `Booking_Id eq '${this.bookingId}'` );
        filterParts.push(this.bookingShare == null ? null : `Booking_Share_Loc eq ${this.bookingShare}`);
        filterParts.push(this.participantType == null ? null : `Booking_Participant_Type eq ${this.participantType}`);
        filterParts.push(this.participantEmail == null ? null : `Booking_Participant_Email eq '${this.participantEmail.toLocaleLowerCase()}'`);
        filterParts.push(this.startDateTime == null ? null : `Booking_Start ge datetime'${this.startDateTime}'`);
        filterParts.push(this.endDateTime == null ? null : `Booking_Start lt datetime'${this.endDateTime}'`);
        return filterParts.filter(i => i != null).join(" and ");
    }
}
