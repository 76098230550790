import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { store } from "./app/store";
import { Provider } from 'react-redux'
import "./Common/LuxonExtensions";
import "./Common/ArrayExtensions";

const rootElement = document.getElementById("root")
ReactDOM.render(
    <React.StrictMode>
		<Provider store={store}>
        <App />
		</Provider>
    </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
