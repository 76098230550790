import React, { Component } from 'react';
import Badge, { BadgeOrigin } from '@mui/material/Badge';

type IbssBadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
  badgeContent?: React.ReactNode,
  anchorOrigin?: BadgeOrigin,
  components?: {
    Root?: React.ElementType<any> | undefined;
    Badge?: React.ElementType<any> | undefined;
  },
  invisible?: boolean,
  showZero?: boolean,
  variant?: "standard" | "dot",
  className?: string,
}


class IbssBadge extends Component<IbssBadgeProps> {
  render() {
    return (
      <Badge
        color={this.props.color}
        badgeContent={this.props.badgeContent}
        anchorOrigin={this.props.anchorOrigin}
        components={this.props.components}
        invisible={this.props.invisible}
        showZero={this.props.showZero}
        variant={this.props.variant}
        className={this.props.className}
      >
        {this.props.children}
      </Badge>
    )
  }
}

export default IbssBadge;
