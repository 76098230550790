import{ Component } from 'react';

export default class BuildingIcon extends Component {
    render() {
        return (
            <svg>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 23.1175C0.5 22.8252 0.737026 22.5881 1.02941 22.5881L23.6176 22.5881C23.91 22.5881 24.1471 22.8252 24.1471 23.1175C24.1471 23.4099 23.91 23.647 23.6176 23.647L1.02941 23.647C0.737026 23.647 0.5 23.4099 0.5 23.1175Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.6238 0.0934938C12.7672 0.192301 12.8528 0.355291 12.8528 0.529415V23.1177C12.8528 23.41 12.6158 23.6471 12.3234 23.6471H2.44103C2.14865 23.6471 1.91162 23.41 1.91162 23.1177V4.29412C1.91162 4.07445 2.04728 3.87759 2.25256 3.79939L12.1349 0.0346859C12.2976 -0.0273013 12.4804 -0.00531309 12.6238 0.0934938ZM2.97044 4.65897V22.5882H11.794V1.29762L2.97044 4.65897Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.0229 0.0934938C12.1663 -0.00531309 12.3491 -0.0273013 12.5118 0.0346859L22.3942 3.79939C22.5995 3.87759 22.7351 4.07445 22.7351 4.29412V23.1177C22.7351 23.41 22.4981 23.6471 22.2057 23.6471H12.3234C12.031 23.6471 11.7939 23.41 11.7939 23.1177V0.529415C11.7939 0.355291 11.8796 0.192301 12.0229 0.0934938ZM12.8528 1.29762V22.5882H21.6763V4.65897L12.8528 1.29762Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.342 4.61188C14.4264 4.33194 14.7217 4.17339 15.0017 4.25777L19.5154 5.6182C19.7954 5.70257 19.9539 5.99791 19.8695 6.27786C19.7852 6.55781 19.4898 6.71635 19.2099 6.63197L14.6961 5.27155C14.4162 5.18717 14.2576 4.89183 14.342 4.61188Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.3354 7.58344C14.4066 7.29986 14.6943 7.12771 14.9778 7.19893L19.4916 8.33262C19.7752 8.40385 19.9473 8.69147 19.8761 8.97505C19.8049 9.25863 19.5172 9.43078 19.2337 9.35955L14.7199 8.22586C14.4363 8.15464 14.2642 7.86701 14.3354 7.58344Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.3298 10.5556C14.3874 10.2689 14.6665 10.0832 14.9531 10.1408L19.4669 11.0478C19.7536 11.1054 19.9392 11.3845 19.8816 11.6711C19.8241 11.9578 19.545 12.1435 19.2583 12.0859L14.7446 11.1789C14.4579 11.1213 14.2722 10.8423 14.3298 10.5556Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.3253 13.5285C14.3689 13.2394 14.6386 13.0403 14.9277 13.0839L19.4415 13.7641C19.7306 13.8076 19.9297 14.0773 19.8861 14.3665C19.8425 14.6556 19.5728 14.8546 19.2837 14.8111L14.7699 14.1309C14.4808 14.0873 14.2817 13.8176 14.3253 13.5285Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.322 16.5022C14.3513 16.2112 14.6108 15.9991 14.9017 16.0283L19.4155 16.4818C19.7064 16.511 19.9185 16.7706 19.8893 17.0615C19.8601 17.3524 19.6005 17.5645 19.3096 17.5353L14.7959 17.0818C14.5049 17.0526 14.2928 16.7931 14.322 16.5022Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.32 19.476C14.3347 19.184 14.5833 18.9592 14.8753 18.9738L19.3891 19.2006C19.6811 19.2152 19.9059 19.4638 19.8913 19.7559C19.8766 20.0479 19.628 20.2727 19.336 20.2581L14.8222 20.0313C14.5302 20.0166 14.3053 19.768 14.32 19.476Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.93936 4.61188C10.0237 4.89183 9.86519 5.18717 9.58525 5.27155L5.07149 6.63197C4.79154 6.71635 4.4962 6.55781 4.41182 6.27786C4.32745 5.99791 4.48599 5.70257 4.76594 5.6182L9.2797 4.25777C9.55964 4.17339 9.85499 4.33194 9.93936 4.61188Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.94592 7.58344C10.0171 7.86701 9.845 8.15464 9.56142 8.22586L5.04766 9.35955C4.76408 9.43078 4.47646 9.25863 4.40523 8.97505C4.33401 8.69147 4.50616 8.40385 4.78973 8.33262L9.30349 7.19893C9.58707 7.12771 9.8747 7.29986 9.94592 7.58344Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 10.5556C10.0091 10.8423 9.82338 11.1213 9.53672 11.1789L5.02296 12.0859C4.73631 12.1435 4.45723 11.9578 4.39964 11.6711C4.34204 11.3845 4.52773 11.1054 4.81438 11.0478L9.32814 10.1408C9.6148 10.0832 9.89387 10.2689 9.95147 10.5556Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.95591 13.5285C9.99947 13.8176 9.80042 14.0873 9.51129 14.1309L4.99753 14.8111C4.70841 14.8546 4.43871 14.6556 4.39514 14.3665C4.35157 14.0773 4.55063 13.8076 4.83975 13.7641L9.35351 13.0839C9.64264 13.0403 9.91234 13.2394 9.95591 13.5285Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.95913 16.5022C9.98836 16.7931 9.77621 17.0526 9.48529 17.0818L4.97153 17.5353C4.68061 17.5645 4.42108 17.3524 4.39185 17.0615C4.36262 16.7706 4.57477 16.511 4.86569 16.4818L9.37945 16.0283C9.67037 15.9991 9.9299 16.2112 9.95913 16.5022Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.96109 19.476C9.97576 19.768 9.75092 20.0166 9.4589 20.0313L4.94514 20.2581C4.65313 20.2727 4.40451 20.0479 4.38984 19.7559C4.37517 19.4638 4.60001 19.2152 4.89202 19.2006L9.40578 18.9738C9.6978 18.9592 9.94642 19.184 9.96109 19.476Z" />
            </svg>
        )
    }
};
