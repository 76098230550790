import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IEditVisitResponse, IVisit } from "./VisitsRepository";

export class EditVisitEndpoint implements IEditVisitEndpoint
{
    public async execute(nodeId: number, visitId: string, visit: IVisit): Promise<IEditVisitResponse>
    {
        try
        {
            const response: AxiosResponse<IEditVisitResponse> = await axios.put(`${apis.allApi}${nodeId}/Visits/${visitId}`, visit);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEditVisitEndpoint
{
    execute(nodeId: number, visitId: string, visit: IVisit): Promise<IEditVisitResponse>;
}
