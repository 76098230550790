import React from "react";
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import { Descendant,createEditor } from "slate";
import './Styles.css';
import { Button, Toolbar } from "@mui/material";
import UnorderedListButton from "./Buttons/UnorderedListButton";
import OrderedListButton from "./Buttons/OrderedListButton";
import BoldButton from "./Buttons/BoldButton";
import ClearFormattingButton from "./Buttons/ClearFormattingButton";
import CodeBlockButton from "./Buttons/CodeBlockButton";
import HeadingButton from "./Buttons/HeadingButton";
import HorizontalRuleButton from "./Buttons/HorizontalRuleButton";
import ItalicButton from "./Buttons/ItalicButton";
import LinkButton from "./Buttons/LinkButton";
import { Helper } from "./Helper";
import DevButton from "./Buttons/DevButton";

export default class MarkdownEditor extends IbssComponent<IProps, IState>
{
    public static emptySlate = [{ type: 'paragraph', children: [{ text: '' }] }];

    private editor = withReact(createEditor());
    private helper = new Helper(this.editor);
    private debounceTimeInMs = 300;
    private debounceTimeout: NodeJS.Timeout | null = null;
    private currentValue: Descendant[] = [];
    private linkButtonRef = React.createRef<LinkButton>();

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            ready: false,
            initialValue: [],
        };        
    }

    public async componentDidMount(): Promise<void>
    {
        const initialValueAsSlate = await this.helper.fromMarkdown(this.props.initialValue);
        this.setState({
            ready: true,
            initialValue: initialValueAsSlate,
        });
    }

    private handleChange(newValue: Descendant[]): void
    {
        this.currentValue = newValue;
        clearTimeout(this.debounceTimeout ?? undefined);
        this.debounceTimeout = setTimeout(() => this.debounceChange(), this.debounceTimeInMs);
    }

    private handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void 
    {
        const { helper } = this;
        const isText = event.key.length == 1;
        const isEnter = event.key == 'Enter';

        if ((isText || isEnter) && helper.isHorizontalRule() && helper.isSelectionZeroLength()) 
        {
            helper.removeHorizontalRule();
        }

        if (event.ctrlKey) 
        {
            switch (event.key) {
                case 'b': 
                    event.preventDefault();
                    helper.toggleBold();
                    break;
                case 'i': 
                    event.preventDefault();
                    helper.toggleItalic();
                    break;
                case '\\':
                    event.preventDefault();
                    helper.removeMarks();
                    break;
                case ' ': 
                    event.preventDefault();
                    helper.removeMarks();
                    break;
                case 'k': 
                    event.preventDefault();
                    this.linkButtonRef.current?.showLinkDialog();            
                    break;
            }

            if (event.altKey) 
            {
                switch (event.key) {
                    case '0': 
                        event.preventDefault();
                        helper.setHeading(0);
                        break;
                    case '1': 
                        event.preventDefault();
                        helper.setHeading(1);
                        break;
                    case '2':
                        event.preventDefault();
                        helper.setHeading(2);
                        break;
                    case '3': 
                        event.preventDefault();
                        helper.setHeading(3);
                        break;
                }
            }

            if (event.shiftKey) 
            {
                switch (event.code) {
                    case 'Digit7':
                        event.preventDefault();
                        helper.toggleList(true);
                        break;
                    case 'Digit8':
                        event.preventDefault();
                        helper.toggleList(false);
                        break;
                    case 'Backslash': 
                        event.preventDefault();
                        helper.toggleCodeBlock();
                        break;
                    case 'IntlBackslash': 
                        event.preventDefault();
                        helper.toggleCodeBlock();
                        break;
                    case 'Minus': 
                        event.preventDefault();
                        helper.addHorizontalRule();
                        break;
                }
            }
        }
    }

    public async flush(): Promise<void>
    {
        if (!this.debounceTimeout)
        {
            return;
        }
        await this.debounceChange();
    }

    private async debounceChange(): Promise<void>
    {
        this.debounceTimeout = null;
        const markdown = await this.helper.toMarkdown(this.currentValue);
        this.props.onChange?.(markdown);
    }

    private renderLeaf(props: RenderLeafProps): JSX.Element
    {
        return this.helper.renderLeaf(props, false);
    }

    private renderElement(props: RenderElementProps): JSX.Element
    {
        return this.helper.renderElement(props, false);
    }

    public render(): JSX.Element
    {
        const { style } = this.props;
        const { ready, initialValue } = this.state;

        return (
            <div style={style} className="markdown-editor">
                {ready &&
                    <Slate
                        editor={this.editor}
                        initialValue={initialValue}
                        onChange={e => this.handleChange(e)}
                    >
                        <Toolbar className="markdown-editor__toolbar">
                            <BoldButton editor={this.editor} />
                            <ItalicButton editor={this.editor} />
                            <HeadingButton editor={this.editor} />
                            <OrderedListButton editor={this.editor} />
                            <UnorderedListButton editor={this.editor} />
                            {/*<BlockQuoteButton editor={this.editor} /> cannot do block-quotes because API doesn't accept '>' */}
                            <CodeBlockButton editor={this.editor} />
                            <LinkButton editor={this.editor} ref={this.linkButtonRef} />
                            <HorizontalRuleButton editor={this.editor} />
                            <ClearFormattingButton editor={this.editor} />
                            <DevButton editor={this.editor} hide={true} />
                        </Toolbar>
                        <Editable
                            renderLeaf={props => this.renderLeaf(props)}
                            renderElement={props => this.renderElement(props)}
                            className="markdown-editor__editor"
                            spellCheck={false}
                            onKeyDown={e => this.handleKeyDown(e)}
                        />
                    </Slate>
                }
            </div>
        );
    }
}

export interface IProps
{
    style?: React.CSSProperties;
    initialValue: string;
    onChange?: (value: string) => void;
}

export interface IState
{
    ready: boolean;
    initialValue: Descendant[];
}
