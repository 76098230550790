import { Component } from 'react';

export default class Temperature extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 13C8.25 12.5858 8.58579 12.25 9 12.25H12C12.4142 12.25 12.75 12.5858 12.75 13C12.75 13.4142 12.4142 13.75 12 13.75H9C8.58579 13.75 8.25 13.4142 8.25 13Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H12C12.4142 8.25 12.75 8.58579 12.75 9C12.75 9.41421 12.4142 9.75 12 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 5C8.25 4.58579 8.58579 4.25 9 4.25H12C12.4142 4.25 12.75 4.58579 12.75 5C12.75 5.41421 12.4142 5.75 12 5.75H9C8.58579 5.75 8.25 5.41421 8.25 5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 7.25C17.4142 7.25 17.75 7.58579 17.75 8L17.75 20C17.75 20.4142 17.4142 20.75 17 20.75C16.5858 20.75 16.25 20.4142 16.25 20L16.25 8C16.25 7.58579 16.5858 7.25 17 7.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 26.5C18.3807 26.5 19.5 25.3807 19.5 24C19.5 22.6193 18.3807 21.5 17 21.5C15.6193 21.5 14.5 22.6193 14.5 24C14.5 25.3807 15.6193 26.5 17 26.5ZM21 24C21 26.2091 19.2091 28 17 28C14.7909 28 13 26.2091 13 24C13 21.7909 14.7909 20 17 20C19.2091 20 21 21.7909 21 24Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 15V16.902L12.4153 17.1245C10.1938 18.6016 8.75 21.1272 8.75 24C8.75 28.5563 12.4437 32.25 17 32.25C21.5563 32.25 25.25 28.5563 25.25 24C25.25 21.1272 23.8062 18.6016 21.5847 17.1245L21.25 16.902V15H22.75V16.1083C25.1878 17.8816 26.75 20.7579 26.75 24C26.75 29.3848 22.3848 33.75 17 33.75C11.6152 33.75 7.25 29.3848 7.25 24C7.25 20.7579 8.81222 17.8816 11.25 16.1083V15H12.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 6C11.25 2.82436 13.8244 0.25 17 0.25C20.1756 0.25 22.75 2.82436 22.75 6V16.5H21.25V6C21.25 3.65279 19.3472 1.75 17 1.75C14.6528 1.75 12.75 3.65279 12.75 6V16.5H11.25V6Z" />
            </svg>
        )
    }
};