import * as React from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';

export default class IbssAutocomplete extends React.Component<AutocompleteProps<any, boolean, boolean, boolean, React.ElementType<any>>>
{
    public render(): JSX.Element
    {
        return (<Autocomplete {...this.props} />);
    };
};
