import { AccumulationChartComponent, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, BarSeries, Category, ChartComponent, DataLabel, Export, IAxisLabelRenderEventArgs, ITextRenderEventArgs, Inject, Legend, PieSeries, SeriesCollectionDirective, SeriesDirective } from "@syncfusion/ej2-react-charts";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { VisitsDailySummary } from "./DataModels";
import { Grid } from "@mui/material";
import { DateTime, WeekdayNumbers } from "luxon";
import { appContext } from "../../../../AppContext";
import { Helper } from "./Helper";
import React from "react";
import { StyleHelper } from "../../../../Common/StyleHelper";
import { ChartDownloadButton } from "../../../../Components/Buttons/ChartDownloadButton/ChartDownloadButton";

export class VisitAnalyiticsForPeriod extends IbssComponent<IProps, IState>
{
    private pieChartRef = React.createRef<AccumulationChartComponent>();
    private barChartRef = React.createRef<ChartComponent>();
    private styleHelper = new StyleHelper();

    public componentDidMount(): void
    {
        setTimeout(() => this.resizePieChart(), 1000); // todo: not ideal but couldn't work out how else to vertically centre pie-chart
    }

    private resizePieChart()
    {
        if (!this.pieChartRef.current || !this.barChartRef.current)
        {
            return;
        }

        const width = this.pieChartRef.current.availableSize.width;
        const maxHeight = this.barChartRef.current.availableSize.height;
        this.pieChartRef.current.height = `${Math.min(width, maxHeight)}px`;
        setTimeout(() => this.resizePieChart(), 1000);
    }

    private renderAxisLabel(args: IAxisLabelRenderEventArgs): void
    {
        if (args.axis.orientation == 'Vertical')
        {
            return;
        }
        args.text = this.getLabel(args.value);
    }

    private renderDataLabel(args: ITextRenderEventArgs): void
    {
        const { labels } = appContext();
        args.text = this.getLabel(args.point.y as number) + ' ' + labels.funcHours_S;
    }

    private getLabel(value: number): string
    {
        const hours = Math.floor(value);
        const minutes = Math.round((value - hours) * 60);
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    }

    public render(): JSX.Element
    {
        const props = this.props;
        const labels = appContext().labels;
        const fileNameDates = `${props.from.toISODate()} ${labels.HubLabelTo.toLocaleLowerCase()} ${props.to.toISODate()}`;
        const style = this.styleHelper;

        const numOfMeetingInvites = props.summaries.sum(i => i.Visits_Bookings_Count);
        const numOfAdHocVisitors = props.summaries.sum(i => i.Visits_No_Bookings_Count);

        const pieChartData = [
            ...(numOfMeetingInvites > 0 ? [{ name: labels.funcMeetingInvite_S, value: props.summaries.sum(i => i.Visits_Bookings_Count), color: style.getColour('--ui-graph3') }] : []),
            ...(numOfAdHocVisitors > 0 ? [{ name: labels.funcAdHocVisitor_S, value: props.summaries.sum(i => i.Visits_No_Bookings_Count), color: style.getColour('--ui-graph2') }] : []),
        ];

        const pieChartFilename = `${labels.funcVisitAnalyticsForPeriod_S} (${labels.funcPieChart_S}) (${fileNameDates})`;
        const pieChartAsCsv = `,${labels.funcNumberOfVisits_S}\n` + pieChartData.map(i => `${i.name},${i.value}`).join('\n');
        const summariesGroupedByDayOfWeek = new Map(props.summaries.groupBy(i => i.Summary_Date.weekday).map(i => [i.key, i.value]));

        const daysOfWeek: { name: string, value: WeekdayNumbers }[] = [
            { name: labels.HubLabelMonday, value: 1 },
            { name: labels.HubLabelTuesday, value: 2 },
            { name: labels.HubLabelWednesday, value: 3 },
            { name: labels.HubLabelThursday, value: 4 },
            { name: labels.HubLabelFriday, value: 5 },
            { name: labels.HubLabelSaturday, value: 6 },
            { name: labels.HubLabelSunday, value: 7 },
        ];

        const barChartData = daysOfWeek.map(i =>
        {
            const summaries = summariesGroupedByDayOfWeek.get(i.value) ?? null;
            return {
                dayOfWeek: i.name,
                meetingDuration: summaries?.average(j => j.Visits_Avg_Actual_Duration) ?? 0,
            };
        });

        const barChartFilename = `${labels.funcVisitAnalyticsForPeriod_S} (${labels.funcBarChart_S}) (${fileNameDates})`;
        
        const barChartAsCsv = `${labels.HublabelDay},${labels.funcMeetingDuration_S} (${labels.funcHours_S})\n` +
            barChartData.map(i => `${i.dayOfWeek},${i.meetingDuration}`).join('\n');

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ position: 'relative' }} alignContent='center'>
                        {pieChartData.length == 0 &&
                            <div style={{ textAlign: 'center' }}>{labels.HublabelErrorEmptyData}</div>
                        }
                        {pieChartData.length > 0 &&
                            <Grid container flexDirection='column'>
                                <Grid item alignSelf='flex-end'>
                                    <ChartDownloadButton
                                        chartRef={this.pieChartRef}
                                        filename={pieChartFilename}
                                        exportTypes={Helper.exportTypes}
                                        csv={pieChartAsCsv}
                                    />
                                </Grid>
                                <Grid item>
                                    <AccumulationChartComponent
                                        ref={this.pieChartRef}
                                        className='visit_analytics__chart'
                                        background={style.getColour('--ui-background-alternate')}
                                    >
                                        <Inject services={[PieSeries, Legend, AccumulationDataLabel, Export]} />
                                        <AccumulationSeriesCollectionDirective>
                                            <AccumulationSeriesDirective
                                                dataSource={pieChartData}
                                                xName="name"
                                                yName="value"
                                                type="Pie"
                                                pointColorMapping="color"
                                                dataLabel={{
                                                    visible: true,
                                                    name: 'name',
                                                }}
                                            />
                                        </AccumulationSeriesCollectionDirective>
                                    </AccumulationChartComponent>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={8} sx={{ position: 'relative' }}>
                        <ChartDownloadButton
                            chartRef={this.barChartRef}
                            filename={barChartFilename}
                            exportTypes={Helper.exportTypes}
                            style={{ position: 'absolute', top: '-31px', right: '-6px' }}
                            csv={barChartAsCsv}
                        />
                        <ChartComponent
                            ref={this.barChartRef}
                            className='visit_analytics__chart'
                            background={style.getColour('--ui-background-alternate')}
                            primaryXAxis={{
                                valueType: 'Category',
                                labelStyle: { color: style.getColour('--ui-text') },
                            }}
                            primaryYAxis={{
                                labelStyle: { color: style.getColour('--ui-text') },
                            }}
                            axisLabelRender={i => this.renderAxisLabel(i)}
                            textRender={i => this.renderDataLabel(i)}
                        >
                            <Inject services={[BarSeries, Category, DataLabel, Export]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective
                                    dataSource={barChartData}
                                    xName='dayOfWeek'
                                    yName='meetingDuration'
                                    type='Bar'
                                    marker={{
                                        dataLabel: {
                                            visible: true,
                                            font: { color: style.getColour('--ui-text') },
                                        },
                                    }}
                                    fill={style.getColour('--ui-graph2')}
                                />
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </Grid>
                </Grid >
            </>
        );
    }
}

export interface IProps
{
    from: DateTime;
    to: DateTime;
    summaries: VisitsDailySummary[];
}

export interface IState
{
}
