import { Component } from 'react';
export default class AddIcon extends Component {
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75Z" fill="inherit" />
            </svg>
        )
    }
};
