import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICostAllocation } from "./TaskRepository";


export class GetByIdEndpoint implements IGetByIdEndpoint
{
    public async execute(nodeId: number, taskId: string): Promise<ITask>
    {
        try
        {
            const response = await axios.get<ITask>(`${apis.allApi}${nodeId}/Tasks/${taskId }`);

            return response.data;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByIdEndpoint
{
    execute(nodeId: number, taskId: string): Promise<ITask>;
}

export interface ITask
{
    Task_Priority: number;
    Task_Category_Name: string;
    Task_Type_Name: string;
    Task_Description: string;
    Task_CreatedBy: string;
    Node_Id: number;
    Task_Owner: string;
    Space_Id: string;
    Task_Status: string;
    Task_Type_Id: number;
    Booking_Id: string;
}
