import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetUserApiVersion implements IGetUserApiVersion
{
    public async execute(): Promise<IVersion>
    {
        try
        {
            const response = await axios.get<IVersion>(`${apis.userApiBaseUrl}v1/Service/version`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetUserApiVersion
{
    execute(): Promise<IVersion>;
}

export interface IVersion
{
    version: string;
}
