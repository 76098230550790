import { Grid } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { AxesDirective, AxisDirective, ChartComponent, ColumnSeries, DataLabel, DateTimeCategory, Export, Inject, Legend, SeriesCollectionDirective, SeriesDirective, SplineSeries } from "@syncfusion/ej2-react-charts";
import { VisitsDailySummary } from "./DataModels";
import { appContext } from "../../../../AppContext";
import { DateTime, WeekdayNumbers } from "luxon";
import { Helper } from "./Helper";
import React from "react";
import { StyleHelper } from "../../../../Common/StyleHelper";
import './Styles.scss'
import { ChartDownloadButton } from "../../../../Components/Buttons/ChartDownloadButton/ChartDownloadButton";

export class VisitorBreakdownByDay extends IbssComponent<IProps, IState>
{
    private chartRef = React.createRef<ChartComponent>();
    private styleHelper = new StyleHelper();

    public render(): JSX.Element
    {
        const props = this.props;
        const { labels } = appContext();
        const duration = props.to.diff(props.from, 'days');
        const dates = Array.from({ length: duration.days + 1 }, (_, i) => props.from.plus({ days: i }));
        const indexedSummaries = new Map(props.summaries.groupBy(i => i.Summary_Date.toISODate()).map(i => [i.key, i.value]));
        const downloadFileName = `${labels.funcVisitorBreakdownByDay_S} (${props.from.toISODate()} ${labels.HubLabelTo.toLocaleLowerCase()} ${props.to.toISODate()})`;
        const style = this.styleHelper;

        const data = dates.map((date, index) =>
        {
            const summariesByDate = indexedSummaries.get(date.toISODate()) ?? [];
            return {
                date: date,
                plannedVisits: summariesByDate.sum(j => j.Visits_Created_Count),
                approvedVisits: summariesByDate.sum(j => j.Visits_Approved_Count),
                cancelledVisits: summariesByDate.sum(j => j.Visits_Cancelled_Count),
                attendedVisits: summariesByDate.sum(j => j.Visits_Completed_Count),
            }
        });

        const csv = `${labels.HubLabelDate},${labels.funcPlannedVisits_S},${labels.funcApprovedVisits_S},${labels.funcCancelledVisits_S},${labels.funcAttended_S}\n` +
            data.map(i => `${i.date.toLocaleDateString()},${i.plannedVisits},${i.approvedVisits},${i.cancelledVisits},${i.attendedVisits}`).join('\n');

        return (
            <>
                <ChartDownloadButton
                    chartRef={this.chartRef}
                    filename={downloadFileName}
                    exportTypes={Helper.exportTypes}
                    style={{ position: 'absolute', top: '22px', right: '10px' }}
                    csv={csv}
                />
                <ChartComponent
                    ref={this.chartRef}
                    className='visit_analytics__chart'
                    background={style.getColour('--ui-background-alternate')}
                    primaryXAxis={{
                        valueType: 'DateTimeCategory',
                        labelFormat: 'd',
                        intervalType: 'Days',
                        labelStyle: { color: style.getColour('--ui-text') },
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        labelStyle: { color: style.getColour('--ui-text') },
                    }}
                    legendSettings={{
                        textStyle: { color: style.getColour('--ui-text') },
                    }}
                >
                    <Inject services={[ColumnSeries, SplineSeries, DateTimeCategory, Legend, DataLabel, Export]} />
                    <AxesDirective>
                        <AxisDirective
                            name="secondaryXAxis"
                            valueType="DateTimeCategory"
                            labelFormat="MMMM"
                            majorGridLines={{ width: 3 }}
                            intervalType="Months"
                            lineStyle={{ width: 0 }}
                            majorTickLines={{ width: 0 }}
                            labelPadding={0}

                        />
                    </AxesDirective>
                    <SeriesCollectionDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName="date"
                            yName="plannedVisits"
                            type="Column"
                            name={labels.funcPlannedVisits_S}
                            columnSpacing={0.5}
                            fill={style.getColour('--ui-graph3')}
                        />
                        <SeriesDirective
                            dataSource={data}
                            xName="date"
                            yName="approvedVisits"
                            type="Column"
                            name={labels.funcApprovedVisits_S}
                            columnSpacing={0.5}
                            fill={style.getColour('--ui-graph2')}
                        />
                        <SeriesDirective
                            dataSource={data}
                            xName="date"
                            yName="cancelledVisits"
                            type="Column"
                            name={labels.funcCancelledVisits_S}
                            columnSpacing={0.5}
                            fill={style.getColour('--ui-graph5')}
                        />
                        <SeriesDirective
                            dataSource={data}
                            xName="date"
                            yName="attendedVisits"
                            type="Spline"
                            name={labels.funcAttended_S}
                            width={3}
                            xAxisName='secondaryXAxis'
                            fill={style.getColour('--ui-graph6')}
                        />
                    </SeriesCollectionDirective>
                </ChartComponent>
            </>
        );
    }
}

export interface IProps
{
    from: DateTime;
    to: DateTime;
    summaries: VisitsDailySummary[];
}

export interface IState
{
}
