import { Component } from 'react';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';

class BookingStatusChip extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    }

    private getBookingBackgroundColor(bookingStatus: string): string
    {
        switch (bookingStatus)
        {
            case 'New':
                return 'white';
            case 'Amended':
                return 'white';
            case 'In Progress':
                return 'var(--ui-success-pastel)'; // pastel-green
            case 'Early Check In':
                return 'var(--scroll-track)'; // light grey mid-tone
            case 'Late Checkin':
                return 'var(--ui-warn-pastel)'; // pastel-yellow
            case 'No Show':
                return 'var(--ui-error-pastel)';  // pastel-pink
            case 'Completed':
                return 'white';
            case 'Facility Management':
                return 'var(--ui-light-pastel-blue)'; // pastel-blue
            default:
                return 'white';
        }
    }

    public render(): JSX.Element
    {
        return (
            <IbssChip label={`${this.props.bookingStatus}`} sx={{border: '1px var(--scroll-track) solid',backgroundColor: this.getBookingBackgroundColor(this.props.bookingStatus)}}/>
        )
    }
}

export default BookingStatusChip;

export interface IProps
{
    bookingStatus: string;
}

export interface IState
{
}