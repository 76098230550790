import { IGetPrivacyPolicyEndpoint } from "./GetPrivacyPolicyEndpoint";

export class PrivancyPolicyRepository implements IPrivancyPolicyRepository
{
    private getPrivacyPolicyEndpoint: IGetPrivacyPolicyEndpoint;
    
    constructor(
        getPrivacyPolicyEndpoint: IGetPrivacyPolicyEndpoint
        )
    {
        this.getPrivacyPolicyEndpoint = getPrivacyPolicyEndpoint;
    }

    public getPrivacyPolicy(): Promise<string>
    {
        return this.getPrivacyPolicyEndpoint.execute();
    }
}

export interface IPrivancyPolicyRepository
{
    getPrivacyPolicy(): Promise<string>;
}