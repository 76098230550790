import{ Component } from 'react';

export default class BuildingIcon extends Component {
    render() {
        return (
            <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52 26C52 39.7892 40.6013 51 26.5 51C12.3987 51 1 39.7892 1 26C1 12.2108 12.3987 1 26.5 1C40.6013 1 52 12.2108 52 26Z" stroke="#00C853" stroke-width="2"/>
<path d="M15.1428 27.8571L21.7678 34.3571L37.8571 18.5714" stroke="#00C853" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        )
    }
};
