import React, { Component } from "react";

export default class SelectTextBox extends Component {
  state = {
    value: "",
    error: "Provide Value",
    displayOption: false,
    selectedValue: "...",
  };

  handleSelectValue = (option: any) => {
    const { onchange }: any = this.props;
    if (option.target.value !== "...") {
      this.setState({
        selectedValue: option.target.label,
        value: option.target.value
      });
      onchange(option.target.value);
    }
  };


  render() {
    const { label, value, mandatory, options, disabled, labelStyleClass }: any = this.props;
    return (
      <div className="form-input-box">
        <label className="form-input-box-label">{label}</label>
        <span className="form-input-box-required-asterisk">
          {mandatory === true && <>*</>
          }
        </span>
        <div className="form-input-select-box">
          <select className="custom-form-control" disabled={disabled} onChange={this.handleSelectValue} value={value}>
            {/* <option>...</option> */}
            {options.map((option: any) => (
              <option className="custom-form-control" value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export class InputOption
{
    label = "";
    value = "";

    constructor(label: string, value: string)
    {
        this.label = label;
        this.value = value;
    }
}
