import { Component } from 'react';
import { Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type IbssLinkProps = LinkProps & {
    to: string
};

class IbssLink extends Component<IbssLinkProps> {
  render() {
    return (
      <div>
        <Link component={RouterLink} {...this.props} />
      </div>
    );
  }
};

export default IbssLink;
