import axios, { AxiosError, AxiosRequestConfig } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";
import { IDelegate } from "./GetManyByDelegatorEndpoint";
import { IRawPagedResponse3, PagedResponse } from "../Models";

export class GetPrimaryDelegatorsEndpoint implements IPrimariesDelegatorEndpoint
{
    public async execute(): Promise<PagedResponse<IDelegate[]>>
    {
        try
        {
            const response = await axios.get<IRawPagedResponse3<IDelegate[]>>(`${apis.userApiBaseUrl}api/user/v1/Delegates/my-primaries`);
            
            const pagedResponse = PagedResponse.fromRawResponse3(response.data);
            return pagedResponse;
        }
        catch (error)
        {
            throw ApiError.fromAxiosError(error as AxiosError);
        }
    }
}

export interface IPrimariesDelegatorEndpoint
{
    execute(): Promise<PagedResponse<IDelegate[]>>;
}
