import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import "./NewVisitorWidget.scss"
import CreateVisitorDialog from '../../../../Components/Dialogs/CreateVisitorDialog/CreateVisitorDialog';
import "./NewVisitorWidget.scss"

class NewVisitorWidget extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            showModal: false,
        };
    }

    public async widgetClicked():Promise<void>
    {
        this.setState({
            showModal: true,
        });
    }

    private async closeClicked(): Promise<void> 
    {
        await this.props.fetchVisits();
        this.setState({
            showModal: false,
        });
    }

    public render(): JSX.Element
    {

        return (
            <>
                 <CreateVisitorDialog
                    showCreateVisitorDialog={this.state.showModal}
                    closeClicked={() => this.closeClicked()}
                 />
                <div className="visitors-card">
                    <div className='widget-hover-overlay height-100' onClick={() => this.widgetClicked()} >
                            <Card className='bg-transparent height-100'>
                                <CardContent className='height-70'>
                                    <Box>
                                        <Typography variant="h5" className='visitors-title-white mb-3' gutterBottom>{this.labels.HubLabelAddNewVisitor}</Typography>
                                        <Typography variant="h5" className='visitors-title-white' gutterBottom>{this.labels.HubLabelAddAdhocVisitorText}</Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box className="visitors-card-footer">
                                        <IbssButton variant="outlined" className='button-xl-white'>
                                           {this.labels.HubLabelAddVisitor}
                                        </IbssButton>
                                    </Box>
                                </CardActions>
                            </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default NewVisitorWidget;

export interface IProps
{
    fetchVisits: Function;
}

export interface IState
{
    showModal: boolean;
}
