import { appContext } from "../../AppContext";
import { CateringMenuItem, CateringMenuItemClassification, Filter } from "../../Providers.Api/CateringMenuItems/CateringMenuItemRepository";

export class SetSelected implements ISetSelected
{
    private get apiClient() { return appContext().apiClient; }

    public async execute(buildingId: number, cateringMenuId: string, classification: CateringMenuItemClassification, selectedIds: string[]): Promise<void>
    {
        const currentlySelectedItems = await this.apiClient.cateringMenuItems.getManyByMenuId(buildingId, cateringMenuId, CateringMenuItem, new Filter({ classification: classification }));
        const currentlySelectedIds = currentlySelectedItems.value.map(i => i.MenuItem_Id);
        const itemIdsToCreate = selectedIds.filter(i => !currentlySelectedIds.contains(i));
        const itemIdsToDelete = currentlySelectedIds.filter(i => !selectedIds.contains(i));

        if (itemIdsToCreate.length == 0 && itemIdsToDelete.length == 0)
        {
            return;
        }
        await this.apiClient.cateringMenuItems.createDelete(buildingId, cateringMenuId, itemIdsToCreate, itemIdsToDelete);
    }
}

export interface ISetSelected
{
    execute(buildingId: number, cateringMenuId: string, classification: CateringMenuItemClassification, selectedIds: string[]): Promise<void>;
}
