import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class SystemFilter extends ODataSelect
{
    public Name = '';
    public Filter_Id? = '';

    public init(response: any, nodeId: number): SystemFilter
    {
        super.init(response, nodeId);
        return this;
    }
}
