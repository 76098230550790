import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DateTime } from "luxon";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(buildingId: number, startDate: DateTime,endDate: DateTime): Promise<IBookingSummaryData[]>
    {
        try
        {
            const response = await axios.get<IBookingSummaryData[]>(`${apis.allApi}${buildingId}/BookingsDailySummary/${startDate.toUTC().toISODate()}/${endDate.toUTC().toISODate()}/BookingsSummaryData?isUtc=true`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(buildingId: number, startDate: DateTime,endDate: DateTime): Promise<IBookingSummaryData[]>;
}


export interface IBookingSummaryData
{
    Space_Id: string;
    NodeId: number;
    NodeLevelName: string;
    Date: string;
    Summary_Id: string;
    Bookings_Created_Count: number;
    Bookings_Cancelled_Count: number;
    Bookings_Auto_Cancelled_Count: number;
    Bookings_Completed_Count: number;
    Bookings_Early_Checkin_Count: number;
    Bookings_Late_Checkin_Count: number;
    Bookings_Avg_Early_Checkin_Duration: number;
    Bookings_Avg_Late_Checkin_Duration: number;
    Bookings_Avg_Actual_Duration: number;
    Bookings_Avg_Scheduled_Duration: number;
    Bookings_Avg_LeadTime_Duration: number;
    Bookings_No_Show_Count: number;
}
