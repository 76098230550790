import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ApiHelper } from "../ApiHelper";

export class GetByIdEndpoint implements IGetByIdEndpoint
{
    public async execute<TVisit>(type: new() => TVisit, nodeId: number, visitId: string): Promise<TVisit>
    {
        try
        {
            const query = `$select=${ApiHelper.toODataSelect(type)}`;
            const response = await axios.get<TVisit>(`${apis.allApi}/${nodeId}/Visits/${visitId}?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetByIdEndpoint
{
    execute<TVisit>(type: new() => TVisit, nodeId: number, visitId: string): Promise<TVisit>;
}
