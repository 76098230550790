import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IGetCateringRestrictions } from "../CateringRestrictions/CateringRestrictionsRepository";


export class GetRestrictionsEndpoint implements IGetRestrictionsEndpoint
{
    public async execute(buildingId: number,cateringMenuItemId :string): Promise<IGetCateringRestrictions>
    {
        try
        {
            const response = await axios.get<IGetCateringRestrictions>(`${apis.allApiv2}${buildingId}/catering-menuitem/${cateringMenuItemId }/catering-restrictions?recursive=true`);
            return response.data;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetRestrictionsEndpoint
{
    execute(buildingId: number,cateringMenuItemId :string): Promise<IGetCateringRestrictions>;
}