import { appContext } from "../../../../AppContext";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export default class AppPermissionsDialog extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            show: false,
            json: "{}",
            showError: false,
        };
    }

    public componentDidMount(): void
    {
    }

    public async open(json: string): Promise<void>
    {
        await this.setStateAsync({ show: true, json: json, showError: false });
        this.prettify();
    }

    private prettify(): void
    {
        try
        {
            this.setState({ json: JSON.stringify(JSON.parse(this.state.json), null, 4) });
        }
        catch
        {
        }
    }

    private get isValid(): boolean
    {
        if (this.state.showError)
        {
            return false;
        }
        try
        {
            const object = JSON.parse(this.state.json);
            return !Array.isArray(object);
        }
        catch
        {
            return false;
        }
    }

    private async okClicked(): Promise<void>
    {
        const result = await this.props.onValidate(this.state.json);
        this.setState({ show: !result, showError: !result });
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                open={this.state.show}
                onClose={() => this.setState({ show: false })}
                fullWidth
                header={this.labels.HubLabelJSONView}
                dialogContent=
                {
                    <>
                        <label>{this.labels.HubLabelJSONViewDescription}</label>
                        {
                            !this.isValid &&
                            <div className="text-danger">{this.labels.HubLabelInvalidJSONMessage}</div>
                        }
                        <div className="nonePointer" style={{ margin: "10px 0 0 0" }}>
                            <textarea
                                name="modalJSON"
                                onChange={e => this.setState({ json: e.target.value, showError: false })}
                                className="textAreaUserDetails"
                                value={this.state.json} />
                        </div>
                    </>
                }
                footer=
                {
                    <>
                        <IbssButton
                            color='primary'
                            variant='contained'
                            disabled={!this.isValid}
                            onClick={() => this.okClicked()}
                        >
                            {this.labels.HubLabelOk}
                        </IbssButton>

                        <IbssButton
                            color='primary'
                            variant='contained'
                            disabled={!this.isValid}
                            onClick={() => this.prettify()}
                        >
                            {this.labels.HubLabelFormat}
                        </IbssButton>

                    </>
                }
            />
        );
    }
}

export interface IProps
{
    onValidate: (json: string) => Promise<boolean>;
}

export interface IState
{
    show: boolean;
    json: string;
    showError: boolean;
}
