import React from "react";


function ChartPanelInfo(props: any) {
    const {iconSrc, chartHeading, chartDescription } = props

    return (
        <div className="chart-panel-info">
            <div className="chart-panel-heading">
                <div className="chart-panel-icon">
                    <img src={iconSrc} alt=""/>
                </div>
                <h1 className="chart-panel-header">{chartHeading}</h1>
            </div>
            <div className="chart-panel-description">
                {chartDescription}
            </div>
        </div>
    )
}


export default ChartPanelInfo;