import { Component } from 'react';

export default class CloseIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.734835 0.734835C0.881282 0.588388 1.11872 0.588388 1.26517 0.734835L11.2652 10.7348C11.4116 10.8813 11.4116 11.1187 11.2652 11.2652C11.1187 11.4116 10.8813 11.4116 10.7348 11.2652L0.734835 1.26517C0.588388 1.11872 0.588388 0.881282 0.734835 0.734835Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2652 0.734835C11.4116 0.881282 11.4116 1.11872 11.2652 1.26517L1.26517 11.2652C1.11872 11.4116 0.881282 11.4116 0.734835 11.2652C0.588388 11.1187 0.588388 10.8813 0.734835 10.7348L10.7348 0.734835C10.8813 0.588388 11.1187 0.588388 11.2652 0.734835Z" />
            </svg>
        )
    }
};