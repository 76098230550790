import "./SpaceCard.scss";
import { Component } from "react";
import { Card } from "react-bootstrap";
import Helper from "../../../Common/Helper";
import { appContext, IPartialAppState } from "../../../AppContext";
import { DateTime } from "luxon";
import { Constants } from "../../../Common/Constants";
import { IBooking } from "../../../Providers.Api/Bookings/GetBookingsEndpoint";
import { Box, CardActionArea, MobileStepper } from "@mui/material";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";

export default class SpaceCard extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private appState = appContext().state;

    constructor(props: Props)
    {
        super(props);
        this.state =
        {
            lightModeTheme: true,
            activeBookingStep: 0
        };
    }

    public componentDidMount(): void
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
        this.setState({ activeBookingStep: 0 });
    }

    public render(): JSX.Element
    {
        let props = this.props;
        return (
            <div className="space-card" onClick={() => this.props.onClick(props.spaceId)}>
                <Card id={this.props.spaceId} className={`${this.props.border ? 'border' : ''} ${this.props.selected ? 'card-selected' : ''}`} onFocus={() => this.props.onCardFocused?.(props.spaceId)}>
                    <CardActionArea sx={{ cursor: "auto"}}>
                        <Card.Body className= {this.props.focus ? 'focused' : ''} >
                            <Box sx={{cursor: this.props.pointer ? "pointer" : ""}}>
                            <div className="img-flex-tab">
                                <div className="row space-card__primary-info-row d-flex">
                                    <div className="space-card__primary-info col align-icons-text-end" style={{ width: "87px" }}>
                                        <div className="align-icons-text">
                                            <span className="space-card__icon"><img alt="Floor Icon" src="/images/Sidebar_Icons/Active_icons/Floor.svg" /></span>
                                            <span className="space-card__text space-text-item text-white" style={{ width: "52px" }}>{props.floorName}</span>
                                        </div>
                                    </div>

                                    <div className="space-card__primary-info col align-icons-text-end" style={{ width: "145px" }}>
                                        <div className="align-icons-text">
                                            <span className="space-card__icon"><img alt="Active Icon" src="/images/Sidebar_Icons/Active_icons/Zone.svg" /></span>
                                            <span className="space-card__text space-text-item text-white" style={{ width: "110px" }}>{props.zone}</span>
                                        </div>
                                    </div>

                                    <div className="space-card__primary-info col align-icons-text-end" style={{ width: "58px" }}>
                                        {this.props.closeIcon &&
                                            <div className="align-icons-text modal-close-btn" onClick={this.props.handleClose}>
                                                <IbssSvgIcon fontSize='inherit' className="">
                                                    {Icons.CloseIcon}
                                                </IbssSvgIcon>
                                            </div>
                                        }

                                        <div className="align-icons-text">
                                            <span className="space-card__icon"><img alt="Attendee Icon" src="/images/Sidebar_Icons/Active_icons/Attendee Icon.svg" /></span>
                                            <span className="space-card__text space-text-item text-white" style={{ width: "23px" }}>{props.spaceCapacity}</span>
                                        </div>
                                    </div>
                                </div>
                                <Card.Img className="img-fluid" variant="top" alt={props.spaceType} src={props.imageUrl ? props.imageUrl : Constants.imagePaths.get(props.spaceType)} />
                            </div>
                            <Card.Title className="space-card__type">{props.spaceTypeLabel ? props.spaceTypeLabel : props.spaceType}</Card.Title>
                            <div className="pb-10">
                                <div className="space-card__name">{props.spaceName}</div>

                                {this.props.closeIcon ?? 
                                <div className="row">
                                    <div className="col-12 mt-8 d-flex">
                                        {props.requiresCatering === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.requiresAV === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/ITAV (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.requiresHearing === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.requiresPresentation === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup > 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup >= 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="icon" /></div>
                                            </div>
                                        )}
                                        {
                                            // add an empty div with a min height that matches the space card icons used so that all SpaceCards have same height.
                                            (this.props.closeIcon ??
                                            <div className="align-icons-text ml-8">
                                                <div style={{minHeight: '34px'}}></div>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>}
                            </div>
                            {
                                props.bookings && props.bookings.length > 0 && (
                                    <>
                                        <Card.Title className="space-card__type">{this.labels.HublabelHostVisitFilter}</Card.Title>
                                        <div className="pb-14">
                                            <div className="space-card__name">{props.bookings[this.state.activeBookingStep].Booking_Share_Loc ? props.bookings[this.state.activeBookingStep].Booking_Owner_Name : this.labels.HubLabelAnonymous}</div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="d-flex">
                                {
                                    props.bookings && props.bookings[this.state.activeBookingStep]?.Booking_Start.isValid && (
                                        <div className="pr-2">
                                            <Card.Title className="space-card__type">{this.labels.HubLabelStart}</Card.Title>
                                            <div className="pb-14">
                                                <div className="space-card__name">{props.bookings[this.state.activeBookingStep].Booking_Start.offsetTimeByNode(this.props.nodeId, true).toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
                                            </div>
                                        </div>
                                    )}
                                {
                                    props.bookings && props.bookings[this.state.activeBookingStep]?.Booking_End.isValid && (
                                        <div className="pl-2">
                                            <Card.Title className="space-card__type">{this.labels.HubLabelEnd}</Card.Title>
                                            <div className="pb-14">
                                                <div className="space-card__name">{props.bookings[this.state.activeBookingStep].Booking_End.offsetTimeByNode(this.props.nodeId, true).toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {
                                props.bookings && props.bookings.length == 0 && (
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        <div className="pb-14">
                                            <div className="space-card__name mr-2">
                                                {this.labels.HubNoBookingDataMessage}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                props.bookings && props.bookings.length > 1 &&
                                <div>
                                    <MobileStepper
                                        className='bg-white'
                                        steps={props.bookings.length}
                                        position="static"
                                        activeStep={this.state.activeBookingStep} nextButton={
                                            <IbssSvgIcon
                                                onClick={() => props.bookings?.length == this.state.activeBookingStep + 1 ? {} : this.setState({ activeBookingStep: this.state.activeBookingStep + 1 })}>{Icons.RightArrowIcon}</IbssSvgIcon>
                                        }
                                        backButton={
                                            <IbssSvgIcon onClick={() => this.state.activeBookingStep == 0 ? {} : this.setState({ activeBookingStep: this.state.activeBookingStep - 1 })} sx={{ transform: 'rotate(180deg)' }}>{Icons.RightArrowIcon}</IbssSvgIcon>
                                        } />
                                </div>
                            }
                            </Box>
                        </Card.Body>
                    </CardActionArea>
                </Card>
            </div>
        );
    }
}

export interface IProps
{
    imageUrl: string;
    nodeId: number;
    floorName: string;
    zone: string;

    spaceId: string;
    spaceName: string;
    spaceType: string;
    spaceTypeLabel: string;
    spaceCapacity: number;

    buildingId?: number;
    requiresCatering: number;
    requiresAV: number;
    requiresHearing: number;
    requiresPresentation: number;
    spaceSetup: number;

    showBookingOwner: boolean;
    bookingShareLocation: boolean;
    bookingOwnerName: string;
    showNoBookingMessage: boolean;
    bookingStart: DateTime;
    bookingEnd: DateTime;

    bookings?: IBooking[] | null

    border?: boolean;
    selected?: boolean;
    closeIcon?: boolean;
    pointer?: boolean;
    handleClose?: () => void;

    onClick: (spaceId: string) => void;
    onCardFocused?: (id:string) => void;
    focus?:boolean
}

export class Props implements IProps
{
    public imageUrl = "";
    public nodeId = 0;
    public floorName = "";
    public zone = "";

    public spaceId = "";
    public spaceName = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceCapacity = 0;

    public requiresCatering = 0;
    public requiresAV = 0;
    public requiresHearing = 0;
    public requiresPresentation = 0;
    public spaceSetup = 0;

    public showBookingOwner = false;
    public bookingShareLocation = false;
    public bookingOwnerName = "";
    public showNoBookingMessage = false;
    public bookingStart = DateTime.invalid("null");
    public bookingEnd = DateTime.invalid("null");

    public bookings = null;

    public border = false;
    public selected = false;
    public focus = false;

    public onClick = (spaceId: string) => { };
    public handleFocus = (id:string) => { };

    public static fromSpace(space: ISpace): Props
    {
        const floor = appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i.Node_Id == space.Node_Id);
        let props = new Props();

        props.imageUrl = space.ImageURI;
        props.nodeId = space.Node_Id;
        props.floorName = (floor?.Node_Name ?? "");
        props.zone = space.Meta_Loc_Zone;

        props.spaceId = space.Space_Id;
        props.spaceName = space.Space_Name;
        props.spaceType = space.Space_Type;
        props.spaceTypeLabel = space.Space_Type_Label;
        props.spaceCapacity = space.Space_Capacity;

        props.requiresCatering = space.Meta_Serv_Reqs_Catering;
        props.requiresAV = space.Meta_Serv_Reqs_AV;
        props.requiresHearing = space.Meta_Serv_Reqs_Hearing;
        props.requiresPresentation = space.Meta_Serv_Reqs_Presentation;
        props.spaceSetup = space.Space_Setup;

        return props;
    }
}

export interface IState
{
    lightModeTheme: boolean;
    activeBookingStep: number;
}

export interface ISpace
{
    Node_Id: number;
    Space_Id: string;
    Space_Name: string;
    Space_Capacity: number;
    Space_Type: string;
    Space_Type_Label: string;
    Space_Setup: number;
    ImageURI: string;
    Meta_Loc_Zone: string;
    Meta_Serv_Reqs_Catering: number;
    Meta_Serv_Reqs_AV: number;
    Meta_Serv_Reqs_Hearing: number;
    Meta_Serv_Reqs_Presentation: number;
}
