import { PagedResponse } from "../Models";
import { IGetCostCodeByIdEndpoint } from "./GetCostCodeByIdEndpoint";
import { IGetCostCodesEndpoint } from "./GetCostCodesEndpoint";
import {IGetV2CostCodesEndpoint } from "./GetV2CostCodesEndpoint";
export class CostCodeRepository implements ICostCodeRepository
{
    private getCostCodesEndpoint: IGetCostCodesEndpoint;
    private getV2CostCodesEndpoint: IGetV2CostCodesEndpoint;
    private getCostCodeByIdEndpoint: IGetCostCodeByIdEndpoint;

    constructor(
        getCostCodesEndpoint: IGetCostCodesEndpoint,
        getV2CostCodesEndpoint: IGetV2CostCodesEndpoint,
        getCostCodeByIdEndpoint: IGetCostCodeByIdEndpoint,
    )
    {
        this.getCostCodesEndpoint = getCostCodesEndpoint;
        this.getV2CostCodesEndpoint = getV2CostCodesEndpoint;
        this.getCostCodeByIdEndpoint = getCostCodeByIdEndpoint;
    }

    public getCostCodes(take: number, startOfCodeCode?: string, costCodeType?: CostCodeType): Promise<ICostCode[]>
    {
        return this.getCostCodesEndpoint.execute(take, startOfCodeCode, costCodeType);
    }

    public getV2CostCodes(take: number, skipToken?: string, startOfCodeCode?: string, costCodeSource?: CostCodeSource, costCodeType?: CostCodeType): Promise<PagedResponse<ICostCode[]>>
    {
        return this.getV2CostCodesEndpoint.execute(take, skipToken, startOfCodeCode, costCodeSource, costCodeType);
    }

    public getCostCodeById(costCodeId: string): Promise<ICostCode>
    {
        return this.getCostCodeByIdEndpoint.execute(costCodeId);
    }
}
export interface ICostCodeRepository 
{
    getCostCodes(take: number, startOfCodeCode?: string, costCodeType?: CostCodeType): Promise<ICostCode[]>;
    getV2CostCodes(take: number, skipToken?: string, startOfCodeCode?: string, costCodeSource?: CostCodeSource, costCodeType?: CostCodeType): Promise<PagedResponse<ICostCode[]>>;
    getCostCodeById(costCodeId: string): Promise<ICostCode>;
}

export interface ICostCode
{
    Cost_Code: string;
    Cost_Code_Description: string;
    Cost_Code_Id: string;
    Cost_Code_Type: number;
    Cost_Code_Source: string;
    EventTime: string;
}

export enum CostCodeSource
{
    Any = 'Any',
    SFTP = 'SFTP',
    API = 'API',
    Manual = 'Manual',
}

export enum CostCodeType
{
    Any = 'Any',
    StandardCostCode = 'IBSS Standard Cost Code',
    ClientCode = 'Client Code (WBS)',
    DepartmentCode = 'Department Code',
}
