import React, { Component } from "react";

export default class InputTextBox extends Component {
    state = {
        value: "",
        error: "Provide Value",
    };


    onchange = (e: any) => {
        const { onchange, readonly, isMultiLine }: any = this.props;
        if (!readonly) {
            this.setState({ value: e.target.value });
            onchange(e);
        }
    };

    handleGetClassName = (isMultiLine:any) => {
        return isMultiLine ? "form-input-box-textarea" : ""
    }

    render() {
        const { name, label, placeholder, value, mandatory, readonly, type, isMultiLine, maxLen, rows, cols, onBlur }: any = this.props;


        return (
            <div className={`form-input-box ${this.handleGetClassName(isMultiLine)}`}>
                <label className="form-input-box-label">
                    {label}
                </label>
                <span className="form-input-box-required-asterisk">
                    {
                        readonly ? (
                            <><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></>
                        )
                            : mandatory === true ? (
                                <>*</>)
                                : (
                                    <></>
                                )
                    }
                </span>

                {isMultiLine ?
                    <textarea
                        name={name}
                        className="form-input-box-input form-input-box-input--textarea"
                        value={this.state.value || value}
                        onChange={(e) => {
                            this.onchange(e);
                        }}
                        placeholder='&#8230;'
                        readOnly={readonly ? true : false}
                        rows={rows ? rows : 10}
                        cols={cols ? cols : 5}                        
                    />
                    :
                    <input
                        name={name}
                        className="form-input-box-input"
                        value={this.state.value || value}
                        onChange={(e) => {
                            this.onchange(e);
                        }}
                        placeholder= {placeholder ? placeholder : '\u2026'}
                        // placeholder= "&#8230;"
                        readOnly={readonly ? true : false}
                        type={type == "number" ? "number" : ""}
                        maxLength={maxLen ? maxLen : 1000}
                        inputMode = {type === "text" && maxLen ? "numeric" : "text"}
                        onBlur =  { onBlur ? () =>onBlur() : () => {}} 

                    />
                }
            </div>
        );
    }
}