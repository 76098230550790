import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class EditCateringOrderEndpoint implements IEditCateringOrderEndpoint
{
    public async execute(nodeId: number, orderId: string, payload: IEditCateringOrder): Promise<string>
    {
        try
        {
            const response = await axios.put(apis.allApiv2 + nodeId + '/catering-order/' + orderId, payload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEditCateringOrderEndpoint
{
    execute(nodeId: number, orderId: string, payload: IEditCateringOrder): Promise<string>;
}

export interface IEditCateringOrder
{
    Node_Id: number;
    Menu_Items: string;
    Booking_Id: string;
    Space_Id: string;
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Status: string;
    Catering_Attendees: number;
    Catering_Notes: string;
    Cost_Code_Allocation: string;
    Catering_Total_Value: number;
    Catering_Order_Restrictions: string;
}
