import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CheckInEndpoint implements ICheckInEndpoint
{
    public async execute(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>
    {
        try
        {
            const payload = {
                Space_IsCheckedIn: 1,
                BookingOwnerEmail: bookingOwnerEmail,
            };
            const response = await axios.put(apis.allApi + nodeId + '/Spaces/' + spaceId + '/action', payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICheckInEndpoint
{
    execute(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>;
}
