import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";
import apis from "../../../../Providers.Api/apis";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import { getFloorsFromSelectedBuilding, getAllBuildingsData, getFloorUsingFloorId, getBuildingNameUsingFloorNodeId } from "../../../../Common/Helper";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import TextField from '@mui/material/TextField';
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo"
import { appContext } from "../../../../AppContext";
import Helper from "../../../../Common/Helper";
import { DateHelper } from "../../../../Common/DateHelper";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Box, Grid, TextareaAutosize } from "@mui/material";
import IbssAutocomplete from "../../../../Components/Inputs/AutoComplete/IbssAutocomplete";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import { ICreateTask } from "../../../../Providers.Api/Tasks/CreateTaskEndpoint";
import { IUpdateTask } from "../../../../Providers.Api/Tasks/UpdateTaskEndpoint";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import { RouteComponentProps, RouterProps } from "react-router";
import { INode, IFloor as IFloorData, PagedResponse } from "../../../../Providers.Api/Models";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import Typography from '@mui/material/Typography';
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { AxiosResponse } from "axios";
import { ITaskCategoryData, ISpaceData, ITaskTypeData, Space } from "./DataModels";
import { TypeHelper } from "../../../../Common/TypeHelper";

export default class EditTask extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    private get isNew() { return this.props.match.params.task_Id === "0"; }
    private get taskId() { return this.props.match.params.task_Id ?? '' }
    private get spaceId() { return new URLSearchParams(this.props.location.search).get('spaceid') ?? ''; }
    private get isFlex() { return window.location.href.includes("flex-my-tasks"); }

    private get selectedBuilding() { return appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings).find(i => i.Node_Id.toString() == this.state.selectedBuildingId) ?? null }
    private get selectedFloor() { return this.state.floorOptions.find(i => i.nodeId == this.state.selectedFloorId) ?? null }
    private get selectedSpace() { return this.state.spaceOptions.find(i => i.spaceId == this.state.selectedSpaceId) ?? null }
    private get selectedTaskCategory() { return this.state.taskCategoryOptions.find(i => i.id == this.state.selectedTaskCategoryId) ?? null }
    private get selectedTaskType() { return this.state.taskTypeOptions.find(i => i.id == this.state.selectedTaskTypeId) ?? null }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            buildingId: this.session.getBuildingId(),
            isLoading: false,
            canUpdate: this.local.hasRight("DATAMODEL.Tasks.Update"),
            canCreate: this.local.hasRight("DATAMODEL.Tasks.Create"),

            buildingOptions: [],
            selectedBuildingId: '',
            selectedBuildingName: "",

            floorOptions: [],
            selectedFloorId: 0,
            selectedFloorName: "",

            spaceOptions: [],
            selectedSpaceId: '',
            selectedSpaceName: "",

            taskCategoryOptions: [],
            selectedTaskCategoryId: "",
            selectedTaskCategoryName: "",

            taskTypeOptions: [],
            selectedTaskTypeId: "",
            selectedTaskTypeName: "",

            description: "",
            bookingId: "",
            taskCreatedBy: "",
            taskOwner: "",
            taskPriority: 0,
            taskNodeId: 0,

            taskStatusOptions: [
                { label: this.labels.HubLabelUnassigned, value: "Unassigned" },
                { label: this.labels.HubButtonInProgress, value: "In-Progress" },
                { label: this.labels.HubLabelResolved, value: "Resolved" },
                { label: this.labels.HubLabelCancelled, value: "Cancelled" },
                { label: this.labels.HubLabelAssigned, value: "Assigned" }
            ],
            selectedTaskStatusName: "",
        }
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadBuildings();
        if (!this.isNew)
        {
            await this.loadByTaskId(this.taskId);
        }
        if (this.spaceId)
        {
            await this.loadBySpaceId(this.spaceId);
        }
    }

    private async loadBuildings(): Promise<void>
    {
        const buildingsData = getAllBuildingsData();
        const buildings = buildingsData.map(i => ({ nodeId: i.Node_Id.toString(), name: i.Name } as IBuilding));
        await this.setStateAsync({ buildingOptions: buildings });
    }

    private async loadByTaskId(taskId: string): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            const task = await this.apiClient.tasks.getById(this.state.buildingId, taskId);
            const floor = getFloorUsingFloorId(task.Node_Id);
            const building = getBuildingNameUsingFloorNodeId(task.Node_Id);

            this.setState({
                selectedSpaceId: task.Space_Id,
                selectedTaskCategoryId: task.Task_Type_Id.toString(),
                selectedFloorName: floor,
                selectedBuildingName: building,
                selectedSpaceName: task.Space_Id,
                selectedTaskCategoryName: task.Task_Category_Name,
                selectedTaskTypeName: task.Task_Type_Name,
                description: task.Task_Description,
                taskPriority: task.Task_Priority,
                bookingId: task.Booking_Id,
                taskCreatedBy: task.Task_CreatedBy,
                taskOwner: task.Task_Owner,
                taskNodeId: task.Node_Id,
                selectedTaskStatusName: task.Task_Status,
            });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async loadBySpaceId(spaceId: string): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });

            const spaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.get<PagedResponse<Space[]>>({
                nodeId: this.state.buildingId,
                top: 1,
                filter: `Space_Id eq '${spaceId}'`,
                select: Space,
            });

            if (!spaces.value)
            {
                return;
            }

            const space = spaces.value[0];
            const floor = appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i.Node_Id == space.Node_Id) ?? null;

            if (!floor)
            {
                return;
            }

            await this.setSelectedBuilding(floor.Node_Parent_Id.toString());
            await this.setSelectedFloor(floor.Node_Id);
            await this.setSelectedSpace(space.Space_Id, space.Space_Type);
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async handleBuildingChange(event: SelectChangeEvent<string>): Promise<void>
    {
        await this.setSelectedBuilding(event.target.value);
    }

    private async handleFloorChange(event: React.SyntheticEvent<Element, Event>, value: IFloor | null): Promise<void>
    {
        await this.setSelectedFloor(value?.nodeId ?? 0);
    }

    private async handleSpaceChange(event: React.SyntheticEvent<Element, Event>, value: ISpace | null): Promise<void>
    {
        await this.setSelectedSpace(value?.spaceId ?? '', value?.spaceType ?? '');
    }

    private async handleTaskCategoryChange(event: SelectChangeEvent): Promise<void>
    {
        await this.setSelectedTaskCategory(event.target.value);
    }

    private handleTaskTypeChange(event: React.SyntheticEvent<Element, Event>, value: ITaskType | null): void
    {
        this.setState({ selectedTaskTypeId: value?.id ?? '' });
    }

    private handleClassificationChange(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ selectedTaskStatusName: e.target.value });
    }

    private async setSelectedBuilding(buildingId: string): Promise<void>
    {
        await this.setStateAsync({ selectedBuildingId: buildingId });
        const floors = this.selectedBuilding?.Floors.map(i => ({ nodeId: i.Node_Id, name: i.Node_Name } as IFloor)) ?? [];
        await this.setStateAsync({ floorOptions: floors });
        await this.setSelectedFloor(0);
    }

    private async setSelectedFloor(floorId: number): Promise<void>
    {
        await this.setStateAsync({ selectedFloorId: floorId });
        let spaces: ISpace[] = [];

        if (floorId)
        {
            try
            {
                this.setState({ isLoading: true });
                const spacesData: AxiosResponse<ISpaceData[]> = await apis.getSpaceByFloorId(this.state.selectedBuildingId, floorId);
                spaces = spacesData.data.map(i => ({ nodeId: i.Node_Id, spaceId: i.Space_Id, spaceType: i.Space_Type } as ISpace))
            }
            finally
            {
                this.setState({ isLoading: false });
            }
        }
        await this.setStateAsync({ spaceOptions: spaces });
        await this.setSelectedSpace('', '');
    }

    private async setSelectedSpace(spaceId: string, spaceType: string): Promise<void>
    {
        await this.setStateAsync({ selectedSpaceId: spaceId });
        let taskCategories: ITaskCategory[] = [];

        if (spaceId)
        {
            try
            {
                this.setState({ isLoading: true });
                const taskCategoriesData: AxiosResponse<ITaskCategoryData[]> = await apis.getTaskCategoriesByNodeid(this.state.selectedBuildingId);

                taskCategories = Array.from(taskCategoriesData.data)
                    .filter(i => i.space_Type === spaceType)
                    .sort((a, b) => a.task_Category_Name.localeCompare(b.task_Category_Name))
                    .map(i => ({ id: i.task_Category_Id, name: i.task_Category_Name } as ITaskCategory));
            }
            finally
            {
                this.setState({ isLoading: false });
            }
        }
        await this.setStateAsync({ taskCategoryOptions: taskCategories });
        await this.setSelectedTaskCategory('');
    }

    private async setSelectedTaskCategory(taskCategoryId: string): Promise<void>
    {
        this.setState({ selectedTaskCategoryId: taskCategoryId });
        let taskTypes: ITaskType[] = [];

        if (taskCategoryId)
        {
            try
            {
                this.setState({ isLoading: true });
                const taskTypesData: AxiosResponse<ITaskTypeData[]> = await apis.getTaskTypesByCategoryIdfacility(this.state.selectedBuildingId, taskCategoryId);
                taskTypes = taskTypesData.data.map(i => ({ id: i.Task_Type_Id, name: i.Task_Type_Name } as ITaskType));
            }
            finally
            {
                this.setState({ isLoading: false });
            }
        }
        await this.setStateAsync({ taskTypeOptions: taskTypes, selectedTaskTypeId: '' });
    }

    private async handleSubmit(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            const { match } = this.props;
            const success = await (this.isNew ? this.create() : this.update());

            if (success)
            {
                this.redirect();
            }
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async create(): Promise<boolean>
    {
        if (!this.selectedSpace)
        {
            return false;
        }

        const payload: ICreateTask = {
            Space_Id: this.selectedSpace.spaceId,
            Task_Category_Id: parseInt(this.state.selectedTaskCategoryId),
            Task_Type_Id: parseInt(this.state.selectedTaskTypeId),
            Task_Description: this.state.description,
            Booking_Start: DateHelper.now(),
            Catering_Service_Time: DateHelper.now(),
            Catering_Clearing_Time: DateHelper.now(),
            Catering_Attendees: 0,
            Catering_Notes: "",
            DisableExtUpdate: false
        };

        const response = await this.apiClient.tasks.createTask(this.selectedSpace.nodeId, payload);
        return (!!response);
    }

    private async update(): Promise<boolean>
    {
        const payload: IUpdateTask = {
            Task_Priority: this.state.taskPriority,
            Task_Description: this.state.description,
            Task_Resolve_Description: "",
            Task_Cost_Code: '',
            Catering_Attendees: 0,
            Catering_Service_Time: DateHelper.now(),
            Catering_Clearing_Time: DateHelper.now(),
            Task_Status: this.state.selectedTaskStatusName
        };

        const response = await this.apiClient.tasks.updateTask(this.state.taskNodeId, Number(this.taskId), payload);
        return (!!response);
    }

    private redirect(): void
    {
        this.props.history.push(this.isFlex ? "/flex-my-tasks" : "/operational-services-tasks/" + this.state.buildingId);
    }

    public render(): JSX.Element
    {
        const requiredStatus = (
            this.state.selectedTaskStatusName === "Unassigned" ||
            this.state.selectedTaskStatusName === "Assigned" ||
            this.state.selectedTaskStatusName === "In-Progress" ||
            this.state.selectedTaskStatusName === "Resolved" ||
            this.state.selectedTaskStatusName === "Cancelled"
        );

        return (<>
            {this.state.isLoading && <LoadingOverlay />}
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="form-panel ">
                        <FormPanelInfo
                            formHeading={this.labels.HubLabelTaskDetails}
                            iconSrc="/images/Sidebar_Icons/Light_theme/Tasks.svg"
                        />
                        <div className="form-panel-form">
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <Typography variant="body1" className="my-0">{this.labels.HubLabelTaskDetailsAdd}</Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container spacing={3}>
                                            <Grid item sm={12}>
                                                {!this.isNew ?
                                                    <TextField id="outlined-basic" disabled value={this.state.selectedBuildingName} label={this.labels.HubLabelBuilding} variant="outlined" fullWidth />
                                                    :
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">{this.labels.HubLabelBuilding}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={this.state.selectedBuildingId}
                                                            label={this.labels.HubLabelBuilding}
                                                            onChange={event => this.handleBuildingChange(event)}
                                                        >
                                                            {this.state.buildingOptions.map(i =>
                                                                <MenuItem value={i.nodeId}>{i.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </Grid>
                                            <Grid item sm={6}>
                                                {!this.isNew ?
                                                    <IbssTextField id="outlined-basic" disabled value={this.state.selectedFloorName} label={this.labels.HubLabelFloor} variant="outlined" fullWidth />
                                                    :
                                                    <IbssAutocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={this.state.floorOptions}
                                                        value={this.selectedFloor}
                                                        onChange={(event, value: IFloor) => this.handleFloorChange(event, value)}
                                                        getOptionLabel={(floor: IFloor) => floor.name}
                                                        renderInput={(params) => <TextField {...params} label={this.labels.HubLabelFloor} />}
                                                    />}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {!this.isNew ?
                                                    <IbssTextField id="outlined-basic" disabled value={this.state.selectedSpaceName} label={this.labels.HubLabelSpaces} variant="outlined" fullWidth />
                                                    :
                                                    <IbssAutocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={this.state.spaceOptions}
                                                        getOptionLabel={(option: ISpace) => option.spaceId}
                                                        value={this.selectedSpace}
                                                        onChange={(event, value: ISpace) => this.handleSpaceChange(event, value)}
                                                        renderInput={(params) => <TextField{...params} label={this.labels.HubLabelSpaces} />}
                                                    />}
                                            </Grid>
                                            <Grid item sm={12}>
                                                {!this.isNew ?
                                                    <TextField id="outlined-basic" disabled value={this.state.selectedTaskCategoryName} label={this.labels.HubLabelCategories} variant="outlined" fullWidth />
                                                    :
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">{this.labels.HubLabelCategories}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            displayEmpty
                                                            value={this.state.selectedTaskCategoryId}
                                                            label={this.labels.HubLabelCategories}
                                                            onChange={(event) => this.handleTaskCategoryChange(event as SelectChangeEvent)}
                                                        >
                                                            {this.state.taskCategoryOptions.map(item =>
                                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>}
                                            </Grid>
                                            <Grid item sm={12}>
                                                {!this.isNew ?
                                                    <IbssTextField id="outlined-basic" disabled value={this.state.selectedTaskTypeName} label={this.labels.HubLabeltaskType} variant="outlined" fullWidth />
                                                    :
                                                    <IbssAutocomplete
                                                        id="combo-box-demo"
                                                        options={this.state.taskTypeOptions}
                                                        getOptionLabel={(option: ITaskType) => option.name}
                                                        value={this.selectedTaskType}
                                                        onChange={(event, value: ITaskType) => this.handleTaskTypeChange(event, value)}
                                                        renderInput={(params) => <TextField {...params} label={this.labels.HubLabeltaskType} />}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item sm={12}>
                                                <IbssTextField
                                                    onChange={(e) => this.setState({ description: e.target.value })}
                                                    label={this.labels.HubLabelDescription}
                                                    multiline
                                                    id="outlined-basic" variant="outlined"
                                                    fullWidth
                                                    value={this.state.description}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {!this.isNew &&
                                        <Grid item sm={6}>
                                            <Grid container spacing={2}>
                                                {this.state.bookingId &&
                                                    <Grid item sm={12}>
                                                        <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.bookingId} label="Associated Booking" variant="outlined" />
                                                    </Grid>
                                                }
                                                <Grid item sm={12}>
                                                    <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.taskCreatedBy} label="Booking Host" variant="outlined" />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.taskOwner} label="Assigned" variant="outlined" />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    {requiredStatus ?
                                                        <IbssFormControl fullWidth>
                                                            <IbssInputDropDown
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleClassificationChange(e)}
                                                                value={this.state.selectedTaskStatusName}
                                                                inputLabel={this.labels.dataNotificationClassification_S}
                                                                id='classification'
                                                                options={this.state.taskStatusOptions}
                                                                disabled={!this.isNew}
                                                            />
                                                        </IbssFormControl>
                                                        :
                                                        <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.selectedTaskStatusName} label="Status" variant="outlined" />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </form>
                        </div>
                    </div>
                    <div className="rightPanel-main-button-group">
                        <div>
                            <IbssButtonRedo color="secondary" variant="contained" className="mr-2" onClick={() => this.redirect()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
                            <IbssButtonRedo color="primary" variant="contained" className="ml-2" onClick={() => this.handleSubmit()}>{this.labels.HubButtonSave}</IbssButtonRedo>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams 
{
    task_Id: string;
}

interface IState
{
    buildingId: number;
    isLoading: boolean;
    canUpdate: boolean;
    canCreate: boolean;

    buildingOptions: IBuilding[];
    selectedBuildingId: string;
    selectedBuildingName: string;

    floorOptions: IFloor[];
    selectedFloorId: number;
    selectedFloorName: string;

    spaceOptions: ISpace[];
    selectedSpaceId: string,
    selectedSpaceName: string;

    taskCategoryOptions: ITaskCategory[];
    selectedTaskCategoryId: string;
    selectedTaskCategoryName: string;

    taskTypeOptions: ITaskType[];
    selectedTaskTypeId: string;
    selectedTaskTypeName: string;

    description: string;
    bookingId: string;
    taskCreatedBy: string;
    taskOwner: string;
    taskPriority: number;
    taskNodeId: number;

    taskStatusOptions: ITaskStatus[];
    selectedTaskStatusName: string;
}

interface IBuilding
{
    nodeId: string;
    name: string;
}

interface IFloor
{
    nodeId: number;
    name: string;
}

interface ISpace
{
    nodeId: number;
    spaceId: string;
    spaceType: string;
}

interface ITaskCategory
{
    id: string;
    name: string;
}

interface ITaskType
{
    id: string;
    name: string;
}

interface ITaskStatus
{
    label: string;
    value: string;
}
