import { DateTime } from "luxon";
import { IBookingSummaryData, IGetManyEndpoint } from "./GetManyEndpoint";


export class BookingDailySummaryRepository implements IBookingDailySummaryRepository
{
    private getManyEndpoint: IGetManyEndpoint;

    constructor(
        getManyEndpoint: IGetManyEndpoint,
    )
    {
        this.getManyEndpoint = getManyEndpoint;
    }

    public getMany(buildingId: number, startTime: DateTime, endTime: DateTime): Promise<IBookingSummaryData[]>
    {
        return this.getManyEndpoint.execute(buildingId, startTime, endTime);
    }
}

export interface IBookingDailySummaryRepository
{
    getMany(buildingId: number, startTime: DateTime, endTime: DateTime): Promise<IBookingSummaryData[]>;
}
