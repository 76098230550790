import { Component } from 'react';

export default class TimeIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.147 1.47073C5.35508 1.47073 1.47049 5.35532 1.47049 10.1472C1.47049 14.9391 5.35508 18.8237 10.147 18.8237C14.9388 18.8237 18.8234 14.9391 18.8234 10.1472C18.8234 5.35532 14.9388 1.47073 10.147 1.47073ZM0.588135 10.1472C0.588135 4.86801 4.86777 0.588379 10.147 0.588379C15.4262 0.588379 19.7058 4.86801 19.7058 10.1472C19.7058 15.4264 15.4262 19.706 10.147 19.706C4.86777 19.706 0.588135 15.4264 0.588135 10.1472Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4412 4.70605C10.6848 4.70605 10.8824 4.90358 10.8824 5.14723V9.85311C10.8824 10.0968 10.6848 10.2943 10.4412 10.2943C10.1975 10.2943 10 10.0968 10 9.85311V5.14723C10 4.90358 10.1975 4.70605 10.4412 4.70605Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1292 9.54084C10.3015 9.36855 10.5808 9.36855 10.7531 9.54084L13.6943 12.482C13.8666 12.6543 13.8666 12.9336 13.6943 13.1059C13.522 13.2782 13.2427 13.2782 13.0704 13.1059L10.1292 10.1648C9.95693 9.99247 9.95693 9.71313 10.1292 9.54084Z" />
            </svg>
        )
    }
};