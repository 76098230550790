import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MobileStepper from '@mui/material/MobileStepper';

const style = {
 
 
};


interface ModalType {
  isOpen: boolean;
  maxSteps: number;
  activeStep: number;
  image: string;
  content: any;
  toggle?: () => void;
  header?: any;
  backgroundColor?: string
  children?: JSX.Element;
}

const IbssOnBoardingPopup = (props: ModalType) => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {props.isOpen && (
        <Modal
          keepMounted
          open={open}
          disableAutoFocus={true}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >

          <Box className="ibss-modal"sx={style}>
            <div className="ibss-modal-header">
              {props.header}
            </div>
            <div className="p-24">
            <div className="mt-20 mb-34">
              <img src={`/images/${props.image}.svg`} />
            </div>
            <div>
              <MobileStepper
                className='bg-white'
                steps={props.maxSteps}
                position="static"
                // sx={{ backgroundColor: "#FFFFFF"}}
                activeStep={props.activeStep}  nextButton={
                  <div/>
                }
                backButton={
                  <div/>
                } />
            </div>

            {props.content}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default IbssOnBoardingPopup;