import { Box, Grid } from '@mui/material'
import React, { Component } from 'react'
import { appContext } from '../../../../AppContext';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { IAssociatedDetail, IVisitorStatus } from './EditVisit';
import { IbssLuxonDateTimePicker } from '../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import VisitorStatusChecker from './VisitorStatusChecker';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';

export default class AssociatedDetails extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }

    render() 
    {
        const { arrivalLocation, arrivalLocationId, bookingName, bookingId, bookingStart, bookingEnd } = this.props.associatedDetails;
        return (
            <Box className="tablePanel">
                <h3>{this.labels.HubLabelAssociatedDetails}</h3>
                <IbssTextField
                    fullWidth
                    required
                    disabled
                    label={this.labels.HubLabelArrivalLocation}
                    value={arrivalLocation}
                    variant="outlined"
                    className='mt-3'
                />
                <IbssTextField
                    fullWidth
                    required
                    disabled
                    label={this.labels.HubLabelArrivalLocationId}
                    value={arrivalLocationId}
                    variant="outlined"
                    className='mt-3'
                />
                <IbssTextField
                    fullWidth
                    required
                    disabled
                    label={this.labels.HubLabelBookingName}
                    value={bookingName}
                    variant="outlined"
                    className='mt-3'
                />
                <IbssTextField
                    fullWidth
                    required
                    disabled
                    label={this.labels.HublabelBookingId}
                    value={bookingId}
                    variant="outlined"
                    className='mt-3'
                />
                <Box display={'flex'} sx={{'& > :not(style)': { my: 2,mr:1,},}}>
                    <IbssLuxonDateTimePicker
                        disabled
                        onChange={() => {}}
                        label={this.labels.HubLabelBookingStart}
                        value={bookingStart}
                    />
                    <IbssLuxonDateTimePicker
                        disabled
                        onChange={() => {}}
                        label={this.labels.HubLabelBookingEnd}
                        value={bookingEnd}
                    />
                </Box>
            </Box>
        )
    }
}

interface IProps
{
    associatedDetails: IAssociatedDetail;
    visitorStatus: IVisitorStatus;
}

interface IState {}