import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";

export default class Messages extends IbssComponent<IProps, IState>
{
    private className(severity: MessageSeverity)
    {
        switch (severity)
        {
            case MessageSeverity.Information:
                return "alert-primary";
            case MessageSeverity.Success:
                return "alert-success";
            case MessageSeverity.Warning:
                return "alert-warning";
            case MessageSeverity.Error:
                return "alert-danger";
            default:
                throw new Error("Not supported.");
        }
    }

    public render(): JSX.Element
    {
        const { messages } = this.props;
        if (messages.length === 0)
        {
            return <></>;
        }

        return (
            <div style={{ margin: "20px 40px -31px 24px" }}>
                {messages.map(i => <div className={`alert ${this.className(i.severity)}`} role="alert">{i.value}</div>)}
            </div>
        );
    }
}

export interface IProps
{
    messages: IMessage[];
}

export interface IState
{
}

export interface IMessage
{
    id: string;
    severity: MessageSeverity;
    value: string;
}

export enum MessageSeverity
{
    Information,
    Success,
    Warning,
    Error,
}
