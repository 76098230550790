import React, { Component } from 'react';

export default class Café extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80074 1.97868C8.21837 0.934616 9.22956 0.25 10.3541 0.25H23.6459C24.7704 0.25 25.7816 0.934615 26.1992 1.97868L27.542 5.33554C28.2432 5.56372 28.75 6.22267 28.75 7V8C28.75 8.9665 27.9665 9.75 27 9.75H26.7194L25.7493 33.0312C25.7326 33.4329 25.4021 33.75 25 33.75H9C8.59793 33.75 8.26739 33.4329 8.25065 33.0312L7.2806 9.75H7C6.0335 9.75 5.25 8.9665 5.25 8V7C5.25 6.22268 5.7568 5.56374 6.45799 5.33555L7.80074 1.97868ZM7 6.75H27C27.1381 6.75 27.25 6.86193 27.25 7V8C27.25 8.13807 27.1381 8.25 27 8.25H26H8H7C6.86193 8.25 6.75 8.13807 6.75 8V7C6.75 6.86193 6.86193 6.75 7 6.75ZM24.8065 2.53576L25.8922 5.25H8.10776L9.19346 2.53576C9.38329 2.06119 9.84292 1.75 10.3541 1.75H23.6459C24.1571 1.75 24.6167 2.06119 24.8065 2.53576ZM25.2181 9.75H8.7819L8.92773 13.25H25.0723L25.2181 9.75ZM25.0098 14.75H8.99023L9.3444 23.25H24.6556L25.0098 14.75ZM9.7194 32.25L9.4069 24.75H24.5931L24.2806 32.25H9.7194ZM14.75 19C14.75 17.7574 15.7574 16.75 17 16.75C18.2426 16.75 19.25 17.7574 19.25 19C19.25 20.2426 18.2426 21.25 17 21.25C15.7574 21.25 14.75 20.2426 14.75 19ZM17 15.25C14.9289 15.25 13.25 16.9289 13.25 19C13.25 21.0711 14.9289 22.75 17 22.75C19.0711 22.75 20.75 21.0711 20.75 19C20.75 16.9289 19.0711 15.25 17 15.25Z"/>
            </svg>
        )
    }
};