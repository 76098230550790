import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Helper } from "../Helper";
import { Editor, Element as SlateElement } from "slate";

export default class UnorderedListButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.helper.toggleList(false);
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();
        const isActive = this.helper.isList(false);
        const variant = (isActive ? "contained" : undefined);
        const sx = { backgroundColor: (isActive ? "var(--ui-mid-tone)" : undefined) };

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title={labels.funcUnorderedList_S}
                variant={variant}
                sx={sx}
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: 'var(--ui-text)' }} fillOpacity="1" width="24px" height="24px" viewBox="-5 -7 60 60">
                    <path d="M8 21c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM8 9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 24.33c-1.47 0-2.67 1.19-2.67 2.67s1.2 2.67 2.67 2.67 2.67-1.19 2.67-2.67-1.2-2.67-2.67-2.67zM14 38h28v-4H14v4zm0-12h28v-4H14v4zm0-16v4h28v-4H14z" />
                </svg>
            </Button>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
}
