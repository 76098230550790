import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Helper } from "../Helper";
import { Editor, Element as SlateElement } from "slate";

export default class HeadingButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);
    constructor(props: IProps)
    {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.setState({ showMenu: true });
    }

    private handleMenuOverlayMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.setState({ showMenu: false });
    }

    private handleMenuItemMouseDown(event: React.MouseEvent, level: 0 | 1 | 2 | 3 | 4 | 5 | 6): void
    {
        event.preventDefault();
        this.setState({ showMenu: false });
        this.helper.setHeading(level);
    }

    public render(): JSX.Element
    {
        const { labels } = appContext();
        const { showMenu } = this.state;
        const isActive = this.helper.isHeading();
        const variant = (isActive ? "contained" : undefined);
        const buttonStyle = { backgroundColor: (isActive ? "var(--ui-mid-tone)" : undefined) };
        const menuStyle = { display: (showMenu ? "block" : "none") };
        const menuOverlayStyle = { display: (showMenu ? "block" : "none") };

        return (
            <>
                <Button
                    className="markdown-editor__toolbar-button"
                    title={labels.funcHeading_S}
                    variant={variant}
                    sx={buttonStyle}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-4 -4 34 34" fill="none" onMouseDown={e => this.handleMouseDown(e)}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9C4 6.79086 5.79086 5 8 5H11H16H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H17V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V7H12V19C12 19.5523 11.5523 20 11 20C10.4477 20 10 19.5523 10 19V13H8C5.79086 13 4 11.2091 4 9ZM10 11V7H8C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11H10Z" style={{ fill: 'var(--ui-text)' }} />
                    </svg>
                    <div
                        className="markdown-editor__overlay"
                        style={menuOverlayStyle}
                        onClick={e => this.handleMenuOverlayMouseDown(e)}
                    >
                    </div>
                    <div
                        className="markdown-editor__dialog markdown-editor__dialog--heading"
                        style={menuStyle}
                    >
                        <ul>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 0)}><span>Text</span></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 1)}><h1>Heading 1</h1></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 2)}><h2>Heading 2</h2></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 3)}><h3>Heading 3</h3></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 4)}><h4>Heading 4</h4></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 5)}><h5>Heading 5</h5></li>
                            <li onMouseDown={e => this.handleMenuItemMouseDown(e, 6)}><h6>Heading 6</h6></li>
                        </ul>
                    </div>
                </Button>
            </>
        );
    }
}

export interface IProps
{
    editor: Editor;
}

export interface IState
{
    showMenu: boolean;
}
