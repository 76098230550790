import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { Space, SpacesFilter } from "./SpaceRepository";
import { IRawPagedResponse } from "../Models";

export class GetV2AllSpacesEndpoint implements IGetV2AllSpacesEndpoint
{
    public async execute(nodeId: number, filter?: SpacesFilter): Promise<Space[]>
    {
        try
        {   
            const query =
                `$top=1000&` +
                `$filter=${filter?.toODataString() ?? ""}&`;
            const response = await axios.get<IRawPagedResponse<Space[]>>(`${apis.allApiv2}${nodeId}/Spaces?${query}`);
            return response.data.Value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2AllSpacesEndpoint
{
    execute(nodeId: number,filter?: SpacesFilter ): Promise<Space[]>;
}
