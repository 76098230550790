import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ODataQuery } from "../ODataQuery";
import { IRawPagedResponse, PagedResponse } from "../Models";

export class GetManyV2Endpoint implements IGetManyV2Endpoint
{
    public async execute<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>
    {
        try
        {
            const queryString = query.toODataString("", "");
            const response = await axios.get<IRawPagedResponse<TVisit[]>>(`${apis.allApiv2}${query.nodeId}/Visits?${queryString}`);
            response.data.Value.forEach(i => query.fixTypes(i));
            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyV2Endpoint
{
    execute<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>;
}
