import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";


export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(buildingId: number,payload: IUpdateOrpderPolicyPayload): Promise<void>
    {
        try
        {
            await axios.put(`${apis.allApiv2}${buildingId}/catering-order-policy/${payload.OrderPolicy_Id}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(buildingId: number,payload: IUpdateOrpderPolicyPayload): Promise<void>;
}

export interface IUpdateOrpderPolicyPayload
{
    OrderPolicy_Id:string;
    Description: string;
    AdvanceNotice: string;
    PreparationTime_Mins: number;  
    Kitchen_OperatingHours_Start:string;
    Kitchen_OperatingHours_Stop: string;
    Name: string;
    Node_Id: number;
}
