import React, { Component } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { InputOption } from '../../../../Components/Inputs/SelectList2/InputSelectBox';
import Helper from '../../../../Common/Helper';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IUserPreferences, IUserPreferencesNode } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import { IFloor, INode } from '../../../../Providers.Api/Models';
import { ISpaceZone } from '../../../../Providers.Api/SpaceZones/GetSpaceZones';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { PageHelper } from './PageHelper';

export default class Building extends IbssPage<IProps, State>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get alert() { return appContext().alert; }
    private get userPreferences() { return appContext().userPreferencesService; }
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props)
        this.state = new State();
    }

    public async componentDidMount(): Promise<void>
    {
        await this.setStateAsync({ userPreferences: this.local.getUserPreferences() });
        await this.populateBuildings();
        await this.populateFloors();
        await this.populateZones();
    }

    private async populateBuildings(): Promise<void>
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name < b.Name ? -1 : 1);
        const defaultBuildingId = this.state.userPreferences?.SearchPrefs?.DefaultBuilding;
        const selectedBuilding: INode | null = buildings.find(i => i.Node_Id == defaultBuildingId) ?? buildings[0] ?? null;

        await this.setStateAsync({
            buildingOptions: buildings.map(i => new InputOption(i.Name, i.Node_Id.toString())),
            selectedBuildingId: selectedBuilding?.Node_Id?.toString() ?? "",
        });
    }

    private async populateFloors(): Promise<void>
    {
        const floors = (Helper.getFloorsFromSelectedBuilding(this.selectedBuildingIdAsNumber) as IFloor[]).sort((a, b) => a.Node_Name < b.Node_Name ? -1 : 1);
        const defaultFloorId = this.state.userPreferences?.Nodes?.find(i => i.NodeId === this.selectedBuildingIdAsNumber)?.DefaultFloor ?? null;
        const selectedFloor: IFloor | null = floors.find(i => i.Node_Id == defaultFloorId) ?? floors[0] ?? null;

        await this.setStateAsync({
            floorOptions: floors.map(i => new InputOption(i.Node_Name, i.Node_Id.toString())),
            selectedFloorId: selectedFloor?.Node_Id?.toString() ?? "",
        });
    }

    private async populateZones(): Promise<void>
    {
        let zones: ISpaceZone[];
        try
        {
            zones = await this.apiClient.spaceZones.getMultiple(this.selectedFloorIdAsNumber);
            zones = zones.sort((a, b) => a.Meta_Loc_Zone < b.Meta_Loc_Zone ? -1 : 1);
        } catch {
            zones = [];
        }

        const defaultZoneId = this.state.userPreferences?.Nodes?.find(i => i.NodeId === this.selectedBuildingIdAsNumber)?.DefaultZone ?? -1;
        const selectedZone: ISpaceZone | null = zones.find(i => i.Space_Zone_Id == defaultZoneId) ?? null;

        const zoneOptions = [
            new InputOption(this.labels.HubLabelAny, "-1"),
            ...zones.map(i => new InputOption(i.Meta_Loc_Zone, i.Space_Zone_Id.toString()))
        ];

        await this.setStateAsync({
            zoneOptions: zoneOptions,
            selectedZoneId: selectedZone?.Space_Zone_Id?.toString() ?? "-1",
        });
    }

    private async buildingChanged(option: React.ChangeEvent<HTMLSelectElement>): Promise<void>
    {
        await this.setStateAsync({ selectedBuildingId: option.target.value });
        await this.populateFloors();
        await this.populateZones();
    }

    private async floorChanged(option: React.ChangeEvent<HTMLSelectElement>): Promise<void>
    {
        await this.setStateAsync({ selectedFloorId: option.target.value });
        await this.populateZones();
    }

    private async zoneChanged(option: React.ChangeEvent<HTMLSelectElement>): Promise<void>
    {
        await this.setStateAsync({ selectedZoneId: option.target.value });
    }

    private async saveClicked(): Promise<void>
    {
        const payload: IUserPreferences = {
            ...this.state.userPreferences,
            SearchPrefs:
            {
                DefaultBuilding: this.selectedBuildingIdAsNumber,
                DefaultSearchResults: this.state.userPreferences.SearchPrefs.DefaultSearchResults,
            },
            Nodes: [{
                NodeId: this.selectedBuildingIdAsNumber,
                DefaultFloor: this.selectedFloorIdAsNumber,
                DefaultZone: this.selectedZoneIdAsNumber
            }]
        };

        try
        {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Grid container>
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleTailorNotification}</Typography>

                            <Typography className='card-userPref-text mb-32' variant="body2" gutterBottom>{this.labels.HubSubtitleworkplace}</Typography>

                        </Grid>

                        <Grid className='mb-16'>
                            <IbssInputDropDown
                                inputLabel={this.labels.dataUserPrefWorkplaceDefBuilding_L}
                                name="DefaultBuilding"
                                id="DefaultBuilding"
                                onChange={(i: any) => this.buildingChanged(i)}
                                options={this.state.buildingOptions}
                                value={this.state.selectedBuildingId}
                            />
                        </Grid>

                        <Grid className='mb-16'>
                            <IbssInputDropDown
                                inputLabel={this.labels.dataUserPrefWorkplaceDefFloor_L}
                                name="DefaultFloor"
                                id="DefaultFloor"
                                onChange={(i: any) => this.floorChanged(i)}
                                options={this.state.floorOptions}
                                value={this.state.selectedFloorId}
                            />
                        </Grid>

                        <Grid className='mb-16'>
                            <IbssInputDropDown
                                inputLabel={this.labels.dataUserPrefWorkplaceDefZone_L}
                                name="SpaceZone"
                                id="SpaceZone"
                                onChange={(i: any) => this.zoneChanged(i)}
                                options={this.state.zoneOptions}
                                value={this.state.selectedZoneId}
                            />
                        </Grid>

                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => this.saveClicked()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }

    private get selectedBuildingIdAsNumber(): number
    {
        let buildingId = Number(this.state.selectedBuildingId);
        if (isNaN(buildingId))
        {
            buildingId = -1;
        }
        return buildingId;
    }

    private get selectedFloorIdAsNumber(): number
    {
        let floorId = Number(this.state.selectedFloorId);
        if (isNaN(floorId))
        {
            floorId = -1;
        }
        return floorId;
    }

    private get selectedZoneIdAsNumber(): number
    {
        let zoneId = Number(this.state.selectedZoneId);
        if (isNaN(zoneId))
        {
            zoneId = -1;
        }
        return zoneId;
    }
}

export interface IProps { }

export class State
{
    public buildingOptions = new Array<InputOption>();
    public selectedBuildingId = "";
    public floorOptions = new Array<InputOption>();
    public selectedFloorId = "";
    public zoneOptions = new Array<InputOption>();
    public selectedZoneId = "";
    public userPreferences: IUserPreferences = PageHelper.getUserPrefrenceJson();
}
