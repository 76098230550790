import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IBookingPolicyData } from "./BookingPolicyRepository";

export class GetSpaceBookingPolicyEndpoint implements IGetSpaceBookingPolicyEndpoint
{
    public async execute(nodeId: number, spaceId: string): Promise<IBookingPolicyData>
    {
        try
        {
            const response: AxiosResponse<IBookingPolicyData> = await axios.get(`${apis.allApiv2}${nodeId}/spaces/${spaceId}/booking-policy`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetSpaceBookingPolicyEndpoint
{
    execute(nodeId: number, spaceId: string): Promise<IBookingPolicyData>;
}
