import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse } from "../Models";
import { ICateringOrderPolicy } from "./CateringOrderPoliciesRepository";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(buildingId: number): Promise<ICateringOrderPolicy[]>
    {
        try
        {
            
            const query =
                `schema=false&` +
                `showDebugStats=false&` +
                `$top=2000&` +
                `$select=Node_Id,OrderPolicy_Id,OrderPolicy_Ref,Description,AdvanceNotice,PreparationTime_Mins,Kitchen_OperatingHours_Start,Kitchen_OperatingHours_Stop,Name,ConcurrencyStamp,OwnedByUserId,OwnedByRoleId,CreatedByUserId,ModifiedByUserId,TenancyId`;

            const response = await axios.get<IRawPagedResponse<ICateringOrderPolicy[]>>(`${apis.allApiv2}${buildingId}/catering-order-policy?${query}`);

            return response.data.Value;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(buildingId: number): Promise<ICateringOrderPolicy[]>;
}



