import { Component } from 'react';
export default class WarningIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2679 5C11.0377 3.66667 12.9623 3.66667 13.7321 5L18.9282 14C19.698 15.3333 18.7358 17 17.1962 17H6.80385C5.26425 17 4.302 15.3333 5.0718 14L10.2679 5Z" stroke="white" stroke-width="2" />
                <path d="M12 6.15381V12.3077" stroke="white" stroke-width="2" stroke-linecap="round" />
                <ellipse cx="11.9999" cy="14.7692" rx="0.6" ry="0.615385" fill="white" />
            </svg>

        )
    }
};
