
export class KeyValue<TKey, TValue>
{
    constructor(key: TKey, value: TValue)
    {
        this.key = key;
        this.value = value;
    }

    public key: TKey;
    public value: TValue;
}
