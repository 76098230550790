import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import SelectBox from "../../../../Components/Inputs/SelectBox/SelectBox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { Button, Modal } from 'react-bootstrap';
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo"
import { appContext } from "../../../../AppContext";
import { IIdentityProviderPayload } from "../../../../Providers.Api/Models";

class EditIdentityProvider extends Component {
  private labels = appContext().labels;
  private alert = appContext().alert;
  private local = appContext().localStorageProvider;

  state = {
    isLoadingToSave: false,
    loading: false,
    page1show: true,
    activePage: 0,
    updates: false,
    title: "",
    id: 0,
    securityProviderTypeId: 0,
    clientId: "",
    tenantDomain: "",
    tenantId: "",
    clientSecret: "",
    clientSecretUpdated: false,
    name: "",
    isDefault: false,
    additionalAudiences: "",
    nameClaimType: "",
    passwordComplexity: 0,
    maxPasswordAge: 0,
    minPasswordLength: 0,
    status: 0,
    lastSync: "",
    lastSyncStatus: "",
    lastSyncInfo: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
    identityProviderDetails: null,
  };
  componentDidMount() {
    const { match }: any = this.props;
    if (match.params.id !== "0") {
      match.params.id && this.getDetails(match.params.id);
      this.getRightList();
    } else {
      this.setState({
        title: this.labels.HubLabelNewProvider,
        id: 0,
        isUpdateRight: true,
      })
    }
  }

  getRightList = () => {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.IdentityProviders && res.ADMINPORTAL.IdentityProviders.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.IdentityProviders && res.ADMINPORTAL.IdentityProviders.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any) => {
    this.setState({ loading: true });
    apis.getIdentityProvidersDetailsById(id).then((res) => {
      this.setState({ ...res.data, clientSecret: this.replaceStringValues(res.data.clientSecret, '*', 3), loading: false, identityProviderDetails: res.data, }, () => {
        this.setState({
          title: this.state.name
        });
      });
    }).catch((error: any) => { });

  };

  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match }: any = this.props;
    const isNew = (match.params.id === "0");
    const isNative = (this.state.securityProviderTypeId === 0);
    const oldDetails = this.state.identityProviderDetails !== null ? this.state.identityProviderDetails : this.state;
    const newDetails = this.state;

    const getValue = <T,>(oldValue: T, newValue: T): T | undefined => 
    {
      return (isNew || oldValue !== newValue ? newValue : undefined);
    };

   const payload:IIdentityProviderPayload = 
   {
        securityProviderTypeId: getValue(oldDetails.securityProviderTypeId, newDetails.securityProviderTypeId),
        clientId: getValue(oldDetails.clientId, newDetails.clientId),
        tenantDomain: getValue(oldDetails.tenantDomain, newDetails.tenantDomain),
        tenantId: getValue(oldDetails.tenantId, newDetails.tenantId),
        name: getValue(oldDetails.name, newDetails.name),
        additionalAudiences: (isNative ? "" : getValue(oldDetails.additionalAudiences, newDetails.additionalAudiences)),
        nameClaimType: getValue(oldDetails.nameClaimType, newDetails.nameClaimType),
        maxPasswordAge: (isNative ? getValue(oldDetails.maxPasswordAge, newDetails.maxPasswordAge) : 0),
        minPasswordLength: (isNative ? getValue(oldDetails.minPasswordLength, newDetails.minPasswordLength) : 0),
        passwordComplexity: (isNative ? getValue(oldDetails.passwordComplexity, newDetails.passwordComplexity) : 0),
        status: getValue(oldDetails.status, newDetails.status),
        isDefault: getValue(oldDetails.isDefault, newDetails.isDefault),
        clientSecret: (this.state.clientSecretUpdated ? this.state.clientSecret : undefined),
    }; 
    isNew ? this.create(payload) : this.patch(payload);
};

 patch = async (payload: IIdentityProviderPayload): Promise<void> => {
  const { match, history }: any = this.props;
  const id = match.params.id;
  try {
    if (Object.entries(payload).length === 0) {
      return;
    }
    await apis.patchProvider(id, payload);
    history.push("/security-identity-providers");
  } finally {
    this.setState({ isLoadingToSave: false });
  }
}


  create = (payload: any) => {
    apis.createProvider(payload).then((res) => {
      const { history }: any = this.props;
      this.setState({
        updates: true,
        isLoadingToSave: false,
      }, () => {
        history.push("/security-identity-providers/details/" + res.data.id);
        window.location.reload();
      })

    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false })
    });
  };

  change = (e: any) => {
    this.setState({ updates: true })
    if (e.target.name === "minPasswordLength") {
      if (e.target.value === 0) {
        this.alert.show("", this.labels.HubLabelZeroNotAcceptable);
      } else {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target.name === "clientSecret")
    {
      this.setState({ clientSecretUpdated: true });
    }
  };
  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    if (this.state.isDelete) {
      apis.deleteById(id).then((res) => {
        const { history }: any = this.props;
        history.push("/security-identity-providers");
      }).catch((error: any) => { });
    }
  };

  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/security-identity-providers");
    }
  }

  syncaad = () => {
    apis.getsyncaad().then((res) => {
    }).catch((error: any) => { });
  }

  showModal = () => {
    this.alert.show("", this.state.lastSyncInfo);
  }

  setChecked = (e: any) => {
    this.setState({ updates: true })
    if (e.target.name === 'isDefault' && this.state.isDefault === true) {
      this.setState({
        isDefault: false
      })
    }
    if (e.target.name === 'isDefault' && this.state.isDefault === false) {
      this.setState({
        isDefault: true
      })
    }
  };

  private replaceStringValues(string: string, value: string, index: number): string
  {
    if(string)
    {
      let updatedString = string.substring(0, index);
      let i = index;
      while (i < string.length)
      {
        updatedString = updatedString + value;
        i += 1;
      }
      return updatedString;
    }
    return string;
  }

  render() {
    const { location, match, history }: any = this.props;
    const id = match.params.id;
    const {
      name,
      activePage,
      securityProviderTypeId,
      clientId,
      tenantId,
      clientSecret,
      nameClaimType,
      minPasswordLength,
      maxPasswordAge,
      passwordComplexity,
      tenantDomain,
      additionalAudiences,
      status,
      lastSync,
      lastSyncStatus
    }: any = this.state;

    const nameFld = {
      name: "name",
      label: this.labels.HubLabelName,
      value: name,
      mandatory: true,
      placeholder: this.labels.HubLabelNamePlaceholderText,
      onchange: this.change,
    };
    const IdentityProviderTypeFld = {
      name: "securityProviderTypeId",
      label: this.labels.HubLabelSecurityProviderType,
      value: securityProviderTypeId,
      mandatory: true,
      disabled: this.state.id !== 0,
      onchange: (value: any) => {
        this.setState({
          securityProviderTypeId: value,
          updates: true
        });
      },
      type: "option",
      options: [
        { title: this.labels.HubLabelNativeIdentityProvider, value: 0 },
        { title: this.labels.HubLabelAAD, value: 1 },
        { title: this.labels.HubLabelOKTAIdentity, value: 2 }
      ],
      placeholder: this.labels.HubLabelIdentityProviderPlaceholderText,
    };
    const clientIdfld = {
      name: "clientId",
      label: this.labels.HubLabelClientID,
      value: clientId,
      mandatory: true,
      placeholder: this.labels.HubLabelClientIDPlaceholderText,
      onchange: this.change,
    };
    const tenantIdfld = {
      name: "tenantId",
      label: this.labels.HubLabelDirectoryClientID,
      value: tenantId,
      mandatory: true,
      placeholder: this.labels.HubLabelProviderClientIDPlaceholderText,
      onchange: this.change,
    };
    const tenantDomainfld = {
      name: "tenantDomain",
      label: this.labels.HubLabelTenantDomain,
      value: tenantDomain,
      mandatory: true,
      placeholder: this.labels.HubLabelTenantDomain,
      onchange: this.change,
    };
    const additionalAudiencesfld = {
      name: "additionalAudiences",
      label: this.labels.HubLabelAdditionalAudiences,
      value: additionalAudiences,
      mandatory: true,
      placeholder: this.labels.HubLabelAudiences,
      onchange: this.change,
    };
    const clientsecretIdfld = {
      name: "clientSecret",
      label: this.labels.HubLabelClientSecret,
      value: clientSecret,
      mandatory: true,
      placeholder: this.labels.HubLabelClientSecretPLR,
      onchange: this.change,
    };
    const claimtypefld = {
      name: "nameClaimType",
      label: this.labels.HubLabelClaimType,
      value: nameClaimType,
      mandatory: true,
      placeholder: this.labels.HubLabelClaimTypePLR,
      onchange: this.change,
    };
    const minpassfld = {
      name: "minPasswordLength",
      label: this.labels.HubLabelMinimumPasswordLength,
      value: minPasswordLength,
      mandatory: true,
      placeholder: this.labels.HubLabelMinPasswordLength,
      onchange: this.change,
    };
    const maxpassagefld = {
      name: "maxPasswordAge",
      label: this.labels.HubLabelMaxPasswordAge,
      value: maxPasswordAge,
      mandatory: true,
      placeholder: this.labels.HubLabelMaxPasswordLength,
      onchange: this.change,
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.name,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.name,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/security-identity-providers") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }

    const lastSyncFld = {
      name: "lastSync",
      label: this.labels.HubLabelLastSynchronisation,
      value: lastSync,
      readonly: true,
      placeholder: this.labels.HubLabelProvideLastSynchronisation,
      onchange: this.change,
    };
    const lastSyncStatusFld = {
      name: "lastSyncStatus",
      label: this.labels.HubLabelStatus,
      value: lastSyncStatus,
      readonly: true,
      placeholder: this.labels.HubLabelProvideStatus,
      onchange: this.change,
    };
    const passcomplexfld = {
      name: "passwordComplexity",
      label: this.labels.HubLabelPasswordComplexity,
      value: passwordComplexity,
      mandatory: true,
      onchange: (value: any) => {
        this.setState({
          passwordComplexity: value,
          updates: true
        });
      },
      type: "option",
      options: [
        { title: this.labels.HubLabelOnecharacterAnyType, value: 1 },
        { title: this.labels.HubLabelOnecharacterTwoDifferenttypes, value: 2, selected: true },
        { title: this.labels.HubLabelOnecharacterThreeDifferentTypes, value: 3 },
        { title: this.labels.HubLabelOnecharacterFOURDifferenttypes, value: 4 },
      ],
      placeholder: "Provide value",
    }
    let divClassName
    if (id) {
      divClassName = "col-12";
    } else {
      divClassName = "col-8 mr-2";
    }
    let secondTabTitle = this.labels.HubTabProperties
    if (securityProviderTypeId !== null) {
      if (securityProviderTypeId === 0) {
        secondTabTitle = this.labels.HubLabelNativeProperty
      } else if (securityProviderTypeId === 1) {
        secondTabTitle = this.labels.HubLabelAzureADProperties
      } else {
        secondTabTitle = this.labels.HubLabelOktaProperties
      }
    }

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubLabelCommonProperties,
          tabNo: 0,
          active: true,
        },
        {
          title: secondTabTitle,
          tabNo: 1,
          active: false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
        <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
          <div className="rightPanel">
            {id ?
              <Submenu {...props} />
              : ""}

            {id ? (activePage === 0 ? (
              <div className="rightPanel-main-content">
                {this.state.show ? (
                  <ConfirmModal {...confModalData} />
                ) : ""}
                <div className="form-panel">
                  <FormPanelInfo
                    formHeading={this.state.title}
                    iconSrc="/images/ID-provider.svg"
                  />
                  <div className="main-content">
                    {/* <div className="breadcrumbs">{this.labels.HubLabelIdentityproviders}</div> */}
                    {/* <div className="page-title mb-20">
                      <h1>{this.state.title}</h1>
                    </div> */}
                    <div className="main-content">
                      {/* <div className=""> */}
                      <div className="card card-scroller">
                        <div className="p-45">
                          <form>
                            <div className="col-12">
                              <div className="col-6">
                                <Inputbox {...nameFld} />
                              </div>
                              <div className="input-controls col-6">
                                <SelectBox {...IdentityProviderTypeFld} />
                              </div>
                              <div className=" col-6 div-flex">
                                <div className="col-6 pr-2">
                                  <label className="label">{this.labels.HubLabelStatus}</label>
                                  <div className="switch-field ">
                                    <input
                                      type="radio"
                                      id="radio-one"
                                      name="switch-one"
                                      defaultValue="Disabled"
                                      checked={status === 0}
                                      onChange={() => { this.setState({ status: 0 }); }}
                                    />
                                    <label htmlFor="radio-one">
                                      {this.labels.HubLabelDisabled}
                                    </label>
                                    <input
                                      type="radio"
                                      id="radio-two"
                                      name="switch-one"
                                      defaultValue="Enabled"
                                      checked={status === 1}
                                      onChange={() => { this.setState({ status: 1 }); }}
                                    />
                                    <label htmlFor="radio-two">
                                      {this.labels.HubLabelEnabled}
                                    </label>
                                  </div>
                                </div>

                                {this.state.isDefault === true &&
                                  <div className="col-6 pr-2 input-controls">
                                    <label className="check-label">{this.labels.HubLabelIsDefault}</label>
                                    <input name="isDefault" type="checkbox" className="input-check-box-label border"
                                      defaultChecked={true} onChange={this.setChecked} />
                                  </div>
                                }
                                {this.state.isDefault === false &&
                                  <div className="col-6 pr-2 input-controls">
                                    <label className="check-label">{this.labels.HubLabelIsDefault}</label>
                                    <input name="isDefault" type="checkbox" className="input-check-box-label border"
                                      onChange={this.setChecked} />
                                  </div>
                                }

                              </div>{" "}
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="main-button-container">
                  <div className="buttons-group">
                    <div className="col-8  ">
                      <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                      {(this.state.id !== 0 && this.state.isDeleteRight === false) &&
                        <button className="button-tertiary-disable" disabled={true}>{this.labels.HubButtonDelete}</button>
                      }
                      {(this.state.id !== 0 && this.state.isDeleteRight === true) &&
                        <button className="button-tertiary" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                      }
                    </div>
                    {this.state.isUpdateRight === false &&
                      <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                    }
                    {this.state.isUpdateRight === true &&
                      <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                    }
                  </div>
                </div>
              </div>
            )
              :
              (
                <div className="rightPanel-main-content">
                  <div className="form-panel">
                    <FormPanelInfo
                      formHeading={this.state.title}
                      iconSrc="/images/ID-provider.svg"
                    />
                    <div className="main-content">
                      {/* <div className="breadcrumbs">{this.labels.HubLabelIdentityproviders}</div> */}
                      {/* <div className="page-title mb-20">
                        <h1>{this.state.title}</h1>
                      </div> */}
                      <div className="main-content">
                        <div className="card card-scroller">
                          {securityProviderTypeId !== 0 ? (
                            <div className="p-45">
                              <form>
                                <div className="row">
                                  <div className="col-6 pr-2">
                                    <div className="input-controls col-12">
                                      <Inputbox {...clientIdfld} />
                                    </div>
                                    {securityProviderTypeId !== 2 ?
                                      <>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...tenantIdfld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...clientsecretIdfld} />
                                        </div>
                                      </>
                                      :
                                      <div className="input-controls col-12 ">
                                        <Inputbox {...tenantDomainfld} />
                                      </div>
                                    }
                                  </div>
                                  <div className="col-6">
                                    {securityProviderTypeId !== 2 ?
                                      <>
                                        <div className="input-controls col-12">
                                          <Inputbox {...claimtypefld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...tenantDomainfld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...additionalAudiencesfld} />
                                        </div>
                                      </>
                                      :
                                      <div className="input-controls col-12">
                                        <Inputbox {...claimtypefld} />
                                      </div>
                                    }

                                  </div>
                                </div>
                                {this.state.securityProviderTypeId === 1 &&

                                  <div className="row">
                                    <div className="col-6 pr-2 div-flex">
                                      <div className="col-6 pr-2 input-controls">
                                        <Inputbox {...lastSyncFld} />
                                      </div>
                                      <div className="col-6 input-controls">
                                        <Inputbox {...lastSyncStatusFld} />
                                      </div>
                                    </div>
                                    <div className="col-6  div-flex">
                                      <div className="col-6 pr-2 button-center">
                                        <button type="button" className="button-outline mr-2" onClick={() => this.syncaad()}>
                                          {this.labels.HubLabelIsSyncNowLabel}
                                        </button>
                                      </div>
                                      <div className="col-6 button-center">
                                        <button type="button" className="button-outline mr-2" onClick={() => this.showModal()}>
                                          {this.labels.HubLabeShowError}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                }
                              </form>
                            </div>
                          ) : (
                            <div className="p-45">
                              <h2 className="title-secondary m-0">
                                {/* {this.labels.HubLabelNativeIdentityProvider + " "} */}
                              </h2>
                              <form>
                                <div className="row">
                                  <div className="col-6 pr-2">
                                    <div className="input-controls col-12">
                                      <Inputbox {...minpassfld} />
                                    </div>
                                    <div className="input-controls col-12 ">
                                      <Inputbox {...maxpassagefld} />
                                    </div>
                                    <div className="input-controls col-12 pr-2">
                                      <SelectBox {...passcomplexfld} />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-button-container">
                    <div className="buttons-group">
                      <div className="col-4">
                        <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                        {(this.state.id !== 0 && this.state.isDeleteRight === false) &&
                          <button className="button-tertiary-disable" disabled={true}>{this.labels.HubButtonDelete}</button>
                        }
                        {(this.state.id !== 0 && this.state.isDeleteRight === true) &&
                          <button className="button-tertiary" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                        }
                      </div>
                      <div className=" space-between">
                        {this.state.isUpdateRight === false &&
                          <button className="button-primary-disable ml-2" disabled={true} >{this.labels.HubButtonSave}</button>
                        }
                        {this.state.isUpdateRight === true &&
                          <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
              :
              (
                <div className="content">
                  <div className="form-panel">
                    {/* <div className="page-title mb-20">
                      <h1 style={{ textTransform: "uppercase" }}>
                        {(location.state && location.state.name) || this.labels.HubLabelNewProvider}
                      </h1>
                    </div> */}
                    <FormPanelInfo
                      tableHeading={(location.state && location.state.name) || this.labels.HubLabelNewProvider}
                      iconSrc="/images/ID-provider.svg"
                    />
                    {this.state.loading && (
                      <div className="row"> {this.labels.HubLabelLoadingText} </div>
                    )}
                    {this.state.loading || (
                      <div className="main-content">
                        {this.state.page1show ? (
                          <div className={divClassName}>
                            <div className="card vh-3 card-scroller">
                              <div className="p-45">
                                <h6 className="text-desabled m-0">{this.labels.HubLabelStepOne}</h6>
                                <h2 className="title-secondary m-0">
                                  {this.labels.HubLabelCommonProperties}
                                </h2>
                                <form>
                                  <div className="col-6">
                                    <Inputbox {...nameFld} />
                                  </div>
                                  <div className="input-controls col-6">
                                    <SelectBox {...IdentityProviderTypeFld} />
                                  </div>
                                  <div className=" col-6">
                                    <label className="label">{this.labels.HubLabelStatus}</label>
                                    <div className="switch-field ">
                                      <input
                                        type="radio"
                                        id="radio-one"
                                        name="switch-one"
                                        defaultValue="Disabled"
                                        checked={status === 0}
                                        onChange={() => { this.setState({ status: 0 }); }}
                                      />
                                      <label htmlFor="radio-one">
                                        {this.labels.HubLabelDisabled}
                                      </label>
                                      <input
                                        type="radio"
                                        id="radio-two"
                                        name="switch-one"
                                        defaultValue="Enabled"
                                        checked={status === 1}
                                        onChange={() => { this.setState({ status: 1 }); }}
                                      />
                                      <label htmlFor="radio-two">
                                        {this.labels.HubLabelEnabled}
                                      </label>
                                    </div>
                                  </div>{" "}
                                </form>
                              </div>
                            </div>
                            <div className="buttons-group mt-2">
                              <div className="col-8">
                                <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                                {this.state.isDeleteRight === false &&
                                  <button className="button-tertiary-disable" disabled={true}>{this.labels.HubButtonDelete}</button>
                                }
                                {this.state.isDeleteRight === true &&
                                  <button className="button-tertiary" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                                }
                              </div>
                              {securityProviderTypeId === null ?
                                <button className="button-primary-disable" disabled={true}>{this.labels.HubButtonNext}</button>
                                :
                                <button className="button-primary" onClick={() => this.setState({ page1show: !this.state.page1show })}>{this.labels.HubButtonNext}</button>
                              }
                            </div>
                          </div>
                        ) : (
                          <div className={divClassName}>
                            <div className="card vh-3 card-scroller">
                              {securityProviderTypeId !== 0 ? (
                                <div className="p-45">
                                  <h6 className="text-desabled m-0">
                                    {this.labels.HubLabelStepTwo}
                                  </h6>

                                  <h2 className="title-secondary m-0">
                                    {securityProviderTypeId === 1
                                      ? "Azure Active Directory Properties"
                                      : name + " Identity Provider"}
                                  </h2>
                                  <form>
                                    <div className="row">
                                      <div className="col-6 pr-2">
                                        <div className="input-controls col-12">
                                          <Inputbox {...clientIdfld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...tenantIdfld} />
                                        </div>
                                        <div className="input-controls col-12 pr-2">
                                          <Inputbox {...clientsecretIdfld} />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="input-controls col-12">
                                          <Inputbox {...claimtypefld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...tenantDomainfld} />
                                        </div>
                                        <div className="input-controls col-12 pr-2">
                                          <Inputbox {...additionalAudiencesfld} />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                <div className="p-45">
                                  {" "}
                                  <h6 className="text-desabled m-0">
                                    {this.labels.HubLabelStepTwo}
                                  </h6>
                                  <h2 className="title-secondary m-0">
                                    {this.labels.HubLabelNativeIdentityProvider + " "}
                                  </h2>
                                  <form>
                                    <div className="row">
                                      <div className="col-6 pr-2">
                                        <div className="input-controls col-12">
                                          <Inputbox {...minpassfld} />
                                        </div>
                                        <div className="input-controls col-12 ">
                                          <Inputbox {...maxpassagefld} />
                                        </div>
                                        <div className="input-controls col-12 pr-2">
                                          <SelectBox {...passcomplexfld} />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              )}
                            </div>

                            <div className="buttons-group mt-2">
                              <div className="col-6">
                                <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                                {this.state.isDeleteRight === false &&
                                  <button className="button-tertiary-disable" disabled={true}>{this.labels.HubButtonDelete}</button>
                                }
                                {this.state.isDeleteRight === true &&
                                  <button className="button-tertiary" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                                }
                              </div>
                              <div className=" space-between">
                                <button className="button-outline" onClick={() => this.setState({ page1show: !this.state.page1show })}>{this.labels.HubButtonPrevious}</button>
                                {this.state.isUpdateRight === false &&
                                  <button className="button-primary ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                                }
                                {this.state.isUpdateRight === true &&
                                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                                }
                              </div>
                            </div>
                          </div>
                        )}
                        {!id ? (<div className="col-4  ml-2 ">
                          {/* {this.state.page1show ? (<StatusStepOne />) : (<StatusStepTwo />)} */}
                        </div>) : ""}
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(EditIdentityProvider);
//434
