import { ApiHelper } from "../Providers.Api/ApiHelper";
import { IFilter } from "./ODataFilter";

export class ODataQueryV2<TSelect extends ODataSelect>
{
    public select = ODataSelect as (new () => TSelect);
    public nodeId = 1;
    public top = 0;
    public skipToken = "";
    public orderBy = "";
    public filter = "";

    constructor(value: Partial<ODataQueryV2<TSelect>>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let query: string[] = [];
        query.push(this.select == null ? "" : `$select=${new this.select().toSelect()}`);
        query.push(this.filter == "" ? "" : `$filter=${this.filter}`);
        query.push(this.top == 0 ? "" : `$top=${this.top}`);
        query.push(this.skipToken == "" ? "" : `$skipToken=${this.skipToken}`);
        query.push(this.orderBy == "" ? "" : `$orderby=${this.orderBy}`);
        query = query.filter(i => i != "");

        const queryString = query.join("&");
        return queryString;
    }

    public createResponse(response: {}): TSelect
    {
        const instance = new this.select();
        instance.init(response, this.nodeId);
        return instance;
    }
}

/** @deprecated */
export class ODataQuery<TSelect = void>
{
    public select: ((new () => TSelect) | null) = null;
    public nodeId = 1;
    public top = 0;
    public skipToken = "";
    public orderBy = "";
    public filter: (IFilter | null) = null;

    constructor(value: Partial<ODataQuery<TSelect>>)
    {
        Object.assign(this, value);
    }

    public toODataString(filterSeparator: string, customFilter: string): string
    {
        const filterString = "$filter=" + this.toODataFilter(filterSeparator, customFilter);

        let query: string[] = [];
        query.push(this.select == null ? "" : "$select=" + ApiHelper.toODataSelect(this.select));
        query.push(filterString);
        query.push(this.top == 0 ? "" : `$top=${this.top}`);
        query.push(this.skipToken == "" ? "" : `$skipToken=${this.skipToken}`);
        query.push(this.orderBy == "" ? "" : `$orderby=${this.orderBy}`);
        query = query.filter(i => i != "");

        const queryString = query.join("&");
        return queryString;
    }

    public toODataFilter(filterSeparator: string, customFilter: string): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.filter == null ? "" : this.filter.toODataString());
        filterParts.push(customFilter);
        filterParts = filterParts.filter(i => i != "");
        const filterString = filterParts.join(filterSeparator);
        return filterString;
    }

    public fixTypes(primitiveResponse: TSelect): void
    {
        if (this.select == null)
        {
            return;
        }

        const select = new this.select() as any;
        if (select.fixTypes == null)
        {
            return;
        }

        select.fixTypes(primitiveResponse);
    }
}

export class ODataSelect
{
    public init(response: any, nodeId: number): ODataSelect
    {
        Object.assign(this, response);
        return this;
    }

    public toSelect(): string
    {
        return Object.entries(this).map(i => i[0]).join(",");
    }

    public toExpand(): string
    {
        return "";
    }
}
