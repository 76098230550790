import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { enableRipple } from "@syncfusion/ej2-base";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import SelectBox from "../../../../Components/Inputs/SelectBox/SelectBox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import { convertApiResponseObjectToLower, convertObjectVariableFirstLatterToUpperCase } from "../../../../Common/Helper"
import { appContext } from "../../../../AppContext";

enableRipple(true);

class EditTaskCategory extends Component {

  private labels = appContext().labels;
  private local = appContext().localStorageProvider;
  
  state = {
    isLoadingToSave: false,
    loading: false,
    activePage: 0,
    filter: "",
    task_Category_Id: 0,
    updates: false,
    displayName: null,
    node_Id: null,
    task_Category_Name: null,
    task_Category_IsEnabled: 0,
    imageURI: null,
    space_Type: null,
    ibssspacetypes: [],
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
    apiVersion: 11
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    let versions = 11;
    const version = this.local.getVersion();
    if (version !== 0) {
      versions = version;
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter'),
      apiVersion: versions
    })
    this.getibssspacetypesDetails();
    if (match.params.id === "0") {
      this.setState({
        node_Id: match.params.nodeid,
        filter: match.params.filter,
        displayName: "New Task Categories",
        task_Category_Id: 0,
        isUpdateRight: true,
      });
    } else {
      this.getRightList();
      this.setState({
        node_Id: match.params.nodeid,
        task_Category_Id: match.params.id,
        filter: match.params.filter
      });
      match.params.id && this.getDetails(match.params.nodeid, match.params.id);
    }

  }

  getRightList = () => {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.TaskCategories && res.ADMINPORTAL.TaskCategories.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.TaskCategories && res.ADMINPORTAL.TaskCategories.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getibssspacetypesDetails = () => {
    apis.getibssspacetypesList().then((res) => {
      var arraydata = []
      for (let j = 0; j < res.data.length; j++) {
        var newJson = { title: res.data[j], value: res.data[j] }
        arraydata.push(newJson)
      }
      this.setState({
        ibssspacetypes: arraydata
      });

    }).catch((error: any) => { });
  }

  getDetails = (nodeid: any, id: any) => {
    this.setState({ loading: true });

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }
    apis.getTaskCategoriesById(nodeid, id).then((res) => {
      const lowerCaseData = convertApiResponseObjectToLower({ ...res.data[0] })

      this.setState({ ...lowerCaseData });
      this.setState({
        loading: false,
        displayName: lowerCaseData.task_Category_Name
      });
    }).catch((error: any) => { });
  };

  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match, history }: any = this.props;
    const { filter, updates, imageURI, node_Id, space_Type, task_Category_Id, task_Category_IsEnabled, task_Category_Name }: any = this.state;

    if (match.params.id === "0") {
      let payload = {
        "imageURI": imageURI,
        "node_Id": node_Id,
        "space_Type": space_Type,
        "task_Category_IsEnabled": task_Category_IsEnabled,
        "task_Category_Name": task_Category_Name
      }

      if (this.state.apiVersion > 10) {
        // use upperCase
        payload = convertObjectVariableFirstLatterToUpperCase(payload)
      } else if (this.state.apiVersion === 10) {
        // use lowerCase
      }

      this.create(node_Id, payload);
    } else {
      let payload = {
        "imageURI": imageURI,
        "node_Id": node_Id,
        "space_Type": space_Type,
        "task_Category_Id": task_Category_Id,
        "task_Category_IsEnabled": task_Category_IsEnabled,
        "task_Category_Name": task_Category_Name
      }

      if (this.state.apiVersion > 10) {
        // use upperCase
        payload = convertObjectVariableFirstLatterToUpperCase(payload)
      } else if (this.state.apiVersion === 10) {
        // use lowerCase
      }

      this.update(node_Id, task_Category_Id, payload);
    }
  };

  create = (nodeId: any, payload: any) => {
    const { history }: any = this.props;
    const { filter }: any = this.state;
    apis.createTaskCategories(nodeId, payload).then((res) => {
      this.setState({
        updates: true,
        isLoadingToSave: false,
      }, () => {
        history.push("/portfolio-setup-spaces/filter=" + filter + "/nodeid=" + nodeId + "/id=" + res.data.task_Category_Id ? res.data.task_Category_Id : res.data.Task_Category_Id);
        window.location.reload();
      })
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false });
    });
  }

  update = (nodeId: any, id: any, payload: any) => {
    const { match, history }: any = this.props;
    const { updates }: any = this.state;
    if (updates) {
      apis.updateTaskCategoriesSpacesById(nodeId, id, payload).then(() => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/Building/8");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      this.setState({ isLoadingToSave: false });
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/Building/8");
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };
  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }

  delete = () => {
    const { match }: any = this.props;
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, node_Id } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + node_Id + "/Building/8");
    }
  }
  setChecked = (e: any) => {
    this.setState({ updates: true })
    if (e.target.name === 'task_Category_IsEnabled' && this.state.task_Category_IsEnabled == 0) {
      this.setState({
        task_Category_IsEnabled: 1
      })
    }
    if (e.target.name === 'task_Category_IsEnabled' && this.state.task_Category_IsEnabled === 1) {
      this.setState({
        task_Category_IsEnabled: 0
      })
    }
  };

  //   redirectRoles = (id:any) => {
  //     const { history, match }: any = this.props;
  //     const userId = match.params.id;
  //     history.push("/security-roles/rolesdetails/"+id, `roles_${userId}`);
  //   }

  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }


  linkPath = (data: any) => {
    const { filter } = this.state
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  render() {
    const { task_Category_Name, task_Category_IsEnabled, space_Type }: any = this.state;
    const { history }: any = this.props;

    const taskCategoryNameFld = {
      name: "task_Category_Name",
      label: this.labels.HubLabelName,
      value: this.state.task_Category_Name,
      mandatory: true,
      placeholder: this.labels.HubLabelTaskCategoryNamePlaceholder,
      onchange: this.change,
    };

    const imageURIFld = {
      name: "imageURI",
      label: this.labels.HubLabelImageURI,
      value: this.state.imageURI,
      mandatory: true,
      placeholder: this.labels.HubLabelImageURIPlaceholder,
      onchange: this.change,
    };

    const spaceTypeFld = {
      name: "space_Type",
      label: this.labels.HubLabelSpaceType,
      value: this.state.space_Type,
      mandatory: true,
      onchange: (value: any) => {
        this.setState({
          space_Type: value,
          updates: true
        });
      },
      type: "option",
      options: this.state.ibssspacetypes,
      placeholder: "Provide value",
    };


    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        }
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.task_Category_Name,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.task_Category_Name,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.node_Id + "/Building/8") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              <div className="form-panel">
                <div className="form-panel-heading">
                  <div className="form-panel-breadcrumbs">
                    <div className="breadcrumbs">
                      {this.state.task_Category_Id === 0 ?
                        <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.node_Id + `/Building` }}>{this.labels.HubLabelTaskCateory}</Link>
                        :
                        <div>
                          <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                          {this.state.showOrganisation && this.state.organisationState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                          {this.state.showRegion && this.state.regionState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                          {this.state.showBuilding && this.state.buildingState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                          {this.state.showFloor && this.state.floorState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                          {this.state.showZone && this.state.zoneState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                          {this.state.showRoom && this.state.roomState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                          {this.state.showDesk && this.state.deskState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                        </div>
                      }

                    </div>
                    <div className="page-title mb-20"><h1>{this.state.displayName}</h1></div>
                  </div>
                </div>
                {this.state.activePage === 0 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    {/* <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/security-users` }}>{this.labels.HubLabelTaskCateory}</Link></div> */}

                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller">
                          <div className="rightPanel-main-content">
                            <form>
                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...taskCategoryNameFld} /> </div>
                                <div className="col-6 input-controls">
                                  <label className="check-label">{this.labels.HubLabelEnabled}</label>
                                  <input name="task_Category_IsEnabled" type="checkbox" className="input-check-box-label border"
                                    defaultChecked={task_Category_IsEnabled === 1 ? true : false} onChange={this.setChecked} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <Inputbox {...imageURIFld} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <SelectBox {...spaceTypeFld} />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                {(this.state.task_Category_Id !== 0 && this.state.isDeleteRight === false) && (
                  <button className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</button>
                )}
                {(this.state.task_Category_Id !== 0 && this.state.isDeleteRight === true) && (
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
              </div>
              <div>
                {this.state.isUpdateRight === false &&
                  <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                }
                {this.state.isUpdateRight === true &&
                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                }
              </div>
            </div>
          </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(EditTaskCategory);