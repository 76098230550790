import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import IbssAutocomplete from "../../Inputs/AutoComplete/IbssAutocomplete";
import { appContext } from "../../../AppContext";
import IbssTextField from "../../Inputs/TextField/IbssTextField";
import { IUser as IUserData } from "../../../Providers.Api/Users/UsersRepository";
import Guid from "../../../Common/Guid";

export default class UserPicker extends IbssComponent<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }
    private get labels() { return appContext().labels; }
    private searchUsersTimer: NodeJS.Timeout | null = null;
    private get noUserSelectedError() { return (!this.props.searchText || this.state.selectedUser ? "" : this.labels.funcNoUserSelected_S); }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            users: [],
            selectedUser: null,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.searchTextUpdated();
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any): Promise<void>
    {
        if (this.props.searchText != prevProps.searchText)
        {
            await this.searchTextUpdated();
        }
    }

    private async searchTextUpdated(): Promise<void>
    {
        clearTimeout(this.searchUsersTimer ?? undefined);
        const { searchText } = this.props;

        if (searchText.length < 3)
        {
            await this.setStateAsync({ users: [], selectedUser: null });
            await this.props.onUserChange?.(null);
        }
        else
        {
            this.searchUsersTimer = setTimeout(() => this.searchUsers(), 500);
        }
    }

    private async searchUsers(): Promise<void>
    {
        const { searchText } = this.props;
        const userData = await this.apiClient.users.getUsers(searchText);
        const users = userData.map(i => this.toUser(i));

        // important: we must only select a user if the email (and nothing else) matches the seartch text because this is the only field that uniquely identifies the user
        const selectedUser = users.find(i => i.email.toLowerCase() == searchText.toLowerCase()) ?? null;

        await this.setStateAsync({ users: users, selectedUser: selectedUser });
        await this.props.onUserChange?.(selectedUser);
    }

    private toUser(userData: IUserData): IUser
    {
        return {
            label: userData.displayName + ` (${userData.email})`,
            email: userData.email,
            userName: userData.UserName,
            firstName: userData.firstName,
            lastName: userData.lastName,
            displayName: userData.displayName,
            company: userData.companyName,
        };
    }

    private async userChanged(e: React.SyntheticEvent<Element, Event>, user: IUser | null): Promise<void>
    {
        await this.props.onChange(user?.email ?? "");
    }

    private async searchTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        const text = e.target.value;
        if (text.length == 0)
        {
            return; // handled by userChanged
        }
        await this.props.onChange(text);
    }

    public render(): JSX.Element
    {
        const { width } = this.props;
        return (
            <div style={{ ...(width != null && { width: width }) }}>
                <IbssAutocomplete
                    disabled={this.props.disabled ?? false}
                    fullWidth={!this.props.alternateStyle}
                    freeSolo
                    options={this.state.users}
                    value={this.props.searchText}
                    className={"mt-8" + (this.props.alternateStyle ? " form-input-box-autocomplete auto-text" : "")}
                    onChange={(e, user: IUser | null) => this.userChanged(e, user)}
                    renderInput={params =>
                        <IbssTextField
                            {...params}
                            label={this.props.label ?? this.labels.HubLabelNameOrEmailLabel}
                            placeholder={this.props.placeholder ?? this.labels.HubLabelNameOrEmailLabel}
                            variant="outlined"
                            value={this.props.searchText}
                            className={this.props.alternateStyle ? "input-box model-textbox select-box-border" : ""}
                            onChange={e => this.searchTextChanged(e)}
                        />}
                />
                <div className="text-danger">{this.noUserSelectedError}</div>
            </div>
        );
    }
}

export interface IProps
{
    disabled?: boolean;
    width?: string;
    searchText: string;
    label?: string;
    placeholder?: string;
    alternateStyle?: boolean;
    onChange: (searchText: string) => Promise<void>;
    onUserChange?: (user: IUser | null) => Promise<void>;
}

export interface IState
{
    users: IUser[];
    selectedUser: IUser | null;
}

export interface IUser
{
    label: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    displayName: string;
    company: string;
}
