import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CateringItemsFilter } from "./CateringItemRepository";

export class GetManyAsBlobEndpoint implements IGetManyAsBlobEndpoint
{
    public async execute(buildingId: number,filter: CateringItemsFilter): Promise<Blob>
    {
        try
        {
            const query = `$filter=${filter.toODataString()}`;
            const response = await axios.get<Blob>(apis.allApiv2 + buildingId + `/catering-menuitem/download?${query}`,{ responseType: 'blob' })
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyAsBlobEndpoint
{
    execute(buildingId: number,filter: CateringItemsFilter): Promise<Blob>;
}