import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import './SetupTeardownTimeSummary.scss'
import { Box } from '@mui/material';

class SetupTeardownTimeSummary extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <Box className="container">
                <div className="setupResetTimeCard">
                    <div>
                        <div className="TimeCardHeader">
                            {this.labels.dataBookingCreateSetup_S}
                        </div>
                        <div className="TimeCardText">
                            {this.props.setup ? this.props.setup.toLocaleString(DateTime.TIME_24_SIMPLE) : '-'}
                        </div>
                    </div>
                </div>
                <div className="bookingTimeCard" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div>
                        <div className="TimeCardHeader">
                            {this.labels.HubLabelStart}
                        </div>
                        <div className="TimeCardText">
                            {this.props.start.toLocaleString(DateTime.TIME_24_SIMPLE)}
                        </div>
                    </div>
                    <div>
                        <div className="TimeCardHeader">
                            {this.labels.HubLabelEnd}
                        </div>
                        <div className="TimeCardText">
                        {this.props.end.toLocaleString(DateTime.TIME_24_SIMPLE)}
                        </div>
                    </div>
                </div>
                <div className="setupResetTimeCard">
                    <div>
                        <div className="TimeCardHeader">
                            {this.labels.dataBookingCreateReset_S}
                        </div>
                        <div className="TimeCardText">
                        {this.props.teardown ? this.props.teardown.toLocaleString(DateTime.TIME_24_SIMPLE) : '-'}
                        </div>
                    </div>
                </div>
            </Box>
        )
    }
}

export default SetupTeardownTimeSummary;

export interface IProps
{
    setup: DateTime | null;
    start: DateTime;
    end: DateTime;
    teardown: DateTime | null;
}

export interface IState
{
}