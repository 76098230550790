import axios from "axios";
import { IbssRequestConfig } from "../ApiClient";
import apis from "../apis";

export class GetActiveNotificationEndpoint implements IGetActiveNotificationEndpoint
{
    public async execute(nodeId: number): Promise<INotification[]>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: true };
            const response = await axios.get<INotification[]>(`${apis.allApi}${nodeId}/Notifications/active?%24top=50&%24select=Notification_Id,ImageURI,ActionURI,Notification_Title,Notification_Priority,Classification,Subclassification,Notification_Message,Available_From,Notification_Record_Id,Notification_Short_Message,Node_Id,Notification_Status,Notification_User_Email`, axiosConfig);
            return response.data;
        }
        catch (error)
        {
            throw console.log(error, 'error');
        }
    }
}

export interface IGetActiveNotificationEndpoint
{
    execute(nodeId: number): Promise<INotification[]>;
}

export interface INotification
{
    Notification_Id: number;
    ImageURI: string;
    ActionURI: string;
    Notification_Title: string;
    Notification_Priority: number;
    Classification: string;
    Subclassification: string;
    Notification_Message: string;
    Available_From: string;
    Expiry_Date: string;
    Node_Id: number;
    Notification_Record_Id: string;
    Notification_Short_Message: string;
    Notification_Status: string;
    Notification_User_Email: string
}
