import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import { TagToEventTypeView } from './ViewBookingsManagedSchedule';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IconHousing from './IconHousing';
import { Box } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';

class BookingTagsDialog extends Component<IProps, IState>
{
    private get labels() { return appContext().labels } 
    constructor(props: IProps)
    {

        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="booking tags modal"
                fullWidth
                maxWidth={'sm'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.funcCurrentTags_S}</label>
                    </>
                }
                dialogContent=
                {
                    <Box display='flex' alignItems='center' gap='0.5em'>
                        {
                            this.props.tags.map(tag => <Box key={tag.tagToEventTypeId} paddingTop='0.5em'><IconHousing tagToEventTypeId={tag.tagToEventTypeId} name={tag.name} tagType={tag.tagType} value={tag.value} location={tag.icon.split(';')[0]} /></Box>)
                        }
                    </Box>
                }
                footer=
                {
                    <div className="d-flex justify-content-center">
                        <IbssButton fullWidth variant="contained" onClick={this.props.onClose}>{this.labels.HubLabelOk}</IbssButton>
                    </div>
                }
            />
        )
    }
}

export default BookingTagsDialog;

export interface IProps
{
    show: boolean;
    onClose: () => void;
    tags: TagToEventTypeView[],
}

export interface IState
{
}