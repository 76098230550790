import { Component } from 'react';

export default class LockIcon extends Component
{
    render()
    {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 6.375H12.75V4.875C12.75 2.805 11.07 1.125 9 1.125C6.93 1.125 5.25 2.805 5.25 4.875V6.375H4.5C3.675 6.375 3 7.05 3 7.875V15.375C3 16.2 3.675 16.875 4.5 16.875H13.5C14.325 16.875 15 16.2 15 15.375V7.875C15 7.05 14.325 6.375 13.5 6.375ZM6.75 4.875C6.75 3.63 7.755 2.625 9 2.625C10.245 2.625 11.25 3.63 11.25 4.875V6.375H6.75V4.875ZM13.5 15.375H4.5V7.875H13.5V15.375ZM9 13.125C9.825 13.125 10.5 12.45 10.5 11.625C10.5 10.8 9.825 10.125 9 10.125C8.175 10.125 7.5 10.8 7.5 11.625C7.5 12.45 8.175 13.125 9 13.125Z" fill="black" />
            </svg>
        )
    }
};
