import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { IUpdateV2BookingRequest } from "./UpdateV2BookingEndpoint";

export class UpdateV1BookingEndpoint implements IUpdateV1BookingEndpoint
{
    public async execute(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>
    {
        try
        {
            const response = await axios.put<IUpdateBookingResponse>(apis.allApi + nodeId + `/Bookings/` + bookingId, booking);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateV1BookingEndpoint
{
    execute(nodeId: number, bookingId: string, booking: IUpdateV1BookingRequest): Promise<IUpdateBookingResponse>;
}

export interface IUpdateV1BookingRequest extends Omit<IUpdateV2BookingRequest, "Booking_Parties" | "Booking_Parties_Visitors" | "Cost_Code_Allocation">
{
    Booking_Parties?: string;
    Booking_Parties_Visitors?: string;
    Cost_Code_Allocation?: string;
}

export interface IUpdateBookingResponse
{
    Message: string;
}
