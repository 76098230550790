import { Component } from 'react';

export default class Restaurant extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.25 17C32.25 8.57766 25.4223 1.75 17 1.75V0.25C26.2508 0.25 33.75 7.74923 33.75 17C33.75 23.6239 29.905 29.3483 24.3285 32.0657L23.6715 30.7173C28.7519 28.2416 32.25 23.0286 32.25 17ZM9.37554 3.78972C4.81535 6.42765 1.75 11.3563 1.75 17C1.75 23.3988 5.69123 28.8789 11.2814 31.1418C11.3841 31.1833 11.4873 31.2238 11.591 31.2631L11.0589 32.6656C10.9448 32.6223 10.8314 32.5778 10.7186 32.5322C4.58145 30.0479 0.25 24.0307 0.25 17C0.25 10.7992 3.61983 5.38633 8.62446 2.49131L9.37554 3.78972ZM17 32.25C18.1469 32.25 19.2634 32.1235 20.3367 31.884L20.6633 33.348C19.4836 33.6113 18.2576 33.75 17 33.75C16.1105 33.75 15.2366 33.6806 14.3837 33.5468C14.2231 33.5216 14.0632 33.4941 13.9041 33.4643L14.1797 31.9899C14.3245 32.0169 14.47 32.042 14.6163 32.0649C15.3925 32.1867 16.1886 32.25 17 32.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.25C9.41421 0.25 9.75 0.585786 9.75 1V8.50515C9.75 9.24405 10.0636 9.94823 10.6128 10.4425C11.4781 11.2213 11.9722 12.3307 11.9722 13.4948V31.2222C11.9722 31.7898 12.4324 32.25 13 32.25C13.5676 32.25 14.0278 31.7898 14.0278 31.2222V13.4948C14.0278 12.3307 14.5219 11.2213 15.3872 10.4425C15.9364 9.94823 16.25 9.24405 16.25 8.50515V1C16.25 0.585786 16.5858 0.25 17 0.25C17.4142 0.25 17.75 0.585786 17.75 1V8.50515C17.75 9.66928 17.2559 10.7787 16.3906 11.5575C15.8414 12.0518 15.5278 12.7559 15.5278 13.4948V31.2222C15.5278 32.6183 14.3961 33.75 13 33.75C11.6039 33.75 10.4722 32.6183 10.4722 31.2222V13.4948C10.4722 12.7559 10.1586 12.0518 9.60939 11.5575C8.7441 10.7787 8.25 9.66928 8.25 8.50515V1C8.25 0.585786 8.58579 0.25 9 0.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.999 0.28338C24.3134 0.380432 24.5278 0.671015 24.5278 1V31.2222C24.5278 32.6183 23.3961 33.75 22 33.75C20.604 33.75 19.4722 32.6183 19.4722 31.2222V22.0485C19.4722 20.4867 19.1136 18.9458 18.4241 17.5445L17.9489 16.5788C17.8111 16.2988 17.7493 15.9873 17.7687 15.6769C18.0922 10.5016 19.8071 5.48714 22.731 1.2033L23.1583 0.577195C23.3438 0.305467 23.6847 0.186328 23.999 0.28338ZM23.0278 3.5299C20.8297 7.24486 19.5359 11.4485 19.2658 15.7705C19.2626 15.8212 19.2729 15.872 19.2948 15.9166L19.77 16.8822C20.5609 18.4896 20.9722 20.2571 20.9722 22.0485V31.2222C20.9722 31.7899 21.4324 32.25 22 32.25C22.5676 32.25 23.0278 31.7899 23.0278 31.2222V3.5299Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.25C12.4142 0.25 12.75 0.585786 12.75 1V8C12.75 8.41421 12.4142 8.75 12 8.75C11.5858 8.75 11.25 8.41421 11.25 8V1C11.25 0.585786 11.5858 0.25 12 0.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0.25C14.4142 0.25 14.75 0.585786 14.75 1V8C14.75 8.41421 14.4142 8.75 14 8.75C13.5858 8.75 13.25 8.41421 13.25 8V1C13.25 0.585786 13.5858 0.25 14 0.25Z" />
            </svg>
        )
    }
};