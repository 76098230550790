import { IUserPreferences } from "../../../../Providers.Api/UserPreferences/UserPreferenceRepository";

export class PageHelper
{
    public static getUserPrefrenceJson(): IUserPreferences
    {
        let userPref: IUserPreferences = {
            NotificationPrefs: {
                Visitors: 0,
                Tasks: 0,
                Bookings: 0,
                BuildingsPrefs: []
            },
            PrivacyPrefs: {
                ShareLocation: 0
            },
            CalendarPrefs: {
                SyncCalendar: 0
            },
            EnvironmentPrefs: {
                Temperature: 0,
                SoundLevel: 1
            },
            WorkingHoursPrefs: {
                UserStartTime: "",
                UserEndTime: ""
            },
            SearchPrefs: {
                DefaultSearchResults: "Map",
                DefaultBuilding: null
            },
            DiagnosticsPrefs: {
                Logging: 0
            },
            FavouriteColleagues: [],
            Nodes: [
                {
                    NodeId: 0,
                    DefaultFloor: 0,
                    FavouriteSpaces: [],
                    FavouriteAmenities: [],
                    FavouriteEquipment: [],
                    DefaultZone: null
                }
            ],
            SecurityPrefs: {
                Pin: '',
            },
            DefaultBookingCostCodes: [],
            DefaultCateringCostCodes: []
        };
        return userPref;
    }

}