import settings from '../AppSettings.json';

export class SettingsProvider implements ISettingsProvider
{

    public getSettings(): IAppSettings
    {
        return settings;
    }
}

export interface ISettingsProvider
{
    getSettings(): IAppSettings
}

export interface IAppSettings
{
    externalVersion: string;
    internalVersion : string;
}
