import { Component } from 'react';

export default class Indoorgarden extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2747 20.7512C20.2747 20.3369 20.6105 20.0012 21.0247 20.0012H21.0784C21.4926 20.0012 21.8284 20.3369 21.8284 20.7512V24.2516L23.1902 22.8898C23.4831 22.5969 23.958 22.5969 24.2509 22.8898L24.2885 22.9274C24.4291 23.0681 24.5082 23.2589 24.5082 23.4578C24.5082 23.6567 24.4291 23.8474 24.2885 23.9881L21.8284 26.4482V33C21.8284 33.4142 21.4926 33.75 21.0784 33.75C20.6642 33.75 20.3284 33.4142 20.3284 33V26.5019L18.8897 25.0632C18.7491 24.9226 18.6701 24.7318 18.6701 24.5329C18.6701 24.334 18.7491 24.1432 18.8897 24.0026L18.9274 23.9649C19.2203 23.672 19.6951 23.672 19.988 23.9649L20.2747 24.2516V20.7512Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.584 15.5162C18.6126 14.9498 19.7949 14.6256 21.0516 14.6256C25.0285 14.6256 28.2523 17.8494 28.2523 21.8263C28.2523 25.8032 25.0285 29.027 21.0516 29.027C18.8643 29.027 16.9097 28.0479 15.5909 26.5127C15.4531 26.3524 15.3895 26.1413 15.4155 25.9316C15.4415 25.7218 15.5549 25.5328 15.7276 25.411C17.1943 24.3769 18.1513 22.6763 18.1513 20.7512C18.1513 19.6284 17.8283 18.041 17.2412 16.43C17.1145 16.0826 17.2601 15.6946 17.584 15.5162ZM18.8721 16.5585C19.3624 18.0623 19.6513 19.5649 19.6513 20.7512C19.6513 22.8721 18.7315 24.775 17.2751 26.0908C18.2816 26.9846 19.6024 27.527 21.0516 27.527C24.2 27.527 26.7523 24.9748 26.7523 21.8263C26.7523 18.6779 24.2 16.1256 21.0516 16.1256C20.2789 16.1256 19.5433 16.28 18.8721 16.5585Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42455 10.6339C10.2736 9.83118 11.2974 9.25 12.4507 9.25C13.604 9.25 14.6278 9.83118 15.4769 10.6339C16.3293 11.4398 17.0702 12.5271 17.6773 13.6933C18.8847 16.0126 19.6514 18.8194 19.6514 20.7512C19.6514 24.7281 16.4276 27.9519 12.4507 27.9519C8.47382 27.9519 5.25 24.7281 5.25 20.7512C5.25 18.8194 6.01675 16.0126 7.22409 13.6933C7.83118 12.5271 8.57206 11.4398 9.42455 10.6339ZM8.55461 14.3859C7.42727 16.5515 6.75 19.1203 6.75 20.7512C6.75 23.8996 9.30225 26.4519 12.4507 26.4519C15.5992 26.4519 18.1514 23.8996 18.1514 20.7512C18.1514 19.1203 17.4741 16.5515 16.3468 14.3859C15.7865 13.3096 15.1374 12.3771 14.4464 11.7239C13.752 11.0674 13.0788 10.75 12.4507 10.75C11.8226 10.75 11.1495 11.0674 10.455 11.7239C9.76399 12.3771 9.11487 13.3096 8.55461 14.3859Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6738 18.601C11.6738 18.1867 12.0096 17.851 12.4238 17.851H12.4776C12.8918 17.851 13.2276 18.1867 13.2276 18.601V21.5638L14.0518 20.7396C14.1925 20.5989 14.3832 20.5199 14.5821 20.5199C14.7811 20.5199 14.9718 20.5989 15.1125 20.7396L15.1501 20.7772C15.443 21.0701 15.443 21.545 15.1501 21.8379L13.2258 23.7622L13.1738 32.5045C13.1714 32.9187 12.8336 33.2524 12.4194 33.25C12.0052 33.2475 11.6714 32.9098 11.6739 32.4955L11.7127 25.9495L9.21378 23.4506C8.92089 23.1577 8.92089 22.6828 9.21378 22.3899L9.25141 22.3523C9.39206 22.2116 9.58283 22.1326 9.78174 22.1326C9.98065 22.1326 10.1714 22.2116 10.3121 22.3523L11.6738 23.714V22.1477L10.2889 20.7628C9.996 20.4699 9.996 19.995 10.2889 19.7021L10.3265 19.6645C10.4672 19.5238 10.6579 19.4448 10.8569 19.4448C11.0558 19.4448 11.2465 19.5238 11.3872 19.6645L11.6738 19.9511V18.601Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6481 0.337663C16.8738 0.217778 17.145 0.22104 17.3677 0.346318L33.3677 9.34632C33.6039 9.47916 33.75 9.72904 33.75 10V33C33.75 33.4142 33.4142 33.75 33 33.75H1C0.585786 33.75 0.25 33.4142 0.25 33V9.5C0.25 9.22258 0.403139 8.96782 0.648134 8.83766L16.6481 0.337663ZM1.75 9.95083V32.25H32.25V10.4386L16.9897 1.85473L1.75 9.95083Z" />
            </svg>
        )
    }
};