import { Component } from 'react';
import { styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

class IbssIconButton extends Component<IconButtonProps> {
  public render(): JSX.Element
  {
    return (
      <StyledIconButton {...this.props}>
        {this.props.children}
      </StyledIconButton>
    )
  }
};

const StyledIconButton = styled(IconButton)<IconButtonProps>(({}) => 
({
    
}));

export default IbssIconButton
