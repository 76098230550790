import { DateTime } from "luxon";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";
import { DateHelper } from "../../../../Common/DateHelper";

export class VisitsDailySummary extends ODataSelect
{
    public Node_Id = 0;
    public Summary_Date = DateHelper.null();
    public Visits_Approved_Count = 0;
    public Visits_Cancelled_Count = 0;
    public Visits_Completed_Count = 0;
    public Visits_Created_Count = 0;
    public Visits_Early_Checkin_Count = 0;
    public Visits_Late_Checkin_Count = 0;
    public Visits_Avg_Actual_Duration = 0;
    public Visits_Bookings_Count = 0;
    public Visits_No_Bookings_Count = 0;

    public init(response: any, nodeId: number): VisitsDailySummary
    {
        super.init(response, nodeId);
        this.Summary_Date = DateHelper.fromIsoByNode(response.Summary_Date, nodeId);
        return this;
    }
}
