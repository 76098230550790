export enum MenuItemKey
{
    NotSet,

    Admin,
    Admin_Setup,
    Admin_Setup_Security,
    Admin_Setup_Security_IdentityProviders,
    Admin_Setup_Security_Roles,
    Admin_Setup_Security_DelegatePermissions,
    Admin_Setup_Organisation,
    Admin_Setup_Organisation_Buildings,
    Admin_Setup_Organisation_Regions,
    Admin_Setup_Organisation_Notifications,
    Admin_Manage,
    Admin_Manage_Users,
    Admin_Manage_CostCodes,
    Admin_Manage_BuildingSelector,
    Admin_Manage_BookingPolicies,
    Admin_Manage_SystemFilters,
    Admin_Manage_Spaces, // todo: broken
    Admin_Manage_FileManagement,
    Admin_Manage_FileManagement_Roamer,
    Admin_Manage_FileManagement_Unity,
    Admin_Manage_FileManagement_Inspire,
    Admin_Manage_FileManagement_Common,
    Admin_Manage_Tags,

    Flex,
    Flex_Home,
    Flex_FindASpace,
    Flex_FindASpace_Single,
    Flex_FindASpace_Recurring,
    Flex_FindAColleague,
    Flex_Schedule,
    Flex_MySchedule,
    Flex_MySchedule_MyBookings,
    Flex_MySchedule_MyVisitors,
    Flex_MySchedule_MyBookingsForOthers,
    Flex_MyNotification,
    Flex_MyTasks,
    Flex_MyTask_TasksRaised,
    Flex_Settings,
    Flex_Settings_Notifications,
    Flex_Settings_Workplace,
    Flex_Settings_Account,
    Flex_Settings_CostCodes,
    Flex_Settings_DelegatePermissions,
    Flex_Settings_DelegateAccess,

    OneLens,
    OneLens_BuildingSelector,
    OneLens_Home,
    OneLens_Operations,
    OneLens_Operations_Bookings,
    OneLens_Operations_Bookings_Dashboard,
    OneLens_Operations_Bookings_List,
    OneLens_Operations_Bookings_Trends,
    OneLens_Operations_Bookings_ScheduleView,
    OneLens_Operations_Visitors,
    OneLens_Operations_Visitors_Dashboard,
    OneLens_Operations_Visitors_List,
    OneLens_Operations_Visitors_Trends,
    OneLens_Operations_Catering,
    OneLens_Operations_Catering_CateringOrders,
    Onelens_Operations_Catering_CateringItems,
    Onelens_Operations_Catering_CateringMenus,
    Onelens_Operations_Catering_OrderPolicy,
    Onelens_Operations_Catering_DietaryTerms,
    Onelens_Operations_Catering_Suppliers,
    OneLens_Operations_Catering_PantryTimeline,
    OneLens_Operations_CostCodes,
    OneLens_Operations_Tasks,
    OneLens_Operations_Announcements,
    OneLens_Operations_Notifications,
    OneLens_Operations_Spaces,
    OneLens_Operations_DirectedCleaning,
    OneLens_Operations_DeletedUsers,
    OneLens_Analytics,
    OneLens_Analytics_Visitors,
    OneLens_Analytics_Sustainability,
    OneLens_Analytics_Sustainability_Overview,
    OneLens_Analytics_Sustainability_Floors,
    OneLens_Analytics_Sustainability_Zones,
    OneLens_Analytics_Sustainability_Sensors,
    OneLens_Analytics_Space,
    OneLens_Analytics_Space_Overview,
    OneLens_Analytics_Space_Heatmaps,
}