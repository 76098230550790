import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { SpacesFilter } from "./SpaceRepository";
import { ODataQueryV2, ODataSelect } from "../ODataQuery";
import { IRawPagedResponse, PagedResponse } from "../Models";

export class GetV2SpacesEndpoint implements IGetV2SpacesEndpoint
{
    public async execute<TSelect extends ODataSelect>(query: ODataQueryV2<TSelect>): Promise<PagedResponse<TSelect[]>>
    {
        try
        {
            const response = await axios.get<IRawPagedResponse<TSelect[]>>(`${apis.allApiv2}${query.nodeId}/spaces/search?${query.toODataString()}`);
            response.data.Value = response.data.Value.map(i => query.createResponse(i));
            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2SpacesEndpoint
{
    execute<TSelect extends ODataSelect>(query: ODataQueryV2<TSelect>): Promise<PagedResponse<TSelect[]>>;
}

export class Space extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Type = "";
    public Space_Work_Type = "";
    public Space_Type_Label = "";
    public Space_Setup = 0;
    public ImageURI = "";
    public Meta_Loc_Zone = "";
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
}

export class NodeIdSpaceId extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Type = "";
    public Space_Work_Type = "";
}
