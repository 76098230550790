import { Component } from 'react';

export default class Trainingroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 6.75C24.7574 6.75 23.75 7.75736 23.75 9C23.75 10.2426 24.7574 11.25 26 11.25C27.2426 11.25 28.25 10.2426 28.25 9C28.25 7.75736 27.2426 6.75 26 6.75ZM22.25 9C22.25 6.92893 23.9289 5.25 26 5.25C28.0711 5.25 29.75 6.92893 29.75 9C29.75 11.0711 28.0711 12.75 26 12.75C23.9289 12.75 22.25 11.0711 22.25 9Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3856 11.0698C14.6231 10.7305 15.0908 10.648 15.4301 10.8855L19.9136 14.024C20.1237 14.1711 20.374 14.2499 20.6304 14.2499H30C31.5188 14.2499 32.75 15.4812 32.75 16.9999V22.9999C32.75 23.4142 32.4142 23.7499 32 23.7499C31.5858 23.7499 31.25 23.4142 31.25 22.9999V16.9999C31.25 16.3096 30.6904 15.7499 30 15.7499H20.6304C20.0662 15.7499 19.5157 15.5764 19.0534 15.2528L14.5699 12.1144C14.2306 11.8768 14.148 11.4092 14.3856 11.0698Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23 18.25C23.4142 18.25 23.75 18.5858 23.75 19V32.25H28.25V19C28.25 18.5858 28.5858 18.25 29 18.25C29.4142 18.25 29.75 18.5858 29.75 19V33C29.75 33.4142 29.4142 33.75 29 33.75H23C22.5858 33.75 22.25 33.4142 22.25 33V19C22.25 18.5858 22.5858 18.25 23 18.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 25.25C26.4142 25.25 26.75 25.5858 26.75 26V33C26.75 33.4142 26.4142 33.75 26 33.75C25.5858 33.75 25.25 33.4142 25.25 33V26C25.25 25.5858 25.5858 25.25 26 25.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 2.4815 1.48035 1.25 2.99941 1.25H30.0007C31.5198 1.25 32.75 2.48153 32.75 4V11.5C32.75 11.9142 32.4142 12.25 32 12.25C31.5858 12.25 31.25 11.9142 31.25 11.5V4C31.25 3.30933 30.6907 2.75 30.0007 2.75H2.99941C2.30934 2.75 1.75 3.30936 1.75 4V20.5C1.75 21.1904 2.30964 21.75 3 21.75H20C20.4142 21.75 20.75 22.0858 20.75 22.5C20.75 22.9142 20.4142 23.25 20 23.25H3C1.48122 23.25 0.25 22.0188 0.25 20.5V4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25H16.5C16.9142 5.25 17.25 5.58579 17.25 6C17.25 6.41421 16.9142 6.75 16.5 6.75H5C4.58579 6.75 4.25 6.41421 4.25 6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 10C4.25 9.58579 4.58579 9.25 5 9.25H11C11.4142 9.25 11.75 9.58579 11.75 10C11.75 10.4142 11.4142 10.75 11 10.75H5C4.58579 10.75 4.25 10.4142 4.25 10Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 14C4.25 13.5858 4.58579 13.25 5 13.25H12C12.4142 13.25 12.75 13.5858 12.75 14C12.75 14.4142 12.4142 14.75 12 14.75H5C4.58579 14.75 4.25 14.4142 4.25 14Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 18C4.25 17.5858 4.58579 17.25 5 17.25H15C15.4142 17.25 15.75 17.5858 15.75 18C15.75 18.4142 15.4142 18.75 15 18.75H5C4.58579 18.75 4.25 18.4142 4.25 18Z" />
            </svg>
        )
    }
};