import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';

type IbssTimePickerProps = TimePickerProps<Date, Date>;
class IbssTimePicker extends React.Component<IbssTimePickerProps> {

  constructor(props: any) {
    super(props);
  };

  render(): React.ReactNode {

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          {...this.props}
        />
      </LocalizationProvider>
    );
  }
};

export default IbssTimePicker;