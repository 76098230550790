import { unified } from 'unified';
import { default as markdownPlugin } from 'remark-parse';
import { default as slatePlugin } from "remark-slate";
import { VFile } from 'vfile'
import { Descendant, Editor, createEditor } from 'slate';
import MarkdownEditor from '../MarkdownEditor';
import { isExclamationToken } from 'typescript';
import { IBlock, IText } from '../Models';

export class MarkdownToSlateAdapter
{
    private processor = unified().use(markdownPlugin).use(slatePlugin);

    public async execute(markdown: string): Promise<Descendant[]>
    {
        return new Promise((resolve, reject) =>
        {
            this.processor.process(markdown, (error: Error | undefined, result: VFile | undefined) =>
            {
                if (error)
                {
                    reject(error);
                    return;
                }
                else if (!result)
                {
                    reject(new Error("No result"));
                    return;
                }

                const slate = result?.result as Descendant[];
                const slateOrDefault = (slate.length == 0 ? MarkdownEditor.emptySlate : slate);
                this.processNodes(slateOrDefault, null);
                resolve(slateOrDefault);
            });
        });
    }

    private processNodes(nodes: Descendant[], parent: Descendant | null): void
    {
        let index = { value: 0 };
        for (index.value = 0; index.value < nodes.length; index.value++)
        {
            this.unwrapLink(nodes, index);
            this.unwrapListItem(nodes, parent, index);
            this.mormaliseCodeBlock(nodes, parent, index);

            const node = nodes[index.value];
            if (node && 'children' in node && node.children)
            {
                this.processNodes(node.children, node);
            }
        }
    }

    private unwrapLink(siblings: Descendant[], index: { value: number }): void
    {
        const node = siblings[index.value];
        if (!('type' in node) || node.type != 'link')
        {
            return;
        }

        const href = ('link' in node ? node.link : '') as string;
        siblings.splice(index.value--, 1);

        if ('children' in node && node.children)
        {
            for (let t = 0; t < node.children.length; t++)
            {
                const text = node.children[t] as Descendant & { link: string };
                text.link = href;
                siblings.splice(++index.value, 0, text);
            }
        }
    }

    private unwrapListItem(siblings: Descendant[], parent: Descendant | null, index: { value: number }): void
    {
        const node = siblings[index.value];
        if (!parent || !('type' in parent) || parent.type != 'list_item' || !('type' in node) || node.type != 'paragraph')
        {
            return;
        }

        siblings.splice(index.value--, 1);
        if ('children' in node && node.children)
        {
            for (let t = 0; t < node.children.length; t++)
            {
                const child = node.children[t];
                siblings.splice(++index.value, 0, child);
            }
        }
    }

    private mormaliseCodeBlock(siblings: Descendant[], parent: Descendant | null, index: { value: number }): void
    {
        const node = siblings[index.value];
        if (!parent || !('type' in parent) || parent.type != 'code_block' || !('text' in node))
        {
            return;
        }

        siblings.splice(index.value--, 1);
        const lines = node.text.split('\n').filter(i => i);

        for (let line of lines)
        {
            siblings.splice(++index.value, 0, { type: 'paragraph', children: [{ text: line }] } as IBlock);
        }
    }
}
