import { DateTime } from "luxon";
import { ICreateCateringSupplierEndpoint } from "./CreateCateringSupplierEndpoint";
import { IGetCateringSupplierEndpoint } from "./GetCateringSupplierByIdEndpoint";
import { IGetCateringSupplierNodeIdEndpoint } from "./GetCateringSupplierByNodeIdEndpoint";
import { IGetManyAsBlobEndpoint } from "./GetManyAsBlobEndpoint";
import { IUpdateCateringSupplierEndpoint } from "./UpdateCateringSupplier";
import fileDownload from "js-file-download";
import { IDeleteCateringSupplierEndpoint } from "./DeleteEndpoint";
export class CateringSupplierRepository implements ICateringSupplierRepository
{
    private createCateringSupplierEndpoint: ICreateCateringSupplierEndpoint;
    private getCateringSupplierByIdEndpoint: IGetCateringSupplierEndpoint;
    private getCateringSupplierByNodeIdEndpoint: IGetCateringSupplierNodeIdEndpoint;
    private getManyAsBlobEndpoint: IGetManyAsBlobEndpoint;
    private deleteCateringSupplierEndpoint: IDeleteCateringSupplierEndpoint;
    private updateCateringSupplierByIdEndpoint: IUpdateCateringSupplierEndpoint;

    constructor(
        createCateringSupplierEndpoint: ICreateCateringSupplierEndpoint,
        getCateringSupplierByIdEndpoint: IGetCateringSupplierEndpoint,
        getCateringSupplierByNodeIdEndpoint: IGetCateringSupplierNodeIdEndpoint,
        getManyAsBlobEndpoint: IGetManyAsBlobEndpoint,
        updateCateringSupplierByIdEndpoint: IUpdateCateringSupplierEndpoint,
        deleteCateringSupplierEndpoint: IDeleteCateringSupplierEndpoint,
    )
    {
        this.createCateringSupplierEndpoint = createCateringSupplierEndpoint;
        this.getCateringSupplierByIdEndpoint = getCateringSupplierByIdEndpoint;
        this.getCateringSupplierByNodeIdEndpoint = getCateringSupplierByNodeIdEndpoint;
        this.getManyAsBlobEndpoint = getManyAsBlobEndpoint;
        this.deleteCateringSupplierEndpoint = deleteCateringSupplierEndpoint;
        this.updateCateringSupplierByIdEndpoint = updateCateringSupplierByIdEndpoint;
    }

    public createCateringSupplier(nodeId: number, payload: ICateringSupplier): Promise<void>
    {
        return this.createCateringSupplierEndpoint.execute(nodeId, payload);
    }

    public getCateringSupplierById(nodeId: number, supplierId: string): Promise<ICateringSupplier>
    {
        return this.getCateringSupplierByIdEndpoint.execute(nodeId, supplierId);
    }

    public updateCateringSupplier(nodeId: number, supplierId: string, payload: ICateringSupplier): Promise<void>
    {
        return this.updateCateringSupplierByIdEndpoint.execute(nodeId, supplierId, payload);
    }

    public getCateringSupplierByNodeId(nodeId: number): Promise<IGetCateringSupplier>
    {
        return this.getCateringSupplierByNodeIdEndpoint.execute(nodeId);
    }

    public async getManyAsBlob(buildingId: number): Promise<Blob>
    {
        return this.getManyAsBlobEndpoint.execute(buildingId);
    }

    public deleteCateringSupplier(nodeId: number, supplierId: string): Promise<void>
    {
        return this.deleteCateringSupplierEndpoint.execute(nodeId, supplierId);
    }

}
export interface ICateringSupplierRepository 
{
    createCateringSupplier(nodeId: number, payload: ICateringSupplier): Promise<void>;
    getCateringSupplierById(nodeId: number, supplierId: string): Promise<ICateringSupplier>;
    getCateringSupplierByNodeId(nodeId: number): Promise<IGetCateringSupplier>;
    getManyAsBlob(buildingId: number): Promise<Blob>;
    updateCateringSupplier(nodeId: number, supplierId: string, payload: ICateringSupplier): Promise<void>;
    deleteCateringSupplier(nodeId: number, supplierId: string): Promise<void>;
}

export interface IGetCateringSupplier
{
    Value: ISupplierView[];
}

export interface ICateringSupplier
{
    Supplier_Id?: string;
    Name: string;
    Description: string;
    AccountManager: string;
    Phone: string;
    Email: string;
    LeadTime: string;
    External_Id: string;
    ConcurrencyStamp?: string;
}

export interface ISupplierView
{
    AccountManager: string;
    ConcurrencyStamp: string;
    CreatedAt: string;
    CreatedByUserId: string;
    Description: string;
    ETag: null
    Email: string;
    External_Id: string;
    ID: number;
    LeadTime: string;
    ModifiedAt: string;
    ModifiedByUserId: string;
    Name: string;
    Node_Id: 6
    OwnedByRoleId: null
    OwnedByUserId: string;
    Phone: string;
    Supplier_Id: string;
}

export class CateringSupplierStatus
{
    public static active = "Active";
    public static inactive = "Inactive";
}
