import React, { Component } from "react";

export default class SelectBox extends Component {
  state = {
    value: "",
    error: "Provide Value",
    displayOption: false,
    selectedValue: "Please Select",
  };

  toggleMenu = () => {
    this.setState({ displayOption: !this.state.displayOption });
  };

  selectedValue = (option: any) => {
    const { onchange }: any = this.props;
    this.setState({ selectedValue: option.title });
    onchange(option.value);
    this.toggleMenu();
  };


  getValue = (value: any) => {
    const { options }: any = this.props;
    const res = options.filter((r: any) => r.value === value);
    return res.length && res[0].title;
  };


  render() {
    const { label, value, mandatory, options, disabled, labelStyleClass }: any = this.props;
    return (
      <div className="custom-select-wrapper">
        <div className={labelStyleClass ? labelStyleClass : "label"}>
          <label>
            {label}
            {mandatory === true &&
            <span className="required"> *</span>
            }
          </label>
        </div>
        <div className="custom-select" onClick={!disabled ? () => this.toggleMenu() : ()=>{}} data-disabled={disabled} onMouseLeave={!disabled ? () => {this.setState({ displayOption: false });} : ()=>{}}>
          <div className="custom-select__trigger">
            <div>{this.getValue(value) || this.state.selectedValue}</div>
            <div className="arrow">
              <i
                className={`fa ${
                  this.state.displayOption ? "fa-angle-up" : "fa-angle-down"
                }`}
                aria-hidden="true"
              ></i>
            </div>
          </div>
          {this.state.displayOption && (
            <div  className="custom-options" onMouseLeave={() => { this.setState({ displayOption: !this.state.displayOption }); }} >
              {options.map((option: any) => (
                <div className="custom-option" key={option.value} onClick={() => this.selectedValue(option)}>{option.title} </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
