import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CateringOrdersFilter } from "./CateringOrderRepository";

export class GetManyAsBlobEndpoint implements IGetManyAsBlobEndpoint
{
    public async execute(buildingId: number, filter: CateringOrdersFilter): Promise<Blob>
    {
        try
        {
            const query =
            `schema=false&` +
            `showDebugStats=false&` +
            `$top=2000&` +
            `$filter=${filter.toODataString()}`;

            const response: AxiosResponse<Blob> = await axios.get(`${apis.allApiv2}${buildingId}/catering-order/download?${query}`, { responseType: 'blob' });
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyAsBlobEndpoint
{
    execute(buildingId: number, filter: CateringOrdersFilter): Promise<Blob>;
}

