import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { ICateringSpaces } from "./CateringSpacesRepository";

export class GetManyByMenuIdEndpoint implements IGetManyByMenuIdEndpoint
{
    public async execute(nodeId: number, menuId: string): Promise<ICateringSpaces[]>
    {
        try
        {
            const response = await axios.get<IRawPagedResponse<ICateringSpaces[]>>(`${apis.allApiv2}${nodeId}/catering-menu/${menuId}/spaces`);
            return response.data.Value;
        }
        catch (error)
        {
            const response = (error as AxiosError).response as AxiosResponse;
            throw ApiError.fromAxiosResponse(response);
        }
    }
}

export interface IGetManyByMenuIdEndpoint
{
    execute(nodeId: number, menuId: string): Promise<ICateringSpaces[]>;
}
