import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { appContext } from "../../../../AppContext";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import EditIcon from "../../../../Components/Icons/EditIcon";
import "./ListTags.scss";
import Bin from "../../../../Components/Icons/Bin";
export default class TagItem extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps) 
    {
        super(props);
    }

    public render(): JSX.Element 
    {

        const { tagData, onEditTag, deleteTag } = this.props;
        return (
            <Box display="flex" justifyContent="space-between" className="tag-item">
                <Box display="flex" alignItems="center">
                    {(tagData.TagType === "TagTypeSimple" && tagData.Icon !== "") &&
                        <img src={tagData.Icon} alt="Icon" />
                    }
                    <Typography style={{ marginLeft: '8px' }}>{tagData.Name}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <IbssButton variant="text" color="error" onClick={() => deleteTag()}
                    >
                        <IbssSvgIcon>
                            <Bin />
                        </IbssSvgIcon>
                    </IbssButton>
                    <Box mr={2}>
                        <FormControlLabel
                            control={<Checkbox checked={tagData.Status === "StatusActive"} disabled />}
                            label={this.labels.HubLabelEnabled}
                        />
                    </Box>
                    <IbssButton variant="text"
                        onClick={() => onEditTag()}
                    >
                        <IbssSvgIcon>
                            <EditIcon />
                        </IbssSvgIcon>
                    </IbssButton>
                </Box>
            </Box>
        )
    }
}
export interface IProps 
{
    tagData: ITagData;
    onEditTag: () => void;
    deleteTag: () => void;
}
export interface IState 
{
}
export interface ITagData 
{
    Icon: string;
    Name: string;
    Status: string;
    TagType: string;
}