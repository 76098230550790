import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import * as GetByZoneIds from "./GetByZoneIdsEndpoint";

export class EnvironmentalZoneDataRepository implements IEnvironmentalZoneDataRepository
{
    private getByZoneIdsEndpoint: GetByZoneIds.IGetByZoneIdsEndpoint;

    constructor(
        getByZoneIdsEndpoint: GetByZoneIds.IGetByZoneIdsEndpoint
    )
    {
        this.getByZoneIdsEndpoint = getByZoneIdsEndpoint;
    }

    public getByZoneIds(nodeId: number, environmentalZoneIds: string[]): Promise<GetByZoneIds.IEnvironmentalZoneData[]>
    {
        return this.getByZoneIdsEndpoint.execute(nodeId, environmentalZoneIds);
    }
}

export interface IEnvironmentalZoneDataRepository
{
    getByZoneIds(nodeId: number, environmentalZoneIds: string[]): Promise<GetByZoneIds.IEnvironmentalZoneData[]>;
}
