import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import { appContext } from "../../../AppContext";
import IbssTextField from "../../../Components/Inputs/TextField/IbssTextField";
import IbssFormControl from "../../../Components/Forms/FormControl/IbssFormControl";
import { RouteComponentProps } from 'react-router';
import { IBookingPolicy } from '../../../Providers.Api/BookingPolicies/GetBookingPolicyEndpoint';

class DuplicatePolicyModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; };
    private get apiClient() { return appContext().apiClient; };
    private get appState() { return appContext().state; };

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            duplicatePolicyName: '',
            selectedPolicy: null
        };
    }

    public componentDidMount(): void
    {
        const { bookingPolicyId } = this.props;
        if (bookingPolicyId) 
        {
            this.setDuplicatePolicy(bookingPolicyId);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState): void
    {
        if (prevProps.show != this.props.show)
        {
            this.setDuplicatePolicy(this.props.bookingPolicyId);
        }
    }

    private async setDuplicatePolicy(bookingPolicyId: string): Promise<void>
    {
        const bookingPolicy = await this.apiClient.bookingPolicies.getBookingPolicy(this.appState.buildingId, bookingPolicyId);
        if (bookingPolicy == null)
        {
            return;
        }

        this.setState({ 
            duplicatePolicyName: `Copy of ${bookingPolicy.Booking_Policy_Name}`, 
            selectedPolicy: bookingPolicy, 
        });    
    }

    private policyNameChanged(name: string): void
    {
        this.setState({ duplicatePolicyName: name });
    }

    private async duplicatePolicyModalSubmitted(): Promise<void>
    {
        if(this.state.selectedPolicy)
        {
            const payload = {
                Node_Id: this.state.selectedPolicy.Node_Id,
                Booking_Policy_Id: '',
                Booking_Policy_Name: this.state.duplicatePolicyName,
                Booking_Policy: this.state.selectedPolicy.Booking_Policy,
                Booking_Policy_Object: JSON.parse(this.state.selectedPolicy.Booking_Policy),
            };
            await this.apiClient.bookingPolicies.createBookingPolicy(payload);
        }
        this.props.onBookingPolicyCreated();
        this.props.onHide();
    }

    public render(): JSX.Element
    {
        const { show, onHide } = this.props;

        return (
        <Modal show={show} onHide={() => onHide()}>
            <Modal.Header>
                <Modal.Title>{this.labels.funDuplicatePolicyTitle}</Modal.Title>
                <button type="button" className="close" onClick={() => onHide()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <div className="m-4 text-center">
                <div className='m-4 text-left'>
                    {this.labels.funDuplicatePolicyDescription}
                </div>
                <IbssFormControl style={{ width: '550px', paddingBottom: '8px' }} >
                    <IbssTextField
                        label={this.labels.HubLabelName} 
                        type='text' 
                        variant='outlined' 
                        fullWidth 
                        value={this.state.duplicatePolicyName} 
                        onChange={e => this.policyNameChanged(e.target.value)} 
                     />
                </IbssFormControl>
            </div>
            <Modal.Footer>
                <div style={{ textAlign: 'center' }}>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '180px' }}
                        color='secondary'
                        variant="contained"
                        onClick={() => onHide()}
                    >
                        {this.labels.HubButtonCancel}
                    </IbssButton>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px' }}
                        variant="contained"
                        onClick={() => this.duplicatePolicyModalSubmitted()}
                    >
                        {this.labels.funcDuplicate}
                    </IbssButton>
                </div>
            </Modal.Footer>
        </Modal>
        )
    }
}

export default DuplicatePolicyModal;

export interface IState
{
    duplicatePolicyName: string;
    selectedPolicy: IBookingPolicy | null;
}

export interface IProps
{
    show: boolean;
    onHide: () => void;
    onBookingPolicyCreated: () => Promise<void>;
    bookingPolicyId: string;
}