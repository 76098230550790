import { Component } from 'react';
export default class PresentationAidIcon extends Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2354 3.17634C11.6506 3.17634 11.1766 3.65039 11.1766 4.23516C11.1766 4.81994 11.6506 5.29399 12.2354 5.29399C12.8202 5.29399 13.2942 4.81994 13.2942 4.23516C13.2942 3.65039 12.8202 3.17634 12.2354 3.17634ZM10.4707 4.23516C10.4707 3.26054 11.2608 2.47046 12.2354 2.47046C13.21 2.47046 14.0001 3.26054 14.0001 4.23516C14.0001 5.20978 13.21 5.99987 12.2354 5.99987C11.2608 5.99987 10.4707 5.20978 10.4707 4.23516Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.76891 5.20917C6.88069 5.04948 7.10076 5.01064 7.26045 5.12242L9.37034 6.59935C9.46921 6.66856 9.58698 6.70568 9.70767 6.70568H14.1169C14.8316 6.70568 15.411 7.28508 15.411 7.9998V10.8233C15.411 11.0183 15.253 11.1763 15.0581 11.1763C14.8631 11.1763 14.7051 11.0183 14.7051 10.8233V7.9998C14.7051 7.67493 14.4417 7.41157 14.1169 7.41157H9.70767C9.44215 7.41157 9.18306 7.32989 8.96554 7.17763L6.85565 5.70071C6.69596 5.58892 6.65713 5.36885 6.76891 5.20917Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8236 8.58813C11.0186 8.58813 11.1766 8.74615 11.1766 8.94108V15.1764H13.2942V8.94108C13.2942 8.74615 13.4522 8.58813 13.6472 8.58813C13.8421 8.58813 14.0001 8.74615 14.0001 8.94108V15.5293C14.0001 15.7242 13.8421 15.8823 13.6472 15.8823H10.8236C10.6287 15.8823 10.4707 15.7242 10.4707 15.5293V8.94108C10.4707 8.74615 10.6287 8.58813 10.8236 8.58813Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2358 11.8823C12.4307 11.8823 12.5887 12.0403 12.5887 12.2353V15.5294C12.5887 15.7243 12.4307 15.8823 12.2358 15.8823C12.0408 15.8823 11.8828 15.7243 11.8828 15.5294V12.2353C11.8828 12.0403 12.0408 11.8823 12.2358 11.8823Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.117188 1.88225C0.117188 1.16767 0.696174 0.588135 1.41103 0.588135H14.1175C14.8324 0.588135 15.4113 1.16768 15.4113 1.88225V5.41166C15.4113 5.60659 15.2533 5.76461 15.0584 5.76461C14.8634 5.76461 14.7054 5.60659 14.7054 5.41166V1.88225C14.7054 1.55723 14.4422 1.29402 14.1175 1.29402H1.41103C1.08629 1.29402 0.82307 1.55724 0.82307 1.88225V9.64696C0.82307 9.97183 1.08643 10.2352 1.41131 10.2352H9.41131C9.60623 10.2352 9.76425 10.3932 9.76425 10.5881C9.76425 10.7831 9.60623 10.9411 9.41131 10.9411H1.41131C0.696584 10.9411 0.117188 10.3617 0.117188 9.64696V1.88225Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2.8234C2 2.62848 2.15802 2.47046 2.35294 2.47046H7.76471C7.95963 2.47046 8.11765 2.62848 8.11765 2.8234C8.11765 3.01832 7.95963 3.17634 7.76471 3.17634H2.35294C2.15802 3.17634 2 3.01832 2 2.8234Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.70572C2 4.5108 2.15802 4.35278 2.35294 4.35278H5.17647C5.37139 4.35278 5.52941 4.5108 5.52941 4.70572C5.52941 4.90065 5.37139 5.05867 5.17647 5.05867H2.35294C2.15802 5.05867 2 4.90065 2 4.70572Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6.58805C2 6.39312 2.15802 6.23511 2.35294 6.23511H5.64706C5.84198 6.23511 6 6.39312 6 6.58805C6 6.78297 5.84198 6.94099 5.64706 6.94099H2.35294C2.15802 6.94099 2 6.78297 2 6.58805Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8.47037C2 8.27545 2.15802 8.11743 2.35294 8.11743H7.05882C7.25375 8.11743 7.41176 8.27545 7.41176 8.47037C7.41176 8.6653 7.25375 8.82331 7.05882 8.82331H2.35294C2.15802 8.82331 2 8.6653 2 8.47037Z" fill="inherit" />
            </svg>
        )
    }
};
