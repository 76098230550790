import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { NodeIdSpaceId } from "./SpaceRepository";

export class EnableSpacesEndpoint implements IEnableSpacesEndpoint
{
    public async execute(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>
    {
        try
        {
            await axios.patch<void>(`${apis.allApiv2}${nodeId}/Spaces/Enable`, spaces);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEnableSpacesEndpoint
{
    execute(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>;
}
