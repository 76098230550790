import { Component } from 'react';
export default class TimerIcon extends Component {
    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 4.55887C6.02065 4.55887 2.79446 7.78505 2.79446 11.7647C2.79446 12.0084 2.59694 12.2059 2.35329 12.2059C2.10963 12.2059 1.91211 12.0084 1.91211 11.7647C1.91211 7.29774 5.53334 3.67651 10.0003 3.67651C14.4674 3.67651 18.0886 7.29774 18.0886 11.7647C18.0886 16.2318 14.4674 19.853 10.0003 19.853H4.70623C4.46257 19.853 4.26505 19.6555 4.26505 19.4118C4.26505 19.1682 4.46257 18.9706 4.70623 18.9706H10.0003C13.98 18.9706 17.2062 15.7444 17.2062 11.7647C17.2062 7.78505 13.98 4.55887 10.0003 4.55887Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2939 11.3235C15.5375 11.3235 15.7351 11.521 15.7351 11.7647C15.7351 14.9322 13.1673 17.5 9.99977 17.5C9.75612 17.5 9.55859 17.3024 9.55859 17.0588C9.55859 16.8151 9.75612 16.6176 9.99977 16.6176C12.68 16.6176 14.8527 14.4449 14.8527 11.7647C14.8527 11.521 15.0502 11.3235 15.2939 11.3235Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.91211 17.0589C1.91211 16.8152 2.10963 16.6177 2.35329 16.6177H7.6474C7.89106 16.6177 8.08858 16.8152 8.08858 17.0589C8.08858 17.3025 7.89106 17.5 7.6474 17.5H2.35329C2.10963 17.5 1.91211 17.3025 1.91211 17.0589Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146484 14.7058C0.146484 14.4622 0.344006 14.2646 0.587661 14.2646H5.88178C6.12543 14.2646 6.32295 14.4622 6.32295 14.7058C6.32295 14.9495 6.12543 15.147 5.88178 15.147H0.587661C0.344006 15.147 0.146484 14.9495 0.146484 14.7058Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79492 1.17638C7.79492 0.607856 8.25581 0.146973 8.82433 0.146973H11.1773C11.7458 0.146973 12.2067 0.607856 12.2067 1.17638V2.35286C12.2067 2.92138 11.7458 3.38227 11.1773 3.38227H8.82433C8.25581 3.38227 7.79492 2.92138 7.79492 2.35286V1.17638ZM8.82433 1.02933C8.74312 1.02933 8.67727 1.09517 8.67727 1.17638V2.35286C8.67727 2.43407 8.74312 2.49991 8.82433 2.49991H11.1773C11.2585 2.49991 11.3243 2.43407 11.3243 2.35286V1.17638C11.3243 1.09517 11.2585 1.02933 11.1773 1.02933H8.82433Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38281 2.5H11.6181V4.55882H8.38281V2.5ZM9.26517 3.38235V3.67647H10.7358V3.38235H9.26517Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0005 11.0293C9.59437 11.0293 9.26517 11.3585 9.26517 11.7646C9.26517 12.1707 9.59437 12.4999 10.0005 12.4999C10.4066 12.4999 10.7358 12.1707 10.7358 11.7646C10.7358 11.3585 10.4066 11.0293 10.0005 11.0293ZM8.38281 11.7646C8.38281 10.8712 9.10706 10.147 10.0005 10.147C10.8939 10.147 11.6181 10.8712 11.6181 11.7646C11.6181 12.658 10.8939 13.3823 10.0005 13.3823C9.10706 13.3823 8.38281 12.658 8.38281 11.7646Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4298 7.33503C14.6021 7.50732 14.6021 7.78666 14.4298 7.95895L11.1945 11.1942C11.0223 11.3665 10.7429 11.3665 10.5706 11.1942C10.3983 11.0219 10.3983 10.7426 10.5706 10.5703L13.8059 7.33503C13.9782 7.16274 14.2575 7.16274 14.4298 7.33503Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42926 12.6292C9.60155 12.8015 9.60155 13.0808 9.42926 13.2531L8.84103 13.8414C8.66874 14.0137 8.3894 14.0137 8.21711 13.8414C8.04482 13.6691 8.04482 13.3897 8.21711 13.2175L8.80534 12.6292C8.97763 12.4569 9.25697 12.4569 9.42926 12.6292Z" fill="inherit" />
            </svg>

        )
    }
};
