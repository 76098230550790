import{ Component } from 'react';

export default class SpaceBuildingIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 21C4.0875 21 3.73438 20.8531 3.44063 20.5594C3.14688 20.2656 3 19.9125 3 19.5V8.625C3 8.2125 3.14688 7.85938 3.44063 7.56563C3.73438 7.27187 4.0875 7.125 4.5 7.125H7.125V4.5C7.125 4.0875 7.27187 3.73438 7.56563 3.44063C7.85938 3.14688 8.2125 3 8.625 3H15.375C15.7875 3 16.1406 3.14688 16.4344 3.44063C16.7281 3.73438 16.875 4.0875 16.875 4.5V11.25H19.5C19.9125 11.25 20.2656 11.3969 20.5594 11.6906C20.8531 11.9844 21 12.3375 21 12.75V19.5C21 19.9125 20.8531 20.2656 20.5594 20.5594C20.2656 20.8531 19.9125 21 19.5 21H13.325V16.875H10.675V21H4.5ZM4.5 19.5H7.125V16.875H4.5V19.5ZM4.5 15.375H7.125V12.75H4.5V15.375ZM4.5 11.25H7.125V8.625H4.5V11.25ZM8.625 15.375H11.25V12.75H8.625V15.375ZM8.625 11.25H11.25V8.625H8.625V11.25ZM8.625 7.125H11.25V4.5H8.625V7.125ZM12.75 15.375H15.375V12.75H12.75V15.375ZM12.75 11.25H15.375V8.625H12.75V11.25ZM12.75 7.125H15.375V4.5H12.75V7.125ZM16.875 19.5H19.5V16.875H16.875V19.5ZM16.875 15.375H19.5V12.75H16.875V15.375Z" fill="#191C1D"/>
            </svg>
        )
    }
};