import { Component } from 'react';
export default class CostCodeIcon extends Component {
    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.588149C0 0.344494 0.197521 0.146973 0.441176 0.146973H10.2596C10.3767 0.146973 10.4889 0.193469 10.5716 0.276231L19.0066 8.71342C19.4545 9.1641 19.7059 9.77374 19.7059 10.4092C19.7059 11.0446 19.4545 11.6542 19.0066 12.1049L18.6937 11.7939L19.0057 12.1059L11.966 19.1474C11.7427 19.371 11.4775 19.5484 11.1855 19.6695C10.8935 19.7905 10.5806 19.8529 10.2645 19.8529C9.94849 19.8529 9.63554 19.7905 9.34358 19.6695C9.05163 19.5484 8.7864 19.371 8.56305 19.1474L0.129359 10.7213C0.0465357 10.6385 0 10.5262 0 10.4092V0.588149ZM18.3812 11.4825C18.6645 11.1972 18.8235 10.8113 18.8235 10.4092C18.8235 10.007 18.6645 9.62111 18.3812 9.3358L10.0769 1.02933H0.882353V10.2263L9.18705 18.5235C9.32845 18.6651 9.49672 18.7778 9.68155 18.8544C9.86637 18.9311 10.0645 18.9705 10.2645 18.9705C10.4646 18.9705 10.6627 18.9311 10.8475 18.8544C11.0324 18.7778 11.2003 18.6655 11.3417 18.5239L18.3812 11.4825Z" fill="inherit" />
                <circle cx="4.85352" cy="5" r="1.625" stroke="#2A3F49" stroke-width="0.75" />
                <path d="M9.51752 15.32V14.132C9.13352 14.092 8.76152 13.988 8.40152 13.82C8.04152 13.644 7.73352 13.44 7.47752 13.208L7.93352 12.524C8.18952 12.748 8.46952 12.94 8.77352 13.1C9.08552 13.252 9.42552 13.328 9.79352 13.328C10.2415 13.328 10.5735 13.216 10.7895 12.992C11.0135 12.76 11.1255 12.46 11.1255 12.092C11.1255 11.764 11.0455 11.496 10.8855 11.288C10.7255 11.08 10.5175 10.9 10.2615 10.748C10.0055 10.596 9.73352 10.452 9.44552 10.316C9.16552 10.172 8.89752 10.016 8.64152 9.848C8.38552 9.672 8.17752 9.456 8.01752 9.2C7.85752 8.944 7.77752 8.62 7.77752 8.228C7.77752 7.692 7.93752 7.248 8.25752 6.896C8.57752 6.536 8.99752 6.316 9.51752 6.236V5.024H10.2375V6.212C10.6215 6.252 10.9455 6.356 11.2095 6.524C11.4815 6.692 11.7255 6.892 11.9415 7.124L11.4135 7.712C11.1895 7.496 10.9695 7.328 10.7535 7.208C10.5455 7.088 10.2775 7.028 9.94952 7.028C9.58152 7.028 9.28952 7.132 9.07352 7.34C8.85752 7.548 8.74952 7.828 8.74952 8.18C8.74952 8.468 8.82952 8.708 8.98952 8.9C9.14952 9.084 9.35752 9.244 9.61352 9.38C9.86952 9.516 10.1375 9.652 10.4175 9.788C10.7055 9.924 10.9775 10.084 11.2335 10.268C11.4895 10.444 11.6975 10.672 11.8575 10.952C12.0175 11.232 12.0975 11.588 12.0975 12.02C12.0975 12.588 11.9295 13.06 11.5935 13.436C11.2575 13.804 10.8055 14.028 10.2375 14.108V15.32H9.51752Z" fill="inherit" />
            </svg>
        )
    }
};
