import React, { Component } from "react";

export default class DonutChart extends Component<IProps>
{
    public render(): JSX.Element
    {
        const { cardClicked, title, subTitle } = this.props

        return (
            <>
                <div className="spacePanel" onClick={cardClicked}>
                    <h3 className="donutcharttitlecenter">{title}</h3>
                    <h1 className="donutcharttitlecenter">{subTitle}</h1>
                </div>
            </>
        );
    }
}

interface IProps
{
    cardClicked: () => void;
    title: string;
    subTitle: number;
}
