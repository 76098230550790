import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class ResolveTask implements IResolveTask
{
    public async execute(nodeId: number, taskId: string): Promise<string>
    {
        try
        {
            const response = await axios.put(`${apis.allApi}/${nodeId}/Tasks/${taskId}/resolve`);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}


export interface IResolveTask
{
    execute(nodeId: number, taskId: string): Promise<string>;
}