import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo";
import { appContext } from "../../../../AppContext";

class ListBuildings extends Component {
  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    spaces: [{ id: 0, name: "" }],
    filter: "Building",
    valueBox: 0,
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    this.setState({
      isLoading: true
    })
    apis.getSpacesByFilterEnabledBuilding(this.state.filter, 1).then((res) => {
      if (res.data.length === 0) {
        this.setState({
          spaces: [{ id: 0, name: "" }],
        });
      } else {
        this.setState({
          spaces: res.data
        }, () => {
          if (this.session.getBuildingId() && this.session.getBuildingId() !== 0) {
            if (this.session.getBuildingId() !== match.params.buildingid) {
              let temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
              history.push(temp + "/" + this.session.getBuildingId())
            }
          } else {
            if (match.params.buildingid !== "0") {
              for (let i = 0; i < this.state.spaces.length; i++) {
                if (this.state.spaces[i].id === match.params.buildingid) {
                  this.session.setBuildingId(this.state.spaces[0].id)
                  this.session.setBuildingName(this.state.spaces[i].name);
                  window.location.reload();
                }
              }
            }
            if (this.session.getBuildingId() === null || this.session.getBuildingId() === 0) {
              this.session.setBuildingId(this.state.spaces[0].id)
              this.session.setBuildingName(this.state.spaces[0].name);
              window.location.reload();
            }
          }
        });
      }
      this.setState({ isLoading: false })
    }).catch((error: any) => {
      let errorMessage = this.labels.HubLabelTokenErorr
      this.setState({
        spaces: [{ id: 0, name: "" }],
      });
      if (error.response) {
        if (error.response.statusText) {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
      this.setState({ isLoading: false })
    });
  }

  handleClick(params: any) {
    this.session.setBuildingId(params.id)
    this.session.setBuildingName(params.name);
    window.history.pushState("", "", "/operational-services-buildings/" + params.id)
    this.setState({
      valueBox: parseInt(params.id)
    })
  }

  render() {
    const { lightModeTheme }: any = this.props;
    const { spaces, valueBox }: any = this.state;

    for (let j = 0; j < spaces.length && spaces[0].id !== 0; j++) {
      let params = spaces[j];
      spaces[j] = {
        ...spaces[j],
        clickEvent: () => this.handleClick(params),
        'grossArea': spaces[j].area.grossArea,
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name="building" id={spaces[j].id} checked={this.session.getBuildingId() == spaces[j].id} value={spaces[j].id} onClick={() => this.handleClick(params)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    const data = {
      columns: [
        {
          label: "",
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelName,
          field: "name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelCode,
          field: "code",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelCapacity,
          field: "personCapacity",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSize,
          field: "grossArea",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: spaces
    };

    const dataEmpty = {
      columns: [
        {
          label: "",
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelName,
          field: "name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelCode,
          field: "code",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelCapacity,
          field: "personCapacity",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSize,
          field: "grossArea",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: []
    };

    return (
      <>
            <div className="rightPanel-main-content">
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.labels.HubLabelBuildings}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Building.svg"
                />
                <div className="position-relative">
                  {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                  {spaces.length !== 0 ?
                    <MDBDataTable className="standardTable tablePageNoButtons tableBuildingSelection" searchLabel={this.labels.HubLabelMySearchLabel} disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={data} />
                    :
                    <MDBDataTable className="standardTable tablePageNoButtons tableBuildingSelection" searchLabel={this.labels.HubLabelMySearchLabel} displayEntries={false} entries={50} hover data={dataEmpty} />
                  }
                </div>
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(ListBuildings);
