import { Card, Grid, Typography } from '@mui/material';
import React, { Component } from 'react'
import { appContext } from '../../../../AppContext';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';

type labelsList = {
    label: string,
    checked: boolean,
    onChange: (e: any) => void,
    name: string,
}

type labelsListProp = {
    labelsList: labelsList[]
};

class IbssUserPrefNotificationUI extends Component<labelsListProp> {
    private labels = appContext().labels;

    public constructor(props:labelsListProp) {
        super(props);
    }

    render() {
        return (
            <Card className="card-userPref">
                <Grid
                    container
                    gap={1}
                >
                    <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleTailorNotification}</Typography>

                    <Typography className='card-userPref-text' variant="body2" gutterBottom>{this.labels.HubSubTitleTailorNotification}</Typography>

                    <Typography className='card-userPref-text' variant="body2" marginBottom={"32px"}>{this.labels.HubSubTitleSecondTailorNotification}</Typography>
                </Grid>

                <Grid
                    container
                    item
                    display={"flex"}
                    alignItems="start"
                    direction="column"
                    gap={2}
                >
                    {this.props.labelsList.map(({ label, checked, onChange, name }) =>
                        <IbssSwitchLabel
                            key={name}
                            labelClasses={{ "style": { margin: "16px 0px" } }}
                            label={label}
                            labelPlacement={LabelPlacementType.start}
                            checked={checked}
                            onChange={onChange}
                            name={name}
                        />
                    )}

                </Grid>

            </Card>
        )
    }
};

export default IbssUserPrefNotificationUI;
