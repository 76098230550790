import { Button } from "@mui/material";
import { IbssComponent } from "../../../Core/BaseComponent/IbssComponent"
import { appContext } from "../../../../AppContext";
import { Helper } from "../Helper";
import { Editor } from "slate";

export default class DevButton extends IbssComponent<IProps, IState>
{
    private helper = new Helper(this.props.editor);

    private handleMouseDown(event: React.MouseEvent): void
    {
        event.preventDefault();
        this.helper.logSlate();
        this.helper.logMarkdown();
    }

    public render(): JSX.Element
    {
        if (this.props.hide)
        {
            return <></>;
        }

        return (
            <Button
                className="markdown-editor__toolbar-button"
                title="Debug"
                onMouseDown={e => this.handleMouseDown(e)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-9 -9 40 40">
                    <path style={{ stroke: 'var(--ui-text)' }} d="M4.6 15c-.9-2.6-.6-4.6-.5-5.4 2.4-1.5 5.3-2 8-1.3.7-.3 1.5-.5 2.3-.6-.1-.3-.2-.5-.3-.8h2l1.2-3.2-.9-.4-1 2.6h-1.8C13 4.8 12.1 4 11.1 3.4l2.1-2.1-.7-.7L10.1 3c-.7 0-1.5 0-2.3.1L5.4.7l-.7.7 2.1 2.1C5.7 4.1 4.9 4.9 4.3 6H2.5l-1-2.6-.9.4L1.8 7h2C3.3 8.3 3 9.6 3 11H1v1h2c0 1 .2 2 .5 3H1.8L.6 18.3l.9.3 1-2.7h1.4c.4.8 2.1 4.5 5.8 3.9-.3-.2-.5-.5-.7-.8-2.9 0-4.4-3.5-4.4-4zM9 3.9c2 0 3.7 1.6 4.4 3.8-2.9-1-6.2-.8-9 .6.7-2.6 2.5-4.4 4.6-4.4zm14.8 19.2l-4.3-4.3c2.1-2.5 1.8-6.3-.7-8.4s-6.3-1.8-8.4.7-1.8 6.3.7 8.4c2.2 1.9 5.4 1.9 7.7 0l4.3 4.3c.2.2.5.2.7 0 .2-.2.2-.5 0-.7zm-8.8-3c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1z" />
                    <path fill="none" d="M0 0h24v24H0z" />
                </svg>
            </Button>
        );
    }
}

export interface IProps
{
    hide: boolean;
    editor: Editor;
}

export interface IState
{
}
