import{ Component } from 'react';

export default class LocationIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 16 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00222 9.75C8.48407 9.75 8.89583 9.57843 9.2375 9.23528C9.57917 8.89214 9.75 8.47964 9.75 7.99778C9.75 7.51593 9.57843 7.10417 9.23528 6.7625C8.89214 6.42083 8.47964 6.25 7.99778 6.25C7.51593 6.25 7.10417 6.42158 6.7625 6.76472C6.42083 7.10786 6.25 7.52036 6.25 8.00222C6.25 8.48407 6.42158 8.89583 6.76472 9.2375C7.10786 9.57917 7.52036 9.75 8.00222 9.75ZM8.0125 19.7C7.90417 19.7 7.8 19.6833 7.7 19.65C7.6 19.6167 7.50833 19.5667 7.425 19.5C4.94167 17.3167 3.08333 15.2926 1.85 13.4277C0.616667 11.5627 0 9.82018 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.81667 15.3833 11.5583 14.15 13.425C12.9167 15.2917 11.0583 17.3167 8.575 19.5C8.49167 19.5667 8.40417 19.6167 8.3125 19.65C8.22083 19.6833 8.12083 19.7 8.0125 19.7Z" fill="#191C1D"/>
            </svg>
        )
    }
};