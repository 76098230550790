import { Component } from 'react';

export default class DownAngleIcon extends Component {
    render() {
        return (
            <svg>
                <path d="M10 1L5.5 6L1 1" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        )
    }
};