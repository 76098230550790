import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class SetStatusToCancelled implements ISetStatusToCancelled
{
    public async execute(nodeId: number, taskId: string, message: string): Promise<string>
    {
        try
        {
            const response = await axios.put(apis.allApi + nodeId + '/Tasks/' + taskId + '/cancel?taskResolveDescription=' + message);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ISetStatusToCancelled
{
    execute(nodeId: number, taskId: string, message: string): Promise<string>; 
} 
