import Grid from "@mui/material/Grid";
import DonutProgressChart from "../../../../Components/Data/DonutProgressChart/IbssDonutProgressChart";
import TrendChart from "./TrendChart";

import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { getFloorsFromSelectedBuildingUsingBuildingId, donutColor, imgCall } from "../../../../Common/Helper";
import { useEffect, useState } from "react";
import { ONELENS_SPACEANALYTICS_DETAIL } from '../../../../app/constants';
import { Link } from "react-router-dom";
import { appContext } from "../../../../AppContext";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";

export interface IProps {
  Node_Id: number,
  Space_Class: string,
  Space_Type: string,
  Period_Current_Capacity_Value: number,
  name: string,
  spaceId: string,
  spaceUtilization: number,
  spaceChange: number,
  maxOccupancy: number,
  avgOccupancy: number,
  capacityUtilization: number,
  capacityChange: number,
  occupanyDiff: number,
  occupancyVal: number| undefined,
};

const AnalyticsRow = (props: IProps) => {
  const {Node_Id,Space_Class,Space_Type,name,spaceId,spaceUtilization,spaceChange,maxOccupancy,
        avgOccupancy,capacityUtilization,capacityChange, occupanyDiff, occupancyVal} = props;
  
  const [width, setWidth]   = useState(window.innerWidth);
  const [spaceList,setSpaceList] = useState<Space[]>([]);
  const dispatch = useDispatch();
  const appState = appContext().state;
  const {
    onelensSpaceAnalyticsFilterCriteria,
    selectedFloor,
    onelensDateBuilding,
    lightModeTheme,
  } = useSelector((state: RootStateOrAny) => ({
    onelensSpaceAnalyticsFilterCriteria: state.onelensSpaceAnalyticsFilterCriteria.buildingWorkSpaceType,
    selectedFloor: state.onelensSpaceAnalyticsFilterCriteria.buildingFloor,
    onelensDateBuilding: state.onelensDateBuilding,
    lightModeTheme: state.lightModeTheme,
  }));
  
  /* No. of space count conditions */
  const spacesNo = () => {
    let noOfSpaceCount = [];
    if (
      onelensDateBuilding.nodeLevelName === "Floor" &&
      (onelensSpaceAnalyticsFilterCriteria === "Any" ||
        onelensSpaceAnalyticsFilterCriteria === "Total")
    ) {
      if (selectedFloor !== "Any") {
        noOfSpaceCount = spaceList.filter(
          (val: Space) =>
            val.Node_Id === Node_Id && val.Space_Class === Space_Class
        );
      } else {
        noOfSpaceCount = spaceList.filter(
          (val: Space) =>
            val.Node_Id === Node_Id && val.Space_Class === Space_Class
        );
      }
    } else if (
      onelensDateBuilding.nodeLevelName === "Floor" &&
      onelensSpaceAnalyticsFilterCriteria !== "Any" &&
      onelensSpaceAnalyticsFilterCriteria !== "Total"
    ) {
      noOfSpaceCount = spaceList.filter(
        (val: Space) =>
          val.Node_Id === Node_Id &&
          val.Space_Class === Space_Class &&
          (val.Space_Type_Label
            ? val.Space_Type_Label === onelensSpaceAnalyticsFilterCriteria
            : val.Space_Type === onelensSpaceAnalyticsFilterCriteria)
      );
    } else if (onelensDateBuilding.nodeLevelName === "Building") {

      const fetchfloor = getFloorsFromSelectedBuildingUsingBuildingId(
        Node_Id
      ).map((val: any) => val.Node_Id);
      let fetchfloorData = [];

      if (selectedFloor === "Any") {
        fetchfloorData = fetchfloor.map((value: any) => {
          const noOfSpace = spaceList.filter((val: Space) => {
            return (
              val.Node_Id === value &&
              val.Space_Class === Space_Class &&
              (val.Space_Type_Label
                ? val.Space_Type_Label === name
                : val.Space_Type === name)
            );
          });
          return noOfSpace;
        });
      } else {
       
        if (onelensDateBuilding.nodeLevelName === "Building") {
          if (onelensSpaceAnalyticsFilterCriteria === "Total") {
            if (onelensDateBuilding.buildingNodeId !== 1) {
              if (onelensSpaceAnalyticsFilterCriteria !== "Any") {
                const fetchfloor_ =
                getFloorsFromSelectedBuildingUsingBuildingId(
                  onelensDateBuilding.buildingNodeId
                  ).map((val: any) => val.Node_Id);
                    const noOfSpace = spaceList.filter((val: Space) => {
                      return (
                        val.Node_Id === onelensDateBuilding.floor &&
                        val.Space_Class === Space_Class &&
                        val.Space_Type_Label === name
                          );
                        });
                  return noOfSpace;
             
              } else {
                fetchfloorData = fetchfloor.map((value: any) => {
                  const noOfSpace = spaceList.filter((val: Space) => {
                    return (
                      val.Node_Id === value &&
                      val.Space_Class === Space_Class &&
                      (val.Space_Type_Label
                        ? val.Space_Type_Label === name
                        : val.Space_Type === name)
                    );
                  });
                  return noOfSpace;
                });
              }
            } else {
              fetchfloorData = fetchfloor.map((value: any) => {
                const noOfSpace = spaceList.filter((val: Space) => {
                  return (
                    val.Node_Id === value && val.Space_Class === Space_Class
                  );
                });
                return noOfSpace;
              });
            }
          } else {
            if (
              onelensSpaceAnalyticsFilterCriteria !== "Any" &&
              onelensSpaceAnalyticsFilterCriteria !== "Total"
            ) {
              const fetchfloor_ = getFloorsFromSelectedBuildingUsingBuildingId(
                onelensDateBuilding.buildingNodeId
              ).map((val: any) => val.Node_Id);
              fetchfloorData = fetchfloor_.map((value: any) => {
                const noOfSpace = spaceList.filter((val: Space) => {
                  return (
                    val.Node_Id === value &&
                    val.Space_Class === Space_Class &&
                    (val.Space_Type_Label
                      ? val.Space_Type_Label === name
                      : val.Space_Type === name)
                  );
                });
                return noOfSpace;
              });
            } else {
              fetchfloorData = fetchfloor.map((value: any) => {
                const noOfSpace = spaceList.filter((val: Space) => {
                  return (
                    val.Node_Id === value &&
                    val.Space_Class === Space_Class &&
                    (val.Space_Type_Label
                      ? val.Space_Type_Label ===
                        onelensSpaceAnalyticsFilterCriteria
                      : val.Space_Type === onelensSpaceAnalyticsFilterCriteria)
                  );
                });
                return noOfSpace;
              });
            }
          }
        } else {
          fetchfloorData = fetchfloor.map((value: any) => {
            const noOfSpace = spaceList.filter((val: Space) => {
              return val.Node_Id === value && val.Space_Class === Space_Class;
            });
            return noOfSpace;
          });
        }
      }
      noOfSpaceCount = fetchfloorData.flat(1);
    } else if (onelensDateBuilding.nodeLevelName === "Space") {
      const fetchfloor = getFloorsFromSelectedBuildingUsingBuildingId(
        onelensDateBuilding.buildingNodeId
      ).map((val: any) => val.Node_Id);
      let spaceData = [];

      if (selectedFloor === "Any") {
        spaceData = fetchfloor.map((value: any) => {
          const noOfSpace = spaceList.filter((val: Space) => {
            return (
              val.Node_Id === value &&
              val.Space_Class === Space_Class &&
              val.Space_Name === name
            );
          });
          return noOfSpace;
        });
        noOfSpaceCount = spaceData.flat(1);
      } else {
        const noOfSpace = spaceList.filter((val: Space) => {
          return (
            val.Node_Id === onelensDateBuilding.floor &&
            val.Space_Class === Space_Class &&
            val.Space_Name === name
          );
        });
        noOfSpaceCount = noOfSpace;
      }
    }
    return noOfSpaceCount;
  };

  const availableCapacity = spacesNo().map((val: any) => val.Space_Capacity);
  /* Total Capacity counts */
  const availableCapacityTotal = availableCapacity.reduce(
    (acc: number, curr: number) => acc + curr,
    0
  );

  /* get dimension */
    const updateDimensions = () => {
      setWidth(window.innerWidth);
  }

  const handleDispatchDetailPage = (spaceName: string)=>{
    dispatch({
      type: ONELENS_SPACEANALYTICS_DETAIL,
      payload: {
        spaceName: spaceName,
        periodType: onelensDateBuilding.periodtype_,
        startDate: onelensDateBuilding.periodstartDate,
        endDate: onelensDateBuilding.periodEndDate
      },
    })
  }

  const loadSpaces = async () => {   
    const _space = await appContext().apiCache.getSpacesByBuilding(appState.buildingId);
    setSpaceList(_space);
  }

  useEffect(() => {
    loadSpaces()
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  
  const state = {
    spaceName: props.name,
    capacityPer: Math.round(props.spaceUtilization),
    capacityVal: Math.round(props.maxOccupancy),
    occupancyDiff: occupanyDiff,
    occupanyVal: occupancyVal,
    nodeId: Node_Id,
    spaceType: Space_Type,
    spaceId: spaceId, 
  }
  return (
    <>
      {/* Response render */}
      {props ? (
        <div className="grid-row" >
          <Grid container spacing={2} className="xscroll space-between" sx={{TextAlign:'center'}}>
          {width < 990 ? null : 
           <Grid item xs={1} className="text-ui-md text-align-center">
              <img
                src={`/images/SpaceType_Icons/${
                  lightModeTheme ? "Light_Theme" : "Dark_Theme"
                }/${imgCall(Space_Type,onelensDateBuilding.buildingNodeId,onelensSpaceAnalyticsFilterCriteria,onelensDateBuilding.nodeLevelName)}.svg`}
              />
            </Grid>}
            {name.includes("Floor") || (onelensDateBuilding.buildingNodeId === 1 && onelensDateBuilding.nodeLevelName === "Building")? 
             <Grid item xs={1} className="text-ui-md">
             {name}
           </Grid>: 
           <Link className="text-decoration-none" 
            to={{
              pathname: "/space-analytics-detail/",
              state: {
                ...state,
                availableCapacityTotal: availableCapacityTotal
              }
            }}
           onClick={()=>handleDispatchDetailPage(props.name)} >
           <Grid item xs={2} className="text-ui-md ">
             {name}
           </Grid>
           </Link>}
            {width < 990 ? null :
             <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
             {spacesNo().length}
           </Grid>}
            <Grid item xs={1} className="text-ui-md justify-content-center">
              <DonutProgressChart
                strokeWidth={7}
                value={spaceUtilization}
                strokeColor={donutColor(capacityUtilization)}
                fontColor={`var(--ui-text)`}
                portionColor={"#DFE2E7"}
                donutSize={50}
              />
            </Grid>
            {width < 1000 ? null :
             <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
              <TrendChart
                percentage={Math.round(spaceChange)}
                arrowSize={15}
                fontSize={1.02}
                showPercentage={true}
                size={0}
              />
            </Grid>}
           
            {width < 1100 ? null : (
              <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
                {maxOccupancy}
              </Grid>
            )}
            {width < 1120 ? null : (
              <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
                {avgOccupancy}
              </Grid>
            )}
            {width < 1242 ? null : (
              <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
                {availableCapacityTotal}
              </Grid>
            )}
            <Grid item xs={2}>
              <DonutProgressChart
                strokeWidth={7}
                value={capacityUtilization}
                strokeColor={donutColor(capacityUtilization)}
                fontColor={`var(--ui-text)`}
                portionColor={"#DFE2E7"}
                donutSize={50}
              />
            </Grid>
            {width< 1014? null:
            <Grid item xs={1} className="alignContent text-ui-md justify-content-center">
              <TrendChart
                percentage={capacityChange}
                arrowSize={15}
                fontSize={1.02}
                showPercentage={true}
                size={0}
              />
            </Grid>
            }
        
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default AnalyticsRow;
