import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { ODataQuery } from "../ODataQuery";
import { BookingStatus } from "./BookingRepository";

export class GetMyV2BookingsForOthersEndpoint implements IGetMyV2BookingsForOthersEndpoint
{
    public async execute(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<Booking[]>>
    {
        try
        {
            query.select = Booking;
            const lowerCaseEmail = email.toLowerCase();
            const queryString = query.toODataString(' and ', `CreatedBy eq '${lowerCaseEmail}' and Booking_Owner_Email ne '${lowerCaseEmail}' and Booking_Start ge datetime'${start.toUTC().toISO()}' and Booking_End lt datetime'${end.toUTC().toISO()}' and Booking_Status ne 'Cancelled'`);
            const response = await axios.get<IRawPagedResponse<Booking[]>>(`${apis.allApiv2}${query.nodeId}/Bookings?${queryString}`);
            
            response.data.Value.forEach(i =>
            {
                i.Booking_Start = DateTime.fromISO(i.Booking_Start as unknown as string);
                i.Booking_End = DateTime.fromISO(i.Booking_End as unknown as string);
                i.Booking_Early_Checkin = DateTime.fromISO(i.Booking_Early_Checkin as unknown as string);
                i._CreatedAt = DateTime.fromISO(i._CreatedAt as unknown as string);
            });
            
            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetMyV2BookingsForOthersEndpoint
{
    execute(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<Booking[]>>;
}

export class Booking
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Early_Checkin = DateHelper.null();
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public Booking_IsCheckedIn = 0;
    public _CreatedAt = DateHelper.null()
    public _CreatedBy = "";
    public Booking_Series_Id = "";
    public Parent_Booking_Relation_Type =  "";
}
