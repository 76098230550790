import { Component } from 'react';

export default class OverviewIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 27.5C22.6797 27.5 28.5 21.6797 28.5 14.5C28.5 7.3203 22.6797 1.5 15.5 1.5C8.3203 1.5 2.5 7.3203 2.5 14.5C2.5 21.6797 8.3203 27.5 15.5 27.5ZM15.5 29C23.5081 29 30 22.5081 30 14.5C30 6.49187 23.5081 0 15.5 0C7.49187 0 1 6.49187 1 14.5C1 22.5081 7.49187 29 15.5 29Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1926 33.4625C32.9036 33.7593 32.4288 33.7657 32.132 33.4767L23.9768 25.5374C23.68 25.2484 23.6737 24.7736 23.9626 24.4768C24.2515 24.18 24.7264 24.1737 25.0232 24.4626L33.1783 32.4019C33.4751 32.6909 33.4815 33.1657 33.1926 33.4625Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 22C10.8358 22 10.5 21.6642 10.5 21.25L10.5 12.75C10.5 12.3358 10.8358 12 11.25 12C11.6642 12 12 12.3358 12 12.75L12 21.25C12 21.6642 11.6642 22 11.25 22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 22C13.8358 22 13.5 21.6642 13.5 21.25L13.5 6.75C13.5 6.33579 13.8358 6 14.25 6C14.6642 6 15 6.33579 15 6.75L15 21.25C15 21.6642 14.6642 22 14.25 22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 22C16.8358 22 16.5 21.6642 16.5 21.25V9.75C16.5 9.33579 16.8358 9 17.25 9C17.6642 9 18 9.33579 18 9.75V21.25C18 21.6642 17.6642 22 17.25 22Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.25 22C19.8358 22 19.5 21.6642 19.5 21.25V13.75C19.5 13.3358 19.8358 13 20.25 13C20.6642 13 21 13.3358 21 13.75V21.25C21 21.6642 20.6642 22 20.25 22Z" />
            </svg>
        )
    }
};
