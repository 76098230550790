import * as React from 'react';
import { FormControl } from '@mui/material';
import { FormControlProps } from '@mui/material/FormControl';

class IbssFormControl extends React.Component<FormControlProps>{
    render(){
        return <FormControl {...this.props}/>
    }
}

export default IbssFormControl;