import { Component } from 'react';
export default class AssignSpacesIcon extends Component {
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70585 0.926471C2.17083 0.926471 0.926442 2.17086 0.926442 3.70588C0.926442 5.24091 2.17083 6.48529 3.70585 6.48529C5.24088 6.48529 6.48527 5.24091 6.48527 3.70588C6.48527 2.17086 5.24088 0.926471 3.70585 0.926471ZM0.132324 3.70588C0.132324 1.73228 1.73225 0.132353 3.70585 0.132353C5.67946 0.132353 7.27938 1.73228 7.27938 3.70588C7.27938 5.67949 5.67946 7.27941 3.70585 7.27941C1.73225 7.27941 0.132324 5.67949 0.132324 3.70588Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70565 1.72059C3.92494 1.72059 4.10271 1.89836 4.10271 2.11765V3.69638L5.01867 4.45968C5.18713 4.60006 5.20989 4.85043 5.0695 5.0189C4.92912 5.18736 4.67875 5.21012 4.51029 5.06974L3.45146 4.18738C3.36094 4.11194 3.30859 4.00019 3.30859 3.88235V2.11765C3.30859 1.89836 3.48636 1.72059 3.70565 1.72059Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1175 7.27942C11.3368 7.27942 11.5145 7.45719 11.5145 7.67648L11.5145 10.3235C11.5145 10.5428 11.3368 10.7206 11.1175 10.7206C10.8982 10.7206 10.7204 10.5428 10.7204 10.3235L10.7204 8.63506L7.95705 11.3984C7.80199 11.5535 7.55059 11.5535 7.39553 11.3984C7.24047 11.2433 7.24047 10.9919 7.39553 10.8369L10.1589 8.07354L8.47041 8.07353C8.25112 8.07353 8.07335 7.89576 8.07335 7.67647C8.07335 7.45719 8.25112 7.27942 8.47041 7.27942L11.1175 7.27942Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.132324 12.1765C0.132324 11.6648 0.547119 11.25 1.05879 11.25H5.29409C5.80576 11.25 6.22056 11.6648 6.22056 12.1765V16.4118C6.22056 17.2158 5.56874 17.8676 4.76468 17.8676H1.58821C0.784145 17.8676 0.132324 17.2158 0.132324 16.4118V12.1765ZM1.05879 12.0441C0.985698 12.0441 0.926442 12.1034 0.926442 12.1765V16.4118C0.926442 16.7772 1.22272 17.0735 1.58821 17.0735H4.76468C5.13016 17.0735 5.42644 16.7772 5.42644 16.4118V12.1765C5.42644 12.1034 5.36719 12.0441 5.29409 12.0441H1.05879Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7793 1.05882C11.7793 0.547148 12.1941 0.132353 12.7058 0.132353H16.9411C17.4527 0.132353 17.8675 0.547148 17.8675 1.05882V5.29412C17.8675 6.09818 17.2157 6.75 16.4117 6.75H13.2352C12.4311 6.75 11.7793 6.09818 11.7793 5.29412V1.05882ZM12.7058 0.926471C12.6327 0.926471 12.5734 0.985727 12.5734 1.05882V5.29412C12.5734 5.6596 12.8697 5.95588 13.2352 5.95588H16.4117C16.7771 5.95588 17.0734 5.6596 17.0734 5.29412V1.05882C17.0734 0.985727 17.0142 0.926471 16.9411 0.926471H12.7058Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.926442 13.5V14.8235C0.926442 15.189 1.22272 15.4853 1.58821 15.4853H4.76468C5.13016 15.4853 5.42644 15.189 5.42644 14.8235V13.5H6.22056V14.8235C6.22056 15.6276 5.56874 16.2794 4.76468 16.2794H1.58821C0.784145 16.2794 0.132324 15.6276 0.132324 14.8235V13.5H0.926442Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5734 2.38235V3.70588C12.5734 4.07136 12.8697 4.36765 13.2352 4.36765H16.4117C16.7771 4.36765 17.0734 4.07136 17.0734 3.70588V2.38235H17.8675V3.70588C17.8675 4.50994 17.2157 5.16176 16.4117 5.16176H13.2352C12.4311 5.16176 11.7793 4.50994 11.7793 3.70588V2.38235H12.5734Z" fill="inherit" />
            </svg>
        )
    }
};

