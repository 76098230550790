import { Component, createRef, ReactNode } from 'react';

class ClickedOutsideBox extends Component<IProps, IState>
{
    private boxRef: React.RefObject<HTMLDivElement>;
    
    constructor(props: IProps)
    {
        super(props);
        this.boxRef = createRef<HTMLDivElement>()
        this.state =
        {
        };
    }

    public componentDidMount(): void
    {
        document.addEventListener('click', this.handleClickeOutside.bind(this), true);
    }

    public componentWillUnmount(): void 
    {
        document.removeEventListener('click', ()=> this.handleClickeOutside.bind(this), true);
    }

    private handleClickeOutside(event: MouseEvent): void
    {
        if(this.boxRef.current && ! this.boxRef.current.contains(event.target as Node))
        {
            this.props.onClickOutside();
        }
    }

    public render(): JSX.Element
    {
        return (
            <div ref={this.boxRef}>{this.props.children}</div>
        )
    }
}

export default ClickedOutsideBox;

export interface IProps
{
    children: ReactNode;
    onClickOutside: () => void;
}

export interface IState
{
}