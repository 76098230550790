import * as GetDataApiVersion from "./GetDataApiVersion";
import * as GetDataEntryApiVersion from "./GetDataEntryApiVersion";
import * as GetIbssApiVersion from "./GetIbssApiVersion";
import * as GetUserApiVersion from "./GetUserApiVersion";

export class VersionInfoRepository implements IVersionInfoRepository
{
    private _getDataApiVersion: GetDataApiVersion.IGetDataApiVersion;
    private _getDataEntryApiVersion: GetDataEntryApiVersion.IGetDataEntryApiVersion;
    private _getIbssApiVersion: GetIbssApiVersion.IGetIbssApiVersion;
    private _getUserApiVersion: GetUserApiVersion.IGetUserApiVersion;

    constructor(
         getDataApiVersion: GetDataApiVersion.IGetDataApiVersion,
         getDataEntryApiVersion: GetDataEntryApiVersion.IGetDataEntryApiVersion,
         getIbssApiVersion: GetIbssApiVersion.IGetIbssApiVersion,
         getUserApiVersion: GetUserApiVersion.IGetUserApiVersion,
    )
    {
        this._getDataApiVersion = getDataApiVersion;
        this._getDataEntryApiVersion = getDataEntryApiVersion;
        this._getIbssApiVersion = getIbssApiVersion;
        this._getUserApiVersion = getUserApiVersion;
    }
    public getDataApiVersion(): Promise<GetDataApiVersion.IVersion>
    {
        return this._getDataApiVersion.execute();
    }

    public getDataEntryApiVersion(): Promise<GetDataEntryApiVersion.IVersion>
    {
        return this._getDataEntryApiVersion.execute();
    }

    public getIbssApiVersion(): Promise<GetIbssApiVersion.IVersion>
    {
        return this._getIbssApiVersion.execute();
    }

    public getUserApiVersion(): Promise<GetUserApiVersion.IVersion>
    {
        return this._getUserApiVersion.execute();
    }
}
export interface IVersionInfoRepository 
{
    getDataApiVersion(): Promise<GetDataApiVersion.IVersion>;
    getDataEntryApiVersion(): Promise<GetDataEntryApiVersion.IVersion>;
    getIbssApiVersion(): Promise<GetIbssApiVersion.IVersion>;
    getUserApiVersion(): Promise<GetUserApiVersion.IVersion>;
}
