import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringOrder } from "./CateringOrderRepository";

export class UpdateCateringOrdersEndpoint implements IUpdateCateringOrdersEndpoint
{
    public async execute(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>
    {
        try
        {
            const response = await axios.put(apis.allApiv2 + nodeId + '/catering-order/' + taskId + '/order-items', payload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateCateringOrdersEndpoint
{
    execute(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>;
}
