import { Component } from 'react';

export default class UserIcon extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91211 30.6502C2.91211 24.5316 7.36648 19.4119 13.0514 19.4119H21.7774C27.5 19.4119 32.0265 24.6124 32.0298 30.7728V30.8504C32.0298 31.9517 31.2117 33.0001 30.0196 33.0001H4.92225C3.7249 33.0001 2.91211 31.9459 2.91211 30.8504V30.6502ZM13.0514 20.8175C8.32675 20.8175 4.34412 25.1306 4.34412 30.6502V30.8504C4.34412 31.3493 4.68726 31.5944 4.92225 31.5944H30.0196C30.2536 31.5944 30.5977 31.3505 30.5977 30.8504V30.7735C30.5948 25.2114 26.5397 20.8175 21.7774 20.8175H13.0514Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.73438 8.74311C8.73438 3.90406 12.6544 0 17.4697 0C22.2895 0 26.205 3.918 26.205 8.7525C26.205 13.5831 22.2934 17.4706 17.4852 17.4706H17.4572C12.6512 17.4706 8.73776 13.5728 8.73438 8.74361L8.73438 8.74311ZM10.1509 8.74286C10.1539 12.7897 13.433 16.0541 17.4572 16.0541H17.4852C21.5137 16.0541 24.7884 12.7981 24.7884 8.7525C24.7884 4.6983 21.5051 1.41653 17.4697 1.41653C13.436 1.41653 10.151 4.68709 10.1509 8.74286Z" />
            </svg>
        )
    }
};
