import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteBookingPartyEndPoint implements IDeleteBookingPartyEndPoint
{
    public async execute(nodeId: number, recordId: string): Promise<void>
    {
        try
        {
            await axios.delete(apis.allApi + nodeId + "/BookingParties/" + recordId);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }

    }
}

export interface IDeleteBookingPartyEndPoint
{
    execute(nodeId: number, recordId: string): Promise<void>
}