import  { Component } from 'react';

export default class Toilets extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 2.75C24.8978 2.75 24.0577 3.59972 24.0577 4.58333C24.0577 5.56695 24.8978 6.41667 26 6.41667C27.1022 6.41667 27.9423 5.56695 27.9423 4.58333C27.9423 3.59972 27.1022 2.75 26 2.75ZM22.5577 4.58333C22.5577 2.71348 24.1284 1.25 26 1.25C27.8716 1.25 29.4423 2.71348 29.4423 4.58333C29.4423 6.45319 27.8716 7.91667 26 7.91667C24.1284 7.91667 22.5577 6.45319 22.5577 4.58333Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1562 12.6307C23.5626 12.7103 23.8277 13.1043 23.7481 13.5108L21.9181 22.8569L21.913 22.8769C21.8903 22.9663 21.9108 23.0609 21.9696 23.1382C22.0283 23.2153 22.106 23.2514 22.182 23.2514H24.0345L24.758 31.9874V32.0177C24.7581 32.0186 24.7593 32.0322 24.7715 32.0579C24.7857 32.0879 24.8104 32.1242 24.8458 32.1591C24.9262 32.2384 24.9951 32.2501 25.004 32.2501H26.996C27.0049 32.2501 27.0739 32.2384 27.1543 32.1591C27.1897 32.1242 27.2143 32.0879 27.2286 32.0579C27.2407 32.0322 27.2419 32.0186 27.242 32.0177V31.9874L27.9656 23.2514H29.818C29.894 23.2514 29.9718 23.2153 30.0304 23.1382C30.0892 23.0609 30.1097 22.9663 30.087 22.8769L30.082 22.8569L28.252 13.5108C28.1724 13.1043 28.4374 12.7103 28.8439 12.6307C29.2504 12.5511 29.6444 12.8161 29.724 13.2226L31.5466 22.5309C31.8115 23.635 30.9804 24.7514 29.818 24.7514H29.3465L28.7416 32.0547C28.7301 32.5326 28.4925 32.9463 28.2072 33.2274C27.9142 33.5163 27.4851 33.7501 26.996 33.7501H25.004C24.5149 33.7501 24.0859 33.5163 23.7928 33.2274C23.5076 32.9463 23.27 32.5326 23.2585 32.0547L22.6536 24.7514H22.182C21.0196 24.7514 20.1885 23.635 20.4535 22.5309L22.276 13.2226C22.3556 12.8161 22.7497 12.5511 23.1562 12.6307Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1527 11.1561C20.6225 9.67315 22.2053 8.48328 23.9837 8.48328H28.0163C29.7476 8.48328 31.3805 9.47017 31.8551 11.1823L31.8589 11.1963L33.6995 18.999L33.7028 19.017C33.9455 20.3308 33.2409 21.6381 31.8848 22.1299L31.8179 22.1902C31.5104 22.4676 31.0361 22.4432 30.7587 22.1356C30.4813 21.828 30.5057 21.3538 30.8133 21.0764L31.1216 20.7982L31.283 20.7497C32.0094 20.5313 32.336 19.9168 32.2306 19.3056L30.4059 11.5701C30.1423 10.6436 29.2122 9.98328 28.0163 9.98328H23.9837C22.8407 9.98328 21.8653 10.762 21.5906 11.5848L19.7694 19.3056C19.6641 19.9168 19.9906 20.5313 20.7171 20.7497L20.8784 20.7982L21.1868 21.0764C21.4943 21.3538 21.5188 21.828 21.2413 22.1356C20.9639 22.4432 20.4896 22.4676 20.1821 22.1902L20.1152 22.1299C18.7591 21.6381 18.0545 20.3308 18.2972 19.017L18.3006 18.999L20.1442 11.183L20.1527 11.1561Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.92498 12.6204C3.33711 12.579 3.7048 12.8795 3.74623 13.2916L5.625 31.9807V32.0069C5.62686 32.0103 5.64032 32.0589 5.71966 32.1281C5.81733 32.2133 5.92882 32.25 6 32.25H9.1875C9.25867 32.25 9.37016 32.2133 9.46783 32.1281C9.54717 32.0589 9.56063 32.0103 9.56249 32.0069V31.9844L11.253 13.2991C11.2904 12.8865 11.655 12.5824 12.0676 12.6197C12.4801 12.657 12.7843 13.0217 12.7469 13.4342L11.0619 32.0584C11.0477 32.5681 10.7631 32.9886 10.454 33.2583C10.1298 33.5412 9.67882 33.75 9.1875 33.75H6C5.50867 33.75 5.05766 33.5412 4.73345 33.2583C4.42531 32.9894 4.14147 32.5705 4.12569 32.0628L2.25376 13.4417C2.21233 13.0295 2.51284 12.6618 2.92498 12.6204Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.22716 8.48328H10.7424C13.0862 8.48328 14.9599 10.2376 14.7316 12.523L14.1942 18.9295C14.1779 20.2535 13.2114 21.2431 12.421 21.7731L12.3904 21.7936L11.4572 22.2942L10.7481 20.9724L11.6148 20.5075C12.2436 20.077 12.6944 19.4781 12.6944 18.8956V18.8642L13.2385 12.3782C13.366 11.1241 12.3591 9.98328 10.7424 9.98328H4.28934C2.60141 10.133 1.64295 11.3194 1.76054 12.3707L1.7617 12.381L2.30559 18.8642V18.8956C2.30559 19.4656 2.74618 20.0681 3.53131 20.4892L4.43197 20.9724L3.72294 22.2942L2.82227 21.8111C1.8145 21.2705 0.822204 20.2748 0.805796 18.9295L0.26863 12.5265C0.0343056 10.3682 1.94366 8.66886 4.19684 8.48574L4.22716 8.48328Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.75C6.55649 2.75 5.75 3.54744 5.75 4.58333C5.75 5.61923 6.55649 6.41667 7.5 6.41667C8.44351 6.41667 9.25 5.61923 9.25 4.58333C9.25 3.54744 8.44351 2.75 7.5 2.75ZM4.25 4.58333C4.25 2.76575 5.68208 1.25 7.5 1.25C9.31792 1.25 10.75 2.76575 10.75 4.58333C10.75 6.40091 9.31792 7.91667 7.5 7.91667C5.68208 7.91667 4.25 6.40091 4.25 4.58333Z" />
            </svg>
        )
    }
};