import React, { Suspense, Component } from "react";
import Routes from "./routes";
import { Helmet } from "react-helmet";
import '../src/styles/css/bootstrapmodal.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './azure-authentication-config';
import { MsalProvider } from "@azure/msal-react";
import '../src/styles/css/mdbreact/dist/scss/style.scss';
import { appContext } from "./AppContext";
import apis from "./Providers.Api/apis";
import { connect, DispatchProp } from "react-redux";
import { RootState } from "./app/store";
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import customTheme from "./customTheme";
import { MY_NEW_NOTIFICATION, MY_NOTIFICATION_LIST } from "./app/constants";
import { IDispatch, IPropsFromState } from "./redux/Interfaces";
import { IUserPreferences } from "./Providers.Api/UserPreferences/UserPreferenceRepository";
import { IRootNode } from "./Providers.Api/Models";
import { IbssComponent } from "./Components/Core/BaseComponent/IbssComponent";
import { LogOutReason } from "./Providers.Api/Authentication";

interface IState 
{
    ready: boolean,
    theme: Theme,
}

interface IProps
{
    lightModeTheme: boolean,
    getNotificationList: (payload: any) => any,
    setNewNotification: (payload: any) => any,
}

class App extends IbssComponent<IProps, IState>
{
    private msalInstance: PublicClientApplication;
    private appContext = appContext();
    private get apiClient() { return appContext().apiClient; };
    private get session() { return appContext().sessionStorageProvider; };
    private get local() { return appContext().localStorageProvider; }
    private userPreferences: IUserPreferences | null = null;
    private nodeData: IRootNode | null = null;

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            ready: false,
            theme: createTheme(customTheme.getDesignTokens(this.props.lightModeTheme ? 'light' : 'dark' as PaletteMode)),
        };
        this.msalInstance = new PublicClientApplication(msalConfig);
    }

    private notificationTimerId: NodeJS.Timeout | null = null;

    public async componentDidMount(): Promise<void>
    {
        this.updateTheme();
        await this.createApplicationContext()
        const expectedVersion = this.local.getHubVersion().split('.').slice(0, 2).join('.');
        const actualVersion = appContext().settingsProvider.getSettings().internalVersion.split('.').slice(0, 2).join('.');

        if (expectedVersion != actualVersion)
        {
            this.local.setHubVersion(actualVersion);
            appContext().authentication.logOut(LogOutReason.VersionChanged);
            return;
        }

        this.userPreferences = this.local.getUserPreferences();
        this.nodeData = this.local.getNodeData();

        await this.setDefaultBuilding();
        await this.setStateAsync({ ready: true });
        const token = appContext().localStorageProvider.getToken();

        if (token)
        {
            this.getNotification();
            this.notificationTimerId = setInterval(() => this.getNotification(), 120000);
        }
    }

    public componentWillUnmount(): void
    {
        clearInterval(this.notificationTimerId ?? undefined);
    }

    private async getNotification(): Promise<void>
    {
        try
        {
            const { notificationList }: any = this.props;
            const response = await this.apiClient.notifications.getActiveNotification(this.session.getBuildingId());
            if (notificationList.length < response.length)
            {
                this.props.setNewNotification(true);
            }
            this.props.getNotificationList(response);
        }
        catch (error)
        {
            console.log(error);
        }
    }

    private async createApplicationContext(): Promise<void>
    {
        // register helpers
        this.appContext.useDefaultCache();

        // register providers
        this.appContext.useDefaultLocalStorageProvider();
        this.appContext.useDefaultSessionStorageProvider();
        this.appContext.useDefaultSettingsProvider();
        this.appContext.useDefaultApiCache();
        this.appContext.useDefaultApiClient();
        this.appContext.useDefaultUserApiClientV1();
        this.appContext.useDefaultWebEntryApiClientV1();
        this.appContext.useDefaultWebEntryApiClientV2();
        this.appContext.useDefaultIbssApiClientV1();
        this.appContext.useDefaultIbssApiClientV2();
        this.appContext.useDefaultMsalProvider();
        await this.appContext.useDefaultLabels();
        await this.appContext.useDefaultApiMessages();

        // register servives
        this.appContext.useDefaultServices();
    }

    private setDefaultBuilding(): Promise<void>
    {
        // set default building Id and building name
        if (this.nodeData?.Regions && this.userPreferences?.SearchPrefs?.DefaultBuilding)
        {
            const buildingName = this.nodeData.Regions.flatMap(region => region.Buildings).find(building => building.Node_Id === this.userPreferences?.SearchPrefs?.DefaultBuilding ?? undefined)?.Name;

            return this.appContext.state.set({
                buildingId: this.userPreferences.SearchPrefs.DefaultBuilding,
                buildingName: buildingName,
            });
        }
        else
        {
            // else user has not yet authenticated so there is no userPreferences or local storage.
            return new Promise(resolve => resolve());
        }
    }

    // use redux's lightModeTheme value to switch between custom Ibss themes for lightMode and darkMode, built with MUI theme.
    public getCustomTheme(mode: PaletteMode): Theme
    {
        return createTheme(customTheme.getDesignTokens(mode))
    }

    private updateTheme(): void
    {
        const theme: PaletteMode = (this.props.lightModeTheme ? "light" : "dark");
        document.documentElement.setAttribute("data-theme", theme);
        this.appContext.state.set({ lightModeTheme: this.props.lightModeTheme });
        this.setState({ theme: this.getCustomTheme(theme) });
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if (this.props.lightModeTheme !== prevProps.lightModeTheme)
        {
            this.updateTheme();
        }
    }

    public render(): JSX.Element
    {
        let REACT_APP_VERSION = "";
        try
        {
            REACT_APP_VERSION = (process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "");
        }
        catch (error)
        {
            REACT_APP_VERSION = "";
        }
        // This flag is true if it encounters a url passed back to the AAD popup login, after sucessful authentication. - e.g. /auth#code=xyz
        // It's used to avoid the race condition, which is between the React Router stripping the url of the hash, and the window that opened the popup grabbing the hash from url to complete login.
        const AADLoginPopup = window.location.href.includes('/auth#') && window.location.hash !== "";

        return (
            <>
                <Suspense fallback={(<div>Loading</div>)}>
                    <Helmet>
                        <meta name="version" content={REACT_APP_VERSION} />
                    </Helmet>
                    {this.state.ready && (
                        <ThemeProvider theme={this.state.theme}>
                            <MsalProvider instance={this.msalInstance}>
                                {!AADLoginPopup && <Routes />}
                                {AADLoginPopup && <></>}
                            </MsalProvider>
                        </ThemeProvider>
                    )}
                </Suspense>
            </>
        );
    }
}

const mapStateToProps = (state: RootState): IPropsFromState =>
{
    return {
        notificationList: state.notificationList,
        notificationReadedList: state.notificationReadedList,
        lightModeTheme: state.lightModeTheme,
    };
};

const mapDispatchToProps = (dispatch: any): IDispatch =>
{

    return {
        getNotificationList: (payload: any) => dispatch({ type: MY_NOTIFICATION_LIST, payload: payload }),
        setNewNotification: (payload: any) => dispatch({ type: MY_NEW_NOTIFICATION, payload: payload }),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
