import{ Component } from 'react';

export default class Benchseating extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 30C0.25 29.5858 0.585786 29.25 1 29.25H33C33.4142 29.25 33.75 29.5858 33.75 30C33.75 30.4142 33.4142 30.75 33 30.75H1C0.585786 30.75 0.25 30.4142 0.25 30Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 22C0.25 19.9289 1.92893 18.25 4 18.25H30C32.0711 18.25 33.75 19.9289 33.75 22C33.75 24.0711 32.0711 25.75 30 25.75H4C1.92893 25.75 0.25 24.0711 0.25 22ZM4 19.75C2.75736 19.75 1.75 20.7574 1.75 22C1.75 23.2426 2.75736 24.25 4 24.25H30C31.2426 24.25 32.25 23.2426 32.25 22C32.25 20.7574 31.2426 19.75 30 19.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 8C2.25 6.48122 3.48122 5.25 5 5.25H15C16.5188 5.25 17.75 6.48122 17.75 8V17C17.75 18.5188 16.5188 19.75 15 19.75H5C3.48122 19.75 2.25 18.5188 2.25 17V8ZM5 6.75C4.30964 6.75 3.75 7.30964 3.75 8V17C3.75 17.6904 4.30964 18.25 5 18.25H15C15.6904 18.25 16.25 17.6904 16.25 17V8C16.25 7.30964 15.6904 6.75 15 6.75H5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 8C16.25 6.48122 17.4812 5.25 19 5.25H29C30.5188 5.25 31.75 6.48122 31.75 8V17C31.75 18.5188 30.5188 19.75 29 19.75H19C17.4812 19.75 16.25 18.5188 16.25 17V8ZM19 6.75C18.3096 6.75 17.75 7.30964 17.75 8V17C17.75 17.6904 18.3096 18.25 19 18.25H29C29.6904 18.25 30.25 17.6904 30.25 17V8C30.25 7.30964 29.6904 6.75 29 6.75H19Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 30L3.25 25L4.75 25L4.75 30L3.25 30Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.25 30L29.25 25L30.75 25L30.75 30L29.25 30Z" />
            </svg>
        )
    }
};