import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { ButtonGroup, Box, Typography }from '@mui/material';
import { appContext } from '../../../../AppContext';
import Drawer from 'react-modern-drawer'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Note, Visit } from './DataModels';
import { PagedResponse } from '../../../../Providers.Api/Models';
import "./EditBookingDrawer.scss"
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssAccordion from '../../../../Components/Miscellaneous/Accordion/IbssAccordion';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IbssLink from '../../../../Components/Navigation/Link/IbssLink';
import { DateTime } from 'luxon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BookingParties, Space } from './DataModels';
import Notes, { INote } from '../../../../Components/Lists/Notes/Notes';
import { EventType } from '../../../../Providers.Api/EventTypes';
import BookingTagPicker from '../../../../Components/Lists/Tags/BookingTagPicker';
import { TagToEventTypeView } from './ViewBookingsManagedSchedule';
import { ApiConstants } from '../../../../Providers.Api/ApiConstants';
import BookingStatusChip from './BookingStatusChip';
import { DateHelper } from '../../../../Common/DateHelper';
import { INode } from '../../../../Providers.Api/Models';
import Helper from '../../../../Common/Helper';
import BookingSummary from './BookingSummary';

class EditBookingDrawer extends IbssComponent<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get apiClient() { return appContext().apiClient }
    private get labels() { return appContext().labels; }
    private get visitsService() { return appContext().visitsService; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            attendees: [],
            bookingNotes: [],
            buildingVisitorArrivalWindow: 0,
            expandedAttendees: false,
            expandedBookingSummary: false,
            expandedBookingNotes: false,
            expandedVisitors: false,
            isLoading: false,
            notes: [],
            selectedSpaces: [],
            visitors: [], // but state.visitors from bookingParty, used to work out whether to make visits api call to populate state.visitorsDetaisl.
            visitorsDetails: [], // need this state because checkIn statuses from bookingParties visitors does not reflect visitor checkinStatus,
        };
    }

    
    public async componentDidMount(): Promise<void>
    {
        await this.refreshData();
        this.setBuildingVisitorArrivalWindow();    
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void> 
    {
        if(prevProps.bookingId !== this.props.bookingId)
        {
            this.clearBookingParty();
            await this.refreshData();
            return;
        }

        if(prevProps.buildingId !== this.props.buildingId)
        {
            if(this.props.buildingId !== 0)
            {
                this.setBuildingVisitorArrivalWindow();
            }
            return;
        }

        if(prevProps.open !== this.props.open)
        {
            if(this.props.open)
            {
                await this.refreshData();
            }
            return;
        }
    }

    private async refreshData(): Promise<void>
    {
        await this.loadNotes(this.props.nodeId,this.props.bookingId);
        await this.getBookedParties(this.props.bookingId);
        await this.getVisitorCheckinStatus(this.props.bookingId);
        await this.getSelectedSpaces();
    }

    private clearBookingParty()
    {
        this.setState({ attendees:[], visitors: [], visitorsDetails: []});
    }

    private async loadNotes(nodeId: number, bookingid: string): Promise<void>
    {
        this.setState({isLoading: true});
        const response = await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.notes.get<PagedResponse<Note[]>>
        ({
            nodeId: nodeId,
            bookingid: bookingid, 
            filter: `EventType_Id eq ${EventType.bookings.id} and Event_Id eq '${bookingid}' and IsDeleted eq 0`,
            select: Note,
            top: ApiConstants.largeODataTop,
        });
        
        const notes: INote[] = response.value.map(i => ({
            isNew: false,
            id: i.Note_Id,
            value: i.Note,
            author: i.CreatedBy,
            time: i.CreatedAt,
          }));
        this.setState
        ({
            notes: notes,
            isLoading: false,
        });
    }

    private async checkinBookingClicked(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            const spaceId = this.props.spaceId.split(';')[0];
            await this.apiClient.spaces.checkIntoSpace(this.props.buildingId, spaceId, this.props.bookingOwnerEmail);
            this.setState({ isLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckInSuccessfully);
            this.props.refreshSpacesAndBookings();
            this.props.toggleDrawer(false);
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private editBookingClicked(): void
    {
        this.props.history.push(`/operational-services-bookings/${this.props.buildingId}/bookings/${this.props.bookingId}/${this.props.spaceId}`);
    }

    private async getBookedParties(bookingId: string): Promise<void>
    {
        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.bookingparties.get<PagedResponse<BookingParties[]>>({
                nodeId: this.props.nodeId,
                select: BookingParties,
                filter: `Booking_Id eq ${bookingId}`, 
            });
            
            const bookingParticipants = response.value;

            const bookingPartyList = bookingParticipants?.filter(item => !!(parseInt(item.Booking_Participant_Status)) && item.Booking_Participant_Type !== 4 && item.Booking_Participant_Type !== 3).map(i => new IAttendee(i)) ?? [];

            await this.setStateAsync({
                attendees: bookingPartyList.filter(i => i.visitor === false),
                visitors: bookingPartyList.filter(i => i.visitor === true),
            });
        }
        catch (error)
        {
            return;
        }
    }

    private async getVisitorCheckinStatus(bookingId: string): Promise<void>
    {
        if(this.state.visitors.length > 0)
        {
            // do additional check to get actual visitor checkin status.
            try
            {
                const response = await appContext().ibssApiClientV2.v2.byNodeid.visits.get<PagedResponse<Visit[]>>({
                    nodeId: this.props.buildingId,
                    select: Visit,
                    filter: `Booking_Id eq ${bookingId}`,
                });

                const visitorsDetails = response.value.map(visit => VisitorDetailView.fromVisit(visit));
                this.setState({visitorsDetails: visitorsDetails});
            }
            catch
            {
                return;
            }
        }
    }

    private async checkInVisitorClicked(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, bookingId: string, buildingId: number, visitID: string): Promise<void>
    {
        event.stopPropagation();
        this.setState({ isLoading: true });

        try
        {
            await this.visitsService.checkin(buildingId, [visitID])
            await this.getVisitorCheckinStatus(bookingId);
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async checkOutVisitorClicked(event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ,bookingId: string, buildingId: number, visitID: string): Promise<void>
    {
        event.stopPropagation();
        this.setState({ isLoading: true });

        try
        {
            await this.visitsService.checkout(buildingId, [visitID])
            await this.getVisitorCheckinStatus(bookingId);
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private getInitials(firstName: string, lastName: string): string
    {
        const firstNameInitial = firstName?.charAt(0).toUpperCase();
        const lastNameInitial = lastName?.charAt(0).toUpperCase();
        const firstLetters = firstNameInitial + lastNameInitial;
        return firstLetters;
    }

    private async createNote(note: INote): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            await appContext().ibssApiClientV2.v2.byNodeid.notes.post({
                nodeId: this.props.nodeId,
                body: {
                    Node_Id: this.props.nodeId,
                    EventType_Id: EventType.bookings.id,
                    EventType_Name: EventType.bookings.name,
                    Event_Id: this.props.bookingId,
                    Note: note.value,
                    IsDeleted: 0
                },
            });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async updateNote(note: INote): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            await appContext().ibssApiClientV2.v2.byNodeid.notes.byNoteid.put({
                nodeId: this.props.nodeId,
                noteid: note.id,
                body: {
                    Node_Id: this.props.nodeId,
                    Note_Id: note.id,
                    EventType_Id: EventType.bookings.id,
                    EventType_Name: EventType.bookings.name,
                    Event_Id: this.props.bookingId,
                    Note: note.value,
                    IsDeleted: 0,
                },
            });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }



    private async deleteNote(note: INote): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            await appContext().ibssApiClientV2.v2.byNodeid.notes.byNoteid.cancel.patch({
                nodeId:  this.props.nodeId,
                noteid: note.id,
                body: {
                    IsDeleted: 1
                },
            });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async getSelectedSpaces(): Promise<void>
    {
        // clear existing state
        this.setState({selectedSpaces: []});

        // this call is needed because space status isn't populated in space cache.
        const bookingSpaceId = this.props.spaceId;
        // split bookingSpaceId which can contain 'space_1;space2' spaceId strings.
        const spaceIds = bookingSpaceId.split(';');
        const filterQuery = spaceIds.map(spaceId => `Space_Id eq '${spaceId}'`).join(` or `);

        try
        {
            this.setState({ isLoading: true});
            const response = await appContext().ibssApiClientV2.v2.byNodeid.spaces.get<PagedResponse<Space[]>>
            ({
                nodeId: this.props.nodeId,
                select: Space,
                top: 10,
                filter: filterQuery,
            })

            const spaces = response.value.map(space => SpaceView.fromSpace(space));
            this.setState({selectedSpaces: spaces});
        }
        finally
        {
            this.setState({ isLoading: false});
        }        
    }

    private get selectedSpaceStatusIsValid(): boolean
    {
        const isValid = this.state.selectedSpaces.find(space => (space.spaceStatus === "S28" || space.spaceStatus === "S38" || space.spaceStatus === "S39"));
        return !!isValid;
    }

    private get checkInEnabled(): boolean
    {
        const invalidCheckinBookingStatus = (this.props.bookingStatus === "Early Check In" || this.props.bookingStatus === "In Progress" || this.props.bookingStatus === "Completed" || this.props.bookingStatus === "Cancelled" || this.props.bookingStatus === "No Show" || this.props.bookingStatus === "Auto Cancelled" || this.props.bookingStatus === "Checked In");
        const checkInEnabled = (
            this.selectedSpaceStatusIsValid &&
            this.props.bookingEarlyCheckIn < DateHelper.fromIsoByNode(DateTime.utc().toISO(), this.props.nodeId) &&
            this.props.bookingEnd > DateHelper.fromIsoByNode(DateTime.utc().toISO(), this.props.nodeId) && 
            !invalidCheckinBookingStatus  // Disable if the booking is in progress or completed
        );

        return checkInEnabled;
    }

    private visitorCheckinIsValid(visitor: VisitorDetailView): boolean
    {
        const bookingEarlyVisitorCheckInTime = this.props.bookingStart.minus({minutes: this.state.buildingVisitorArrivalWindow });
        const buildingNow = DateHelper.fromIsoByNode(DateTime.utc().toISO(), this.props.nodeId);

        const checkinIsAfterEarlyCheckin = buildingNow > bookingEarlyVisitorCheckInTime;
        const checkinIsBeforeBookingEnd = buildingNow < this.props.bookingEnd;

        return (visitor.status === 'Approved' && !visitor.isCheckedIn && !visitor.isCheckedOut && checkinIsAfterEarlyCheckin && checkinIsBeforeBookingEnd);
    }

    private visitorCheckOutIsValid(visitor: VisitorDetailView): boolean
    {
        return (visitor.isApproved && visitor.isCheckedIn && !visitor.isCheckedOut);
    }

    private setBuildingVisitorArrivalWindow(): void
    {
        const building = Helper.getAllBuildingsData().find(building => building.Node_Id == this.props.buildingId) as INode;
        this.setState({buildingVisitorArrivalWindow: building.Vis_Arvl_Wndw_Mins});
    } 

    private get validVisitors(): VisitorDetailView[]
    {
        return this.state.visitorsDetails.filter(visitor => visitor.status !== 'Cancelled');
    }

    public render(): JSX.Element
    {
        const checkedInVisitors = this.validVisitors.filter(visitor => visitor.isCheckedIn).length;
        const disableVisitorAndAttendees = this.props.bookingStatus == "Completed" || this.props.bookingStatus == "Cancelled" || this.props.bookingStatus == "No Show";

        return (
            <Drawer
                direction='right'
                open={this.props.open} 
                onClose={()=> this.props.toggleDrawer(!this.props.open)}
                className={'bookings-dashboard__drawer'}
            >   
                {
                <Box className="m3 ml-5">
                    <div className="mt-4 mr-4" style={{overflow: 'auto'}}>
                        <span className="flexMySearch-filter-criteria-close" onClick={() => this.props.toggleDrawer(false)}>&times;</span>
                    </div>
                </Box> 
                }            
                {<Box className="flexMySearch-filter-criteria ml-5">
                    {/* standard header */}
                    {<Box width="95%">
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography variant="h4" className="pageTitle" padding='24px 0px'>{this.props.bookingName || this.labels.HubLabelBookingName}</Typography>
                            <BookingStatusChip bookingStatus={this.props.bookingStatus} />
                        </Box>
                        <Box>
                            <BookingTagPicker
                                addTag={this.props.addTag}
                                removeTag={this.props.removeTag} 
                                bookingId={this.props.bookingId}
                                buildingId={this.props.buildingId} 
                                nodeId={this.props.nodeId} 
                                selectedBookingTags={this.props.selectedBookingTags}
                            />
                        </Box>
                        {/* Booking Summary */}
                        <IbssAccordion
                            variant='outlined'
                            borderWidth='1px 0px'
                            isExpanded={this.state.expandedBookingSummary}
                            expandMoreClickedHandler={()=>this.setState({expandedBookingSummary: !this.state.expandedBookingSummary})}
                            expandMoreIcon={AddIcon}
                            collapseIcon={RemoveIcon}
                            values={[{
                                key: 'summary-accordion',
                                summary: (<Typography className="pageTitle" variant="h5">{`${this.labels.funcScheduleBookingSummary_S}`}</Typography>),
                                details:(
                                    <BookingSummary 
                                        bookingDescription={this.props.bookingDescription}
                                        bookingOwner={this.props.bookingOwnerName}
                                        bookingEnd={this.props.bookingEnd}
                                        bookingStart={this.props.bookingStart}
                                        spaceName={this.props.spaceName}
                                        spaceLayout={this.props.spaceLayout}
                                    />
                                )
                            }]}
                        />
                        
                        {/* Booking Notes */}
                        <IbssAccordion
                            variant='outlined'
                            borderWidth='1px 0px'
                            isExpanded={this.state.expandedBookingNotes}
                            expandMoreClickedHandler={()=>this.setState({expandedBookingNotes: !this.state.expandedBookingNotes})}
                            expandMoreIcon={AddIcon}
                            collapseIcon={RemoveIcon}
                            values={[{
                                key: 'notes-accordion',
                                summary: (<Typography className="pageTitle" variant="h5">{`${this.labels.funcScheduleBookingNotes_S}`}</Typography>),
                                details: (
                                    <Box>
                                        <Notes
                                            buildingId={this.props.buildingId}
                                            notes={this.state.notes}
                                            onChange={notes => this.setState({notes: notes})}
                                            onCreate={note => this.createNote(note)}
                                            onUpdate={note => this.updateNote(note)}
                                            onDelete={note => this.deleteNote(note)}
                                        />
                                    </Box>
                                ),
                            }]} 
                        />

                        <div id="column1" aria-label="search criteria column">
                            <ButtonGroup sx={{ margin: '2em 0em', gap: '1em'}}>
                                {/* Checkin Booking button */}
                                <IbssButton
                                    variant={'contained'}
                                    size={'large'}
                                    disabled={!this.checkInEnabled}
                                    onClick={()=> this.checkinBookingClicked()}
                                >
                                    <Typography className="bookingDashboard-drawer-checkin-btn">{this.labels.HubButtonCheckIn}</Typography>
                                </IbssButton>
                                {/* Edit Booking button */}
                                <IbssButton
                                    variant={'contained'}
                                    sx={{ backgroundColor: (theme) => theme.palette.grey[400] }}
                                    size={'large'}
                                    onClick={()=> this.editBookingClicked()}
                                >
                                    <Typography className="bookingDashboard-drawer-editBooking-btn">{this.labels.HubLabelEditBooking}</Typography>
                                </IbssButton>
                            </ButtonGroup>

                            {/* Attendees */}
                            {!disableVisitorAndAttendees &&
                                <IbssAccordion
                                    variant='outlined'
                                    borderWidth='1px 0px'
                                    isExpanded={this.state.expandedAttendees}
                                    expandMoreClickedHandler={() => this.setState({ expandedAttendees: !this.state.expandedAttendees })}
                                    expandMoreIcon={ArrowDropDownIcon}
                                    collapseIcon={ArrowDropDownIcon}
                                    values={[{
                                        key: 'attendee-accordion',
                                        summary: (
                                            <Box className='bookingDashboard-bookingParticipant-container'>
                                                <Typography className="pageTitle" variant="h6">{`${this.labels.HubLabelAttendees} (${this.state.attendees.length})`}</Typography>
                                                {
                                                    !this.state.expandedAttendees && <span className="ml-2 mr-2 d-flex">
                                                        {this.state.attendees.map((attendee: IAttendee) => (
                                                            <p
                                                                className="attendee-name circle-labels overlap"
                                                                // non visitor, light blue #2A3F49, visitor - dark blue #2A3F49,
                                                                style={{ backgroundColor: attendee.type === 2 ? '#2A3F49' : '#4893cf' }}
                                                                id={attendee.email}
                                                                key={attendee.email}
                                                            >
                                                                {this.getInitials(attendee.name?.split(' ')[0], attendee.name?.split(' ')[1])}
                                                            </p>
                                                        ))}
                                                    </span>
                                                }
                                            </Box>
                                        ),
                                        details: (
                                            <Box>
                                                {this.state.attendees.map((attendee, index) => <Typography key={index} variant='body1' className="columnSubheading">{`${attendee.name}`}</Typography>)}
                                            </Box>
                                        ),
                                    }]}
                                />
                            }
                            {/* Visitors */}
                            {!disableVisitorAndAttendees &&
                                <IbssAccordion
                                    variant='outlined'
                                    borderWidth='1px 0px'
                                    isExpanded={this.state.expandedVisitors}
                                    expandMoreClickedHandler={() => this.setState({ expandedVisitors: !this.state.expandedVisitors })}
                                    expandMoreIcon={ArrowDropDownIcon}
                                    collapseIcon={ArrowDropDownIcon}
                                    values={[{
                                        key: 'visitor-accordion',
                                        summary: (
                                            <Box className='bookingDashboard-bookingParticipant-container'>
                                                <Typography className="pageTitle" variant="h6">{`${this.labels.funcVisitors_S} (${checkedInVisitors} ${this.labels.HubLabelOf} ${this.validVisitors.length} ${this.labels.HubLabelCheckedIn})`}</Typography>
                                                {
                                                    !this.state.expandedVisitors && <span className="ml-2 mr-2 d-flex">
                                                        {this.validVisitors.map((visitor) => (
                                                            <p
                                                                className="attendee-name circle-labels overlap"
                                                                // non visitor, light blue #2A3F49, visitor - dark blue #2A3F49,
                                                                style={{ backgroundColor: '#4893cf' }}
                                                                id={visitor.visitId}
                                                                key={visitor.visitId}
                                                            >
                                                                {this.getInitials(visitor.firstName, visitor.lastName)}
                                                            </p>
                                                        ))}
                                                    </span>
                                                }
                                            </Box>
                                        ),
                                        details: (
                                            <Box>
                                                {this.validVisitors.map((visitor, index) => 
                                                {
                                                    return (
                                                        <Box className='bookingDashBoard-visitors-container' key={visitor.visitId}>
                                                            <Box className='bookingDashBoard-visitors-container'>
                                                                <CheckCircleIcon color={visitor.isCheckedIn ? 'success' : 'disabled'} />
                                                                <Typography key={index} variant='body1' className="columnSubheading">{`${visitor.firstName} ${visitor.lastName}`}</Typography>
                                                            </Box>
                                                            <Box>
                                                                {this.visitorCheckOutIsValid(visitor) && <IbssLink to='#' onClick={(event) => this.checkOutVisitorClicked(event, this.props.bookingId, this.props.buildingId, visitor.visitId)}>{this.labels.funcCheckOutHypenated_S}</IbssLink>}
                                                                {this.visitorCheckinIsValid(visitor) && <IbssLink to='#' onClick={(event) => this.checkInVisitorClicked(event, this.props.bookingId, this.props.buildingId, visitor.visitId)}>{this.labels.funcCheckInHypenated_S}</IbssLink>}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })
                                                }
                                            </Box>
                                        ),
                                    }]}
                                />
                            }
                        </div>
                    </Box>
                    }
                </Box>
                }
            </Drawer>
        )
    }
}

export default withRouter(EditBookingDrawer);

export interface IProps extends RouteComponentProps
{
    open: boolean, 
    toggleDrawer: (showEditBooking: boolean)=> void,
    bookingId: string,
    bookingEarlyCheckIn: DateTime,
    bookingEnd: DateTime,
    bookingStart: DateTime,
    bookingIsApproved: number,
    bookingName: string,
    bookingOwnerEmail: string,
    bookingOwnerName: string,
    bookingDescription : string,
    bookingStatus: string,
    buildingId: number,
    changeIsLoading: (isLoading: boolean) => void,
    isLoading: boolean,
    nodeId: number,
    selectedBookingTags: TagToEventTypeView[],
    spaceId: string,
    spaceLayout: string,
    spaceName: string,
    addTag: (tag: TagToEventTypeView) => void,
    removeTag: (tagToEventTypeId: string) => void,
    refreshSpacesAndBookings:  () => Promise<void>,
}
export interface IState
{
    attendees: IAttendee[],
    bookingNotes: string[],
    buildingVisitorArrivalWindow: number,
    expandedAttendees: boolean,
    expandedBookingSummary: boolean,
    expandedBookingNotes: boolean,
    expandedVisitors: boolean,
    isLoading: boolean,
    notes: INote[],
    visitors: IAttendee[],
    visitorsDetails: VisitorDetailView[],
    selectedSpaces: SpaceView[],
}

export class IAttendee
{
    bookingStart: DateTime;
    bookingEnd: DateTime;
    bookingParticipantStatus: string;
    bookingEarlyCheckin: string;
    email: string;
    isCheckedIn: boolean;
    name: string;
    organisation: string;
    resourceId: string | null;
    visitId: string;
    visitor: boolean;
    type: number;
    backgroundColor?: string;
    
    constructor(data: BookingParties)
    {
        this.bookingStart = data.Booking_Start;
        this.bookingEnd = data.Booking_End;
        this.bookingParticipantStatus = data.Booking_Participant_Status;
        this.bookingEarlyCheckin = data.Booking_Early_Checkin;
        this.email = data.Booking_Participant_Email;
        this.isCheckedIn = !!data.Booking_Participant_CheckedIn;
        this.name = data.Booking_Participant_Name;
        this.organisation = data.Booking_Participant_Organisation;
        this.resourceId = data.Booking_Resource_Id;
        this.visitId = data.Visit_Id;
        this.visitor = !!data.Booking_Visitor;
        this.type = data.Booking_Participant_Type;
    }
}

class VisitorDetailView
{
    public ownedBy = '';
    public ownedById = '';
    public visitId = '';
    public spaceId = '';
    public bookingId = '';
    public nodeId = 0;
    public spaceName = '';
    public firstName = '';
    public lastName = '';
    public email = '';
    public company = '';
    public hostName = '';
    public hostEmail = '';
    public isCheckedIn = false;
    public isApproved = false;
    public isCancelled = false;
    public isNoShow = false;
    public isCheckedOut = false;
    public isDenied = false;
    public isEarlyCheckin = false;
    public isLateCheckin = false;
    public status = '';


    public static fromVisit(visit: Visit): VisitorDetailView
    {
        return {
            ownedBy: visit.OwnedBy,
            ownedById: visit.OwnedById,
            visitId: visit.Visit_Id,
            spaceId: visit.Space_Id,
            bookingId: visit.Booking_Id,
            nodeId: visit.Node_Id,
            spaceName: visit.Space_Name,
            firstName: visit.Visitor_First_Name,
            lastName: visit.Visitor_Last_Name,
            email: visit.Visitor_Email,
            company: visit.Visitor_Company,
            hostName: visit.Visit_Host_Name,
            hostEmail: visit.Visit_Host_Email,
            isCheckedIn: !!visit.Visit_IsCheckedIn,
            isApproved: !!visit.Visit_IsApproved,
            isCancelled: !!visit.Visit_IsCancelled,
            isNoShow: !!visit.Visit_IsNoShow,
            isCheckedOut: !!visit.Visit_IsCheckedOut,
            isDenied: !!visit.Visit_IsDenied,
            isEarlyCheckin: !!visit.Visit_IsEarly_Checkin,
            isLateCheckin: !!visit.Visit_IsLate_Checkin,
            status: visit.Visit_Status,
        };
    }
}


export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceLayout = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceSetup = 0;
    public spaceStatus = "";
    public bookingPolicyId = "";
    public spaceWorkType = "";


    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceLayout: space.Space_Layout,
            spaceSetup: space.Space_Setup,
            spaceStatus: space.Space_Status,
            bookingPolicyId: space.Booking_Policy_Id,
            spaceType: space.Space_Type, 
            spaceTypeLabel: space.Space_Type_Label,
            spaceWorkType: space.Space_Work_Type,
        };
    }
}