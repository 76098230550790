import React, { Component } from 'react';

export default class Wellbeing extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 6.64474C0.25 5.31803 1.29228 4.25 2.625 4.25C3.95772 4.25 5 5.31803 5 6.64474V14.8684H3.5V6.64474C3.5 6.12934 3.11228 5.75 2.625 5.75C2.13772 5.75 1.75 6.12934 1.75 6.64474V18.0263C1.75 19.2402 2.14809 20.3786 2.93237 21.2858L2.93827 21.2926L6.84876 26.0422L6.8534 26.0481C7.57798 26.9648 8.05686 28.0852 8.20286 29.25H13.25V24.2763C13.25 23.4903 12.9414 22.7729 12.3881 22.2633C12.3878 22.263 12.3875 22.2627 12.3872 22.2624L7.06736 17.4033C6.86179 17.2284 6.60236 17.1752 6.2658 17.3207C6.15401 17.3824 6.01894 17.5204 5.96243 17.6348L5.88827 17.7849L5.8781 17.8029C5.77524 17.9851 5.79998 18.2421 5.934 18.4049L5.95273 18.4276L10.0646 24.307L8.83539 25.1667L4.75523 19.3326C4.26186 18.7123 4.16224 17.8111 4.56064 17.0857L4.61757 16.9705C4.81888 16.563 5.19543 16.181 5.60134 15.9756L5.61666 15.9678L5.63232 15.9608C6.45353 15.5913 7.35668 15.6685 8.05649 16.2756L8.06373 16.2819L13.4028 21.1586C14.2789 21.9647 14.75 23.089 14.75 24.2763V30.75H6.75V30C6.75 28.9298 6.35914 27.8443 5.68104 26.9839L1.79154 22.2598C0.75991 21.0629 0.25 19.5725 0.25 18.0263V6.64474Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.75 6.64474C33.75 5.31803 32.7077 4.25 31.375 4.25C30.0423 4.25 29 5.31803 29 6.64474V14.8684H30.5V6.64474C30.5 6.12934 30.8877 5.75 31.375 5.75C31.8623 5.75 32.25 6.12934 32.25 6.64474V18.0263C32.25 19.2402 31.8519 20.3786 31.0676 21.2858L31.0617 21.2926L27.1512 26.0422L27.1466 26.0481C26.422 26.9648 25.9431 28.0852 25.7971 29.25H20.75V24.2763C20.75 23.4903 21.0586 22.7729 21.6119 22.2633C21.6122 22.263 21.6125 22.2627 21.6128 22.2624L26.9326 17.4033C27.1382 17.2284 27.3976 17.1752 27.7342 17.3207C27.846 17.3824 27.9811 17.5204 28.0376 17.6348L28.1117 17.7849L28.1219 17.8029C28.2248 17.9851 28.2 18.2421 28.066 18.4049L28.0473 18.4276L23.9354 24.307L25.1646 25.1667L29.2448 19.3326C29.7381 18.7123 29.8378 17.8111 29.4394 17.0857L29.3824 16.9705C29.1811 16.563 28.8046 16.181 28.3987 15.9756L28.3833 15.9678L28.3677 15.9608C27.5465 15.5913 26.6433 15.6685 25.9435 16.2756L25.9363 16.2819L20.5972 21.1586C19.7211 21.9647 19.25 23.089 19.25 24.2763V30.75H27.25V30C27.25 28.9298 27.6409 27.8443 28.319 26.9839L32.2085 22.2598C33.2401 21.0629 33.75 19.5725 33.75 18.0263V6.64474Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 5.63158C7.25 2.63649 9.69836 0.25 12.7143 0.25C14.4261 0.25 15.9944 1.03216 17 2.27958C18.0056 1.03216 19.5739 0.25 21.2857 0.25C24.3061 0.25 26.75 2.68299 26.75 5.63158C26.75 7.19975 26.109 8.7367 25.226 10.1205C24.3387 11.5112 23.1684 12.8075 22.0191 13.9097C20.8671 15.0146 19.7181 15.9416 18.8587 16.5916C18.4283 16.9171 18.069 17.1743 17.8162 17.3509C17.6897 17.4392 17.5898 17.5074 17.5208 17.554C17.4863 17.5773 17.4595 17.5952 17.441 17.6075L17.4196 17.6217L17.4136 17.6256L17.4119 17.6268C17.4117 17.6269 17.4109 17.6274 17 17C16.5891 17.6274 16.5889 17.6273 16.5887 17.6272L16.5864 17.6256L16.5804 17.6217L16.559 17.6075C16.5405 17.5952 16.5137 17.5773 16.4792 17.554C16.4102 17.5074 16.3103 17.4392 16.1838 17.3509C15.931 17.1743 15.5717 16.9171 15.1413 16.5916C14.2819 15.9416 13.1329 15.0146 11.9809 13.9097C10.8316 12.8075 9.66135 11.5112 8.77399 10.1205C7.89103 8.7367 7.25 7.19975 7.25 5.63158ZM17 17L16.5887 17.6272L17 17.8966L17.4109 17.6274L17 17ZM17 16.0912C17.2368 15.9252 17.5644 15.6898 17.9538 15.3952C18.7819 14.769 19.8829 13.8801 20.9809 12.8271C22.0816 11.7714 23.1613 10.5677 23.9615 9.31369C24.766 8.05278 25.25 6.80025 25.25 5.63158C25.25 3.52754 23.4939 1.75 21.2857 1.75C19.6877 1.75 18.2868 2.67864 17.6826 4.00555L17 5.50452L16.3174 4.00555C15.7132 2.67864 14.3123 1.75 12.7143 1.75C10.5016 1.75 8.75 3.48983 8.75 5.63158C8.75 6.80025 9.23397 8.05278 10.0385 9.31369C10.8387 10.5677 11.9184 11.7714 13.0191 12.8271C14.1171 13.8801 15.2181 14.769 16.0462 15.3952C16.4356 15.6898 16.7632 15.9252 17 16.0912Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 30C5.25 29.5858 5.58579 29.25 6 29.25H15C15.4142 29.25 15.75 29.5858 15.75 30V33C15.75 33.4142 15.4142 33.75 15 33.75H6C5.58579 33.75 5.25 33.4142 5.25 33V30ZM6.75 30.75V32.25H14.25V30.75H6.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.25 30C18.25 29.5858 18.5858 29.25 19 29.25H28C28.4142 29.25 28.75 29.5858 28.75 30V33C28.75 33.4142 28.4142 33.75 28 33.75H19C18.5858 33.75 18.25 33.4142 18.25 33V30ZM19.75 30.75V32.25H27.25V30.75H19.75Z" />
            </svg>
        )
    }
};