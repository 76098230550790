import { Component } from 'react';


export default class DataPrivacyIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 8.99999C10.3434 8.99999 9.00023 10.3431 9.00023 12C9.00023 13.6568 10.3434 15 12.0002 15C13.6571 15 15.0002 13.6568 15.0002 12C15.0002 10.3431 13.6571 8.99999 12.0002 8.99999ZM7.94141 12C7.94141 9.75836 9.7586 7.94116 12.0002 7.94116C14.2419 7.94116 16.0591 9.75836 16.0591 12C16.0591 14.2416 14.2419 16.0588 12.0002 16.0588C9.7586 16.0588 7.94141 14.2416 7.94141 12Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9996 1.23534C11.1224 1.23534 10.4114 1.94641 10.4114 2.82357C10.4114 3.70073 11.1224 4.41181 11.9996 4.41181C12.8768 4.41181 13.5878 3.70073 13.5878 2.82357C13.5878 1.94641 12.8768 1.23534 11.9996 1.23534ZM9.35254 2.82357C9.35254 1.36164 10.5377 0.176514 11.9996 0.176514C13.4615 0.176514 14.6467 1.36164 14.6467 2.82357C14.6467 4.2855 13.4615 5.47063 11.9996 5.47063C10.5377 5.47063 9.35254 4.2855 9.35254 2.82357Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9991 4.41187C12.2915 4.41187 12.5285 4.64889 12.5285 4.94128V6.70598C12.5285 6.99837 12.2915 7.23539 11.9991 7.23539C11.7068 7.23539 11.4697 6.99837 11.4697 6.70598V4.94128C11.4697 4.64889 11.7068 4.41187 11.9991 4.41187Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0004 23.1177C12.8776 23.1177 13.5886 22.4066 13.5886 21.5295C13.5886 20.6523 12.8776 19.9412 12.0004 19.9412C11.1232 19.9412 10.4122 20.6523 10.4122 21.5295C10.4122 22.4066 11.1232 23.1177 12.0004 23.1177ZM14.6475 21.5295C14.6475 22.9914 13.4623 24.1765 12.0004 24.1765C10.5385 24.1765 9.35334 22.9914 9.35334 21.5295C9.35334 20.0675 10.5385 18.8824 12.0004 18.8824C13.4623 18.8824 14.6475 20.0675 14.6475 21.5295Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0009 19.9412C11.7085 19.9412 11.4715 19.7041 11.4715 19.4118L11.4714 17.647C11.4714 17.3547 11.7085 17.1176 12.0009 17.1176C12.2932 17.1176 12.5303 17.3547 12.5303 17.647L12.5303 19.4118C12.5303 19.7041 12.2932 19.9412 12.0009 19.9412Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.22292 6.33392C1.78434 7.09356 2.04461 8.06491 2.80425 8.50348C3.56389 8.94206 4.53524 8.68179 4.97382 7.92215C5.4124 7.16251 5.15213 6.19116 4.39249 5.75258C3.63285 5.314 2.6615 5.57427 2.22292 6.33392ZM2.27484 9.42045C1.00877 8.68949 0.574986 7.07057 1.30595 5.8045C2.03692 4.53843 3.65583 4.10465 4.9219 4.83561C6.18797 5.56658 6.62176 7.18549 5.89079 8.45156C5.15983 9.71763 3.54091 10.1514 2.27484 9.42045Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.97369 7.92213C5.11988 7.66892 5.44367 7.58216 5.69688 7.72835L7.22516 8.61071C7.47837 8.7569 7.56513 9.08068 7.41894 9.3339C7.27275 9.58711 6.94896 9.67387 6.69575 9.52767L5.16747 8.64532C4.91425 8.49913 4.8275 8.17534 4.97369 7.92213Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.032 17.2752C22.4705 16.5156 22.2103 15.5442 21.4506 15.1056C20.691 14.6671 19.7196 14.9273 19.2811 15.687C18.8425 16.4466 19.1028 17.418 19.8624 17.8566C20.622 18.2951 21.5934 18.0349 22.032 17.2752ZM21.98 14.1887C23.2461 14.9196 23.6799 16.5386 22.9489 17.8046C22.218 19.0707 20.5991 19.5045 19.333 18.7735C18.0669 18.0426 17.6331 16.4236 18.3641 15.1576C19.0951 13.8915 20.714 13.4577 21.98 14.1887Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2812 15.687C19.135 15.9402 18.8112 16.027 18.558 15.8808L17.0297 14.9984C16.7765 14.8522 16.6898 14.5284 16.8359 14.2752C16.9821 14.022 17.3059 13.9353 17.5591 14.0815L19.0874 14.9638C19.3406 15.11 19.4274 15.4338 19.2812 15.687Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.22332 17.2751C2.6619 18.0347 3.63325 18.295 4.39289 17.8564C5.15253 17.4178 5.4128 16.4465 4.97423 15.6869C4.53565 14.9272 3.5643 14.6669 2.80466 15.1055C2.04501 15.5441 1.78474 16.5155 2.22332 17.2751ZM4.9223 18.7734C3.65623 19.5044 2.03732 19.0706 1.30635 17.8045C0.575388 16.5384 1.00918 14.9195 2.27524 14.1886C3.54131 13.4576 5.16023 13.8914 5.89119 15.1574C6.62216 16.4235 6.18837 18.0424 4.9223 18.7734Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.97455 15.6868C4.82836 15.4336 4.91512 15.1098 5.16833 14.9636L6.69661 14.0812C6.94982 13.935 7.27361 14.0218 7.4198 14.275C7.56599 14.5282 7.47924 14.852 7.22602 14.9982L5.69774 15.8806C5.44453 16.0268 5.12074 15.94 4.97455 15.6868Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0316 6.33404C21.593 5.5744 20.6216 5.31413 19.862 5.7527C19.1024 6.19128 18.8421 7.16263 19.2807 7.92227C19.7192 8.68192 20.6906 8.94219 21.4502 8.50361C22.2099 8.06503 22.4701 7.09368 22.0316 6.33404ZM19.3326 4.83574C20.5986 4.10477 22.2176 4.53856 22.9485 5.80463C23.6795 7.0707 23.2457 8.68961 21.9796 9.42058C20.7136 10.1515 19.0947 9.71775 18.3637 8.45169C17.6327 7.18562 18.0665 5.5667 19.3326 4.83574Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2803 7.92235C19.4265 8.17556 19.3398 8.49935 19.0866 8.64554L17.5583 9.52789C17.3051 9.67409 16.9813 9.58733 16.8351 9.33412C16.6889 9.0809 16.7756 8.75712 17.0289 8.61093L18.5571 7.72857C18.8104 7.58238 19.1341 7.66914 19.2803 7.92235Z" />
            </svg>
        )
    }
};
