import { Component } from 'react';

export default class Parkingspace extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 1.5H8C4.41015 1.5 1.5 4.41015 1.5 8V26C1.5 29.5899 4.41015 32.5 8 32.5H26C29.5899 32.5 32.5 29.5899 32.5 26V8C32.5 4.41015 29.5899 1.5 26 1.5ZM8 0C3.58172 0 0 3.58172 0 8V26C0 30.4183 3.58172 34 8 34H26C30.4183 34 34 30.4183 34 26V8C34 3.58172 30.4183 0 26 0H8Z" />
                <path d="M11.7996 25V8.65002H16.9496C17.8496 8.65002 18.683 8.73336 19.4496 8.90002C20.233 9.06669 20.908 9.35003 21.4746 9.75003C22.0413 10.15 22.483 10.675 22.7996 11.325C23.1163 11.9584 23.2746 12.75 23.2746 13.7C23.2746 14.6 23.1163 15.3834 22.7996 16.05C22.483 16.7167 22.0413 17.275 21.4746 17.725C20.9246 18.1584 20.2663 18.4834 19.4996 18.7C18.7496 18.9 17.933 19 17.0496 19H14.6996V25H11.7996ZM14.6996 16.675H16.8246C19.2246 16.675 20.4246 15.6834 20.4246 13.7C20.4246 12.6667 20.108 11.9584 19.4746 11.575C18.858 11.175 17.9413 10.975 16.7246 10.975H14.6996V16.675Z" />
            </svg>
        )
    }
};