import { Component } from 'react';

export default class VisitorIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fillRule="evenodd" clipRule="evenodd" d="M2 8.75C1.86193 8.75 1.75 8.86193 1.75 9V31C1.75 31.1381 1.86193 31.25 2 31.25H32C32.1381 31.25 32.25 31.1381 32.25 31V9C32.25 8.86193 32.1381 8.75 32 8.75H23V7.25H32C32.9665 7.25 33.75 8.0335 33.75 9V31C33.75 31.9665 32.9665 32.75 32 32.75H2C1.0335 32.75 0.25 31.9665 0.25 31V9C0.25 8.0335 1.0335 7.25 2 7.25H18V8.75H2Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13 11.75C12.8619 11.75 12.75 11.8619 12.75 12V13C12.75 13.1381 12.8619 13.25 13 13.25H21C21.1381 13.25 21.25 13.1381 21.25 13V11.5C21.25 11.6381 21.3619 11.75 21.5 11.75V10.25C22.1904 10.25 22.75 10.8096 22.75 11.5V13C22.75 13.9665 21.9665 14.75 21 14.75H13C12.0335 14.75 11.25 13.9665 11.25 13V12C11.25 11.0335 12.0335 10.25 13 10.25H16V11.75H13Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.98661 17.75C9.11267 17.75 8.35369 18.2492 7.99528 18.9826L7.99169 18.99L7.99165 18.9899C7.84025 19.2914 7.75 19.6433 7.75 20C7.75 20.5437 7.92933 21.0193 8.24107 21.4104C8.65442 21.9214 9.28681 22.25 10 22.25C10.7132 22.25 11.3456 21.9214 11.7589 21.4104C12.0696 21.0207 12.25 20.5322 12.25 20C12.25 19.6338 12.1616 19.2982 11.9993 18.9852L11.993 18.973L11.9931 18.9729C11.6362 18.2524 10.8772 17.75 9.98661 17.75ZM6.64938 18.3204C7.25598 17.0825 8.53163 16.25 9.98661 16.25C11.4507 16.25 12.7255 17.0781 13.3342 18.3009C13.5985 18.8129 13.75 19.382 13.75 20C13.75 20.8804 13.4488 21.698 12.9299 22.348L12.9273 22.3512L12.9273 22.3512C12.2424 23.1993 11.1878 23.75 10 23.75C8.81216 23.75 7.75763 23.1993 7.07274 22.3512L7.07014 22.348L7.07015 22.348C6.55232 21.6994 6.25 20.8955 6.25 20C6.25 19.3981 6.40009 18.8181 6.64938 18.3204Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.34204 25.75C8.255 25.75 8.23058 25.7513 8.21443 25.7536C8.1934 25.7566 8.17224 25.7588 8.15103 25.76C6.71512 25.8444 5.75 26.8578 5.75 27.9009V28.2196C5.76894 28.2328 5.80673 28.25 5.86162 28.25H14.1384C14.1965 28.25 14.2329 28.2322 14.25 28.2198V27.9009C14.25 26.8523 13.2916 25.8439 11.8748 25.76C11.8536 25.7588 11.8326 25.7566 11.8117 25.7536C11.7955 25.7513 11.7711 25.75 11.6841 25.75H8.34204ZM8.33043 24.25C8.33447 24.25 8.33834 24.25 8.34204 24.25H11.6841C11.6878 24.25 11.6916 24.25 11.6957 24.25C11.7614 24.2499 11.8715 24.2498 11.9912 24.2644C14.009 24.3966 15.75 25.8991 15.75 27.9009V28.2521C15.75 29.1636 14.9402 29.75 14.1384 29.75H5.86162C5.08848 29.75 4.25 29.1885 4.25 28.2521V27.9009C4.25 25.8937 6.01014 24.396 8.03508 24.2644C8.15472 24.2498 8.26478 24.2499 8.33043 24.25Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30 19.75H19V18.25H30V19.75Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30 23.75H19V22.25H30V23.75Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30 27.75H19V26.25H30V27.75Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.4104 0.372271C28.7571 0.598949 28.8544 1.06375 28.6277 1.41044L20.1277 14.4104C19.9892 14.6223 19.7531 14.75 19.5 14.75H14C13.721 14.75 13.4651 14.5952 13.3357 14.3481C13.2062 14.101 13.2246 13.8024 13.3834 13.5731L22.3834 0.573091C22.6191 0.232528 23.0863 0.14758 23.4269 0.383354C23.7675 0.619129 23.8524 1.08634 23.6166 1.42691L15.4314 13.25H19.0943L27.3723 0.589561C27.599 0.242877 28.0638 0.145593 28.4104 0.372271Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5641 0.389701C10.227 0.630458 10.1489 1.09887 10.3897 1.43593L14.5426 7.25H11.8645L6.58974 0.536635C6.33383 0.210931 5.86234 0.154352 5.53664 0.410262C5.21094 0.666172 5.15436 1.13766 5.41027 1.46337L11.1355 8.75H17.4574L11.6103 0.564072C11.3695 0.227013 10.9011 0.148944 10.5641 0.389701Z" />
            </svg>
        )
    }
};
