import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import { Grid, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default class OnBoardingWorkingDay extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private userPreferencesService = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: IProps)
    {
        super(props);
        this.state = 
        {
            startTime: null,
            endTime: null,
        };
    }

    public componentDidMount(): void
    {
        const userPreferences = this.local.getUserPreferences();
        // office start time and end time

        // if userPreferences.WorkingHoursPrefs exist, use that
        // else, use default state start and end time.

        // set start time
        if (userPreferences?.WorkingHoursPrefs?.UserStartTime != "date" && userPreferences?.WorkingHoursPrefs?.UserStartTime != "" && userPreferences?.WorkingHoursPrefs?.UserStartTime != null && userPreferences?.WorkingHoursPrefs?.UserStartTime !== undefined)
        {
            const startTime = userPreferences.WorkingHoursPrefs.UserStartTime;
            this.setState({startTime: DateTime.fromFormat(startTime, 'HH:mm')});
        }

        // set end time
        if (userPreferences?.WorkingHoursPrefs?.UserEndTime != "date" && userPreferences?.WorkingHoursPrefs?.UserEndTime != "" && userPreferences?.WorkingHoursPrefs?.UserStartTime != null && userPreferences?.WorkingHoursPrefs?.UserStartTime !== undefined)
        {
            const endTime = userPreferences.WorkingHoursPrefs.UserEndTime;
            this.setState({endTime: DateTime.fromFormat(endTime, 'HH:mm')});
        }
    }

    public onStartTimeChange(e: DateTime | null): void 
    {
        if(e !== null)
        {
            this.setState({
                startTime: e,
            });
        }
    }

    public onEndTimeChange(e: DateTime | null): void 
    {
        if(e !== null)
        {
            this.setState({
                endTime: e
            });
        }
    }

    private async submitBuildingTime(): Promise<void> 
    {
        if(this.state.startTime && this.state.endTime)
        {
            const startDate = this.state.startTime;
            const endDate = this.state.endTime;

            const payload =
            {
                ...this.local.getUserPreferences(),
                WorkingHoursPrefs:
                {
                    UserStartTime: startDate.toFormat('HH:mm'), // format is `2 digit hour:2 digit minute` 
                    UserEndTime: endDate.toFormat('HH:mm')
                },
            };

            try 
            {
                const res = await this.userPreferencesService.update(payload);
                if(res) 
                {
                    this.props.incrementActiveStep();
                }
            }
            catch(error) 
            {
                console.log(error);
            }
        }
    }



    public render(): JSX.Element {

        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelYourWorkingDay}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelWorkingHrs} </p>

                <Grid container spacing={2} className="mb-34">
                    <Grid item xs={6}>
                        <div className="ibss-label-bold-md">{this.labels.HubLabelStarttext}</div>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <TimePicker
                                value={this.state.startTime}
                                onChange={(e) => this.onStartTimeChange(e)}
                                ampm
                                minutesStep={15}
                                maxTime={this.state.endTime}
                                renderInput={(params) => {
                                    const { sx, ...paramsMinusSx } = params
                                    return <TextField 
                                                {...paramsMinusSx}
                                                required
                                                inputProps={{...params.inputProps, readOnly: true,}} // stop user from using entering a time via key strokes. 
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            />
                                }}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={6}>
                        <div className="ibss-label-bold-md">{this.labels.HubLabelEndtext}</div>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <TimePicker
                                value={this.state.endTime}
                                onChange={(e) => this.onEndTimeChange(e)}
                                ampm
                                minutesStep={15}
                                minTime={this.state.startTime}
                                renderInput={(params) => {
                                    const { sx, ...paramsMinusSx } = params
                                    return <TextField 
                                                {...paramsMinusSx}
                                                required
                                                inputProps={{...params.inputProps, readOnly: true,}} // stop user from using entering a time via key strokes. 
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            />
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <IbssButton
                    color="primary" variant="contained"
                    onClick={async() => await this.submitBuildingTime()}
                    disabled={this.state.startTime===null || this.state.endTime===null || this.state.endTime < this.state.startTime}
                > 
                    {this.labels.HubButtonNext} 
                </IbssButton>
            </div>
        </>);
    }
}

interface IState
{
    startTime: DateTime | null,
    endTime: DateTime | null,
}

export interface IProps
{
    incrementActiveStep: ()=> void,
    buildingId: number | null,
}

