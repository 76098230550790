import { IFilter } from "../ODataFilter";
import { IGetManyEndpoint, IUserNotifications } from "./GeManyEndpoint";
import { DateTime } from "luxon";

export class UserNotificationsRepository implements IUserNotificationsRepository
{
    private getManyEndpoint: IGetManyEndpoint;


    constructor(
        getManyEndpoint: IGetManyEndpoint,
    )
    {
        this.getManyEndpoint = getManyEndpoint;
    }


    public getMany(nodeId: number,filter:UserNotificationsFilter): Promise<IUserNotifications[]>
    {
        return this.getManyEndpoint.execute(nodeId,filter);
    }

}

export interface IUserNotificationsRepository
{
    getMany(nodeId: number,filter:UserNotificationsFilter): Promise<IUserNotifications[]>;
}

export class UserNotificationsFilter implements IFilter
{
    public startDate: (DateTime | null) = null;
    public endDate: (DateTime | null) = null;
    public classification: (string| null) = null;

    constructor(value: Partial<UserNotificationsFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.startDate ? `EventTime ge datetime'${this.startDate.toUTC().toISO()}'`:"")
        filterParts.push(this.endDate ? `EventTime lt datetime'${this.endDate.toUTC().toISO()}'`:"")
        filterParts.push(this.classification ? `Classification ne '${this.classification}'` : "");
        return filterParts.filter(i => i !== "").join(" and ");
    }

}