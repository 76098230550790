import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray, getAirStatus } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo";
import InputTextBox from "../../../../Components/Inputs/TextBox/InputTextBox";
import { appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";


class ViewSensor extends Component {

  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  
  state = {
    isLoading: false,
    sensorsData: [],
    facilityDataSort: { columnName: "", direction: "" },
    sensorsList: [],
    formFieldsData: [],
    fullSensorData: {
      Env_Sensor_Name: '',
      IAQ_Status: '',
      Env_Temperature: "",
      Env_Humidity: "",
      Env_Sound_Level: "",
      Env_CO: "",
      Env_CO2: "",
      Env_PM10: "",
      Env_PM25: "",
      Env_TVOC: "",
      Env_Zone_LastUpdated: ""
    }
  };

  componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    const { sensorId } = match.params;
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsSensors});
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetSensorsData(sensorId);
  }
  getAirStatus(val: number): string {
    let obj: any = { 1: 'Good', 2: 'Fair', 3: 'Poor', 0: 'No Data' };
    return obj[val]
  }

  handleGetSensorsData = async (sensorId: string) => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFullSensorsData(this.session.getBuildingId(), sensorId)
      this.setState({ fullSensorData: response?.data ?? {} });
      this.makeFormFields()
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  makeFormFields = () => {
    let arr = [
      {
        name: 'Env_Sensor_Name',
        label: this.labels.HubLabelName,
        value: `${this.state.fullSensorData.Env_Sensor_Name}`,
        readonly: true
      },
      {
        name: 'IAQ_Status',
        label: this.labels.HubLabelStatus,
        value: `${this.state.fullSensorData.IAQ_Status}`,
        readonly: true
      },
      {
        name: 'Env_Temperature',
        label: this.labels.HubLabelTemperature,
        value: `${this.state.fullSensorData.Env_Temperature}\u00b0C`,
        readonly: true
      },
      {
        name: 'Env_Humidity',
        label: this.labels.HubLabelHumidity,
        value: `${this.state.fullSensorData.Env_Humidity}%`,
        readonly: true
      },
      {
        name: 'Env_Sound_Level',
        label: this.labels.HubLabelNoise,
        value: `${this.state.fullSensorData.Env_Sound_Level} dB`,
        readonly: true
      },
      {
        name: 'Env_CO',
        label: this.labels.HubLabelCo,
        value: `${this.state.fullSensorData.Env_CO} ppm`,
        readonly: true
      },
      {
        name: 'Env_CO2',
        label: this.labels.HubLabelCO2,
        value: `${this.state.fullSensorData.Env_CO2} ppm`,
        readonly: true
      },
      {
        name: 'Env_PM10',
        label: this.labels.HubLabelPM10,
        value: `${this.state.fullSensorData.Env_PM10} ppm`,
        readonly: true
      },
      {
        name: 'Env_PM25',
        label: this.labels.HubLabelPM25,
        value: `${this.state.fullSensorData.Env_PM25} ppm`,
        readonly: true
      },
      {
        name: 'Env_TVOC',
        label: this.labels.HubLabelTVOC,
        value: `${this.state.fullSensorData.Env_TVOC} ppm`,
        readonly: true
      },
      {
        name: 'Env_Zone_LastUpdated',
        label: this.labels.HubLabelLastUpdate,
        value: DateTime.fromISO(this.state.fullSensorData.Env_Zone_LastUpdated).toFormat("dd/MM/yyyy HH:mm"),
        readonly: true
      },
    ];

    this.setState({ isLoading: false, formFieldsData: arr })
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.sensorsList, e.column, e.direction)
    this.setState({ sensorsList: newArray })
  }
  render() {
    return (
      <>
            <div className="rightPanel-main-content-form">
              <div className="form-panel">
                {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                <FormPanelInfo
                  formHeading={this.state.fullSensorData.Env_Sensor_Name}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Sensor.svg"
                />
                <form className="form-panel-form">
                  {this.state.formFieldsData.map((obj: any, ind: number) => {
                    return (
                      <div className="row" key={ind}>
                        <InputTextBox {...obj} />
                      </div>
                    )
                  })}
                </form>
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(ViewSensor);
