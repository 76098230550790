import { Component } from "react";
import "../../../styles/css/setupPIN.scss";
import "../../../App.css";


export default class OnBoardingSetUpaPIN extends Component<IOnBoardingSetUpaPIN, State>
{

    constructor(props: IOnBoardingSetUpaPIN) {
        super(props);
        this.state = new State();
    }

    public valueItems = () => {
        const valueArray = this.props.value.split('');
        const items: Array<string> = [];

        const RE_DIGIT = new RegExp(/^\d+$/);

        for (let i = 0; i < this.props.valueLength; i++) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    };

    private inputOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        idx: number
    ) => {
        const { value, onChange } = this.props
        const target = e.target;
        let targetValue = target.value;
        const RE_DIGIT = new RegExp(/^\d+$/);

        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const newValue =
            value.substring(0, idx) + targetValue + value.substring(idx + 1);

        onChange(newValue);

        if (!isTargetValueDigit) {
            return;
        }

        const nextElementSibling =
            target.nextElementSibling as HTMLInputElement | null;

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    public inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }

        const previousElementSibling =
            target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };


    public render(): JSX.Element {
        const myValuesArray: string[] = this.valueItems();

        return (
            <>
                <div className="otp-group">
                    {myValuesArray.map((digit: any, idx: number) => (
                        <input
                            key={idx}
                            type="password"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d{1}"
                            maxLength={this.props.valueLength}
                            className="otp-input"
                            value={digit}
                            onChange={(e) => this.inputOnChange(e, idx)}
                            onKeyDown={this.inputOnKeyDown}
                        />
                    ))}
                </div>
            </>
        );
    }

}



class State {
    // PIN = ""

}
export interface IOnBoardingSetUpaPIN {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
}

