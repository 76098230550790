import { Component } from 'react';

export default class TabletIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 3C0.25 1.48122 1.48122 0.25 3 0.25H31C32.5188 0.25 33.75 1.48122 33.75 3V31C33.75 32.5188 32.5188 33.75 31 33.75H3C1.48122 33.75 0.25 32.5188 0.25 31V3ZM3 1.75C2.30964 1.75 1.75 2.30964 1.75 3V31C1.75 31.6904 2.30964 32.25 3 32.25H31C31.6904 32.25 32.25 31.6904 32.25 31V3C32.25 2.30964 31.6904 1.75 31 1.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5 27.75H1.5V26.25H32.5V27.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 29.75C16.8619 29.75 16.75 29.8619 16.75 30C16.75 30.1381 16.8619 30.25 17 30.25C17.1381 30.25 17.25 30.1381 17.25 30C17.25 29.8619 17.1381 29.75 17 29.75ZM15.25 30C15.25 29.0335 16.0335 28.25 17 28.25C17.9665 28.25 18.75 29.0335 18.75 30C18.75 30.9665 17.9665 31.75 17 31.75C16.0335 31.75 15.25 30.9665 15.25 30Z" />
            </svg>
        )
    }
};
