import { appContext } from "../../AppContext";
import { Role } from "../../Providers.Api/Roles/RoleRepository";

export class SetSelected implements ISetSelected
{
    private get apiClient() { return appContext().apiClient; }

    public async execute(buildingId: number, cateringMenuId: string, selectedIds: string[]): Promise<void>
    {
        const currentlySelectedRoles = await this.apiClient.roles.getManyByMenuId(buildingId, cateringMenuId, Role);
        const currentlySelectedIds = currentlySelectedRoles.value.map(i => i.Id);
        const itemIdsToCreate = selectedIds.filter(i => !currentlySelectedIds.contains(i));
        const itemIdsToDelete = currentlySelectedIds.filter(i => !selectedIds.contains(i));

        if (itemIdsToCreate.length == 0 && itemIdsToDelete.length == 0)
        {
            return;
        }
        await this.apiClient.roles.createDelete(buildingId, cateringMenuId, itemIdsToCreate, itemIdsToDelete);
    }
}

export interface ISetSelected
{
    execute(buildingId: number, cateringMenuId: string, selectedIds: string[]): Promise<void>;
}
