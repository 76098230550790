import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";

export class GetMyBookingsForOthersEndpoint implements IGetMyBookingsForOthersEndpoint
{
    public async execute(email: string, start: DateTime, end: DateTime): Promise<IBooking[]>
    {
        const lowerCaseEmail = email.toLowerCase()
        try
        {
            const query =
                `$top=2000&` +
                `$select=_CreatedBy,_CreatedAt, Booking_Id,Booking_Owner_Name,Booking_Owner_Email,Booking_Start,Booking_End,Booking_Name,Space_Id,Space_Name,Booking_Status,Node_Id&` +
                `$filter=CreatedBy eq '${lowerCaseEmail}' and Booking_Owner_Email ne '${lowerCaseEmail}' and Booking_Start ge datetime'${start.toUTC().toISO()}' and Booking_End lt datetime'${end.toUTC().toISO()}' and Booking_Status ne 'Cancelled'`;

            const response = await axios.get<IBooking[]>(`${apis.allApi}1/Bookings?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetMyBookingsForOthersEndpoint
{
    execute(email: string, start: DateTime, end: DateTime): Promise<IBooking[]>;
}

export interface IBooking
{
    Node_Id: number;
    Space_Id: string;
    Space_Name: string;
    Booking_Id: string;
    Booking_Name: string;
    Booking_Start: string;
    Booking_Early_Checkin: string;
    Booking_End: string;
    Booking_Status: string;
    Booking_IsActive: number;
    Booking_IsApproved: number;
    Booking_Owner_Email: string;
    Booking_Owner_Name: string;
    _CreatedAt: string;
    _CreatedBy: string;
}
