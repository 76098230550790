import { Component } from 'react';

export default class Quietroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3003 7.31277C16.5735 7.43213 16.75 7.70192 16.75 8.00002V26C16.75 26.2981 16.5735 26.5679 16.3003 26.6873C16.0272 26.8066 15.7093 26.7529 15.4905 26.5504L10.1506 21.6072H6C5.58579 21.6072 5.25 21.2714 5.25 20.8572V13.1429C5.25 12.7287 5.58579 12.3929 6 12.3929H10.1506L15.4905 7.44964C15.7093 7.24714 16.0272 7.19341 16.3003 7.31277ZM15.25 9.71633L10.9539 13.6933C10.8153 13.8216 10.6334 13.8929 10.4444 13.8929H6.75V20.1072H10.4444C10.6334 20.1072 10.8153 20.1784 10.9539 20.3068L15.25 24.2837V9.71633Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4697 13.4697C20.7626 13.1768 21.2374 13.1768 21.5303 13.4697L28.0303 19.9697C28.3232 20.2626 28.3232 20.7374 28.0303 21.0303C27.7374 21.3232 27.2626 21.3232 26.9697 21.0303L20.4697 14.5303C20.1768 14.2374 20.1768 13.7626 20.4697 13.4697Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0303 13.4697C27.7374 13.1768 27.2626 13.1768 26.9697 13.4697L20.4697 19.9697C20.1768 20.2626 20.1768 20.7374 20.4697 21.0303C20.7626 21.3232 21.2374 21.3232 21.5303 21.0303L28.0303 14.5303C28.3232 14.2374 28.3232 13.7626 28.0303 13.4697Z" />
            </svg>
        )
    }
};