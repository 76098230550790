import { Component } from 'react';
import { BookingView } from './ViewBookingsManagedSchedule';
import { appContext } from '../../../../AppContext';
import { Box, Typography } from '@mui/material';
import { ParentBookingRelationTypeEnum } from '../../../../Providers.Api/Bookings/GetV2BookingsEndpoint';
import './BookingsCount.scss'
import './ViewBookingsManagedSchedule.scss'

class BookingsCount extends Component<IProps, IState>
{
    private get labels() {return appContext().labels }
    private filteredBookingStatusList: string[];
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            summary: [],
        };
        this.filteredBookingStatusList = ['New', 'Amended', 'In Progress', 'Early Check In', 'Late Checkin', 'No Show', 'Completed'];
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if(JSON.stringify(prevProps.bookings) !== JSON.stringify(this.props.bookings))
        {
            this.createBookingSummaryData();
        }
    }

    public componentDidMount(): void
    {
        this.createBookingSummaryData();
    }

    private getBookingGroupData(bookingGroupKey: string): IBookingGroup
    {
        switch (bookingGroupKey)
        {
            case 'Not Started':
                return { sortOrder: 1, label: this.labels.HubLabelNotStarted_S, backgroundColor: 'white'};
            case 'In Progress':
                return { sortOrder: 2, label: this.labels.HubButtonInProgress, backgroundColor: 'var(--ui-success-pastel)'} // pastel-green
            case 'Early Check In':
                return { sortOrder: 3, label: this.labels.HublabelEarlyCheckIn, backgroundColor: 'var(--scroll-track)'} // light grey mid-tone
            case 'Late Checkin':
                return { sortOrder: 4, label: this.labels.HublabelLateCheckIn, backgroundColor: 'var(--ui-warn-pastel)'} // pastel-yellow
            case 'No Show':
                return { sortOrder: 5, label: this.labels.HubLabelNoShow, backgroundColor: 'var(--ui-error-pastel)'};  // pastel-pink
            case 'Completed':
                return { sortOrder: 6, label: this.labels.HubLabelCompleted, backgroundColor: 'white'}
            case 'Facility Management':
                return { sortOrder: 7, label: this.labels.funcBookingsOperationalSlots_S, backgroundColor: 'var(--ui-light-pastel-blue)'}; // pastel-blue
            default:
                return { sortOrder: 8, label: '', backgroundColor: ''};
        }
    }

    private createBookingSummaryData(): void
    {
        this.setState({ summary: []});
        const groupedBookings = this.props.bookings.filter(booking => this.filteredBookingStatusList.includes(booking.bookingStatus)).groupBy(booking => booking.bookingStatus==='New' || booking.bookingStatus==='Amended' ? 'Not Started' : booking.bookingStatus);
        const bookingsSummary = groupedBookings.map(bookingCategory => ({
            bookingSummarykey: bookingCategory.key,
            backgroundColor: this.getBookingGroupData(bookingCategory.key).backgroundColor,
            label: this.getBookingGroupData(bookingCategory.key).label,
            sortOrder: this.getBookingGroupData(bookingCategory.key).sortOrder,
            total: bookingCategory.value.filter(booking => booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Main ).length,
        })).filter(bookingCategory => bookingCategory.total > 0);
        
        const facilityManagementBookings = this.props.bookings.filter(booking => booking.parentBookingRelationType !== ParentBookingRelationTypeEnum.Main);
        
        let bookingsGrouped: IBookingSummary[] = [];
        if(facilityManagementBookings.length === 0)
        {
            bookingsGrouped = bookingsSummary.sort((a, b) => a.sortOrder - b.sortOrder);
        }
        else
        {
            const facilityManagementSummary = {
                bookingSummarykey: 'Facility Management',
                backgroundColor: this.getBookingGroupData('Facility Management').backgroundColor,
                label: this.getBookingGroupData('Facility Management').label,
                sortOrder: this.getBookingGroupData('Facility Management').sortOrder,
                total: facilityManagementBookings.length,
            };

            bookingsGrouped = [...bookingsSummary, facilityManagementSummary].sort((a, b) => a.sortOrder - b.sortOrder);
        }
        this.setState({summary: bookingsGrouped});
    }

    public render(): JSX.Element
    {
        const totalMainBookings = this.props.bookings.filter(booking => booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Main).length;
        return (
            <Box display='flex' alignItems='center' gap='0.5rem'>
                <Box className='bookingsCount-border' paddingRight='1rem'>
                    <Typography marginLeft='12px' className='bookingsDashboard-text'>{this.labels.funcBookingsSummary_S.replace("{bookings-count}", `${totalMainBookings}`)}</Typography>
                </Box>
                {this.state.summary.map(bookingGroup => {
                    const borderStyle = bookingGroup.backgroundColor === 'white'? `1px var(--ui-core) solid`: '';
                    return <Box key={bookingGroup.bookingSummarykey} display='flex' gap='5px' className='bookingsCount-border' paddingRight='0.5rem' paddingLeft='0.5rem'>
                    <Typography className='bookingsDashboard-text'>{bookingGroup.label}</Typography>
                    <Box sx={{display:'flex', justifyContent: 'center', backgroundColor: bookingGroup.backgroundColor, border: `${borderStyle}`, borderRadius: '2px' }} minWidth='27px'>
                        <Typography className='bookingsDashboard-text' sx={{color:'var(--ui-secondary)'}}>
                            {bookingGroup.total}
                        </Typography>
                    </Box>
                </Box>
                })}
            </Box>
        )
    }
}

export default BookingsCount;

export interface IProps
{
    bookings: BookingView[];
}

export interface IState
{
    summary: IBookingSummary[],
}

interface IBookingSummary
{
    bookingSummarykey: string;
    backgroundColor: string;
    label: string;
    sortOrder: number;
    total: number;
}

interface IBookingGroup
{
    backgroundColor: string;
    label: string;
    sortOrder: number;

}