import { Grid } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { Category, ChartComponent, ColumnSeries, DataLabel, Export, Inject, Legend, SeriesCollectionDirective, SeriesDirective } from "@syncfusion/ej2-react-charts";
import { VisitsDailySummary } from "./DataModels";
import { appContext } from "../../../../AppContext";
import { DateTime, WeekdayNumbers } from "luxon";
import React from "react";
import { Helper } from "./Helper";
import { StyleHelper } from "../../../../Common/StyleHelper";
import './Styles.scss'
import { ChartDownloadButton } from "../../../../Components/Buttons/ChartDownloadButton/ChartDownloadButton";

export class WeekdayTrendForPeriod extends IbssComponent<IProps, IState>
{
    private chartRef = React.createRef<ChartComponent>();
    private styleHelper = new StyleHelper();

    public render(): JSX.Element
    {
        const props = this.props;
        const labels = appContext().labels;
        const summariesGroupedByDayOfWeek = new Map(this.props.summaries.groupBy(i => i.Summary_Date.weekday).map(i => [i.key, i.value]));
        const downloadFileName = `${labels.funcWeekdayTrendForPeriod_S} (${props.from.toISODate()} ${labels.HubLabelTo.toLocaleLowerCase()} ${props.to.toISODate()})`;
        const style = this.styleHelper;

        const daysOfWeek: { name: string, value: WeekdayNumbers }[] = [
            { name: labels.HubLabelMonday, value: 1 },
            { name: labels.HubLabelTuesday, value: 2 },
            { name: labels.HubLabelWednesday, value: 3 },
            { name: labels.HubLabelThursday, value: 4 },
            { name: labels.HubLabelFriday, value: 5 },
            { name: labels.HubLabelSaturday, value: 6 },
            { name: labels.HubLabelSunday, value: 7 },
        ];

        const data = daysOfWeek.map(i =>
        {
            const summaries = summariesGroupedByDayOfWeek.get(i.value) ?? null;
            return {
                dayOfWeek: i.name,
                expectedVisits: summaries?.average(j => j.Visits_Approved_Count) ?? 0,
                actualVisits: summaries?.average(j => j.Visits_Completed_Count) ?? 0,
            };
        });

        const csv = `${labels.HublabelDay},${labels.funcDailyExpectedVisits_S},${labels.funcDailyVisits_S}\n` +
            data.map(i => `${i.dayOfWeek},${i.expectedVisits},${i.actualVisits}`).join('\n');

        return (
            <>
                <ChartDownloadButton
                    chartRef={this.chartRef}
                    filename={downloadFileName}
                    exportTypes={Helper.exportTypes}
                    style={{ position: 'absolute', top: '22px', right: '10px' }}
                    csv={csv}
                />
                <ChartComponent
                    ref={this.chartRef}
                    className='visit_analytics__chart'
                    background={style.getColour('--ui-background-alternate')}
                    primaryXAxis={{
                        valueType: 'Category',
                        labelStyle: { color: style.getColour('--ui-text') },
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        labelStyle: { color: style.getColour('--ui-text') },
                    }}
                    legendSettings={{
                        textStyle: { color: style.getColour('--ui-text') },
                    }}
                >
                    <Inject services={[ColumnSeries, Category, Legend, DataLabel, Export]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName="dayOfWeek"
                            yName="expectedVisits"
                            type="Column"
                            name={labels.funcDailyExpectedVisits_S}
                            columnSpacing={0.2}
                            fill={style.getColour('--ui-graph3')}
                        />
                        <SeriesDirective
                            dataSource={data}
                            xName="dayOfWeek"
                            yName="actualVisits"
                            type="Column"
                            name={labels.funcDailyVisits_S}
                            columnSpacing={0.2}
                            fill={style.getColour('--ui-graph2')}
                        />
                    </SeriesCollectionDirective>
                </ChartComponent>
            </>
        );
    }
}

export interface IProps
{
    from: DateTime;
    to: DateTime;
    summaries: VisitsDailySummary[];
}

export interface IState
{
}
