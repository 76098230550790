import { Component } from 'react';

export default class Security extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.69102 11.7061L5.85178 4.74767C6.34547 3.68139 7.58584 3.21393 8.6415 3.68213L8.64542 3.68387L31.8203 13.9754L20.032 20.7107L20.0263 20.714C19.4439 21.0535 18.7438 21.091 18.1134 20.8275L3.77941 14.5146L3.76901 14.51C2.68796 14.0353 2.20451 12.7596 2.68907 11.7104L2.69102 11.7061ZM4.48906 4.12077C5.32193 2.31728 7.43867 1.50378 9.25574 2.31365L32.6885 12.7197C33.7417 13.1755 33.7606 14.6087 32.7999 15.1432L32.2649 15.4489L28.4075 22.8145C28.0466 23.6314 27.0927 23.9596 26.299 23.6117L21.7032 21.6312C21.6562 21.611 21.6123 21.5865 21.5719 21.5584L20.7818 22.0098L20.779 22.0115C19.7717 22.5975 18.5738 22.6487 17.5262 22.2079L17.5148 22.203L12.3463 19.9266L8.25716 27.3614C8.12535 27.6011 7.87352 27.75 7.6 27.75H1.75V33C1.75 33.4142 1.41421 33.75 1 33.75C0.585786 33.75 0.25 33.4142 0.25 33V21C0.25 20.5858 0.585786 20.25 1 20.25C1.41421 20.25 1.75 20.5858 1.75 21V26.25H7.15655L10.9682 19.3197L3.16516 15.8831C1.3289 15.0762 0.486 12.9064 1.32623 11.0837L1.32727 11.0815L4.48906 4.12077ZM23.1853 20.6366L26.901 22.2377C26.9387 22.2543 26.9719 22.2521 26.9958 22.2429C27.0189 22.234 27.0305 22.2206 27.0368 22.2051C27.0469 22.1803 27.0584 22.156 27.0711 22.1325L29.9676 16.7615L23.1853 20.6366Z" />
            </svg>
        )
    }
};