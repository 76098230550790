import axios, { AxiosResponse } from "axios";
import apis from "../apis";
import { ApiError } from "../ApiError";
import { ICreateNotification } from "./CreateNotificationEndpoint";

export class EditNotificationEndpoint implements IEditNotificationEndpoint
{
    public async execute(nodeId: number, notificationId: number, payload: ICreateNotification): Promise<void>
    {
        try
        {
            await axios.put(`${apis.allApi}${nodeId}/Notifications/${notificationId}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IEditNotificationEndpoint
{
    execute(nodeId: number, notificationId: number, payload: ICreateNotification): Promise<void>;
}
