import { Component } from 'react';

export default class IdProvider extends Component {
    render() {
        return (
            <svg style={{overflow: 'visible'}}>
                <path id="Path_1" data-name="Path 1" d="M5.973,16.27a2.225,2.225,0,0,0-2.262,2.186V29.1a2.225,2.225,0,0,0,2.262,2.186H28A2.225,2.225,0,0,0,30.26,29.1V18.456A2.225,2.225,0,0,0,28,16.27ZM1.941,18.456a3.966,3.966,0,0,1,4.031-3.9H28a3.966,3.966,0,0,1,4.031,3.9V29.1A3.966,3.966,0,0,1,28,33H5.973a3.966,3.966,0,0,1-4.031-3.9Z" fill-rule="evenodd" />
                <path id="Path_2" data-name="Path 2" d="M17.47,1.725a7.054,7.054,0,0,0-4.928,1.993A6.727,6.727,0,0,0,10.5,8.532v6.135a.884.884,0,0,1-1.767,0V8.532A8.432,8.432,0,0,1,11.293,2.5a8.884,8.884,0,0,1,12.354,0A8.432,8.432,0,0,1,26.2,8.532v6.135a.884.884,0,0,1-1.767,0V8.532A6.727,6.727,0,0,0,22.4,3.719,7.054,7.054,0,0,0,17.47,1.725Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
