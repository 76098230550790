import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { ICateringRestriction } from "./CateringRestrictionsRepository";

export class CreateEndpoint implements ICreateEndpoint
{
    public async execute(nodeId: number, payload: ICateringRestriction): Promise<ICreateCateringRestrictionResponse>
    {
        try
        {
            const response = await axios.post<ICreateCateringRestrictionResponse>(apis.allApiv2 + nodeId + `/catering-restriction/`, payload);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface ICreateEndpoint
{
    execute(nodeId: number, payload: ICateringRestriction): Promise<ICreateCateringRestrictionResponse>;
}

export interface ICreateCateringRestrictionResponse
{
    Message: string;
}
