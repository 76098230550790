import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Space } from "./SpaceRepository";

export class SearchV2SpaceByIdEndpoint implements ISearchV2SpaceByIdEndpoint
{
    public async execute(nodeId: number, spaceId: string): Promise<Space>
    {
        try
        {
            const response = await axios.get<Space>(`${apis.allApiv2}${nodeId}/spaces/${spaceId}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ISearchV2SpaceByIdEndpoint
{
    execute(nodeId: number, spaceId: string): Promise<Space>;
}
