import * as React from 'react';
import { Input } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import { styled } from '@mui/material/styles';

type IbssInputProps = InputProps & {
    // custom props created to allow us to style the component via passing in props.  
    bottomBorderColor?: string,
    focusColor?: string,
    inputTextColor?: string,
 };

// styledComponent allow us to define styles upfront with css in js, MUI theme as well as dynamically with props.
// we can create styledComponent using MUI's styled() function.
// we SHOULD NOT forward custom props we create for styling to DOM elements. React will complain.
// use the second argument in styled() to specify these custom props.

const StyledInput = styled(Input, {shouldForwardProp:(propName: PropertyKey) => {
    return !['bottomBorderColor', 'focusColor', 'inputTextColor'].includes(propName as string)}})<IbssInputProps>(({ bottomBorderColor, focusColor, inputTextColor }) => ({
    color: inputTextColor,
    
    '&.MuiInput-underline:before':{
        borderBottomColor: bottomBorderColor,
    },
    '&.MuiInput-underline:after':{
        borderBottomColor: focusColor,
    }
  }));

class IbssInput extends React.Component<IbssInputProps>{
    render(){
        return <StyledInput {...this.props}/>
    }
}

export default IbssInput;