import { CateringMenuItemClassification } from "../../Providers.Api/CateringMenuItems/CateringMenuItemRepository";
import * as Set from "./SetSelected";

export class Service implements IService
{
    private setSelectedService: Set.ISetSelected;

    constructor(
        setSelectedService: Set.ISetSelected,
    )
    {
        this.setSelectedService = setSelectedService;
    }

    public setSelected(buildingId: number, cateringMenuId: string, classification: CateringMenuItemClassification, selectedIds: string[]): Promise<void>
    {
        return this.setSelectedService.execute(buildingId, cateringMenuId, classification, selectedIds);
    }
}

export interface IService
{
    setSelected(buildingId: number, cateringMenuId: string, classification: CateringMenuItemClassification, selectedIds: string[]): Promise<void>;
}