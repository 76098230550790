import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IBookingPoliciesV2 } from "./BookingPolicyRepository";

export class GetV2BookingPoliciesEndpoint implements IGetV2BookingPoliciesEndpoint
{
    public async execute(nodeId: number, top: number): Promise<IBookingPoliciesV2[]>
    {
        try
        {
            let query =
                `$select=Booking_Policy_Id,Booking_Policy_Name,Booking_Policy&` +
                `$filter=Node_Id eq ${nodeId}&` +
                `$top=${top}&`;

            const response: AxiosResponse<IBookingPoliciesV2[]> = await axios.get(`${apis.allApiv2}${nodeId}/BookingPolicies?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2BookingPoliciesEndpoint
{
    execute(nodeId: number, top: number): Promise<IBookingPoliciesV2[]>;
}
