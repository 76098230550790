import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { BookingFilter } from "./BookingRepository";

export class GetV1BookingsByEmailEndpoint implements IGetV1BookingsByEmailEndpoint
{
    public async execute(email: string, start: DateTime, end: DateTime, filter: BookingFilter): Promise<IBooking[]>
    {
        try
        {
            const query =
                `$top=2000&` +
                `$select=Booking_IsActive,_CreatedBy,_CreatedAt,Booking_Id,Booking_Owner_Name,Booking_Owner_Email,Booking_Start,Booking_End,Booking_Name,Space_Id,Booking_Status,Node_Id,Booking_IsApproved,Booking_IsCheckedIn&` +
                `$filter=Booking_Owner_Email eq '${email}' and Booking_Start ge datetime'${start.toUTC().toISO()}' and Booking_End lt datetime'${end.toUTC().toISO()}' and ${filter.toODataString()}`;

            const response = await axios.get<IBooking[]>(`${apis.allApi}1/Bookings?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV1BookingsByEmailEndpoint
{
    execute(email: string, start: DateTime, end: DateTime, filter: BookingFilter): Promise<IBooking[]>;
}

export interface IBooking
{
    Node_Id: number;
    Space_Id: string;
    Space_Name: string;
    Booking_Id: string;
    Booking_Name: string;
    Booking_Start: string;
    Booking_Early_Checkin: string;
    Booking_End: string;
    Booking_Status: string;
    Booking_IsActive: number;
    Booking_IsApproved: number;
    Booking_Owner_Email: string;
    Booking_Owner_Name: string;
    Booking_IsCheckedIn: number;
    _CreatedAt: string;
    _CreatedBy: string;
}
