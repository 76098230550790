import React from "react";
import { Box, Typography, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl } from "@mui/material";
import { appContext } from "../../../../AppContext";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import "./ListTags.scss";
import TagItem from "./TagItem";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import { PagedResponse } from "../../../../Providers.Api/Models";
import { TagDetails } from "./DataModels";

export default class ListTags extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }

    constructor(props: IProps) 
    {
        super(props);
        this.state = {
            filter: 'All',
            buildingId: this.appState.buildingId,
            tagData: [],
            showDeleteConfirmationModal: false,
            deletedTagId: '',
            skipToken: '',
            initialTagCount: 25
        };
    }

    public async componentDidMount(): Promise<void> 
    {
        await this.loadTags();
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.loadTags();
        }
    }
    
    private async loadTags(): Promise<void> 
    {
        let skipToken = ''
        let allTags: TagDetails[] = []

        try 
        {
            do
            {
                const tagData = await appContext().ibssApiClientV2.v2.byNodeid.tags.get<PagedResponse<TagDetails[]>>({nodeId: this.state.buildingId, top:100, skipToken:skipToken})

                skipToken = tagData.skipToken
                allTags = allTags.concat(tagData.value)
            } 
            while (skipToken);

            this.setState({
                tagData: allTags,
                skipToken: skipToken,
            });
            this.props.tagCount(allTags.length)
        }
        catch 
        {
        }
    }

    private getDeletedTagId(tagId: string): void 
    {
        this.setState({
            showDeleteConfirmationModal: true,
            deletedTagId: tagId
        })
    }


    private async deleteTag(): Promise<void> 
    {
        await appContext().ibssApiClientV2.v2.byNodeid.tags.byTagid.delete({ nodeId: this.state.buildingId, tagid: this.state.deletedTagId });
        
        this.setState({
            tagData: [],
            skipToken: '',
            initialTagCount: 25
        })
        
        await this.loadTags();
        this.setState({
            showDeleteConfirmationModal: false
        })       
    }

    private onEditTag(tagId: string): void 
    {
        const selectedTag = this.state.tagData.find(tag => tag.Tag_Id === tagId);
        if (selectedTag) {
            this.props.onEditTag(selectedTag);
        }
    }

    private filterChange(event: SelectChangeEvent): void 
    {
        this.setState({ filter: event.target.value as string });
    }

    private async loadMoreClicked(): Promise<void>
    {
        this.setState(prevState => ({
            initialTagCount: prevState.initialTagCount + 25
        }));
    }

    public render(): JSX.Element 
    {
        const { filter, tagData, initialTagCount  } = this.state;
        const filteredTagData = filter === 'All' ? tagData : tagData.filter(tag => tag.TagType === filter);
        const Tags = filteredTagData.slice(0, initialTagCount);

        return (
            <Box>
                <Typography variant="h4" fontWeight={'medium'} mb={1.5}>{this.labels.funcCurrentTags_S}</Typography>
                <Typography variant="body1" className="ui-text-light">{this.labels.funcViewTheTagsInfo_D}</Typography>
                <Box className="space-between" display="flex" alignItems="center">
                    <Typography variant="h6" my={3}>{this.labels.funcTags_S}</Typography>
                    <FormControl sx={{ minWidth: 120 }} >
                        <InputLabel id="view-label">{this.labels.HubLabelView}</InputLabel>
                        <Select
                            labelId="view-label"
                            value={filter}
                            onChange={(event) => this.filterChange(event)}
                            label={this.labels.HubLabelView}
                        >
                            <MenuItem value={'All'}>{this.labels.HubLabelAll}</MenuItem>
                            <MenuItem value={'TagTypeSimple'}>{this.labels.funcSimpleTags_S}</MenuItem>
                            <MenuItem value={'TagTypeComplex'}>{this.labels.funcComplexTag_S}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {Tags.map(tagData => (
                    <Box className="option-box" mt={1}>
                        <TagItem
                            key={tagData.Tag_Id}
                            tagData={tagData}
                            onEditTag={() => this.onEditTag(tagData.Tag_Id)}
                            deleteTag={() => this.getDeletedTagId(tagData.Tag_Id)}
                        />
                    </Box>
                ))}

                {initialTagCount < filteredTagData.length &&
                    <Box className="text-center my-4">
                        <button
                            type="button"
                            className="edit-search btn-primary btn-md"
                            onClick={() => this.loadMoreClicked()}
                        >
                            {this.labels.HubButtonLoadMore}
                        </button>
                    </Box>
                }
                <IbssDialog
                    open={this.state.showDeleteConfirmationModal}
                    onClose={() => this.setState({ showDeleteConfirmationModal: false })}
                    fullWidth
                    header={this.labels.funcDeleteTag_S}
                    dialogContent=
                    {
                        <div>{this.labels.funcTagConfirmDelete_Message}</div>
                    }
                    footer=
                    {
                        <>
                            <IbssButton
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.deleteTag()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        </>
                    }
                />
            </Box>
        )
    }
}

interface IProps 
{
    onEditTag: (tagData: TagDetails) => void;
    tagCount: (tagCount: number) => void;
}

interface IState 
{
    filter: string;
    buildingId: number;
    tagData: TagDetails[];
    showDeleteConfirmationModal: boolean;
    deletedTagId: string;
    skipToken: string;
    initialTagCount: number;
}
