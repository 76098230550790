import React from "react";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import { appContext } from "../../../../AppContext";

class GetCateringStatus extends React.Component<Props> {
    private get labels() { return appContext().labels; }
    
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public renderTaskOverAllStatus(taskStatus: string): React.ReactNode {
        switch(taskStatus) {
            case "PendingApproval":
                return <IbssChip label={this.labels.HubLabelPendingApproval} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text)",
                }} />;
            case "NotApproved":
                return <IbssChip label={this.labels.HubLabelNotApproved} sx={{
                    backgroundColor: 'var(--ui-warn-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "RequestApproved":
                return <IbssChip label={this.labels.HubLabelRequestApproved} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "ReadyForPrep":
                return <IbssChip label={this.labels.HubLabelReadyForPrep} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "PrepareForService":
                return <IbssChip label={this.labels.HubLabelPrepareForService} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text)",
                }} />;
            case "InPreparation":
                return <IbssChip label={this.labels.HubLabelInPreparation} sx={{
                    backgroundColor: 'var(--ui-primary-pastel )',
                    color: "var(ui-text)",
                }} />;
            case "ReadyForDespatchInTime":
                return <IbssChip label={this.labels.HubLabelReadyForDispatchInTime} sx={{
                    backgroundColor: 'var(--ui-primary-pastel )',
                    color: "var(ui-text)",
                }} />;
            case "ReadyForDespatchOutOfTime":
                return <IbssChip label={this.labels.HubLabelReadyForDispatchOutOfTime} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "CancelledCharged":
                return <IbssChip label={this.labels.HubLabelCancelledCharged} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text)",
                }} />;
            case "CancelledNoCharge":
                return <IbssChip label={this.labels.HubLabelCancelledNoCharge} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "DeliveryIssue":
                return <IbssChip label={this.labels.HubLabelDeliveryIssue} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text)",
                }} />;
            case "Delivered":
                return <IbssChip label={this.labels.HubLabelDelivered} sx={{
                    backgroundColor: 'var(--ui-primary-pastel )',
                    color: "var(ui-text)",
                }} />;
            case "ClearUpScheduled":
                return <IbssChip label={this.labels.HubLabelClearUpScheduled} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            case "ClearUpExcess":
                return <IbssChip label={this.labels.HubLabelClearUpExcess} sx={{
                    backgroundColor: 'var(--ui-success)',
                    color: "var(--ui-light-text)",
                }} />;
            case "Cleared":
                return <IbssChip label={this.labels.HubLabelCleared} sx={{
                    backgroundColor: 'var(--ui-success-pastel)',
                    color: "var(--ui-text )",
                }} />;
            default:
                return <p>-</p>;
        }
    }

    public renderTaskOverAllStatusText(taskStatus: string): string {
        switch (taskStatus) {
            case "PendingApproval":
                return this.labels.HubLabelPendingApproval;
            case "NotApproved":
                return this.labels.HubLabelNotApproved;
            case "RequestApproved":
                return this.labels.HubLabelRequestApproved;
            case "ReadyForPrep":
                return this.labels.HubLabelReadyForPrep;
            case "PrepareForService":
                return this.labels.HubLabelPrepareForService;
            case "InPreparation":
                return this.labels.HubLabelInPreparation;
            case "ReadyForDespatchInTime":
                return this.labels.HubLabelReadyForDispatchInTime;
            case "ReadyForDespatchOutOfTime":
                return this.labels.HubLabelReadyForDispatchOutOfTime;
            case "CancelledCharged":
                return this.labels.HubLabelCancelledCharged;
            case "CancelledNoCharge":
                return this.labels.HubLabelCancelledNoCharge;
            case "DeliveryIssue":
                return this.labels.HubLabelDeliveryIssue;
            case "Delivered":
                return this.labels.HubLabelDelivered;
            case "ClearUpScheduled":
                return this.labels.HubLabelClearUpScheduled;
            case "ClearUpExcess":
                return this.labels.HubLabelClearUpExcess;
            case "Cleared":
                return this.labels.HubLabelCleared;
            default:
                return "-";
        }
    }
    
}

export { GetCateringStatus };

interface Props {}