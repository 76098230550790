import { IbssComponent } from "../../Core/BaseComponent/IbssComponent"

export default class DeleteIcon extends IbssComponent<IProps, {}>
{
    public render(): JSX.Element
    {
        const { className } = this.props;

        return (
            <svg className={className} width="16" height="16" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <path d="M 1 16 C 1 17.1 1.9 18 3 18 L 11 18 C 12.1 18 13 17.1 13 16 L 13 4 L 1 4 L 1 16 Z M 14 1 L 10.5 1 L 9.5 0 L 4.5 0 L 3.5 1 L 0 1 L 0 3 L 14 3 L 14 1 Z"></path>
            </svg>
        );
    }
}

export interface IProps
{
    className?: string;
}
