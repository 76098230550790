import { Component } from 'react';
export default class Bikestore extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4343 21.6368C23.5819 21.6368 21.2696 23.9491 21.2696 26.8015C21.2696 29.6539 23.5819 31.9663 26.4343 31.9663C29.2868 31.9663 31.5991 29.6539 31.5991 26.8015C31.5991 23.9491 29.2868 21.6368 26.4343 21.6368ZM19.7696 26.8015C19.7696 23.1207 22.7535 20.1368 26.4343 20.1368C30.1152 20.1368 33.0991 23.1207 33.0991 26.8015C33.0991 30.4824 30.1152 33.4663 26.4343 33.4663C22.7535 33.4663 19.7696 30.4824 19.7696 26.8015Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08703 26.9271C7.13083 26.8833 7.14465 26.8324 7.14465 26.8019C7.14465 26.7714 7.13083 26.7205 7.08703 26.6767C7.04323 26.6329 6.99234 26.6191 6.96183 26.6191C6.93131 26.6191 6.88042 26.6329 6.83662 26.6767C6.79282 26.7205 6.779 26.7714 6.779 26.8019C6.779 26.8559 6.79221 26.8928 6.80062 26.9108C6.80901 26.9288 6.81701 26.9379 6.82143 26.9423C6.82584 26.9467 6.83493 26.9547 6.85292 26.9631C6.87095 26.9715 6.90788 26.9847 6.96183 26.9847C6.99234 26.9847 7.04324 26.9709 7.08703 26.9271ZM5.64465 26.8019C5.64465 26.0636 6.22351 25.4847 6.96183 25.4847C7.34754 25.4847 7.67048 25.67 7.88209 25.8817C8.09369 26.0933 8.279 26.4162 8.279 26.8019C8.279 27.5402 7.70014 28.1191 6.96183 28.1191C6.22352 28.1191 5.64465 27.5402 5.64465 26.8019Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5734 26.9271C26.6172 26.8833 26.631 26.8324 26.631 26.8019C26.631 26.7714 26.6172 26.7205 26.5734 26.6767C26.5296 26.6329 26.4787 26.6191 26.4482 26.6191C26.4177 26.6191 26.3668 26.6329 26.323 26.6767C26.2792 26.7205 26.2654 26.7714 26.2654 26.8019C26.2654 26.8699 26.2911 26.9091 26.3102 26.9292C26.3343 26.9545 26.3827 26.9847 26.4482 26.9847C26.4787 26.9847 26.5296 26.9709 26.5734 26.9271ZM25.131 26.8019C25.131 26.0636 25.7099 25.4847 26.4482 25.4847C27.2206 25.4847 27.7654 26.139 27.7654 26.8019C27.7654 27.5402 27.1865 28.1191 26.4482 28.1191C25.7099 28.1191 25.131 27.5402 25.131 26.8019Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.74997 26.8273C1.74998 29.6796 4.06225 31.9919 6.91459 31.9919C9.76692 31.9919 12.0792 29.6796 12.0792 26.8273C12.0792 23.9749 9.76692 21.6627 6.91459 21.6627C4.06225 21.6627 1.74997 23.9749 1.74997 26.8273ZM6.91459 33.4918C3.23386 33.4918 0.25003 30.508 0.25003 26.8273C0.25003 23.1465 3.23385 20.1627 6.91459 20.1627C10.5953 20.1627 13.5791 23.1465 13.5791 26.8273C13.5791 30.508 10.5953 33.4918 6.91459 33.4918Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0496 13.2848C22.4446 13.1601 22.8659 13.3792 22.9906 13.7742C23.1051 14.1367 23.2197 14.472 23.3513 14.8571C23.3559 14.8703 23.3604 14.8835 23.365 14.8969C23.5019 15.2975 23.6556 15.7483 23.8399 16.3219C23.84 16.3222 23.8399 16.3216 23.8399 16.3219L27.1617 26.5707C27.2894 26.9647 27.0735 27.3877 26.6795 27.5154C26.2854 27.6431 25.8625 27.4272 25.7348 27.0332L22.4122 16.7818C22.2321 16.2215 22.082 15.7813 21.9456 15.382C21.9403 15.3667 21.9351 15.3515 21.93 15.3363C21.7999 14.9559 21.6802 14.6055 21.5603 14.2259C21.4355 13.8309 21.6546 13.4096 22.0496 13.2848Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5796 17.2891C10.7167 17.0698 10.957 16.9366 11.2156 16.9366H23.2477C23.5346 16.9366 23.7962 17.1002 23.9219 17.358C24.0476 17.6158 24.0152 17.9227 23.8385 18.1487L16.4248 27.6286C16.2655 27.8323 16.0124 27.9395 15.7552 27.9124C15.4981 27.8852 15.273 27.7275 15.1597 27.495L10.5413 18.0151C10.4281 17.7827 10.4426 17.5083 10.5796 17.2891ZM12.4152 18.4366L15.9826 25.7591L21.7091 18.4366H12.4152Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5353 17.0082C11.91 17.1847 12.0707 17.6316 11.8941 18.0063L7.59981 27.1216C7.42328 27.4963 6.97641 27.6569 6.6017 27.4804C6.22698 27.3039 6.06633 26.857 6.24286 26.4823L10.5372 17.367C10.7137 16.9923 11.1606 16.8316 11.5353 17.0082Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.88286 14.8612C10.2575 14.6844 10.7044 14.8449 10.8812 15.2195L11.894 17.3667C12.0707 17.7413 11.9102 18.1883 11.5356 18.365C11.161 18.5417 10.714 18.3812 10.5373 18.0066L9.52451 15.8594C9.34779 15.4848 9.50824 15.0379 9.88286 14.8612Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.81175 15.1651C7.90662 14.7619 8.31039 14.512 8.71359 14.6068C8.97746 14.6689 9.54495 14.7188 10.2944 14.7496C11.0194 14.7794 11.8488 14.7895 12.593 14.7895C13.0073 14.7895 13.343 15.1252 13.343 15.5395C13.343 15.9537 13.0073 16.2895 12.593 16.2895C11.8384 16.2895 10.9865 16.2793 10.2328 16.2483C9.50352 16.2183 8.79487 16.1669 8.37003 16.067C7.96683 15.9721 7.71688 15.5683 7.81175 15.1651Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5255 14C21.5255 13.5858 21.8612 13.25 22.2755 13.25H25.2329C26.3033 13.25 27.2387 14.0807 27.2387 15.2154C27.2387 16.2858 26.4081 17.2213 25.2734 17.2213H24.9898C24.5756 17.2213 24.2398 16.8855 24.2398 16.4713C24.2398 16.057 24.5756 15.7213 24.9898 15.7213H25.2734C25.5161 15.7213 25.7387 15.5224 25.7387 15.2154C25.7387 14.9726 25.5398 14.75 25.2329 14.75H22.2755C21.8612 14.75 21.5255 14.4142 21.5255 14Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4343 8.63677C23.5819 8.63677 21.2696 10.9491 21.2696 13.8015C21.2696 14.2157 20.9338 14.5515 20.5196 14.5515C20.1054 14.5515 19.7696 14.2157 19.7696 13.8015C19.7696 10.1207 22.7535 7.13678 26.4343 7.13678C30.1152 7.13678 33.0991 10.1207 33.0991 13.8015C33.0991 15.651 32.3447 17.3257 31.1288 18.5324C30.8348 18.8241 30.3599 18.8223 30.0681 18.5283C29.7764 18.2343 29.7782 17.7594 30.0722 17.4677C31.016 16.5311 31.5991 15.2351 31.5991 13.8015C31.5991 10.9491 29.2868 8.63677 26.4343 8.63677Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.74998 13.8273C1.74998 15.1477 2.2445 16.3508 3.05959 17.2643C3.33536 17.5733 3.30837 18.0474 2.99932 18.3232C2.69026 18.5989 2.21618 18.5719 1.94041 18.2629C0.889583 17.0852 0.25003 15.53 0.25003 13.8273C0.25003 10.1465 3.23385 7.16271 6.91459 7.16271C8.95086 7.16271 10.7744 8.07685 11.9957 9.51447C12.2639 9.83014 12.2254 10.3034 11.9097 10.5716C11.594 10.8398 11.1207 10.8013 10.8526 10.4856C9.90408 9.36911 8.49222 8.66266 6.91459 8.66266C4.06225 8.66266 1.74997 10.9749 1.74998 13.8273Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0496 0.284831C22.4446 0.160098 22.8659 0.379183 22.9906 0.774169C23.1051 1.13667 23.2197 1.472 23.3513 1.85707C23.3559 1.87028 23.3604 1.88355 23.365 1.89687C23.5014 2.29616 23.6545 2.74531 23.8381 3.31626L26.2097 10.2575C26.3436 10.6495 26.1345 11.0758 25.7425 11.2097C25.3505 11.3437 24.9242 11.1345 24.7903 10.7425L22.4121 3.78184C22.232 3.22156 22.082 2.78129 21.9456 2.38198C21.9403 2.36671 21.9351 2.35148 21.9299 2.3363C21.7999 1.95586 21.6802 1.60552 21.5603 1.22587C21.4355 0.83088 21.6546 0.409564 22.0496 0.284831Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5796 4.28906C10.7167 4.06982 10.957 3.93665 11.2156 3.93665H23.2477C23.5346 3.93665 23.7962 4.10021 23.9219 4.35803C24.0476 4.61584 24.0152 4.92275 23.8385 5.14867L16.4248 14.6286C16.2655 14.8323 16.0124 14.9395 15.7552 14.9124C15.4981 14.8852 15.273 14.7275 15.1597 14.495L10.5413 5.01512C10.4281 4.78268 10.4426 4.5083 10.5796 4.28906ZM12.4152 5.43665L15.9826 12.7591L21.7091 5.43665H12.4152Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5353 4.00816C11.91 4.18469 12.0707 4.63156 11.8941 5.00628L7.59981 14.1216C7.42328 14.4963 6.97641 14.6569 6.6017 14.4804C6.22698 14.3039 6.06633 13.857 6.24286 13.4823L10.5372 4.367C10.7137 3.99229 11.1606 3.83163 11.5353 4.00816Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.88286 1.86116C10.2575 1.68445 10.7044 1.84489 10.8812 2.21952L11.894 4.36667C12.0707 4.7413 11.9102 5.18825 11.5356 5.36496C11.161 5.54167 10.714 5.38123 10.5373 5.0066L9.52451 2.85945C9.34779 2.48482 9.50824 2.03787 9.88286 1.86116Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.81175 2.16512C7.90662 1.76192 8.31039 1.51197 8.71359 1.60684C8.97746 1.66892 9.54495 1.71878 10.2944 1.74958C11.0194 1.77938 11.8488 1.78946 12.593 1.78946C13.0073 1.78946 13.343 2.12525 13.343 2.53946C13.343 2.95368 13.0073 3.28946 12.593 3.28946C11.8384 3.28946 10.9865 3.27929 10.2328 3.24832C9.50352 3.21835 8.79487 3.16693 8.37003 3.06696C7.96683 2.97209 7.71688 2.56832 7.81175 2.16512Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5255 1C21.5255 0.585786 21.8612 0.25 22.2755 0.25H25.2329C26.3033 0.25 27.2387 1.08068 27.2387 2.21537C27.2387 3.2858 26.4081 4.22125 25.2734 4.22125H24.9898C24.5756 4.22125 24.2398 3.88547 24.2398 3.47125C24.2398 3.05704 24.5756 2.72125 24.9898 2.72125H25.2734C25.5161 2.72125 25.7387 2.52236 25.7387 2.21537C25.7387 1.97264 25.5398 1.75 25.2329 1.75H22.2755C21.8612 1.75 21.5255 1.41421 21.5255 1Z" />
            </svg>
        )
    }
};