import { AccountInfo } from "@azure/msal-browser";
import { IMsalProvider } from "./IMsalProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../azure-authentication-config";

export class MsalProvider implements IMsalProvider
{
    private msal = new PublicClientApplication(msalConfig);

    public getAccount(): (AccountInfo | null)
    {
        return this.msal.getAllAccounts()[0];
    }
}