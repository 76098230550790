import { Component } from 'react';

export default class Quickstop extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 14.734C1.75 15.9386 1.90457 16.89 2.10261 17.3851C2.52654 18.445 2.7406 20.0047 2.87451 21.4286C2.94279 22.1546 2.99214 22.8715 3.03453 23.5049C3.03808 23.5578 3.04156 23.6101 3.04501 23.6616C3.0631 23.9326 3.07991 24.1845 3.09651 24.4137H7.67185C7.76723 22.1981 8.29923 20.7007 9.23543 18.8283C9.56103 18.1771 9.88939 17.0444 10.1027 15.6734C10.314 14.3149 10.4031 12.7816 10.2838 11.351C10.1635 9.90717 9.83679 8.639 9.2735 7.75383C8.73514 6.90783 7.99002 6.41367 6.90625 6.41367C4.4279 6.41367 2.9752 8.3422 2.25365 10.9278C1.89961 12.1964 1.75 13.5493 1.75 14.734ZM0.808853 10.5246C1.5873 7.73514 3.3846 4.91367 6.90625 4.91367C8.57247 4.91367 9.76486 5.73202 10.539 6.94852C11.2882 8.12585 11.649 9.67018 11.7787 11.2264C11.9094 12.7957 11.811 14.4499 11.5848 15.904C11.3606 17.3454 11.0015 18.6503 10.5771 19.4991C9.61511 21.423 9.15626 22.8079 9.15626 25.1637C9.15626 25.5779 8.82048 25.9137 8.40626 25.9137H2.40625C2.02094 25.9137 1.69831 25.6217 1.65997 25.2383C1.62055 24.8441 1.58635 24.3314 1.54884 23.7692C1.54522 23.7149 1.54157 23.6602 1.53788 23.6051C1.49551 22.9719 1.4473 22.273 1.3811 21.569C1.24606 20.1331 1.04345 18.7761 0.709891 17.9422C0.40793 17.1873 0.25 16.0137 0.25 14.734C0.25 13.4343 0.412892 11.9434 0.808853 10.5246Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.65624 28.1637C1.65624 27.7495 1.99203 27.4137 2.40624 27.4137H8.40624C8.7884 27.4137 9.10945 27.701 9.15165 28.0808C9.24264 28.8998 9.22221 30.1519 8.82239 31.2357C8.61997 31.7845 8.30508 32.331 7.81741 32.7439C7.31677 33.1677 6.67663 33.4137 5.90624 33.4137C4.92316 33.4137 4.12294 33.2022 3.48804 32.8092C2.85068 32.4146 2.43322 31.87 2.1641 31.2933C1.65561 30.2037 1.656 28.9392 1.65623 28.2086C1.65623 28.1934 1.65624 28.1784 1.65624 28.1637ZM3.16882 28.9137C3.19512 29.5055 3.27596 30.1288 3.52338 30.659C3.69176 31.0198 3.93054 31.319 4.27757 31.5338C4.62704 31.7501 5.13931 31.9137 5.90624 31.9137C6.33585 31.9137 6.62904 31.7846 6.8482 31.5991C7.08031 31.4026 7.27167 31.1054 7.41509 30.7166C7.62141 30.1573 7.69665 29.4947 7.69629 28.9137H3.16882Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9151 12.591C26.2924 13.6222 25.6683 14.3568 25.2429 14.6782C24.3322 15.3664 23.3427 16.5909 22.4921 17.7407C22.0583 18.3269 21.6456 18.915 21.2819 19.4354C21.2515 19.4788 21.2215 19.5218 21.1919 19.5641C21.0363 19.7868 20.8917 19.9937 20.7591 20.1813L16.8423 17.8163C17.9059 15.8704 18.2245 14.3135 18.3909 12.2267C18.4488 11.501 18.7531 10.3617 19.2792 9.07774C19.8005 7.8056 20.5168 6.44697 21.3584 5.28387C22.2077 4.1101 23.1429 3.19337 24.0826 2.72678C24.9808 2.28084 25.8741 2.24297 26.8018 2.80316C28.9234 4.0842 29.1701 6.48601 28.4514 9.07235C28.0987 10.3414 27.5274 11.5769 26.9151 12.591ZM29.8966 9.474C30.672 6.68374 30.5919 3.3394 27.5772 1.51909C26.1508 0.657826 24.707 0.742035 23.4155 1.38327C22.1656 2.00386 21.0585 3.13941 20.1431 4.40457C19.22 5.6804 18.4492 7.14734 17.8912 8.50898C17.3381 9.85884 16.9711 11.1615 16.8956 12.1075C16.7247 14.2517 16.4016 15.6744 15.1839 17.6911C14.9698 18.0457 15.0837 18.5067 15.4383 18.7208L20.5746 21.8222C20.9045 22.0213 21.3316 21.9381 21.5626 21.6297C21.8001 21.3127 22.0944 20.8914 22.4171 20.4295C22.4482 20.385 22.4796 20.34 22.5113 20.2947C22.8748 19.7746 23.2774 19.2012 23.6979 18.6328C24.5558 17.4734 25.4306 16.4165 26.1472 15.875C26.7959 15.3849 27.5377 14.4619 28.1992 13.3663C28.871 12.2537 29.5022 10.8933 29.8966 9.474Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0536 24.1359C20.2677 23.7813 20.1538 23.3203 19.7993 23.1062L14.663 20.0049C14.3358 19.8073 13.9125 19.8874 13.68 20.1907C13.1788 20.8447 12.5491 21.9271 12.3311 23.0616C12.2208 23.636 12.2078 24.2667 12.4119 24.8722C12.6214 25.4938 13.0422 26.0352 13.7017 26.4334C14.5433 26.9415 15.3376 27.1742 16.0843 27.1659C16.8338 27.1576 17.4727 26.9071 18.0012 26.5526C18.9997 25.8826 19.653 24.8 20.0304 24.1744C20.0383 24.1614 20.046 24.1486 20.0536 24.1359ZM18.3711 23.9961C18.0427 24.4892 17.6513 24.981 17.1654 25.307C16.8348 25.5288 16.4758 25.6614 16.0677 25.666C15.6567 25.6705 15.1336 25.5457 14.4771 25.1493C14.1093 24.9273 13.925 24.6652 13.8333 24.3931C13.7362 24.1049 13.726 23.7516 13.8042 23.3447C13.9167 22.7592 14.1948 22.1531 14.4954 21.6559L18.3711 23.9961Z" />
            </svg>
        )
    }
};