import{ Component } from 'react';

export default class SpacesIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 12H16L21 21H12H3L8 12Z" stroke="#191C1D" stroke-width="2" stroke-linejoin="round"/>
            <path d="M12 3C10.3425 3 9 4.3425 9 6C9 7.6575 10.3425 9 12 9C13.6575 9 15 7.6575 15 6C15 4.3425 13.6575 3 12 3V3Z" stroke="#191C1D" stroke-width="2"/>
            <path d="M2 7C3.6575 7 5 8.3425 5 10C5 11.6575 3.6575 13 2 13" stroke="#191C1D" stroke-width="2"/>
            <path d="M22 7C20.3425 7 19 8.3425 19 10C19 11.6575 20.3425 13 22 13" stroke="#191C1D" stroke-width="2"/>
            </svg>
        )
    }
};
