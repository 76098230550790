import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { VisitId } from "./VisitsRepository";

export class CheckoutVisitsV2Endpoint implements ICheckoutVisitsV2Endpoint
{
    public async execute(nodeId: number, visits: VisitId[]): Promise<void>
    {
        try
        {
            const response: AxiosResponse<void> = await axios.post(`${apis.allApiv2}${nodeId}/visits/checkout`, visits);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICheckoutVisitsV2Endpoint
{
    execute(nodeId: number, visits: VisitId[]): Promise<void>;
}