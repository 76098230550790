import React, { Component } from 'react';

export default class Plantroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.996 4.78703C18.0904 4.46852 18.383 4.25 18.7152 4.25H22.9051C23.1456 4.25 23.3715 4.36537 23.5126 4.56022C23.6536 4.75507 23.6927 5.00575 23.6175 5.23425L21.9978 10.1607H25C25.2599 10.1607 25.5013 10.2952 25.638 10.5163C25.7746 10.7375 25.7871 11.0136 25.6708 11.246L20.6264 21.3354C20.4697 21.6488 20.1169 21.8119 19.7767 21.7283C19.4364 21.6448 19.1994 21.3367 19.2057 20.9864L19.3159 14.9184H16C15.7634 14.9184 15.5407 14.8067 15.3991 14.6171C15.2575 14.4276 15.2137 14.1823 15.2809 13.9554L17.996 4.78703ZM19.2753 5.75L17.0043 13.4184H20.0796C20.2809 13.4184 20.4737 13.4993 20.6148 13.6429C20.7558 13.7865 20.8332 13.9808 20.8295 14.182L20.7656 17.7028L23.7865 11.6607H20.9617C20.7212 11.6607 20.4952 11.5453 20.3542 11.3504C20.2131 11.1556 20.1741 10.9049 20.2492 10.6764L21.869 5.75H19.2753Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 17C10.75 17.4142 10.4142 17.75 10 17.75H4C3.58579 17.75 3.25 17.4142 3.25 17C3.25 16.5858 3.58579 16.25 4 16.25H10C10.4142 16.25 10.75 16.5858 10.75 17Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 20C10.75 20.4142 10.4142 20.75 10 20.75H4C3.58579 20.75 3.25 20.4142 3.25 20C3.25 19.5858 3.58579 19.25 4 19.25H10C10.4142 19.25 10.75 19.5858 10.75 20Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 23C10.75 23.4142 10.4142 23.75 10 23.75H4C3.58579 23.75 3.25 23.4142 3.25 23C3.25 22.5858 3.58579 22.25 4 22.25H10C10.4142 22.25 10.75 22.5858 10.75 23Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 29.25H10.75V31C10.75 32.5188 9.51878 33.75 8 33.75H6C4.48122 33.75 3.25 32.5188 3.25 31V29.25ZM4.75 30.75V31C4.75 31.6904 5.30964 32.25 6 32.25H8C8.69036 32.25 9.25 31.6904 9.25 31V30.75H4.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.25 29.25H29.75V31C29.75 32.5188 28.5188 33.75 27 33.75H25C23.4812 33.75 22.25 32.5188 22.25 31V29.25ZM23.75 30.75V31C23.75 31.6904 24.3096 32.25 25 32.25H27C27.6904 32.25 28.25 31.6904 28.25 31V30.75H23.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13.75C2.30964 13.75 1.75 14.3096 1.75 15V28C1.75 28.6904 2.30964 29.25 3 29.25H30C30.6904 29.25 31.25 28.6904 31.25 28V15C31.25 14.3096 30.6904 13.75 30 13.75H27.6406V12.25H30C31.5188 12.25 32.75 13.4812 32.75 15V28C32.75 29.5188 31.5188 30.75 30 30.75H3C1.48122 30.75 0.25 29.5188 0.25 28V15C0.25 13.4812 1.48122 12.25 3 12.25H13V13.75H3Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 3C12.25 2.0335 13.0335 1.25 14 1.25H27C27.9665 1.25 28.75 2.0335 28.75 3V23C28.75 23.9665 27.9665 24.75 27 24.75H14C13.0335 24.75 12.25 23.9665 12.25 23V3ZM14 2.75C13.8619 2.75 13.75 2.86193 13.75 3V23C13.75 23.1381 13.8619 23.25 14 23.25H27C27.1381 23.25 27.25 23.1381 27.25 23V3C27.25 2.86193 27.1381 2.75 27 2.75H14Z" />
            </svg>
        )
    }
};