import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { ICreateV2BookingRequest } from "./CreateV2BookingEndpoint";
import { IbssRequestConfig } from "../ApiClient";

export class CreateV1BookingEndpoint implements ICreateV1BookingEndpoint
{
    public async execute(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>
    {
        try
        {
            const { Booking_Name: Name, Booking_Description: Description, Booking_Start, Booking_End, ...bookingDetails } = booking;

            const utcBooking = {
                ...bookingDetails,
                Name,
                Description,
                Start: DateTime.fromISO(Booking_Start).toUTC().toISO(),
                End: DateTime.fromISO(Booking_End).toUTC().toISO(),
            };
        
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const response = await axios.post<ICreateBookingResponse>(apis.allApi + nodeId + `/Bookings`, utcBooking, axiosConfig);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface ICreateV1BookingEndpoint
{
    execute(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>;
}

export interface ICreateV1BookingRequest extends Omit<ICreateV2BookingRequest, "Booking_Parties" | "Booking_Parties_Visitors" | "Cost_Code_Allocation">
{
    Booking_Parties?: string;
    Booking_Parties_Visitors?: string;
    Cost_Code_Allocation?: string;
}

export interface ICreateBookingResponse
{
    Node_Id: number;
    Booking_Id: string;
    Space_Id: string;
    Booking_Start: string;
    Booking_End: string;
    Booking_Details?: IBookingDetails[];
    Failed_Bookings_Details?: IFailedBookingDetails[];
}

export interface IBookingDetails
{
    Node_Id: number;
    Space_Id: string,
    StartDate: string,
    EndDate: string,
    BookingId: string
}

export interface IFailedBookingDetails
{
    Node_Id: number,
    Space_Id: string,
    Booking_Start: string,
    Booking_End: string,
}
