import * as React from 'react';
import { FormHelperText } from '@mui/material';
import { FormHelperTextProps } from '@mui/material/FormHelperText';

class IbssFormHelperText extends React.Component<FormHelperTextProps>{
    render(){
        return <FormHelperText {...this.props}/>
    }
}

export default IbssFormHelperText;