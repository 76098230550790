import { ICreateTagEndpoint, ITag } from "./CreateTagEndpoint";
import { IGetTagsEndpoint, ITagDetails } from "./GetTagsEndpoint";
import { IUpdateTag, IUpdateTagEndpoint } from "./UpdateTagEndpoint";

export class TagRepository implements ITagRepository
{
    private createTagEndpoint: ICreateTagEndpoint;
    private getTagsEndpoint: IGetTagsEndpoint;
    private updateTagEndpoint: IUpdateTagEndpoint;

    constructor(
        createTagEndpoint: ICreateTagEndpoint,
        getTagsEndpoint:IGetTagsEndpoint,
        updateTagEndpoint:IUpdateTagEndpoint
    )
    {
        this.createTagEndpoint = createTagEndpoint;
        this.getTagsEndpoint = getTagsEndpoint;
        this.updateTagEndpoint = updateTagEndpoint;
    }

    public async createTag(nodeId: number, payload: ITag): Promise<void>
    {
        return this.createTagEndpoint.execute(nodeId, payload);
    }

    public getMany(buildingId: number): Promise<ITagDetails[]>
    {
        return this.getTagsEndpoint.execute(buildingId);
    }

    public async updateTag(buildingId: number, tagId: string, payload: IUpdateTag): Promise<void>
    {
        return this.updateTagEndpoint.execute(buildingId, tagId, payload);
    }
}

export interface ITagRepository
{
    createTag(nodeId: number, payload: ITag): Promise<void>;
    getMany(buildingId: number):Promise<ITagDetails[]>
    updateTag(buildingId: number, tagId: string, payload: IUpdateTag): Promise<void>
}