import { Component } from 'react';

export default class SecurityIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34406 11.8325C3.43569 11.8325 2.69931 12.5444 2.69931 13.4226V21.1655C2.69931 22.0436 3.43569 22.7555 4.34406 22.7555H20.3625C21.2709 22.7555 22.0073 22.0436 22.0073 21.1655V13.4226C22.0073 12.5444 21.2709 11.8325 20.3625 11.8325H4.34406ZM1.41211 13.4226C1.41211 11.8572 2.72479 10.5881 4.34406 10.5881H20.3625C21.9818 10.5881 23.2945 11.8572 23.2945 13.4226V21.1655C23.2945 22.7309 21.9818 23.9999 20.3625 23.9999H4.34406C2.72479 23.9999 1.41211 22.7309 1.41211 21.1655V13.4226Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7055 1.41176C11.3317 1.41176 10.0441 1.80258 9.12219 2.45987C8.20321 3.11507 7.7643 3.94333 7.7643 4.73203C7.7643 5.12187 7.44827 5.43791 7.05842 5.43791C6.66857 5.43791 6.35254 5.12187 6.35254 4.73203C6.35254 3.38512 7.10354 2.16525 8.30262 1.31035C9.49876 0.457551 11.0839 0 12.7055 0C14.327 0 15.9122 0.457551 17.1083 1.31035C18.3074 2.16525 19.0584 3.38512 19.0584 4.73203V10.5882C19.0584 10.9781 18.7424 11.2941 18.3525 11.2941C17.9627 11.2941 17.6467 10.9781 17.6467 10.5882V4.73203C17.6467 3.94333 17.2077 3.11507 16.2888 2.45987C15.3669 1.80258 14.0793 1.41176 12.7055 1.41176Z"/>
            </svg>
        )
    }
};
