import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import Header from "../../../../Components/Layout/Header/Header";
import Sidebar from "../../../../Components/Layout/Sidebar/Sidebar";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { withRouter } from "react-router-dom";
import History from "history";
import { RouterProps } from "react-router-dom";
import { appContext } from "../../../../AppContext";
import { Props as SpaceCardProps } from "../../../../Components/Cards/SpaceAnalyticsCard/SpaceAnalyticsCard";
import IbssbreadcrumbLeft from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import { UPDATE_MAIN_PAGE_TITLE, ONELENS_SPACEANALYTICS_OVERVIEW } from "../../../../app/constants";
import { DateTime } from "luxon";
import SpaceAnalyticsDetailCard from "../../../../Components/Cards/SpaceAnalyticsDetailCard/SpaceAnalyticsDetailCard";
import { Link } from "react-router-dom";
import { SpaceCardRes, SearchCriteriaValue, UserSearchCriteria } from './IAnalyticsModel';
import { dateConverter, getBuildingTimeZoneByNodeId, periodType } from "../../../../Common/Helper";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssSpaceAnalyticsDetailCriteria from "./IbssSpaceAnalyticsDetailCriteria";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { ISpacesDailySummary } from "../../../../Providers.Api/SpacesDailySummaries/SpacesDailySummaryRepository";
import { DateHelper } from "../../../../Common/DateHelper";

enum SearchResults {
  NoResults,
}

type SearchState = {
  spaceCardResponse: Array<SpaceCardRes>
  isLoading: boolean,
  spaceCards: Array<SpaceCardProps>,
  openDrawer: boolean,
  searchData: Array<string>,
  searchCriteria: Array<SearchCriteriaValue>,
  searchResults: SearchResults,
  zone: string,
  userSearchCriteria: UserSearchCriteria,
  graphResponse: Array<ISpacesDailySummary>,
  utilizationResponse: Array<ISpacesDailySummary>,
  metaZone: string,
  imageURI: string,
  totalCapacity: number,
}


class ViewSpaceDailySummary extends Component<any, SearchState> {
  private labels = appContext().labels;
  private apiClient = appContext().apiClient;
  private session = appContext().sessionStorageProvider;
  private local = appContext().localStorageProvider;
  private get apiCache() {return appContext().apiCache;}
  private history: History.History;

  private buildingid_ = this.session.getBuildingId();

  constructor(props: RouterProps) {
    super(props);
    this.history = props.history;
    this.state = {
      isLoading: false,
      spaceCards: [],
      openDrawer: false,
      searchData: ["spacename", "date"],
      searchCriteria: [],
      searchResults: SearchResults.NoResults,
      zone: "",
      userSearchCriteria: {
        selectedBuildingOption: "",
        selectedWorkspaceTypes: "",
        selectedSpaceTypes: "",
        selectedFloor: "",
        selectedZone: "",
        fmsfc_start_date_for_filter_modal: "",
        End_Date_For_filter_modal: "",
      },
      spaceCardResponse: [],
      graphResponse: [{
        Avg_Occupancy: 0, Booked_Duration: 0, Env_Zone_Id: "", IsWorking_Day: 0, Max_Occupancy: 0, Node_Id: 0, Period_Capacity_Utilisation: 0, Period_Duration: 0, Period_End: "", Period_Peak_Utilisation: 0,
        Period_Space_Utilisation: 0, Period_Start: "", Record_Id: "", S5: 0, S6: 0, S7: 0, S8: 0, S9: 0, S10: 0, S11: 0, S12: 0, S13: 0, S14: 0, S15: 0, S16: 0, S17: 0, S18: 0, S19: 0, S24: 0, S25: 0, S26: 0, S27: 0, S28: 0, S29: 0,
        S30: 0, S31: 0, S36: 0, S37: 0, S38: 0, S39: 0, Space_Class: "", Space_Id: "", Space_IsEnabled: 0, Space_Name: "", Space_Type: "", Space_Type_Label: "", Space_Used: 0, Space_Work_Type: "", Summary_Date: "", Summary_Day_Type: ""
      }],
      utilizationResponse: [],
      metaZone: "",
      imageURI: "",
      totalCapacity: 0,
    };
  }

  public async componentDidMount() {
    const spaceList = await this.apiCache.getSpacesByBuilding(this.buildingid_);
    const filterSpace = spaceList.filter((val: { Space_Name: string, Node_Id: string | number }) => val.Space_Name === this.props.location.state.spaceName && val.Node_Id === parseInt(this.props.location.state.nodeId));
    this.setState({ metaZone: filterSpace.map((val: { Meta_Loc_Zone: string }) => val.Meta_Loc_Zone).toString(), imageURI: filterSpace.map((val: { ImageURI: string }) => val.ImageURI).toString() })
    const totalCapacity = spaceList.filter((val: any) => val.Space_Name === this.props.location.state.spaceName).map((val: any) => val.Space_Capacity)
    this.setState({
      totalCapacity: totalCapacity.reduce(
        (acc: number, curr: number) => acc + curr,
        0
      )
    });

    try {
      
      const startDate = DateTime.fromISO((this.props.onelensSpaceAnalyticsDetails.startDate)).setZoneByNode(this.props.onelensDateBuildingNodeId).toUTC();
      const endDate = DateTime.fromISO((this.props.onelensSpaceAnalyticsDetails.endDate)).setZoneByNode(this.props.onelensDateBuildingNodeId).toUTC();

      this.setState({ isLoading: true });
      apis
        .getOverviewWorkSpaceClassification(
          this.buildingid_,
          "Total",
          this.props.onelensSpaceAnalyticsDetails.periodType,
          startDate,
          endDate,
          "Building"
        )
        .then((res) => {
          this.setState({ spaceCardResponse: res });
          this.setState({ isLoading: false });
        });
    } catch {
      this.setState({ isLoading: false });
    }

    if (this.props.onelensSpaceAnalyticsDetails)
    {
      const spaceAnalyticsFilter = this.session.getSpaceAnalyticsCriteria();
      const filter = {
        startDate: this.props.onelensSpaceAnalyticsDetails.startDate, 
        endDate: this.props.onelensSpaceAnalyticsDetails.endDate, 
        spaceId: this.props.location.state.spaceId,
        periodType: spaceAnalyticsFilter.periodType != null ? spaceAnalyticsFilter.periodType : this.props.onelensSpaceAnalyticsDetails.periodType,
      }
      this.session.setSpaceAnalyticsCriteria(filter);
      this.getBookingGraph(this.session.getBuildingId(), this.props.onelensSpaceAnalyticsDetails.startDate, this.props.onelensSpaceAnalyticsDetails.endDate, this.props.location.state.spaceId);
    }
    else{
      const searchCriteria = this.session.getSpaceAnalyticsCriteria();
      this.getBookingGraph(this.session.getBuildingId(), searchCriteria.startDate.toISO(), searchCriteria.endDate.toISO(), searchCriteria.spaceId);
    }
    const { dispatch } = this.props;
    dispatch({
      type: UPDATE_MAIN_PAGE_TITLE,
      mainPageTitle: this.labels.HubMenuSpaceDetails,
    });
    await dispatch({
      type: ONELENS_SPACEANALYTICS_OVERVIEW,
      payload: {
        buildingOption: this.session.getBuildingName(),
        floor: "Any",
        classType: "Work",
        WorkSpace: "Any",
        periodTypeValue: this.props.onelensSpaceAnalyticsOverviewperiodTypeValue,
        periodType: this.props.onelensSpaceAnalyticsOverviewperiodType,
        buildingNodeId: this.session.getBuildingId(),
        fmsfc_start_date_for_filter_modal: this.props.onelensSpaceAnalyticsOverviewstart_date,
        End_Date_For_filter_modal: this.props.onelensSpaceAnalyticsOverviewend_date
      },
    });
    this.setState({ searchCriteria: this.makeSearchCriteria() });
  }

  // Breadcrumb Call
  public makeSearchCriteria(): SearchCriteriaValue[] {
    let arr: any = [];
    this.state.searchData.map((option: string) => {
      if (option === "spacename") {
        if (
          this.props.location.state.spaceType !== "" || this.props.location.state.spaceType !== undefined
        ) {
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Desk.svg`,
            value: this.props.location.state.spaceType,
          });
        } else {
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Desk.svg`,
            value: this.props.location.state.spaceType,
          });
        }
      }
      if (option === "date") {
        if (
          this.props.onelensSpaceAnalyticsDetails != null &&
          this.props.onelensSpaceAnalyticsDetails != undefined
        ) {

          const startDate = DateTime.fromJSDate(new Date(this.props.onelensSpaceAnalyticsDetails.startDate.slice(0, 10))).toFormat('dd-MM-yyyy');;
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Calendar.svg`,
            value: startDate,
          });
        }
      }
    });
    return arr;
  }

  public async handleGetSearchData(buildingId: number, filterData: { buildingNodeId: number, buildingOption: string, endDate: string, periodType: number, periodTypeValue: string, spaceName: string, startDate: string }) {
    this.setState({ isLoading: true });
    try {
      const response = await apis.getOverviewWorkSpaceClassification(
        buildingId,
        "Total",
        filterData.periodType,
        DateTime.fromISO(filterData.startDate).startOf('day').setZoneByNode(this.props.onelensDateBuildingNodeId).toUTC(),
        DateTime.fromISO(filterData.endDate).endOf('day').setZoneByNode(this.props.onelensDateBuildingNodeId).toUTC(),
        "Building"
      );
      this.setState({ spaceCardResponse: response });
      this.setState({ isLoading: false });
      this.setState({ searchCriteria: this.makeSearchCriteria() });
      // this.updateSearchResults(this.state.searchCriteria);

    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  // Booking graph call
  public async getBookingGraph(nodeId: number, startDate: string, endDate: string, spaceName: string) {
    const StartDate = DateTime.fromISO(dateConverter(startDate)).setZoneByNode(nodeId).startOf('day').toUTC().toISO();
    const EndDate = DateTime.fromISO(dateConverter(endDate)).setZoneByNode(nodeId).endOf('day').toUTC().toISO();

    this.apiClient.spacesDailySummaries.getSpacesDailySummary(nodeId, StartDate, EndDate, spaceName).then((res) => {
      this.setState({ graphResponse: res });
      this.setState({ utilizationResponse: res });
    });
  }

  public async updateSearchResults(
    searchCriteria: SearchCriteriaValue[]
  ): Promise<void> {
    this.setState({
      isLoading: false,
      searchCriteria: searchCriteria,
    });
  }

  public toggleFilterCriteraDrawer() {
    this.setState({
      openDrawer: !this.state.openDrawer,
    });
  }

  public editSearchCriteria() {
    this.setState({
      openDrawer: true,
    });
  }

  public render(): JSX.Element {
    const filterCriteraDrawerData = {
      ...this.props,
      open: this.state.openDrawer,
      toggleDrawer: () => this.toggleFilterCriteraDrawer(),
      updateSearchResults: async(buildingId: number, filterData: { buildingNodeId: number, buildingOption: string, endDate: string, periodType: number, periodTypeValue: string, spaceName: string, startDate: string }) => {
        this.setState({ searchCriteria: this.makeSearchCriteria() });
        await this.handleGetSearchData(buildingId, filterData);
        await this.getBookingGraph(buildingId, filterData.startDate, filterData.endDate, this.props.location.state.spaceId);
      },
    };

    const detailsData = {
      buildingNodeId: this.props.onelensDateBuildingNodeId,
      buildingname: this.props.onelensSpaceAnalyticsFilterCriteriabuildingOptions,
      classtype: this.props.onelensSpaceAnalyticsFilterCriteriabuildingType,
      periodtype: this.props.onelensSpaceAnalyticsFilterCriteriaperiodType,
      startdate: this.props.onelensDateBuildingperiodstartDate,
      enddate: this.props.onelensDateBuildingperiodEndDate,
      floortype: this.props.onelensSpaceAnalyticsFilterCriteriaFloor,
      workspaceType: this.props.onelensSpaceAnalyticsFilterCriteriaWorkSpace,
      groupby: this.props.onelensDateBuldingGroupBy,
      metabookable: this.props.onelensDateBuildingmetaBookable
    }
    return (
      <>
        <link
          rel="stylesheet"
          href="/src/pages/Flex/Search/SearchComponent.css"
        ></link>
        <div key="/Pages/Flex/Search" className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
          {this.state.isLoading && <Spinner />}
          <div
            className="rightPanel">
            <div
              className="rightPanel-main-content"
            >
              <div className="space-box-cont">
                <div className="left-space-box-cont flex-row-bredcrumb">
                  <IbssbreadcrumbLeft value={this.state.searchCriteria} />
                </div>
                <div className="right-space-box-cont">
                  <div className=" d-flex">
                    <div className="ibss-breadcrumb text-decoration-none">
                      <Link
                        className="text-decoration-none"
                        to={{
                          pathname: "/space-analytics-analytics",
                          state: { ...detailsData },
                        }}
                      >
                        <IbssButtonRedo
                          sx={{ marginRight: 2 }}
                          variant="contained"
                          color="secondary"
                        >
                          {this.labels.HubButtonBacklabel}
                        </IbssButtonRedo>
                      </Link>
                    </div>
                    {!this.state.openDrawer ? (
                      <IbssButtonRedo
                        variant="contained"
                        size="small"
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={() => this.editSearchCriteria()}
                      >
                        {" "}
                        {this.labels.HubButtonEditsearchcriteria}
                      </IbssButtonRedo>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {this.state.openDrawer ? (
                  <IbssSpaceAnalyticsDetailCriteria
                    {...filterCriteraDrawerData}
                  />
                ) : (
                  ""
                )}
              </div>
              <br />
            </div>
            <div
              className={"search-results-cont mt-0 search-results-cont--list"}
            >
              <SpaceAnalyticsDetailCard
                capacityPer={+this.props.location.state.capacityPer}
                capacityVal={+this.props.location.state.availableCapacityTotal}
                spaceName={this.props.location.state.spaceName}
                occupanyDiff={+this.props.location.state.occupancyDiff}
                occupancyVal={+this.props.location.state.occupanyVal}
                graphResponse={this.state.graphResponse}
                utilizationResponse={this.state.utilizationResponse}
                zone={this.state.metaZone}
                imageURI={this.state.imageURI}
                spaceType={this.props.location.state.spaceType}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
    onelensSpaceAnalyticsOverview: state.onelensSpaceAnalyticsOverview,
    onelensSpaceAnalyticsFilterCriteriabuildingType: state.onelensSpaceAnalyticsFilterCriteria.buildingClassType,
    onelensSpaceAnalyticsFilterCriteriaFloor: state.onelensSpaceAnalyticsFilterCriteria.buildingFloor,
    onelensSpaceAnalyticsFilterCriteriaWorkSpace: state.onelensSpaceAnalyticsFilterCriteria.buildingWorkSpaceType,
    onelensDateBuildingNodeId: state.onelensDateBuilding.buildingNodeId,
    onelensDateBuldingGroupBy: state.onelensDateBuilding.nodeLevelName,
    onelensSpaceAnalyticsFilterCriteriabuildingOptions: state.onelensSpaceAnalyticsFilterCriteria.buildingOptions,
    onelensSpaceAnalyticsFilterCriteriaperiodType: state.onelensDateBuilding.periodtype_,
    onelensDateBuildingperiodstartDate: state.onelensDateBuilding.periodstartDate,
    onelensDateBuildingperiodEndDate: state.onelensDateBuilding.periodEndDate,
    onelensSpaceAnalyticsOverviewperiodTypeValue: state.onelensSpaceAnalyticsOverview.periodTypeValue,
    onelensSpaceAnalyticsOverviewperiodType: state.onelensSpaceAnalyticsOverview.periodType,
    onelensSpaceAnalyticsOverviewstart_date: state.onelensSpaceAnalyticsOverview.fmsfc_start_date_for_filter_modal,
    onelensSpaceAnalyticsOverviewend_date: state.onelensSpaceAnalyticsOverview.End_Date_For_filter_modal,
    onelensSpaceAnalyticsDetails: state.onelensSpaceAnalyticsDetails,
    onelensDateBuildingmetaBookable: state.onelensDateBuilding.metaBookable
  };
};

export default withRouter(connect(mapStateToProps)(ViewSpaceDailySummary));
export class Props {
  public lightModeTheme = "";
  public mainPageTitle = "";
  public onelensSpaceAnalyticsFilterCriteriabuildingType = "";
  public onelensSpaceAnalyticsFilterCriteriaFloor = "";
  public onelensSpaceAnalyticsFilterCriteriaWorkSpace = "";
  public onelensDateBuildingNodeId = 0;
  public onelensSpaceAnalyticsFilterCriteriabuildingOptions = "";
  public onelensSpaceAnalyticsFilterCriteriaperiodType = 0;
  public onelensDateBuildingperiodstartDate = "";
  public onelensDateBuildingperiodEndDate = "";
  public onelensSpaceAnalyticsOverviewperiodTypeValue = "";
  public onelensSpaceAnalyticsOverviewstart_date = "";
  public onelensSpaceAnalyticsOverviewend_date = "";
  public onelensSpaceAnalyticsDetails = "";
  public onelensDateBuildingmetaBookable = 0;

  constructor(space: Space) {
  }
}

