import { Component } from 'react';


class LoadingOverlay extends Component<IProps, any>
{
  public render(): JSX.Element
  {
    return (
      <div className="loadingOverlay">
        <div className="loadingOverlay__background" />
        <div className="text-center">
          <div className="spinner-border text-primary" role="status" />
          <p className="sr-only">Loading...</p>
          {this.props.text && <p className='text-white'>{this.props.text}</p>}
        </div>
      </div>
    )
  }
}

export default LoadingOverlay;

export interface IProps{
  text?: string;
}