export class TypeHelper
{
    public static toBoolean(value: (string | number | null)): (boolean | null)
    {
        if (value == null)
        {
            return null;
        }
        else if (<any>value instanceof String || typeof (value) == "string")
        {
            value = (<string>value).toLowerCase();
            return (value == "1" || value == "true" || value == "yes" || value == "on")
            {
                return true;
            }
            if (value == "0" || value == "false" || value == "no" || value == "off")
            {
                return false;
            }
            else
            {
                return null;
            }
        }
        else if (<any>value instanceof Number || typeof (value) == "number")
        {
            value = <number>value;
            return (value == 1 ? true : (value == 0 ? false : null));
        }
        else
        {
            throw new Error(`Could not convert '${value}' to a boolean.`);
        }
    }

    public static toNumber(value: (string | null)): number | null
    {
        if (value == null || value.trim() == '')
        {
            return null;
        }

        const valueAsNumber = Number(value);
        if (isNaN(valueAsNumber))
        {
            return null;
        }

        return valueAsNumber;
    }
}