import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse } from "../Models";
import { Space } from "./SpaceRepository";
import { ISpace } from "./UpdateV2SpaceByIdEndpoint";

export class CreateV2SpaceEndpoint implements ICreateV2SpaceEndpoint
{
    public async execute(nodeId: number, payload: ISpace): Promise<Space>
    {
        try
        {
            const response = await axios.post(`${apis.allApiv2}${nodeId}/spaces`, payload);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateV2SpaceEndpoint
{
    execute(nodeId: number, payload: ISpace): Promise<Space>;
}
