import { RouteComponentProps, RouterProps } from 'react-router';
import ViewSeries, { ISeriesBooking } from '../../../Flex/Bookings/ViewSeries/ViewSeries';
import { appContext } from '../../../../AppContext';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { IRawPagedResponse, IRawPagedResponseBookingSeries, PagedResponse } from '../../../../Providers.Api/Models';
import { CateringOrder, BookingSeriesResponse, Booking } from './DataModels';
import { DateHelper } from '../../../../Common/DateHelper';
import { getBuildingNodeIdUsingFloorNodeId } from '../../../../Common/Helper';

class ViewBookingSeries extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            fetchingSeriesData: false,
            seriesDates: [],
            bookingName: '',
            bookingDescription: '',
            bookingOwner: ''
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcViewSeries_S;
        this.setState({ fetchingSeriesData: true });
        try
        {
            const seriesData = await appContext().ibssApiClientV2.v2.byNodeid.bookings.bookingsSeries.bySeriesid.get<IRawPagedResponseBookingSeries<BookingSeriesResponse>>({
                nodeId: parseInt(this.props.match.params.buildingId),
                seriesid: this.props.match.params.seriesId,
                top: 100
            });

            await this.setStateAsync({
                fetchingSeriesData: false,
                bookingName: seriesData.Booking.Booking_Name,
                bookingDescription: seriesData.Booking.Booking_Description,
                bookingOwner: seriesData.Booking.Booking_Owner_Id,
                seriesDates: seriesData.Booking.Booking_Details.map(x => ({ 
                    start: DateHelper.fromIsoByNode(x.Booking_Start, parseInt(this.props.match.params.buildingId)),
                    end: DateHelper.fromIsoByNode(x.Booking_End, parseInt(this.props.match.params.buildingId)),
                    bookingId: x.Booking_Id,
                    spaceId: x.Space_Id,
                    nodeId: x.Node_Id,
                    bookedFor: x.Booking_Owner_Email,
                    attendeedCount: x.Booking_Party_Count,
                    catering: false,
                    resources: false
                }))
            });

            await this.updateCateringAndResourceStatuses();
        } catch (error)
        {
            this.setState({ fetchingSeriesData: false });
        }
    }

    private async updateCateringAndResourceStatuses(): Promise<void>
    {
        const buildingNodeId = parseInt(getBuildingNodeIdUsingFloorNodeId(this.props.match.params.buildingId));
        const bookingIdsMappedForFilter = this.state.seriesDates.map(x => `Booking_Id eq '${x.bookingId}'`)
        const bookingIdsFilter = bookingIdsMappedForFilter.toString().replaceAll(',', ' or ')
        const cateringOrders = await appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.get<PagedResponse<CateringOrder[]>>({
            nodeId: buildingNodeId,
            filter: `(${bookingIdsFilter})`,
            recursive: true,
            top: 100,
            select: CateringOrder,
        });
        const bookingIdsWithCatering = cateringOrders.value.map(x => x.Booking_Id);
        const bookings = await appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>({
            nodeId: buildingNodeId,
            filter: `(${bookingIdsFilter})`,
            recursive: true,
            top: 100,
            select: Booking
        });
        const bookingIdsWithResources = bookings.value.filter(x => x.Booking_Resources.length > 0).map(x => x.Booking_Id);
        await this.setStateAsync({ seriesDates: this.state.seriesDates.map(x => ({ ...x, catering: bookingIdsWithCatering.includes(x.bookingId), resources: bookingIdsWithResources.includes(x.bookingId) })) });
    }

    public seriesBookingEditRoute(nodeId: string, bookingId: string, spaceId: string): void
    {
        const url = window.location.href;
        const path = new URL(url).pathname;
        if (path.includes("oneLens"))
        {
            this.props.history.push(`/operational-services-bookings/${nodeId}/bookings/${bookingId}/${spaceId}`)
        }
        else
        {
            this.props.history.push(`/flex-my-bookings/${nodeId}/mybooking/${bookingId}/${spaceId}`)
        }
    }

    public render(): JSX.Element
    {
        return (
            <div className='m-3'>
                {
                    this.state.fetchingSeriesData &&
                    <LoadingOverlay />
                }
                <ViewSeries
                    redirectBackRoute={() => this.props.history.goBack()}
                    editRedirectRoute={(nodeId: string, bookingId: string, spaceId: string) => this.seriesBookingEditRoute(nodeId, bookingId, spaceId)}
                    bookingDates={this.state.seriesDates}
                    bookingDetails={{ bookingName: this.state.bookingName, bookingDescription: this.state.bookingDescription, bookingOwner: this.state.bookingOwner }}
                    allowTitleDescriptionEdit={false}
                />
            </div>
        )
    }
}

export default ViewBookingSeries;

export interface IProps extends RouterProps, RouteComponentProps<IMatchParams>
{
}

export interface IState
{
    fetchingSeriesData: boolean;
    seriesDates: ISeriesBooking[];
    bookingName: string;
    bookingDescription: string;
    bookingOwner: string;
}

export interface IMatchParams
{
    seriesId: string;
    buildingId: string;
}