import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse2, PagedResponse } from "../Models";
import { Filter } from "./UsersRepository";

export class GetDeletedEndpoint implements IGetDeletedEndpoint
{
    public async execute(filter?: Filter): Promise<IDeletedUser[]>
    {
        const query = `$top=2000${filter ? `&$filter=${filter.toODataString()}` : ''}`;
        try
        {
            const response = await axios.get<IRawPagedResponse2<IDeletedUser[]>>(`${apis.userApi}/deleted?${query}`);
            const pagedResponse = PagedResponse.fromRawResponse2(response.data);
            return pagedResponse.value;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetDeletedEndpoint
{
    execute(filter?: Filter): Promise<IDeletedUser[]>;
}

export interface IDeletedUser
{
    DisplayName: string,
    Email: string,
    CompanyName: string,
    FirstName: string,
    LastName: string,
    UserName: string,
    DeletedAt: string,
}
