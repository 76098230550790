import { Box, Grid } from "@mui/material";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import { DateTime } from "luxon";
import EditNoteDialog from "./EditNoteDialog";
import { DateHelper } from "../../../Common/DateHelper";
import Guid from "../../../Common/Guid";
import { relative } from "path";
import EditIcon from "./EditIcon";
import DeleteIcon from "./DeleteIcon";
import MarkdownViewer from "../../Miscellaneous/MarkdownViewer/MarkdownViewer";
import "./Styles.scss";
import { appContext } from "../../../AppContext";
import ConfirmModal from "../../Dialogs/ConfirmDialog/ConfirmModal";
import { Bool } from "reselect/es/types";
import { Button } from "react-bootstrap";

export default class Notes extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            showEdit: false,
            showConfirmDelete: false,
            selectedNoteId: "",
            selectedNoteValue: "",
            canCreate: appContext().localStorageProvider.hasRight("DATAMODEL.Notes.Create"),
            canUpdate: appContext().localStorageProvider.hasRight("DATAMODEL.Notes.Update"),
            canDelete: appContext().localStorageProvider.hasRight("DATAMODEL.Notes.Delete"),
            currentUserEmail: appContext().localStorageProvider.getUserDetails().email.toLocaleLowerCase(),
        };
    }

    private canUpdate(author: string): boolean
    {
        return (this.state.canUpdate && author.toLocaleLowerCase() == this.state.currentUserEmail);
    }

    private canDelete(author: string): boolean
    {
        return (this.state.canDelete && author.toLocaleLowerCase() == this.state.currentUserEmail);
    }

    private editNoteClicked(note: INote | null): void
    {
        if (note == null && !this.state.canCreate)
        {
            return;
        }
        if (note != null && !this.canUpdate(note.author))
        {
            return;
        }

        this.setState({
            showEdit: true,
            selectedNoteId: note?.id ?? "",
            selectedNoteValue: note?.value ?? "",
        });
    }

    private editNoteClosed(): void
    {
        this.setState({ showEdit: false });
    }

    private editDialogOked(): void
    {
        const { buildingId, notes, onChange } = this.props;
        const { selectedNoteId, selectedNoteValue } = this.state;
        const existingNote = notes.find(i => i.id == selectedNoteId) ?? null;

        if (!existingNote)
        {
            const newNote = {
                isNew: true,
                id: Guid.new().toString(),
                value: selectedNoteValue,
                author: this.state.currentUserEmail,
                time: DateHelper.now(buildingId),
            };

            const copyOfNotes = notes.map(i => i);
            copyOfNotes.push(newNote);
            onChange(copyOfNotes);
            this.props.onCreate?.(newNote);
        }
        else
        {
            const editedNote = {
                ...existingNote,
                value: selectedNoteValue,
                time: DateHelper.now(buildingId),
            };

            const copyOfNotes = notes.map(i => (i.id == selectedNoteId ? editedNote : i));
            onChange(copyOfNotes);
            this.props.onUpdate?.(editedNote);
        }
        this.setState({ showEdit: false });
    }

    private deleteNoteClicked(note: INote): void
    {
        if (!this.canDelete(note.author))
        {
            return;
        }
        this.setState({ showConfirmDelete: true, selectedNoteId: note.id });
    }

    private deleteNoteConfirmed(): void
    {
        const updatedNotes = this.props.notes.filter(i => i.id != this.state.selectedNoteId);
        const selectedNote = this.props.notes.find(i => i.id == this.state.selectedNoteId);
        this.props.onChange(updatedNotes);
        if(selectedNote)
        {
            this.props.onDelete?.(selectedNote);
        }
        this.setState({ showConfirmDelete: false, selectedNoteId: "" });
    }

    private selectedNoteChanged(value: string): void
    {
        this.setState({ selectedNoteValue: value });
    }

    public render(): JSX.Element
    {
        const orderedNotes = this.props.notes.sort((a, b) => (a.time > b.time ? -1 : 1));
        const createCssClass = 'notes__create px-10 note-buttons-focus' + (this.state.canCreate ? '' : ' notes__create--disabled');
        const updateCssClass = (author: string) => 'notes__action p-10 note-buttons-focus' + (this.canUpdate(author) ? '' : ' notes__action--disabled');
        const deleteCssClass = (author: string) => 'notes__action p-10 note-buttons-focus' + (this.canDelete(author) ? '' : ' notes__action--disabled');

        return (
            <Box className="notes">
                <Grid container direction="column" sx={{ backgroundColor: "var(--ui-background)", padding: "0 8px 4px 8px", margin: "0 0 -4px 0" }}>
                    <Grid item>
                        <Button variant="text" onClick={() => this.editNoteClicked(null)} className={createCssClass} tabIndex={0}>
                            <Box component="span" style={{ fontSize: "24px", marginRight: "5px" }}>+</Box>
                            <Box component="span" style={{ position: "relative", bottom: "4px" }}>{this.labels.funcAddNewNote_S}</Box>
                        </Button>
                    </Grid>
                    <Box className="note-scoll-height">
                    {orderedNotes.map(note =>
                        <Grid key={note.id} item sx={{ margin: "4px 0" }}>
                            <Grid container direction="row">
                                <Grid item sx={{ width: "calc(100% - 48px)", backgroundColor: "var(--ui-background-alternate)", padding: "8px" }}>
                                    <Grid container direction="column">
                                        <Grid item sx={{ padding: "0 0 8px 0" }}>
                                            <MarkdownViewer value={note.value} />
                                        </Grid>
                                        <Grid container direction="row">
                                            <Grid item sx={{ width: "calc(100% - 120px)", fontSize: "14px", color: "var(--ui-text-light)" }}>{note.author}</Grid>
                                            <Grid item sx={{ width: "120px", textAlign: "right", fontSize: "14px", color: "var(--ui-text-light)" }}>{note.time.toLocaleDateTimeString()}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ width: "20px" }}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Button variant="text" onClick={() => this.editNoteClicked(note)} className={updateCssClass(note.author)} tabIndex={0}>
                                                <EditIcon />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="text" onClick={() => this.deleteNoteClicked(note)} className={deleteCssClass(note.author)}>
                                                <DeleteIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid >
                    )
                    }
                    </Box>
                </Grid>
                <EditNoteDialog
                    markdown={this.state.selectedNoteValue}
                    open={this.state.showEdit}
                    onClose={() => this.editNoteClosed()}
                    onOk={() => this.editDialogOked()}
                    onChange={value => this.selectedNoteChanged(value)}
                />
                <ConfirmModal
                    show={this.state.showConfirmDelete}
                    modalHeading={this.labels.HubLabelConfirmation}
                    modalMessage={this.labels.HubLabelDeleteMessage}
                    okButton={() => this.deleteNoteConfirmed()}
                    handleModal={() => this.setState({ showConfirmDelete: false })}
                />
            </Box >
        );
    }
}

export interface IProps
{
    buildingId: number;
    notes: INote[];
    onChange: (notes: INote[]) => void;
    onCreate?: (note: INote) => void;
    onUpdate?: (note: INote) => void;
    onDelete?: (note: INote) => void;
}

export interface IState
{
    showEdit: boolean;
    selectedNoteId: string;
    selectedNoteValue: string;
    showConfirmDelete: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    currentUserEmail: string;
}

export interface INote
{
    isNew: boolean;
    id: string;
    value: string;
    author: string;
    time: DateTime;
}
