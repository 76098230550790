import { appContext } from "../AppContext";
import { IBookingPolicies } from "../Providers.Api/BookingPolicies/BookingPolicyRepository";

export class BookingPolicyService implements IBookingPolicyService
{
    private get api() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }

    public async getBookingPolicies(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>
    {
        const ibssApiVersion = this.local.getVersion();
        if(ibssApiVersion < 22) // 22 = R2023.02
        {
            return await this.api.bookingPolicies.getBookingPolicies(nodeId, top, skipToken);
        }
        else
        {
            const results =  await this.api.bookingPolicies.getV2BookingPolicies(nodeId, top);
            const policies = results.map(x => ({
                Node_Id: x.Node_Id,
                Booking_Policy_Id: x.Booking_Policy_Id,
                Booking_Policy_Name: x.Booking_Policy_Name,
                Booking_Policy: '',
                Booking_Policy_Object: x.Booking_Policy
            }));
            const data = { value: policies };
            return data;
        }
    }
}

export interface IBookingPolicyService
{
    getBookingPolicies(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>;
}
