import { Component } from 'react';

export default class DrinkIcon extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7498 9.01697C10.7498 9.01751 10.7498 9.01805 10.7498 9.01859L10.7498 9.01866M10.7498 9.01849L10.7498 9.01761L10.7498 9.01697M10.7498 9.01849L10.75 9.01282C10.7502 9.00622 10.7508 8.99428 10.7518 8.97737C10.7538 8.94352 10.7577 8.88989 10.765 8.81932C10.7796 8.67794 10.8077 8.47015 10.8612 8.21836C10.9688 7.71197 11.1755 7.04434 11.5684 6.38309C12.3271 5.10613 13.8345 3.75 17 3.75C17.4142 3.75 17.75 3.41421 17.75 3C17.75 2.58579 17.4142 2.25 17 2.25C13.2951 2.25 11.3026 3.89387 10.2788 5.61691C9.7805 6.45566 9.5254 7.28803 9.39398 7.90664C9.32797 8.21735 9.29228 8.47831 9.27297 8.66505C9.2633 8.75855 9.2577 8.83383 9.25447 8.88787C9.25286 8.9149 9.25183 8.93665 9.25119 8.95276L9.25048 8.97264L9.25029 8.97933L9.25022 8.98184L9.2502 8.98289C9.25019 8.98335 9.25018 8.98379 9.97078 8.99937L9.25018 8.9838C9.24123 9.39792 9.56969 9.74088 9.9838 9.74983C10.3971 9.75875 10.7396 9.43158 10.7498 9.01859" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2501 1.98546L21.2499 1.99147C21.2497 1.99874 21.2492 2.01138 21.2483 2.02901C21.2465 2.06429 21.243 2.11944 21.2366 2.1916C21.2238 2.33617 21.1993 2.54754 21.1528 2.80314C21.059 3.31778 20.8794 3.9931 20.54 4.65965C20.2018 5.32377 19.7129 5.96414 19.0041 6.43966C18.3006 6.91152 17.3379 7.25 16 7.25C15.5858 7.25 15.25 7.58579 15.25 8C15.25 8.41421 15.5858 8.75 16 8.75C17.6065 8.75 18.866 8.33848 19.8397 7.68534C20.8079 7.03586 21.451 6.17623 21.8767 5.34035C22.3011 4.5069 22.5173 3.68221 22.6285 3.07186C22.6844 2.76496 22.7145 2.50758 22.7308 2.32402C22.7389 2.23212 22.7436 2.15837 22.7463 2.10576C22.7477 2.07944 22.7485 2.05839 22.7491 2.04296L22.7496 2.02407L22.7498 2.01788L22.7498 2.0156L22.7499 2.01467C22.7499 2.01427 22.7499 2.01389 22 2L22.7499 2.01389C22.7575 1.59974 22.428 1.2578 22.0139 1.25013C21.6 1.24246 21.2582 1.57163 21.2501 1.98546Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 14C4.25 13.0335 5.0335 12.25 6 12.25H22C22.9665 12.25 23.75 13.0335 23.75 14V32C23.75 32.9665 22.9665 33.75 22 33.75H6C5.0335 33.75 4.25 32.9665 4.25 32V14ZM6 13.75C5.86193 13.75 5.75 13.8619 5.75 14V32C5.75 32.1381 5.86193 32.25 6 32.25H22C22.1381 32.25 22.25 32.1381 22.25 32V14C22.25 13.8619 22.1381 13.75 22 13.75H6Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.25 20.5C22.25 18.1528 24.1528 16.25 26.5 16.25H27.5C29.8472 16.25 31.75 18.1528 31.75 20.5V25.5C31.75 27.8472 29.8472 29.75 27.5 29.75H26.5C24.1528 29.75 22.25 27.8472 22.25 25.5V20.5ZM26.5 17.75C24.9812 17.75 23.75 18.9812 23.75 20.5V25.5C23.75 27.0188 24.9812 28.25 26.5 28.25H27.5C29.0188 28.25 30.25 27.0188 30.25 25.5V20.5C30.25 18.9812 29.0188 17.75 27.5 17.75H26.5Z" />
            </svg>
        )
    }
};
