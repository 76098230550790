import { Component } from 'react';
import '../../../styles/css/spinner.scss'

class Spinner extends Component<IProps, any>
{

  public render(): JSX.Element
  {
    return (
      <div style={{ zIndex: 99999999 }} className="loadingIndicator">
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">In Progress ...</span>
          </div>
        </div>
            {this.props.text && <p className='text-center'>{this.props.text}</p>}
      </div>
    )
  }
}

export default Spinner;

export interface IProps
{
  text?: string
}
