import { Component } from 'react';


export default class SeatingArrangementIcon extends Component
{
    render()
    {
        return (
            <svg width="21" height="21" viewBox="0 0 21 21" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281372 2.4843C0.281372 1.2675 1.26779 0.281082 2.48459 0.281082H17.7602C18.977 0.281082 19.9634 1.2675 19.9634 2.4843V17.7599C19.9634 18.9767 18.977 19.9632 17.7602 19.9632H2.48459C1.26779 19.9632 0.281372 18.9767 0.281372 17.7599V2.4843ZM2.48459 1.16237C1.75451 1.16237 1.16266 1.75422 1.16266 2.4843V17.7599C1.16266 18.49 1.75451 19.0819 2.48459 19.0819H17.7602C18.4903 19.0819 19.0822 18.49 19.0822 17.7599V2.4843C19.0822 1.75422 18.4903 1.16237 17.7602 1.16237H2.48459Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.63147 12.0316H17.6133V15.263H2.63147V12.0316ZM3.51276 12.9129V14.3817H16.7321V12.9129H3.51276Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.80652 19.5225V14.8223H4.68781V19.5225H3.80652Z" fill="inherit" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.557 19.5225V14.8223H16.4383V19.5225H15.557Z" fill="inherit" />
                <path d="M4.23632 10.1221H3.24487L5.05001 4.99462H6.19668L8.00432 10.1221H7.01287L5.64337 6.04616H5.60332L4.23632 10.1221ZM4.26887 8.11167H6.97282V8.85776H4.26887V8.11167Z" fill="inherit" />
                <path d="M11.0714 4.75427L9.41901 10.8932H8.62535L10.2778 4.75427H11.0714Z" fill="inherit" />
                <path d="M12.0286 10.1221V4.99462H13.9914C14.362 4.99462 14.6699 5.05304 14.9153 5.16988C15.1623 5.28505 15.3467 5.44278 15.4686 5.64307C15.5921 5.84336 15.6539 6.07036 15.6539 6.32406C15.6539 6.5327 15.6138 6.7113 15.5337 6.85985C15.4536 7.00673 15.3459 7.12607 15.2107 7.21787C15.0755 7.30967 14.9245 7.3756 14.7576 7.41566V7.46573C14.9395 7.47575 15.1139 7.53166 15.2808 7.63348C15.4494 7.73362 15.5871 7.8755 15.6939 8.0591C15.8007 8.2427 15.8542 8.46469 15.8542 8.72507C15.8542 8.99046 15.7899 9.22914 15.6614 9.44112C15.5329 9.65142 15.3392 9.8175 15.0805 9.93934C14.8218 10.0612 14.4963 10.1221 14.1041 10.1221H12.0286ZM12.9574 9.34598H13.9564C14.2935 9.34598 14.5364 9.28172 14.6849 9.15319C14.8352 9.023 14.9103 8.85609 14.9103 8.65246C14.9103 8.50058 14.8727 8.36371 14.7976 8.24186C14.7225 8.11835 14.6157 8.02154 14.4771 7.95144C14.3386 7.87967 14.1734 7.84378 13.9814 7.84378H12.9574V9.34598ZM12.9574 7.17531H13.8763C14.0365 7.17531 14.1809 7.1461 14.3094 7.08768C14.4379 7.02759 14.5389 6.9433 14.6123 6.83481C14.6875 6.72465 14.725 6.59446 14.725 6.44424C14.725 6.24562 14.6549 6.08204 14.5147 5.95352C14.3762 5.825 14.17 5.76074 13.8963 5.76074H12.9574V7.17531Z" fill="inherit" />
            </svg>
        )
    }
};
