import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DateTime } from "luxon";

export class PatchV2BookingEndpoint implements IPatchV2BookingEndpoint
{
    public async execute(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>
    {
        const primitiveBooking = 
        {
            Space_Id: booking.SpaceId,
            Booking_Start: booking.StartTime?.toUTC()?.toISO() ?? undefined,
            Booking_End: booking.EndTime?.toUTC()?.toISO() ?? undefined
        }
        
        try
        {
            const response = await axios.patch<IPatchV2BookingResponse>(apis.allApiv2 + nodeId + `/Bookings/` + bookingId, primitiveBooking);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IPatchV2BookingEndpoint
{
    execute(nodeId: number, bookingId: string, booking: IPatchV2BookingRequest): Promise<IPatchV2BookingResponse>;
}

export interface IPatchV2BookingRequest
{
    SpaceId: string;
    StartTime?: DateTime;
    EndTime?: DateTime;
}

export interface IPatchV2BookingResponse
{
    Message: string;
}