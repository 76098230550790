import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { withRouter } from "react-router-dom";
import History from "history";
import { RouterProps } from "react-router-dom";
import { IPartialAppState, appContext } from "../../../../AppContext";
import SpaceAnalyticsCard, { Props as SpaceCardProps } from "../../../../Components/Cards/SpaceAnalyticsCard/SpaceAnalyticsCard";
import IbssbreadcrumbLeft from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import { UPDATE_MAIN_PAGE_TITLE, ONELENS_SPACEANALYTICS_OVERVIEW } from "../../../../app/constants";
import SpaceAnalyticsMainSearch from "./SpaceAnalyticsMainSearch";
import Alert from "../../../../Components/Miscellaneous/Alert/Alert";
import { Grid } from "@mui/material";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import { DateTime } from "luxon";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { DateHelper } from "../../../../Common/DateHelper";
import { getBuildingNameUsingBuildingId } from "../../../../Common/Helper";

type SearchCriteriaValue = {
  src: string,
  value: string
}

// user submitted search criteria from the SearchCriteria form.
type UserSearchCriteria = {
  selectedBuildingOption: string | number, // mistyped in Class State as string | number[]? observed a number in React Components.
  selectedWorkspaceTypes: string,
  selectedSpaceTypes: string;
  selectedFloor: string | number;
  selectedZone: string,
  is_as_soon_as_possible: boolean,
  fmsfc_start_date_for_filter_modal: string | Date, // todo: this should be either a date or a string, not both
  End_Date_For_filter_modal: string | Date, // todo: this should be either a date or a string, not both
  isZoneDisabled: boolean;
}

type SpaceCardRes = {
  Node_Id: number,
  Node_Level_Name: string,
  Period_Change_Capacity_Value: number,
  Period_Change_Max_Occ_Value: number,
  Period_Change_Space_Value: number,
  Period_Current_Capacity_Value: number,
  Period_Current_Max_Occ_Value: number,
  Period_Current_Space_Value: number,
  Space_Class: string,
  Space_Id: string,
  Space_Name: string,
  Space_Type: string,
  Space_Type_Label: string
}

type SearchState = {
  spaceCardResponse: Array<SpaceCardRes>
  isLoading: boolean,
  spaceCards: Array<SpaceCardProps>,
  openDrawer: boolean,
  searchData: Array<string>,
  cardSequence: Array<string>,
  searchCriteria: Array<SearchCriteriaValue>,
  zone: string,
  userSearchCriteria: UserSearchCriteria,
  selectedBuildingId: string,
}

class ViewSpaceAnalyticsOverview extends Component<IProps, SearchState> {
  private apiCache = appContext().apiCache;
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  private history: History.History;
  private spaces = new Array<Space>();

  constructor(props: IProps) {
    super(props);
    this.history = props.history;
    this.state = {
      isLoading: false,
      spaceCards: [],
      openDrawer: false,
      searchData: ["building", "periodType", "date"],
      cardSequence: ["Work", "Support", "Amenity"],
      searchCriteria: [],
      zone: "",
      userSearchCriteria: {
        // todo. this is meant to replace const filterData = this.props.flexMySearchFilterCriteria;
        selectedBuildingOption: "",
        selectedWorkspaceTypes: "",
        selectedSpaceTypes: "",
        selectedFloor: "",
        selectedZone: "",
        is_as_soon_as_possible: false,
        fmsfc_start_date_for_filter_modal: "",
        End_Date_For_filter_modal: "",
        isZoneDisabled: false,
      },
      spaceCardResponse: [],
      selectedBuildingId: this.appState.buildingId.toString(),
    };
  }

  public async componentDidMount() {
    const { match }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));

    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    this.fetchData()
  }

  private async appStateChanged(state: IPartialAppState): Promise<void> {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined) {
      this.setState({ selectedBuildingId: state.buildingId?.toString() });
      history.push(match.path.replace(":buildingid", state.buildingId));
    }
  }

  public async componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any) {
    if (prevState.selectedBuildingId != this.state.selectedBuildingId) {
      this.fetchData()
    }
  }

  public async fetchData() {
    const currentDate = DateTime.now();
    const startDate = currentDate.startOf('month');
    const endDate = currentDate.endOf('month');

    const defaultMonthStartDate = currentDate.minus({ months: 1 }).startOf('month');
    const defaultMonthEndDate = currentDate.minus({ months: 1 }).endOf('month');

    const initStartDate = currentDate.day !== 1 ? startDate : defaultMonthStartDate;
    const initEndDate = currentDate.day !== 1 ? endDate : defaultMonthEndDate;

    const { dispatch } = this.props;

    const filter = {
      startDate: initStartDate, 
      endDate: initEndDate,
      spaceId:"",
      periodType: 1
    }
  
    this.session.setSpaceAnalyticsCriteria(filter);

    // Card data API call
    try {
      this.setState({ isLoading: true });
      apis
        .getOverviewWorkSpaceClassification(
          this.state.selectedBuildingId,
          "Total",
          1,
          initStartDate.setZoneByNode(+this.state.selectedBuildingId),
          initEndDate.setZoneByNode(+this.state.selectedBuildingId).plus({ days: 1 }).startOf('day'),
          "Building"
        )
        .then((res) => {
          this.setState({ spaceCardResponse: this.cardSequenceOutput(res) });
          this.setState({ isLoading: false });
        });
    } catch {
      this.setState({ isLoading: false });
    }

    // Page name stored
    dispatch({
      type: UPDATE_MAIN_PAGE_TITLE,
      mainPageTitle: this.labels.HubMenuSpaceAnalyticsmain,
    });

    // Spaceanalytics overview storage
    dispatch(this.initialRenderDispatch(this.session.getBuildingId(), this.session.getBuildingName(), 1, initStartDate.toJSDate(), initEndDate.toJSDate()));
    this.setState({ searchCriteria: this.makeSearchCriteria() });
  }

  public initialRenderDispatch(buildingId: number, buildingName: string, periodType: number, startDate: string | Date, EndDate: string | Date) {
    return {
      type: ONELENS_SPACEANALYTICS_OVERVIEW,
      payload: {
        buildingOption: buildingName,
        floor: "Any",
        classType: "Work",
        WorkSpace: "Any",
        periodTypeValue: "Month",
        periodType: periodType,
        buildingNodeId: buildingId,
        fmsfc_start_date_for_filter_modal: startDate,
        End_Date_For_filter_modal: EndDate,
        groupBy: "Floor",
        metabookable: 0,
      },
    }
  }

  // Sequence of data card
  public cardSequenceOutput(res: SpaceCardRes[]) {
    const array2 = ["Work", "Support", "Amenity"];

    const sortedArray = res.sort((a: { Space_Class: string }, b: { Space_Class: string }) => {
      const aIndex = array2.indexOf(a.Space_Class);
      const bIndex = array2.indexOf(b.Space_Class);
      return aIndex - bIndex;
    });
    return sortedArray
  }

  // Breadcrumb call
  public makeSearchCriteria(): SearchCriteriaValue[] {
    let arr: any = [];
    this.state.searchData.map((option: string) => {
      if (option === "building") {
        if (
          this.props.onelensSpaceAnalyticsOverview != null &&
          this.props.onelensSpaceAnalyticsOverview != undefined
        ) {
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Building.svg`,
            value: getBuildingNameUsingBuildingId(this.state.selectedBuildingId),
          });
        }
      }
      if (option === "periodType") {
        if (
          this.props.onelensSpaceAnalyticsOverview != null &&
          this.props.onelensSpaceAnalyticsOverview != undefined
        ) {
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Calendar.svg`,
            value:
              this.props.onelensSpaceAnalyticsOverview.periodTypeValue.toString(),
          });
        }
      }
      if (option === "date") {
        if (
          this.props.onelensSpaceAnalyticsOverview != null &&
          this.props.onelensSpaceAnalyticsOverview != undefined
        ) {
          var date_ = new Date(this.props.onelensSpaceAnalyticsOverview.fmsfc_start_date_for_filter_modal).toISOString();
          const startDate = DateTime.fromISO(date_).toFormat('dd/MM/yy');
          arr.push({
            src: `/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
              }/Calendar.svg`,
            value: startDate,
          });
        }
      }
    });
    return arr;
  }

  // Update data for changed criteria for form
  public async handleGetSearchData(buildingId: number, filterData: { End_Date_For_filter_modal: string, buildingNodeId: number, buildingOption: string, fmsfc_start_date_for_filter_modal: string, periodType: number, periodTypeValue: string }) {
    this.setState({ isLoading: true });
    const startDate = DateHelper.fromIsoOrJsDate(filterData.fmsfc_start_date_for_filter_modal).setZoneByNode(buildingId).startOf('day');
    const endDate = startDate.plusPeriods(filterData.periodType);
    const filter = {
      startDate: startDate, 
      endDate: endDate,
      spaceId:"",
      periodType: filterData.periodType
    }  
    this.session.setSpaceAnalyticsCriteria(filter);
    try {
      const response = await apis.getOverviewWorkSpaceClassification(
        buildingId,
        "Total",
        filterData.periodType,
        startDate,
        endDate,
        "Building"
      );
      this.setState({ spaceCardResponse: this.cardSequenceOutput(response) });
      this.setState({ isLoading: false });

      this.updateSearchResults(this.state.searchCriteria);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
    // Spaceanalytics overview storage
    this.setState({ searchCriteria: this.makeSearchCriteria() });
  }

  public async updateSearchResults(
    searchCriteria: SearchCriteriaValue[]
  ): Promise<void> {

    this.setState({
      isLoading: false,
      searchCriteria: searchCriteria,
    });
  }

  public toggleFilterCriteraDrawer() {
    this.setState({
      openDrawer: !this.state.openDrawer,
    });
  }

  public editSearchCriteria() {
    this.setState({
      openDrawer: true,
    });
  }

  public render(): JSX.Element {
    const filterCriteraDrawerData = {
      ...this.props,
      open: this.state.openDrawer,
      toggleDrawer: () => this.toggleFilterCriteraDrawer(),
      updateSearchResults: (buildingId: number, filterData: { buildingNodeId: number, End_Date_For_filter_modal: string, buildingOption: string, fmsfc_start_date_for_filter_modal: string, periodType: number, periodTypeValue: string }) => {
        this.setState({ searchCriteria: this.makeSearchCriteria() });
        this.handleGetSearchData(buildingId, filterData);
      },
    };

    return (
      <>
        <link
          rel="stylesheet"
          href="/src/pages/Flex/Search/SearchComponent.css"
        ></link>
        <div key="/Pages/Flex/Search">
          {this.state.isLoading && <Spinner />}
          <div className="page-height-exct-header">
            <div
              className="rightPanel-main-content"
              style={{ paddingBottom: "10px" }}
            >
              <div className="space-box-cont">
                <div className="left-space-box-cont flex-row-bredcrumb">

                  {/* Breadcrumb Component */}
                  <IbssbreadcrumbLeft value={this.state.searchCriteria} />
                </div>
                <div className="right-space-box-cont">
                  {!this.state.openDrawer ? (
                    <IbssButtonRedo sx={{whiteSpace:"nowrap"}}
                      variant='contained'
                      onClick={() => this.editSearchCriteria()}
                    >
                      {this.labels.HubButtonEditsearchcriteria}
                    </IbssButtonRedo>
                  ) : (
                    ""
                  )}
                </div>
                {/* Toggle window */}
                {this.state.openDrawer ? (
                  <SpaceAnalyticsMainSearch {...filterCriteraDrawerData} />
                ) : (
                  ""
                )}
              </div>

              <div className="space-box-cont">
                <div className="left-space-box-cont flex-row-bredcrumb">
                  <div className="search-results-title my-2">
                    {this.labels.HubLabelSearchResults}
                  </div>
                </div>
              </div>
              <div className={""}>
              {/* No result found */}
              {this.state.spaceCardResponse.length === 0 ? (
                <Alert
                  key="noResults"
                  title={"There are currently no spaces available"}
                  text={`Based on your search criteria we've found no spaces available, please adjust your criteria and try again.`}
                />
              ) : null}
              <Grid container spacing={1.5}>
                {this.state.spaceCardResponse?.map((props, index) => (
                  <SpaceAnalyticsCard key={index}
                    Period_Current_Peak_Value={0}
                    nodeId={0}
                    zone={""}
                    spaceType={""}
                    imageUrl={""}
                    spaceId={""}
                    spaceName={""}
                    spaceCapacity={0}
                    spaceSetup={0}
                    {...props}
                  />
                ))}
              </Grid>
            </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
    onelensSpaceAnalyticsOverview: state.onelensSpaceAnalyticsOverview
  };
};

export default withRouter(connect(mapStateToProps)(ViewSpaceAnalyticsOverview) as any);

interface IProps extends RouterProps, IPropsFromState, IDispatch {
}
