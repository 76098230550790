import { Component } from 'react';

export default class Meetingbooth extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 32.25C31.2426 32.25 32.25 31.2426 32.25 30L32.25 0.999996C32.25 0.585781 32.5858 0.249996 33 0.249996C33.4142 0.249996 33.75 0.585781 33.75 0.999996L33.75 30C33.75 32.0711 32.0711 33.75 30 33.75L4 33.75C1.92893 33.75 0.250004 32.0711 0.250003 30L0.250001 0.999999C0.250001 0.585784 0.585786 0.249999 1 0.249999C1.41421 0.249999 1.75 0.585784 1.75 0.999999L1.75 30C1.75 31.2426 2.75736 32.25 4 32.25L30 32.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.75 28C22.75 29.5188 21.5188 30.75 20 30.75L14 30.75C12.4812 30.75 11.25 29.5188 11.25 28L11.25 4C11.25 2.48122 12.4812 1.25 14 1.25L20 1.25C21.5188 1.25 22.75 2.48122 22.75 4L22.75 28ZM20 29.25C20.6904 29.25 21.25 28.6904 21.25 28L21.25 4C21.25 3.30964 20.6904 2.75 20 2.75L14 2.75C13.3096 2.75 12.75 3.30965 12.75 4L12.75 28C12.75 28.6904 13.3096 29.25 14 29.25L20 29.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 27.5C9.75 29.2949 8.29493 30.75 6.5 30.75C4.70508 30.75 3.25 29.2949 3.25 27.5L3.25 4.5C3.25 2.70508 4.70507 1.25 6.5 1.25C8.29492 1.25 9.75 2.70507 9.75 4.5L9.75 27.5ZM6.5 29.25C7.4665 29.25 8.25 28.4665 8.25 27.5L8.25 4.5C8.25 3.5335 7.4665 2.75 6.5 2.75C5.5335 2.75 4.75 3.5335 4.75 4.5L4.75 27.5C4.75 28.4665 5.5335 29.25 6.5 29.25Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.75 27.5C30.75 29.2949 29.2949 30.75 27.5 30.75C25.7051 30.75 24.25 29.2949 24.25 27.5L24.25 4.5C24.25 2.70508 25.7051 1.25 27.5 1.25C29.2949 1.25 30.75 2.70507 30.75 4.5L30.75 27.5ZM27.5 29.25C28.4665 29.25 29.25 28.4665 29.25 27.5L29.25 4.5C29.25 3.5335 28.4665 2.75 27.5 2.75C26.5335 2.75 25.75 3.5335 25.75 4.5L25.75 27.5C25.75 28.4665 26.5335 29.25 27.5 29.25Z" />
            </svg>
        )
    }
};
