import { Component } from 'react';

export default class Lightsoff extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 13.0951C4.25 6.0215 9.95916 0.25 16.9851 0.25C24.0396 0.25 29.75 6.02028 29.75 13.0951C29.75 17.9557 27.0794 22.1595 23.1448 24.3438V25.7935C23.1448 26.8648 22.2775 27.75 21.1993 27.75H12.8007C11.7225 27.75 10.8552 26.8648 10.8552 25.7935V24.3438C6.92058 22.1595 4.25 17.9557 4.25 13.0951ZM16.9851 1.75C10.8005 1.75 5.75 6.83697 5.75 13.0951C5.75 17.5385 8.27253 21.3574 11.9451 23.2247C12.1968 23.3527 12.3552 23.611 12.3552 23.8933V25.7935C12.3552 26.0493 12.5639 26.25 12.8007 26.25H21.1993C21.4361 26.25 21.6448 26.0493 21.6448 25.7935V23.8933C21.6448 23.611 21.8032 23.3527 22.0549 23.2247C25.7275 21.3574 28.25 17.5385 28.25 13.0951C28.25 6.83819 23.2007 1.75 16.9851 1.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 27C12.25 26.5858 12.5858 26.25 13 26.25H22C22.4142 26.25 22.75 26.5858 22.75 27V28.8C22.75 29.9197 21.7907 30.75 20.7189 30.75H14.2811C13.2093 30.75 12.25 29.9197 12.25 28.8V27ZM13.75 27.75V28.8C13.75 29.0003 13.9437 29.25 14.2811 29.25H20.7189C21.0563 29.25 21.25 29.0003 21.25 28.8V27.75H13.75Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 30C13.25 29.5858 13.5858 29.25 14 29.25H21C21.4142 29.25 21.75 29.5858 21.75 30C21.75 32.1685 19.7266 33.75 17.5 33.75C15.2734 33.75 13.25 32.1685 13.25 30ZM14.9143 30.75C15.2827 31.5786 16.2423 32.25 17.5 32.25C18.7577 32.25 19.7173 31.5786 20.0857 30.75H14.9143Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 5.75C12.6318 5.75 9.75 8.91517 9.75 13C9.75 13.4142 9.41421 13.75 9 13.75C8.58579 13.75 8.25 13.4142 8.25 13C8.25 8.28483 11.6182 4.25 16 4.25C16.4142 4.25 16.75 4.58579 16.75 5C16.75 5.41421 16.4142 5.75 16 5.75Z" />
            </svg>
        )
    }
};