import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import "../../../../styles/css/form.scss";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import NoneIcon from '../../../../assets/images/securityRight/None.png';
import BasicIcon from '../../../../assets/images/securityRight/Basic.png';
import LocalIcon from '../../../../assets/images/securityRight/Local.png';
import DeepIcon from '../../../../assets/images/securityRight/Deep.png';
import GlobalIcon from '../../../../assets/images/securityRight/Global.png';
import { appContext } from "../../../../AppContext";
import { IUserDetails } from "../../../../Providers.LocalStorage/Models";
import { Box, Typography } from "@mui/material";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Details from "./Details";
import DataPermissions from "./DataPermissions";
import Users from "./Users";
import AppPermissions, { IPermissionsObject } from "./AppPermissions";
import { RouteComponentProps } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { AxiosResponse } from "axios";
import React from "react";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";

class RolesDetails extends IbssPage<IProps, IState>
{
    private labels = appContext().labels;
    private alert = appContext().alert;
    private local = appContext().localStorageProvider;
    private appPermissionsRef = React.createRef<AppPermissions>();
    private get appPermissions() { return this.appPermissionsRef.current }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoadingToSave: false,
            activePage: 0,
            loading: false,
            title: "",
            concurrencyStamp: null,
            groupId: null,
            identityProviderId: null,
            name: "",
            isOwner: 0,
            isUpdateRight: false,
            breadcrumbs: null,
            userID: null,
            showConfirmCancelDialog: false,
            availablePermissions: {},
            selectedPermissions: {},
        };
    }

    private get roleId(): string
    {
        return this.props.match.params.id;
    }

    private get isNew(): boolean
    {
        return (this.roleId === "0");
    }

    public async componentDidMount(): Promise<void>
    {
        const availablePermissions: AxiosResponse<IPermissionsObject> = await apis.getAvailableRights();

        if (this.isNew)
        {
            this.setState({
                concurrencyStamp: null,
                title: this.labels.HubButtonNewRole,
                isUpdateRight: true,
                availablePermissions: availablePermissions.data,
            });
        }
        else
        {
            const selectedPermissions = await apis.getRolesById(this.roleId);
            const role = selectedPermissions.data;

            this.setState({
                concurrencyStamp: role.concurrencyStamp,
                title: role.name,
                isUpdateRight: this.local.hasRight("DATAMODEL.Roles.Update"),
                name: role.name,
                identityProviderId: role.identityProviderId,
                groupId: role.groupId,
                isOwner: role.isOwner,
                availablePermissions: availablePermissions.data,
                selectedPermissions: JSON.parse(role.applicationActions || "{}"),
            });
        }

        if (sessionStorage.getItem("roleTitle") !== null || this.props.history.location.state === 'cancel')
        {
            this.setState({ activePage: 3 });
        }

        sessionStorage.removeItem("roleTitle");
        sessionStorage.removeItem("roleID");

        if (sessionStorage.getItem('userTitle') !== null)
        {
            this.setState({
                breadcrumbs: sessionStorage.getItem('userTitle'),
                userID: sessionStorage.getItem('userID'),
            });
        }
    }

    private get isValid(): boolean
    {
        return (!!this.state.name && !!this.state.identityProviderId && (this.state.identityProviderId == 1 || !!this.state.groupId));
    }

    private detailsChanged(name: string, identityProviderId: number, groupId: string, isOwner: number): void
    {
        this.setState({
            name: name,
            identityProviderId: identityProviderId,
            groupId: groupId,
            isOwner: isOwner,
        });
    }

    private cancelClicked(): void
    {
        this.setState({ showConfirmCancelDialog: true });
    }

    private confirmCancelDialogOked(): void
    {
        const { history } = this.props;
        if (this.state.userID)
        {
            history.push("/security-users/userdetails/" + this.state.userID, 'cancel');
        }
        else
        {
            history.push("/security-roles");
        }
    }

    private async saveClicked(): Promise<void>
    {
        this.setState({ isLoadingToSave: true })
        const { name, groupId, identityProviderId, isOwner, concurrencyStamp } = this.state;

        const payload: IPayload = {
            name: name,
            groupId: groupId,
            applicationActions: JSON.stringify(this.appPermissions?.payload ?? {}),
            identityProviderId: identityProviderId,
            concurrencyStamp: concurrencyStamp,
            isOwner: isOwner,
        };

        if (this.roleId === "0")
        {
            await this.createRole(payload);
        }
        else
        {
            await this.updateRole(payload);
        }
    }

    private async createRole(payload: IPayload): Promise<void>
    {
        try
        {
            const res = await apis.createRole(payload) as unknown as AxiosResponse<{ id: string, concurrencyStamp: string }>;
            const { history } = this.props;
            this.setStateAsync({ isLoadingToSave: false, concurrencyStamp: res.data.concurrencyStamp });
            history.push("/security-roles/rolesdetails/" + res.data.id);
        }
        catch
        {
            this.setState({ isLoadingToSave: false })
        }
    }

    private async updateRole(payload: IPayload): Promise<void>
    {
        const { history } = this.props;
        try
        {
            await apis.updateRoleDataSecurityById(this.roleId, payload);
            const res = await apis.getRightsList();
            const user = this.local.getUserDetails();

            const rightsData: IUserDetails = {
                ...user,
                userRights: res.data,
            };

            this.local.setUserDetails(rightsData);
            history.push("/security-roles");
        }
        finally
        {
            this.setState({ isLoadingToSave: false });
        }
    }

    private getTabMessage(): string
    {
        switch (this.state.activePage)
        {
            case 0:
                return this.labels.funcRolesAndDataSecurityRoleDetailsTab_Message
            case 1:
                return this.labels.funcRolesAndDataSecurityAppSettingsTab_Message
            case 2:
                return this.labels.funcRolesAndDataSecurityDataSecurityTab_Message
            case 3:
                return this.labels.funcRolesAndDataSecurityUsersTab_Message
            default:
                return ''
        }
    }

    public render(): JSX.Element
    {
        const {
            name,
            identityProviderId,
            groupId,
        } = this.state;

        const props = {
            ...this.props,
            submenu: [{
                title: this.labels.HubLabelRoleDetailsSubMenu,
                tabNo: 0,
                active: this.state.activePage === 0 ? true : false,
            },
            {
                title: this.labels.HubLabelsppSettingsSubMenuTitle,
                tabNo: 1,
                active: this.state.activePage === 1 ? true : false,
            },
            {
                title: this.labels.HubLabelDataSecuritySubMenuTitle,
                tabNo: 2,
                active: this.state.activePage === 2 ? true : false,
                display: this.roleId !== "0" ? 'block' : 'none',
            },
            {
                title: this.labels.HubLabelUsers,
                tabNo: 3,
                active: this.state.activePage === 3 ? true : false,
                display: this.roleId !== "0" ? 'block' : 'none',
            }],
            activePageValue: this.state.activePage,
            childValue: (i: number) =>
            {
                this.setState({ activePage: i });
            },
        };

        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="rightPanel">
                    <Submenu {...props} />
                    <div className="rightPanel-main-content">
                        <div className="table-panel p-30 ">
                            {
                                this.state.showConfirmCancelDialog &&
                                <ConfirmModal
                                    show={this.state.showConfirmCancelDialog}
                                    name={this.state.name}
                                    modalHeading={this.labels.HubLabelConfirmation}
                                    modalMessage={this.labels.HubLabelConfirmCancelMessage}
                                    handleModal={() => this.setState({ showConfirmCancelDialog: false })}
                                    okButton={() => this.confirmCancelDialogOked()}
                                />
                            }
                            {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}

                            <Typography variant="h4" gutterBottom>
                                {this.state.title}
                            </Typography>
                            <Typography gutterBottom>
                                {this.getTabMessage()}
                            </Typography>
                            <div className="d-flex flex-row flex-wrap">
                                {this.state.activePage == 2 &&
                                    <div className="securityIcons">
                                        <div className="securityIcon-item">
                                            <p className="securityIcon-title">{this.labels.HubLabelNoneSecurityIcon}</p>
                                            <img className="securityIcon-image" src={NoneIcon} alt="NoneIcon"></img>
                                        </div>
                                        <div className="securityIcon-item">
                                            <p className="securityIcon-title">{this.labels.HubLabelBasicSecurityIcon}</p>
                                            <img className="securityIcon-image" src={BasicIcon} alt="BasicIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelLocalSecurityIcon}</p>
                                            <img className="securityIcon-image" src={LocalIcon} alt="LocalIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelDeepSecurityIcon}</p>
                                            <img className="securityIcon-image" src={DeepIcon} alt="DeepIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelGlobalSecurityIcon}</p>
                                            <img className="securityIcon-image" src={GlobalIcon} alt="GlobalIcon"></img>
                                        </div>
                                    </div>}
                            </div>

                            {
                                this.state.activePage === 0 &&
                                <Details
                                    id={this.roleId}
                                    name={name}
                                    identityProviderId={identityProviderId ?? undefined}
                                    groupId={groupId ?? undefined}
                                    loading={this.state.loading}
                                    isOwner={this.state.isOwner}
                                    onChange={(name, identityProviderId, groupId, isOwner) => this.detailsChanged(name, identityProviderId, groupId, isOwner)}
                                />
                            }
                            <AppPermissions
                                show={this.state.activePage === 1}
                                ref={this.appPermissionsRef}
                                id={this.roleId}
                                loading={this.state.loading}
                                availablePermissions={this.state.availablePermissions}
                                selectedPermissions={this.state.selectedPermissions}
                            />
                            {
                                this.state.activePage === 2 &&
                                <DataPermissions
                                    id={this.roleId}
                                    loading={this.state.loading}
                                    isUpdateRight={this.state.isUpdateRight}
                                    onPermissionsLoad={permissions => { }}
                                    onLoad={() => this.setState({ loading: false })}
                                />
                            }
                            {
                                this.state.activePage === 3 &&
                                <Users
                                    id={this.roleId}
                                    loading={this.state.loading}
                                    title={this.state.title}
                                    onUserSelected={user => { }}
                                />
                            }
                        </div>
                        <Box className=" space-between mt-3">
                            <Box >
                                {this.state.activePage === 1 &&
                                    <IbssButton
                                        variant='outlined'
                                        className="border-none"
                                        sx={{ backgroundColor: 'transparent', border: 'none', textDecoration: "underline", color: "var(--ui-text-alternate)" }}
                                        onClick={() => this.appPermissions?.openJsonDialog()}
                                    >
                                        {this.labels.HubLabelButtonJSONView}
                                    </IbssButton>
                                }
                            </Box>
                            <Box >
                                <IbssButton
                                    variant="contained"
                                    color="secondary"
                                    sx={{ mr: 2 }}
                                    onClick={() => this.cancelClicked()}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButton>
                                <IbssButton
                                    variant="contained"
                                    sx={{ ml: 2 }}
                                    disabled={!this.state.isUpdateRight || !this.isValid}
                                    onClick={() => this.saveClicked()}
                                >
                                    {this.labels.HubButtonSave}
                                </IbssButton>
                            </Box>
                        </Box>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProvidersDetails,
        mainPageTitle: state.mainPageTitle,
    }
}

export default connect(mapStateToProps)(RolesDetails);

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IQueryParams
{
    id: string;
}

export interface IState
{
    isLoadingToSave: boolean;
    activePage: number;
    loading: boolean;
    title: string;
    concurrencyStamp: string | null;
    groupId: string | null;
    identityProviderId: number | null;
    name: string;
    isOwner: number;
    isUpdateRight: boolean;
    breadcrumbs: string | null;
    userID: string | null;
    showConfirmCancelDialog: boolean;
    availablePermissions: IPermissionsObject;
    selectedPermissions: IPermissionsObject;
}

export interface IPayload
{
    name: string;
    groupId: string | null;
    applicationActions: string;
    identityProviderId: number | null;
    concurrencyStamp: string | null;
    isOwner: number;
}
