import { Component } from 'react';

export default class Collaborationspace extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.215 11.6667C11.215 9.80888 12.6388 8.21503 14.5 8.21503H19.5C21.3612 8.21503 22.785 9.80888 22.785 11.6667V13C22.785 13.4336 22.4335 13.785 22 13.785C21.5665 13.785 21.215 13.4336 21.215 13V11.6667C21.215 10.579 20.4002 9.78503 19.5 9.78503H14.5C13.5998 9.78503 12.785 10.579 12.785 11.6667V13C12.785 13.4336 12.4335 13.785 12 13.785C11.5665 13.785 11.215 13.4336 11.215 13V11.6667Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.215 31.6667C11.215 29.8089 12.6388 28.215 14.5 28.215H19.5C21.3612 28.215 22.785 29.8089 22.785 31.6667V33C22.785 33.4336 22.4335 33.785 22 33.785C21.5665 33.785 21.215 33.4336 21.215 33V31.6667C21.215 30.579 20.4002 29.785 19.5 29.785H14.5C13.5998 29.785 12.785 30.579 12.785 31.6667V33C12.785 33.4336 12.4335 33.785 12 33.785C11.5665 33.785 11.215 33.4336 11.215 33V31.6667Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.215 21C27.215 20.5665 27.5665 20.215 28 20.215H30.3333C32.2396 20.215 33.785 21.7604 33.785 23.6667V25C33.785 25.4336 33.4335 25.785 33 25.785C32.5665 25.785 32.215 25.4336 32.215 25V23.6667C32.215 22.6275 31.3725 21.785 30.3333 21.785H28C27.5665 21.785 27.215 21.4336 27.215 21Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.785 21C6.785 20.5665 6.43354 20.215 6 20.215H3.66667C1.76036 20.215 0.215 21.7604 0.215 23.6667V25C0.215 25.4336 0.566456 25.785 1 25.785C1.43354 25.785 1.785 25.4336 1.785 25V23.6667C1.785 22.6275 2.62745 21.785 3.66667 21.785H6C6.43354 21.785 6.785 21.4336 6.785 21Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.215 4.00003C13.215 1.90963 14.9096 0.215027 17 0.215027C19.0904 0.215027 20.785 1.90963 20.785 4.00003C20.785 6.09042 19.0904 7.78503 17 7.78503C14.9096 7.78503 13.215 6.09042 13.215 4.00003ZM17 1.78503C15.7767 1.78503 14.785 2.77672 14.785 4.00003C14.785 5.22334 15.7767 6.21503 17 6.21503C18.2233 6.21503 19.215 5.22334 19.215 4.00003C19.215 2.77672 18.2233 1.78503 17 1.78503Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.215 24C13.215 21.9096 14.9096 20.215 17 20.215C19.0904 20.215 20.785 21.9096 20.785 24C20.785 26.0904 19.0904 27.785 17 27.785C14.9096 27.785 13.215 26.0904 13.215 24ZM17 21.785C15.7767 21.785 14.785 22.7767 14.785 24C14.785 25.2233 15.7767 26.215 17 26.215C18.2233 26.215 19.215 25.2233 19.215 24C19.215 22.7767 18.2233 21.785 17 21.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.215 16C25.215 13.9096 26.9096 12.215 29 12.215C31.0904 12.215 32.785 13.9096 32.785 16C32.785 18.0904 31.0904 19.785 29 19.785C26.9096 19.785 25.215 18.0904 25.215 16ZM29 13.785C27.7767 13.785 26.785 14.7767 26.785 16C26.785 17.2233 27.7767 18.215 29 18.215C30.2233 18.215 31.215 17.2233 31.215 16C31.215 14.7767 30.2233 13.785 29 13.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.785 16C8.785 13.9096 7.0904 12.215 5 12.215C2.9096 12.215 1.215 13.9096 1.215 16C1.215 18.0904 2.9096 19.785 5 19.785C7.0904 19.785 8.785 18.0904 8.785 16ZM5 13.785C6.22331 13.785 7.215 14.7767 7.215 16C7.215 17.2233 6.22331 18.215 5 18.215C3.77669 18.215 2.785 17.2233 2.785 16C2.785 14.7767 3.77669 13.785 5 13.785Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7527 16.785C12.7303 16.785 11.8408 17.4847 11.6 18.4783L8.76292 30.1849C8.6608 30.6063 8.23646 30.8651 7.81511 30.7629C7.39376 30.6608 7.13497 30.2365 7.23708 29.8151L10.0741 18.1085C10.4856 16.4107 12.0057 15.215 13.7527 15.215H20.9776C22.6838 15.215 24.179 16.3565 24.6288 18.0024L27.7572 29.4517C27.8715 29.8699 27.6251 30.3015 27.2069 30.4158C26.7887 30.5301 26.357 30.2837 26.2428 29.8655L23.1143 18.4162C22.8511 17.453 21.9761 16.785 20.9776 16.785H13.7527Z" />
            </svg>
        )
    }
};
