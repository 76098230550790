import IResponse from "../IResponse";
import { IDeleteEndpoint } from "./DeleteEndpoint";
import { ISpaceCateringMenuItem, IGetManyEndpoint } from "./GetManyEndpoint";
import { IUpdateSpaceCateringMenuItemPayload, IUpdateEndpoint } from "./UpdateEndpoint";
import { ICreateSpaceCateringMenuItemPayload, ICreateEndpoint } from "./CreateEndpoint";
import { IGetManyAsBlobEndpoint } from "./GetManyAsBlobEndpoint";
import { IGetByIdEndpoint } from "./GetByIdEndpoint";

export class CateringItemRepository implements ICateringItemRepository
{
    private getManyEndpointEndpoint: IGetManyEndpoint;
    private createEndpoint: ICreateEndpoint;
    private updateEndpoint: IUpdateEndpoint;
    private deleteEndpoint: IDeleteEndpoint;
    private getManyAsBlobEndpoint: IGetManyAsBlobEndpoint;
    private getByIdEndPoint: IGetByIdEndpoint;

    constructor(
        getManyEndpointEndpoint: IGetManyEndpoint,
        createEndpoint: ICreateEndpoint,
        updateEndpoint: IUpdateEndpoint,
        deleteEndpoint: IDeleteEndpoint,
        getManyAsBlobEndpoint: IGetManyAsBlobEndpoint,
        getByIdEndPoint: IGetByIdEndpoint,
    )
    {
        this.getManyEndpointEndpoint = getManyEndpointEndpoint;
        this.createEndpoint =createEndpoint;
        this.updateEndpoint = updateEndpoint;
        this.deleteEndpoint = deleteEndpoint;
        this.getManyAsBlobEndpoint = getManyAsBlobEndpoint;
        this.getByIdEndPoint = getByIdEndPoint;
    }

    public getMany(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>
    {
        return this.getManyEndpointEndpoint.execute(buildingId, filter);
    }

    
    public getById(buildingId: number, cateringMenuItemId: string) : Promise<ISpaceCateringMenuItem>
    {
        return this.getByIdEndPoint.execute(buildingId,cateringMenuItemId);
    }

    public create(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<ISpaceCateringMenuItem>
    {
        return this.createEndpoint.execute(buildingId, payload);
    }

    public update(buildingId: number, spaceCateringId: string, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>
    {
        return this.updateEndpoint.execute(buildingId, spaceCateringId, payload);
    }
    
    public delete(buildingId: number, spaceCateringId: string): Promise<void>
    {
        return this.deleteEndpoint.execute(buildingId, spaceCateringId);
    }

    public async getManyAsBlob(buildingId: number,filter: CateringItemsFilter): Promise<Blob>
    {
        return this.getManyAsBlobEndpoint.execute(buildingId,filter);
    }

}

export interface ICateringItemRepository
{
    getMany(buildingId: number, cateringFilter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>;
    getById(buildingId: number,cateringMenuItemId:string) : Promise<ISpaceCateringMenuItem>;
    create(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<ISpaceCateringMenuItem>;
    update(buildingId: number, spaceCateringId :string, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>;
    delete(buildingId: number, spaceCateringId :string): Promise<void>;
    getManyAsBlob(buildingId: number,cateringFilter: CateringItemsFilter): Promise<Blob>;
}

export type Classification = "MenuItemClassificationBeverage" | "MenuItemClassificationSnack" | "MenuItemClassificationFood"

export type Status = "StatusActive" | "StatusInactive"

export class CateringItemsFilter
{
    public classification: (Classification| null) = null;
    public status: (Status | null) = null;
    public supplierId: (string | null) = null;

    constructor(value: Partial<CateringItemsFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string | null>();
        filterParts.push(this.classification == null ? null : `Classification eq '${this.classification }'`);
        filterParts.push(this.status == null ? null : `Status eq '${this.status }'`);
        filterParts.push(this.supplierId == null ? null : `Supplier_Id eq '${this.supplierId}'`);
        return filterParts.filter(i => i != null).join(" and ");
    }
}

