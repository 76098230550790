import { Component } from 'react';

export default class Desk extends Component {
    render() {
        return (
            <svg style={{ overflow: "visible" }}>
                <path id="Path_73" data-name="Path 73" d="M20.058,13.294a.728.728,0,0,1,.666-.434H32.03a.728.728,0,0,1,.667,1.018l-4.224,9.706a.728.728,0,0,1-.668.437H16.5a.728.728,0,0,1-.728-.728v-.061a.728.728,0,0,1,.555-.707,1.045,1.045,0,0,0,.811-.991.745.745,0,0,0-.1-.467.728.728,0,0,1-.089-.737Zm1.14,1.022L18.43,20.589a2.464,2.464,0,0,1,.165.946,2.4,2.4,0,0,1-.229,1.031h8.963l3.59-8.25Z" fill-rule="evenodd" />
                <path id="Path_74" data-name="Path 74" d="M32.758,23.294a.728.728,0,0,1-.728.728H16.5V22.566H32.03A.728.728,0,0,1,32.758,23.294Z" fill-rule="evenodd" />
                <path id="Path_75" data-name="Path 75" d="M23.294,22.566a.728.728,0,0,1,.728.728v4.853a.728.728,0,0,1-1.456,0V23.294A.728.728,0,0,1,23.294,22.566Z" fill-rule="evenodd" />
                <path id="Path_76" data-name="Path 76" d="M6.087,5.581A3.141,3.141,0,1,0,9.227,8.721,3.141,3.141,0,0,0,6.087,5.581ZM1.49,8.721a4.6,4.6,0,1,1,4.6,4.6A4.6,4.6,0,0,1,1.49,8.721Z" fill-rule="evenodd" />
                <path id="Path_77" data-name="Path 77" d="M.242,16.209a3.524,3.524,0,0,1,3.536-3.536H7.022a3.524,3.524,0,0,1,3.536,3.536v2.828h5.636a2.513,2.513,0,0,1,2.475,2.475,2.472,2.472,0,0,1-2.475,2.475H13.335A5.062,5.062,0,0,1,14.8,27.565a.728.728,0,0,1-.728.728H1.968a.728.728,0,0,1-.455-.16,3.393,3.393,0,0,1-1.272-2.69ZM9.83,23.988H6.274a.728.728,0,0,1-.728-.728V18.268a.728.728,0,1,1,1.456,0v4.264h9.193a1.016,1.016,0,0,0,1.019-1.019,1.059,1.059,0,0,0-1.019-1.019H9.83a.728.728,0,0,1-.728-.728V16.209a2.068,2.068,0,0,0-2.08-2.08H3.778a2.068,2.068,0,0,0-2.08,2.08v9.234a1.951,1.951,0,0,0,.549,1.393H13.273A3.515,3.515,0,0,0,9.83,23.988Z" fill-rule="evenodd" />
            </svg>
        )
    }
};
