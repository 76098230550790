import { Component } from 'react';

export default class Postroom extends Component {
    render() {
        return (
            <svg style={{overflow: "visible"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H30C32.0711 0.25 33.75 1.92893 33.75 4V30C33.75 32.0711 32.0711 33.75 30 33.75H4C1.92893 33.75 0.25 32.0711 0.25 30V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V30C1.75 31.2426 2.75736 32.25 4 32.25H30C31.2426 32.25 32.25 31.2426 32.25 30V4C32.25 2.75736 31.2426 1.75 30 1.75H4Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 16C3.25 13.9289 4.92893 12.25 7 12.25H21C23.0711 12.25 24.75 13.9289 24.75 16V24C24.75 26.0711 23.0711 27.75 21 27.75H7C4.92893 27.75 3.25 26.0711 3.25 24V16ZM7 13.75C5.75736 13.75 4.75 14.7574 4.75 16V24C4.75 25.2426 5.75736 26.25 7 26.25H21C22.2426 26.25 23.25 25.2426 23.25 24V16C23.25 14.7574 22.2426 13.75 21 13.75H7Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 10C9.25 7.92893 10.9289 6.25 13 6.25H27C29.0711 6.25 30.75 7.92893 30.75 10V18C30.75 20.0711 29.0711 21.75 27 21.75H24V20.25H27C28.2426 20.25 29.25 19.2426 29.25 18V10C29.25 8.75736 28.2426 7.75 27 7.75H13C11.7574 7.75 10.75 8.75736 10.75 10V13.5H9.25V10Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.07648 16.119L4.9235 14.881L14 21.0912L23.0765 14.881L23.9235 16.119L14 22.9088L4.07648 16.119Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.416 8.62404L22.916 13.624L22.084 12.376L29.584 7.37596L30.416 8.62404ZM17.5641 13.6103L10.5641 8.6103L11.4359 7.3897L18.4359 12.3897L17.5641 13.6103Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5303 21.0303L5.53033 27.0303L4.46967 25.9697L10.4697 19.9697L11.5303 21.0303Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4697 21.0303L22.4697 27.0303L23.5303 25.9697L17.5303 19.9697L16.4697 21.0303Z" />
            </svg>
        )
    }
};