import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import { CheckboxProps } from '@mui/material';


type checkBox = CheckboxProps & {
  label?: string;
  disabled?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  onClicked: Function;
  checked?: boolean;
};

class IbssCheckBox extends React.Component<checkBox>{

  render() {
    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={(e) => this.props.onClicked(e)}/>}
          disabled={this.props.disabled}
          label={this.props.label}
          labelPlacement={this.props.labelPlacement}
          checked={this.props.checked}
        />
      </FormGroup>
    );
  }
}
export default IbssCheckBox;

