import { Component } from 'react';


export default class SearchResultIcon extends Component {
    render() {
        return (
            <svg>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52921 1.23534C4.9485 1.23534 1.23509 4.94874 1.23509 9.52946C1.23509 14.1102 4.9485 17.8236 9.52921 17.8236C14.1099 17.8236 17.8233 14.1102 17.8233 9.52946C17.8233 4.94874 14.1099 1.23534 9.52921 1.23534ZM0.17627 9.52946C0.17627 4.36397 4.36372 0.176514 9.52921 0.176514C14.6947 0.176514 18.8822 4.36397 18.8822 9.52946C18.8822 14.6949 14.6947 18.8824 9.52921 18.8824C4.36372 18.8824 0.17627 14.6949 0.17627 9.52946Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8611 15.8609C16.0679 15.6541 16.4031 15.6541 16.6098 15.8609L23.6686 22.9197C23.8754 23.1264 23.8754 23.4616 23.6686 23.6684C23.4619 23.8751 23.1267 23.8751 22.9199 23.6684L15.8611 16.6096C15.6544 16.4028 15.6544 16.0676 15.8611 15.8609Z" />
            </svg>
        )
    }
};
