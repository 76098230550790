import React from 'react';
import { Component } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import './BookingSummary.scss';

class BookingSummaryItem extends Component<IProps>
{

    public render(): JSX.Element
    {
        const info = this.props.label? `${this.props.label}: ${this.props.value}`: `${this.props.value}`;
        return (
                <Box className='bookingSummary-info'>
                {this.props.value &&
                    <>    
                        <SvgIcon component={this.props.icon} />
                        <Typography variant='body1'className="columnSubheading">{`${info}`}</Typography>
                    </> 
                }
                </Box>

        )
    }
}

export default BookingSummaryItem;

export interface IProps
{
    //icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; } || React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string;}>
    icon: React.ElementType;
    label: string;
    value: string;
}