import { GridLocaleText, deDE, enUS, esES, jaJP, zhCN } from "@mui/x-data-grid";

export class DataGridText
{
    public getLocaleText(): Partial<GridLocaleText>
    {
        const languages = navigator.languages;
        let preferredLanguage = enUS.components.MuiDataGrid.defaultProps.localeText;

        for (var i = 0; i < languages.length; i++)
        {
            if (languages[i] == 'en')
            {
                preferredLanguage = enUS.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'de')
            {
                preferredLanguage = deDE.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'es')
            {
                preferredLanguage = esES.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'ja')
            {
                preferredLanguage = jaJP.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'zh')
            {
                preferredLanguage = zhCN.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
        }
        return preferredLanguage;
    }
}
