import { Card, CardContent, Grid } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { Category, ChartComponent, ColumnSeries, DataLabel, Inject, Legend, SeriesCollectionDirective, SeriesDirective } from "@syncfusion/ej2-react-charts";
import { KpiDataCard } from "./KpiDataCard";
import { VisitsDailySummary } from "./DataModels";
import { DateTime } from "luxon";
import { appContext } from "../../../../AppContext";

export class KpiDataForPeriod extends IbssComponent<IProps, IState>
{
    public render(): JSX.Element
    {
        const props = this.props;
        const { labels } = appContext();

        const periodDurationInDays = props.to.diff(props.from).days + 1;
        const previous3Months = props.summaries.filter(i => i.Summary_Date >= props.from.minus({ months: 3 }) && i.Summary_Date < props.from);
        const previousPeriod = props.summaries.filter(i => i.Summary_Date >= props.from.minus({ days: periodDurationInDays }) && i.Summary_Date < props.from);
        const currentPeriod = props.summaries.filter(i => i.Summary_Date >= props.from && i.Summary_Date < props.to.plus({ days: 1 }));

        const totalVisitorsCreated = currentPeriod.sum(i => i.Visits_Created_Count);
        const totalVisitorsAttended = currentPeriod.sum(i => i.Visits_Early_Checkin_Count + i.Visits_Late_Checkin_Count);

        const averageDailyVisitorsPlannedThisPeriod = currentPeriod.average(i => i.Visits_Created_Count);
        const averageDailyVisitorsPlannedPreviousPeriod = previousPeriod.average(i => i.Visits_Created_Count);
        const averageDailyVisitorsPlannedRollingAverage = previous3Months.average(i => i.Visits_Created_Count);

        const averageDailyVisitorsApprovedThisPeriod = currentPeriod.average(i => i.Visits_Approved_Count);
        const averageDailyVisitorsApprovedPreviousPeriod = previousPeriod.average(i => i.Visits_Approved_Count);
        const averageDailyVisitorsApprovedRollingAverage = previous3Months.average(i => i.Visits_Approved_Count);

        const averageDailyVisitorsAttendedThisPeriod = currentPeriod.average(i => i.Visits_Early_Checkin_Count + i.Visits_Late_Checkin_Count);
        const averageDailyVisitorsAttendedPreviousPeriod = previousPeriod.average(i => i.Visits_Early_Checkin_Count + i.Visits_Late_Checkin_Count);
        const averageDailyVisitorsAttendedRollingAverage = previous3Months.average(i => i.Visits_Early_Checkin_Count + i.Visits_Late_Checkin_Count);

        return (<>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%", backgroundColor: "var(--ui-warn-highlight)" }}>
                        <CardContent>
                            <div><span>{labels.funcTotalVisitorsCreated_S}:</span> <span style={{ fontWeight: "bold" }}>{totalVisitorsCreated}</span></div>
                            <div><span>{labels.funcTotalVisitorsAttended_S}:</span> <span style={{ fontWeight: "bold" }}>{totalVisitorsAttended}</span></div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <KpiDataCard
                        backgroundColour="var(--ui-warn-highlight)"
                        title={labels.funcAvgDailyVisitorsPlanned_S}
                        value={averageDailyVisitorsPlannedThisPeriod}
                        valueForPreviousPeriod={averageDailyVisitorsPlannedPreviousPeriod}
                        rollingAverage={averageDailyVisitorsPlannedRollingAverage}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <KpiDataCard
                        backgroundColour="var(--ui-warn-highlight)"
                        title={labels.funcAvgDailyVisitorsApproved_S}
                        value={averageDailyVisitorsApprovedThisPeriod}
                        valueForPreviousPeriod={averageDailyVisitorsApprovedPreviousPeriod}
                        rollingAverage={averageDailyVisitorsApprovedRollingAverage}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <KpiDataCard
                        backgroundColour="var(--ui-warn-highlight)"
                        title={labels.funcAvgDailyVisitorsAttended_S}
                        value={averageDailyVisitorsAttendedThisPeriod}
                        valueForPreviousPeriod={averageDailyVisitorsAttendedPreviousPeriod}
                        rollingAverage={averageDailyVisitorsAttendedRollingAverage}
                    />
                </Grid>
            </Grid>
        </>
        );
    }
}

export interface IProps
{
    from: DateTime;
    to: DateTime;
    summaries: VisitsDailySummary[];
}

export interface IState
{
}
