export default class Guid
{
    private value = "";

    private constructor()
    {
    }

    public static new(): Guid
    {
        const guid = new Guid();
        guid.generate();
        return guid;
    }

    private static _empty: Guid | null = null;
    public static get empty(): Guid
    {
        if (Guid._empty == null)
        {
            Guid._empty = new Guid();
            Guid._empty.value = "00000000-0000-0000-0000-000000000000";
        }
        return Guid._empty;
    }

    private generate()
    {
        this.value = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/x/g, char => (Math.random() * 16 | 0).toString(16));
    }

    public toString(format = "D"): string
    {
        switch (format)
        {
            case "D":
                return this.value;
            case "N":
                return this.value.replace(/-/g, "");
            default:
                throw new Error(`"The format '${format}' is not supported`);
        }
    }
}