import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { headerSelectionCall, headerImage } from "../../../../../src/Common/Helper";

const SpaceAnalyticsLeftHeader = (props: {
  spaceAnalyticsTable: any;
}) => {
  const { spaceAnalyticsTable } = props;
  const {
    buildingClassType,
    buildingWorkSpaceType,
    buildingOptions,
    buildingFloor,
    nodeLevelName,
    lightModeTheme,
    
} = useSelector((state: RootStateOrAny) => ({
    buildingClassType:
      state.onelensSpaceAnalyticsFilterCriteria.buildingClassType,
    buildingWorkSpaceType:
      state.onelensSpaceAnalyticsFilterCriteria.buildingWorkSpaceType,
    buildingOptions: state.onelensSpaceAnalyticsFilterCriteria.buildingOptions,
    buildingFloor: state.onelensSpaceAnalyticsFilterCriteria.buildingFloor,
    onelensDateBuilding: state.onelensDateBuilding,
    nodeLevelName: state.onelensDateBuilding.nodeLevelName,
    lightModeTheme: state.lightModeTheme,
  }));
  return (
    <div className="card-result p-10 my-6">
      <div className="d-flex justify-content-center p-10">
        <div className="">
          {spaceAnalyticsTable.length === 0 ? "": <img
            alt=""
            src={`/images/SpaceType_Icons/${
              lightModeTheme ? "Light_Theme" : "Dark_Theme"
            }/${headerImage(
              nodeLevelName,
              buildingFloor,
              buildingOptions,
              buildingWorkSpaceType
            )}.svg`}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.stopPropagation();
              (e.target as HTMLImageElement).onerror = null;
              (e.target as HTMLImageElement).src = `/images/SpaceType_Icons/${
                lightModeTheme ? "Light_Theme" : "Dark_Theme"
              }/${spaceAnalyticsTable[0]?.Space_Type}.svg`;
            }}
          />}
          
        </div>
        <div className="mx-10 result-item-center">
          <div className="text-ui-20">{spaceAnalyticsTable.length}</div>
          <div className="text-ui-14">
            {headerSelectionCall(
              nodeLevelName,
              buildingFloor,
              buildingOptions,
              buildingWorkSpaceType,
              buildingClassType,
              spaceAnalyticsTable.length
            )}
          </div>
        </div>
        <div>
          <img alt="arrowImg" src="/images/Arrow.svg" />
        </div>
      </div>
    </div>
  );
};

export default SpaceAnalyticsLeftHeader;
