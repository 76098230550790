import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import IbssInputDropDown from "../../../Components/Inputs/SelectList/IbssInputDropDown";
import { InputOption } from "../../../Components/Inputs/SelectList2/InputSelectBox";
import { IUserPreferences } from "../../../Providers.Api/UserPreferences/UserPreferenceRepository";

export default class OnBoardingBuildingPreferences extends Component<IOnBoardingBuildingPreferences>
{
    private labels = appContext().labels;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;
    private incrementActiveStepProp;

    constructor(props: IOnBoardingBuildingPreferences) {
        super(props);
        this.incrementActiveStepProp = props.incrementActiveStep;
    }

    submitBuilding = async () => {
        const payload: IUserPreferences =
        {
            ...this.local.getUserPreferences(),
            SearchPrefs:
            {
                DefaultBuilding: this.props.buildings.value,
                DefaultSearchResults: this.props.userPrefrences.SearchPrefs?.DefaultSearchResults ?? 'List',
            },
        };

        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.incrementActiveStepProp();
            }
        }
        catch { }
    }

    public render(): JSX.Element {
        const { buildings } = this.props;

        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10">{this.labels.HubLabelBuildingPreference}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelBuildingPreferenceParagraph} </p>
                <div className="ibss-label-bold-md">{this.labels.HubLabelBuildingText} </div>
                <div className="my-16">
                    <IbssInputDropDown
                        {...buildings}
                    />
                </div>
                <IbssButton variant="contained" onClick={() => {
                    this.submitBuilding();
                }}
                > {this.labels.HubButtonNext} </IbssButton>
            </div>
        </>);
    }
}

class State {
    private get labels() { return appContext().labels; }
    public buildings =
        {
            name: "DefaultBuilding",
            id: "DefaultBuilding",
            inputLabel: this.labels.dataUserPrefWorkplaceDefBuilding_L,
            options: new Array<InputOption>(),
            value: 0,
            onChange: (value: string) => { }
        };
    public floors =
        {
            name: "DefaultFloor",
            id: "DefaultFloor",
            inputLabel: this.labels.dataUserPrefWorkplaceDefFloor_L,
            options: new Array<InputOption>(),
            value: 0,
            onChange: (value: string) => { }
        };

}

export interface IOnBoardingBuildingPreferences {
    incrementActiveStep?: any;
    buildings: {
        name: string;
        id: string;
        inputLabel: string;
        options: InputOption[];
        value: number | null;
        onChange: (value: string) => void;
    }
    userPrefrences: IUserPreferences;
}