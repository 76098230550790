import React, { Component } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

type IbssSvgIconProps = SvgIconProps

export default class IbssSvgIcon extends Component<IbssSvgIconProps> {
  render() {
    return (
      <>
        <SvgIcon {...this.props} style={{ overflow: "visible" }}>
          {this.props.children}
        </SvgIcon>
      </>
    )
  }
}