import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class DeleteCateringRestrictionEndpoint implements IDeleteCateringRestrictionEndpoint
{
    public async execute(nodeId: number, restrictionId: string): Promise<IDeleteCateringRestricitonResponse>
    {
        try
        {
            const response = await axios.delete<IDeleteCateringRestricitonResponse>(apis.allApiv2 + nodeId + `/catering-restriction/` + restrictionId);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface IDeleteCateringRestrictionEndpoint
{
    execute(nodeId: number, restrictionId: string): Promise<IDeleteCateringRestricitonResponse>;
}

export interface IDeleteCateringRestricitonResponse
{
    Message: string;
}
