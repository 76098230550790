import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { convertApiResponseObjectToLower } from "../../../../Common/Helper"
import { appContext } from "../../../../AppContext";

class EditDevice extends Component {

  private labels = appContext().labels;
  private alert = appContext().alert;
  private local = appContext().localStorageProvider;

  state = {
    isLoadingForDeleteDevice: false,
    isLoadingToSave: false,
    filter: "",
    loading: false,
    activePage: 0,
    updates: false,
    nodeId: null,
    title: "",
    id: null,
    signage_Id: null,
    space_Id: null,
    device_Type: null,
    device_Code: null,
    device_Mode: null,
    device_Auth_Error: "",
    device_General_Error: "",
    device_OEM_Mode: null,
    device_OEM_Client_ImageURI: "",
    device_OEM_Brand_ImageURI: "",
    device_MAC: "",
    device_IP: "",
    device_Config: "",
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter')
    })
    if (match.params.id !== "0" && match.params.nodeid) {
      this.getRightList();
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        id: parseInt(match.params.id),
        filter: match.params.filter
      }, () => {
        this.getDetails(this.state.id, this.state.filter);
      })
    } else {
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        title: this.labels.HubLabelNewDevice,
        filter: match.params.filter,
        id: 0,
        isUpdateRight: true
      })
    }

  }

  getRightList = () => {
    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.Devices && res.ADMINPORTAL.Devices.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.Devices && res.ADMINPORTAL.Devices.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any, filter: any) => {
    this.setState({ loading: true });

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }

    apis.getDeviceById(id).then((res) => {
      const newData = convertApiResponseObjectToLower(res.data)
      this.setState({ ...newData });
      this.setState({
        loading: false,
        title: res.data.device_Id,
        device_Config: JSON.stringify(res.data.device_Config)
      });
    }).catch((error: any) => { });

  };
  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match }: any = this.props;
    const { nodeId, id, device_Type, device_Code, device_Mode, space_Id, signage_Id, OEM_Mode,
      device_OEM_Client_ImageURI, device_OEM_Brand_ImageURI, device_General_Error, device_Auth_Error, device_MAC, device_IP, device_Config }: any = this.state;

    let payload = {
      "signage_Id": parseInt(signage_Id),
      "space_Id": space_Id,
      "device_Type": parseInt(device_Type),
      "device_Code": parseInt(device_Code),
      "device_Mode": parseInt(device_Mode),
      "device_Auth_Error": device_Auth_Error,
      "device_General_Error": device_General_Error,
      "device_OEM_Mode": parseInt(OEM_Mode),
      "device_OEM_Client_ImageURI": device_OEM_Client_ImageURI,
      "device_OEM_Brand_ImageURI": device_OEM_Brand_ImageURI,
      "device_MAC": device_MAC,
      "device_IP": device_IP,
      "device_Config": device_Config,
    }

    if (match.params.id === "0") {
      this.create(nodeId, payload);
    } else {
      this.update(id, payload);
    }
  };

  create = (nodeId: any, payload: any) => {
    apis.createDevice(payload, nodeId).then((res) => {
      const { history }: any = this.props;
      this.setState({
        updates: true,
        isLoadingToSave: false
      }, () => {
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Device/nodeid=" + nodeId + "/id=" + res.data.device_Id);
        window.location.reload();
      })
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false });
    });
  }

  update = (id: any, payload: any) => {
    const { history }: any = this.props;
    const { updates, nodeId }: any = this.state;
    if (updates) {
      apis.updateDeviceById(id, payload).then((res) => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/6");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      this.setState({ isLoadingToSave: false });
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/6");
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };
  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { nodeId } = this.state
    const { match }: any = this.props;
    const id = match.params.id;
    this.setState({ isLoadingForDeleteDevice: true })
    if (this.state.isDelete) {
      apis.deleteDeviceById(id).then((res) => {
        const { history }: any = this.props;
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/6");
      }).catch((error: any) => { });
    }
    this.setState({ isLoadingForDeleteDevice: false })
    return;
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, nodeId } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/6");
    }
  }

  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }


  linkPath = (data: any) => {
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  formatJson = () => {
    this.setState({ updates: true })
    if (this.state.device_Config.length !== 0 && this.state.device_Config !== "{}") {
      try {
        let newData = JSON.parse(this.state.device_Config)
        this.setState({
          device_Config: JSON.stringify(newData, null, 2)
        })
      }
      catch (e) {
        this.alert.show("", this.labels.HubLabelInvalidJSONMessage);
      }
    }

  }

  render() {
    const { device_Id, device_Type, device_Code, device_Mode, space_Id, signage_Id, OEM_Mode, device_MAC, device_IP, device_Config,
      device_OEM_Client_ImageURI, device_OEM_Brand_ImageURI, device_General_Error, device_Auth_Error }: any = this.state;
    const { history }: any = this.props;

    const deviceIdFld = {
      name: "device_Id",
      label: this.labels.HubLabelDeviceId,
      value: device_Id,
      mandatory: true,
      placeholder: this.labels.HubLabelDeviceIdPlaceholder,
      onchange: this.change,
    }

    const deviceTypeFld = {
      name: "device_Type",
      label: this.labels.HubLabelDeviceType,
      value: device_Type,
      mandatory: true,
      placeholder: this.labels.HubLabelDeviceTypePlaceholder,
      onchange: this.change,
    }

    const deviceCodeFld = {
      name: "device_Code",
      label: this.labels.HubLabelDeviceCode,
      value: device_Code,
      mandatory: true,
      placeholder: this.labels.HubLabelDeviceCodePlaceholder,
      onchange: this.change,
    }

    const deviceModeFld = {
      name: "device_Mode",
      label: this.labels.HubLabelDeviceMode,
      value: device_Mode,
      mandatory: true,
      placeholder: this.labels.HubLabelDeviceModePlaceholder,
      onchange: this.change,
    }

    const spaceIdFld = {
      name: "space_Id",
      label: this.labels.HubLabelSpaceId,
      value: space_Id,
      mandatory: true,
      placeholder: this.labels.HubLabelSpaceIdPlaceholder,
      onchange: this.change,
    }

    const signageIdFld = {
      name: "signage_Id",
      label: this.labels.HubLabelSignageId,
      value: signage_Id,
      mandatory: true,
      placeholder: this.labels.HubLabelSignageIdPlaceholder,
      onchange: this.change,
    }

    const OEMModeFld = {
      name: "OEM_Mode",
      label: this.labels.HubLabelOEMMode,
      value: OEM_Mode,
      placeholder: this.labels.HubLabelOEMModePlaceholder,
      onchange: this.change,
    }

    const deviceOEMClientImageURIFld = {
      name: "device_OEM_Client_ImageURI",
      label: this.labels.HubLabelOEMClientImageURI,
      value: device_OEM_Client_ImageURI,
      mandatory: true,
      placeholder: this.labels.HubLabelOEMClientImageURIPlaceholder,
      onchange: this.change,
    }

    const device_OEM_Brand_ImageURIFld = {
      name: "device_OEM_Brand_ImageURI",
      label: this.labels.HubLabelDeviceOEMBrandImageURI,
      value: device_OEM_Brand_ImageURI,
      mandatory: true,
      placeholder: this.labels.HubLabelOEMBrandImageURIPlaceholder,
      onchange: this.change,
    }

    const device_General_ErrorFld = {
      name: "device_General_Error",
      label: this.labels.HubLabelGeneralErrorMessage,
      value: device_General_Error,
      mandatory: true,
      placeholder: this.labels.HubLabelGeneralErrorMessagePlaceholder,
      onchange: this.change,
    }

    const device_Auth_ErrorFld = {
      name: "device_Auth_Error",
      label: this.labels.HubLabelAuthenticationError,
      value: device_Auth_Error,
      mandatory: true,
      placeholder: this.labels.HubLabelAuthenticationErrorPlaceholder,
      onchange: this.change,
    }

    const deviceMACFld = {
      name: "device_MAC",
      label: this.labels.HubLabelMACaddress,
      value: device_MAC,
      placeholder: this.labels.HubLabelMACaddressPlaceholder,
      onchange: this.change,
    }

    const deviceIPFld = {
      name: "device_IP",
      label: this.labels.HubLabelIPAddressLabel,
      value: device_IP,
      placeholder: this.labels.HubLabelIPAddressPlaceholder,
      onchange: this.change,
    }

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
        {
          title: this.labels.HubTabDeviceConfiguration,
          tabNo: 1,
          active: this.state.activePage === 1 ? true : false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.device_Code,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.device_Code,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.nodeId + "/" + this.state.filter + "/6") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }

    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              {(this.state.isLoadingForDeleteDevice) && <Spinner />}
              <div className="form-panel">
                <div className="form-panel-heading">
                  <div className="form-panel-breadcrumbs">
                    <div className="breadcrumbs">
                      {this.state.id === 0 ?
                        <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelDevices}</Link>
                        :
                        <div>
                          <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                          {this.state.showOrganisation && this.state.organisationState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                          {this.state.showRegion && this.state.regionState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                          {this.state.showBuilding && this.state.buildingState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                          {this.state.showFloor && this.state.floorState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                          {this.state.showZone && this.state.zoneState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                          {this.state.showRoom && this.state.roomState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                          {this.state.showDesk && this.state.deskState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                        </div>
                      }
                    </div>
                    <div className="page-title mb-20"><h1>{this.state.title}</h1></div>
                  </div>
                </div>
                {/* {this.state.activePage === 0 && ( */}
                <div className="main-content">
                  {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                  <div className="height-control">
                    {this.state.loading || (
                      <div className="card card-scroller">
                        {this.state.activePage === 0 && (
                          <div className="p-45">
                            <form>
                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...deviceIdFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...deviceTypeFld} /> </div>

                                <div className="col-3 pr-2 input-controls"><Inputbox {...deviceCodeFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...deviceModeFld} /> </div>
                              </div>

                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...spaceIdFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...signageIdFld} /> </div>

                                <div className="col-6">
                                  <Inputbox {...OEMModeFld} />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...deviceOEMClientImageURIFld} /></div>
                                <div className="col-6"><Inputbox {...device_OEM_Brand_ImageURIFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...device_General_ErrorFld} /></div>
                                <div className="col-6"><Inputbox {...device_Auth_ErrorFld} /></div>
                              </div>
                              <div className="row">
                                <div className="col-3 pr-2"><Inputbox {...deviceMACFld} /></div>
                                <div className="col-3"><Inputbox {...deviceIPFld} /></div>
                              </div>

                            </form>
                          </div>
                        )}
                        {this.state.activePage === 1 && (
                          <div className="p-45">
                            <form>
                              <div className="row" style={{ display: "block" }}>
                                <div className="label"><label>{this.labels.HubTabDeviceConfiguration}</label></div>
                                <textarea
                                  name="device_Config"
                                  className="textAreaUserDetails"
                                  onChange={this.change}
                                  value={device_Config}
                                />
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* )} */}

                {/* {this.state.activePage === 1 && (

              )} */}


              </div>
            </div>
            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                {(this.state.id !== 0 && this.state.isDeleteRight === false) && (
                  <button className="button-tertiary-disable mr-2" disabled={true} >{this.labels.HubButtonDelete}</button>
                )}
                {(this.state.id !== 0 && this.state.isDeleteRight === true) && (
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
              </div>
              <div>
                {(this.state.activePage === 1 && this.state.isUpdateRight === false) &&
                  <button className="button-tertiary ml-2" disabled={true}>{this.labels.HubLabelFormat}</button>
                }
                {(this.state.activePage === 1 && this.state.isUpdateRight === true) &&
                  <button className="button-tertiary ml-2" onClick={() => this.formatJson()}>{this.labels.HubLabelFormat}</button>
                }
                {this.state.isUpdateRight === false &&
                  <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                }
                {this.state.isUpdateRight === true &&
                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                }
              </div>
            </div>
          </div>
   
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(EditDevice);