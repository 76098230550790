import { Component } from 'react'
import { Card, Grid, Typography } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SecurityIcon from '../../../../Components/Icons/SecurityIcon';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { PageHelper } from './PageHelper';

class ManagePinState
{
    public ManagePin = PageHelper.getUserPrefrenceJson();
    public isLoading = false;
    public showSetPinPassword = false;
    public showConfirmPinPassword = false;
    public setPin = "";
    public confirmSetPin = "";
    public setPinError = "";
    public ConfirmSetPinError = "";
    public setPinMethodError = false;
    public correctPin = false;
};

class ManagePin extends Component<any, ManagePinState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: any)
    {
        super(props)
        this.state = new ManagePinState();
    }

    componentDidMount()
    {
        this.setState({ isLoading: true });
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, ManagePin: response });


        let pin = "";
        if (response?.SecurityPrefs?.Pin)
        {
            pin = response?.SecurityPrefs?.Pin

            this.setState({
                setPin: pin,
                confirmSetPin: pin
            })
        }
    };

    public change = (e: any) =>
    {
        this.setState({
            setPinError: "",
            ConfirmSetPinError: ""
        })
        if (e.target.name === "setPin")
        {
            this.setState({ setPin: e.target.value });
        } else
        {
            this.setState({ confirmSetPin: e.target.value })
        }
    };

    public handleClickShowPin = (value: string) =>
    {
        if (value === "SetPin")
        {
            this.setState({
                showSetPinPassword: !this.state.showSetPinPassword
            })
        } else
        {
            this.setState({
                showConfirmPinPassword: !this.state.showConfirmPinPassword
            })
        }
    };

    public setPinMethod = () =>
    {
        const reg = new RegExp('^[0-9]+$')
        if (this.state.setPin === "")
        {
            this.setState({ setPinError: "Please enter set PIN", setPinMethodError: true });
            return;
        }
        if (this.state.confirmSetPin === "")
        {
            this.setState({ setPinError: "Please enter confirm set PIN", setPinMethodError: true });
            return;
        }

        if (!reg.test(this.state.setPin))
        {
            this.setState({ setPinError: "Please enter numbers only", setPinMethodError: true });
            return;
        }
        if (this.state.setPin !== this.state.confirmSetPin)
        {
            this.setState({ ConfirmSetPinError: "Your PIN does not match.", setPinMethodError: true });
            return;
        }
        this.setState({ correctPin: true });
    };

    public SubmitPin = async () =>
    {
        if (this.state.setPin === "")
        {
            this.setState({ setPinError: "Please enter set PIN", setPinMethodError: true });
            return;
        }
        if (this.state.confirmSetPin === "")
        {
            this.setState({ setPinError: "Please enter confirm set PIN", setPinMethodError: true });
            return;
        }
        if (!this.state.setPinMethodError)
        {
            const payload = {
                ...this.state.ManagePin,
                SecurityPrefs: {
                    Pin: this.state.setPin,
                }
            }
            try
            {
                await this.userPreferences.update(payload);
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
            }
            catch { }
        }
    };

    public handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    {
        event.preventDefault();
    };

    render()
    {
        return (
            <>
                <Grid container>
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleManagePin}</Typography>

                            <Typography className='card-userPref-text' variant="body2" gutterBottom>{this.labels.HubSubTitleManagePin}</Typography>

                            <Typography className='card-userPref-text' variant="body2" marginBottom={"32px"} gutterBottom>{this.labels.HubSubTitleSecondManagePin}</Typography>
                        </Grid>

                        <div>
                            <div className='mb-16'>
                                <IbssFormControl variant="outlined" fullWidth >
                                    <OutlinedInput
                                        id="set-PIN"
                                        type={this.state.showSetPinPassword ? 'text' : 'password'}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IbssSvgIcon>
                                                    {Icons.securityIcon}
                                                </IbssSvgIcon>
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">

                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPin("SetPin")}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {this.state.showSetPinPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        name='setPin'
                                        onChange={this.change}
                                        value={this.state.setPin}
                                        inputProps={{ maxLength: 4 }}
                                        placeholder={this.labels.funcUserPrefAccountSetPIN_L}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    />
                                    {this.state.setPinError && <span className='error-text'>{this.state.setPinError}</span>}
                                </IbssFormControl>
                            </div>
                            <div>
                                <IbssFormControl variant="outlined" fullWidth >
                                    <OutlinedInput
                                        id="Confirm-PIN"
                                        type={this.state.showConfirmPinPassword ? 'text' : 'password'}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IbssSvgIcon>
                                                    {Icons.securityIcon}
                                                </IbssSvgIcon>
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPin('ConfirmPin')}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {this.state.showConfirmPinPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        name='confirmSetPin'
                                        onChange={this.change}
                                        value={this.state.confirmSetPin}
                                        inputProps={{ maxLength: 4 }}
                                        placeholder={this.labels.funcUserPrefAccountConfirmPIN_L}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    />
                                </IbssFormControl>
                                {this.state.ConfirmSetPinError && <span className='error-text'>{this.state.ConfirmSetPinError}</span>}
                            </div>
                        </div>

                        <Typography className="card-userPref-text mb-32 mt-16" variant="body2" gutterBottom>{this.labels.HubLabelYourPinWillNeedToBeA4DigitCode}</Typography>

                        <IbssButton size="large" className="btn-block" variant="contained" onClick={() => this.setPinMethod()} >{this.labels.HubButtonSetPin}</IbssButton>
                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' disabled={!this.state.correctPin} onClick={() => this.SubmitPin()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default ManagePin;
